import {
  Activity,
  Agreement,
  Locations,
  ProviderActionType,
  ProviderVisibilityLocations,
  ProvinceCode,
  Region,
  RegionCode,
} from '@kaa/api/providers';
import { Province } from '@kaa/api/providers/model/province';
import { httpTo } from '@kaa/api/providers/utilities';
import { ActionType, useActionDispatch } from '@kaa/common/context';
import { ActionsHandler } from '@kaa/common/handlers';
import { ValueOf } from '@kaa/common/types';
import { useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AlertType,
  getAlertPropsByType,
  SwActionGroup,
  SwAlert,
  SwButton,
  SwColumn,
  SwContainer,
  SwFetchErrorMessage,
  SwForm,
  SwGrid,
} from '@kaa/ui-flanders/components';
import { RouteComponentProps } from '@reach/router';
import { Formik, FormikActions } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ContainerLoadingPageHeader, PageHeader } from '../../../components';
import { dataTest } from '../../../datatest/keys';
import { useApi, useSelectedProviderState } from '../../../utils';
import {
  EventAction,
  EventCategory,
  EventLabel,
  sendCustomInteractionToGTM,
} from '../../../utils/google-analytics';
import { handleApiError } from '../../../utils/validation';
import { VisibilityByAgreements } from './components/byAgreements/VisibilityByAgreements';
import { VisibilityByPostalCodes } from './components/ByPostalCodes/VisibiltyByPostalCodes';
import { VisibilityByRegionsProvinces } from './components/ByRegionsProvinces/VisibilityByRegionsProvinces';
import { VisibilityFormState } from './VisibilityScreen.type';

type LocationsFormRepresentation = {
  postcodes: string[];
  regions: { [regionCode in ValueOf<typeof RegionCode>]: boolean };
  provinces: { [provinceCode in ValueOf<typeof ProvinceCode>]: boolean };
  agreements: { [Activity in ValueOf<typeof Activity>]: boolean };
};

const getInitialFormValues = (
  activeLocations: ProviderVisibilityLocations,
  availableLocations: Locations,
  agreements: Agreement[],
): LocationsFormRepresentation => ({
  postcodes: activeLocations.postcodes,
  regions: availableLocations.regions.reduce(
    (regions, { code: regionCode }) => ({
      ...regions,
      [regionCode]: activeLocations.regions.some((code) => code === regionCode),
    }),
    {} as any,
  ),
  provinces: availableLocations.provinces.reduce(
    (provinces, { code: provinceCode, regionCode }) => ({
      ...provinces,
      [provinceCode]:
        activeLocations.regions.some((code) => code === regionCode) ||
        activeLocations.provinces.some((code) => code === provinceCode),
    }),
    {} as any,
  ),
  agreements: agreements.reduce(
    (acc, { activityType, isVisible }) => ({
      ...acc,
      [activityType]: isVisible,
    }),
    {} as any,
  ),
});

type VisibilityScreenProps = {
  enterpriseId: string;
};

export const VisibilityScreen = ({
  enterpriseId,
}: RouteComponentProps<VisibilityScreenProps>) => {
  const { t } = useTranslation();
  const dispatchAction = useActionDispatch();

  const { providers: providersApi } = useApi();
  const currentProvider = useSelectedProviderState();

  const [
    { value: visibilityData, loading, error },
    getVisibilityData,
  ] = useAsyncCallback(
    async () => {
      if (!enterpriseId) {
        return;
      }

      const [
        providerData,
        availableLocationsData,
        activeLocationsData,
        agreementsData,
      ] = await Promise.all([
        providersApi.getProvider(Number(enterpriseId)).then(({ data }) => data),
        providersApi.getGlobalLocations().then(({ data }) => data),
        providersApi
          .getProviderVisibilityForLocations(Number(enterpriseId))
          .then(({ data }) => data),
        providersApi
          .getProviderVisibilityForAgreements(Number(enterpriseId))
          .then(({ data }) => data),
      ]);

      const { actions } = agreementsData;

      if (actions) {
        dispatchAction({ type: ActionType.ADD, payload: actions });
      }

      return {
        provider: providerData.data,
        availableLocations: availableLocationsData.data,
        activeLocations: activeLocationsData.data,
        agreements: agreementsData.data,
      };
    },
    [enterpriseId, providersApi],
    { loading: true },
  );

  useEffect(() => {
    getVisibilityData();
  }, [getVisibilityData]);

  const [{ value: submitedForm }, submit] = useAsyncCallback(
    async (
      formikData: VisibilityFormState,
      formikActions: FormikActions<VisibilityFormState>,
    ) => {
      if (!visibilityData || !enterpriseId) {
        return;
      }

      const {
        postcodes,
        provinces,
        agreements: activeAgreements,
        regions,
      } = formikData;

      const { availableLocations, agreements } = visibilityData;
      const activeProvinces = availableLocations.provinces.reduce<Province[]>(
        (acc, province) =>
          provinces[province.code] ? [...acc, province] : acc,
        [],
      );
      const activeRegions = availableLocations.regions.reduce<Region[]>(
        (acc, region) => (regions[region.code] ? [...acc, region] : acc),
        [],
      );

      const data = {
        postcodes,
        provinces: activeProvinces.map(({ code }) => code),
        regions: activeRegions.map(({ code }) => code),
      };

      const [error, response] = await httpTo(
        Promise.all([
          providersApi.updateProviderVisibilityForLocations(
            Number(enterpriseId),
            data,
          ),
          providersApi.updateProviderVisibilityForAgreements(
            Number(enterpriseId),
            agreements.map((agreement) => ({
              ...agreement,
              isVisible: activeAgreements[agreement.activityType],
            })),
          ),
        ]),
      );

      return {
        error,
        response,
        data,
        formikData,
        formikActions,
      };
    },
    [enterpriseId, providersApi, visibilityData],
  );

  useEffect(() => {
    if (submitedForm) {
      const { error, formikActions, formikData } = submitedForm;
      const { setSubmitting, setStatus, resetForm } = formikActions;
      if (handleApiError(error, formikActions)) {
        return;
      }

      const {
        postcodes,
        provinces,
        agreements: activeAgreements,
        regions,
      } = formikData;

      resetForm({
        postcodes,
        provinces,
        agreements: activeAgreements,
        regions,
      });

      setStatus({
        type: AlertType.SUCCESS,
        msg: t(i18nKeys.visibility.locations.update.successMessage),
      });

      setSubmitting(false);

      sendCustomInteractionToGTM(
        EventCategory.COMPANY_MANAGEMENT,
        EventAction.MODIFY,
        EventLabel.VISIBILITY,
      );
    }
  }, [submitedForm]);

  if (!visibilityData && loading) {
    return (
      <ContainerLoadingPageHeader
        title={t(i18nKeys.visibility.locations.title)}
        introduction={t(i18nKeys.visibility.locations.introduction)}
      />
    );
  }

  if (error || !visibilityData) {
    return (
      <SwContainer error>
        <SwFetchErrorMessage onClick={getVisibilityData} />
      </SwContainer>
    );
  }

  const {
    provider,
    availableLocations,
    activeLocations,
    agreements,
  } = visibilityData;

  const {
    address: { postcode: providerPostcode },
  } = provider;

  const initialFormValues = getInitialFormValues(
    activeLocations,
    availableLocations,
    agreements,
  );

  return (
    <SwContainer>
      <SwGrid modStacked>
        <PageHeader
          title={t(i18nKeys.visibility.locations.title)}
          introduction={t(i18nKeys.visibility.locations.introduction)}
        />
        <SwColumn width="10" widthS="12">
          <Formik<VisibilityFormState>
            onSubmit={submit}
            initialValues={initialFormValues}
            render={({
              handleSubmit,
              status,
              values,
              dirty,
              isValid,
              isSubmitting,
              setFieldValue,
              setStatus,
            }) => {
              return (
                <SwForm onSubmit={handleSubmit}>
                  <SwGrid modStacked>
                    <ActionsHandler
                      resourceId={currentProvider.resourceId}
                      actions={[ProviderActionType.CAN_EDIT_VISIBILITY]}
                    >
                      {({ isAllowed }) => {
                        return (
                          <>
                            <SwColumn>
                              <VisibilityByAgreements
                                agreements={agreements}
                                modDisabled={!isAllowed}
                              />
                            </SwColumn>
                            <SwColumn>
                              <VisibilityByRegionsProvinces
                                availableLocations={availableLocations}
                                activeProvinces={values.provinces}
                                activeRegions={values.regions}
                                mutatorSetValue={setFieldValue}
                                modDisabled={!isAllowed}
                              />
                            </SwColumn>
                            <SwColumn>
                              <VisibilityByPostalCodes
                                availableLocations={availableLocations}
                                activePostcodes={values.postcodes}
                                mutatorSetValue={setFieldValue}
                                providerPostcode={providerPostcode}
                                modDisabled={!isAllowed}
                              />
                            </SwColumn>
                            <SwColumn>
                              <SwActionGroup>
                                <SwButton
                                  data-testid={dataTest.visibility.submitButton}
                                  modLoading={isSubmitting}
                                  modDisabled={!isAllowed || !dirty || !isValid}
                                  type="submit"
                                >
                                  {t(i18nKeys.general.cta.validate)}
                                </SwButton>
                              </SwActionGroup>
                            </SwColumn>
                          </>
                        );
                      }}
                    </ActionsHandler>
                    {status && (
                      <SwColumn>
                        <SwAlert
                          {...getAlertPropsByType(status.alertType)}
                          close={() => setStatus(null)}
                          closable
                          modSmall
                        >
                          {status.msg}
                        </SwAlert>
                      </SwColumn>
                    )}
                  </SwGrid>
                </SwForm>
              );
            }}
          />
        </SwColumn>
      </SwGrid>
    </SwContainer>
  );
};
