/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */

export type GenderCode = 'M' | 'F';

export const GenderCode = {
  M: 'M' as GenderCode,
  F: 'F' as GenderCode,
};
