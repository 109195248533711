import { toggleModalById } from '@kaa/ui-flanders/components';
import TagManager, { DataLayerArgs } from 'react-gtm-module';
import { Modals } from '../constants';

export enum EventCategory {
  DASHBOARD = 'dashboard',
  MAIN_NAVIGATION = 'main navigation',
  BREADCRUMB = 'breadcrumb',
  REMITTANCES = 'remittances',
  PRESTATIONS = 'prestations',
  WORKER_DETAILS = 'worker details',
  WORKER_LIST = 'worker list',
  PRESTATIONS_HISTORY = 'prestations history',
  PROFILE = 'profile',
  REMITTANCES_MANUAL_ELECTRONIC = 'remittances manual electronic',
  REMITTANCES_REFUSED_VOUCHERS = 'remittances refused vouchers',
  REMITTANCES_INCORRECT_VOUCHERS = 'remittances incorrect vouchers',
  REMITTANCES_VOUCHERS_DECLARATION = 'remittances voucher declaration',
  REMITTANCES_REIMBURSEMENT = 'remittances reimbursement',
  SEARCH = 'search',
  DOWNLOAD = 'download',
  RELATIVE_DETAILS = 'relative details',
  COMPANY_MANAGEMENT = 'company management',
  CONTACT_PERSON = 'contact person',
  VISIBILITY = 'visibility',
  PICKUP = 'pickup',
  PICKUP_BILLING = 'pickup billing',
}

export enum EventAction {
  CLICK = 'click',
  CLICK_ALERT = 'click alert',
  CLICK_FORM = 'click form',
  FORM_CONFIRMATION = 'form confirmation',
  CLICK_EMAIL = 'click to email',
  SEARCH = 'search',
  SEND_FORM = 'send form',
  BACK = 'click back',
  LOG_OUT = 'Log out',
  DOWNLOAD = 'download',
  OPEN = 'open',
  PDF = 'pdf',
  XLS = 'xls',
  XML = 'xml',
  MODIFY = 'modify',
  DELETE = 'delete',
  ADD = 'add',
  DISABLE = 'disable',
  SELECT = 'select',
  PAUSE = 'pause',
  PLAY = 'play',
  SORT_BY = 'sort by',
  QUICK_ACTION = 'quick action',
  UPLOAD = 'upload',
}

export enum EventLabel {
  CHANGE_BANK_REQUEST = 'Change bank request',
  SUBMIT_RELATIVE_DETAILS_FORM = 'company details',
  REQUEST_CHANGE = 'Request changes',
  CONTACT_PERSON = 'contact person',
  CONTACT_PERSON_ADDED = 'New contact person added',
  CONTACT_PERSON_DELETED = 'Contact person deleted',
  CONTACT_PERSON_EDITED = 'Contact person edited',
  VISIBILITY = 'visibility',
  PROFILE_EMAIL_EDITED = 'email',
  PROFILE_PASSWORD_EDITED = 'password',
  RELATIVE = 'subsidiary',
  SORTBY = 'sortBy',
  REMITTANCES_SEARCH_ALERT = 'Display the remittance to correct from Alert',
  REMITTANCES_PAPER_CREATE_SLIP = 'Create online delivery slip',
  REMITTANCES_VOUCHER_VALIDITY = 'Check voucher validity',
  REMITTANCES_MANUAL_ELECTRONIC = 'Manual electronic remittance',
  REMITTANCES_MANUAL_ELECTRONIC_SEARCH = 'Search Electronic remittances',
  REMITTANCES_DECLARATION_ONE_BY_ONE = 'Declared Voucher (one by the one)',
  REMITTANCES_DECLARATION_UPLOAD_LIST = 'Upload Excell to Declare a Voucher List',
  REMITTANCES_DECLARATION_CORRECT_ROW_LIST = 'Correct a row from a Voucher List',
  REMITTANCES_DECLARATION_DELETE_ROW_LIST = 'Delete a row from a Voucher List',
  REMITTANCES_INCORRECT_VOUCHERS_ERRORTYPE = 'Filter Incorrect Vouchers by Error type',
  REMITTANCES_INCORRECT_VOUCHERS_SUBMITROW = 'Submit a row after correction',
  REMITTANCES_REFUSED_VOUCHERS_ERRORTYPE = 'Filter refused Vouchers by Error type',
  REMITTANCES_AUTOMATIC_ELECTRONIC = 'Automatic electronic remittance',
  REMITTANCES_MODIFY_PREFERENCES = 'Modofy preferences',
  PRESTATIONS_CREATION = 'Add a prestation',
  PRESTATIONS_DETAILS = 'See details of a prestation',
  PRESTATIONS_CANCEL = 'Cancel a prestation',
  PRESTATIONS_EDIT = 'Edit a prestation',
  PRESTATIONS_VALIDATE = 'Validate a prestation',
  PRESTATIONS_VALIDATE_ALL_OF_ONE = 'Validate all prestations of one worker',
  PRESTATIONS_VALIDATE_ALL_OF_ALL = 'Validate all prestations of all workers',
  PRESTATIONS_OVERVIEW = 'Prestations need an action',
  PRESTATIONS_SORT_DATES = 'Sort works per dates',
  PRESTATIONS_SORT_SERVICE_DATES = 'Sort works per service dates',
  PRESTATIONS_SORT_CREATION_DATES = 'Sort works per creation dates',
  PRESTATIONS_SORT_CUSTOMER = 'Sort works per customer name',
  PRESTATIONS_SORT_WORKER = 'Sort works per worker name',
  PRESTATIONS_STATUS_MISSING_CONTRACT = 'Add a contract for a worker',
  PRESTATIONS_STATUS_PAID = 'Create a remittance',
  PRESTATIONS_LIST_FILE_GUIDANCE = 'prestations list file guidance',
  PRESTATIONS_CLOSE_MODAL_DURING_UPLOAD_PARSING = 'close modal during upload',
  PRESTATIONS_CLOSE_MODAL_AFTER_UPLOAD_PARSING = 'close modal after upload',
  WORKER_LIST_FILE_GUIDANCE = 'file guidance',
  WORKER_LIST_REPORT = 'report',
  WORKER_LIST_ACTIVATE_ALL = 'activate all',
  WORKER_LIST_DEACTIVATE_ALL = 'deactivate all',
  WORKER_DISPLAY_INVOLVED = 'Display involved workers',
  WORKER_RESET_PASSWORD = 'Reset password',
  WORKER_DETAILS_EDIT = 'Edit details',
  WORKER_DETAILS_ACTIVATE = 'activate',
  WORKER_DETAILS_DEACTIVATE = 'deactivate',
  WORKER_CONTRACT_EDIT = 'Reset password',
  WORKER_CONTRACT_DELETE = 'Reset password',
  PICKUP_MANAGE = 'Manage pickup',
  PICKUP_SELECT = 'select pickup enterpise',
  PICKUP_STAND_ALONE = 'Create standalong pickup',
  PICKUP_RECURRENT = 'Create recurrent pickup',
  PICKUP_BILLING_REQUEST_DOMICILIATION = 'Request domiciliation',
  PICKUP_BILLING_EDIT_FINANCIAL_CONTACT = 'Edit financial contact person',
  PICKUP_BILLING_SEARCH_TASKS = 'Search tasks',
  PICKUP_BILLING_SORTBY_COMMERCIAL_NAME = 'Sort tasks by commercial name',
  PICKUP_BILLING_SORTBY_PROVIDER_ID = 'Sort tasks by provider id',
  PICKUP_BILLING_SORTBY_CONSUMPTION_DATE = 'Sort tasks by consumption date',
  PICKUP_BILLING_SORTBY_DEFAULT = 'Sort tasks by default',
}

export enum EventPathname {}

const dataLayerProxy = (data: DataLayerArgs) => {
  // Uncomment the following line for easy testing of GTM events
  // console.log(JSON.stringify(data, null, 2));
  TagManager.dataLayer(data);
};

export enum DashboardActionEvent {
  CLICK_CARD = 'click card',
  CLICK_ACTION = 'click action',
  CLICK_TIPS = 'click tips',
}

export const initAnalytics = (googleTagManagerId: string) => {
  TagManager.initialize({
    gtmId: googleTagManagerId,
  });
};

export const sendPageViewEvent = ({
  pathname,
  modDefaultPathname = true,
  isLogged,
}: {
  pathname?: string;
  modDefaultPathname?: boolean;
  isLogged?: boolean;
} = {}) => {
  dataLayerProxy({
    dataLayer: {
      event: 'VirtualPageview',
      virtualPageURL:
        (modDefaultPathname ? window.location.pathname : '') + pathname,
      isLogged,
    },
  });
};

export const openModalWithPageView = (
  id: Modals,
  { appendToId = '', appendToPath = '' } = {},
) => {
  toggleModalById(`${id}${appendToId}`);
  sendPageViewEvent({
    pathname: `/${id}${appendToPath ? '/' : ''}${appendToPath}`,
  });
};

export const sendConfirmationModalViewEvent = (id: Modals) => {
  sendPageViewEvent({
    pathname: `/${id}/confirmation`,
  });
};

export const sendMainNavigationEventToGTM = (
  parent: string,
  child?: string,
) => {
  dataLayerProxy({
    dataLayer: {
      event: 'GA Tracking',
      eventCategory: EventCategory.MAIN_NAVIGATION,
      eventAction: parent,
      eventLabel: child,
      eventValue: 0,
      eventNonInteraction: false,
    },
  });
};

export const sendDashboardActionEventToGTM = (
  action: DashboardActionEvent,
  elementClicked: string,
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'GA Tracking',
      eventCategory: EventCategory.DASHBOARD,
      eventAction: action,
      eventLabel: elementClicked,
      eventValue: 0,
      eventNonInteraction: false,
    },
  });
};

export const sendFormToGTM = (
  formName: string, // variable 1 = Form name
  elementsForm: string, // variable 2 = Specific form related element (eg: recurency check box for Pick up form)  or 'submit' Includes: validity check,
) => {
  dataLayerProxy({
    dataLayer: {
      event: 'GA Tracking',
      eventCategory: EventCategory.SEARCH,
      eventAction: formName,
      eventLabel: elementsForm,
      eventValue: 0,
      eventNonInteraction: false,
    },
  });
};

export const sendSearchToGTM = (
  searchedSection: string, // works (history)|remittances|...
  elementsFilled: string, // Name of the element filled separated by a | and the period in amount of days
) => {
  dataLayerProxy({
    dataLayer: {
      event: 'GA Tracking',
      eventCategory: EventCategory.SEARCH,
      eventAction: searchedSection,
      eventLabel: elementsFilled,
      eventValue: 0,
      eventNonInteraction: false,
    },
  });
};

export const sendDownloadEventToGTM = (action: EventAction, label: string) => {
  dataLayerProxy({
    dataLayer: {
      event: 'GA Tracking',
      eventCategory: EventCategory.DOWNLOAD,
      eventAction: action,
      eventLabel: label,
      eventValue: 0,
      eventNonInteraction: false,
    },
  });
};

export const sendCustomInteractionToGTM = (
  eventCategory: EventCategory,
  eventAction: string,
  eventLabel: string,
  eventValue?: number,
) => {
  dataLayerProxy({
    dataLayer: {
      event: 'GA Tracking',
      eventCategory,
      eventAction,
      eventLabel,
      eventValue: eventValue || 0,
      eventNonInteraction: false,
    },
  });
};
