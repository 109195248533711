/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */

export type WeekDays =
  | 'MONDAY'
  | 'TUESDAY'
  | 'WEDNESDAY'
  | 'THURSDAY'
  | 'FRIDAY'
  | 'SATURDAY'
  | 'SUNDAY';

export const WeekDays = {
  MONDAY: 'MONDAY' as WeekDays,
  TUESDAY: 'TUESDAY' as WeekDays,
  WEDNESDAY: 'WEDNESDAY' as WeekDays,
  THURSDAY: 'THURSDAY' as WeekDays,
  FRIDAY: 'FRIDAY' as WeekDays,
  SATURDAY: 'SATURDAY' as WeekDays,
  SUNDAY: 'SUNDAY' as WeekDays,
};
