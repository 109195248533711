import { TFunctionResult } from 'i18next';
import React from 'react';

type DashboardOverviewCardLabelProps = {
  text: TFunctionResult | number;
};

export const DashboardOverviewCardLabel = ({
  text,
}: DashboardOverviewCardLabelProps) => <strong>{String(text)}</strong>;
