import { RemittanceDetailRefused } from '@kaa/api/providers';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import { SwDataTable } from '@kaa/ui-flanders/components';
import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TableHead,
  TableRowLoader,
  TableRowNoResult,
} from '../../../../../components';
import { RemittancesSearchHistoryRefusedModalTableRow } from './RemittancesSearchHistoryRefusedModalTableRow';

type RemittancesSearchHistoryRefusedModalTableProps = {
  refusedVouchers: RemittanceDetailRefused;
  loading: boolean;
};

export const RemittancesSearchHistoryRefusedModalTable = ({
  refusedVouchers,
  loading,
}: RemittancesSearchHistoryRefusedModalTableProps) => {
  const { t } = useTranslation();

  const head = [
    { text: t(i18nKeys.remittances.general.vouchers) },
    { text: t(i18nKeys.remittances.refusedModal.table.tableHead.emission) },
    {
      text: t(i18nKeys.remittances.refusalReasons.label),
      style: { minWidth: '200px' } as CSSProperties,
    },
    { text: t(i18nKeys.remittances.refusedModal.table.tableHead.user) },
    { text: t(i18nKeys.remittances.refusedModal.table.tableHead.userId) },
  ];

  return (
    <SwDataTable modCollapsedM className="vl-u-spacer--medium">
      <TableHead elements={head} />
      <tbody>
        {loading ? (
          <TableRowLoader colSpan={head.length} />
        ) : (
          <>
            {!refusedVouchers || !refusedVouchers.length ? (
              <TableRowNoResult colSpan={head.length} />
            ) : (
              refusedVouchers.map((vouchersBundle) => (
                <RemittancesSearchHistoryRefusedModalTableRow
                  key={vouchersBundle.voucherReference}
                  vouchersBundle={vouchersBundle}
                />
              ))
            )}
          </>
        )}
      </tbody>
    </SwDataTable>
  );
};
