import { RegionCode } from '@kaa/api/providers';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import { SwBadge, useTooltip } from '@kaa/ui-flanders/components';
import get from 'lodash.get';
import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { getConfig } from '../../../../common/config';

type PrestationsOverviewTooltipProps = {
  status: string;
  count: number;
};

export const PrestationsOverviewTooltip = ({
  status,
  count,
}: PrestationsOverviewTooltipProps) => {
  const { t } = useTranslation();

  const regionCode = get(getConfig(), 'buildConfig.regionCode') as RegionCode;

  const swBadgeProps = {
    tagName: 'span',
    modAction: regionCode !== RegionCode.BE_WAL,
    modAccent: regionCode === RegionCode.BE_WAL,
    modSmall: true,
    style: {
      borderRadius: '50%',
      width: '3rem',
      height: '3rem',
      lineHeight: '3rem',
      textAlign: 'center',
      marginRight: '.5em',
    } as CSSProperties,
  };

  const tooltips = i18nKeys.prestations.search.alert.toolTip;

  const toolTipMessage = t(
    tooltips[status.toUpperCase() as keyof typeof tooltips],
  );

  const refTooltipOverview = useTooltip({
    value: toolTipMessage,
    placement: 'bottom',
  });

  return toolTipMessage && refTooltipOverview ? (
    <SwBadge {...swBadgeProps} ref={refTooltipOverview}>
      {count}
    </SwBadge>
  ) : (
    <SwBadge {...swBadgeProps}>{count}</SwBadge>
  );
};
