/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */

export type PickupWeekFrequency =
  | 'FIRST'
  | 'SECOND'
  | 'THIRD'
  | 'FOURTH'
  | 'LAST';

export const PickupWeekFrequency = {
  FIRST: 'FIRST' as PickupWeekFrequency,
  SECOND: 'SECOND' as PickupWeekFrequency,
  THIRD: 'THIRD' as PickupWeekFrequency,
  FOURTH: 'FOURTH' as PickupWeekFrequency,
  LAST: 'LAST' as PickupWeekFrequency,
};
