import {
  PickupConfigurationResponseData,
  PickupWeekFrequency,
} from '@kaa/api/providers';
import { useLuxon } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  Icon,
  SwBadge,
  SwFormColumn,
  SwFormGrid,
  SwFormMessageLabel,
  SwGrid,
  SwInputCalendarField,
  SwInputCalendarFieldFormatDate,
  SwInputCalendarFieldParseDate,
  SwSelect,
  useTooltip,
} from '@kaa/ui-flanders/components';
import { SwInfoTileDefault } from '@kaa/ui-flanders/components/info-tile/SwInfoTileDefault';
import { Field } from 'formik';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getRecurrentPickupInfo } from '../../PickupScreen.utils';
import {
  PickupCreateFormData,
  PickupFormFieldNames,
  PickupUpdateFormData,
  weekSelectionMethods,
} from '../PickupModals.constants';
import {
  getDisabledDates,
  maxEndDate,
  maxStartDate,
  minEndDate,
  minStartDate,
} from '../PickupModals.utils';
import { PickupRecurrentModalSelectDays } from './PickupRecurrentModalSelectDays';
import { PickupRecurrentModalSelectWeeks } from './PickupRecurrentModalSelectWeeks';

type PickupRecurrentModalProps = {
  configuration: PickupConfigurationResponseData;
  values: PickupCreateFormData | PickupUpdateFormData;
  setFieldValue: (field: string, value: any) => void;
  disableStartDate?: boolean;
};

export const PickupRecurrentModal = ({
  configuration: { disabledDates = [] },
  setFieldValue,
  values,
  disableStartDate = false,
}: PickupRecurrentModalProps) => {
  const { t } = useTranslation();
  const { DateTime } = useLuxon();

  const [weekSelectionMethod, setWeekSelectionMethod] = useState<
    weekSelectionMethods
  >(
    Object.values(PickupWeekFrequency).length === values.selectedWeeks.length
      ? weekSelectionMethods.EACH_WEEK
      : weekSelectionMethods.WEEK_SELECTION,
  );

  const refTooltipPickupInformation = useTooltip({
    value: t(i18nKeys.pickup.modal.add.form.pickUpTooltip),
    placement: 'right',
  });

  return (
    <SwFormGrid modStacked>
      <SwFormColumn width="12" style={{ marginBottom: 0 }}>
        <SwFormMessageLabel>
          {t(i18nKeys.pickup.modal.add.form.pickUpDate)}
          <SwBadge
            modAction
            modXsmall
            icon={Icon.INFO}
            ref={refTooltipPickupInformation}
            style={{
              marginLeft: '.5rem',
              verticalAlign: 'middle',
            }}
          />
        </SwFormMessageLabel>
      </SwFormColumn>
      <SwFormColumn width="6" widthS="12" style={{ marginTop: 0 }}>
        {disableStartDate ? (
          <Field
            column={{ width: '12' }}
            labelColumn={{ width: '12' }}
            name={PickupFormFieldNames.START_DATE}
            component={SwInputCalendarField}
            parse={SwInputCalendarFieldParseDate}
            format={SwInputCalendarFieldFormatDate}
            modDisabled
          />
        ) : (
          <Field
            column={{ width: '12' }}
            labelColumn={{ width: '12' }}
            name={PickupFormFieldNames.START_DATE}
            component={SwInputCalendarField}
            parse={SwInputCalendarFieldParseDate}
            format={SwInputCalendarFieldFormatDate}
            disabledDates={getDisabledDates(disabledDates)}
            minDate={minStartDate}
            maxDate={maxStartDate}
          />
        )}
      </SwFormColumn>
      <SwFormColumn width="6" widthS="12" style={{ marginTop: 0 }}>
        <Field
          column={{ width: '12' }}
          labelColumn={{ width: '12' }}
          name={PickupFormFieldNames.END_DATE}
          component={SwInputCalendarField}
          parse={SwInputCalendarFieldParseDate}
          format={SwInputCalendarFieldFormatDate}
          disabledDates={getDisabledDates(disabledDates)}
          minDate={minEndDate(
            DateTime.fromISO(values[PickupFormFieldNames.START_DATE]),
            disabledDates,
          )}
          maxDate={maxEndDate(
            DateTime.fromISO(values[PickupFormFieldNames.START_DATE]),
            disabledDates,
          )}
        />
      </SwFormColumn>
      <SwFormColumn width="12" style={{ marginBottom: 0 }}>
        <SwFormMessageLabel>
          {t(i18nKeys.pickup.modal.add.form.pickupOn)}
        </SwFormMessageLabel>
      </SwFormColumn>
      <SwFormColumn style={{ marginTop: 0 }}>
        <PickupRecurrentModalSelectDays values={values} />
      </SwFormColumn>
      <SwFormColumn width="6" widthS="12" className="vl-u-spacer--small">
        <SwSelect
          style={{ width: '100%' }}
          defaultValue={weekSelectionMethod}
          modNoPlaceholder
          onChange={(event: ChangeEvent<HTMLSelectElement>) => {
            setWeekSelectionMethod(event.target.value as weekSelectionMethods);

            if (event.target.value === weekSelectionMethods.EACH_WEEK) {
              setFieldValue(
                PickupFormFieldNames.SELECTED_WEEKS,
                Object.values(PickupWeekFrequency),
              );
            }
          }}
        >
          {Object.keys(weekSelectionMethods).map((method) => (
            <option value={method} key={method}>
              {t(
                i18nKeys.pickup.modal.add.form[
                  method as keyof typeof weekSelectionMethods
                ],
              )}
            </option>
          ))}
        </SwSelect>
      </SwFormColumn>
      {weekSelectionMethod === weekSelectionMethods.WEEK_SELECTION && (
        <SwFormColumn style={{ marginTop: 0 }}>
          <SwGrid style={{ width: '100%', justifyContent: 'flex-start' }}>
            <SwFormColumn width="0">
              <SwFormMessageLabel>
                {t(i18nKeys.pickup.modal.add.form.weeks.label)}
              </SwFormMessageLabel>
            </SwFormColumn>
            <PickupRecurrentModalSelectWeeks values={values} />
          </SwGrid>
        </SwFormColumn>
      )}
      {!!values[PickupFormFieldNames.SELECTED_DAYS].length && (
        <SwFormColumn>
          <SwInfoTileDefault
            tagName="div"
            title={t(i18nKeys.pickup.modal.add.form.summary.title)}
            subtitle={getRecurrentPickupInfo(values, t)}
            badge={<SwBadge modAccent icon={Icon.PICK_UP} />}
          />
        </SwFormColumn>
      )}
    </SwFormGrid>
  );
};
