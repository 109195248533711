/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */

export type VideoTopicReference =
  | 'PRESTATIONS'
  | 'WORKERS'
  | 'REMITTANCE'
  | 'ENTERPRISE'
  | 'PICKUP'
  | 'EXPORT'
  | 'WEBINAR';

export const VideoTopicReference = {
  PRESTATIONS: 'PRESTATIONS' as VideoTopicReference,
  WORKERS: 'WORKERS' as VideoTopicReference,
  REMITTANCE: 'REMITTANCE' as VideoTopicReference,
  ENTERPRISE: 'ENTERPRISE' as VideoTopicReference,
  PICKUP: 'PICKUP' as VideoTopicReference,
  EXPORT: 'EXPORT' as VideoTopicReference,
  WEBINAR: 'WEBINAR' as VideoTopicReference,
};
