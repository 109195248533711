import {
  Activity,
  Agreement,
  AgreementStatus,
  SubsidyReceivalModeCode,
  Title,
} from '@kaa/api/providers';
import { Format } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  Icon,
  SwBadge,
  SwDataTable,
  SwFormColumn,
  SwFormGrid,
  SwIcon,
  SwRadioField,
  SwTitle,
  useTooltip,
} from '@kaa/ui-flanders/components';
import { Field } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TableHead } from '../../../../../../components';
import { ContactPersonsForm } from '../../../../../../components/contact-persons/ContactPersonForm';
import { RelativeAddModalfieldNames } from '../RelativeAddModal.constants';
import { RelativeAddStepSpecificationsBankAccountField } from './RelativeAddStepSpecificationsBankAccountField';

type RelativeAddStepSpecificationsProps = {
  bankAccount: string;
  titles: Title[];
  agreements: Agreement[];
};

export const RelativeAddStepSpecifications = ({
  bankAccount,
  titles,
  agreements,
}: RelativeAddStepSpecificationsProps) => {
  const { t } = useTranslation();

  const tooltipIndividualRef = useTooltip({
    value: t(
      i18nKeys.relative.add.reimbursementType.individual.tooltip.message,
    ),
  });

  const tooltipGroupedRef = useTooltip({
    value: t(i18nKeys.relative.add.reimbursementType.grouped.tooltip.message),
  });

  const getPropsByAgreementsType = (activityType: Activity) => {
    switch (activityType) {
      case Activity.GROCERY_SHOPPING:
        return {
          icon: Icon.SHOPPING,
        };

      case Activity.HOUSEKEEPING:
        return {
          icon: Icon.VACCUM_CLEANER,
        };

      case Activity.DISABLED_PERSON_TRANSPORTATION:
        return {
          icon: Icon.TRANSPORT,
        };

      case Activity.IRONING:
        return {
          icon: Icon.IRONING,
        };

      default:
        return {
          icon: Icon.IRONING,
        };
    }
  };

  const head = [
    { text: '' },
    { text: t(i18nKeys.activityAgreement.details.status) },
    { text: t(i18nKeys.activityAgreement.details.issueDate) },
    { text: t(i18nKeys.activityAgreement.details.withdrawalDate) },
    { text: t(i18nKeys.activityAgreement.details.suspensionDate) },
  ];

  return (
    <>
      <SwFormColumn className="vl-u-spacer--medium">
        <SwTitle tagName="h5" className="vl-u-spacer">
          {t(i18nKeys.relative.add.bankAccount.title)}
        </SwTitle>
        <fieldset>
          <legend className="vl-form__label vl-u-spacer--xsmall">
            {t(i18nKeys.general.label.bankAccount)}
          </legend>

          <Field
            name={RelativeAddModalfieldNames.SUBSIDY_RECEIVAL_BANK_ACCOUNT}
            bankAccount={bankAccount}
            component={RelativeAddStepSpecificationsBankAccountField}
          />
        </fieldset>
        <fieldset>
          <legend className="vl-form__label vl-u-spacer--xsmall">
            {t(i18nKeys.relative.add.label.reimbursementChoice)}
          </legend>
          <Field
            name={RelativeAddModalfieldNames.SUBSIDY_RECEIVAL_MODE}
            id={SubsidyReceivalModeCode.ONE_RECEIVAL_PER_REMITTANCE}
            label={
              <>
                {t(i18nKeys.relative.add.reimbursementType.individual.label)}
                <span ref={tooltipIndividualRef}>
                  <SwIcon icon={Icon.INFO_CIRCLE} />
                </span>
              </>
            }
            component={SwRadioField}
          />
          <Field
            name={RelativeAddModalfieldNames.SUBSIDY_RECEIVAL_MODE}
            id={SubsidyReceivalModeCode.MONTHLY_RECEIVAL}
            label={
              <>
                {t(i18nKeys.relative.add.reimbursementType.grouped.label)}
                <span ref={tooltipGroupedRef}>
                  <SwIcon icon={Icon.INFO_CIRCLE} />
                </span>
              </>
            }
            component={SwRadioField}
            modSpacer
          />
        </fieldset>
      </SwFormColumn>

      <SwFormColumn className="vl-u-spacer--medium">
        <fieldset>
          <legend className="vl-title vl-title--h5 vl-u-spacer--xsmall">
            {t(i18nKeys.relative.add.contactPerson.title)}
          </legend>

          <SwFormGrid modStacked>
            <ContactPersonsForm
              titles={titles}
              fieldNamePrefix="mainContact"
              column={{ width: '12' }}
              labelColumn={{ width: '12' }}
            />
          </SwFormGrid>
        </fieldset>
      </SwFormColumn>

      <SwTitle tagName="h5" className="vl-u-spacer--xsmall">
        {t(i18nKeys.relative.add.activity.title)}
      </SwTitle>
      <div className="vl-u-table-overflow">
        <SwDataTable modCollapsedS>
          <TableHead elements={head} />
          <tbody>
            {agreements.map(
              ({
                activityType,
                status,
                issueDate,
                withdrawalDate,
                suspensionDate,
                resourceId,
              }) => (
                <tr key={resourceId}>
                  <td>
                    <SwBadge
                      modMedium
                      modSuccess={status === AgreementStatus.ACTIVE}
                      modError={status !== AgreementStatus.ACTIVE}
                      {...getPropsByAgreementsType(activityType)}
                    />
                  </td>
                  <td
                    data-title={t(i18nKeys.activityAgreement.details.status)}
                    style={{ verticalAlign: 'middle' }}
                  >
                    {status}
                  </td>

                  <td
                    data-title={t(i18nKeys.activityAgreement.details.issueDate)}
                    style={{ verticalAlign: 'middle' }}
                  >
                    {Format.date(issueDate) || '-'}
                  </td>

                  <td
                    data-title={t(
                      i18nKeys.activityAgreement.details.withdrawalDate,
                    )}
                    style={{ verticalAlign: 'middle' }}
                  >
                    {Format.date(withdrawalDate) || '-'}
                  </td>

                  <td
                    data-title={t(
                      i18nKeys.activityAgreement.details.suspensionDate,
                    )}
                    style={{ verticalAlign: 'middle' }}
                  >
                    {Format.date(suspensionDate) || '-'}
                  </td>
                </tr>
              ),
            )}
          </tbody>
        </SwDataTable>
      </div>
    </>
  );
};
