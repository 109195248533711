import { Pickup } from '@kaa/api/providers';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  Icon,
  SwDataTable,
  SwIcon,
  SwTitle,
} from '@kaa/ui-flanders/components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modals } from '../../../constants';
import { PickupDatesScheduledTableRow } from './PickupDatesScheduledTableRow';

type PickupDatesScheduledTable = {
  name: string;
  estimatedCost: number;
  opened: boolean;
  pickups: Pickup[];
  openModal: (modal: Modals, pickup?: Pickup | undefined) => void;
};

export const PickupDatesScheduledTable = ({
  name,
  estimatedCost,
  opened,
  pickups,
  openModal,
}: PickupDatesScheduledTable) => {
  const { t } = useTranslation();

  const [openTable, setOpenTable] = useState(opened);

  return (
    <div className="vl-u-table-overflow">
      <SwDataTable modLine className="vl-u-spacer">
        <thead>
          <tr className="vl-u-bg-alt" onClick={() => setOpenTable(!openTable)}>
            <th colSpan={5} style={{ position: 'relative' }}>
              <SwTitle
                tagName="h5"
                className="vl-u-float-left"
                style={{ textTransform: 'capitalize' }}
              >
                {name}
              </SwTitle>
              <SwIcon
                modLarge
                icon={Icon.ARROW_DOWN}
                style={
                  openTable
                    ? {
                        float: 'right',
                        display: 'block',
                        transition: 'ease transform .4s',
                        transform: 'rotate(180deg)',
                      }
                    : {
                        float: 'right',
                        display: 'block',
                        transition: 'ease transform .4s',
                        transform: 'rotate(0)',
                      }
                }
              />
              <div className="vl-u-float-right" style={{ margin: '.5rem' }}>
                <p
                  className="vl-u-text--small vl-u-text--italic"
                  style={{ fontWeight: 'normal' }}
                >
                  {t(i18nKeys.pickup.estimatedCost.month)}
                  <span className="vl-u-text--bold">{estimatedCost}€</span>
                </p>
              </div>
            </th>
          </tr>
        </thead>
        {openTable && (
          <tbody>
            {pickups.map((pickup: Pickup) => (
              <PickupDatesScheduledTableRow
                key={pickup.id}
                pickup={pickup}
                isOpen={openTable}
                openModal={openModal}
              />
            ))}
          </tbody>
        )}
      </SwDataTable>
    </div>
  );
};
