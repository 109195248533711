import { ServiceResponseData } from '@kaa/api/providers';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import React from 'react';
import { useTranslation } from 'react-i18next';

type PrestationContestedAlertProps = {
  prestation: ServiceResponseData;
};

export const PrestationContestedAlert = ({
  prestation,
}: PrestationContestedAlertProps) => {
  const { t } = useTranslation();

  const {
    activity,
    durationInHours,
    previousActivity,
    previousDurationInHours,
  } = prestation;

  const contestedActivity = previousActivity && previousActivity !== activity;

  const contestedDuration =
    previousDurationInHours && previousDurationInHours !== durationInHours;

  return (
    <>
      {contestedActivity && previousActivity && (
        <>
          {t(i18nKeys.prestations.modals.alerts.text.CONTESTED.activity)}{' '}
          {t(i18nKeys.general.services[activity])}{' '}
          <span style={{ textDecoration: 'line-through' }}>
            {t(i18nKeys.general.services[previousActivity])}
          </span>
          <br />
        </>
      )}
      {contestedDuration && (
        <>
          {t(i18nKeys.prestations.modals.alerts.text.CONTESTED.worktime)}{' '}
          {durationInHours}
          {t(i18nKeys.general.label.hourCode)}00{' '}
          <span style={{ textDecoration: 'line-through' }}>
            {previousDurationInHours}
            {t(i18nKeys.general.label.hourCode)}00
          </span>
        </>
      )}
    </>
  );
};
