import { httpTo } from '@kaa/api/providers/utilities';
import { useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  SwActionGroup,
  SwButton,
  SwLink,
  SwModalRenderProps,
  SwTitle,
  toggleModalById,
} from '@kaa/ui-flanders/components';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modals } from '../../../../constants';
import { useApi } from '../../../../utils';

type ResetPasswordModalProps = {
  providerId: string;
  workerId: number;
  setCredentials: (credentials: {
    login: string;
    password: string | undefined;
  }) => void;
};

export const ResetPasswordModal = ({
  providerId,
  workerId,
  setCredentials,
}: SwModalRenderProps<ResetPasswordModalProps>) => {
  const { t } = useTranslation();
  const { providers } = useApi();

  const [{ value: submitedReset }, submit] = useAsyncCallback(async () => {
    const [error, response] = await httpTo(
      providers.resetPasswordWorker(Number(providerId), workerId),
    );
    return { error, response };
  }, [providers, providerId, workerId]);

  useEffect(() => {
    if (submitedReset) {
      const { error, response } = submitedReset;

      if (error || !response) {
        return;
      }

      toggleModalById(Modals.WORKERS_RESET_PASSWORD_MODAL);

      setCredentials(response.data.data);
    }
  }, [submitedReset]);

  return (
    <>
      <SwTitle tagName="h2">
        {t(i18nKeys.workers.loginInformation.modal.resetPassword.title)}
      </SwTitle>
      <SwActionGroup style={{ marginTop: '3rem' }}>
        <SwButton modLarge onClick={submit}>
          {t(i18nKeys.workers.loginInformation.modal.resetPassword.cta)}
        </SwButton>
        <SwLink
          modLarge
          onClick={() => toggleModalById(Modals.WORKERS_RESET_PASSWORD_MODAL)}
        >
          {t(i18nKeys.general.cta.cancel)}
        </SwLink>
      </SwActionGroup>
    </>
  );
};
