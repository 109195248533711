import { Relative } from '@kaa/api/providers';
import { useLuxon } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AlertType,
  getAlertPropsByType,
  SwAlert,
  SwColumn,
  SwFormMessageLabel,
  SwGrid,
} from '@kaa/ui-flanders/components';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modals } from '../../../../constants';
import { sendConfirmationModalViewEvent } from '../../../../utils/google-analytics';
import { PrestationFormData } from '../form/PrestationForm.types';

type PrestationCreationModalConfirmationProps = {
  work: PrestationFormData;
  relatives: Relative[];
};

const valueStyle = {
  marginRight: '2rem',
  minWidth: '40%',
};

export const PrestationCreationModalConfirmation = ({
  work,
  relatives,
}: PrestationCreationModalConfirmationProps) => {
  const { t } = useTranslation();
  useEffect(() => {
    sendConfirmationModalViewEvent(Modals.PRESTATIONS_ADD_PRESTATION_MODAL);
  }, []);
  const relative = relatives.find(
    (relative) => String(relative.id) === work.providerId,
  );

  const { DateTime } = useLuxon();
  return (
    <>
      <SwAlert
        {...getAlertPropsByType(AlertType.SUCCESS)}
        title={t(i18nKeys.general.label.success)}
        className="vl-u-spacer"
      >
        {t(i18nKeys.prestations.modals.add.confirmation.message)}
      </SwAlert>
      <SwGrid>
        {relative && (
          <>
            <SwColumn width="5" widthS="12" className="vl-u-spacer--small">
              <SwFormMessageLabel tagName="span">
                {t(i18nKeys.general.exploitationName)}
              </SwFormMessageLabel>
            </SwColumn>
            <SwColumn width="7" widthS="12" className="vl-u-display-flex">
              <span style={valueStyle}>
                {relative.name} - {work.providerId}
              </span>
            </SwColumn>
          </>
        )}
        <SwColumn width="5" widthS="12" className="vl-u-spacer--small">
          <SwFormMessageLabel tagName="span">
            {t(i18nKeys.prestations.search.form.labels.prestationDate)}
          </SwFormMessageLabel>
        </SwColumn>
        <SwColumn width="7" widthS="12" className="vl-u-display-flex">
          <span style={valueStyle}>
            {DateTime.fromISO(work.serviceDate).toFormat('dd/LL/yyyy')}
          </span>
        </SwColumn>

        <SwColumn width="5" widthS="12" className="vl-u-spacer--small">
          <SwFormMessageLabel tagName="span">
            {t(i18nKeys.general.label.customerName)}
          </SwFormMessageLabel>
        </SwColumn>
        <SwColumn width="7" widthS="12" className="vl-u-display-flex">
          <span style={valueStyle}>{work.customerFullname}</span>
        </SwColumn>

        <SwColumn width="5" widthS="12" className="vl-u-spacer--small">
          <SwFormMessageLabel tagName="span">
            {t(i18nKeys.general.label.workerName)}
          </SwFormMessageLabel>
        </SwColumn>
        <SwColumn width="7" widthS="12" className="vl-u-display-flex">
          <span style={valueStyle}>{work.workerFullname}</span>
        </SwColumn>

        <SwColumn width="5" widthS="12" className="vl-u-spacer--small">
          <SwFormMessageLabel tagName="span">
            {t(i18nKeys.general.label.activity)}
          </SwFormMessageLabel>
        </SwColumn>
        <SwColumn width="7" widthS="12" className="vl-u-display-flex">
          <span style={valueStyle}>
            {t(i18nKeys.general.activityType[work.activity])}
          </span>
        </SwColumn>

        <SwColumn width="5" widthS="12" className="vl-u-spacer--small">
          <SwFormMessageLabel tagName="span">
            {t(i18nKeys.general.label.time)}
          </SwFormMessageLabel>
        </SwColumn>
        <SwColumn width="7" widthS="12" className="vl-u-display-flex">
          <span style={valueStyle}>
            {work.durationInHours}
            {t(i18nKeys.general.label.hourCode)}
          </span>
        </SwColumn>
      </SwGrid>
    </>
  );
};
