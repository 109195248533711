/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */

export type RemittanceFrequency = 'NONE' | 'DAILY' | 'WEEKLY' | 'MONTHLY';

export const RemittanceFrequency = {
  NONE: 'NONE' as RemittanceFrequency,
  DAILY: 'DAILY' as RemittanceFrequency,
  WEEKLY: 'WEEKLY' as RemittanceFrequency,
  MONTHLY: 'MONTHLY' as RemittanceFrequency,
};
