import { i18nKeys } from '@kaa/i18n/providers/keys';
import React, { PropsWithChildren, TdHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

type TableRowNoResultProps = TdHTMLAttributes<HTMLTableCellElement> & {
  title?: string;
};

export const TableRowNoResult = ({
  title = i18nKeys.general.noResults,
  children,
  ...props
}: PropsWithChildren<TableRowNoResultProps>) => {
  const { t } = useTranslation();
  return (
    <tr>
      <td className="vl-u-align-center" {...props}>
        {children || t(title)}
      </td>
    </tr>
  );
};
