import { ProviderType } from '@kaa/api/providers';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import { SwColumn, SwContainer, SwGrid } from '@kaa/ui-flanders/components';
import { RouteComponentProps } from '@reach/router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '../../components';
import { useSelectedProviderState } from '../../utils';
import { PickupBilling } from './billing/PickupBilling';

export const PickupBillingScreen = (props: RouteComponentProps) => {
  const { t } = useTranslation();
  const provider = useSelectedProviderState();

  if (provider.type !== ProviderType.HEADQUARTER) {
    return (
      <SwContainer>
        <SwGrid modStacked>
          <SwColumn>
            <PageHeader
              title={t(i18nKeys.navigation.pickupBilling)}
              introduction={t(i18nKeys.pickup.billing.accessDenied)}
            />
          </SwColumn>
        </SwGrid>
      </SwContainer>
    );
  }
  return <PickupBilling {...props} />;
};
