/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */

export type ReimbursementRefDateReference = 'MONTH_OF_ISSUE' | 'MONTH_OF_WORK';

export const ReimbursementRefDateReference = {
  MONTH_OF_ISSUE: 'MONTH_OF_ISSUE' as ReimbursementRefDateReference,
  MONTH_OF_WORK: 'MONTH_OF_WORK' as ReimbursementRefDateReference,
};
