import faker from 'faker';
import { Title, TitleCode, TitlesResponse } from '../model';
import { getArray } from './utils';

export const getTitlesResponse = ({
  data,
}: Partial<TitlesResponse> = {}): TitlesResponse => {
  return { data: getTitles(data) };
};

export const getTitles = (
  titles: Array<Partial<Title>> = getArray(3),
): Title[] => {
  return titles
    .map(getTitle)
    .reduce<Title[]>(
      (acc, title) =>
        acc.some(({ code }) => code === title.code) ? acc : [...acc, title],
      [],
    );
};

export const getTitle = (title: Partial<Title> = {}): Title => {
  const code = faker.helpers.randomize(Object.values(TitleCode));
  return {
    code,
    name: code,
    ...title,
  };
};
