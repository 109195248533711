import {
  Activity,
  Author,
  CustomersResponseDataItem,
  Relative,
  ServiceComment,
  WorkerSummary,
} from '@kaa/api/providers';
import { useLuxon } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AutocompleteInputField,
  SwColumn,
  SwFormColumn,
  SwFormGrid,
  SwInputCalendarField,
  SwInputTextField,
  SwLoader,
  SwSelectField,
  SwTextAreaField,
  SwTitle,
} from '@kaa/ui-flanders/components';
import { Field, FieldProps } from 'formik';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { PrestationFormFieldNames } from './PrestationForm.constant';
import {
  getAutocompleteCustomersOption,
  getAutocompleteWorkersOption,
  menuWorkerOptionFormat,
  onSelectWorkerOption,
} from './PrestationForm.utils';

type PrestationFormProps = {
  relatives: Relative[];
  getWorkers?: (
    query: string,
    searchedProviderId: string,
  ) => Promise<[Error | undefined, WorkerSummary[] | undefined]>;
  getCustomers?: (
    query: string,
  ) => Promise<[Error | undefined, CustomersResponseDataItem[] | undefined]>;
  modWithoutComment?: boolean;
  modDisabled?:
    | boolean
    | Partial<{ [key in PrestationFormFieldNames]: boolean }>;
  comments?: ServiceComment[];
  customerFullname?: string;
  searchedProviderId?: string;
  customersLoading?: boolean;
  workersLoading?: boolean;
};

export const PrestationForm = ({
  relatives,
  getWorkers,
  getCustomers,
  modWithoutComment,
  modDisabled,
  comments,
  customerFullname = '',
  searchedProviderId = '',
  customersLoading = false,
  workersLoading = false,
}: PrestationFormProps) => {
  const { t } = useTranslation();

  const relativeOptions = relatives.map(({ id, name }) => ({
    value: id,
    text: `${name} - ${id}`,
  }));

  const activityOptions = Object.values(Activity).map((aggrement) => {
    return {
      value: aggrement,
      text: t(i18nKeys.general.services[aggrement]),
    };
  });

  const durationOptions = Array.from(Array(9).keys()).map((hour) => {
    return {
      value: hour + 1,
      text: `${hour + 1}${t(i18nKeys.general.label.hourCode)}`,
    };
  });

  const isDisabled = (field: PrestationFormFieldNames) =>
    typeof modDisabled === 'boolean' ? modDisabled : modDisabled?.[field];

  const { DateTime } = useLuxon();

  return (
    <>
      <SwFormGrid modStacked>
        <SwFormColumn width="6" widthS="12">
          <Field
            column={{ width: '12' }}
            labelColumn={{ width: '12' }}
            name={PrestationFormFieldNames.PROVIDER_ID}
            label={t(i18nKeys.general.exploitationName)}
            component={SwSelectField}
            options={relativeOptions}
            modDisabled={isDisabled(PrestationFormFieldNames.PROVIDER_ID)}
            modNoPlaceholder
            inputOnChange={(
              event: ChangeEvent<HTMLInputElement>,
              field: FieldProps['field'],
              form: FieldProps['form'],
            ) => {
              form.setFieldValue(PrestationFormFieldNames.WORKER_NISS, '');
              form.setFieldValue(PrestationFormFieldNames.WORKER_NAME, '');
            }}
          />
        </SwFormColumn>
        <SwFormColumn width="6" widthS="12">
          <Field
            label={t(i18nKeys.prestations.search.form.labels.prestationDate)}
            labelColumn={{ width: '12' }}
            name={PrestationFormFieldNames.SERVICE_DATE}
            column={{ width: '12' }}
            visualFormat="d/m/Y"
            component={SwInputCalendarField}
            format={(date: string) =>
              date ? [DateTime.fromISO(date).toJSDate()] : undefined
            }
            parse={(dates: Date[]) =>
              dates?.length
                ? DateTime.fromJSDate(dates[0])
                    .startOf('day')
                    .toISODate()
                : undefined
            }
            {...(!isDisabled(PrestationFormFieldNames.SERVICE_DATE)
              ? {
                  minDate: DateTime.local()
                    .minus({ month: 12 })
                    .toJSDate(),
                  maxDate: DateTime.local().toJSDate(),
                  defaultDate: DateTime.local().toJSDate(),
                }
              : { modDisabled: true })}
          />
        </SwFormColumn>
        <SwFormColumn width="6" widthS="12">
          <Field
            id={PrestationFormFieldNames.CUSTOMER_NUMBER}
            label={t(i18nKeys.general.label.customerNumber)}
            name={PrestationFormFieldNames.CUSTOMER_NUMBER}
            column={{ width: '12' }}
            labelColumn={{ width: '12' }}
            autoComplete="off"
            getOptions={getAutocompleteCustomersOption(getCustomers)}
            minLength={12}
            maxLength={12}
            component={AutocompleteInputField}
            modDisabled={isDisabled(PrestationFormFieldNames.CUSTOMER_NUMBER)}
            modWithoutDropdown
          />
        </SwFormColumn>
        <SwFormColumn width="6" widthS="12">
          <Field
            modDisabled
            label={t(i18nKeys.general.label.customerName)}
            name={PrestationFormFieldNames.CUSTOMER_NAME}
            column={{ width: '12' }}
            labelColumn={{ width: '12' }}
            component={SwInputTextField}
          >
            {customersLoading && <SwLoader modMessageHidden />}
          </Field>
        </SwFormColumn>
        <SwFormColumn width="6" widthS="12">
          <Field
            id={PrestationFormFieldNames.WORKER_NISS}
            label={t(i18nKeys.general.label.workerNissRef)}
            name={PrestationFormFieldNames.WORKER_NISS}
            column={{ width: '12' }}
            labelColumn={{ width: '12' }}
            autoComplete="off"
            getOptions={getAutocompleteWorkersOption(
              searchedProviderId,
              getWorkers,
            )}
            menuOptionFormat={menuWorkerOptionFormat}
            onSelectOption={onSelectWorkerOption()}
            minLength={2}
            maxLength={11}
            component={AutocompleteInputField}
            modDisabled={isDisabled(PrestationFormFieldNames.WORKER_NISS)}
          />
        </SwFormColumn>
        <SwFormColumn width="6" widthS="12">
          <Field
            modDisabled
            label={t(i18nKeys.general.label.workerName)}
            name={PrestationFormFieldNames.WORKER_NAME}
            column={{ width: '12' }}
            labelColumn={{ width: '12' }}
            component={SwInputTextField}
          >
            {workersLoading && <SwLoader modMessageHidden />}
          </Field>
        </SwFormColumn>
        <SwFormColumn width="6" widthS="12">
          <Field
            column={{ width: '12' }}
            labelColumn={{ width: '12' }}
            name={PrestationFormFieldNames.ACTIVITY}
            label={t(i18nKeys.general.label.activity)}
            component={SwSelectField}
            options={activityOptions}
            modDisabled={isDisabled(PrestationFormFieldNames.ACTIVITY)}
          />
        </SwFormColumn>
        <SwFormColumn width="6" widthS="12">
          <Field
            column={{ width: '12' }}
            labelColumn={{ width: '12' }}
            name={PrestationFormFieldNames.DURATION}
            label={t(i18nKeys.general.label.time)}
            component={SwSelectField}
            options={durationOptions}
            modDisabled={isDisabled(PrestationFormFieldNames.DURATION)}
          />
        </SwFormColumn>
        {comments && (
          <SwColumn>
            <hr className="vl-u-hr vl-u-spacer" />
            <SwTitle tagName="h3" tagStyle="h4" className="vl-u-spacer--small">
              {t(i18nKeys.prestations.modals.comments.title, {
                count: comments ? comments.length : 0,
              })}
            </SwTitle>
            {comments.map((comment) => {
              const isCustomer = comment.author === Author.CUSTOMER;
              return (
                <div key={comment.publicationTimestamp}>
                  <SwColumn
                    width="12"
                    className={`${
                      !isCustomer ? 'vl-u-align-right' : ''
                    } vl-u-spacer`}
                  >
                    {DateTime.fromISO(comment.publicationTimestamp).toFormat(
                      'dd/LL/yyyy - HH:mm',
                    )}
                    <br />
                    <strong>
                      {isCustomer
                        ? customerFullname
                        : t(i18nKeys.prestations.modals.you)}
                    </strong>{' '}
                    {isCustomer
                      ? t(i18nKeys.prestations.modals.suggestModifications)
                      : t(i18nKeys.prestations.modals.bringsModifications)}
                  </SwColumn>
                  <SwColumn
                    key={comment.publicationTimestamp}
                    width="9"
                    push={!isCustomer ? '3' : '0'}
                    className={`${
                      !isCustomer ? 'vl-u-align-right' : ''
                    } vl-u-spacer`}
                  >
                    <div
                      className="vl-u-bg-alt vl-u-align-left vl-discussionItem"
                      style={{
                        padding: '1.5rem',
                        backgroundColor: !isCustomer
                          ? 'rgba(0, 85, 204, .1)'
                          : '',
                      }}
                    >
                      {comment.text}
                    </div>
                  </SwColumn>
                </div>
              );
            })}
          </SwColumn>
        )}
        {!modWithoutComment && (
          <SwFormColumn width="12">
            <Field
              rows="4"
              name={PrestationFormFieldNames.COMMENT}
              label={t(i18nKeys.general.label.enterpriseComment)}
              column={{ width: '12' }}
              style={{ resize: 'none' }}
              component={SwTextAreaField}
              maxLength="255"
              modDisabled={isDisabled(PrestationFormFieldNames.COMMENT)}
            />
          </SwFormColumn>
        )}
      </SwFormGrid>
    </>
  );
};
