import {
  Address,
  PickupConfigurationResponseData,
  PickupType,
  PickupWeekFrequency,
  PickupWithAddress,
  Relative,
} from '@kaa/api/providers/model';
import { notIf, required } from '@kaa/common/validation';
import { DateTime } from 'luxon';
import { PickupFormFieldNames } from './PickupModals.constants';

export const getAddressLabel = (address: Address): string =>
  `${address.street}, ${address.houseNumber} - ${address.postcode} ${address.city}`;

export const getAddressValue = (address: Address): string =>
  JSON.stringify(address);

export const getRelativesOptions = (relatives: Relative[]) =>
  relatives.map(({ id, name }) => ({
    value: id,
    text: `${name} - ${id}`,
  }));

export const getFirstDateFromAvailable = (
  date: DateTime,
  disabledDates?: string[],
): DateTime =>
  disabledDates &&
  disabledDates.map((e) => e.split('T')[0]).includes(date.toISODate())
    ? getFirstDateFromAvailable(
        date.plus({ day: 1 }).startOf('day'),
        disabledDates,
      )
    : date;

export const getDisabledDates = (dates: string[]): Date[] =>
  dates.map((date) =>
    DateTime.fromISO(date)
      .startOf('day')
      .toJSDate(),
  );

export const minStartDate = DateTime.fromJSDate(new Date())
  .plus({ day: 2 })
  .startOf('day')
  .toJSDate();

export const maxStartDate = DateTime.fromJSDate(minStartDate)
  .plus({ months: 11 })
  .startOf('day')
  .toJSDate();

export const minEndDate = (
  startDate = DateTime.fromJSDate(minStartDate),
  disabledDates?: string[],
) =>
  getFirstDateFromAvailable(
    startDate.plus({ months: 1 }).startOf('day'),
    disabledDates,
  ).toJSDate();

export const maxEndDate = (
  startDate = DateTime.fromJSDate(minStartDate),
  disabledDates?: string[],
) =>
  getFirstDateFromAvailable(
    startDate.plus({ months: 12 }).startOf('day'),
    disabledDates,
  ).toJSDate();

// TODO: make something global
export const getNotIfValidations = (
  field: PickupFormFieldNames,
  values: object,
) =>
  Object.entries(values).reduce(
    (acc, [key, validators]) => ({
      ...acc,
      [key]: notIf(field, validators),
    }),
    {},
  );

export const getPickupCreateInitialValues = (
  providerId: number,
  {
    mainPickupAddress: { address },
    disabledDates,
  }: PickupConfigurationResponseData,
) => ({
  [PickupFormFieldNames.TYPE]: PickupType.STANDALONE,
  [PickupFormFieldNames.RELATIVE_ID]: providerId,
  [PickupFormFieldNames.ADDRESS]: getAddressValue(address),
  [PickupFormFieldNames.COMMENT]: '',
  [PickupFormFieldNames.DATE]: '',
  [PickupFormFieldNames.START_DATE]: getFirstDateFromAvailable(
    DateTime.fromJSDate(minStartDate),
    disabledDates,
  ).toISODate(),
  [PickupFormFieldNames.END_DATE]: getFirstDateFromAvailable(
    DateTime.fromJSDate(minStartDate).plus({ months: 2 }),
    disabledDates,
  ).toISODate(),
  [PickupFormFieldNames.SELECTED_DAYS]: [],
  [PickupFormFieldNames.SELECTED_WEEKS]: Object.values(PickupWeekFrequency),
});

export const getPickupUpdateInitialValues = ({
  id,
  type,
  providerId,
  address,
  comment,
  date,
  recurrenceStartDate,
  recurrenceEndDate,
  selectedDays,
  selectedWeeks,
}: PickupWithAddress) => ({
  [PickupFormFieldNames.ID]: id,
  [PickupFormFieldNames.TYPE]: type,
  [PickupFormFieldNames.INITIAL_DATE]: date,
  [PickupFormFieldNames.RELATIVE_ID]: providerId,
  [PickupFormFieldNames.ADDRESS]: getAddressValue(address),
  [PickupFormFieldNames.COMMENT]: comment || '',
  [PickupFormFieldNames.DATE]: date || '',
  [PickupFormFieldNames.START_DATE]: recurrenceStartDate || '',
  [PickupFormFieldNames.END_DATE]: recurrenceEndDate || '',
  [PickupFormFieldNames.SELECTED_DAYS]: selectedDays,
  [PickupFormFieldNames.SELECTED_WEEKS]: selectedWeeks,
});

export const pickupValidation = {
  [PickupFormFieldNames.RELATIVE_ID]: [required],
  [PickupFormFieldNames.ADDRESS]: [required],
  [PickupFormFieldNames.TYPE]: [required],
  [PickupFormFieldNames.COMMENT]: [required],
};

export const standalonePickupValidation = {
  [PickupFormFieldNames.DATE]: [required],
};

export const recurrentPickupValidation = {
  [PickupFormFieldNames.START_DATE]: [required],
  [PickupFormFieldNames.END_DATE]: [required],
  [PickupFormFieldNames.SELECTED_DAYS]: [required],
  [PickupFormFieldNames.SELECTED_WEEKS]: [required],
};
