import { i18nKeys } from '@kaa/i18n/providers/keys';
import { BreadcrumbItem } from '../../../common/types';
import { Routes, ROUTES } from '../../routes';

// TODO testId
export const BREADCRUMB_ITEMS_DASHBOARD: Array<BreadcrumbItem<Routes>> = [
  {
    id: Routes.DASHBOARD,
    label: i18nKeys.navigation.dashboard,
    base: true,
    testid: ROUTES[Routes.DASHBOARD].title,
    popover: {
      testid: ROUTES[Routes.DASHBOARD].title,
      links: [
        {
          id: Routes.REMITTANCES,
          label: i18nKeys.navigation.remittances,
          testid: ROUTES[Routes.REMITTANCES].title,
        },
        {
          id: Routes.PRESTATIONS,
          label: i18nKeys.navigation.prestations,
          testid: ROUTES[Routes.PRESTATIONS].title,
        },
        {
          id: Routes.WORKERS,
          label: i18nKeys.navigation.workers,
          testid: ROUTES[Routes.WORKERS].title,
        },
        {
          id: Routes.PICKUP,
          label: i18nKeys.navigation.pickup,
          testid: ROUTES[Routes.PICKUP].title,
        },
        {
          id: Routes.ENTERPRISE,
          label: i18nKeys.navigation.enterprise,
          testid: ROUTES[Routes.ENTERPRISE].title,
        },
      ],
    },
  },
];

export const BREADCRUMB_ITEMS_REMITTANCES: Array<BreadcrumbItem<Routes>> = [
  ...BREADCRUMB_ITEMS_DASHBOARD,
  {
    id: Routes.REMITTANCES,
    label: i18nKeys.navigation.remittances,
    base: true,
    testid: ROUTES[Routes.REMITTANCES].title,
  },
];

export const BREADCRUMB_ITEMS_PRESTATIONS: Array<BreadcrumbItem<Routes>> = [
  ...BREADCRUMB_ITEMS_DASHBOARD,
  {
    id: Routes.PRESTATIONS,
    label: i18nKeys.navigation.prestations,
    base: true,
    testid: ROUTES[Routes.PRESTATIONS].title,
    popover: {
      testid: ROUTES[Routes.PRESTATIONS].title,
      links: [
        {
          id: Routes.PRESTATIONS_HISTORY,
          label: i18nKeys.navigation.prestationsHistory,
          testid: ROUTES[Routes.PRESTATIONS_HISTORY].title,
        },
      ],
    },
  },
];

export const BREADCRUMB_ITEMS_WORKERS: Array<BreadcrumbItem<Routes>> = [
  ...BREADCRUMB_ITEMS_DASHBOARD,
  {
    id: Routes.WORKERS,
    label: i18nKeys.navigation.workers,
    base: true,
    testid: ROUTES[Routes.WORKERS].title,
  },
];

export const BREADCRUMB_ITEMS_WORKERS_DETAILS: Array<BreadcrumbItem<Routes>> = [
  ...BREADCRUMB_ITEMS_WORKERS,
  {
    id: Routes.WORKERS_DETAILS,
    label: i18nKeys.navigation.contactPersonDetails, // TODO
    base: true,
    testid: ROUTES[Routes.WORKERS_DETAILS].title,
  },
];

export const BREADCRUMB_ITEMS_PICKUP: Array<BreadcrumbItem<Routes>> = [
  ...BREADCRUMB_ITEMS_DASHBOARD,
  {
    id: Routes.PICKUP,
    label: i18nKeys.navigation.pickup,
    base: true,
    testid: ROUTES[Routes.PICKUP].title,
    popover: {
      testid: ROUTES[Routes.PICKUP].title,
      links: [
        {
          id: Routes.PICKUP_BILLING,
          label: i18nKeys.navigation.pickupBilling,
          testid: ROUTES[Routes.PICKUP_BILLING].title,
        },
      ],
    },
  },
];

export const BREADCRUMB_ITEMS_ENTERPRISE: Array<BreadcrumbItem<Routes>> = [
  ...BREADCRUMB_ITEMS_DASHBOARD,
  {
    id: Routes.ENTERPRISE,
    label: i18nKeys.navigation.enterprise,
    base: true,
    testid: ROUTES[Routes.ENTERPRISE].title,
    popover: {
      testid: ROUTES[Routes.ENTERPRISE].title,
      links: [
        {
          id: Routes.ENTERPRISE_ACTIVITIES,
          label: i18nKeys.navigation.enterpriseActivities,
          testid: ROUTES[Routes.ENTERPRISE_ACTIVITIES].title,
        },
      ],
    },
  },
];

export const BREADCRUMB_ITEMS_ENTERPRISE_DETAILS: Array<BreadcrumbItem<
  Routes
>> = [
  ...BREADCRUMB_ITEMS_ENTERPRISE,
  {
    id: Routes.ENTERPRISE_DETAILS,
    label: i18nKeys.navigation.enterpriseDetails,
    base: true,
    testid: ROUTES[Routes.ENTERPRISE_DETAILS].title,
    popover: {
      testid: ROUTES[Routes.ENTERPRISE_DETAILS].title,
      links: [
        {
          id: Routes.ENTERPRISE_DETAILS_CONTACT_PERSON,
          label: i18nKeys.navigation.contactPerson,
          testid: ROUTES[Routes.ENTERPRISE_DETAILS_CONTACT_PERSON].title,
        },
        {
          id: Routes.ENTERPRISE_DETAILS_VISIBILITY,
          label: i18nKeys.navigation.enterpriseDetailsVisibility,
          testid: ROUTES[Routes.ENTERPRISE_DETAILS_VISIBILITY].title,
        },
      ],
    },
  },
];

export const BREADCRUMB_ITEMS_ENTERPRISE_CONTACT_DETAILS: Array<BreadcrumbItem<
  Routes
>> = [
  ...BREADCRUMB_ITEMS_ENTERPRISE_DETAILS,
  {
    id: Routes.ENTERPRISE_DETAILS_CONTACT_PERSON_DETAILS,
    label: i18nKeys.navigation.contactPersonDetails,
    base: true,
    testid: ROUTES[Routes.ENTERPRISE_DETAILS_CONTACT_PERSON_DETAILS].title,
  },
];

const BREADCRUMB_ITEMS_LANDING = [
  {
    id: Routes.LANDING,
    base: true,
    label: ROUTES[Routes.LANDING].title,
  },
];

export const BREADCRUMB_ITEMS: Partial<
  { [key in Routes]: Array<BreadcrumbItem<Routes>> }
> = {
  [Routes.LANDING]: BREADCRUMB_ITEMS_LANDING,
  [Routes.DASHBOARD]: BREADCRUMB_ITEMS_DASHBOARD,
  [Routes.REMITTANCES]: BREADCRUMB_ITEMS_REMITTANCES,
  [Routes.PRESTATIONS]: BREADCRUMB_ITEMS_PRESTATIONS,
  [Routes.PRESTATIONS_HISTORY]: BREADCRUMB_ITEMS_PRESTATIONS,
  [Routes.WORKERS]: BREADCRUMB_ITEMS_WORKERS,
  [Routes.WORKERS_DETAILS]: BREADCRUMB_ITEMS_WORKERS,
  [Routes.PICKUP]: BREADCRUMB_ITEMS_PICKUP,
  [Routes.PICKUP_BILLING]: BREADCRUMB_ITEMS_PICKUP,
  [Routes.ENTERPRISE]: BREADCRUMB_ITEMS_ENTERPRISE,
  [Routes.ENTERPRISE_ACTIVITIES]: BREADCRUMB_ITEMS_ENTERPRISE,
  [Routes.ENTERPRISE_DETAILS]: BREADCRUMB_ITEMS_ENTERPRISE_DETAILS,
  [Routes.ENTERPRISE_DETAILS_CONTACT_PERSON]: BREADCRUMB_ITEMS_ENTERPRISE_DETAILS,
  [Routes.ENTERPRISE_DETAILS_CONTACT_PERSON_DETAILS]: BREADCRUMB_ITEMS_ENTERPRISE_CONTACT_DETAILS,
  [Routes.ENTERPRISE_DETAILS_VISIBILITY]: BREADCRUMB_ITEMS_ENTERPRISE_DETAILS,
};
