import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { ApiMode } from '../common/constants';
import { BFFProvidersApi, getBFFProvidersApi } from './providers';
import { getProvidersMock } from './providers.mock';
import { getHTTPInstance } from './utilities';

export interface Api {
  providers: BFFProvidersApi;
}

interface ApiByMode {
  mock: () => Api;
  api: (instance: AxiosInstance) => Api;
}

interface ApiParameters {
  headers?: { [key: string]: string | undefined };
  interceptor?: {
    request?: {
      onFulfilled?: (
        value: AxiosRequestConfig,
      ) => AxiosRequestConfig | Promise<AxiosRequestConfig>;
      onRejected?: (error: any) => any;
    };
    response?: {
      onFulfilled?: (
        value: AxiosResponse,
      ) => AxiosResponse | Promise<AxiosResponse>;
      onRejected?: (error: any) => any;
    };
  };
  baseUrl?: string;
  mode?: ApiMode;
}

const apiByMode: ApiByMode = {
  mock: () => ({
    providers: getProvidersMock(),
  }),
  api: (instance) => ({
    providers: getBFFProvidersApi(instance),
  }),
};

/**
 * It returns an Api object that is configured according to the ApiMode
 * @param {ApiParameters}  - headers: The headers to be used in the request.
 * @returns An Api object.
 */
export const getApi = ({
  headers = {},
  interceptor,
  baseUrl,
  mode = ApiMode.API,
}: ApiParameters): Api => {
  const instance = getHTTPInstance(baseUrl, headers, interceptor);

  return apiByMode[mode](instance);
};
