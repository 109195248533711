import {
  GetWorkerServicesParams,
  Provider,
  Service,
  Sorting,
  WorkersServicesResponseDataDataWorkersItem,
} from '@kaa/api/providers';
import { ServiceUpdateResponseDataData } from '@kaa/api/providers/model/serviceUpdateResponseDataData';
import { ActionType, useActionDispatch } from '@kaa/common/context';
import { useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  SwDataTable,
  SwFetchErrorMessage,
  SwLink,
  SwLoader,
} from '@kaa/ui-flanders/components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableRowNoResult } from '../../../components';
import { Modals } from '../../../constants';
import { useApi } from '../../../utils';
import {
  EventCategory,
  openModalWithPageView,
} from '../../../utils/google-analytics';
import { PrestationsModals } from '../components/PrestationsModals';
import {
  PrestationsTableHead,
  PrestationsTableHeadElement,
} from '../components/PrestationsTableHead';
import { PrestationsTableRow } from '../components/PrestationsTableRow';
import { ValidateStatus } from '../PrestationsScreen.types';
import { PrestationsSearchTableRowStatus } from './components/PrestationsSearchTableRowStatus';

const PRESTATIONS_SEARCH_SERVICES_PAGE_SIZE = 50;

type PrestationsSearchWorkersWorkerTableProps = {
  provider: Provider;
  worker: WorkersServicesResponseDataDataWorkersItem;
  parameters: GetWorkerServicesParams;
  setValidateStatus: (
    value: React.SetStateAction<ValidateStatus | undefined>,
  ) => void;
  onSuccess: (props: ServiceUpdateResponseDataData) => void;
};

export const PrestationsSearchWorkersWorkerTable = ({
  provider,
  worker,
  parameters: initialParameters,
  setValidateStatus,
  onSuccess,
}: PrestationsSearchWorkersWorkerTableProps) => {
  const { t } = useTranslation();
  const { providers } = useApi();

  const dispatchAction = useActionDispatch();

  const [parameters, setParameters] = useState<GetWorkerServicesParams>(
    initialParameters,
  );

  const [services, setServices] = useState<Service[]>([]);
  const [selectedService, setSelectedService] = useState<Service>();
  const [selectedPage, setSelectedPage] = useState(1);

  const providerId = Number(parameters?.searchedProviderId || provider.id);

  const [
    { value: servicesResponse, loading: servicesLoading, error: servicesError },
    getServices,
  ] = useAsyncCallback(
    async (servicesParams: GetWorkerServicesParams) =>
      (
        await providers.getWorkerServices(
          providerId,
          Number(worker.workerId),
          servicesParams,
        )
      ).data,
    [providers],
    { loading: true },
  );

  useEffect(() => {
    getServices(parameters);
    setSelectedPage(() => 1);
  }, [getServices, parameters]);

  useEffect(() => {
    if (servicesResponse) {
      const { data, actions } = servicesResponse;

      if (actions) {
        dispatchAction({
          type: ActionType.ADD,
          payload: actions,
        });
      }

      setServices(() => data);
    }
  }, [servicesResponse]);

  if (servicesLoading) {
    return (
      <SwDataTable modLine>
        <tbody>
          <tr>
            <td>
              <SwLoader />
            </td>
          </tr>
        </tbody>
      </SwDataTable>
    );
  }

  if (servicesError || !servicesResponse) {
    return (
      <SwDataTable modLine>
        <tbody>
          <tr>
            <td>
              <SwFetchErrorMessage onClick={getServices} />
            </td>
          </tr>
        </tbody>
      </SwDataTable>
    );
  }

  const openModal = (modal: Modals | string, service: Service) => {
    setSelectedService(service);
    openModalWithPageView(modal as Modals, {
      appendToId: service.workerId,
    });
  };

  const head = [
    {
      text: t(i18nKeys.general.label.id),
    },
    {
      text: t(i18nKeys.general.label.date),
      sorting: Sorting.DATE_ASC,
    },
    {
      text: t(i18nKeys.general.label.creationDate),
      sorting: Sorting.CREATION_DATE_ASC,
    },
    {
      text: t(i18nKeys.general.label.customer),
      sorting: Sorting.CUSTOMER_ASC,
    },
    { text: t(i18nKeys.general.label.activity) },
    { text: t(i18nKeys.general.label.time) },
    { text: t(i18nKeys.general.label.status) },
    { text: '' },
  ] as PrestationsTableHeadElement[];

  return (
    <>
      <div className="vl-u-table-overflow">
        <SwDataTable modLine>
          <PrestationsTableHead<GetWorkerServicesParams>
            head={head}
            parameters={parameters}
            setParameters={setParameters}
            eventCategory={EventCategory.PRESTATIONS}
          />
          <tbody>
            {!services || !services.length ? (
              <TableRowNoResult colSpan={head.length} />
            ) : (
              services
                .slice(0, PRESTATIONS_SEARCH_SERVICES_PAGE_SIZE * selectedPage)
                .map((service) => (
                  <PrestationsTableRow
                    key={`${service.workerId}-${service.id}-${service.status}`}
                    service={service}
                    openModal={openModal}
                    providerId={
                      parameters?.searchedProviderId || provider.id.toString()
                    }
                    showActions
                  >
                    <PrestationsSearchTableRowStatus
                      providerId={provider.id}
                      workerId={service.workerId}
                      service={service}
                    />
                  </PrestationsTableRow>
                ))
            )}
          </tbody>
        </SwDataTable>
      </div>
      {!!services &&
        services.length >
          PRESTATIONS_SEARCH_SERVICES_PAGE_SIZE * selectedPage && (
          <SwLink
            className="vl-u-bg-alt vl-u-align-center vl-u-display-block vl-u-text--small"
            style={{ width: '100%', border: '.1rem solid #cbd2da' }}
            onClick={(e) => {
              e.preventDefault();
              setSelectedPage((page) => page + 1);
            }}
          >
            {t(i18nKeys.prestations.search.table.showMore)}
          </SwLink>
        )}
      {selectedService && (
        <PrestationsModals
          provider={provider}
          parameters={parameters}
          selectedService={selectedService}
          services={services}
          setServices={setServices}
          onSuccess={onSuccess}
          setValidateStatus={setValidateStatus}
        />
      )}
    </>
  );
};
