import { Relative, SupportType, VoucherStatus } from '@kaa/api/providers';
import { getStarOfTheDayJsDate } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import { TFunction } from 'i18next';
import { DateTime } from 'luxon';
import {
  BACKEND_LIMIT_DATE,
  RemittancesSearchHistoryFormType,
  TODAY_DATE,
} from './RemittancesSearchHistory.constants';

export const getRelativesOptions = (relatives: Relative[]) =>
  relatives.map(({ id, name }) => ({
    value: id,
    text: `${name} - ${id}`,
  }));

export const getSupportTypeOptions = (t: TFunction) =>
  Object.values(SupportType).map((e: SupportType) => ({
    value: e,
    text: t(i18nKeys.general.supportType[e]),
  }));

export const getVoucherStatusOptions = (t: TFunction) =>
  Object.values(VoucherStatus).map((e) => ({
    value: e,
    text: t(i18nKeys.general.voucherStatus[e]),
  }));

export const getMinStartDate = (values: RemittancesSearchHistoryFormType) => {
  if (values.endDate) {
    const limit = getStarOfTheDayJsDate(
      DateTime.fromISO(values.endDate).minus({ months: 12 }),
    );
    return limit > BACKEND_LIMIT_DATE ? limit : BACKEND_LIMIT_DATE;
  }

  return BACKEND_LIMIT_DATE;
};

export const getMaxStartDate = (values: RemittancesSearchHistoryFormType) => {
  return values.endDate
    ? getStarOfTheDayJsDate(DateTime.fromISO(values.endDate))
    : TODAY_DATE;
};

export const getMinEndDate = (values: RemittancesSearchHistoryFormType) => {
  return values.startDate
    ? getStarOfTheDayJsDate(DateTime.fromISO(values.startDate))
    : BACKEND_LIMIT_DATE;
};

export const getMaxEndDate = (values: RemittancesSearchHistoryFormType) => {
  if (values.startDate) {
    const limit = getStarOfTheDayJsDate(
      DateTime.fromISO(values.startDate).plus({ months: 12 }),
    );
    return limit < TODAY_DATE ? limit : TODAY_DATE;
  }

  return TODAY_DATE;
};
