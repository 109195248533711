import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AlertType,
  getAlertPropsByType,
  SwAlert,
  SwColumn,
  SwDescriptionData,
  SwDescriptionDataItem,
  SwGrid,
  SwTitle,
} from '@kaa/ui-flanders/components';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modals } from '../../../../constants';
import { sendConfirmationModalViewEvent } from '../../../../utils/google-analytics';

type AddWorkerModalConfirmationProps = {
  worker: {
    id: string;
    login: string;
    password: string;
    alreadyExists: boolean;
    firstName: string;
    lastName: string;
  };
};

export const AddWorkerModalConfirmation = ({
  worker,
}: AddWorkerModalConfirmationProps) => {
  const { t } = useTranslation();
  useEffect(() => {
    sendConfirmationModalViewEvent(Modals.WORKERS_ADD_WORKER_MODAL);
  }, []);

  const SwDescriptionDataItemColumn = { width: '6', widthS: '12' };
  const SwDescriptionDataItemStyle = {
    paddingTop: '1rem',
    paddingBottom: '1rem',
    margin: 0,
  };

  const { login, password, alreadyExists, firstName, lastName } = worker;

  return (
    <SwGrid>
      <SwColumn>
        <SwAlert
          {...getAlertPropsByType(AlertType.SUCCESS)}
          title={t(i18nKeys.general.label.success)}
          className="vl-u-spacer--medium"
        >
          {t(
            alreadyExists
              ? i18nKeys.workers.modal.addWorker.confirmation.alreadyExist
                  .alertMessage
              : i18nKeys.workers.modal.addWorker.confirmation.alertMessage,
          )}
        </SwAlert>
        <SwTitle tagName="h3" tagStyle="h4" className="vl-u-spacer--xsmall">
          {t(i18nKeys.workers.modal.addWorker.subtitle)}
        </SwTitle>
        <SwDescriptionData>
          <SwDescriptionDataItem
            label={lastName}
            column={SwDescriptionDataItemColumn}
            style={SwDescriptionDataItemStyle}
          >
            {t(i18nKeys.general.label.name)}
          </SwDescriptionDataItem>
          <SwDescriptionDataItem
            label={firstName}
            column={SwDescriptionDataItemColumn}
            style={SwDescriptionDataItemStyle}
          >
            {t(i18nKeys.general.label.firstname)}
          </SwDescriptionDataItem>
          <SwDescriptionDataItem
            label={login}
            column={SwDescriptionDataItemColumn}
            style={SwDescriptionDataItemStyle}
          >
            {t(i18nKeys.workers.loginInformation.login)}
          </SwDescriptionDataItem>
          <SwDescriptionDataItem
            label={password}
            column={SwDescriptionDataItemColumn}
            style={SwDescriptionDataItemStyle}
          >
            {t(i18nKeys.workers.loginInformation.password)}
          </SwDescriptionDataItem>
        </SwDescriptionData>
      </SwColumn>
    </SwGrid>
  );
};
