import {
  b64toBlob,
  POSTCODES_CITIES,
  sampleCsv,
  sampleXlsx,
} from '@kaa/api/common/mocks';
import faker from 'faker';
import {
  Address,
  FileExtensionReference,
  MonthPeriodResponse,
  Relative,
  Service,
  Sorting,
  WorkerSummary,
} from '../model';

export const getArray = (count = faker.random.number(99)) => [...Array(count)];

export const getRandomArray = (params?: object | number) => {
  if (typeof params === 'number') {
    return getArray(faker.random.number(params));
  }

  if (typeof params === 'object') {
    return getArray(faker.random.number(params));
  }

  return getArray();
};

export const getId = (str?: string | number): string =>
  String(str || faker.random.number().toString());

export const getFullName = (firstName?: string, lastName?: string): string =>
  `${firstName || faker.name.firstName()} ${lastName || faker.name.lastName()}`;

export const getProviderAddress = (address?: Partial<Address>) => {
  const POSTOCDE_CITY = faker.helpers.randomize(POSTCODES_CITIES);
  return {
    streetId: faker.random.number(999),
    houseNumber: faker.random.number(999).toString(),
    boxNumber: faker.random.number(9).toString(),
    street: faker.address.streetName(),
    postcode: POSTOCDE_CITY.postcode,
    city: faker.helpers.randomize(POSTOCDE_CITY.names),
    country: 'Belgium',
    ...address,
  };
};

export const getMonthPeriodResponse = (): MonthPeriodResponse => ({
  data: getRandomArray({ min: 1, max: 10 }).map(() => {
    const date = faker.date.recent(90);
    return `${date.getFullYear()}-${date.getMonth()}-01`;
  }),
});

export const getBlobResponse = (
  fileExtension?: FileExtensionReference,
): Blob => {
  switch (fileExtension) {
    case FileExtensionReference.CSV:
      return b64toBlob(sampleCsv, 'text/csv');
    default:
      return b64toBlob(
        sampleXlsx,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      );
  }
};

export const sortByServiceDate = <T extends Service>(
  sort: Sorting,
  services: T[],
): T[] =>
  [...services].sort((a, b) =>
    sort === Sorting.DATE_ASC
      ? Number(new Date(b.serviceDate)) - Number(new Date(a.serviceDate))
      : Number(new Date(a.serviceDate)) - Number(new Date(b.serviceDate)),
  );

export const sortByCreationDate = <T extends Service>(
  sort: Sorting,
  services: T[],
): T[] =>
  [...services].sort((a, b) =>
    sort === Sorting.CREATION_DATE_ASC
      ? Number(new Date(b.creationDate)) - Number(new Date(a.creationDate))
      : Number(new Date(a.creationDate)) - Number(new Date(b.creationDate)),
  );

export const sortByWorkerName = <T extends Service>(
  sort: Sorting,
  services: T[],
): T[] =>
  [...services].sort((a, b) =>
    sort === Sorting.WORKER_ASC
      ? a.workerFullname.localeCompare(b.workerFullname)
      : b.workerFullname.localeCompare(a.workerFullname),
  );

export const sortByCustomerName = <T extends Service>(
  sort: Sorting,
  services: T[],
): T[] =>
  [...services].sort((a, b) =>
    sort === Sorting.CUSTOMER_ASC
      ? a.customerFullname.localeCompare(b.customerFullname)
      : b.customerFullname.localeCompare(a.customerFullname),
  );

export const sortByPostcodes = <T extends Relative | WorkerSummary>(
  sort: Sorting,
  subsidiaries: T[],
): T[] =>
  [...subsidiaries].sort((a, b) =>
    sort === Sorting.POSTCODE_ASC
      ? a.postcode.localeCompare(b.postcode, 'kn', {
          numeric: true,
        })
      : b.postcode.localeCompare(a.postcode, 'kn', {
          numeric: true,
        }),
  );
