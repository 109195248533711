import { i18nKeys } from '@kaa/i18n/providers/keys';
import { SwContainer } from '@kaa/ui-flanders/components';
import { RouteComponentProps } from '@reach/router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '../components';

export const ForbiddenWrongRegion = (props: RouteComponentProps) => {
  const { t } = useTranslation();

  return (
    <SwContainer error>
      <PageHeader
        title={t(i18nKeys.forbiddenWrongRegion.title)}
        introduction={t(i18nKeys.forbiddenWrongRegion.introduction)}
        className="vl-u-spacer--medium"
      />
    </SwContainer>
  );
};
