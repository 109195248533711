import {
  ElectronicVoucherRemittanceConfirmationData,
  ExportRemittanceWorksParams,
  FileExtensionReference,
} from '@kaa/api/providers';
import { Format, toCurrencyFormat } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AlertType,
  getAlertPropsByType,
  SwAlert,
  SwColumn,
  SwFormMessageLabel,
  SwGrid,
  SwModal,
  SwTitle,
} from '@kaa/ui-flanders/components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AvailableLanguages } from '../../../../../../../common/enums';
import { DownloadButtons } from '../../../../../components';
import { Modals } from '../../../../../constants';
import {
  openModalWithPageView,
  sendConfirmationModalViewEvent,
} from '../../../../../utils/google-analytics';
import { RemittanceManualCreationDownloadModal } from './RemittanceManualCreationDownloadModal';

type RemittanceManualModalConfirmationProps = {
  providerId: number;
  confirmationProps: ElectronicVoucherRemittanceConfirmationData;
};

export const RemittanceManualModalConfirmation = ({
  confirmationProps,
  providerId,
}: RemittanceManualModalConfirmationProps) => {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    sendConfirmationModalViewEvent(Modals.REMITTANCES_MANUAL_MODAL_ID);
  }, []);
  const valueStyle = {
    marginRight: '2rem',
    minWidth: '40%',
  };

  const {
    numberOfVouchers,
    remittanceId,
    amountToRefund,
    issuePeriod,
    communication,
    providerName,
  } = confirmationProps;

  const downloadExtensions = [
    FileExtensionReference.XLSX,
    FileExtensionReference.CSV,
  ];

  const [currentFileExtension, setCurrentFileExtension] = useState<
    FileExtensionReference | undefined
  >();

  const closeRemittanceModal = () => {
    setCurrentFileExtension(undefined);
  };

  return (
    <>
      <SwAlert
        {...getAlertPropsByType(AlertType.SUCCESS)}
        title={t(i18nKeys.general.label.success)}
        className="vl-u-spacer"
      >
        {t(i18nKeys.remittances.modal.manual.confirmation.alertMessage)}
      </SwAlert>
      <SwTitle tagName="h5" className="vl-u-spacer">
        {t(i18nKeys.remittances.modal.manual.summary.title)}
      </SwTitle>
      <SwGrid>
        <SwColumn width="5" widthS="12" className="vl-u-spacer--small">
          <SwFormMessageLabel tagName="span">
            {t(i18nKeys.remittances.general.numberVouchers)}
          </SwFormMessageLabel>
        </SwColumn>
        <SwColumn width="7" widthS="12" className="vl-u-display-flex">
          <span style={valueStyle}>{numberOfVouchers}</span>
        </SwColumn>

        <SwColumn width="5" widthS="12" className="vl-u-spacer--small">
          <SwFormMessageLabel tagName="span">
            {t(i18nKeys.remittances.general.remittanceNumber)}
          </SwFormMessageLabel>
        </SwColumn>
        <SwColumn width="7" widthS="12" className="vl-u-display-flex">
          <span style={valueStyle}>{remittanceId}</span>
        </SwColumn>

        <SwColumn width="5" widthS="12" className="vl-u-spacer--small">
          <SwFormMessageLabel tagName="span">
            {t(i18nKeys.remittances.general.reimbursedAmount)}
          </SwFormMessageLabel>
        </SwColumn>
        <SwColumn width="7" widthS="12" className="vl-u-display-flex">
          <span style={valueStyle}>{toCurrencyFormat(amountToRefund)}</span>
        </SwColumn>

        <SwColumn width="5" widthS="12" className="vl-u-spacer--small">
          <SwFormMessageLabel tagName="span">
            {t(i18nKeys.remittances.general.month)}
          </SwFormMessageLabel>
        </SwColumn>
        <SwColumn width="7" widthS="12" className="vl-u-display-flex">
          <span style={valueStyle}>
            {issuePeriod
              ? Format.dateMonthYear(
                  issuePeriod,
                  i18n.language as AvailableLanguages,
                )
              : ''}
          </span>
        </SwColumn>

        <SwColumn width="5" widthS="12" className="vl-u-spacer--small">
          <SwFormMessageLabel tagName="span">
            {t(i18nKeys.remittances.general.freeReference)}
          </SwFormMessageLabel>
        </SwColumn>
        <SwColumn width="7" widthS="12" className="vl-u-display-flex">
          <span style={valueStyle}>{communication}</span>
        </SwColumn>

        <SwColumn width="5" widthS="12" className="vl-u-spacer--small">
          <SwFormMessageLabel tagName="span">
            {t(i18nKeys.remittances.general.exploitationName)}
          </SwFormMessageLabel>
        </SwColumn>
        <SwColumn width="7" widthS="12" className="vl-u-display-flex">
          <span style={valueStyle}>{providerName}</span>
        </SwColumn>
      </SwGrid>

      <DownloadButtons
        modalId={Modals.REMITTANCES_MANUAL_CREATION_DOWNLOAD_MODAL}
        extensions={downloadExtensions}
        onClick={(extension: FileExtensionReference) => {
          setCurrentFileExtension(extension);
          openModalWithPageView(
            Modals.REMITTANCES_MANUAL_CREATION_DOWNLOAD_MODAL,
          );
        }}
      />

      {currentFileExtension && (
        <SwModal
          id={Modals.REMITTANCES_MANUAL_CREATION_DOWNLOAD_MODAL}
          component={RemittanceManualCreationDownloadModal}
          remittanceId={remittanceId}
          providerId={providerId}
          onClose={closeRemittanceModal}
          parameters={
            {
              fileExtension: currentFileExtension,
            } as ExportRemittanceWorksParams
          }
          closable
        />
      )}
    </>
  );
};
