import { mockedDelay, toAxiosResponse } from '@kaa/api/common';
import { getActionsMock } from '@kaa/api/common/mocks';
import { AxiosPromise } from 'axios';
import {
  getActiveLocationsResponse,
  getAgreementsResponse,
  getAvailableLocationsResponse,
  getBillDetailsResponse,
  getBillingConfigurationResponse,
  getBillsResponse,
  getBlobResponse,
  getContactPersonResponse,
  getContactPersonsResponse,
  getCustomersResponse,
  getDashboardPickupOverviewResponse,
  getDashboardRemittancesActionsResponse,
  getDashboardRemittancesOverviewResponse,
  getDashboardWorkersActionsResponse,
  getDashboardWorksActionsResponse,
  getDashboardWorksOverviewResponse,
  getEntityCreatedResponse,
  getEntityCreatedResponseWithActions,
  getHeadquarterOrRelative,
  getLastRemittancesResponse,
  getLegalFormsResponse,
  getListServicesResponse,
  getMonthlyPickupResponse,
  getMonthPeriodResponse,
  getPaperVoucherAnnouncements,
  getPaperVoucherAnnouncementsResponse,
  getPaperVoucherValidityResponse,
  getPickupConfigurationResponse,
  getPickupResponse,
  getProviderResponse,
  getProviderSubsidiaryCreateResponse,
  getProviderSubsidyConfigurationResponse,
  getRelativeAddressesResponse,
  getRelativesResponse,
  getRemittanceDetailIncorrectResponse,
  getRemittanceDetailRefusedResponse,
  getRemittancesDetailResponse,
  getRemittancesOverviewResponse,
  getServiceCancelResponse,
  getServiceResponse,
  getServicesFileStatusResponse,
  getServicesHistoryResponse,
  getServicesOverviewResponse,
  getServicesResponse,
  getServicesValidatedResponse,
  getServiceUpdateResponse,
  getServiceValidateResponse,
  getStreetsResponse,
  getSubsidiesResponse,
  getSubsidyDetailResponse,
  getTitlesResponse,
  getWorkerContractsResponse,
  getWorkerCreateContractResponse,
  getWorkerCreatedResponse,
  getWorkerDetailResponse,
  getWorkerResetPasswordResponse,
  getWorkerServicesResponse,
  getWorkersFileStatusResponse,
  getWorkersOverviewResponse,
  getWorkersServicesResponse,
  getWorkersSummaryResponse,
  postRemittance,
  setNewServicesFileStatusResponse,
  setNewWorkersFileStatusResponse,
  getVideoStreamId,
  getVideosResponse,
} from './mocks';
import {
  Actions,
  ActivateWorkersParams,
  AgreementResponse,
  AnnouncementsWithRegionalAgreement,
  BillDetailsResponse,
  BillingConfigurationResponse,
  BillsResponse,
  ContactPerson,
  ContactPersonActionType,
  ContactPersonResponse,
  ContactPersonsResponse,
  CreateRemittancesParams,
  CustomersResponse,
  DashboardPickupOverviewResponse,
  DashboardRemittancesActionsResponse,
  DashboardRemittancesOverviewResponse,
  DashboardWorkersActionsResponse,
  DashboardWorksActionsResponse,
  DashboardWorksOverviewResponse,
  DeactivateWorkersParams,
  DeleteSinglePickupParams,
  ElectronicVoucherRemittanceConfirmation,
  ElectronicVoucherRemittanceCreationDemand,
  EntityCreatedResponse,
  EntityCreatedResponseWithActions,
  ExportFileServicesParams,
  ExportFileWorkersParams,
  ExportRefusedVouchersRemittanceParams,
  ExportRemittancesParams,
  ExportRemittanceWorksParams,
  ExportServicesParams,
  ExportSubsidiesParams,
  ExportWorkersParams,
  GetCustomersParams,
  GetGlobalLegalFormsParams,
  GetGlobalTitlesParams,
  GetIncorrectVouchersRemittanceParams,
  GetLastRemittancesParams,
  GetListServicesParams,
  GetLocationsSuggestionsParams,
  GetProviderBillsParams,
  GetProviderPickupPlanningParams,
  GetProviderRelativesParams,
  GetRefusedVouchersRemittanceParams,
  GetServicesHistoryParams,
  GetServicesParams,
  GetSinglePickupParams,
  GetSubsidiesParams,
  GetWorkerServicesHistoryParams,
  GetWorkerServicesParams,
  GetWorkersParams,
  GetWorkersServicesParams,
  LanguageCode,
  LegalFormsResponse,
  ListServicesResponse,
  LocationsResponse,
  MonthlyPickupResponse,
  MonthPeriodResponse,
  NoContentResponse,
  PaperVoucherAnnouncementsFileParserResponse,
  PaperVoucherAnnouncementsResponse,
  PaperVouchersRemittance,
  PaperVoucherValidityResponse,
  PickupConfigurationResponse,
  PickupOccurrenceUpdate,
  PickupResponse,
  ProviderRemittanceUpdateConfiguration,
  ProviderResponse,
  ProviderSubsidiary,
  ProviderSubsidyConfigurationResponse,
  ProviderSubsidyConfigurationUpdate,
  ProviderUpdate,
  ProviderVisibilityLocations,
  ProviderVisibilityLocationsResponse,
  ProviderVisibilityUpdateAgreements,
  RecurrentPickupCreate,
  RecurrentPickupUpdate,
  RelativeAddressesResponse,
  RelativeResponse,
  RelativesResponse,
  RemittanceDetailIncorrectResponse,
  RemittanceDetailRefusedResponse,
  RemittanceDetailResponse,
  RemittancesOverviewResponse,
  RemittancesResponse,
  ResumeOccurrenceParams,
  ServiceCancel,
  ServiceCreate,
  ServiceResponse,
  ServicesFileStatusResponse,
  ServicesHistoryResponse,
  ServicesOverviewResponse,
  ServicesResponse,
  ServicesValidatedResponse,
  ServiceUpdate,
  ServiceUpdateResponse,
  StandalonePickupCreate,
  StreetsResponse,
  SubsidiesResponse,
  SubsidyDetailResponse,
  TitlesResponse,
  UserPasswordUpdate,
  ValidateWorkerServicesParams,
  ValidateWorkersServicesParams,
  VideosResponse,
  VideosStreamIdResponse,
  Voucher,
  Worker,
  WorkerContract,
  WorkerContractsResponse,
  WorkerCreateContractResponse,
  WorkerDetailResponse,
  WorkerResetPasswordResponse,
  WorkersCreatedResponse,
  WorkerServicesResponse,
  WorkersFileStatusResponse,
  WorkersOverviewResponse,
  WorkersResponse,
  WorkersServicesResponse,
  WorkerUpdatedResponse,
} from './model';
import { BFFProvidersApi } from './providers';

export const getProvidersMock = (): BFFProvidersApi => {
  return {
    liveness(): AxiosPromise<unknown> {
      return toAxiosResponse(mockedDelay('healthy'));
    },
    // Get all locations
    getGlobalLocations(version?: number): AxiosPromise<LocationsResponse> {
      return toAxiosResponse(mockedDelay(getAvailableLocationsResponse()));
    },
    // Search streets by keyword and postcode
    getLocationsSuggestions(
      params?: GetLocationsSuggestionsParams,
      version?: number,
    ): AxiosPromise<StreetsResponse> {
      return toAxiosResponse(mockedDelay(getStreetsResponse(params?.postcode)));
    },
    // Get all titles
    getGlobalTitles(
      params?: GetGlobalTitlesParams,
      version?: number,
    ): AxiosPromise<TitlesResponse> {
      return toAxiosResponse(mockedDelay(getTitlesResponse()));
    },
    // Get all legal forms
    getGlobalLegalForms(
      params?: GetGlobalLegalFormsParams,
      version?: number,
    ): AxiosPromise<LegalFormsResponse> {
      return toAxiosResponse(mockedDelay(getLegalFormsResponse()));
    },
    // Get provider active locations
    getProviderVisibilityForLocations(
      providerId: number,
      version?: number,
    ): AxiosPromise<ProviderVisibilityLocationsResponse> {
      return toAxiosResponse(mockedDelay(getActiveLocationsResponse()));
    },
    // Update provider active locations
    updateProviderVisibilityForLocations(
      providerId: number,
      providerVisibilityLocations: ProviderVisibilityLocations,
      version?: number,
    ): AxiosPromise<NoContentResponse> {
      return toAxiosResponse(mockedDelay());
    },
    // Get provider activity agreements
    getProviderVisibilityForAgreements(
      providerId: number,
      version?: number,
    ): AxiosPromise<AgreementResponse> {
      return toAxiosResponse(mockedDelay(getAgreementsResponse()));
    },
    // Update provider activity agreements
    updateProviderVisibilityForAgreements(
      providerId: number,
      providerVisibilityUpdateAgreements: ProviderVisibilityUpdateAgreements,
      version?: number,
    ): AxiosPromise<NoContentResponse> {
      return toAxiosResponse(mockedDelay());
    },
    // Get dashboard works overview information
    getDashboardServicesOverview(
      providerId: number,
      version?: number,
    ): AxiosPromise<DashboardWorksOverviewResponse> {
      return toAxiosResponse(mockedDelay(getDashboardWorksOverviewResponse()));
    },
    // Get dashboard remittances overview information
    getDashboardRemittancesOverview(
      providerId: number,
      version?: number,
    ): AxiosPromise<DashboardRemittancesOverviewResponse> {
      return toAxiosResponse(
        mockedDelay(getDashboardRemittancesOverviewResponse()),
      );
    },
    // Get dashboard pickups overview information
    getDashboardPickupsOverview(
      providerId: number,
      version?: number,
    ): AxiosPromise<DashboardPickupOverviewResponse> {
      return toAxiosResponse(mockedDelay(getDashboardPickupOverviewResponse()));
    },
    // Get dashboard remittances actions information
    getDashboardRemittancesActions(
      providerId: number,
      version?: number,
    ): AxiosPromise<DashboardRemittancesActionsResponse> {
      return toAxiosResponse(
        mockedDelay(getDashboardRemittancesActionsResponse()),
      );
    },
    // Get dashboard vouchers overview information
    getDashboardServicesActions(
      providerId: number,
      version?: number,
    ): AxiosPromise<DashboardWorksActionsResponse> {
      return toAxiosResponse(mockedDelay(getDashboardWorksActionsResponse()));
    },
    // Get dashboard workers overview information
    getDashboardWorkersActions(
      providerId: number,
      version?: number,
    ): AxiosPromise<DashboardWorkersActionsResponse> {
      return toAxiosResponse(mockedDelay(getDashboardWorkersActionsResponse()));
    },
    // Create a subsidiary
    createProvider(
      providerSubsidiary: ProviderSubsidiary,
      version?: number,
    ): AxiosPromise<RelativeResponse> {
      return toAxiosResponse(
        mockedDelay(getProviderSubsidiaryCreateResponse(providerSubsidiary)),
      );
    },
    // Get provider
    getProvider(
      providerId: number,
      version?: number,
    ): AxiosPromise<ProviderResponse> {
      return toAxiosResponse(
        mockedDelay(getProviderResponse({ data: { id: providerId } })),
      );
    },
    // Update provider
    updateProvider(
      providerId: number,
      providerUpdate: ProviderUpdate,
      version?: number,
    ): AxiosPromise<NoContentResponse> {
      return toAxiosResponse(mockedDelay());
    },
    // Get provider subsidy configuration
    getProviderSubsidyConfiguration(
      providerId: number,
      version?: number,
    ): AxiosPromise<ProviderSubsidyConfigurationResponse> {
      return toAxiosResponse(
        mockedDelay(getProviderSubsidyConfigurationResponse()),
      );
    },
    // Update provider subsidy configuration
    updateProviderSubsidyConfiguration(
      providerId: number,
      providerSubsidyConfigurationUpdate: ProviderSubsidyConfigurationUpdate,
      version?: number,
    ): AxiosPromise<NoContentResponse> {
      return toAxiosResponse(mockedDelay());
    },
    // Update provider remittance configuration
    updateProviderRemittanceConfiguration(
      providerId: number,
      providerRemittanceUpdateConfiguration: ProviderRemittanceUpdateConfiguration,
      version?: number,
    ): AxiosPromise<NoContentResponse> {
      return toAxiosResponse(mockedDelay());
    },
    // Get provider's contact list
    getContactPersons(
      providerId: number,
      version?: number,
    ): AxiosPromise<ContactPersonsResponse> {
      return toAxiosResponse(
        mockedDelay(getContactPersonsResponse(undefined, providerId)),
      );
    },
    // Add a new provider contact
    addContactPerson(
      providerId: number,
      contactPerson: ContactPerson,
      version?: number,
    ): AxiosPromise<EntityCreatedResponseWithActions> {
      return toAxiosResponse(
        mockedDelay(
          getEntityCreatedResponseWithActions(
            providerId,
            getActionsMock(ContactPersonActionType),
          ),
        ),
      );
    },
    // Get a provider contact
    getContactPerson(
      providerId: number,
      contactPersonId: number,
      version?: number,
    ): AxiosPromise<ContactPersonResponse> {
      return toAxiosResponse(
        mockedDelay(
          getContactPersonResponse({ data: { id: contactPersonId } }),
        ),
      );
    },
    // Update a provider contact
    updateContactPerson(
      providerId: number,
      contactPersonId: number,
      contactPerson: ContactPerson,
      version?: number,
    ): AxiosPromise<Actions> {
      return toAxiosResponse(mockedDelay());
    },
    // Delete a provider contact
    deleteContactPerson(
      providerId: number,
      contactPersonId: number,
      version?: number,
    ): AxiosPromise<NoContentResponse> {
      return toAxiosResponse(mockedDelay());
    },
    // Get provider relatives
    getProviderRelatives(
      providerId: number,
      params?: GetProviderRelativesParams,
      version?: number,
    ): AxiosPromise<RelativesResponse> {
      return toAxiosResponse(
        mockedDelay(getRelativesResponse(providerId, params)),
      );
    },
    // Get provider addresses
    getProviderAddresses(
      providerId: number,
      version?: number,
    ): AxiosPromise<RelativeAddressesResponse> {
      return toAxiosResponse(mockedDelay(getRelativeAddressesResponse()));
    },
    // Deactivate a provider
    deactivateProviderMember(
      providerId: number,
      version?: number,
    ): AxiosPromise<RelativeResponse> {
      const deletedRelative = getHeadquarterOrRelative({
        id: providerId as number,
      });

      return toAxiosResponse(
        mockedDelay({
          data: { ...deletedRelative, isActive: false },
          actions: {
            [deletedRelative.resourceId]: {
              CAN_EDIT: false,
              CAN_DISABLE: false,
            },
          },
        }),
      );
    },
    // Get provider pickup configuration
    getProviderPickupConfiguration(
      providerId: number,
      version?: number,
    ): AxiosPromise<PickupConfigurationResponse> {
      return toAxiosResponse(mockedDelay(getPickupConfigurationResponse()));
    },
    // Get provider pickup planning
    getProviderPickupPlanning(
      providerId: number,
      params?: GetProviderPickupPlanningParams,
      version?: number,
    ): AxiosPromise<MonthlyPickupResponse> {
      return toAxiosResponse(mockedDelay(getMonthlyPickupResponse(params)));
    },
    // Create a standalone pickup
    createStandalonePickup(
      providerId: number,
      standalonePickupCreate: StandalonePickupCreate,
      version?: number,
    ): AxiosPromise<NoContentResponse> {
      return toAxiosResponse(mockedDelay());
    },
    // Get a single pickup
    getSinglePickup(
      providerId: number,
      pickupId: number,
      params?: GetSinglePickupParams,
      version?: number,
    ): AxiosPromise<PickupResponse> {
      return toAxiosResponse(
        mockedDelay(getPickupResponse(providerId, pickupId, params)),
      );
    },
    // Update a single pickup
    updateSinglePickup(
      providerId: number,
      pickupId: number,
      pickupOccurrenceUpdate: PickupOccurrenceUpdate,
      version?: number,
    ): AxiosPromise<NoContentResponse> {
      return toAxiosResponse(mockedDelay());
    },
    // Delete a standalone pickup or cancel an occurrence
    deleteSinglePickup(
      providerId: number,
      pickupId: number,
      params?: DeleteSinglePickupParams,
      version?: number,
    ): AxiosPromise<NoContentResponse> {
      return toAxiosResponse(mockedDelay());
    },
    // Resume an occurrence
    resumeOccurrence(
      providerId: number,
      pickupId: number,
      params?: ResumeOccurrenceParams,
      version?: number,
    ): AxiosPromise<NoContentResponse> {
      return toAxiosResponse(mockedDelay());
    },
    // Create a recurrent pickup
    createRecurrentPickup(
      providerId: number,
      recurrentPickupCreate: RecurrentPickupCreate,
      version?: number,
    ): AxiosPromise<NoContentResponse> {
      return toAxiosResponse(mockedDelay());
    },
    // Update a recurrent pickup
    updateRecurrentPickup(
      providerId: number,
      pickupId: number,
      recurrentPickupUpdate: RecurrentPickupUpdate,
      version?: number,
    ): AxiosPromise<NoContentResponse> {
      return toAxiosResponse(mockedDelay());
    },
    // Delete a recurrent pickup
    deleteRecurrentPickup(
      providerId: number,
      pickupId: number,
      version?: number,
    ): AxiosPromise<NoContentResponse> {
      return toAxiosResponse(mockedDelay());
    },
    // Search for remittances
    getLastRemittances(
      providerId: number,
      params?: GetLastRemittancesParams,
      version?: number,
    ): AxiosPromise<RemittancesResponse> {
      return toAxiosResponse(
        mockedDelay(getLastRemittancesResponse(providerId, params)),
      );
    },
    // Add a new remittances
    createRemittances(
      providerId: number,
      electronicVoucherRemittanceCreationDemand: ElectronicVoucherRemittanceCreationDemand,
      params?: CreateRemittancesParams,
      version?: number,
    ): AxiosPromise<ElectronicVoucherRemittanceConfirmation> {
      return toAxiosResponse(
        mockedDelay(
          postRemittance(providerId, electronicVoucherRemittanceCreationDemand),
        ),
      );
    },
    // Get overview for all remittances
    getRemittancesOverview(
      providerId: number,
      version?: number,
    ): AxiosPromise<RemittancesOverviewResponse> {
      return toAxiosResponse(
        mockedDelay(getRemittancesOverviewResponse(providerId)),
      );
    },
    // Get available months
    getAvailableMonths(
      providerId: number,
      version?: number,
    ): AxiosPromise<MonthPeriodResponse> {
      return toAxiosResponse(mockedDelay(getMonthPeriodResponse()));
    },
    // Get vouchers of a remittance
    getRemittance(
      providerId: number,
      remittanceId: number,
      version?: number,
    ): AxiosPromise<RemittanceDetailResponse> {
      return toAxiosResponse(
        mockedDelay(getRemittancesDetailResponse(providerId)),
      );
    },
    // Get incorrect vouchers of a remittance
    getIncorrectVouchersRemittance(
      providerId: number,
      remittanceId: number,
      params?: GetIncorrectVouchersRemittanceParams,
      version?: number,
    ): AxiosPromise<RemittanceDetailIncorrectResponse> {
      return toAxiosResponse(
        mockedDelay(
          getRemittanceDetailIncorrectResponse(
            providerId,
            remittanceId,
            params,
          ),
        ),
      );
    },
    // Get refused vouchers of a remittance
    getRefusedVouchersRemittance(
      providerId: number,
      remittanceId: number,
      params?: GetRefusedVouchersRemittanceParams,
      version?: number,
    ): AxiosPromise<RemittanceDetailRefusedResponse> {
      return toAxiosResponse(
        mockedDelay(getRemittanceDetailRefusedResponse(params)),
      );
    },
    // Export refused vouchers of a remittance
    exportRefusedVouchersRemittance(
      providerId: number,
      remittanceId: number,
      params?: ExportRefusedVouchersRemittanceParams,
      version?: number,
    ): AxiosPromise<Blob> {
      return toAxiosResponse(
        mockedDelay(getBlobResponse(params?.fileExtension)),
      );
    },
    // Export searched remittances
    exportRemittances(
      providerId: number,
      params?: ExportRemittancesParams,
      version?: number,
    ): AxiosPromise<Blob> {
      return toAxiosResponse(
        mockedDelay(getBlobResponse(params?.fileExtension)),
      );
    },
    // Export reimbursed remittances
    exportRemittanceWorks(
      providerId: number,
      remittanceId: number,
      params?: ExportRemittanceWorksParams,
      version = 1,
    ): AxiosPromise<Blob> {
      return toAxiosResponse(
        mockedDelay(getBlobResponse(params?.fileExtension)),
      );
    },
    // Add a new paper vouchers remittance
    createRemittance(
      providerId: number,
      paperVouchersRemittance: PaperVouchersRemittance,
      version?: number,
    ): AxiosPromise<Blob> {
      return toAxiosResponse(mockedDelay(getBlobResponse()));
    },
    // Add a new paper voucher announcement
    createVoucherAnnouncement(
      providerId: number,
      announcementsWithRegionalAgreement: AnnouncementsWithRegionalAgreement,
      version?: number,
    ): AxiosPromise<PaperVoucherAnnouncementsResponse> {
      return toAxiosResponse(
        mockedDelay(
          getPaperVoucherAnnouncementsResponse(
            announcementsWithRegionalAgreement,
          ),
        ),
      );
    },
    // Parse a file containing a list of announcements
    parseAnnouncementsFile(
      providerId: number,
      blob: Blob,
      version?: number,
    ): AxiosPromise<PaperVoucherAnnouncementsFileParserResponse> {
      return toAxiosResponse(mockedDelay(getPaperVoucherAnnouncements()));
    },
    // Correct a voucher
    correctVoucher(
      providerId: number,
      remittanceId: number,
      voucherId: number,
      voucher: Voucher,
      version?: number,
    ): AxiosPromise<NoContentResponse> {
      return toAxiosResponse(mockedDelay());
    },
    // Get paper voucher validity
    getPaperVoucherValidity(
      voucherReference: string,
      version?: number,
    ): AxiosPromise<PaperVoucherValidityResponse> {
      return toAxiosResponse(mockedDelay(getPaperVoucherValidityResponse()));
    },
    // Get provider's worker list
    getWorkers(
      providerId: number,
      params?: GetWorkersParams,
      version?: number,
    ): AxiosPromise<WorkersResponse> {
      return toAxiosResponse(mockedDelay(getWorkersSummaryResponse(params)));
    },
    // Add new workers
    createWorkers(
      providerId: number,
      worker: Worker,
      version?: number,
    ): AxiosPromise<WorkersCreatedResponse> {
      return toAxiosResponse(mockedDelay(getWorkerCreatedResponse()));
    },
    // Activate workers
    activateWorkers(
      providerId: number,
      params?: ActivateWorkersParams,
      version?: number,
    ): AxiosPromise<NoContentResponse> {
      return toAxiosResponse(mockedDelay());
    },
    // Deactivate workers
    deactivateWorkers(
      providerId: number,
      params?: DeactivateWorkersParams,
      version?: number,
    ): AxiosPromise<NoContentResponse> {
      return toAxiosResponse(mockedDelay());
    },
    // Export provider's worker list
    exportWorkers(
      providerId: number,
      params?: ExportWorkersParams,
      version?: number,
    ): AxiosPromise<Blob> {
      return toAxiosResponse(
        mockedDelay(getBlobResponse(params?.fileExtension)),
      );
    },
    // Parse a file containing a list of workers
    parseWorkersFile(
      providerId: number,
      blob: Blob,
      version?: number,
    ): AxiosPromise<NoContentResponse> {
      return toAxiosResponse(mockedDelay(setNewWorkersFileStatusResponse()));
    },
    // get status of the imported file containing a list of workers
    getWorkersFileStatus(
      providerId: number,
      version?: number,
    ): AxiosPromise<WorkersFileStatusResponse> {
      return toAxiosResponse(mockedDelay(getWorkersFileStatusResponse()));
    },
    // Export imported workers list
    exportFileWorkers(
      providerId: number,
      params?: ExportFileWorkersParams,
      version?: number,
    ): AxiosPromise<Blob> {
      return toAxiosResponse(
        mockedDelay(getBlobResponse(params?.fileExtension)),
      );
    },
    // Get overview for all workers
    getWorkersOverview(
      providerId: number,
      version?: number,
    ): AxiosPromise<WorkersOverviewResponse> {
      return toAxiosResponse(mockedDelay(getWorkersOverviewResponse()));
    },
    // Get detail of a worker
    getWorkerDetail(
      providerId: number,
      workerId: number,
      version?: number,
    ): AxiosPromise<WorkerDetailResponse> {
      return toAxiosResponse(mockedDelay(getWorkerDetailResponse()));
    },
    // Update a worker
    updateWorker(
      providerId: number,
      workerId: number,
      worker: Worker,
      version?: number,
    ): AxiosPromise<NoContentResponse> {
      return toAxiosResponse(mockedDelay());
    },
    // Activate a worker
    activateWorker(
      providerId: number,
      workerId: number,
      version?: number,
    ): AxiosPromise<WorkerUpdatedResponse> {
      return toAxiosResponse(mockedDelay());
    },
    // Deactivate a worker
    deactivateWorker(
      providerId: number,
      workerId: number,
      version?: number,
    ): AxiosPromise<WorkerUpdatedResponse> {
      return toAxiosResponse(mockedDelay());
    },
    // Reset password of a worker
    resetPasswordWorker(
      providerId: number,
      workerId: number,
      version?: number,
    ): AxiosPromise<WorkerResetPasswordResponse> {
      return toAxiosResponse(mockedDelay(getWorkerResetPasswordResponse()));
    },
    // get contracts of a worker
    getWorkerContracts(
      providerId: number,
      workerId: number,
      version?: number,
    ): AxiosPromise<WorkerContractsResponse> {
      return toAxiosResponse(mockedDelay(getWorkerContractsResponse()));
    },
    // Add new contract of a worker
    createWorkerContract(
      providerId: number,
      workerId: number,
      workerContract: WorkerContract,
      version?: number,
    ): AxiosPromise<WorkerCreateContractResponse> {
      return toAxiosResponse(mockedDelay(getWorkerCreateContractResponse()));
    },
    // Update contract of a worker
    updateWorkerContract(
      providerId: number,
      workerId: number,
      contractId: number,
      workerContract: WorkerContract,
      version?: number,
    ): AxiosPromise<NoContentResponse> {
      return toAxiosResponse(mockedDelay());
    },
    // Delete a worker contract
    deleteWorkerContract(
      providerId: number,
      workerId: number,
      contractId: number,
      version?: number,
    ): AxiosPromise<NoContentResponse> {
      return toAxiosResponse(mockedDelay());
    },
    // Get provider's works list
    getWorkersServices(
      providerId: number,
      params?: GetWorkersServicesParams,
      version?: number,
    ): AxiosPromise<WorkersServicesResponse> {
      return toAxiosResponse(mockedDelay(getWorkersServicesResponse(params)));
    },
    // Get provider's works list
    getListServices(
      providerId: number,
      params?: GetListServicesParams,
      version?: number,
    ): AxiosPromise<ListServicesResponse> {
      return toAxiosResponse(mockedDelay(getListServicesResponse(params)));
    },
    // Get provider's services history list
    getServicesHistory(
      providerId: number,
      params?: GetServicesHistoryParams,
      version?: number,
    ): AxiosPromise<ServicesHistoryResponse> {
      return toAxiosResponse(mockedDelay(getServicesHistoryResponse(params)));
    },
    // Get provider's services history list for worker
    getWorkerServicesHistory(
      providerId: number,
      workerId: number,
      params?: GetWorkerServicesHistoryParams,
      version?: number,
    ): AxiosPromise<ServicesHistoryResponse> {
      return toAxiosResponse(mockedDelay(getServicesHistoryResponse(params)));
    },
    // Get a worker services list
    getWorkerServices(
      providerId: number,
      workerId: number,
      params?: GetWorkerServicesParams,
      version?: number,
    ): AxiosPromise<WorkerServicesResponse> {
      return toAxiosResponse(
        mockedDelay(getWorkerServicesResponse(workerId, params)),
      );
    },
    // Validate the queried services for the requested worker
    validateWorkerServices(
      providerId: number,
      workerId: number,
      params?: ValidateWorkerServicesParams,
      version?: number,
    ): AxiosPromise<ServicesValidatedResponse> {
      return toAxiosResponse(mockedDelay(getServicesValidatedResponse()));
    },
    // get a service
    getService(
      providerId: number,
      serviceId: string,
      version?: number,
    ): AxiosPromise<ServiceResponse> {
      return toAxiosResponse(mockedDelay(getServiceResponse()));
    },
    // update a service
    updateWorkerService(
      providerId: string,
      serviceId: string,
      serviceUpdate: ServiceUpdate,
      version?: number,
    ): AxiosPromise<ServiceUpdateResponse> {
      return toAxiosResponse(
        mockedDelay(getServiceUpdateResponse(serviceUpdate)),
      );
    },
    // Cancel a service
    cancelService(
      providerId: string,
      serviceId: string,
      serviceCancel: ServiceCancel,
      version?: number,
    ): AxiosPromise<ServiceUpdateResponse> {
      return toAxiosResponse(mockedDelay(getServiceCancelResponse()));
    },
    // Validate the queried services
    validateWorkersServices(
      providerId: number,
      params?: ValidateWorkersServicesParams,
      version?: number,
    ): AxiosPromise<ServicesValidatedResponse> {
      return toAxiosResponse(mockedDelay(getServicesValidatedResponse()));
    },
    // Validate a service
    validateWorkerService(
      providerId: number,
      serviceId: number,
      version?: number,
    ): AxiosPromise<ServiceUpdateResponse> {
      return toAxiosResponse(
        mockedDelay(getServiceValidateResponse(serviceId)),
      );
    },
    // Export provider's services list
    exportServices(
      providerId: number,
      params?: ExportServicesParams,
      version?: number,
    ): AxiosPromise<Blob> {
      return toAxiosResponse(
        mockedDelay(getBlobResponse(params?.fileExtension)),
      );
    },
    // Parse a file containing a list of services
    parseServicesFile(
      providerId: number,
      blob: Blob,
      version?: number,
    ): AxiosPromise<NoContentResponse> {
      return toAxiosResponse(mockedDelay(setNewServicesFileStatusResponse()));
    },
    // Export imported services list
    exportFileServices(
      providerId: number,
      params?: ExportFileServicesParams,
      version?: number,
    ): AxiosPromise<Blob> {
      return toAxiosResponse(
        mockedDelay(getBlobResponse(params?.fileExtension)),
      );
    },
    // get status of a file containing a list of services
    getServicesFileStatus(
      providerId: number,
      version?: number,
    ): AxiosPromise<ServicesFileStatusResponse> {
      return toAxiosResponse(mockedDelay(getServicesFileStatusResponse()));
    },
    // get services of a provider
    getServices(
      providerId: number,
      params?: GetServicesParams,
      version?: number,
    ): AxiosPromise<ServicesResponse> {
      return toAxiosResponse(mockedDelay(getServicesResponse(params)));
    },
    // Add a new service
    createService(
      providerId: string,
      serviceCreate: ServiceCreate,
      version?: number,
    ): AxiosPromise<EntityCreatedResponse> {
      return toAxiosResponse(mockedDelay(getEntityCreatedResponse()));
    },
    // get services overview of a provider
    getServicesOverview(
      providerId: number,
      version?: number,
    ): AxiosPromise<ServicesOverviewResponse> {
      return toAxiosResponse(mockedDelay(getServicesOverviewResponse()));
    },
    // get services overview of a provider
    getServicesOverviewHistory(
      providerId: number,
      version?: number,
    ): AxiosPromise<ServicesOverviewResponse> {
      return toAxiosResponse(mockedDelay(getServicesOverviewResponse()));
    },
    // get customers
    getCustomers(
      params?: GetCustomersParams,
      version?: number,
    ): AxiosPromise<CustomersResponse> {
      return toAxiosResponse(mockedDelay(getCustomersResponse()));
    },
    // get provider's subsidies
    getSubsidies(
      providerId: number,
      params?: GetSubsidiesParams,
      version?: number,
    ): AxiosPromise<SubsidiesResponse> {
      return toAxiosResponse(
        mockedDelay(getSubsidiesResponse(providerId, params)),
      );
    },
    // Export provider's subsidies
    exportSubsidies(
      providerId: number,
      params?: ExportSubsidiesParams,
      version?: number,
    ): AxiosPromise<Blob> {
      return toAxiosResponse(
        mockedDelay(getBlobResponse(params?.fileExtension)),
      );
    },
    // Get provider's subsidy
    getSubsidy(
      providerId: number,
      subsidyId: string,
      version?: number,
    ): AxiosPromise<SubsidyDetailResponse> {
      return toAxiosResponse(mockedDelay(getSubsidyDetailResponse(providerId)));
    },
    // Update logged in user password
    updateUserPassword(
      userPasswordUpdate: UserPasswordUpdate,
      version?: number,
    ): AxiosPromise<NoContentResponse> {
      return toAxiosResponse(mockedDelay());
    },
    // Get a provider's bills
    getProviderBills(
      providerId: number,
      params?: GetProviderBillsParams,
      version?: number,
    ): AxiosPromise<BillsResponse> {
      return toAxiosResponse(mockedDelay(getBillsResponse(params)));
    },
    // Get a provider's bill
    getProviderBill(
      providerId: number,
      billId: string,
      version?: number,
    ): AxiosPromise<BillDetailsResponse> {
      return toAxiosResponse(mockedDelay(getBillDetailsResponse(billId)));
    },
    // Get a provider's billing configuration
    getProviderBillingConfiguration(
      providerId: number,
      version?: number,
    ): AxiosPromise<BillingConfigurationResponse> {
      return toAxiosResponse(mockedDelay(getBillingConfigurationResponse()));
    },
    // Update a provider's billing configuration
    putProviderBillingConfiguration(
      providerId: number,
      contactPerson: ContactPerson,
      version?: number,
    ): AxiosPromise<NoContentResponse> {
      return toAxiosResponse(mockedDelay());
    },
    downloadBill(
      providerId: number,
      billId: string,
      version?: number,
    ): AxiosPromise<Blob> {
      return toAxiosResponse(mockedDelay(getBlobResponse()));
    },
    getVideos(
      params?: { language: LanguageCode },
      version?: number,
    ): AxiosPromise<VideosResponse> {
      return toAxiosResponse(mockedDelay(getVideosResponse()));
    },
    getVideoStreamId(
      videoId: string,
      version?: number,
    ): AxiosPromise<VideosStreamIdResponse> {
      return toAxiosResponse(mockedDelay(getVideoStreamId()));
    },
    streamVideo(streamId: string, version?: number): AxiosPromise<Blob> {
      return toAxiosResponse(mockedDelay(getBlobResponse()));
    },
    thumbnailVideo(videoId: string, version?: number): AxiosPromise<Blob> {
      return toAxiosResponse(mockedDelay(getBlobResponse()));
    },
  };
};
