import { useAsyncCallback, useEventListener } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  SwColumn,
  SwContainer,
  SwFetchErrorMessage,
  SwGrid,
  SwTabs,
  SwTitle,
  Tab,
} from '@kaa/ui-flanders/components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VideoTopicReference } from '@kaa/api/providers';
import { dataTest } from '../../datatest/keys';
import { useApi, useSelectedProviderIdState } from '../../utils';
import { sendPageViewEvent } from '../../utils/google-analytics';
import { RemittancesElectronic } from './electronic/RemittancesElectronic';
import { RemittancesPaper } from './paper/RemittancesPaper';
import { RemittancesSearchHistory } from './search-history/RemittancesSearchHistory';
import { TutorialVideoLink } from '../../components';
import { ShiftBy } from '../../components/shiftBy/ShiftBy';

export const RemittancesScreen = () => {
  const { t } = useTranslation();
  const { providers } = useApi();
  const providerId = useSelectedProviderIdState();

  const [selectedTab, setSelectedTab] = useState('1');

  const tabDefault = {
    tagName: 'a',
    className: 'vl-link vl-u-display-inline-flex',
  };

  const remittancesTabs: Tab[] = [
    {
      ...tabDefault,
      uid: '1',
      hash: '#electronic',
      header: t(i18nKeys.general.supportType.ELECTRONIC),
      'data-testid': dataTest.remittances.tabElectronic,
      onClick: () => {
        sendPageViewEvent({ pathname: '/electronic' });
      },
    },
    {
      ...tabDefault,
      uid: '2',
      hash: '#paper',
      header: t(i18nKeys.general.supportType.PAPER),
      'data-testid': dataTest.remittances.tabPaper,
      onClick: () => {
        sendPageViewEvent({ pathname: '/paper' });
      },
    },
  ].map((e) => ({
    ...e,
    isActive: e.uid === selectedTab,
  }));

  const [
    { value: overview, loading, error },
    getRemittancesOverview,
  ] = useAsyncCallback(
    async () => (await providers.getRemittancesOverview(providerId)).data.data,
    [providers],
    { loading: true },
  );

  useEffect(() => {
    const currentTab = remittancesTabs.find((tab) => {
      return tab.hash === window.location.hash;
    });

    if (currentTab) {
      setSelectedTab(currentTab.uid);
    }
  }, [setSelectedTab, remittancesTabs]);

  useEventListener(
    'hashchange',
    () => {
      const currentTab = remittancesTabs.find((tab) => {
        return tab.hash === window.location.hash;
      });

      if (currentTab) {
        setSelectedTab(currentTab.uid);
      }
    },
    window,
  );

  useEffect(() => {
    getRemittancesOverview();
  }, [getRemittancesOverview]);

  if (loading && !overview) {
    return <SwContainer loading />;
  }

  if (error || !overview) {
    return (
      <SwContainer error>
        <SwFetchErrorMessage onClick={getRemittancesOverview} />
      </SwContainer>
    );
  }

  return (
    <SwContainer>
      <SwGrid modStacked>
        <SwColumn>
          <SwTitle tagName="h1" className="vl-u-spacer--medium">
            {t(i18nKeys.navigation.remittances)}
          </SwTitle>
          <ShiftBy y={-30}>
            <TutorialVideoLink topic={VideoTopicReference.REMITTANCE} />
          </ShiftBy>
          <SwTabs tabs={remittancesTabs} />
        </SwColumn>
        <SwColumn className="vl-region--no-space-top ">
          {selectedTab === '1' && (
            <RemittancesElectronic
              overview={overview}
              overviewLoading={loading}
              getOverview={getRemittancesOverview}
            />
          )}
          {selectedTab === '2' && (
            <RemittancesPaper
              overview={overview}
              overviewLoading={loading}
              getOverview={getRemittancesOverview}
            />
          )}
        </SwColumn>
        <RemittancesSearchHistory
          overview={overview}
          overviewLoading={loading}
          getOverview={getRemittancesOverview}
        />
      </SwGrid>
    </SwContainer>
  );
};
