import { RegionCode } from '@kaa/api/providers';
import { useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  Icon,
  SwButton,
  SwDescriptionDataItem,
  SwLoader,
} from '@kaa/ui-flanders/components';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../../utils';
import { DashboardOverviewProps } from '../Dashboard.types';
import { overviewCardChildrenProps } from '../Dashboard.utils';
import { DashboardOverviewCard } from './components/DashboardOverviewCard';
import { DashboardOverviewCardLabel } from './components/DashboardOverviewCardLabel';

export const DashboardOverviewRemittances = ({
  providerId,
  regionCode,
}: DashboardOverviewProps) => {
  const { t } = useTranslation();
  const { providers } = useApi();

  const [
    { value, loading, error },
    getDashboardRemittancesOverview,
  ] = useAsyncCallback(
    async () =>
      (await providers.getDashboardRemittancesOverview(providerId)).data.data,
    [providers],
    { loading: true },
  );

  useEffect(() => {
    getDashboardRemittancesOverview();
  }, [getDashboardRemittancesOverview]);

  const overviewCardProps = {
    title: t(i18nKeys.dashboard.overview.remittances.title),
    icon: regionCode === RegionCode.BE_VLG ? Icon.VOUCHERS_LIST : Icon.AUTOFILE,
  };

  if (loading && !value) {
    return (
      <DashboardOverviewCard {...overviewCardProps}>
        <SwDescriptionDataItem
          {...overviewCardChildrenProps}
          label={<SwLoader modMessageHidden />}
          subdata={t(i18nKeys.dashboard.overview.remittances.refunded)}
        />
        <SwDescriptionDataItem
          {...overviewCardChildrenProps}
          label={<SwLoader modMessageHidden />}
          subdata={t(i18nKeys.dashboard.overview.remittances.incorrect)}
        />
        <SwDescriptionDataItem
          {...overviewCardChildrenProps}
          label={<SwLoader modMessageHidden />}
          subdata={t(i18nKeys.dashboard.overview.remittances.refused)}
        />
      </DashboardOverviewCard>
    );
  }

  if (error || !value) {
    return (
      <DashboardOverviewCard
        {...overviewCardProps}
        footer={
          <SwButton onClick={getDashboardRemittancesOverview}>
            {t(i18nKeys.errors.serviceUnavailableScreen.cta)}
          </SwButton>
        }
      >
        <p>{t(i18nKeys.errors.serviceUnavailableScreen.title)}</p>
      </DashboardOverviewCard>
    );
  }

  const { refunded, incorrect, refused } = value;

  return (
    <DashboardOverviewCard {...overviewCardProps}>
      <SwDescriptionDataItem
        {...overviewCardChildrenProps}
        label={<DashboardOverviewCardLabel text={refunded} />}
        subdata={t(i18nKeys.dashboard.overview.remittances.refunded)}
      />
      <SwDescriptionDataItem
        {...overviewCardChildrenProps}
        label={<DashboardOverviewCardLabel text={incorrect} />}
        subdata={t(i18nKeys.dashboard.overview.remittances.incorrect)}
      />
      <SwDescriptionDataItem
        {...overviewCardChildrenProps}
        label={<DashboardOverviewCardLabel text={refused} />}
        subdata={t(i18nKeys.dashboard.overview.remittances.refused)}
      />
    </DashboardOverviewCard>
  );
};
