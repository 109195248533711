import { PaperVoucherValidityReference } from '@kaa/api/providers';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import { AlertType } from '@kaa/ui-flanders/components';

export const getVoucherValidityAlert = (
  validity: PaperVoucherValidityReference,
  voucherReference: string,
  t = (str: string, obj?: any): string => str,
) => ({
  type:
    validity === PaperVoucherValidityReference.VALID
      ? AlertType.SUCCESS
      : AlertType.ERROR,
  title: t(
    validity === PaperVoucherValidityReference.VALID
      ? i18nKeys.remittances.modal.checkVoucherValidity.successMessage
      : i18nKeys.remittances.modal.checkVoucherValidity.errorMessage,
  ),
  msg: t(i18nKeys.general.paperVoucherValidityReference[validity], {
    voucherReference,
  }),
});
