import faker from 'faker';
import { DateTime } from 'luxon';
import {
  DashboardNextPickupOverview,
  DashboardPickupOverviewResponse,
  DashboardRemittancesActions,
  DashboardRemittancesActionsResponse,
  DashboardRemittancesOverview,
  DashboardRemittancesOverviewResponse,
  DashboardWorkersActions,
  DashboardWorkersActionsResponse,
  DashboardWorksActions,
  DashboardWorksActionsResponse,
  DashboardWorksOverview,
  DashboardWorksOverviewResponse,
} from '../model';
import { getPickup } from './pickups';

const kittenKiller = <Kitten>(kitten: Kitten): Kitten | undefined =>
  faker.random.boolean() ? kitten : undefined;

const getDashboardWorksOverview = (): DashboardWorksOverview => ({
  validated: faker.random.number({ min: 0, max: 10 }),
  waitingRemittance: faker.random.number({ min: 0, max: 10 }),
  contested: faker.random.number({ min: 0, max: 10 }),
});

const getDashboardRemittancesOverview = (): DashboardRemittancesOverview => ({
  refunded: faker.random.number({ min: 0, max: 10 }),
  incorrect: faker.random.number({ min: 0, max: 10 }),
  refused: faker.random.number({ min: 0, max: 10 }),
});

const getDashboardNextPickupOverview = (): DashboardNextPickupOverview => {
  const startDate = DateTime.fromJSDate(faker.date.future()).toISODate();
  const nextPickup = kittenKiller(
    getPickup({
      startDate,
      endDate: DateTime.fromISO(startDate)
        .plus({ month: 1 })
        .toISODate(),
    }),
  );

  return {
    nextPickup,
  };
};

const getDashboardRemittancesActions = (): DashboardRemittancesActions => ({
  ...kittenKiller({
    incorrect: {
      remittances: faker.random.number({
        min: 1,
        max: 10,
      }),
      vouchers: faker.random.number({
        min: 1,
        max: 10,
      }),
    },
  }),
  ...kittenKiller({
    refused: {
      remittances: faker.random.number({
        min: 1,
        max: 10,
      }),
      vouchers: faker.random.number({
        min: 1,
        max: 10,
      }),
    },
  }),
});

const getDashboardWorksActions = (): DashboardWorksActions => ({
  ...kittenKiller({
    aboutToExpire: faker.random.number({
      min: 1,
      max: 10,
    }),
  }),
  ...kittenKiller({
    waitingForValidation: faker.random.number({
      min: 1,
      max: 10,
    }),
  }),
  ...kittenKiller({
    contested: faker.random.number({
      min: 1,
      max: 10,
    }),
  }),
});

const getDashboardWorkersActions = (): DashboardWorkersActions => ({
  ...kittenKiller({
    missingContract: faker.random.number({
      min: 1,
      max: 10,
    }),
  }),
  ...kittenKiller({
    needResetPassword: faker.random.number({
      min: 1,
      max: 10,
    }),
  }),
});

export const getDashboardWorksOverviewResponse = (): DashboardWorksOverviewResponse => ({
  data: getDashboardWorksOverview(),
});

export const getDashboardRemittancesOverviewResponse = (): DashboardRemittancesOverviewResponse => ({
  data: getDashboardRemittancesOverview(),
});

export const getDashboardPickupOverviewResponse = (): DashboardPickupOverviewResponse => ({
  data: getDashboardNextPickupOverview(),
});

export const getDashboardRemittancesActionsResponse = (): DashboardRemittancesActionsResponse => ({
  data: getDashboardRemittancesActions(),
});

export const getDashboardWorksActionsResponse = (): DashboardWorksActionsResponse => ({
  data: getDashboardWorksActions(),
});

export const getDashboardWorkersActionsResponse = (): DashboardWorkersActionsResponse => ({
  data: getDashboardWorkersActions(),
});
