import { Activity, WorkerSummary } from '@kaa/api/providers';
import { Format } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AutocompleteInputField,
  Icon,
  SwBadge,
  SwIcon,
  SwInputTextField,
  SwLink,
  SwLoader,
} from '@kaa/ui-flanders/components';
import { Field, FormikProps } from 'formik';
import get from 'lodash.get';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getPath, getWorkerDetailsPath, Routes } from '../../../../../routes';
import { IncorrectVoucherFieldNames } from './RemittancesSearchHistoryIncorrect.constants';
import { IncorrectVoucherWithValidation } from './RemittancesSearchHistoryIncorrect.types';
import {
  getAutocompleteWorkersOption,
  menuWorkerOptionFormat,
  onSelectWorkerOption,
} from './RemittancesSearchHistoryIncorrect.utils';
import { RemittancesSearchHistoryIncorrectEditRowFormActivities } from './RemittancesSearchHistoryIncorrectEditRowFormActivities';

type RemittancesSearchHistoryIncorrectEditRowFormProps = FormikProps<
  IncorrectVoucherWithValidation
> & {
  selectedActivities: Activity[];
  setFocusRow: (voucherReference: string) => void;
  focusedRow: string;
  getWorkers: (
    query: string,
  ) => Promise<[Error | undefined, WorkerSummary[] | undefined]>;
  validated?: boolean;
  providerId: number;
  registerRequired: boolean;
  contractRequired: boolean;
};

export const RemittancesSearchHistoryIncorrectEditRowForm = ({
  selectedActivities,
  setFocusRow,
  focusedRow,
  getWorkers,
  validated,
  providerId,
  registerRequired,
  contractRequired,
  ...formikProps
}: RemittancesSearchHistoryIncorrectEditRowFormProps) => {
  const {
    validateForm,
    handleSubmit,
    resetForm,
    dirty,
    isSubmitting,
    values,
  } = formikProps;

  const { t } = useTranslation();

  const [currentProviderId, setCurrentProviderId] = useState(
    Number(providerId),
  );

  const isValidRegionalAgreementId = useRef(
    !get(values.errors, IncorrectVoucherFieldNames.REGIONAL_AGREEMENT_ID) &&
      !get(
        values.errors,
        `server.${IncorrectVoucherFieldNames.REGIONAL_AGREEMENT_ID}`,
      ),
  );

  const isValidWorkerNiss = useRef(
    !get(values.errors, IncorrectVoucherFieldNames.WORKER_NISS) &&
      !get(values.errors, `server.${IncorrectVoucherFieldNames.WORKER_NISS}`),
  );

  const isValidServiceDate = useRef(
    !get(values.errors, IncorrectVoucherFieldNames.SERVICE_DATE) &&
      !get(values.errors, `server.${IncorrectVoucherFieldNames.SERVICE_DATE}`),
  );

  useEffect(() => {
    if (values.errors?.server) {
      validateForm();
    }
  }, [validateForm, values.errors?.server]);

  const buttonStyle = (
    disabled: boolean,
    color: string,
    colorDisabled: string,
  ) => ({
    border: 'none',
    textDecoration: 'underline',
    cursor: !disabled ? 'pointer' : 'default',
    color: !disabled ? color : colorDisabled,
  });

  return (
    <>
      <td data-title={t(i18nKeys.general.label.record)}>
        <span className="alignedCel"> {values.voucherReference}</span>
      </td>
      <td data-title={t(i18nKeys.general.label.voucher)}>
        {validated || isValidRegionalAgreementId.current ? (
          <span className="alignedCel">{values.regionalAgreementId}</span>
        ) : (
          <Field
            id={`${values.voucherReference}-${IncorrectVoucherFieldNames.REGIONAL_AGREEMENT_ID}`}
            name={IncorrectVoucherFieldNames.REGIONAL_AGREEMENT_ID}
            column={{ width: '12' }}
            component={SwInputTextField}
          />
        )}
      </td>
      <td data-title={t(i18nKeys.general.label.nissWorker)}>
        {validated || isValidWorkerNiss.current ? (
          <span className="alignedCel">{values.workerNiss}</span>
        ) : (
          <Field
            id={`${values.voucherReference}-${IncorrectVoucherFieldNames.WORKER_NISS}`}
            name={IncorrectVoucherFieldNames.WORKER_NISS}
            column={{ width: '12' }}
            autoComplete="off"
            getOptions={getAutocompleteWorkersOption(getWorkers)}
            menuOptionFormat={menuWorkerOptionFormat}
            onSelectOption={onSelectWorkerOption(
              IncorrectVoucherFieldNames.WORKER_NISS,
              setCurrentProviderId,
            )}
            minLength={2}
            component={AutocompleteInputField}
          />
        )}
      </td>
      <td data-title={t(i18nKeys.general.label.date)}>
        {validated || isValidServiceDate.current ? (
          <span className="alignedCel">{values.serviceDate}</span>
        ) : (
          <Field
            id={`${values.voucherReference}-${IncorrectVoucherFieldNames.SERVICE_DATE}`}
            name={IncorrectVoucherFieldNames.SERVICE_DATE}
            column={{ width: '12' }}
            component={SwInputTextField}
          />
        )}
      </td>
      <td data-title={t(i18nKeys.general.label.activity)}>
        <RemittancesSearchHistoryIncorrectEditRowFormActivities
          {...formikProps}
          selectedActivities={selectedActivities}
          validated={validated}
        />
      </td>
      <td style={{ paddingRight: 0 }} className="vl-u-align-center">
        <span className="alignedCel">
          {values.isWorkerRegistered ? (
            t(i18nKeys.remittances.general.yes)
          ) : (
            <>
              {registerRequired ? (
                <SwLink className="vl-form__error" to={getPath(Routes.WORKERS)}>
                  {t(i18nKeys.remittances.general.no)}
                </SwLink>
              ) : (
                '-'
              )}
            </>
          )}
        </span>
      </td>
      <td style={{ paddingRight: 0 }} className="vl-u-align-center">
        <span className="alignedCel">
          {values.isWorkerContracted ? (
            t(i18nKeys.remittances.general.present)
          ) : (
            <>
              {contractRequired ? (
                <SwLink
                  className="vl-form__error"
                  to={getWorkerDetailsPath(values.workerId, currentProviderId)}
                >
                  {t(i18nKeys.remittances.general.missing)}
                </SwLink>
              ) : (
                '-'
              )}
            </>
          )}
        </span>
      </td>
      <td style={{ paddingRight: 0 }}>
        <span className="alignedCel">
          {Format.date(values.correctionLimitDate)}
        </span>
      </td>
      <td className="vl-u-align-center">
        {focusedRow === values.voucherReference ? (
          <div className="vl-u-display-flex vl-u-flex-v-left">
            <button
              type="button"
              style={buttonStyle(!dirty || isSubmitting, '#0055cc', '#cbd2da')}
              disabled={isSubmitting}
              onClick={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              {isSubmitting ? (
                <SwLoader
                  style={{ display: 'inline-block' }}
                  modMessageHidden
                />
              ) : (
                <SwIcon modLarge icon={Icon.SAVE} />
              )}
              <br />
              {t(i18nKeys.general.cta.save)}
            </button>
            <button
              type="button"
              style={buttonStyle(isSubmitting, '#0055cc', '#cbd2da')}
              disabled={isSubmitting}
              onClick={(e) => {
                setFocusRow('');

                if (dirty) {
                  resetForm();
                }
                e.preventDefault();
              }}
            >
              <SwIcon modLarge icon={Icon.CLOSE} />
              <br />
              {t(i18nKeys.general.cta.cancel)}
            </button>
          </div>
        ) : (
          <SwBadge
            style={{
              marginRight: '.5rem',
              minWidth: '2.75rem',
              width: '2.75rem',
              height: '2.75rem',
            }}
            modMedium
            modWarning={!validated}
            modSuccess={validated}
            icon={validated ? Icon.CHECK_CIRCLE : Icon.ALERT_CIRCLE}
          />
        )}
      </td>
    </>
  );
};
