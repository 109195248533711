import { LanguageCode, Title } from '@kaa/api/providers';
import { Format } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import { SwInputTextField, SwSelectField } from '@kaa/ui-flanders/components';
import { Column } from '@kaa/ui-flanders/components/core/SwColumn';
import { Field } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContactPersonsFormFieldNames } from './ContactPersonForm.constant';
import { getFieldName } from './ContactPersonForm.utils';

type ContactPersonsDataProps = {
  titles: Title[];
  fieldNamePrefix?: string;
  column?: Column;
  labelColumn?: Column;
  focus?: ContactPersonsFormFieldNames;
};

export const ContactPersonsForm = ({
  fieldNamePrefix,
  titles,
  column,
  labelColumn,
  focus,
}: ContactPersonsDataProps) => {
  const { t } = useTranslation();

  const languageOption = Object.values(LanguageCode)
    .map((language) => ({
      value: language,
      text: t(i18nKeys.general.language[language]),
    }))
    // Remove english from languages
    .filter((e) => e.value !== LanguageCode.EN);

  return (
    <>
      <Field
        name={getFieldName(
          ContactPersonsFormFieldNames.TITLE_CODE,
          fieldNamePrefix,
        )}
        label={t(i18nKeys.general.label.gender)}
        column={column}
        labelColumn={labelColumn}
        component={SwSelectField}
        modFocus={focus === ContactPersonsFormFieldNames.TITLE_CODE}
        options={titles.map(({ code, name }) => ({ text: name, value: code }))}
      />
      <Field
        name={getFieldName(
          ContactPersonsFormFieldNames.FIRSTNAME,
          fieldNamePrefix,
        )}
        label={t(i18nKeys.general.label.firstname)}
        column={column}
        labelColumn={labelColumn}
        component={SwInputTextField}
        modFocus={focus === ContactPersonsFormFieldNames.FIRSTNAME}
      />
      <Field
        name={getFieldName(
          ContactPersonsFormFieldNames.LASTNAME,
          fieldNamePrefix,
        )}
        label={t(i18nKeys.general.label.name)}
        column={column}
        labelColumn={labelColumn}
        component={SwInputTextField}
        modFocus={focus === ContactPersonsFormFieldNames.LASTNAME}
      />
      <Field
        name={getFieldName(ContactPersonsFormFieldNames.ROLE, fieldNamePrefix)}
        label={t(i18nKeys.general.label.function)}
        column={column}
        labelColumn={labelColumn}
        component={SwInputTextField}
        modFocus={focus === ContactPersonsFormFieldNames.ROLE}
      />
      <Field
        name={getFieldName(
          ContactPersonsFormFieldNames.PHONE_NUMBER,
          fieldNamePrefix,
        )}
        label={t(i18nKeys.general.label.phone)}
        column={column}
        labelColumn={labelColumn}
        component={SwInputTextField}
        modFocus={focus === ContactPersonsFormFieldNames.PHONE_NUMBER}
        parse={(value: string) => (value ? Format.phoneNumber(value) : '')}
      />
      <Field
        name={getFieldName(
          ContactPersonsFormFieldNames.MOBILE_PHONE_NUMBER,
          fieldNamePrefix,
        )}
        label={t(i18nKeys.general.label.mobilePhone)}
        column={column}
        labelColumn={labelColumn}
        component={SwInputTextField}
        modFocus={focus === ContactPersonsFormFieldNames.MOBILE_PHONE_NUMBER}
        parse={(value: string) =>
          value ? Format.mobilePhoneNumber(value) : ''
        }
      />
      <Field
        name={getFieldName(
          ContactPersonsFormFieldNames.EMAIL_ADDRESS,
          fieldNamePrefix,
        )}
        label={t(i18nKeys.general.label.emailAddress)}
        column={column}
        labelColumn={labelColumn}
        component={SwInputTextField}
        modFocus={focus === ContactPersonsFormFieldNames.EMAIL_ADDRESS}
      />
      <Field
        name={getFieldName(
          ContactPersonsFormFieldNames.LANGUAGE,
          fieldNamePrefix,
        )}
        column={column}
        labelColumn={labelColumn}
        label={t(i18nKeys.general.label.language)}
        component={SwSelectField}
        modFocus={focus === ContactPersonsFormFieldNames.LANGUAGE}
        options={languageOption}
      />
    </>
  );
};
