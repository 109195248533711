import { ServiceActiveStatus } from '@kaa/api/providers';
import { useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AlertType,
  getAlertPropsByType,
  SwAlert,
  SwContainer,
  SwFetchErrorMessage,
  SwForm,
  SwFormGrid,
  SwModalRenderProps,
  SwTitle,
  toggleModalById,
} from '@kaa/ui-flanders/components';
import { Formik } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modals } from '../../../../constants';
import { useApi, useSelectedProviderIdState } from '../../../../utils';
import { PrestationForm } from '../form/PrestationForm';
import { PrestationFormData } from '../form/PrestationForm.types';
import {
  getPrestationStatusAlert,
  getRequiredDefaultWorksFields,
} from '../form/PrestationForm.utils';
import { PrestationContestedAlert } from '../PrestationContestedAlert';

type PrestationDetailsModalProps = {
  prestationId: string;
  searchedProviderId: number;
};

export const PrestationDetailsModal = ({
  prestationId,
  searchedProviderId,
}: SwModalRenderProps<PrestationDetailsModalProps>) => {
  const { t } = useTranslation();
  const { providers } = useApi();
  const providerId = useSelectedProviderIdState();

  const [{ value, loading, error }, getPrestationData] = useAsyncCallback(
    async () => {
      const [
        {
          data: {
            data: { headquarter, subsidiaries },
          },
        },
        {
          data: { data },
        },
      ] = await Promise.all([
        providers.getProviderRelatives(providerId),
        providers.getService(searchedProviderId, prestationId),
      ]);

      return {
        relatives: [
          headquarter,
          ...subsidiaries.filter(({ isActive }) => isActive),
        ],
        prestation: data,
      };
    },
    [providers, searchedProviderId, prestationId],
    { loading: true },
  );

  useEffect(() => {
    getPrestationData();
  }, [getPrestationData]);

  if (loading && !value) {
    return (
      <>
        <SwTitle tagName="h2">
          {t(i18nKeys.prestations.modals.details.title)}
        </SwTitle>
        <SwContainer loading />
      </>
    );
  }
  if (error || !value) {
    return (
      <SwContainer error>
        <SwFetchErrorMessage
          onClick={() => {
            getPrestationData();
          }}
        />
      </SwContainer>
    );
  }

  const close = () => {
    toggleModalById(Modals.PRESTATIONS_DETAILS_PRESTATION_MODAL);
  };

  const { relatives, prestation } = value;

  const status = getPrestationStatusAlert(prestation, t);

  return (
    <>
      <SwTitle tagName="h2">
        {t(i18nKeys.prestations.modals.details.title)}
      </SwTitle>
      {status && (
        <SwAlert
          {...getAlertPropsByType(AlertType.WARNING)}
          title={status.title}
        >
          {prestation.status !== ServiceActiveStatus.CONTESTED && status.text && (
            <>
              {Array.isArray(status.text)
                ? status.text.map((e) => (
                    <span
                      key={JSON.stringify(e)}
                      style={{
                        display: 'block',
                      }}
                    >
                      {e}
                    </span>
                  ))
                : status.text}
            </>
          )}
          {prestation.status === ServiceActiveStatus.CONTESTED && (
            <PrestationContestedAlert prestation={prestation} />
          )}
        </SwAlert>
      )}

      <Formik<Partial<PrestationFormData>>
        onSubmit={close}
        initialValues={getRequiredDefaultWorksFields(prestation)}
        render={() => {
          return (
            <SwForm>
              <SwFormGrid modStacked>
                <PrestationForm
                  relatives={relatives}
                  modWithoutComment
                  modDisabled
                  comments={prestation.comments}
                  customerFullname={prestation.customerFullname}
                />
              </SwFormGrid>
            </SwForm>
          );
        }}
      />
    </>
  );
};
