import {
  ApiErrorField,
  FieldValidationErrorCode,
  RemittanceIncorrectVouchersBundle,
  WorkerSummary,
} from '@kaa/api/providers';
import { ValueOf } from '@kaa/common/types';
import {
  required,
  ValidationError,
  ValidatorFunction,
} from '@kaa/common/validation';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import { FormikHandlers, FormikProps } from 'formik';
import get from 'lodash.get';
import React from 'react';
import { FORM_ERROR_BY_CODE } from '../../../../../utils/map-server-error-code-to-translation-key';
import {
  createValidatorWithServerErrorHandled,
  getErrorFromApiErrorField,
  getServerErrorPathInFormValuesError,
  mapperServerError,
} from '../../../../../utils/validation';

export const mapperVoucherFieldNameError = (validations: ApiErrorField[]) => {
  const errors = getErrorFromApiErrorField<
    Partial<RemittanceIncorrectVouchersBundle>,
    FieldValidationErrorCode,
    ApiErrorField
  >({ validations });

  return errors;
};

export const getErrorsFromIncorrectVoucher = (
  paperVoucher: RemittanceIncorrectVouchersBundle,
) => {
  const { errors } = mapperServerError<
    Partial<RemittanceIncorrectVouchersBundle>,
    FieldValidationErrorCode
  >(mapperVoucherFieldNameError(paperVoucher.voucherErrors));

  return errors;
};

const FORM_VOUCHERS_ERROR_BY_CODE = ({
  ...FORM_ERROR_BY_CODE,
  [FieldValidationErrorCode.WORKER_CONTRACT_MISSING]: {
    id: i18nKeys.errors.server.form.NOT_REGISTERED,
    defaultMessage: 'Must be registered',
  },
  [FieldValidationErrorCode.UNREGISTERED_WORKER]: {
    id: i18nKeys.errors.server.form.NOT_REGISTERED,
    defaultMessage: 'Must be registered',
  },
} as any) as {
  [key in ValueOf<typeof FieldValidationErrorCode>]: ValidationError;
} &
  {
    [key in ValueOf<typeof FieldValidationErrorCode>]: ValidationError;
  } & {
    DEFAULT: ValidationError;
  };

const serverErrorsSetInFormValues = (fieldName: string): ValidatorFunction => {
  return (value: string, values: any): ValidationError | false => {
    const firstError = (Object.values({
      ...FieldValidationErrorCode,
    }) as Array<FieldValidationErrorCode & FieldValidationErrorCode>)
      .map((errorCode: FieldValidationErrorCode & FieldValidationErrorCode) => {
        return get(
          values,
          getServerErrorPathInFormValuesError(fieldName, errorCode),
        )
          ? FORM_VOUCHERS_ERROR_BY_CODE[errorCode] ||
              FORM_VOUCHERS_ERROR_BY_CODE.DEFAULT
          : null;
      })
      .filter(Boolean)[0];

    if (firstError) {
      return firstError;
    }
    return false;
  };
};

export const validateVoucherRow = createValidatorWithServerErrorHandled(
  {
    regionalAgreementId: [required],
    workerNiss: [required],
    serviceDate: [required],
    activityTypes: [required],
  },
  { displayFocussed: true },
  serverErrorsSetInFormValues,
);

export const getAutocompleteWorkersOption = (
  getWorkers: (
    query: string,
  ) => Promise<[Error | undefined, WorkerSummary[] | undefined]>,
) => async (
  query: string,
  field: {
    onChange: FormikHandlers['handleChange'];
    onBlur: FormikHandlers['handleBlur'];
    value: string;
    name: string;
  },
  form: FormikProps<RemittanceIncorrectVouchersBundle>,
) => {
  if (!query) {
    return [];
  }

  const [, value = []] = await getWorkers(query);

  return value;
};

export const onSelectWorkerOption = (
  fieldName: string,
  setProviderId: React.Dispatch<React.SetStateAction<number>>,
) => async (
  { id, isContracted, isRegistered, niss, providerId }: WorkerSummary,
  field: {
    onChange: FormikHandlers['handleChange'];
    onBlur: FormikHandlers['handleBlur'];
    value: string;
    name: string;
  },
  form: FormikProps<RemittanceIncorrectVouchersBundle>,
) => {
  const { setFieldValue } = form;
  setFieldValue(fieldName, niss);
  setFieldValue('isWorkerRegistered', isRegistered);
  setFieldValue('isWorkerContracted', isContracted);
  setFieldValue('workerId', id);
  setProviderId(() => Number(providerId));
};

export const menuWorkerOptionFormat = ({
  firstName,
  lastName,
  niss,
}: WorkerSummary) => `${firstName} ${lastName} - ${niss}`;
