import faker from 'faker';
import { CustomersResponse, CustomerStatus } from '../model';
import { getFullName, getId, getRandomArray } from './utils';

export const getCustomer = () => {
  return {
    id: getId(),
    fullname: getFullName(),
    status: faker.helpers.randomize(Object.values(CustomerStatus)),
  };
};

export const getCustomersResponse = (): CustomersResponse => {
  return {
    data: getRandomArray({ min: 1, max: 10 }).map(getCustomer),
  };
};
