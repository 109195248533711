/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */

export type ContactPersonActionType = 'CAN_DELETE' | 'CAN_EDIT';

export const ContactPersonActionType = {
  CAN_DELETE: 'CAN_DELETE' as ContactPersonActionType,
  CAN_EDIT: 'CAN_EDIT' as ContactPersonActionType,
};
