import { getActionsMock, getResourceIdMock } from '@kaa/api/common/mocks';
import faker from 'faker';
import {
  Activity,
  Agreement,
  AgreementActionType,
  AgreementResponse,
  AgreementStatus,
} from '../model';

export const RESOURCE_PREFIX_AGREEMENT = 'agreement';

export const getAgreement = (agreement?: Partial<Agreement>): Agreement => {
  return {
    activityType: faker.helpers.randomize<Activity>(Object.values(Activity)),
    status: faker.helpers.randomize<AgreementStatus>(
      Object.values(AgreementStatus),
    ),
    resourceId: getResourceIdMock(RESOURCE_PREFIX_AGREEMENT),
    isVisible: faker.random.boolean(),
    issueDate: faker.helpers
      .randomize([faker.date.future(), faker.date.past()])
      .toISOString(),
    withdrawalDate: faker.random.arrayElement([
      faker.helpers
        .randomize([faker.date.future(), faker.date.past()])
        .toISOString(),
      undefined,
    ]),
    suspensionDate: faker.random.arrayElement([
      faker.helpers
        .randomize([faker.date.future(), faker.date.past()])
        .toISOString(),
      undefined,
    ]),
    ...agreement,
  };
};

export const getAgreementsResponse = (): AgreementResponse => {
  const mockedActivities = Object.values(Activity).map((activity) => ({
    activityType: activity,
    resourceId: getResourceIdMock(RESOURCE_PREFIX_AGREEMENT),
  }));

  return {
    data: mockedActivities.map((activity) => getAgreement(activity)),
    actions: mockedActivities.reduce(
      (acc, { resourceId }) => ({
        ...acc,
        [resourceId]: getActionsMock(AgreementActionType),
      }),
      {},
    ),
  };
};
