export enum WorkerFormFieldNames {
  GENDER = 'gender',
  FIRSTNAME = 'firstName',
  LASTNAME = 'lastName',
  EMAIL_ADDRESS = 'emailAddress',
  NISS = 'niss',
  LANGUAGE = 'language',
  BIRTHDATE = 'birthDate',
  REFERENCE = 'reference',
  FOREIGNER = 'isForeigner',
  POSTCODE = 'postcode',
  PHONE_NUMBER = 'mobilePhoneNumber',
}
