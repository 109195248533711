import { Locations, ProvinceCode, RegionCode } from '@kaa/api/providers';
import { ValueOf } from '@kaa/common/types';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  SwCheckboxField,
  SwColumn,
  SwFormColumn,
  SwFormGrid,
  SwGrid,
  SwTitle,
} from '@kaa/ui-flanders/components';
import { Field } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

const CodeToTranslationKey = (code: RegionCode | ProvinceCode): string => {
  switch (code) {
    case RegionCode.BE_VLG:
      return i18nKeys.visibility.locations.byRegionsAndProvinces.flanders;
    case RegionCode.BE_WAL:
      return i18nKeys.visibility.locations.byRegionsAndProvinces.wallonia;
    case RegionCode.BE_BRU:
      return i18nKeys.visibility.locations.byRegionsAndProvinces.brussels;
    case ProvinceCode.BEVOV:
      return i18nKeys.visibility.locations.byRegionsAndProvinces.eastFlanders;
    case ProvinceCode.BEVWV:
      return i18nKeys.visibility.locations.byRegionsAndProvinces.westFlanders;
    case ProvinceCode.BEVAN:
      return i18nKeys.visibility.locations.byRegionsAndProvinces.antwerp;
    case ProvinceCode.BEVBR:
      return i18nKeys.visibility.locations.byRegionsAndProvinces.flemishBrabant;
    case ProvinceCode.BEVLI:
      return i18nKeys.visibility.locations.byRegionsAndProvinces.limburg;
    case ProvinceCode.BEWHT:
      return i18nKeys.visibility.locations.byRegionsAndProvinces.hainaut;
    case ProvinceCode.BEWBR:
      return i18nKeys.visibility.locations.byRegionsAndProvinces.walloonBrabant;
    case ProvinceCode.BEWLG:
      return i18nKeys.visibility.locations.byRegionsAndProvinces.liege;
    case ProvinceCode.BEWNA:
      return i18nKeys.visibility.locations.byRegionsAndProvinces.namur;
    case ProvinceCode.BEWLX:
      return i18nKeys.visibility.locations.byRegionsAndProvinces.luxembourg;
    default:
      throw Error(`Translation key not found for code ${code}`);
  }
};

type VisibilityByRegionsProvincesProps = {
  availableLocations: Locations;
  activeProvinces: { [provinceCode in ValueOf<typeof ProvinceCode>]: boolean };
  activeRegions: { [regionCode in ValueOf<typeof RegionCode>]: boolean };
  mutatorSetValue: (fieldName: string, value: any) => void;
  modDisabled: boolean;
};

export const VisibilityByRegionsProvinces = ({
  availableLocations,
  activeProvinces,
  activeRegions,
  mutatorSetValue,
  modDisabled,
}: VisibilityByRegionsProvincesProps) => {
  const { t } = useTranslation();

  return (
    <SwGrid modStacked className="vl-u-flex-v-flex-start">
      <SwColumn>
        <SwTitle tagName="h4">
          {t(i18nKeys.visibility.locations.byRegionsAndProvinces.title)}
        </SwTitle>
      </SwColumn>
      <SwColumn width="6">
        {availableLocations.regions.map((region) => {
          const provincesForThatRegion = availableLocations.provinces.filter(
            ({ regionCode }) => regionCode === region.code,
          );

          if (provincesForThatRegion.length) {
            const nbrOfActiveProvinces = provincesForThatRegion.reduce(
              (acc, { code }) => acc + +activeProvinces[code],
              0,
            );
            if (activeRegions[region.code] && nbrOfActiveProvinces < 5) {
              mutatorSetValue(`regions.${region.code}`, false);
            }

            if (!activeRegions[region.code] && nbrOfActiveProvinces === 5) {
              mutatorSetValue(`regions.${region.code}`, true);
            }
          }

          return (
            <SwFormGrid key={region.code}>
              <Field
                name={`regions.${region.code}`}
                label={t(CodeToTranslationKey(region.code))}
                component={SwCheckboxField}
                modDisabled={modDisabled}
                inputOnChange={() => {
                  provincesForThatRegion.forEach((province) =>
                    mutatorSetValue(
                      `provinces.${province.code}`,
                      !activeRegions[region.code],
                    ),
                  );
                }}
              />
              <SwFormColumn push="1">
                <SwFormGrid>
                  {provincesForThatRegion.map((province) => (
                    <Field
                      key={province.code}
                      name={`provinces.${province.code}`}
                      label={t(CodeToTranslationKey(province.code))}
                      component={SwCheckboxField}
                      modDisabled={modDisabled}
                    />
                  ))}
                </SwFormGrid>
              </SwFormColumn>
            </SwFormGrid>
          );
        })}
      </SwColumn>
    </SwGrid>
  );
};
