import { i18nKeys } from '@kaa/i18n/providers/keys';
import { SwLink, SwTitle } from '@kaa/ui-flanders/components';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { getPath, Routes } from '../../../../routes';

const TIPS_URLS = {
  item_3: `${getPath(
    Routes.REMITTANCES,
    // TODO: add queryParams to open the popup
  )}#paper`,
};

export const PickupTipsModal = () => {
  const { t } = useTranslation();
  const { title, intro, outro, list } = i18nKeys.pickup.modal.tips;

  const tips = Object.keys(list).map((key) =>
    !TIPS_URLS[key as keyof typeof TIPS_URLS]
      ? list[key as keyof typeof list]
      : {
          text: list[key as keyof typeof list],
          url: TIPS_URLS[key as keyof typeof TIPS_URLS],
        },
  );

  return (
    <>
      <SwTitle tagName="h2" className="vl-u-spacer">
        {t(title)}
      </SwTitle>
      <p className="vl-u-spacer">{t(intro)}</p>
      {tips.length && (
        <ul className="vl-link-list vl-u-spacer">
          {tips.map((e) => (
            <li
              key={JSON.stringify(e)}
              className="vl-link-list__item"
              style={{ display: 'flex' }}
            >
              <i
                className="vl-link__icon vl-link__icon--before vl-vi vl-vi-arrow-right-fat"
                aria-hidden="true"
                style={{ marginTop: '0.5rem' }}
              />
              <div>
                {typeof e === 'object' ? (
                  <Trans i18nKey={e.text}>
                    <SwLink to={e.url} style={{ padding: 0 }} />
                  </Trans>
                ) : (
                  t(e)
                )}
              </div>
            </li>
          ))}
        </ul>
      )}
      <p>{t(outro)}</p>
    </>
  );
};
