import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AlertType,
  getAlertPropsByType,
  SwAlert,
} from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

type ActivationAlertProps = {
  deOrActivateWorkerHasError: boolean;
  activation: boolean;
  close: () => void;
};

export const ActivationAlert = ({
  deOrActivateWorkerHasError,
  activation,
  close,
}: ActivationAlertProps) => {
  const { t } = useTranslation();
  const activationLokalisedKey = activation
    ? i18nKeys.workers.modals.activateWorker.worker
    : i18nKeys.workers.modals.deactivateWorker.worker;
  return (
    <SwAlert
      {...getAlertPropsByType(
        deOrActivateWorkerHasError ? AlertType.ERROR : AlertType.SUCCESS,
      )}
      title={t(
        activationLokalisedKey[
          deOrActivateWorkerHasError ? 'failure' : 'success'
        ],
      )}
      close={close}
      closable
      modSmall
    />
  );
};
