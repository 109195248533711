import { ValidationError } from '@kaa/common/validation';
import { i18nKeys } from '@kaa/i18n/providers/keys';

export const isValidVoucherNumber = (
  value: string,
): ValidationError | false => {
  if (
    value &&
    value.length !== 16 &&
    value.length !== 17 &&
    value.length !== 33
  ) {
    return {
      defaultMessage: 'Invalid voucher format',
      id: i18nKeys.errors.form.voucherFormat,
    };
  }

  return false;
};
