import {
  ExportSubsidiesParams,
  FileExtensionReference,
} from '@kaa/api/providers';
import { httpTo } from '@kaa/api/providers/utilities';
import { useAsyncCallback } from '@kaa/common/utils';
import { DownloadTemplates } from '@kaa/core-app/providers/components';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  SwFetchErrorMessage,
  SwLoader,
  SwTitle,
} from '@kaa/ui-flanders/components';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../../../../utils';
import { getApplicationType } from '../../../../../utils/templates';

type RemittancesSubsidiesDownloadModal = {
  providerId: number;
  parameters?: ExportSubsidiesParams;
};
export const RemittancesSubsidiesDownloadModal = ({
  providerId,
  parameters,
}: RemittancesSubsidiesDownloadModal) => {
  const { t } = useTranslation();
  const { providers } = useApi();

  const [
    { value: templateLink, loading, error },
    getSubsidiesExport,
  ] = useAsyncCallback(
    async () => {
      const [, payload] = await httpTo(
        providers.exportSubsidies(providerId, parameters),
      );

      if (!payload) {
        return undefined;
      }

      const blob = new Blob([payload.data as BlobPart], {
        type: getApplicationType(
          parameters?.fileExtension || FileExtensionReference.XLSX,
        ),
      });

      const url = window.URL.createObjectURL(blob);

      return { url, blob };
    },
    [providers],
    { loading: true },
  );

  useEffect(() => {
    getSubsidiesExport();
  }, [getSubsidiesExport]);

  if (loading) {
    return <SwLoader />;
  }

  if (error || !templateLink) {
    return <SwFetchErrorMessage onClick={getSubsidiesExport} />;
  }

  return (
    <>
      <SwTitle tagName="h2" className="vl-u-spacer--medium">
        {t(i18nKeys.remittances.reimbursement.modal.export.title)}
      </SwTitle>
      <DownloadTemplates
        linkToTemplate={templateLink.url}
        fileName={t(
          i18nKeys.remittances.reimbursement.modal.export.downloadTemplate
            .fileName,
        )}
        blob={templateLink.blob}
      />
    </>
  );
};
