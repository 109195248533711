import { ApiMode } from '@kaa/api/common';
import { RegionCode } from '@kaa/api/providers';
import { AuthProvider, AuthService, AuthServiceMock } from '@kaa/auth/common';
import {
  Logout,
  ChangeTitleRoute,
  RemoveIfInIframe,
} from '@kaa/core-app/common/components';
import { ActionProvider, AlertProvider } from '@kaa/common/context';

import { BuildConfig, getConfig } from '@kaa/core-app/common/config';
import {
  ActiveRouteProvider,
  Breadcrumb,
  LoadAuthUser,
  LoadUser,
  PageViewGTMListener,
  PrivateRoute,
  VlFunctionalHeader,
} from '@kaa/core-app/providers/components';
import { UserAccessTokenExpiringListener } from '@kaa/core-app/providers/components/headless/UserAccessTokenExpiringListener';
import { UserSignedOutListener } from '@kaa/core-app/providers/components/headless/UserSignedOutListener';
import { TITLE_ROUTES } from '@kaa/core-app/providers/constants/titleRoutes';
import { getI18nInstance } from '@kaa/core-app/providers/i18n';
import { getPath, getRouterPath, Routes } from '@kaa/core-app/providers/routes';
import {
  ActivitiesScreen,
  ContactPersonDetailScreen,
  ContactPersonsScreen,
  DashboardScreen,
  EnterpriseRootScreen,
  EnterpriseScreen,
  ForbiddenWrongRegion,
  LoginScreen,
  NotFoundScreen,
  PickupBillingScreen,
  PickupScreen,
  PrestationsHistoryScreen,
  PrestationsScreen,
  RelativeDetailsScreen,
  RemittancesReimbursementScreen,
  RemittancesScreen,
  ServiceUnavailableScreen,
  VideosScreen,
  VisibilityScreen,
  WorkersDetailsScreen,
  WorkersRootScreen,
  WorkersScreen,
  MaintenanceScreen,
} from '@kaa/core-app/providers/screens';
import { SwMain, SwPage } from '@kaa/ui-flanders/components';
import { Redirect, Router } from '@reach/router';
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { Footer } from './components/Footer';
import { Header } from './components/header/Header';
import { ChangeLanguage } from './components/headless/ChangeLanguage';
import { LoginRedirectScreen } from './screens/LoginRedirectScreen';

export const App: React.FC = () => {
  const config = getConfig();

  if (!config) {
    return <ServiceUnavailableScreen />;
  }

  const {
    app: {
      mode,
      auth: { oidc },
    },
    buildConfig,
  } = config;

  const {
    i18n: { availableLanguages, fallbackLanguage },
    loginUrl,
  } = buildConfig;

  const initialState =
    mode === ApiMode.MOCK
      ? new AuthServiceMock()
      : new AuthService(oidc, buildConfig as BuildConfig<RegionCode>);

  return (
    <div className="App vl-u-sticky-gf">
      <I18nextProvider i18n={getI18nInstance()}>
        <AuthProvider initialState={initialState}>
          <ActionProvider>
            <AlertProvider>
              <PageViewGTMListener />
              <ChangeLanguage
                availableLanguages={availableLanguages}
                fallbackLanguage={fallbackLanguage}
              >
                <UserSignedOutListener
                  redirectUrl={getRouterPath(Routes.LANDING)}
                />
                <UserAccessTokenExpiringListener />
                <RemoveIfInIframe>
                  <LoadAuthUser />
                  <LoadUser />
                  <Header />
                </RemoveIfInIframe>
                <SwPage>
                  <SwMain>
                    <RemoveIfInIframe>
                      <ActiveRouteProvider>
                        {({ activeRoute }) => (
                          <>
                            <ChangeTitleRoute
                              activeRoute={activeRoute}
                              titleRoutes={TITLE_ROUTES}
                            />
                            <Breadcrumb activeRoute={activeRoute} />
                            <VlFunctionalHeader activeRoute={activeRoute} />
                          </>
                        )}
                      </ActiveRouteProvider>
                    </RemoveIfInIframe>
                    <Router className="mainContent" primary={false}>
                      <NotFoundScreen path={getRouterPath(Routes.PAGE_404)} />
                      <ServiceUnavailableScreen
                        path={getRouterPath(Routes.SERVICE_UNAVAILABLE)}
                      />
                      <Redirect
                        from={getPath(Routes.LANDING)}
                        to={getPath(Routes.DASHBOARD)}
                        noThrow
                      />
                      <PrivateRoute
                        unauthenticatedRedirectUrl={getRouterPath(Routes.LOGIN)}
                        path={getRouterPath(Routes.DASHBOARD)}
                        component={DashboardScreen}
                      />
                      <PrivateRoute
                        unauthenticatedRedirectUrl={getRouterPath(Routes.LOGIN)}
                        path={getRouterPath(Routes.REMITTANCES)}
                        component={RemittancesScreen}
                      />
                      <PrivateRoute
                        unauthenticatedRedirectUrl={getRouterPath(Routes.LOGIN)}
                        path={getRouterPath(Routes.REMITTANCES_REIMBURSEMENT)}
                        component={RemittancesReimbursementScreen}
                      />
                      <PrivateRoute
                        unauthenticatedRedirectUrl={getRouterPath(Routes.LOGIN)}
                        path={getRouterPath(Routes.PRESTATIONS)}
                        component={PrestationsScreen}
                      />
                      <PrivateRoute
                        unauthenticatedRedirectUrl={getRouterPath(Routes.LOGIN)}
                        path={getRouterPath(Routes.PRESTATIONS_HISTORY)}
                        component={PrestationsHistoryScreen}
                      />
                      <PrivateRoute
                        unauthenticatedRedirectUrl={getRouterPath(Routes.LOGIN)}
                        path={getRouterPath(Routes.WORKERS)}
                        component={WorkersScreen}
                      >
                        <Redirect
                          from="*"
                          to={getPath(Routes.WORKERS)}
                          noThrow
                        />
                        <PrivateRoute
                          unauthenticatedRedirectUrl={getRouterPath(
                            Routes.LOGIN,
                          )}
                          path="/"
                          component={WorkersRootScreen}
                        />
                        <PrivateRoute
                          unauthenticatedRedirectUrl={getRouterPath(
                            Routes.LOGIN,
                          )}
                          path={getRouterPath(Routes.WORKERS_DETAILS)}
                          component={WorkersDetailsScreen}
                        />
                      </PrivateRoute>
                      <PrivateRoute
                        unauthenticatedRedirectUrl={getRouterPath(Routes.LOGIN)}
                        path={getRouterPath(Routes.PICKUP)}
                        component={PickupScreen}
                      />
                      <PrivateRoute
                        unauthenticatedRedirectUrl={getRouterPath(Routes.LOGIN)}
                        path={getRouterPath(Routes.PICKUP_BILLING)}
                        component={PickupBillingScreen}
                      />
                      <PrivateRoute
                        unauthenticatedRedirectUrl={getRouterPath(Routes.LOGIN)}
                        path={getRouterPath(Routes.ENTERPRISE)}
                        component={EnterpriseScreen}
                      >
                        <Redirect
                          from="*"
                          to={getPath(Routes.ENTERPRISE)}
                          noThrow
                        />
                        <PrivateRoute
                          unauthenticatedRedirectUrl={getRouterPath(
                            Routes.LOGIN,
                          )}
                          path="/"
                          component={EnterpriseRootScreen}
                        />
                        <PrivateRoute
                          unauthenticatedRedirectUrl={getRouterPath(
                            Routes.LOGIN,
                          )}
                          path={getRouterPath(Routes.ENTERPRISE_ACTIVITIES)}
                          component={ActivitiesScreen}
                        />
                        <PrivateRoute
                          unauthenticatedRedirectUrl={getRouterPath(
                            Routes.LOGIN,
                          )}
                          path={getRouterPath(Routes.ENTERPRISE_DETAILS)}
                          component={RelativeDetailsScreen}
                        />
                        <PrivateRoute
                          unauthenticatedRedirectUrl={getRouterPath(
                            Routes.LOGIN,
                          )}
                          path={getRouterPath(
                            Routes.ENTERPRISE_DETAILS_CONTACT_PERSON,
                          )}
                          component={ContactPersonsScreen}
                        />
                        <PrivateRoute
                          unauthenticatedRedirectUrl={getRouterPath(
                            Routes.LOGIN,
                          )}
                          path={getRouterPath(
                            Routes.ENTERPRISE_DETAILS_CONTACT_PERSON_DETAILS,
                          )}
                          component={ContactPersonDetailScreen}
                        />
                        <PrivateRoute
                          unauthenticatedRedirectUrl={getRouterPath(
                            Routes.LOGIN,
                          )}
                          path={getRouterPath(
                            Routes.ENTERPRISE_DETAILS_VISIBILITY,
                          )}
                          component={VisibilityScreen}
                        />
                      </PrivateRoute>
                      <PrivateRoute
                        unauthenticatedRedirectUrl={getRouterPath(Routes.LOGIN)}
                        path={getRouterPath(Routes.VIDEOS)}
                        component={VideosScreen}
                      />
                      <ForbiddenWrongRegion
                        path={getRouterPath(Routes.FORBIDDEN_WRONG_REGION)}
                      />
                      {
                        // LoginCallback is html page in public folder
                      }
                      <LoginRedirectScreen path={loginUrl} />
                      <LoginScreen path={getRouterPath(Routes.LOGIN)} />
                      <LoginScreen
                        path={getRouterPath(Routes.LOGIN_LOCAL)}
                        local
                      />
                      <Logout path={getRouterPath(Routes.LOGOUT)} />
                      <MaintenanceScreen
                        path={getRouterPath(Routes.MAINTENANCE)}
                      />
                      {
                        // LogoutCallback is html page in public folder
                      }
                    </Router>
                  </SwMain>
                </SwPage>
                <Footer />
              </ChangeLanguage>
            </AlertProvider>
          </ActionProvider>
        </AuthProvider>
      </I18nextProvider>
    </div>
  );
};
