import { City } from '@kaa/api/providers';

export const getAutocompletePostcodeOption = ({
  cities = [],
  activePostcodes = [],
}: {
  activePostcodes: string[];
  cities: City[];
}) => async (query: string) =>
  cities
    .filter((city: City) => !activePostcodes.includes(city.postcode))
    .map((option: City) => {
      if (option.postcode.startsWith(query)) {
        return {
          option,
          orderNumber: 0,
        };
      }
      if (
        option.names.some((name) =>
          name.toLowerCase().includes(query.toLowerCase()),
        )
      ) {
        return {
          option,
          orderNumber: 1,
        };
      }
      return { option, orderNumber: -1 };
    })
    .filter(
      ({ orderNumber }: { option: City; orderNumber: number }) =>
        orderNumber !== -1,
    )
    .sort(
      (
        a: { option: City; orderNumber: number },
        b: { option: City; orderNumber: number },
      ) => a.orderNumber - b.orderNumber,
    )
    .map(({ option }: { option: City; orderNumber: number }) => option);
