import faker from 'faker';
import {
  Activity,
  AnnouncementsWithRegionalAgreement,
  ApiErrorField,
  FieldValidationErrorCode,
  PaperVoucherAnnouncement,
  PaperVoucherAnnouncementsFileParserResponse,
  PaperVoucherAnnouncementsResponse,
  PaperVoucherAnnouncementValidation,
  PaperVoucherValidityReference,
} from '../model';
import { getArray } from './utils';

const FieldToValidate = {
  voucherReference: 'voucherReference' as keyof PaperVoucherAnnouncement,
  workerNiss: 'workerNiss' as keyof PaperVoucherAnnouncement,
  workDate: 'workDate' as keyof PaperVoucherAnnouncement,
  activityType: 'activityType' as keyof PaperVoucherAnnouncement,
};

export const getPaperVoucherAnnouncements = (): PaperVoucherAnnouncementsFileParserResponse => {
  return {
    data: getArray(50).map((_, recordNumber) =>
      getPaperVoucherAnnouncement({ recordNumber }),
    ),
  };
};

export const getPaperVoucherAnnouncement = (
  paperVoucherAnnouncement?: Partial<PaperVoucherAnnouncement>,
): PaperVoucherAnnouncement => {
  return {
    voucherReference: ['345656880089786445343568798986568', '567990877853436'][
      faker.random.number({ min: 0, max: 1 })
    ],
    workerNiss: faker.random.number(99999999999).toString(),
    workDate: faker.date.past().toISOString(),
    activityType: faker.helpers.randomize(Object.values(Activity)),
    recordNumber: faker.random.number(99999999),
    ...paperVoucherAnnouncement,
  };
};

export const getPaperVoucherAnnouncementsResponse = (
  announcementsWithRegionalAgreement: AnnouncementsWithRegionalAgreement,
): PaperVoucherAnnouncementsResponse => {
  const { announcements } = announcementsWithRegionalAgreement;
  const slicedAnnoucements = announcements
    ? announcements.slice(0, faker.random.number(announcements.length))
    : [];
  return {
    data: slicedAnnoucements.map(({ recordNumber }) =>
      getPaperVoucherAnnouncementsResponseInner({ recordNumber }),
    ),
  };
};

export const getPaperVoucherAnnouncementsResponseInner = (
  paperVoucherAnnouncementValidation: Partial<
    PaperVoucherAnnouncementValidation
  > = {},
): PaperVoucherAnnouncementValidation => {
  return {
    recordNumber: faker.random.number(9999999999999999999999999999),
    voucherReferenceStatus: faker.helpers.randomize(
      Object.values(PaperVoucherValidityReference),
    ),
    validations: faker.helpers
      .shuffle(Object.values(FieldToValidate))
      .slice(0, faker.random.number(Object.values(FieldToValidate).length))
      .map((fieldName) => getFieldValidation({ fieldName })),
    ...paperVoucherAnnouncementValidation,
  };
};

export const getFieldValidation = (
  apiErrorField: Partial<ApiErrorField> = {},
): ApiErrorField => {
  return {
    fieldName: faker.lorem.word(),
    errors: faker.helpers
      .shuffle(Object.values(FieldValidationErrorCode))
      .slice(
        0,
        faker.random.number(Object.values(FieldValidationErrorCode).length),
      ),
    ...apiErrorField,
  };
};
