import { WorkerSummary } from '@kaa/api/providers';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  Icon,
  SwCheckbox,
  SwDataTable,
  SwIcon,
  SwLink,
} from '@kaa/ui-flanders/components';
import { navigate } from '@reach/router';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TableHead,
  TableHeadElement,
  TableRowLoader,
  TableRowNoResult,
} from '../../../../components';
import { getWorkerDetailsPath } from '../../../../routes';

type WorkersSearchTableResultsProps = {
  head: TableHeadElement[];
  workers: WorkerSummary[];
  searchedProviderId: string;
  loading: boolean;
  openModal: () => void;
  setActivation: (payload: { active: boolean; workerId?: number }) => void;
};

export const WorkersSearchTableResults = ({
  head,
  workers,
  searchedProviderId,
  loading,
  setActivation,
  openModal,
}: WorkersSearchTableResultsProps) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="vl-u-table-overflow vl-u-spacer--medium">
        <SwDataTable modLine>
          <TableHead elements={head} />
          <tbody>
            {loading ? (
              <TableRowLoader colSpan={head.length} />
            ) : (
              <>
                {!workers || !workers.length ? (
                  <TableRowNoResult colSpan={head.length} />
                ) : (
                  workers.map((worker) => {
                    return (
                      <tr key={worker.id}>
                        <td>
                          <SwCheckbox
                            name={`id-${worker.id}`}
                            checked={worker.isActive}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                              setActivation({
                                active: e.target.checked,
                                workerId: worker.id,
                              });
                              openModal();
                            }}
                          />
                        </td>
                        <td>{worker.id}</td>
                        <td>{worker.firstName}</td>
                        <td>{worker.lastName}</td>
                        <td>{worker.niss}</td>
                        <td>{worker.postcode}</td>
                        <td>{worker.reference}</td>
                        <td>
                          {worker.isContracted ? (
                            t(i18nKeys.general.label.present)
                          ) : (
                            <SwLink
                              to={getWorkerDetailsPath(
                                worker.id,
                                searchedProviderId,
                              )}
                            >
                              {t(i18nKeys.general.label.missing)}
                            </SwLink>
                          )}
                        </td>
                        <td>
                          <div className="vl-u-display-flex vl-u-flex-v-center">
                            <button
                              type="button"
                              style={{
                                border: 'none',
                                textDecoration: 'underline',
                                cursor: 'pointer',
                                color: '#0055cc',
                              }}
                              onClick={() =>
                                navigate(
                                  getWorkerDetailsPath(
                                    worker.id,
                                    searchedProviderId,
                                  ),
                                )
                              }
                            >
                              <SwIcon modLarge icon={Icon.EDIT} />
                              <br />
                              {t(i18nKeys.general.cta.edit)}
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                )}
              </>
            )}
          </tbody>
        </SwDataTable>
      </div>
    </>
  );
};
