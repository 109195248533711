import { SwColumn, SwInfoTileSpotlight } from '@kaa/ui-flanders/components';
import React, { PropsWithChildren } from 'react';

type DashboardOverviewCardProps = {
  title: string;
  icon?: string;
  footer?: React.ReactNode;
};

export const DashboardOverviewCard = ({
  children,
  ...props
}: PropsWithChildren<DashboardOverviewCardProps>) => (
  <SwColumn width="3" widthM="6" widthS="12">
    <SwInfoTileSpotlight
      {...props}
      modVCenter
      modBadge
      modMedium
      style={{ height: '100%' }}
    >
      {children}
    </SwInfoTileSpotlight>
  </SwColumn>
);
