import { SwLoader } from '@kaa/ui-flanders/components';
import React, { PropsWithChildren, TdHTMLAttributes } from 'react';

type TableRowLoaderProps = TdHTMLAttributes<HTMLTableCellElement>;

export const TableRowLoader = ({
  children,
  ...props
}: PropsWithChildren<TableRowLoaderProps>) => {
  return (
    <tr>
      <td className="vl-u-align-center" {...props}>
        {children || <SwLoader modMessageHidden />}
      </td>
    </tr>
  );
};
