import { Locations } from '@kaa/api/providers';
import { City } from '@kaa/api/providers/model/city';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AutocompleteInputField,
  Icon,
  SwColumn,
  SwGrid,
  SwIcon,
  SwPill,
  SwTitle,
} from '@kaa/ui-flanders/components';
import { Field } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getAutocompletePostcodeOption } from './VisibilityByPostalCodes.utils';

type VisibilityByPostalCodesProps = {
  availableLocations: Locations;
  activePostcodes: string[];
  mutatorSetValue: (fieldName: string, value: any) => void;
  providerPostcode: string;
  modDisabled: boolean;
};

export const VisibilityByPostalCodes = ({
  availableLocations,
  activePostcodes,
  mutatorSetValue,
  providerPostcode,
  modDisabled,
}: VisibilityByPostalCodesProps) => {
  const { t } = useTranslation();
  const providerCity = availableLocations.cities.find(
    ({ postcode }) => postcode === providerPostcode.toString(),
  );

  return (
    <SwGrid modStacked>
      <SwColumn>
        <SwTitle tagName="h4">
          {t(i18nKeys.visibility.locations.byPostalCodes.title)}
        </SwTitle>
      </SwColumn>
      <SwColumn>
        <SwTitle modSans>
          {t(i18nKeys.visibility.locations.byPostalCodes.subtitle)}
        </SwTitle>
        <SwIcon icon={Icon.LOCATION} /> {providerPostcode}{' '}
        {providerCity && providerCity.names.join(', ')}
      </SwColumn>
      <SwColumn>
        <Field
          name="postcodes"
          component={AutocompleteInputField}
          label={t(i18nKeys.visibility.locations.addPostalCode)}
          controlled={false}
          modDisabled={modDisabled}
          getOptions={getAutocompletePostcodeOption({
            cities: availableLocations.cities,
            activePostcodes,
          })}
          menuOptionFormat={({ postcode, names }: City) =>
            `${postcode} ${names.join(', ')}`
          }
          onSelectOption={(active: City) => {
            mutatorSetValue('postcodes', [...activePostcodes, active.postcode]);
          }}
        />
      </SwColumn>
      <SwColumn>
        {!modDisabled ? (
          <>
            {activePostcodes.map((postcode) => (
              <SwPill
                key={postcode}
                modClosable
                id={`postCode-${postcode}`}
                className="vl-u-spacer--xsmall"
                style={{ marginRight: '1rem' }}
                aria-label={`${t(i18nKeys.general.cta.delete)} ${postcode}`}
                onClick={() => {
                  mutatorSetValue(
                    'postcodes',
                    activePostcodes.filter(
                      (activePostcode) => activePostcode !== postcode,
                    ),
                  );
                }}
              >
                {postcode}
              </SwPill>
            ))}
          </>
        ) : (
          <>
            {activePostcodes.map((postcode) => (
              <SwPill
                key={postcode}
                id={`postCode-${postcode}`}
                className="vl-u-spacer--xsmall"
                style={{ marginRight: '1rem' }}
                aria-label={`${t(i18nKeys.general.cta.delete)} ${postcode}`}
              >
                {postcode}
              </SwPill>
            ))}
          </>
        )}
      </SwColumn>
    </SwGrid>
  );
};
