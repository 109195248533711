import {
  ExportRefusedVouchersRemittanceParams,
  FileExtensionReference,
  GetRefusedVouchersRemittanceParams,
  PaginationDirectives,
  RemittanceDetailRefused,
  RemittanceWithTotalQuantities,
} from '@kaa/api/providers';
import { useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  SwFetchErrorMessage,
  SwLoader,
  SwPaginator,
  SwTitle,
} from '@kaa/ui-flanders/components';
import { FormikActions } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DownloadButtons } from '../../../../../components';
import { useApi } from '../../../../../utils';
import {
  EventAction,
  EventCategory,
  EventLabel,
  sendCustomInteractionToGTM,
} from '../../../../../utils/google-analytics';
import './RemittancesSearchHistoryRefusedModal.style.scss';
import { RemittancesSearchHistoryRefusedModalDownload } from './RemittancesSearchHistoryRefusedModalDownload';
import { RemittancesSearchHistoryRefusedModalForm } from './RemittancesSearchHistoryRefusedModalForm';
import { RemittancesSearchHistoryRefusedModalTable } from './RemittancesSearchHistoryRefusedModalTable';

const PAGE_SIZE = 20;

type RemittancesSearchHistoryRefusedModalProps = {
  remittance: RemittanceWithTotalQuantities;
};

export const RemittancesSearchHistoryRefusedModal = ({
  remittance,
}: RemittancesSearchHistoryRefusedModalProps) => {
  const { t } = useTranslation();
  const { providers } = useApi();

  const { providerId, id: remittanceId } = remittance;

  const [parameters, setParameters] = useState<
    GetRefusedVouchersRemittanceParams
  >({ pageNumber: 1, pageSize: PAGE_SIZE });

  const [pagination, setPagination] = useState<
    PaginationDirectives['paginationDirectives']
  >();

  const [download, setDownload] = useState<FileExtensionReference>();

  const [refusedVouchers, setRefusedVouchers] = useState<
    RemittanceDetailRefused
  >([]);

  const [
    { value: refusedVouchersResponse, loading, error },
    getRefusedVouchers,
  ] = useAsyncCallback(
    async (params: GetRefusedVouchersRemittanceParams) => {
      return (
        await providers.getRefusedVouchersRemittance(
          providerId,
          remittanceId,
          params,
        )
      ).data;
    },
    [providers, providerId, remittanceId],
    { loading: true },
  );

  useEffect(() => {
    getRefusedVouchers(parameters);
  }, [getRefusedVouchers, parameters]);

  useEffect(() => {
    if (refusedVouchersResponse) {
      const { paginationDirectives, data } = refusedVouchersResponse;
      setRefusedVouchers(data);
      setPagination(paginationDirectives);
    }
  }, [refusedVouchersResponse]);

  const [, submitSearch] = useAsyncCallback(
    async (
      refusedVouchersRemittanceParams: GetRefusedVouchersRemittanceParams,
      formikActions: FormikActions<GetRefusedVouchersRemittanceParams>,
    ) => {
      const { setSubmitting, resetForm } = formikActions;

      setDownload(undefined);
      setSubmitting(false);

      resetForm(refusedVouchersRemittanceParams);

      setParameters((parameters) => ({
        ...parameters,
        ...refusedVouchersRemittanceParams,
        pageSize: PAGE_SIZE,
        pageNumber: 1,
      }));

      sendCustomInteractionToGTM(
        EventCategory.REMITTANCES_REFUSED_VOUCHERS,
        EventAction.FORM_CONFIRMATION,
        `${EventLabel.REMITTANCES_REFUSED_VOUCHERS_ERRORTYPE}: ${refusedVouchersRemittanceParams.reason}`,
      );
    },
    [setParameters],
  );

  if (loading && !refusedVouchersResponse) {
    return <SwLoader />;
  }

  if (error || !refusedVouchersResponse) {
    return <SwFetchErrorMessage onClick={getRefusedVouchers} />;
  }

  const downloadExtensions = [
    FileExtensionReference.XLSX,
    FileExtensionReference.CSV,
  ];

  return (
    <div>
      <SwTitle tagName="h2" className="vl-u-spacer--medium">
        {t(i18nKeys.remittances.refusedModal.title, {
          count: pagination?.numberOfItems || 0,
          remittanceId,
        })}
      </SwTitle>
      <RemittancesSearchHistoryRefusedModalForm
        submit={submitSearch}
        parameters={parameters}
      />
      <RemittancesSearchHistoryRefusedModalTable
        refusedVouchers={refusedVouchers}
        loading={loading}
      />
      <div className="vl-u-display-flex">
        {download ? (
          <RemittancesSearchHistoryRefusedModalDownload
            parameters={
              {
                ...parameters,
                fileExtension: download,
              } as ExportRefusedVouchersRemittanceParams
            }
            remittance={remittance}
          />
        ) : (
          <DownloadButtons
            extensions={downloadExtensions}
            onClick={(extension: FileExtensionReference) => {
              setDownload(extension);
            }}
          />
        )}
        <div style={{ marginLeft: 'auto' }}>
          {!!pagination && (
            <div style={{ marginLeft: 'auto' }}>
              <SwPaginator
                itemCount={pagination.numberOfItems}
                pageSize={pagination.pageSize}
                selectedPage={pagination.pageNumber}
                setPage={(page) => {
                  setParameters((parameters) => ({
                    ...parameters,
                    pageNumber:
                      typeof page === 'number'
                        ? page
                        : page(parameters?.pageNumber || 1),
                  }));
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
