import { ProviderSubsidiary } from '@kaa/api/providers';
import { allOfObj, oneOfObj } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import { FormikErrors, FormikTouched } from 'formik';
import { TFunction } from 'i18next';
import { fieldNameThatNeedToBeValidate } from './RelativeAddModal.constants';

const validateFields = ({
  touched,
  errors,
  names,
}: {
  touched: any;
  errors: any;
  names: string[];
}) => {
  return !allOfObj(names, touched) || oneOfObj(names, errors);
};

export const getRelativeAddSteps = ({
  touched,
  errors,
  t,
}: {
  touched: FormikTouched<ProviderSubsidiary>;
  errors: FormikErrors<ProviderSubsidiary>;
  t: TFunction;
}) => [
  { label: t(i18nKeys.relative.add.step.details), value: 'information' },
  {
    label: t(i18nKeys.relative.add.step.details),
    value: 'communication',
    modDisabled: validateFields({
      names: fieldNameThatNeedToBeValidate,
      touched,
      errors,
    }),
  },
  {
    label: t(i18nKeys.relative.add.step.specifications),
    value: 'specifications',
    modDisabled: validateFields({
      names: fieldNameThatNeedToBeValidate,
      touched,
      errors,
    }),
  },
];
