/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */

export type WorkerContractStatus =
  | 'UNEMPLOYED_FULL_TIME_JOB_SEEKER'
  | 'UNEMPLOYED_PART_TIME_JOB_SEEKER'
  | 'INTEGRATION_INCOME_BENEFICIARY'
  | 'CONTRACTED'
  | 'TEMPORARY_JOB'
  | 'STUDENT_JOB'
  | 'OTHER';

export const WorkerContractStatus = {
  UNEMPLOYED_FULL_TIME_JOB_SEEKER: 'UNEMPLOYED_FULL_TIME_JOB_SEEKER' as WorkerContractStatus,
  UNEMPLOYED_PART_TIME_JOB_SEEKER: 'UNEMPLOYED_PART_TIME_JOB_SEEKER' as WorkerContractStatus,
  INTEGRATION_INCOME_BENEFICIARY: 'INTEGRATION_INCOME_BENEFICIARY' as WorkerContractStatus,
  CONTRACTED: 'CONTRACTED' as WorkerContractStatus,
  TEMPORARY_JOB: 'TEMPORARY_JOB' as WorkerContractStatus,
  STUDENT_JOB: 'STUDENT_JOB' as WorkerContractStatus,
  OTHER: 'OTHER' as WorkerContractStatus,
};
