import { httpTo } from '@kaa/api/providers/utilities';
import { AuthAction, useAuthDispatch, useAuthState } from '@kaa/auth/common';
import { navigate } from '@reach/router';
import { useCallback, useEffect } from 'react';
import { getPath, Routes } from '../../routes';
import { AuthContext } from '../../types';
import { useApi } from '../../utils';

export const LoadUser = () => {
  const dispatch = useAuthDispatch();
  const { authUser, user } = useAuthState<AuthContext>();
  const { providers } = useApi();

  const fetchMe = useCallback(
    async (profileId) => {
      const [error, response] = await httpTo(providers.getProvider(profileId));

      if (error) {
        return;
      }

      if (!response) {
        navigate(getPath(Routes.SERVICE_UNAVAILABLE));
        return;
      }

      const {
        data: { data },
      } = response;

      dispatch({
        type: AuthAction.UPDATE,
        payload: {
          user: data,
        },
      });
    },
    [providers, dispatch],
  );

  useEffect(() => {
    if (authUser && !user) {
      const { auid } = JSON.parse(
        authUser.profile[
          'http://schemas.sodexo.be/kaa/identity/claims/proxies'
        ],
      )[0];
      fetchMe(auid);
    }
  }, [authUser, user, fetchMe]);

  return null;
};
