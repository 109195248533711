import { PaperVouchersDeclarationForm } from './RemittancesVoucherDeclaration.types';

export const VoucherDeclarationFieldNames = {
  ID: 'id' as keyof PaperVouchersDeclarationForm,
  VOUCHER_REFERENCE: 'voucherReference' as keyof PaperVouchersDeclarationForm,
  REGIONAL_AGREEMENT_ID: 'regionalAgreementId' as keyof PaperVouchersDeclarationForm,
  WORKER_NAME: 'workerName' as keyof PaperVouchersDeclarationForm,
  WORKER_NISS: 'workerNiss' as keyof PaperVouchersDeclarationForm,
  WORK_DATE: 'workDate' as keyof PaperVouchersDeclarationForm,
  ACTIVITY_TYPE: 'activityType' as keyof PaperVouchersDeclarationForm,
};
