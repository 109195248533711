import { ApiError, CodeError, getApi, Provider } from '@kaa/api/providers';
import { createApiError } from '@kaa/api/providers/utilities';
import { AuthAction, useAuthDispatch, useAuthState } from '@kaa/auth/common';
import { AvailableLanguages, StorageKeys } from '@kaa/common/enums';
import { getQueryParam, useRefMounted } from '@kaa/common/utils';
import { navigate } from '@reach/router';
import { AxiosError } from 'axios';
import get from 'lodash.get';
import { Settings } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getConfig } from '../../common/config';
import { getPath, QueryParams, Routes } from '../routes';
import { AuthContext } from '../types';
import { openModalWithPageView } from './google-analytics';
import {
  getAppIdHeader,
  getAuthHeader,
  getCacheHeader,
  getLanguageHeader,
} from './headers';
import { setRedirectUriInSessionStorage } from './setRedirectUriInSessionStorage';

export function useSelectedProviderState(): Provider {
  const { user } = useAuthState<AuthContext>();

  if (!user) {
    throw new Error('A provider must be selected');
  }

  return user;
}

export function useSelectedProviderIdState(): number {
  const { user } = useAuthState<AuthContext>();

  if (!user) {
    throw new Error('A provider must be selected');
  }

  return user.id;
}

export const useApi = () => {
  const { authUser, user } = useAuthState<AuthContext>();

  const dispatch = useAuthDispatch();

  const mounted = useRefMounted();

  const headers = useMemo(
    () => ({
      ...getAuthHeader(authUser),
      ...getLanguageHeader(user),
      ...getAppIdHeader(),
      ...getCacheHeader(),
    }),
    [authUser],
  );

  const unauthorizedAndErrorInterceptor = useMemo(
    () => ({
      response: {
        async onRejected(error: AxiosError): Promise<unknown> {
          if (!mounted.current) {
            return;
          }
          const { response } = error;

          if (!response) {
            navigate(getPath(Routes.MAINTENANCE));
            return Promise.reject(createApiError());
          }

          if (response.status === 401) {
            sessionStorage.clear();
            dispatch({
              type: AuthAction.RESET,
            });
            setRedirectUriInSessionStorage();
            navigate(get(getConfig(), 'buildConfig.unauthorizedRedirectUrl'));
            return Promise.reject(createApiError());
          }

          if (response.status === 502) {
            getApi({
              headers,
              baseUrl,
            })
              .providers.liveness()
              .catch((resp) => {
                if (!resp || resp.status === 502) {
                  navigate(getPath(Routes.MAINTENANCE));
                }
              });
            return Promise.reject(createApiError());
          }

          const { data } = response;

          if (data.code === CodeError.NO_VALID_CONTRACT) {
            navigate(getPath(Routes.FORBIDDEN_WRONG_REGION));
            return Promise.reject(createApiError());
          }

          if (data.code === CodeError.INVALID_REQUEST_SYNTAX) {
            navigate(getPath(Routes.SERVICE_UNAVAILABLE));
            return Promise.reject(createApiError());
          }

          if (response.status >= 500) {
            dispatch({
              type: AuthAction.RESET,
            });
            navigate(getPath(Routes.SERVICE_UNAVAILABLE));
            return Promise.reject(createApiError());
          }

          if (
            data.code === CodeError.VALIDATION_EXCEPTION &&
            !(data as ApiError).validations
          ) {
            return Promise.reject(createApiError());
          }

          if (!data) {
            return Promise.reject(createApiError());
          }

          const ApiError = data;

          if (!ApiError.code && !ApiError.message) {
            return Promise.reject(createApiError());
          }

          return Promise.reject(data);
        },
      },
    }),
    [],
  );

  const kaaConfig = getConfig();
  const baseUrl = get(kaaConfig, 'app.apiUrl');
  const apiMode = get(kaaConfig, 'app.mode');

  return useMemo(
    () =>
      getApi({
        headers,
        interceptor: unauthorizedAndErrorInterceptor,
        baseUrl,
        mode: apiMode,
      }),
    [headers, unauthorizedAndErrorInterceptor, baseUrl, apiMode],
  );
};

export const useLanguage = () => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState<AvailableLanguages>(
    i18n.language as AvailableLanguages,
  );
  const { user: provider } = useAuthState<AuthContext>();

  const changeLanguage = useCallback(
    (lng: AvailableLanguages) => {
      i18n.changeLanguage(lng);
      Settings.defaultLocale = lng;
      if (provider && provider.id) {
        localStorage.setItem(StorageKeys.LAST_SELECTED_LANGUAGE, lng);
      }
    },
    [i18n, provider],
  );

  useEffect(() => {
    setLanguage(i18n.language as AvailableLanguages);
  }, [i18n.language]);

  useEffect(() => {
    document.documentElement.setAttribute('lang', language);
  }, [language]);

  return { language, changeLanguage };
};

export const useAutoOpenModalFromQueryParams = () => {
  const modalId = getQueryParam(QueryParams.MODAL);

  useEffect(() => {
    let id = 0;
    if (modalId) {
      id = setTimeout(() => {
        openModalWithPageView(modalId as any);
      }, 1000);
    }
    return () => {
      if (id) {
        clearTimeout(id);
      }
    };
  }, [modalId]);
};
