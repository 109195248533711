/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */

export type WorkerServiceActionType =
  | 'CAN_EDIT'
  | 'CAN_CANCEL'
  | 'CAN_VALIDATE';

export const WorkerServiceActionType = {
  CAN_EDIT: 'CAN_EDIT' as WorkerServiceActionType,
  CAN_CANCEL: 'CAN_CANCEL' as WorkerServiceActionType,
  CAN_VALIDATE: 'CAN_VALIDATE' as WorkerServiceActionType,
};
