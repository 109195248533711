import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  SwActionGroup,
  SwColumn,
  SwGrid,
  SwTitle,
} from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './DownloadTemplates.style.scss';
import { DownloadTemplatesProps } from './DownloadTemplates.utils';
import { DownloadTemplatesActions } from './DownloadTemplatesActions';

export const DownloadTemplates = ({
  fromModal,
  fileName,
  linkToTemplate,
  title,
  titleDownloadTemplate,
  titleOpenTemplate,
  blob,
}: DownloadTemplatesProps) => {
  const { t } = useTranslation();
  return (
    <SwGrid className="exportModal">
      <SwColumn>
        <SwTitle tagName="h3" tagStyle="h4" className="vl-u-spacer--xsmall">
          <span className="vl-icon" />
          {title || t(i18nKeys.general.modal.export.subtitle)}
        </SwTitle>
        <SwActionGroup>
          <DownloadTemplatesActions
            fromModal={fromModal}
            fileName={fileName}
            linkToTemplate={linkToTemplate}
            titleDownloadTemplate={titleDownloadTemplate}
            titleOpenTemplate={titleOpenTemplate}
            blob={blob}
          />
        </SwActionGroup>
      </SwColumn>
    </SwGrid>
  );
};
