import { Relative, Sorting } from '@kaa/api/providers';

export const sortRelatives = (
  subsidiaries: Relative[],
  currentSort: Sorting,
) => {
  switch (currentSort) {
    case Sorting.NAME_ASC:
      return sortRelativesByNames(subsidiaries, true);
    case Sorting.NAME_DESC:
      return sortRelativesByNames(subsidiaries, false);
    case Sorting.POSTCODE_ASC:
      return sortRelativesByPostcodes(subsidiaries, true);
    case Sorting.POSTCODE_DESC:
      return sortRelativesByPostcodes(subsidiaries, false);
    default:
      return subsidiaries;
  }
};

const sortRelativesByPostcodes = (
  subsidiaries: Relative[],
  sortAscendByPostcode: boolean,
) => {
  return [...subsidiaries].sort((a, b) =>
    sortAscendByPostcode
      ? a.postcode.localeCompare(b.postcode, 'kn', {
          numeric: true,
        })
      : b.postcode.localeCompare(a.postcode, 'kn', {
          numeric: true,
        }),
  );
};

const sortRelativesByNames = (
  subsidiaries: Relative[],
  sortAscendByNames: boolean,
) => {
  return [...subsidiaries].sort((a, b) =>
    sortAscendByNames
      ? a.name.localeCompare(b.name)
      : b.name.localeCompare(a.name),
  );
};
