import { WorkerSummary } from '@kaa/api/providers';
import { Formik, FormikActions } from 'formik';
import React from 'react';
import { PaperVoucherWithValidation } from './RemittancesVoucherDeclaration.types';
import { validateDeclarationRow } from './RemittancesVoucherDeclaration.utils';
import { RemittancesVouchersDeclarationEditRowForm } from './RemittancesVouchersDeclarationEditRowForm';

type RemittancesVouchersDeclarationEditRowProps = {
  getWorkers: (
    providerId: string | number,
    query: string,
  ) => Promise<[Error | undefined, WorkerSummary[] | undefined]>;
  submitRow: (
    values: PaperVoucherWithValidation,
    formikActions: FormikActions<PaperVoucherWithValidation>,
  ) => Promise<[Error | undefined, unknown | undefined]>;
  voucher: PaperVoucherWithValidation;
  removeVoucher: (recordNumber: number) => void;
};

export const RemittancesVouchersDeclarationEditRow = ({
  submitRow,
  voucher,
  getWorkers,
  removeVoucher,
}: RemittancesVouchersDeclarationEditRowProps) => {
  return (
    <tr className="voucher_declaration_edit_row">
      <Formik
        onSubmit={submitRow}
        initialValues={{ ...voucher }}
        validate={validateDeclarationRow}
        render={(props) => (
          <RemittancesVouchersDeclarationEditRowForm
            {...props}
            getWorkers={getWorkers}
            removeVoucher={removeVoucher}
          />
        )}
      />
    </tr>
  );
};
