import { Omit } from '@kaa/ui-flanders/components/utilities';
import { motion, MotionProps, useAnimation } from 'framer-motion';
import React, { HTMLAttributes, useEffect } from 'react';

type MotionTdProps = MotionProps &
  Omit<HTMLAttributes<HTMLElement>, 'defaultValue'> & {
    testid?: string;
    isOpen: boolean;
  };

export const MotionTd = ({
  testid,
  isOpen,
  children,
  ...props
}: MotionTdProps) => {
  const controls = useAnimation();
  const transitions = { ease: 'easeIn', duration: 0.3 };
  const variantsDiv = {
    open: { opacity: 1, height: 'auto' },
    close: { opacity: 0, height: 0, overflow: 'hidden' },
  };
  const variantsTd = {
    open: { paddingTop: '10px', paddingBottom: '10px' },
    close: { paddingTop: 0, paddingBottom: 0 },
  };

  useEffect(() => {
    controls.stop();
    if (isOpen) {
      controls.start('open');
    } else {
      controls.start('close');
    }

    return () => {
      controls.stop();
    };
  }, [isOpen, controls]);

  return (
    <motion.td
      style={{
        verticalAlign: 'middle',
      }}
      transition={transitions}
      variants={variantsTd}
      animate="open"
      initial={!isOpen ? 'open' : 'close'}
      {...props}
    >
      <motion.div
        transition={transitions}
        variants={variantsDiv}
        animate="open"
        initial={!isOpen ? 'open' : 'close'}
      >
        {children}
      </motion.div>
    </motion.td>
  );
};
