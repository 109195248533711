import { SupportType, VoucherStatus } from '@kaa/api/providers';
import { getStarOfTheDayJsDate } from '../../../../../common/utils';

export const TODAY_DATE = getStarOfTheDayJsDate(new Date());
export const BACKEND_LIMIT_DATE = getStarOfTheDayJsDate(new Date('2016/01/01'));

export enum RemittancesSearchHistoryFormFieldNames {
  SEARCHED_PROVIDER_ID = 'searchedProviderId',
  KEYWORD = 'keyword',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  SUPPORT_TYPE = 'supportType',
  VOUCHER_STATUS = 'voucherStatus',
}

export type RemittancesSearchHistoryFormType = {
  [RemittancesSearchHistoryFormFieldNames.SEARCHED_PROVIDER_ID]: string;
  [RemittancesSearchHistoryFormFieldNames.KEYWORD]: string;
  [RemittancesSearchHistoryFormFieldNames.START_DATE]: string;
  [RemittancesSearchHistoryFormFieldNames.END_DATE]?: string;
  [RemittancesSearchHistoryFormFieldNames.SUPPORT_TYPE]?: SupportType;
  [RemittancesSearchHistoryFormFieldNames.VOUCHER_STATUS]?: VoucherStatus;
};
