import {
  FileExtensionReference,
  ProviderBankAccount,
  ProviderSubsidyConfigurationUpdate,
  RegionCode,
  SubsidyBeneficiaryBankAccountHolderReference,
  SubsidyReceivalModeCode,
} from '@kaa/api/providers';
import { httpTo } from '@kaa/api/providers/utilities';
import { Format, useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  SwActionGroup,
  SwButton,
  SwColumn,
  SwDocument,
  SwFetchErrorMessage,
  SwForm,
  SwFormColumn,
  SwFormInput,
  SwFormMessageLabel,
  SwFormSubmitMessage,
  SwInputField,
  SwLoader,
  SwRadioField,
  SwTitle,
  toggleModalById,
} from '@kaa/ui-flanders/components';
import { Field, Formik, FormikActions } from 'formik';
import get from 'lodash.get';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getConfig } from '../../../../common/config';
import {
  Modals,
  REGION_REMITTANCES_REIMBURSEMENT_PDF,
} from '../../../constants';
import { dataTest } from '../../../datatest/keys';
import { useApi, useSelectedProviderState } from '../../../utils';
import {
  EventAction,
  EventCategory,
  EventLabel,
  sendCustomInteractionToGTM,
  sendDownloadEventToGTM,
} from '../../../utils/google-analytics';
import { handleApiError } from '../../../utils/validation';

const getProviderBankAccountLabel = (
  providerBankAccount: ProviderBankAccount,
) => {
  return `${providerBankAccount.commercialName} - ${Format.iban(
    providerBankAccount.iban,
  )}`;
};

export const RemittancesReimbursementModal = () => {
  const { t } = useTranslation();
  const { providers } = useApi();
  const regionCode = get(getConfig(), 'buildConfig.regionCode') as RegionCode;

  const provider = useSelectedProviderState();

  const [
    { value: providerSubsidies, loading, error },
    getProviderSubsidies,
  ] = useAsyncCallback(
    async () =>
      (await providers.getProviderSubsidyConfiguration(provider.id)).data.data,
    [providers],
    { loading: true },
  );

  useEffect(() => {
    getProviderSubsidies();
  }, [getProviderSubsidies]);

  if (loading) {
    return <SwLoader />;
  }

  if (error || !providerSubsidies) {
    return <SwFetchErrorMessage onClick={getProviderSubsidies} />;
  }

  const {
    subsidyReceivalMode,
    bankAccounts,
    subsidyBeneficiaryBankAccountHolder,
  } = providerSubsidies;

  const headquarter = bankAccounts.find(
    ({ relativeType }) =>
      relativeType === SubsidyBeneficiaryBankAccountHolderReference.HEADQUARTER,
  );

  const subsidiary = bankAccounts.find(
    ({ relativeType }) =>
      relativeType === SubsidyBeneficiaryBankAccountHolderReference.SUBSIDIARY,
  );

  const submit = async (
    providerSubsidiesUpdate: ProviderSubsidyConfigurationUpdate,
    formikActions: FormikActions<ProviderSubsidyConfigurationUpdate>,
  ) => {
    const { resetForm } = formikActions;

    const [error] = await httpTo(
      providers.updateProviderSubsidyConfiguration(
        provider.id,
        providerSubsidiesUpdate,
      ),
    );

    if (handleApiError(error, formikActions)) {
      return;
    }

    resetForm(providerSubsidiesUpdate);
    toggleModalById(Modals.REMITTANCES_REIMBURSEMENT_MODAL_ID);

    sendCustomInteractionToGTM(
      EventCategory.REMITTANCES_REIMBURSEMENT,
      EventAction.FORM_CONFIRMATION,
      EventLabel.REMITTANCES_MODIFY_PREFERENCES,
    );
  };

  return (
    <Formik
      initialValues={{
        subsidyReceivalMode,
        subsidyBeneficiaryBankAccountHolder,
      }}
      onSubmit={submit}
      render={({ handleSubmit, dirty, isSubmitting }) => (
        <SwForm onSubmit={handleSubmit}>
          {/* TODO: align content with relative screens */}
          <SwTitle tagName="h2" className="vl-u-spacer--xsmall">
            {t(i18nKeys.remittances.reimbursement.modal.title)}
          </SwTitle>
          <SwFormColumn className="vl-u-spacer--medium">
            {headquarter && !subsidiary ? (
              <SwFormColumn width="12">
                <SwFormMessageLabel htmlFor="subsidyBeneficiaryBankAccountHolder">
                  {t(i18nKeys.remittances.general.exploitationName)}
                </SwFormMessageLabel>
                <SwFormInput>
                  <SwInputField
                    id="subsidyBeneficiaryBankAccountHolder"
                    type="text"
                    value={getProviderBankAccountLabel(headquarter)}
                    modDisabled
                    modBlock
                  />
                </SwFormInput>
              </SwFormColumn>
            ) : (
              <fieldset>
                <legend className="vl-form__label vl-u-spacer--xsmall">
                  {t(i18nKeys.general.exploitationName)}
                </legend>
                {headquarter && (
                  <Field
                    name="subsidyBeneficiaryBankAccountHolder"
                    id={headquarter.relativeType}
                    key={headquarter.providerId}
                    component={SwRadioField}
                    label={getProviderBankAccountLabel(headquarter)}
                  />
                )}
                {subsidiary && (
                  <Field
                    name="subsidyBeneficiaryBankAccountHolder"
                    id={subsidiary.relativeType}
                    key={subsidiary.providerId}
                    component={SwRadioField}
                    label={getProviderBankAccountLabel(subsidiary)}
                  />
                )}
              </fieldset>
            )}
          </SwFormColumn>
          <fieldset className="vl-u-spacer--medium">
            <legend className="vl-form__label vl-u-spacer--xsmall">
              {t(i18nKeys.remittances.reimbursement.modal.type.title)}
            </legend>
            <Field
              name="subsidyReceivalMode"
              component={SwRadioField}
              id={SubsidyReceivalModeCode.ONE_RECEIVAL_PER_REMITTANCE}
              label={t(
                i18nKeys.remittances.reimbursement.modal.type.individual,
              )}
              data-testid={`subsidyReceivalMode-${SubsidyReceivalModeCode.ONE_RECEIVAL_PER_REMITTANCE}`}
            />
            <Field
              name="subsidyReceivalMode"
              component={SwRadioField}
              id={SubsidyReceivalModeCode.MONTHLY_RECEIVAL}
              label={t(i18nKeys.remittances.reimbursement.modal.type.grouped)}
              data-testid={`subsidyReceivalMode-${SubsidyReceivalModeCode.MONTHLY_RECEIVAL}`}
            />
          </fieldset>
          <SwColumn>
            {/* In order for the EA to change its account number, it can click on
            "Changing of Bank Details" which is the same link used in USP7 - As
            an EA, I want to modify my bank account details that direct it to
            the pdf document "TS_change_bank_data_request.fr". DATA DOMAIN */}
            <SwDocument
              data-testid={dataTest.relatives.details.modifyBankAccount}
              metadata="PDF - 70kb"
              mimeType={FileExtensionReference.PDF.toLowerCase()}
              title={t(
                i18nKeys.remittances.reimbursement.modal.cta.modifyBankAccount,
              )}
              external
              onClick={() => {
                sendDownloadEventToGTM(
                  EventAction.OPEN,
                  `${t(REGION_REMITTANCES_REIMBURSEMENT_PDF[regionCode])
                    .split('/')
                    .pop()} | ${t(
                    REGION_REMITTANCES_REIMBURSEMENT_PDF[regionCode],
                  )}`,
                );
              }}
              href={t(REGION_REMITTANCES_REIMBURSEMENT_PDF[regionCode])}
              externalLink={t(REGION_REMITTANCES_REIMBURSEMENT_PDF[regionCode])}
            />
          </SwColumn>
          <SwActionGroup modCollapseM>
            <SwButton
              type="submit"
              modLarge
              modLoading={isSubmitting}
              modDisabled={!dirty}
            >
              {t(i18nKeys.remittances.reimbursement.modal.cta.submit)}
            </SwButton>
          </SwActionGroup>
          <SwFormSubmitMessage />
        </SwForm>
      )}
    />
  );
};
