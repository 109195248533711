import { useAsyncCallback, useLuxon } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  Icon,
  SwButton,
  SwDescriptionDataItem,
  SwLink,
  SwLoader,
} from '@kaa/ui-flanders/components';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modals } from '../../../constants';
import { getPath, QueryParams, Routes } from '../../../routes';
import { useApi } from '../../../utils';
import {
  DashboardActionEvent,
  sendDashboardActionEventToGTM,
} from '../../../utils/google-analytics';
import { DashboardOverviewProps } from '../Dashboard.types';
import { overviewCardChildrenProps } from '../Dashboard.utils';
import { DashboardOverviewCard } from './components/DashboardOverviewCard';
import { DashboardOverviewCardLabel } from './components/DashboardOverviewCardLabel';

export const DashboardOverviewPickups = ({
  providerId,
}: DashboardOverviewProps) => {
  const { t } = useTranslation();
  const { DateTime } = useLuxon();
  const { providers } = useApi();

  const [
    { value, loading, error },
    getDashboardPickupsOverview,
  ] = useAsyncCallback(
    async () =>
      (await providers.getDashboardPickupsOverview(providerId)).data.data,
    [providers],
    { loading: true },
  );

  useEffect(() => {
    getDashboardPickupsOverview();
  }, [getDashboardPickupsOverview]);

  const overviewCardProps = {
    title: t(i18nKeys.dashboard.overview.pickup.title),
    icon: Icon.PICK_UP,
  };

  if (loading && !value) {
    return (
      <DashboardOverviewCard {...overviewCardProps}>
        <SwLoader modMessageHidden />
      </DashboardOverviewCard>
    );
  }

  if (error || !value) {
    return (
      <DashboardOverviewCard
        {...overviewCardProps}
        footer={
          <SwButton onClick={getDashboardPickupsOverview}>
            {t(i18nKeys.errors.serviceUnavailableScreen.cta)}
          </SwButton>
        }
      >
        <p>{t(i18nKeys.errors.serviceUnavailableScreen.title)}</p>
      </DashboardOverviewCard>
    );
  }

  const { nextPickup } = value;

  return (
    <DashboardOverviewCard
      {...overviewCardProps}
      footer={
        <>
          {nextPickup ? (
            <SwLink
              to={getPath(Routes.PICKUP)}
              onClick={() => {
                sendDashboardActionEventToGTM(
                  DashboardActionEvent.CLICK_CARD,
                  'See pickups',
                );
              }}
            >
              {t(i18nKeys.dashboard.overview.pickup.cta.seePickups)}
            </SwLink>
          ) : (
            <SwButton
              modBlock
              modNarrow
              to={getPath(Routes.PICKUP, {
                queryParams: {
                  [QueryParams.MODAL]: Modals.PICKUP_CREATE_PICKUP_MODAL,
                },
              })}
              onClick={() => {
                sendDashboardActionEventToGTM(
                  DashboardActionEvent.CLICK_CARD,
                  'Create pickup',
                );
              }}
            >
              {t(i18nKeys.dashboard.overview.pickup.cta.createPickup)}
            </SwButton>
          )}
        </>
      }
    >
      {nextPickup ? (
        <>
          <SwDescriptionDataItem
            {...overviewCardChildrenProps}
            label={
              <DashboardOverviewCardLabel
                text={DateTime.fromISO(nextPickup.date).toFormat('dd/MM/yy')}
              />
            }
            subdata={t(i18nKeys.dashboard.overview.pickup.nextScheduled)}
          />
          <SwDescriptionDataItem
            {...overviewCardChildrenProps}
            label={
              <DashboardOverviewCardLabel
                text={t(i18nKeys.pickup.delivery[nextPickup.type])}
              />
            }
            subdata={t(i18nKeys.dashboard.overview.pickup.typeOfPickup)}
          />
        </>
      ) : (
        <p>{t(i18nKeys.dashboard.overview.pickup.noPickupPlanned)}</p>
      )}
    </DashboardOverviewCard>
  );
};
