import {
  FileExtensionReference,
  RemittanceWithTotalQuantities,
} from '@kaa/api/providers';
import { useLuxon } from '@kaa/common/utils';
import {
  Icon,
  SwBadge,
  SwColumn,
  SwGrid,
  SwLink,
} from '@kaa/ui-flanders/components';
import React, { useState } from 'react';
import { Modals } from '../../../../constants';
import { openModalWithPageView } from '../../../../utils/google-analytics';

type RemittancesSearchHistoryTableRowProps = {
  remittance: RemittanceWithTotalQuantities;
  openRemittanceModal: (
    remittance: RemittanceWithTotalQuantities,
    modalId: Modals,
    fileExtension?: FileExtensionReference,
  ) => void;
};

export const RemittancesSearchHistoryTableRow = ({
  remittance,
  openRemittanceModal,
}: RemittancesSearchHistoryTableRowProps) => {
  const { DateTime } = useLuxon();

  const downloadExtensions = [
    FileExtensionReference.XLSX,
    FileExtensionReference.CSV,
  ];

  const {
    id,
    communication,
    serviceDate,
    supportType,
    providerId,
    creationDate,
    announcedVouchers,
    incorrectVouchers,
    refusedVouchers,
    validVouchers,
  } = remittance;

  return (
    <tr>
      <td style={{ verticalAlign: 'middle' }}>
        <SwLink
          tagName="button"
          onClick={() => {
            openRemittanceModal(
              remittance,
              Modals.REMITTANCES_SEARCH_HISTORY_DETAIL_MODAL,
            );
          }}
        >
          {id}
        </SwLink>
      </td>
      <td style={{ verticalAlign: 'middle' }}>{communication}</td>
      <td style={{ verticalAlign: 'middle' }}>
        {DateTime.fromISO(serviceDate).toFormat('MM/yyyy')}
      </td>
      <td style={{ verticalAlign: 'middle' }}>{supportType.substr(0, 1)}</td>
      <td style={{ verticalAlign: 'middle' }}>{providerId}</td>
      <td style={{ verticalAlign: 'middle' }}>
        {DateTime.fromISO(creationDate).toFormat('dd/MM/yyyy')}
      </td>
      <td style={{ verticalAlign: 'middle', minWidth: '15rem' }}>
        <SwGrid className="vl-u-align-center " modVCenter style={{ margin: 0 }}>
          <SwColumn width="3" style={{ padding: 0 }}>
            {announcedVouchers}
          </SwColumn>
          <SwColumn width="3" style={{ padding: 0 }}>
            {incorrectVouchers > 0 ? (
              <SwLink
                style={{ marginTop: '.4rem' }}
                tagName="button"
                onClick={() => {
                  openRemittanceModal(
                    remittance,
                    Modals.REMITTANCES_SEARCH_HISTORY_INCORRECT_MODAL,
                  );
                }}
              >
                <SwBadge
                  style={{
                    marginRight: '.2rem',
                    minWidth: '2.2rem',
                    width: '2.2rem',
                    height: '2.2rem',
                  }}
                  modMedium
                  modWarning
                  icon={Icon.ALERT_CIRCLE}
                />
                {incorrectVouchers}
              </SwLink>
            ) : (
              <>{incorrectVouchers}</>
            )}
          </SwColumn>
          <SwColumn style={{ padding: 0 }} width="2">
            {refusedVouchers > 0 ? (
              <SwLink
                style={{
                  marginTop: '.4rem',
                }}
                tagName="button"
                onClick={() => {
                  openRemittanceModal(
                    remittance,
                    Modals.REMITTANCES_SEARCH_HISTORY_REFUSED_MODAL,
                  );
                }}
              >
                <SwBadge
                  style={{
                    marginRight: '.2rem',
                    minWidth: '2.2rem',
                    width: '2.2rem',
                    height: '2.2rem',
                    transform: 'rotate(45deg)',
                  }}
                  modMedium
                  modError
                  icon={Icon.PLUS_CIRCLE}
                />
                {refusedVouchers}
              </SwLink>
            ) : (
              <>{refusedVouchers}</>
            )}
          </SwColumn>
          <SwColumn style={{ padding: 0 }} width="3">
            {validVouchers}
          </SwColumn>
        </SwGrid>
      </td>
      <td style={{ verticalAlign: 'middle' }}>
        {downloadExtensions.map(
          (fileExtension: FileExtensionReference, i: number) => (
            <React.Fragment key={fileExtension}>
              {i > 0 && <> </>}
              <SwLink
                modBold
                tagName="button"
                onClick={(e) => {
                  e.preventDefault();
                  openRemittanceModal(
                    remittance,
                    Modals.REMITTANCES_REIMBURSEMENT_DOWNLOAD_MODAL,
                    fileExtension,
                  );
                }}
              >
                .{fileExtension.toLowerCase()}
              </SwLink>
            </React.Fragment>
          ),
        )}
      </td>
    </tr>
  );
};
