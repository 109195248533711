import { i18nKeys } from '@kaa/i18n/providers/keys';
import { Icon, SwIcon } from '@kaa/ui-flanders/components';
import { TFunction } from 'i18next';
import React from 'react';
import { dataTest } from '../../datatest/keys';
import { Routes } from '../../routes';
import { VlFunctionHeaderTab } from './VlFunctionalHeader.types';

export const getTabsDashboard = (t: TFunction): VlFunctionHeaderTab[] => [
  {
    id: Routes.DASHBOARD,
    className: 'vl-link vl-u-display-inline-flex',
    header: t(i18nKeys.navigation.dashboard),
    uid: '1',
  },
  {
    id: Routes.REMITTANCES,
    header: t(i18nKeys.navigation.remittances),
    uid: '2',
  },
  {
    id: Routes.PRESTATIONS,
    header: t(i18nKeys.navigation.prestations),
    uid: '3',
  },
  {
    id: Routes.WORKERS,
    header: t(i18nKeys.navigation.workers),
    uid: '4',
  },
  {
    id: Routes.PICKUP,
    header: t(i18nKeys.navigation.pickup),
    uid: '5',
  },
  {
    id: Routes.ENTERPRISE,
    header: t(i18nKeys.navigation.enterprise),
    uid: '6',
  },
];

// TODO align in Customer
const getTabsDefault = (t: TFunction): VlFunctionHeaderTab[] => [
  {
    id: Routes.DASHBOARD,
    'data-testid': dataTest.header.backHome,
    className: 'vl-link vl-u-display-inline-flex',
    header: (
      <>
        <SwIcon className="vl-link__icon" icon={Icon.ARROW_LEFT} />{' '}
        {t(i18nKeys.navigation.backToHomepage)}
      </>
    ),
    uid: '1',
  },
];

const getTabsVideos = (t: TFunction): VlFunctionHeaderTab[] => [
  ...getTabsDefault(t),
  {
    id: Routes.VIDEOS,
    header: t(i18nKeys.navigation.videos),
    uid: '2',
  },
];

const getTabsRemittances = (t: TFunction): VlFunctionHeaderTab[] => [
  ...getTabsDefault(t),
  {
    id: Routes.REMITTANCES,
    header: t(i18nKeys.navigation.remittances),
    uid: '2',
  },
  {
    id: Routes.REMITTANCES_REIMBURSEMENT,
    header: t(i18nKeys.navigation.remittancesReimbursement),
    uid: '3',
  },
];

const getTabsPrestations = (t: TFunction): VlFunctionHeaderTab[] => [
  ...getTabsDefault(t),
  {
    id: Routes.PRESTATIONS,
    header: t(i18nKeys.navigation.prestations),
    uid: '2',
  },
  {
    id: Routes.PRESTATIONS_HISTORY,
    header: t(i18nKeys.navigation.prestationsHistory),
    uid: '3',
  },
];

const getTabsWorkers = (t: TFunction): VlFunctionHeaderTab[] => [
  ...getTabsDefault(t),
  {
    id: Routes.WORKERS,
    header: t(i18nKeys.navigation.workers),
    uid: '2',
  },
];

const getTabsWorkerDetails = (t: TFunction): VlFunctionHeaderTab[] => [
  {
    id: Routes.WORKERS,
    'data-testid': dataTest.header.backWorkers,
    className: 'vl-link vl-u-display-inline-flex',
    header: (
      <>
        <SwIcon className="vl-link__icon" icon={Icon.ARROW_LEFT} />{' '}
        {t(i18nKeys.navigation.backToWorkers)}
      </>
    ),
    uid: '1',
  },
  {
    id: Routes.WORKERS_DETAILS,
    header: t(i18nKeys.navigation.workerDetails),
    uid: '2',
  },
];

const getTabsPickup = (t: TFunction): VlFunctionHeaderTab[] => [
  ...getTabsDefault(t),
  {
    id: Routes.PICKUP,
    header: t(i18nKeys.navigation.pickup),
    uid: '2',
  },
  {
    id: Routes.PICKUP_BILLING,
    header: t(i18nKeys.navigation.pickupBilling),
    uid: '3',
  },
];

const getTabsEnterprise = (t: TFunction): VlFunctionHeaderTab[] => [
  ...getTabsDefault(t),
  {
    id: Routes.ENTERPRISE,
    header: t(i18nKeys.navigation.enterprise),
    uid: '2',
  },
  {
    id: Routes.ENTERPRISE_ACTIVITIES,
    header: t(i18nKeys.navigation.enterpriseActivities),
    uid: '3',
  },
];

const getTabsEnterpriseDetails = (t: TFunction): VlFunctionHeaderTab[] => [
  {
    id: Routes.ENTERPRISE,
    'data-testid': dataTest.header.backEnterprise,
    className: 'vl-link vl-u-display-inline-flex',
    header: (
      <>
        <SwIcon className="vl-link__icon" icon={Icon.ARROW_LEFT} />{' '}
        {t(i18nKeys.navigation.backToEnterprise)}
      </>
    ),
    uid: '1',
  },
  {
    id: Routes.ENTERPRISE_DETAILS,
    header: t(i18nKeys.navigation.enterpriseDetails),
    uid: '2',
  },
  {
    id: Routes.ENTERPRISE_DETAILS_CONTACT_PERSON,
    header: t(i18nKeys.navigation.contactPerson),
    uid: '3',
  },
  {
    id: Routes.ENTERPRISE_DETAILS_VISIBILITY,
    header: t(i18nKeys.navigation.enterpriseDetailsVisibility),
    uid: '4',
  },
];

const getTabsEnterpriseContactDetails = (
  t: TFunction,
): VlFunctionHeaderTab[] => [
  {
    id: Routes.ENTERPRISE_DETAILS_CONTACT_PERSON,
    'data-testid': dataTest.header.backContactPerson,
    className: 'vl-link vl-u-display-inline-flex',
    header: (
      <>
        <SwIcon className="vl-link__icon" icon={Icon.ARROW_LEFT} />{' '}
        {t(i18nKeys.navigation.backToContactPerson)}
      </>
    ),
    uid: '1',
  },
  {
    id: Routes.ENTERPRISE_DETAILS_CONTACT_PERSON_DETAILS,
    header: t(i18nKeys.navigation.contactPersonDetails),
    uid: '2',
  },
];

export const getLoginTabs = (t: TFunction): VlFunctionHeaderTab[] => [
  {
    id: Routes.LOGIN,
    header: t(i18nKeys.navigation.login),
    uid: '1',
  },
];

export const getLoginLocalTabs = (t: TFunction): VlFunctionHeaderTab[] => [
  {
    id: Routes.LOGIN_LOCAL,
    header: t(i18nKeys.navigation.login),
    uid: '1',
  },
];

export const getTabs = (
  route: Routes,
  t: TFunction,
): VlFunctionHeaderTab[] | undefined => {
  const TABS: Partial<
    { [key in Routes]: (t: TFunction) => VlFunctionHeaderTab[] }
  > = {
    [Routes.LOGIN]: getLoginTabs,
    [Routes.LOGIN_LOCAL]: getLoginLocalTabs,
    [Routes.DASHBOARD]: getTabsDashboard,
    [Routes.REMITTANCES]: getTabsRemittances,
    [Routes.REMITTANCES_REIMBURSEMENT]: getTabsRemittances,
    [Routes.PRESTATIONS]: getTabsPrestations,
    [Routes.PRESTATIONS_HISTORY]: getTabsPrestations,
    [Routes.VIDEOS]: getTabsVideos,
    [Routes.WORKERS]: getTabsWorkers,
    [Routes.WORKERS_DETAILS]: getTabsWorkerDetails,
    [Routes.PICKUP]: getTabsPickup,
    [Routes.PICKUP_BILLING]: getTabsPickup,
    [Routes.ENTERPRISE]: getTabsEnterprise,
    [Routes.ENTERPRISE_ACTIVITIES]: getTabsEnterprise,
    [Routes.ENTERPRISE_DETAILS]: getTabsEnterpriseDetails,
    [Routes.ENTERPRISE_DETAILS_CONTACT_PERSON]: getTabsEnterpriseDetails,
    [Routes.ENTERPRISE_DETAILS_CONTACT_PERSON_DETAILS]: getTabsEnterpriseContactDetails,
    [Routes.ENTERPRISE_DETAILS_VISIBILITY]: getTabsEnterpriseDetails,
  };

  const tab = TABS[route];

  return tab ? tab(t) : tab;
};
