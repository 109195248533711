/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */

export type SubsidyBeneficiaryBankAccountHolderReference =
  | 'HEADQUARTER'
  | 'SUBSIDIARY';

export const SubsidyBeneficiaryBankAccountHolderReference = {
  HEADQUARTER: 'HEADQUARTER' as SubsidyBeneficiaryBankAccountHolderReference,
  SUBSIDIARY: 'SUBSIDIARY' as SubsidyBeneficiaryBankAccountHolderReference,
};
