/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */

export type ProviderType = 'HEADQUARTER' | 'SUBSIDIARY';

export const ProviderType = {
  HEADQUARTER: 'HEADQUARTER' as ProviderType,
  SUBSIDIARY: 'SUBSIDIARY' as ProviderType,
};
