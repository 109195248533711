import {
  ExportWorkersParams,
  FileExtensionReference,
  GetWorkersParams,
  PaginationDirectives,
  ProviderType,
  Sorting,
  WorkerSummary,
} from '@kaa/api/providers';
import { useAsyncCallback } from '@kaa/common/utils';
import { DownloadButtons } from '@kaa/core-app/providers/components';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AlertType,
  getAlertPropsByType,
  SwActionGroup,
  SwAlert,
  SwColumn,
  SwContainer,
  SwFetchErrorMessage,
  SwLink,
  SwModal,
  SwPaginator,
  SwTitle,
  toggleModalById,
} from '@kaa/ui-flanders/components';
import { FormikActions } from 'formik';
import { TFunction } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getLabelForSorting } from '../../../components';
import { Modals } from '../../../constants';
import { dataTest } from '../../../datatest/keys';
import { getQueryParams, QueryParams, Routes } from '../../../routes';
import { useApi, useSelectedProviderState } from '../../../utils';
import {
  EventAction,
  EventCategory,
  EventLabel,
  openModalWithPageView,
  sendCustomInteractionToGTM,
  sendSearchToGTM,
} from '../../../utils/google-analytics';
import { WorkersDeOrActivateModal } from './components/WorkersDeOrActivateModal';
import { WorkersSearchDownloadModal } from './components/WorkersSearchDownloadModal';
import { WorkersSearchForm } from './components/WorkersSearchForm';
import { WorkersSearchTableResults } from './components/WorkersSearchTableResults';
import { PAGE_SIZE } from './WorkersSearch.constant';

const getWorkersDeOrActivateModal = (
  active: boolean,
  worker: boolean,
  error: boolean,
  t: TFunction,
): string => {
  if (worker && error) {
    return t(i18nKeys.workers.modals.activateWorker.worker.error);
  }
  if (worker && !error) {
    return active
      ? t(i18nKeys.workers.modals.activateWorker.worker.success)
      : t(i18nKeys.workers.modals.deactivateWorker.worker.success);
  }
  if (!worker && error) {
    return t(i18nKeys.workers.modals.activateWorker.workers.error);
  }
  return active
    ? t(i18nKeys.workers.modals.activateWorker.workers.success)
    : t(i18nKeys.workers.modals.deactivateWorker.workers.success);
};

export const WorkersSearch = () => {
  const { t } = useTranslation();
  const { providers } = useApi();
  const provider = useSelectedProviderState();

  const isSubsidiary = provider.type === ProviderType.SUBSIDIARY;

  const [pagination, setPagination] = useState<
    PaginationDirectives['paginationDirectives']
  >();
  const status = getQueryParams(QueryParams.STATUS);
  const [parameters, setParameters] = useState<GetWorkersParams | undefined>(
    status && status === 'missingContract'
      ? { isContracted: false, isActive: true }
      : undefined,
  );
  const [workers, setWorkers] = useState<WorkerSummary[]>([]);
  const [activation, setActivation] = useState<{
    active: boolean;
    workerId?: number;
  }>();

  const [deOrActivateModalStatus, setDeOrActivateModalStatus] = useState<
    | {
        type: AlertType;
        msg: string;
      }
    | undefined
  >();

  const [, submitSearch] = useAsyncCallback(
    async (
      workersParams: GetWorkersParams & { missingContract: boolean },
      formikActions: FormikActions<
        GetWorkersParams & { missingContract: boolean }
      >,
    ) => {
      const { setSubmitting, resetForm } = formikActions;
      const { missingContract, ...params } = workersParams;

      setSubmitting(false);
      resetForm(workersParams);
      setParameters(() => ({
        ...params,
        pageSize: PAGE_SIZE,
        pageNumber: 1,
      }));

      const {
        workerNameOrNiss, // Sensitive info we can't log due to GDPR
        workerNissOrReference, // Sensitive info we can't log due to GDPR
        ...workersParamsWithoutSensitiveInfo
      } = workersParams;
      sendSearchToGTM(
        Routes.WORKERS,
        JSON.stringify(workersParamsWithoutSensitiveInfo),
      );
    },
    [providers],
  );

  const [
    { value: workersResponse, loading: workersLoading, error: workersError },
    getWorkers,
  ] = useAsyncCallback(
    async (workersParams: GetWorkersParams) =>
      (await providers.getWorkers(provider.id, workersParams)).data,
    [providers],
    { loading: true },
  );

  const [
    {
      value: workersSearchData,
      loading: workersSearchloading,
      error: workersSearchError,
    },
    getWorkerData,
  ] = useAsyncCallback(
    async () => {
      const [
        {
          data: { headquarter, subsidiaries },
        },
        {
          data: { workersUncontracted },
        },
      ] = await Promise.all([
        providers.getProviderRelatives(provider.id).then(({ data }) => data),
        providers.getWorkersOverview(provider.id).then(({ data }) => data),
      ]);

      return {
        relatives: [
          headquarter,
          ...subsidiaries.filter(({ isActive }) => isActive),
        ],
        workersUncontracted,
      };
    },
    [providers, provider],
    { loading: true },
  );

  useEffect(() => {
    getWorkers(parameters);
  }, [getWorkers, parameters]);

  useEffect(() => {
    if (workersResponse) {
      const { paginationDirectives, data } = workersResponse;
      setPagination(paginationDirectives);
      setWorkers(data);
    }
  }, [workersResponse]);

  useEffect(() => {
    getWorkerData();
  }, [getWorkerData]);

  if (
    (workersLoading && !workersResponse) ||
    (workersSearchloading && !workersSearchData)
  ) {
    return <SwContainer loading />;
  }

  if (
    workersSearchError ||
    workersError ||
    !workersSearchData ||
    !workersResponse
  ) {
    return (
      <SwContainer error>
        <SwFetchErrorMessage
          onClick={() => {
            getWorkerData();
            getWorkers();
          }}
        />
      </SwContainer>
    );
  }

  const { relatives, workersUncontracted } = workersSearchData;
  const defaultSearchProviderId = isSubsidiary ? provider.id.toString() : '';

  const downloadExtensions = [
    FileExtensionReference.XLSX,
    FileExtensionReference.CSV,
  ];

  const openModal = () => {
    setDeOrActivateModalStatus(undefined);
    openModalWithPageView(Modals.WORKERS_CONFIRMATION_MODAL, {
      appendToPath: activation?.active ? '-activate-all' : '-deactivate-all',
    });
  };
  const numberOfWorkersSelected = pagination?.numberOfItems || 0;

  const head = [
    { text: t(i18nKeys.workers.label.active) },
    { text: t(i18nKeys.workers.search.table.head.encodingLogin) },
    { text: t(i18nKeys.general.label.firstname) },
    { text: t(i18nKeys.general.label.name) },
    { text: t(i18nKeys.general.label.niss) },
    {
      text: t(i18nKeys.general.label.postcode),
      label: getLabelForSorting(Sorting.POSTCODE_ASC, parameters?.sort, t),
      onClick: () => {
        setParameters((currentParameters) => {
          return {
            ...currentParameters,
            sort:
              currentParameters?.sort === Sorting.POSTCODE_ASC
                ? Sorting.POSTCODE_DESC
                : Sorting.POSTCODE_ASC,
            pageSize: PAGE_SIZE,
          };
        });
      },
    },
    { text: t(i18nKeys.general.label.internalReference) },
    { text: t(i18nKeys.workers.search.table.head.workerContract) },
    { text: '' },
  ];

  return (
    <>
      <SwTitle tagName="h2">{t(i18nKeys.workers.search.title)}</SwTitle>
      {!!workersUncontracted && workersUncontracted > 0 && (
        <SwColumn className="vl-u-spacer">
          <SwAlert
            {...getAlertPropsByType(AlertType.WARNING)}
            title={t(i18nKeys.workers.search.alert.title, {
              count: workersUncontracted,
            })}
          >
            {t(i18nKeys.workers.search.alert.description)}{' '}
            <SwLink
              tagName="button"
              onClick={() => {
                sendCustomInteractionToGTM(
                  EventCategory.WORKER_LIST,
                  EventAction.CLICK_ALERT,
                  EventLabel.WORKER_DISPLAY_INVOLVED,
                );
                setParameters({
                  searchedProviderId: defaultSearchProviderId,
                  pageNumber: 1,
                  pageSize: PAGE_SIZE,
                  isContracted: false,
                  isActive: true,
                });
              }}
            >
              {t(i18nKeys.workers.search.alert.link, {
                count: workersUncontracted,
              })}
            </SwLink>
          </SwAlert>
        </SwColumn>
      )}
      {!workersUncontracted && (
        <SwColumn className="vl-u-spacer">
          <SwAlert
            {...getAlertPropsByType()}
            title={t(i18nKeys.workers.search.alert.allContracted.title)}
            modSmall
          >
            {t(i18nKeys.workers.search.alert.allContracted.description)}
          </SwAlert>
        </SwColumn>
      )}
      <SwColumn>
        <WorkersSearchForm
          submit={submitSearch}
          isSubsidiary={isSubsidiary}
          defaultSearchProviderId={defaultSearchProviderId}
          relatives={relatives}
          paramaters={parameters}
          setParameters={setParameters}
        />
      </SwColumn>
      <SwColumn>
        {!!deOrActivateModalStatus && (
          <SwAlert
            {...getAlertPropsByType(deOrActivateModalStatus.type)}
            title={deOrActivateModalStatus.msg}
            modSmall
          />
        )}
        <SwActionGroup className="vl-u-spacer--small">
          {t(i18nKeys.general.label.selected, {
            count: numberOfWorkersSelected,
          })}
          <span style={{ margin: '0 1.4rem' }}>|</span>
          <SwLink
            type="button"
            tagName="button"
            modDisabled={Boolean(!numberOfWorkersSelected)}
            onClick={() => {
              setActivation({
                active: true,
              });
              openModal();
            }}
          >
            {t(i18nKeys.workers.search.cta.activateAll)}
          </SwLink>
          <span style={{ marginRight: '1.4rem' }}>|</span>
          <SwLink
            type="button"
            tagName="button"
            modDisabled={Boolean(!numberOfWorkersSelected)}
            onClick={() => {
              setActivation({
                active: false,
              });
              openModal();
            }}
          >
            {t(i18nKeys.workers.search.cta.desactivateAll)}
          </SwLink>
        </SwActionGroup>
        <WorkersSearchTableResults
          searchedProviderId={
            parameters?.searchedProviderId || provider.id.toString()
          }
          head={head}
          loading={workersLoading}
          workers={workers}
          setActivation={setActivation}
          openModal={openModal}
        />
      </SwColumn>
      {!!workers && workers.length && (
        <>
          <div className="vl-u-display-flex">
            <DownloadButtons
              modalId={Modals.WORKER_SEARCH_HISTORY_DOWNLOAD_MODAL}
              extensions={downloadExtensions}
              dataTestId={dataTest.workers.searchWorkers.ExportResults}
            />
            {!!pagination && (
              <div style={{ marginLeft: 'auto' }}>
                <SwPaginator
                  itemCount={pagination.numberOfItems}
                  pageSize={PAGE_SIZE}
                  selectedPage={pagination.pageNumber}
                  setPage={(page) => {
                    setParameters((parameters) => ({
                      ...parameters,
                      pageSize: PAGE_SIZE,
                      pageNumber:
                        typeof page === 'number'
                          ? page
                          : page(parameters?.pageNumber || 1),
                    }));
                  }}
                />
              </div>
            )}
          </div>
          {downloadExtensions.map((fileExtension: FileExtensionReference) => (
            <SwModal
              key={`${Modals.WORKER_SEARCH_HISTORY_DOWNLOAD_MODAL}-${fileExtension}`}
              id={`${Modals.WORKER_SEARCH_HISTORY_DOWNLOAD_MODAL}-${fileExtension}`}
              component={WorkersSearchDownloadModal}
              closable
              parameters={
                {
                  ...parameters,
                  fileExtension,
                } as ExportWorkersParams
              }
            />
          ))}
        </>
      )}
      {activation && (
        <SwModal
          id={Modals.WORKERS_CONFIRMATION_MODAL}
          closable
          confirmCloseMessage={t(i18nKeys.dialog.confirmClose.message)}
          component={WorkersDeOrActivateModal}
          parameters={parameters}
          activation={activation}
          onComplete={(error: boolean, active: boolean) => {
            setDeOrActivateModalStatus({
              type: error ? AlertType.ERROR : AlertType.SUCCESS,
              msg: getWorkersDeOrActivateModal(
                active,
                Boolean(activation.workerId),
                error,
                t,
              ),
            });
            setWorkers((currentWorkers) =>
              currentWorkers.reduce<WorkerSummary[]>((acc, worker) => {
                if (activation.workerId && worker.id !== activation.workerId) {
                  return [...acc, worker];
                }
                return [...acc, { ...worker, isActive: activation.active }];
              }, []),
            );
            getWorkerData();
            toggleModalById(Modals.WORKERS_CONFIRMATION_MODAL);
            setActivation(undefined);

            sendCustomInteractionToGTM(
              EventCategory.WORKER_LIST,
              EventAction.CLICK,
              active
                ? EventLabel.WORKER_LIST_ACTIVATE_ALL
                : EventLabel.WORKER_LIST_DEACTIVATE_ALL,
            );
          }}
        />
      )}
    </>
  );
};
