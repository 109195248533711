import { Pickup, ProviderType, VideoTopicReference } from '@kaa/api/providers';
import { useAsyncCallback, useLuxon } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  getAlertPropsByType,
  Icon,
  SwAlert,
  SwButton,
  SwColumn,
  SwContainer,
  SwFetchErrorMessage,
  SwGrid,
  SwLink,
  SwLoader,
  SwModal,
  SwSelect,
  SwTitle,
} from '@kaa/ui-flanders/components';
import { RouteComponentProps } from '@reach/router';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { PageHeader, TutorialVideoLink } from '../../components';
import { Modals } from '../../constants';
import {
  useApi,
  useAutoOpenModalFromQueryParams,
  useSelectedProviderState,
} from '../../utils';
import {
  EventAction,
  EventCategory,
  EventLabel,
  openModalWithPageView,
  sendCustomInteractionToGTM,
} from '../../utils/google-analytics';
import { PickupCreateModal } from './components/create/PickupCreateModal';
import { PickupDeleteModal } from './components/delete/PickupDeleteModal';
import { PickupCard } from './components/PickupCard';
import { PickupDatesScheduledTable } from './components/PickupDatesScheduledTable';
import { onSubmitStatus } from './components/PickupModals.constants';
import { PickupResumeModal } from './components/resume/PickupResumeModal';
import { PickupTipsModal } from './components/tips/PickupTipsModal';
import { PickupUpdateModal } from './components/update/PickupUpdateModal';
import { sortPickupsPerMonth } from './PickupScreen.utils';
import { ShiftBy } from '../../components/shiftBy/ShiftBy';

export const PickupScreen = ({ navigate }: RouteComponentProps) => {
  const { t, i18n } = useTranslation();
  const { DateTime } = useLuxon();

  const { providers } = useApi();
  useAutoOpenModalFromQueryParams();

  const provider = useSelectedProviderState();

  const [relative, setRelative] = useState(provider.id);
  const [currentPickup, setCurrentPickup] = useState<Pickup>();
  const [status, setStatus] = useState<onSubmitStatus | undefined>();

  const [
    {
      value: relativesResponse,
      loading: relativesLoading,
      error: relativesError,
    },
    getRelatives,
  ] = useAsyncCallback(
    async () => {
      const {
        data: {
          data: { headquarter, subsidiaries },
        },
      } = await providers.getProviderRelatives(provider.id);

      return [headquarter, ...subsidiaries.filter(({ isActive }) => isActive)];
    },
    [providers],
    { loading: true },
  );

  const [
    {
      value: pickupPlanningResponse,
      loading: pickupPlanningLoading,
      error: pickupPlanningError,
    },
    getPickupPlanning,
  ] = useAsyncCallback(
    async (relativeId) =>
      (await providers.getProviderPickupPlanning(relativeId)).data.data,
    [providers],
    { loading: true },
  );

  useEffect(() => {
    getRelatives();
  }, [getRelatives]);

  useEffect(() => {
    getPickupPlanning(relative);
  }, [getPickupPlanning]);

  if (
    (relativesLoading && !relativesResponse) ||
    (pickupPlanningLoading && !pickupPlanningResponse)
  ) {
    return <SwContainer loading />;
  }

  if (
    relativesError ||
    !relativesResponse ||
    pickupPlanningError ||
    !pickupPlanningResponse
  ) {
    return (
      <SwContainer error>
        <SwFetchErrorMessage
          onClick={() => {
            getRelatives(provider.id);
            getPickupPlanning(provider.id);
          }}
        />
      </SwContainer>
    );
  }

  const sortedPickupsPerMonth = sortPickupsPerMonth(
    pickupPlanningResponse,
    (dateISO: string) => {
      const date = DateTime.fromISO(dateISO);
      return `${date.toFormat('MMMM')} ${date.toFormat('y')}`;
    },
  );

  const isHeadquarter = provider.type === ProviderType.HEADQUARTER;

  const openModal = (modal: Modals, pickup?: Pickup) => {
    if (pickup) {
      setCurrentPickup(pickup);
    }
    setStatus(undefined);
    openModalWithPageView(modal);
  };

  const onSubmit = (status: onSubmitStatus) => {
    setStatus(() => status);
    getPickupPlanning(relative);
  };

  return (
    <SwContainer>
      <SwGrid modStacked>
        <SwColumn className="vl-u-spacer">
          <PageHeader
            title={t(i18nKeys.navigation.pickup)}
            className="vl-u-spacer"
          />
          <ShiftBy y={-30}>
            <TutorialVideoLink topic={VideoTopicReference.PICKUP} />
          </ShiftBy>
          <SwGrid
            modVStretched
            style={{ width: '100%', justifyContent: 'flex-start' }}
          >
            <PickupCard
              title={t(i18nKeys.pickup.card.title)}
              text={t(i18nKeys.pickup.card.description)}
              icon={Icon.PICK_UP}
            >
              <SwButton
                type="button"
                modBlock
                modNarrow
                onClick={(e) => {
                  e.preventDefault();
                  openModal(Modals.PICKUP_CREATE_PICKUP_MODAL);
                }}
              >
                {t(i18nKeys.pickup.card.cta)}
              </SwButton>
            </PickupCard>
            <PickupCard
              title={t(i18nKeys.pickup.card.tips.title)}
              text={t(i18nKeys.pickup.card.tips.description)}
              icon={Icon.LIGHT_BULB}
            >
              <SwButton
                type="button"
                modBlock
                modNarrow
                onClick={(e) => {
                  e.preventDefault();
                  openModal(Modals.PICKUP_TIPS_MODAL);
                }}
              >
                {t(i18nKeys.pickup.card.tips.cta)}
              </SwButton>
            </PickupCard>
          </SwGrid>
        </SwColumn>
      </SwGrid>
      <SwGrid>
        {isHeadquarter && (
          <SwColumn className="vl-u-spacer">
            <SwTitle tagName="h2">
              {t(i18nKeys.pickup.title.enterprise)}
            </SwTitle>
            <SwColumn
              className="vl-u-bg-alt vl-u-spacer--medium"
              style={{ padding: '2rem' }}
            >
              <SwSelect
                style={{ width: '100%' }}
                modNoPlaceholder
                defaultValue={String(relative)}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  setRelative(Number(e.target.value));
                  getPickupPlanning(Number(e.target.value));
                  sendCustomInteractionToGTM(
                    EventCategory.PICKUP,
                    EventAction.SELECT,
                    EventLabel.PICKUP_SELECT,
                  );
                }}
              >
                {relativesResponse.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name} - {id}
                  </option>
                ))}
              </SwSelect>
            </SwColumn>
          </SwColumn>
        )}
        <SwColumn>
          <SwTitle tagName="h2">{t(i18nKeys.pickup.title.dates)}</SwTitle>
          <p className="vl-u-spacer--medium">
            {t(i18nKeys.pickup.subtitle.dates)}
          </p>
          {status && (
            <SwAlert
              {...getAlertPropsByType(status.type)}
              title={status.title}
              close={() => setStatus(undefined)}
              closable
              modSmall
            >
              {status.msg}
            </SwAlert>
          )}
          {pickupPlanningLoading ? (
            <SwLoader />
          ) : (
            <>
              {sortedPickupsPerMonth.length ? (
                <>
                  {sortedPickupsPerMonth.map((sortedMonth, i: number) => {
                    const { name, pickups, estimatedCost } = sortedMonth;
                    return (
                      <PickupDatesScheduledTable
                        key={`${name}${i}`}
                        name={name}
                        opened={!i}
                        estimatedCost={estimatedCost}
                        pickups={pickups}
                        openModal={openModal}
                      />
                    );
                  })}
                </>
              ) : (
                <Trans i18nKey={i18nKeys.pickups.results.noResult}>
                  <SwLink
                    onClick={(e) => {
                      e.preventDefault();
                      openModal(Modals.PICKUP_CREATE_PICKUP_MODAL);
                    }}
                  />
                </Trans>
              )}
            </>
          )}
        </SwColumn>
      </SwGrid>
      <SwModal
        id={Modals.PICKUP_CREATE_PICKUP_MODAL}
        component={PickupCreateModal}
        relatives={relativesResponse}
        confirmCloseMessage={t(i18nKeys.dialog.confirmClose.message)}
        onSubmit={onSubmit}
        modMedium
        closable
      />
      <SwModal
        id={Modals.PICKUP_TIPS_MODAL}
        component={PickupTipsModal}
        closable
      />
      {!!currentPickup && (
        <>
          <SwModal
            id={Modals.PICKUP_UPDATE_PICKUP_MODAL}
            component={PickupUpdateModal}
            relatives={relativesResponse}
            pickup={currentPickup}
            providerId={relative}
            confirmCloseMessage={t(i18nKeys.dialog.confirmClose.message)}
            onSubmit={onSubmit}
            modMedium
            closable
          />
          <SwModal
            id={Modals.PICKUP_RESUME_PICKUP_MODAL}
            component={PickupResumeModal}
            pickup={currentPickup}
            providerId={relative}
            confirmCloseMessage={t(i18nKeys.dialog.confirmClose.message)}
            onSubmit={onSubmit}
            closable
          />
          <SwModal
            id={Modals.PICKUP_DELETE_PICKUP_MODAL}
            component={PickupDeleteModal}
            pickup={currentPickup}
            providerId={relative}
            confirmCloseMessage={t(i18nKeys.dialog.confirmClose.message)}
            onSubmit={onSubmit}
            closable
          />
        </>
      )}
    </SwContainer>
  );
};
