import {
  PickupCreate,
  PickupOccurrenceUpdateData,
  PickupType,
  PickupUpdate,
  RecurrentPickupCreateData,
  RecurrentPickupUpdateData,
  StandalonePickupCreateData,
} from '@kaa/api/providers';
import { AlertType } from '@kaa/ui-flanders/components';

export type onSubmitStatus = { type: AlertType; title?: string; msg?: string };

export enum PickupFormFieldNames {
  ID = 'id',
  RELATIVE_ID = 'relativeId',
  ADDRESS = 'address',
  COMMENT = 'comment',
  DATE = 'date',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  SELECTED_DAYS = 'selectedDays',
  SELECTED_WEEKS = 'selectedWeeks',
  TYPE = 'type',
  INITIAL_DATE = 'initialDate',
}

export enum weekSelectionMethods {
  EACH_WEEK = 'EACH_WEEK',
  WEEK_SELECTION = 'WEEK_SELECTION',
}

export type PickupCreateFormData = Omit<PickupCreate, 'address'> &
  StandalonePickupCreateData &
  RecurrentPickupCreateData & {
    [PickupFormFieldNames.ADDRESS]: string;
    [PickupFormFieldNames.TYPE]: PickupType;
  };

export type PickupUpdateFormData = Omit<PickupUpdate, 'address'> &
  PickupOccurrenceUpdateData &
  RecurrentPickupUpdateData & {
    [PickupFormFieldNames.ADDRESS]: string;
  };
