import {
  GetIncorrectVouchersRemittanceParams,
  PaginationDirectives,
  RemittanceIncorrectVouchersBundle,
  RemittanceWithTotalQuantities,
  SupportType,
  VoucherErrors,
} from '@kaa/api/providers';
import { httpTo } from '@kaa/api/providers/utilities';
import { useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AlertType,
  getAlertPropsByType,
  SwAlert,
  SwColumn,
  SwDataTable,
  SwFetchErrorMessage,
  SwLoader,
  SwModalRenderProps,
  SwPaginator,
  SwTitle,
} from '@kaa/ui-flanders/components';
import { FormikActions } from 'formik';
import get from 'lodash.get';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getConfig } from '../../../../../../common/config';
import {
  TableHead,
  TableRowLoader,
  TableRowNoResult,
} from '../../../../../components';
import { useApi } from '../../../../../utils';
import {
  EventAction,
  EventCategory,
  EventLabel,
  sendCustomInteractionToGTM,
} from '../../../../../utils/google-analytics';
import { handleApiError } from '../../../../../utils/validation';
import { IncorrectVoucherWithValidation } from './RemittancesSearchHistoryIncorrect.types';
import { getErrorsFromIncorrectVoucher } from './RemittancesSearchHistoryIncorrect.utils';
import { RemittancesSearchHistoryIncorrectEditRow } from './RemittancesSearchHistoryIncorrectEditRow';
import { RemittancesSearchHistoryIncorrectForm } from './RemittancesSearchHistoryIncorrectForm';

const PAGE_SIZE = 10;

type RemittancesSearchHistoryIncorrectModalProps = SwModalRenderProps<{
  remittance: RemittanceWithTotalQuantities;
}>;

export const RemittancesSearchHistoryIncorrectModal = ({
  remittance,
  setConfirmCloseModal,
}: RemittancesSearchHistoryIncorrectModalProps) => {
  const { t } = useTranslation();
  const { providers } = useApi();

  const { providerId, id: remittanceId } = remittance;

  const [parameters, setParameters] = useState<{
    type?: VoucherErrors;
    pageNumber: number;
    pageSize: number;
  }>({ pageNumber: 1, pageSize: PAGE_SIZE });

  const [pagination, setPagination] = useState<
    PaginationDirectives['paginationDirectives']
  >();

  const [focusedRow, setFocusRow] = useState('');
  const [incorrectRows, setIncorrectRows] = useState(0);

  const [vouchers, setVouchers] = useState<IncorrectVoucherWithValidation[]>(
    [],
  );

  const [validVouchers, setValidVouchers] = useState<{
    [key: string]: true;
  }>({});

  const [
    { value: incorrectVouchersReponse, loading, error },
    getIncorrectVouchers,
  ] = useAsyncCallback(
    async (params: GetIncorrectVouchersRemittanceParams) => {
      const {
        data: { data, paginationDirectives },
      } = await providers.getIncorrectVouchersRemittance(
        providerId,
        remittanceId,
        params,
      );

      const vouchers = data.map((voucher) => {
        const errors = getErrorsFromIncorrectVoucher(voucher);

        return {
          ...voucher,
          errors,
        };
      });

      return { paginationDirectives, vouchers };
    },
    [providers, providerId, remittanceId],
    { loading: true },
  );

  useEffect(() => {
    getIncorrectVouchers(parameters);
  }, [getIncorrectVouchers, parameters]);

  useEffect(() => {
    if (incorrectVouchersReponse) {
      const { paginationDirectives, vouchers } = incorrectVouchersReponse;
      setVouchers(vouchers);
      setPagination(paginationDirectives);
      setIncorrectRows(paginationDirectives?.numberOfItems || 0);
    }
  }, [incorrectVouchersReponse]);

  const [, submitSearch] = useAsyncCallback(
    async (
      incorrectVouchersRemittanceParams: GetIncorrectVouchersRemittanceParams,
      formikActions: FormikActions<GetIncorrectVouchersRemittanceParams>,
    ) => {
      const { setSubmitting, resetForm } = formikActions;

      setSubmitting(false);

      resetForm(incorrectVouchersRemittanceParams);

      setParameters((parameters) => ({
        ...parameters,
        ...incorrectVouchersRemittanceParams,
        pageSize: PAGE_SIZE,
        pageNumber: 1,
      }));

      sendCustomInteractionToGTM(
        EventCategory.REMITTANCES_INCORRECT_VOUCHERS,
        EventAction.FORM_CONFIRMATION,
        `${EventLabel.REMITTANCES_INCORRECT_VOUCHERS_ERRORTYPE}: ${incorrectVouchersRemittanceParams.type}`,
      );
    },
    [setParameters],
  );

  const [, getWorkers] = useAsyncCallback(
    async (query: string) => {
      const {
        data: { data },
      } = await providers.getWorkers(providerId, {
        workerNameOrNiss: query,
        supportType: SupportType.PAPER,
      });
      return data;
    },
    [providers, providerId],
  );

  const [{ value: submitedRowResponse }, submitRow] = useAsyncCallback(
    async (
      formikData: RemittanceIncorrectVouchersBundle,
      formikActions: FormikActions<RemittanceIncorrectVouchersBundle>,
    ) => {
      const {
        workerNiss,
        regionalAgreementId,
        serviceDate,
        activityTypes,
        voucherId,
        issueDate,
      } = formikData;

      const [error, response] = await httpTo(
        providers.correctVoucher(providerId, remittanceId, voucherId, {
          voucherId,
          issueDate,
          regionalAgreementId,
          workerNiss,
          serviceDate,
          activityTypes,
        }),
      );

      sendCustomInteractionToGTM(
        EventCategory.REMITTANCES_INCORRECT_VOUCHERS,
        EventAction.FORM_CONFIRMATION,
        EventLabel.REMITTANCES_INCORRECT_VOUCHERS_SUBMITROW,
      );

      return {
        error,
        response,
        formikActions,
        formikData,
      };
    },
    [providers, providerId, remittanceId],
  );

  useEffect(() => {
    if (submitedRowResponse) {
      const {
        formikActions,
        error,
        response,
        formikData,
      } = submitedRowResponse;
      const { setSubmitting, setStatus } = formikActions;

      setSubmitting(false);

      if (handleApiError(error, formikActions)) {
        return;
      }

      if (!response) {
        setStatus({
          msg: t(i18nKeys.errors.server.DEFAULT),
          type: AlertType.ERROR,
        });
        return;
      }

      setValidVouchers((validVouchers) => ({
        ...validVouchers,
        [formikData.voucherReference]: true,
      }));

      setIncorrectRows((current) => (current > 0 ? current - 1 : 0));

      setFocusRow('');
    }
  }, [submitedRowResponse, setFocusRow]);

  useEffect(() => {
    setConfirmCloseModal(true);
  }, []);

  if (loading && !incorrectVouchersReponse) {
    return <SwLoader />;
  }

  if (error || !incorrectVouchersReponse) {
    return <SwFetchErrorMessage onClick={getIncorrectVouchers} />;
  }

  const transitions = { ease: 'easeIn', duration: 0.4 };
  const animateTr = loading
    ? { height: 0, overflow: 'hidden' }
    : { height: '7.5rem' };

  const regionCode = get(
    getConfig(),
    'buildConfig.regionCode',
  ) as keyof typeof i18nKeys.remittances.general.agreementNumber;

  const head = [
    { text: t(i18nKeys.remittances.correction.set) },
    { text: t(i18nKeys.remittances.general.agreementNumber[regionCode]) },
    { text: t(i18nKeys.general.label.nissWorker) },
    { text: t(i18nKeys.remittances.general.prestationDate) },
    { text: t(i18nKeys.general.label.activity) },
    { text: t(i18nKeys.remittances.general.registeredWorker) },
    { text: t(i18nKeys.remittances.general.workerContract) },
    { text: t(i18nKeys.remittances.general.correctionDeadline) },
    { text: t(i18nKeys.remittances.general.voucherStatus) },
  ];

  return (
    <>
      <SwTitle tagName="h2" className="vl-u-spacer--medium">
        {t(i18nKeys.remittances.correctModal.title)} {remittanceId}
      </SwTitle>
      <SwColumn className="vl-u-spacer">
        <SwAlert
          {...getAlertPropsByType(
            incorrectRows > 0 ? AlertType.ERROR : AlertType.SUCCESS,
          )}
          title={
            incorrectRows > 0
              ? t(i18nKeys.remittances.modal.incorrect.alert.title, {
                  count: incorrectRows,
                })
              : t(i18nKeys.remittances.modal.incorrect.alert.titleSuccess)
          }
        >
          <p>{t(i18nKeys.remittances.modal.incorrect.tip)}</p>
        </SwAlert>
      </SwColumn>
      <SwColumn className="vl-u-spacer" width="6" widthS="12">
        <RemittancesSearchHistoryIncorrectForm
          parameters={parameters}
          submit={submitSearch}
          loading={loading}
        />
      </SwColumn>
      <SwColumn>
        <div className="vl-u-table-overflow">
          <SwDataTable className="vl-u-spacer--medium" modCollapsedM modHover>
            <TableHead elements={head} />
            <tbody>
              {loading ? (
                <TableRowLoader colSpan={head.length}>
                  <span className="alignedCel">
                    <SwLoader
                      style={{
                        display: 'inline-block',
                      }}
                      modMessageHidden
                    />
                  </span>
                </TableRowLoader>
              ) : (
                <>
                  {!vouchers || !vouchers.length ? (
                    <TableRowNoResult
                      colSpan={head.length}
                      style={{ whiteSpace: 'normal' }}
                    >
                      <span className="alignedCel">
                        {t(i18nKeys.general.noResults)}
                      </span>
                    </TableRowNoResult>
                  ) : (
                    vouchers.map((voucher) => (
                      <RemittancesSearchHistoryIncorrectEditRow
                        getWorkers={getWorkers}
                        key={voucher.voucherReference}
                        voucher={voucher}
                        submitRow={submitRow}
                        setFocusRow={setFocusRow}
                        focusedRow={focusedRow}
                        validated={validVouchers[voucher.voucherReference]}
                        providerId={providerId}
                        registerRequired={voucher.registerRequired}
                        contractRequired={voucher.contractRequired}
                      />
                    ))
                  )}
                </>
              )}
            </tbody>
          </SwDataTable>
        </div>
        <div className="vl-u-display-flex">
          {!!pagination && (
            <div style={{ marginLeft: 'auto' }}>
              <SwPaginator
                itemCount={pagination.numberOfItems}
                pageSize={pagination.pageSize}
                selectedPage={pagination.pageNumber}
                setPage={(page) => {
                  setParameters((parameters) => ({
                    ...parameters,
                    pageNumber:
                      typeof page === 'number'
                        ? page
                        : page(parameters?.pageNumber || 1),
                  }));
                }}
              />
            </div>
          )}
        </div>
      </SwColumn>
    </>
  );
};
