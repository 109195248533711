import {
  Activity,
  RemittanceIncorrectVouchersBundle,
  WorkerSummary,
} from '@kaa/api/providers';
import { useOnClick } from '@kaa/common/utils';
import { SwFormValidateOnMount } from '@kaa/ui-flanders/components';
import { Formik, FormikActions } from 'formik';
import React, { useCallback, useRef, useState } from 'react';
import { IncorrectVoucherFieldNames } from './RemittancesSearchHistoryIncorrect.constants';
import { IncorrectVoucherWithValidation } from './RemittancesSearchHistoryIncorrect.types';
import { validateVoucherRow } from './RemittancesSearchHistoryIncorrect.utils';
import { RemittancesSearchHistoryIncorrectEditRowForm } from './RemittancesSearchHistoryIncorrectEditRowForm';
import { RemittancesSearchHistoryIncorrectEditRowFormImage } from './RemittancesSearchHistoryIncorrectEditRowFormImage';

type RemittancesSearchHistoryIncorrectEditRowProps = {
  voucher: IncorrectVoucherWithValidation;
  submitRow: (
    values: RemittanceIncorrectVouchersBundle,
    formikActions: FormikActions<RemittanceIncorrectVouchersBundle>,
  ) => Promise<[Error | undefined, unknown | undefined]>;
  setFocusRow: (voucherReference: string) => void;
  focusedRow: string;
  getWorkers: (
    query: string,
  ) => Promise<[Error | undefined, WorkerSummary[] | undefined]>;
  validated?: boolean;
  providerId: number;
  registerRequired: boolean;
  contractRequired: boolean;
};

export const RemittancesSearchHistoryIncorrectEditRow = ({
  voucher,
  submitRow,
  setFocusRow,
  focusedRow,
  getWorkers,
  validated,
  providerId,
  registerRequired,
  contractRequired,
}: RemittancesSearchHistoryIncorrectEditRowProps) => {
  const ref = useRef(null);

  const [selectedActivities, setSelectedActivities] = useState<Activity[]>(
    voucher.activityTypes,
  );

  const handler = useCallback(() => {
    if (!validated) {
      setFocusRow(voucher.voucherReference);
    }
  }, [setFocusRow, validated]);

  useOnClick(ref, handler);

  return (
    <Formik
      onSubmit={submitRow}
      initialValues={{ ...voucher }}
      validate={validateVoucherRow}
      render={(props) => {
        const {
          values: { activityTypes },
        } = props;

        setSelectedActivities(activityTypes);

        return (
          <>
            <tr
              ref={ref}
              style={{
                ...(focusedRow === voucher.voucherReference
                  ? {
                      backgroundColor: '#e8ebee',
                      border: 'none',
                    }
                  : {}),
                ...(validated && {
                  backgroundColor: '#f7fae5',
                  borderColor: '#8bae00',
                }),
              }}
            >
              <RemittancesSearchHistoryIncorrectEditRowForm
                selectedActivities={selectedActivities}
                registerRequired={registerRequired}
                contractRequired={contractRequired}
                setFocusRow={setFocusRow}
                focusedRow={focusedRow}
                getWorkers={getWorkers}
                validated={validated}
                providerId={providerId}
                {...props}
              />
              <SwFormValidateOnMount />
            </tr>
            <RemittancesSearchHistoryIncorrectEditRowFormImage
              isVisible={focusedRow === voucher.voucherReference}
              imageUrl={voucher.voucherImageUrl}
              error={props.values.voucherErrors.find(
                ({ fieldName }) =>
                  fieldName === IncorrectVoucherFieldNames.VOUCHER_IMAGE_URL,
              )}
            />
          </>
        );
      }}
    />
  );
};
