import {
  AddressTypeReference,
  Pickup,
  PickupStatus,
  PickupType,
} from '@kaa/api/providers';
import { useLuxon } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import { Icon, SwBadge, SwIcon, useTooltip } from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modals } from '../../../constants';
import {
  EventAction,
  EventCategory,
  EventLabel,
  sendCustomInteractionToGTM,
} from '../../../utils/google-analytics';
import {
  getPickupAddress,
  getRecurrentPickupInfo,
} from '../PickupScreen.utils';
import { MotionTd } from './MotionTd';

type PickupDatesScheduledTableRowProps = {
  pickup: Pickup;
  isOpen: boolean;
  openModal: (modal: Modals, pickup?: Pickup | undefined) => void;
};

export const PickupDatesScheduledTableRow = ({
  pickup,
  isOpen,
  openModal,
}: PickupDatesScheduledTableRowProps) => {
  const { t } = useTranslation();
  const { DateTime } = useLuxon();

  const {
    id,
    status,
    type,
    date,
    address,
    hasNonWorkingDay,
    recurrenceStartDate: startDate,
    recurrenceEndDate: endDate,
  } = pickup;

  const isFrozen = status === PickupStatus.CANCELED;

  const canResume =
    type === PickupType.RECURRENT && status === PickupStatus.CANCELED;

  const canEdit = status !== PickupStatus.CONFIRMED;
  const canDelete = status !== PickupStatus.CONFIRMED;

  const infoText = getRecurrentPickupInfo(
    {
      ...pickup,
      startDate,
      endDate,
    },
    t,
  );

  const addressText = getPickupAddress(pickup, t);

  const refToolTip = useTooltip({
    value: t(i18nKeys.pickup.results.tooltip[status]),
    placement: 'auto',
  });

  const refToolTipAddress = useTooltip({
    value: t(i18nKeys.pickup.results.address.tooltip),
    placement: 'auto',
  });

  const buttonStyle = (color: string) => ({
    border: 'none',
    textDecoration: 'underline',
    cursor: 'pointer',
    color,
  });

  return (
    <tr key={`${id}${date}`} className={isFrozen ? 'vl-u-bg-alt' : ''}>
      <MotionTd
        isOpen={isOpen}
        style={{
          width: '15rem',
          textTransform: 'capitalize',
          verticalAlign: 'middle',
        }}
      >
        {String(DateTime.fromISO(date).toFormat('EEEE d MMMM'))}
      </MotionTd>
      <MotionTd
        isOpen={isOpen}
        style={{
          width: '20rem',
          verticalAlign: 'middle',
        }}
      >
        <SwIcon
          icon={type === PickupType.RECURRENT ? Icon.PICK_UP : Icon.TRUCK}
          style={{
            display: 'inline-block',
            transform: `scaleX(${type === PickupType.RECURRENT ? '1' : '-1'}`,
          }}
        />
        <p
          style={{
            display: 'inline-block',
            margin: '0 0 0 1rem',
            fontWeight: 400,
          }}
        >
          {t(i18nKeys.pickup.delivery[type])}
        </p>
      </MotionTd>
      <MotionTd isOpen={isOpen}>
        {!!infoText && (
          <p
            className="vl-u-spacer--xsmall"
            style={{
              fontWeight: 400,
            }}
          >
            {infoText}
          </p>
        )}
        {!!addressText && (
          <div
            style={{
              fontWeight: 400,
            }}
          >
            {`${t(i18nKeys.pickup.address.to)}: ${addressText} `}
            {!!address?.addressType && (
              <>
                <span className="vl-u-text--italic vl-u-text--small">
                  ({t(i18nKeys.general.addressType[address.addressType])})
                </span>
                {address.addressType === AddressTypeReference.UNKNOWN && (
                  <SwBadge
                    modAction
                    modXsmall
                    icon={Icon.INFO}
                    style={{ verticalAlign: 'middle', marginLeft: '.5rem' }}
                    ref={refToolTipAddress}
                  />
                )}
              </>
            )}
          </div>
        )}
      </MotionTd>
      <MotionTd
        isOpen={isOpen}
        style={{
          width: '20rem',
          verticalAlign: 'middle',
        }}
      >
        {t(i18nKeys.pickup.status[status])}
        {(status === PickupStatus.CONFIRMED ||
          status === PickupStatus.NON_WORKING_DAY ||
          status === PickupStatus.CANCELED ||
          (status === PickupStatus.PLANNED && hasNonWorkingDay)) && (
          <SwBadge
            modAction
            modXsmall
            icon={Icon.INFO}
            style={{ verticalAlign: 'middle', marginLeft: '.5rem' }}
            ref={refToolTip}
          />
        )}
      </MotionTd>
      <MotionTd style={{ width: '22rem' }} isOpen={isOpen}>
        <div className="vl-u-display-flex vl-u-flex-v-left">
          {canResume && (
            <button
              type="button"
              style={buttonStyle('#0055cc')}
              onClick={(e) => {
                e.preventDefault();
                openModal(Modals.PICKUP_RESUME_PICKUP_MODAL, pickup);
                sendCustomInteractionToGTM(
                  EventCategory.PICKUP,
                  EventAction.PLAY,
                  EventLabel.PICKUP_MANAGE,
                );
              }}
            >
              <SwIcon modLarge icon={Icon.PLAY} />
              <br />
              {t(i18nKeys.pickup.cta.play)}
            </button>
          )}
          {canEdit && (
            <button
              type="button"
              style={buttonStyle('#0055cc')}
              onClick={(e) => {
                e.preventDefault();
                openModal(Modals.PICKUP_UPDATE_PICKUP_MODAL, pickup);
                sendCustomInteractionToGTM(
                  EventCategory.PICKUP,
                  EventAction.MODIFY,
                  EventLabel.PICKUP_MANAGE,
                );
              }}
            >
              <SwIcon modLarge icon={Icon.EDIT} />
              <br />
              {t(i18nKeys.general.cta.edit)}
            </button>
          )}
          {canDelete && (
            <button
              type="button"
              style={buttonStyle('red')}
              onClick={(e) => {
                e.preventDefault();
                openModal(Modals.PICKUP_DELETE_PICKUP_MODAL, pickup);
                sendCustomInteractionToGTM(
                  EventCategory.PICKUP,
                  EventAction.DELETE,
                  EventLabel.PICKUP_MANAGE,
                );
              }}
            >
              <SwIcon modLarge icon={Icon.BIN} />
              <br />
              {t(i18nKeys.general.cta.cancel)}
            </button>
          )}
        </div>
      </MotionTd>
    </tr>
  );
};
