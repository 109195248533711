import {
  GetServicesHistoryParams,
  PaginationDirectives,
  ProviderType,
  Service,
  ServicesHistoryResponseDataDataExtra,
  Sorting,
  VideoTopicReference,
} from '@kaa/api/providers';
import { useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  SwColumn,
  SwContainer,
  SwDataTable,
  SwFetchErrorMessage,
  SwGrid,
  SwLoader,
  SwModal,
} from '@kaa/ui-flanders/components';
import { FormikActions } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PageHeader,
  TableRowNoResult,
  TutorialVideoLink,
} from '../../components';
import { Modals } from '../../constants';
import { getQueryParams, QueryParams, Routes } from '../../routes';
import { useApi, useSelectedProviderState } from '../../utils';
import {
  EventCategory,
  openModalWithPageView,
  sendSearchToGTM,
} from '../../utils/google-analytics';
import { PrestationDetailsModal } from './components/details/PrestationDetailsModal';
import { PrestationsPagination } from './components/pagination/PrestationsPagination';
import { PrestationsForm } from './components/PrestationsForm';
import {
  getServiceActiveStatusOptions,
  getServiceInactiveStatusOptions,
} from './components/PrestationsForm.utils';
import { PrestationsOverview } from './components/PrestationsOverview';
import { getSearchHistoryOverview } from './components/PrestationsOverview.utils';
import {
  PrestationsTableHead,
  PrestationsTableHeadElement,
} from './components/PrestationsTableHead';
import { PrestationsTableRow } from './components/PrestationsTableRow';
import { PrestationsHistoryTableRowStatus } from './history/PrestationsHistoryTableRowStatus';
import { PRESTATIONS_HISTORY_FILTERS } from './PrestationsScreen.constants';
import {
  OverviewFilter,
  PrestationsHistoryFilters,
} from './PrestationsScreen.types';
import {
  getInitialParameters,
  getServicesHistoryParamsForFitler,
} from './PrestationsScreen.utils';
import { ShiftBy } from '../../components/shiftBy/ShiftBy';

const PRESTATIONS_SEARCH_HISTORY_PAGE_SIZE = 50;

export const PrestationsHistoryScreen = () => {
  const { t } = useTranslation();
  const { providers } = useApi();
  const provider = useSelectedProviderState();

  const filter = getQueryParams<PrestationsHistoryFilters>(QueryParams.FILTER);

  const {
    initialParameters,
    paginationInitialParameters,
  } = getInitialParameters(PRESTATIONS_SEARCH_HISTORY_PAGE_SIZE);

  const [parameters, setParameters] = useState<GetServicesHistoryParams>(
    filter && PRESTATIONS_HISTORY_FILTERS[filter]
      ? {
          ...getServicesHistoryParamsForFitler(
            PRESTATIONS_HISTORY_FILTERS[filter],
            PRESTATIONS_SEARCH_HISTORY_PAGE_SIZE,
            initialParameters,
          ),
          ...paginationInitialParameters,
        }
      : initialParameters,
  );

  const [pagination, setPagination] = useState<
    PaginationDirectives['paginationDirectives']
  >();

  const [servicesExtra, setServicesExtra] = useState<
    ServicesHistoryResponseDataDataExtra | undefined
  >();

  const [services, setServices] = useState<Service[]>([]);

  const [selectedService, setSelectedService] = useState<Service>();

  const applyFilter = (overviewFilter: OverviewFilter) => {
    setParameters((currentParameters) => ({
      ...getServicesHistoryParamsForFitler(
        overviewFilter,
        PRESTATIONS_SEARCH_HISTORY_PAGE_SIZE,
        currentParameters,
      ),
      ...paginationInitialParameters,
    }));
  };

  const resetPrestationForm = () => {
    setParameters(() => initialParameters);
  };

  const [
    {
      value: servicesHistoryDataResponse,
      loading: servicesHistoryDataLoading,
      error: servicesHistoryDataError,
    },
    getPrestationsHistoryData,
  ] = useAsyncCallback(
    async () => {
      const [providerRelatives, servicesOverview] = await Promise.all([
        providers.getProviderRelatives(provider.id),
        providers.getServicesOverviewHistory(provider.id),
      ]);

      const {
        data: {
          data: { headquarter, subsidiaries },
        },
      } = providerRelatives;

      const {
        data: { data: overview },
      } = servicesOverview;

      return {
        overview,
        relatives: [
          headquarter,
          ...subsidiaries.filter(({ isActive }) => isActive),
        ],
      };
    },
    [providers],
    { loading: true },
  );

  const [
    {
      value: servicesHistoryResponse,
      loading: servicesHistoryLoading,
      error: servicesHistoryError,
    },
    getServicesHistory,
  ] = useAsyncCallback(
    async (servicesHistoryParams: GetServicesHistoryParams) =>
      (await providers.getServicesHistory(provider.id, servicesHistoryParams))
        .data,
    [providers],
    { loading: true },
  );

  const [, submitSearch] = useAsyncCallback(
    async (
      servicesParams: GetServicesHistoryParams,
      formikActions: FormikActions<GetServicesHistoryParams>,
    ) => {
      const { setSubmitting, resetForm } = formikActions;

      setSubmitting(false);
      resetForm(servicesParams);

      setParameters((currentParameters: GetServicesHistoryParams) => ({
        ...currentParameters,
        ...servicesParams,
        ...paginationInitialParameters,
      }));

      const {
        workerNameOrNiss, // Sensitive info we can't log due to GDPR
        customerNameOrContractId, // Sensitive info we can't log due to GDPR
        ...servicesParamsWithoutSensitiveInfo
      } = servicesParams;

      sendSearchToGTM(
        Routes.PRESTATIONS_HISTORY,
        JSON.stringify(servicesParamsWithoutSensitiveInfo),
      );

      return {
        formikActions,
        servicesParams,
      };
    },
    [setParameters],
  );

  useEffect(() => {
    getPrestationsHistoryData();
  }, [getPrestationsHistoryData]);

  useEffect(() => {
    getServicesHistory(parameters);
  }, [getServicesHistory, parameters]);

  // TODO KILL ME
  useEffect(() => {
    if (servicesHistoryResponse) {
      const {
        paginationDirectives,
        data: { values, extra },
      } = servicesHistoryResponse;

      setServices(() => values);
      setServicesExtra(() => extra);
      setPagination(() => paginationDirectives);
    }
  }, [servicesHistoryResponse]);

  if (
    (servicesHistoryDataLoading && !servicesHistoryDataResponse) ||
    (servicesHistoryLoading && !servicesHistoryResponse)
  ) {
    return (
      <SwColumn>
        <SwContainer loading />
      </SwColumn>
    );
  }

  if (
    servicesHistoryDataError ||
    servicesHistoryError ||
    !servicesHistoryDataResponse ||
    !servicesHistoryResponse
  ) {
    return (
      <SwContainer error>
        <SwFetchErrorMessage onClick={getServicesHistory} />
      </SwContainer>
    );
  }

  const isSubsidiary = provider.type === ProviderType.SUBSIDIARY;
  const { overview, relatives } = servicesHistoryDataResponse;

  const openModal = (modal: Modals | string, service: Service) => {
    setSelectedService(service);
    openModalWithPageView(modal as Modals);
  };

  const head = [
    {
      text: t(i18nKeys.general.label.id),
    },
    {
      text: t(i18nKeys.general.label.date),
      sorting: Sorting.DATE_ASC,
    },
    {
      text: t(i18nKeys.general.label.creationDate),
      sorting: Sorting.CREATION_DATE_ASC,
    },
    {
      text: t(i18nKeys.general.label.worker),
      sorting: Sorting.WORKER_ASC,
    },
    {
      text: t(i18nKeys.general.label.customer),
      sorting: Sorting.CUSTOMER_ASC,
    },
    { text: t(i18nKeys.general.label.activity) },
    { text: t(i18nKeys.general.label.time) },
    { text: t(i18nKeys.general.label.status) },
  ] as PrestationsTableHeadElement[];

  return (
    <SwContainer>
      <SwGrid modStacked>
        <SwColumn>
          <PageHeader
            className="vl-u-spacer"
            title={t(i18nKeys.navigation.prestationsHistory)}
          />
          <ShiftBy y={-30}>
            <TutorialVideoLink topic={VideoTopicReference.PRESTATIONS} />
          </ShiftBy>
        </SwColumn>
        {overview.totalOfServicesForHistory > 0 && (
          <PrestationsOverview
            overview={getSearchHistoryOverview(overview)}
            route={Routes.PRESTATIONS_HISTORY}
            applyFilter={applyFilter}
            title={t(i18nKeys.general.label.quickfilters)}
            category={EventCategory.PRESTATIONS_HISTORY}
          />
        )}
        <SwColumn>
          <PrestationsForm
            resetPrestationForm={resetPrestationForm}
            isLoading={servicesHistoryLoading}
            initialParameters={parameters}
            isSubsidiary={isSubsidiary}
            relatives={relatives}
            submit={submitSearch}
            voucherStatusOptions={[
              ...getServiceActiveStatusOptions(t),
              ...getServiceInactiveStatusOptions(t),
            ]}
          />
        </SwColumn>
        {!servicesHistoryLoading ? (
          <>
            <SwColumn>
              <div className="vl-u-table-overflow">
                <SwDataTable modLine style={{ border: '.1rem solid #cbd2da' }}>
                  <PrestationsTableHead<GetServicesHistoryParams>
                    head={head}
                    parameters={parameters}
                    setParameters={setParameters}
                    eventCategory={EventCategory.PRESTATIONS_HISTORY}
                  />
                  <tbody>
                    {!services || !services.length ? (
                      <TableRowNoResult colSpan={head.length} />
                    ) : (
                      services.map((service, i) => (
                        <PrestationsTableRow
                          key={service.id}
                          service={service}
                          openModal={openModal}
                          providerId={
                            parameters?.searchedProviderId ||
                            provider.id.toString()
                          }
                          showWorker
                          showActivity
                        >
                          <PrestationsHistoryTableRowStatus service={service} />
                        </PrestationsTableRow>
                      ))
                    )}
                  </tbody>
                </SwDataTable>
              </div>
            </SwColumn>
            {!!servicesExtra && !!pagination && (
              <SwColumn>
                <PrestationsPagination
                  pagination={pagination}
                  extra={servicesExtra}
                  setPage={(page) => {
                    setParameters((currentParameters) => ({
                      ...currentParameters,
                      pageSize: currentParameters.pageSize,
                      pageNumber:
                        typeof page === 'number'
                          ? page
                          : page(currentParameters?.pageNumber || 1),
                    }));
                  }}
                />
              </SwColumn>
            )}
          </>
        ) : (
          <SwColumn>
            <SwLoader />
          </SwColumn>
        )}
      </SwGrid>
      {selectedService && (
        <SwModal
          id={Modals.PRESTATIONS_DETAILS_PRESTATION_MODAL}
          closable
          component={PrestationDetailsModal}
          prestationId={selectedService.id}
          searchedProviderId={
            Number(parameters?.searchedProviderId) || provider.id
          }
          confirmCloseMessage={t(i18nKeys.dialog.confirmClose.message)}
        />
      )}
    </SwContainer>
  );
};
