import { Format } from '@kaa/common/utils';
import { dynamicDataTest } from '@kaa/common/utils/dataTest';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  SwFormColumn,
  SwFormInput,
  SwFormMessageError,
  SwInputField,
  SwRadio,
} from '@kaa/ui-flanders/components';
import { useFormError } from '@kaa/ui-flanders/components/util/hooks/useFormError';
import { FieldPropsClean } from '@kaa/ui-flanders/components/utilities';
import { dataTest } from '@kaa/ui-flanders/datatest/keys';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

type RelativeAddStepSpecificationsBankAccountFieldProps = FieldPropsClean & {
  bankAccount: string;
};

export const RelativeAddStepSpecificationsBankAccountField = ({
  field,
  form,
  bankAccount,
}: RelativeAddStepSpecificationsBankAccountFieldProps) => {
  const { t } = useTranslation();
  const [selectedBankAccount, setSelectedBankAccount] = useState('default');
  const errorMessage = useFormError({ field, form });

  return (
    <>
      <SwFormColumn>
        <SwFormInput>
          <SwRadio
            name="bankAccount"
            className="vl-u-spacer--xsmall"
            id="default"
            checked={selectedBankAccount === 'default'}
            onChange={() => {
              if (selectedBankAccount !== 'default') {
                form.setFieldValue(field.name, bankAccount);
                setSelectedBankAccount('default');
              }
            }}
          >
            {`${Format.iban(bankAccount)} - ${t(
              i18nKeys.relative.add.bankAccount.headquarterAccount,
            )}`}
          </SwRadio>
        </SwFormInput>
      </SwFormColumn>
      <SwFormColumn className="vl-u-spacer">
        <SwFormInput>
          <SwRadio
            name="bankAccount"
            id="custom"
            modBlock
            checked={selectedBankAccount === 'custom'}
            onChange={() => {
              if (selectedBankAccount !== 'custom') {
                form.setFieldValue(field.name, '');
                setSelectedBankAccount('custom');
              }
            }}
          >
            <SwInputField
              name={field.name}
              style={{ width: '100%' }}
              onClick={() => {
                if (selectedBankAccount !== 'custom') {
                  form.setFieldValue(field.name, '');
                  setSelectedBankAccount('custom');
                }
              }}
              value={selectedBankAccount === 'custom' ? field.value : ''}
              onBlur={field.onBlur}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (selectedBankAccount === 'custom') {
                  form.setFieldValue(field.name, Format.iban(e.target.value));
                }
              }}
            />
            {!!errorMessage && (
              <SwFormMessageError
                data-testid={dynamicDataTest(dataTest.SwInputTextFieldError, {
                  fieldName: field.name,
                })}
              >
                {errorMessage}
              </SwFormMessageError>
            )}
          </SwRadio>
        </SwFormInput>
      </SwFormColumn>
    </>
  );
};
