import {
  GetListServicesParams,
  GetServicesHistoryParams,
  GetWorkersServicesParams,
  ServiceActiveStatus,
  ServiceInactiveStatus,
} from '@kaa/api/providers';
import { DateTime } from 'luxon';
import { getStarOfTheDayJsDate } from '../../../../common/utils';
import { OverviewFilter } from './PrestationsScreen.types';

const TODAY_DATE = getStarOfTheDayJsDate(new Date());

export const getInitialParameters = (pageSize: number) => {
  const paginationInitialParameters = {
    pageSize,
    pageNumber: 1,
  };

  const initialParameters = {
    ...paginationInitialParameters,
  };

  return { paginationInitialParameters, initialParameters };
};

const getParamsForFilter = (periodInMonths: number, pageSize: number) => ({
  startDate: DateTime.fromJSDate(TODAY_DATE)
    .minus({ months: periodInMonths })
    .toISODate(),
  endDate: DateTime.fromJSDate(TODAY_DATE).toISODate(),
  pageSize,
  pageNumber: 1,
});

export const getWorkersServicesParamsForFilter = (
  { status, periodInMonths }: OverviewFilter,
  pageSize: number,
  currentParameters = {} as GetWorkersServicesParams,
): GetWorkersServicesParams => ({
  ...currentParameters,
  ...getParamsForFilter(periodInMonths, pageSize),
  status: status as ServiceActiveStatus[],
});

export const getListServicesParamsForFilter = (
  { status, periodInMonths }: OverviewFilter,
  pageSize: number,
  currentParameters = {} as GetListServicesParams,
): GetListServicesParams => ({
  ...currentParameters,
  ...getParamsForFilter(periodInMonths, pageSize),
  status: status as ServiceActiveStatus[],
});

export const getServicesHistoryParamsForFitler = (
  { status, periodInMonths }: OverviewFilter,
  pageSize: number,
  currentParameters = {} as GetServicesHistoryParams,
): GetServicesHistoryParams => ({
  ...currentParameters,
  ...getParamsForFilter(periodInMonths, pageSize),
  status: status as ServiceInactiveStatus[],
});

export const updatePendingServices = (
  current: number | undefined,
  update: number | undefined,
): number => {
  if (current !== undefined && update !== undefined && current + update >= 0) {
    return current + update;
  }

  if (update !== undefined && update >= 0) {
    return update;
  }

  return current || 0;
};
