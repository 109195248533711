import {
  Bill,
  BillsResponse,
  GetProviderBillsParams,
  LanguageCode,
} from '@kaa/api/providers';
import { ActionType, useActionDispatch } from '@kaa/common/context';
import { useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  SwColumn,
  SwContainer,
  SwFetchErrorMessage,
  SwGrid,
  SwModal,
} from '@kaa/ui-flanders/components';
import { RouteComponentProps } from '@reach/router';
import { FormikActions } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '../../../components';
import { Modals } from '../../../constants';
import { useApi, useSelectedProviderIdState } from '../../../utils';
import {
  EventAction,
  EventCategory,
  EventLabel,
  openModalWithPageView,
  sendCustomInteractionToGTM,
} from '../../../utils/google-analytics';
import { PickupBillingDetailsModal } from './detail-modal/PickupBillingDetailsModal';
import { PickupBillingDownloadModal } from './download-modal/PickupBillingDownloadModal';
import { PickupBillingBank } from './PickupBillingBank';
import { PickupBillingContact } from './PickupBillingContact';
import { PickupBillingForm } from './PickupBillingForm';
import { PickupBillingTable } from './PickupBillingTable';

export const PickupBilling = ({ navigate }: RouteComponentProps) => {
  const { t, i18n } = useTranslation();
  const { providers } = useApi();
  const providerId = useSelectedProviderIdState();

  const dispatchAction = useActionDispatch();

  const [parameters, setParameters] = useState({
    year: new Date().getFullYear(),
  } as GetProviderBillsParams);

  const [currentBill, setCurrentBill] = useState<Bill>();

  const [
    {
      value: providerBillingConfigurationResponse,
      loading: providerBillingConfigurationLoading,
      error: providerBillingConfigurationError,
    },
    getProviderBillingConfiguration,
  ] = useAsyncCallback(
    async () => {
      const [
        {
          data: { data: titles },
        },
        {
          data: {
            data: { paymentMode, financialContact },
          },
        },
      ] = await Promise.all([
        providers.getGlobalTitles({
          language: i18n.language.toUpperCase() as LanguageCode,
        }),
        providers.getProviderBillingConfiguration(providerId),
      ]);

      return { titles, paymentMode, financialContact };
    },
    [providers],
    { loading: true },
  );

  const [
    {
      value: providerBillsResponse,
      loading: providerBillsLoading,
      error: providerBillsError,
    },
    getProviderBills,
  ] = useAsyncCallback(
    async (params: GetProviderBillsParams) => {
      const { actions, data }: BillsResponse = (
        await providers.getProviderBills(providerId, params)
      ).data;

      if (actions) {
        dispatchAction({ type: ActionType.ADD, payload: actions });
      }

      return data;
    },
    [providers],
    { loading: true },
  );

  useEffect(() => {
    getProviderBillingConfiguration();
  }, [getProviderBillingConfiguration]);

  useEffect(() => {
    getProviderBills(parameters);
  }, [getProviderBills, parameters]);

  const [, submitSearch] = useAsyncCallback(
    async (
      formikData: GetProviderBillsParams,
      formikActions: FormikActions<GetProviderBillsParams>,
    ) => {
      const { setSubmitting, resetForm } = formikActions;

      setParameters(() => formikData);

      setSubmitting(false);

      resetForm(formikData);

      sendCustomInteractionToGTM(
        EventCategory.PICKUP_BILLING,
        EventAction.CLICK_FORM,
        EventLabel.PICKUP_BILLING_SEARCH_TASKS,
      );
    },
    [setParameters],
  );

  if (
    (providerBillingConfigurationLoading &&
      !providerBillingConfigurationResponse) ||
    (providerBillsLoading && !providerBillsResponse)
  ) {
    return <SwContainer loading />;
  }

  if (
    providerBillingConfigurationError ||
    !providerBillingConfigurationResponse ||
    providerBillsError ||
    !providerBillsResponse
  ) {
    return (
      <SwContainer error>
        <SwFetchErrorMessage onClick={() => getProviderBills(parameters)} />
      </SwContainer>
    );
  }

  const openModal = (modal: Modals, bill: Bill) => {
    setCurrentBill(bill);
    openModalWithPageView(modal);
  };

  const closeModal = () => {
    setCurrentBill(undefined);
  };

  const {
    titles,
    paymentMode,
    financialContact,
  } = providerBillingConfigurationResponse;

  return (
    <SwContainer>
      <SwGrid modStacked>
        <SwColumn>
          <PageHeader
            title={t(i18nKeys.navigation.pickupBilling)}
            //  introduction={t(i18nKeys.pickup.billing.introduction)}
          />
        </SwColumn>
        <SwColumn className="vl-u-spacer--medium">
          <PickupBillingContact
            titles={titles}
            financialContact={financialContact}
          />
        </SwColumn>
        <SwColumn className="vl-u-spacer--medium">
          <PickupBillingBank paymentMode={paymentMode} />
        </SwColumn>
        <SwColumn>
          <PickupBillingForm parameters={parameters} submit={submitSearch} />
        </SwColumn>
        <SwColumn>
          <PickupBillingTable
            bills={providerBillsResponse}
            openModal={openModal}
          />
        </SwColumn>
      </SwGrid>
      {!!currentBill && (
        <>
          <SwModal
            id={Modals.PICKUP_BILLS_DETAIL_MODAL}
            component={PickupBillingDetailsModal}
            onClose={closeModal}
            providerId={providerId}
            billId={currentBill.id}
            modLarge
            closable
          />
          <SwModal
            id={Modals.PICKUP_BILLS_DOWNLOAD_MODAL}
            component={PickupBillingDownloadModal}
            onClose={closeModal}
            providerId={providerId}
            billId={currentBill.id}
            closable
          />
        </>
      )}
    </SwContainer>
  );
};
