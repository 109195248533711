import { Relative } from '@kaa/api/providers';
import { useLuxon } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import { Icon, SwBadge, useTooltip } from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

type RemittancesAutomaticModalRowTooltipProps = {
  relative: Relative;
};
export const RemittancesAutomaticModalRowTooltip = ({
  relative,
}: RemittancesAutomaticModalRowTooltipProps) => {
  const { t } = useTranslation();
  const { DateTime } = useLuxon();
  const noResultMessage = t(i18nKeys.general.noResults);
  const refTooltipInfo = useTooltip({
    value: `<strong>${t(
      i18nKeys.general.label.lastModification,
    )}:</strong><br />${
      relative.lastModificationTimestamp
        ? DateTime.fromISO(relative.lastModificationTimestamp).toFormat(
            'dd/LL/yy',
          )
        : noResultMessage
    }<br /><strong>${t(
      i18nKeys.general.label.author,
    )}:</strong><br />${relative.lastAuthoredBy ??
      noResultMessage}<br /><strong>${t(
      i18nKeys.general.label.company,
    )}:</strong><br /> ${relative.lastAuthorCompanyName ??
      noResultMessage}<br/>${relative.lastAuthorCompanyId ?? ''}`,
    placement: 'auto',
  });

  return (
    <SwBadge
      style={{
        paddingLeft: '1rem',
      }}
      ref={refTooltipInfo}
      modAction
      modXsmall
      icon={Icon.INFO}
    />
  );
};
