import { FileExtensionReference } from '@kaa/api/providers';
import { Format } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  SwColumn,
  SwDocument,
  SwFormGrid,
  SwInputTextField,
  SwTitle,
} from '@kaa/ui-flanders/components';
import { Field } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Templates } from '../../../../../constants';
import { dataTest } from '../../../../../datatest/keys';
import {
  EventAction,
  sendDownloadEventToGTM,
} from '../../../../../utils/google-analytics';
import { getTemplate } from '../../../../../utils/templates';
import { RelativeDetailsScreenFieldNames } from '../RealtiveDetailsScreen.constants';

type RelativeDetailsBankAccountFormProps = {
  modDisabled: boolean;
};

export const RelativeDetailsBankAccountForm = ({
  modDisabled,
}: RelativeDetailsBankAccountFormProps) => {
  const { t } = useTranslation();

  const templatePDF = t(
    getTemplate(Templates.RELATIVE_BANK_ACCOUNT, FileExtensionReference.PDF)
      .value,
  );

  return (
    <SwFormGrid
      className="vl-u-spacer"
      modStacked
      data-testid={dataTest.relatives.details.detailRelativeBankAccountForm}
    >
      <Field
        name={RelativeDetailsScreenFieldNames.IBAN}
        label={t(i18nKeys.headquarterAndAffiliateManagement.bankAccountNumber)}
        component={SwInputTextField}
        parse={(value: string) => (value ? Format.iban(value) : '')}
        modDisabled
      />

      {!modDisabled && (
        <SwColumn>
          <SwTitle tagName="h5" className="vl-u-spacer">
            {t(i18nKeys.headquarterAndAffiliateManagement.updateBankAccount)}
          </SwTitle>
          <SwDocument
            data-testid={dataTest.relatives.details.modifyBankAccount}
            metadata={`${FileExtensionReference.PDF} - 70kb`}
            mimeType={FileExtensionReference.PDF.toLowerCase()}
            title={t(
              i18nKeys.headquarterAndAffiliateManagement
                .updateBankAccountDocumentDescription,
            )}
            external
            href={templatePDF}
            externalLink={templatePDF}
            onClick={() => {
              sendDownloadEventToGTM(
                EventAction.OPEN,
                `${templatePDF.split('/').pop()} | ${templatePDF}`,
              );
            }}
          />
        </SwColumn>
      )}
    </SwFormGrid>
  );
};
