import React from 'react';
import { ProfileScreen } from './profile/ProfileScreen';
import { RelativesScreen } from './relatives/RelativesScreen';

export const EnterpriseRootScreen = () => {
  return (
    <>
      <ProfileScreen />
      <RelativesScreen />
    </>
  );
};
