import { Activity, WorkerSummary } from '@kaa/api/providers';
import { useLuxon } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AutocompleteInputField,
  Icon,
  SwButton,
  SwInputCalendarField,
  SwInputTextField,
  SwLink,
  SwSelectField,
} from '@kaa/ui-flanders/components';
import { Field, FormikProps } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { VoucherDeclarationFieldNames } from './RemittancesVoucherDeclaration.constants';
import { PaperVoucherWithValidation } from './RemittancesVoucherDeclaration.types';
import {
  getAutocompleteWorkersOption,
  menuWorkerOptionFormat,
  onSelectWorkerOption,
} from './RemittancesVoucherDeclaration.utils';
import './RemittancesVouchersDeclarationEditRowForm.style.scss';

type RemittancesVouchersDeclarationEditRowFormProps = FormikProps<
  PaperVoucherWithValidation
> & {
  getWorkers: (
    providerId: string | number,
    query: string,
  ) => Promise<[Error | undefined, WorkerSummary[] | undefined]>;
  removeVoucher: (recordNumber: number) => void;
};

export const RemittancesVouchersDeclarationEditRowForm = ({
  handleSubmit,
  values,
  validateForm,
  setFieldValue,
  getWorkers,
  isSubmitting,
  removeVoucher,
}: RemittancesVouchersDeclarationEditRowFormProps) => {
  const { t } = useTranslation();
  const { DateTime } = useLuxon();

  useEffect(() => {
    validateForm();
  }, [validateForm, values.errors && values.errors.server]);

  const activityOptions = Object.values(Activity).map((aggrement) => {
    return {
      value: aggrement,
      text: t(i18nKeys.general.services[aggrement]),
    };
  });

  return (
    <>
      <td data-title={t(i18nKeys.general.label.record)}>
        {values.recordNumber}
      </td>
      <td data-title={t(i18nKeys.general.label.voucher)}>
        <Field
          id={`${values.recordNumber}-${VoucherDeclarationFieldNames.VOUCHER_REFERENCE}`}
          name={VoucherDeclarationFieldNames.VOUCHER_REFERENCE}
          column={{ width: '12' }}
          component={SwInputTextField}
        />
      </td>
      <td data-title={t(i18nKeys.general.label.nissWorker)}>
        <Field
          id={`${values.recordNumber}-${VoucherDeclarationFieldNames.WORKER_NAME}`}
          name={VoucherDeclarationFieldNames.WORKER_NAME}
          column={{ width: '12' }}
          autoComplete="off"
          getOptions={getAutocompleteWorkersOption(getWorkers)}
          menuOptionFormat={menuWorkerOptionFormat}
          onSelectOption={onSelectWorkerOption(
            VoucherDeclarationFieldNames.WORKER_NAME,
          )}
          minLength={2}
          component={AutocompleteInputField}
        />
      </td>
      <td data-title={t(i18nKeys.general.label.date)}>
        <Field
          id={`${values.recordNumber}-${VoucherDeclarationFieldNames.WORK_DATE}`}
          name={VoucherDeclarationFieldNames.WORK_DATE}
          column={{ width: '12' }}
          visualFormat="d/m/Y"
          minDate={DateTime.local()
            .minus({ month: 13 })
            .toJSDate()}
          maxDate={DateTime.local().toJSDate()}
          defaultDate={DateTime.local().toJSDate()}
          component={SwInputCalendarField}
          format={(date: string) =>
            date ? [DateTime.fromISO(date).toJSDate()] : undefined
          }
          parse={(dates: Date[]) =>
            dates && dates.length
              ? DateTime.fromJSDate(dates[0]).toISO()
              : undefined
          }
        />
      </td>
      <td data-title={t(i18nKeys.general.label.activity)}>
        <Field
          id={`${values.recordNumber}-${VoucherDeclarationFieldNames.ACTIVITY_TYPE}`}
          name={VoucherDeclarationFieldNames.ACTIVITY_TYPE}
          column={{ width: '12' }}
          options={activityOptions}
          component={SwSelectField}
        />
      </td>
      <td style={{ paddingRight: 0 }}>
        <SwButton
          modNarrow
          type="button"
          onClick={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          modLoading={isSubmitting}
          modIconBefore
          icon={Icon.SAVE}
        >
          {t(i18nKeys.general.cta.save)}
        </SwButton>
      </td>
      <td>
        <SwLink
          className="vl-u-text--xsmall"
          style={{ height: '3.5rem' }}
          tagName="button"
          type="button"
          onClick={(e) => {
            e.preventDefault();
            removeVoucher(values.recordNumber);
          }}
        >
          {t(i18nKeys.general.cta.cancel)}
        </SwLink>
      </td>
    </>
  );
};
