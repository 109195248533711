import { GenderCode, LanguageCode } from '@kaa/api/providers';
import { Mask, useLuxon } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  SwCheckboxField,
  SwInputCalendarField,
  SwInputTextField,
  SwSelectField,
} from '@kaa/ui-flanders/components';
import { Field } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { WorkerFormFieldNames } from './WorkerForm.constant';

export const WorkerForm = () => {
  const { t } = useTranslation();
  const { DateTime } = useLuxon();

  const titleOptions = [
    {
      text: t(i18nKeys.general.title.MR),
      value: GenderCode.M,
    },
    {
      text: t(i18nKeys.general.title.MRS),
      value: GenderCode.F,
    },
  ];

  const languageOption = Object.values(LanguageCode).map((language) => ({
    value: language,
    text: t(i18nKeys.general.language[language]),
  }));

  return (
    <>
      <Field
        name={WorkerFormFieldNames.GENDER}
        column={{ width: '12' }}
        labelColumn={{ width: '12' }}
        label={t(i18nKeys.general.label.gender)}
        component={SwSelectField}
        options={titleOptions}
      />
      <Field
        name={WorkerFormFieldNames.FIRSTNAME}
        column={{ width: '12' }}
        labelColumn={{ width: '12' }}
        label={t(i18nKeys.general.label.firstname)}
        component={SwInputTextField}
      />
      <Field
        name={WorkerFormFieldNames.LASTNAME}
        column={{ width: '12' }}
        labelColumn={{ width: '12' }}
        label={t(i18nKeys.general.label.name)}
        component={SwInputTextField}
      />
      <Field
        name={WorkerFormFieldNames.EMAIL_ADDRESS}
        column={{ width: '12' }}
        labelColumn={{ width: '12' }}
        label={t(i18nKeys.general.label.emailAddress)}
        component={SwInputTextField}
      />
      <Field
        name={WorkerFormFieldNames.NISS}
        column={{ width: '12' }}
        labelColumn={{ width: '12' }}
        label={t(i18nKeys.general.label.nissWorker)}
        component={SwInputTextField}
        mask={Mask.nationalNumber}
      />
      <Field
        name={WorkerFormFieldNames.BIRTHDATE}
        label={t(i18nKeys.general.label.birthdate)}
        column={{ width: '12' }}
        labelColumn={{ width: '12' }}
        component={SwInputCalendarField}
        visualFormat="d/m/Y"
        maxDate={DateTime.local().toJSDate()}
        format={(date: string) =>
          date ? [DateTime.fromISO(date).toJSDate()] : undefined
        }
        parse={(dates: Date[]) =>
          dates && dates.length
            ? DateTime.fromJSDate(dates[0])
                .startOf('day')
                .toISODate()
            : ''
        }
      />
      <Field
        name={WorkerFormFieldNames.LANGUAGE}
        column={{ width: '12' }}
        labelColumn={{ width: '12' }}
        label={t(i18nKeys.general.label.language)}
        component={SwSelectField}
        options={languageOption}
      />
      <Field
        name={WorkerFormFieldNames.REFERENCE}
        column={{ width: '12' }}
        labelColumn={{ width: '12' }}
        label={t(i18nKeys.general.label.internalReference)}
        component={SwInputTextField}
      />
      <Field
        name={WorkerFormFieldNames.FOREIGNER}
        column={{ width: '12' }}
        label={t(i18nKeys.general.label.foreignWorker)}
        component={SwCheckboxField}
      />
      <Field
        name={WorkerFormFieldNames.POSTCODE}
        column={{ width: '12' }}
        labelColumn={{ width: '12' }}
        label={t(i18nKeys.general.label.postcode)}
        component={SwInputTextField}
      />
      <Field
        name={WorkerFormFieldNames.PHONE_NUMBER}
        column={{ width: '12' }}
        labelColumn={{ width: '12' }}
        label={t(i18nKeys.general.label.mobilePhoneNumber)}
        component={SwInputTextField}
        mask={Mask.mobilePhoneNumber}
      />
    </>
  );
};
