import {
  FileExtensionReference,
  LanguageCode,
  PaperVouchersRemittance,
  ProviderType,
} from '@kaa/api/providers';
import { httpTo } from '@kaa/api/providers/utilities';
import { useAsyncCallback, useLuxon } from '@kaa/common/utils';
import { max, maxLength, min, required } from '@kaa/common/validation';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AlertType,
  SwActionGroup,
  SwButton,
  SwFetchErrorMessage,
  SwForm,
  SwFormColumn,
  SwFormGrid,
  SwFormInput,
  SwFormMessageLabel,
  SwFormSubmitMessage,
  SwInputCalendarField,
  SwInputField,
  SwInputTextField,
  SwLoader,
  SwModalRenderProps,
  SwSelectField,
  SwTitle,
} from '@kaa/ui-flanders/components';
import { Field, Formik, FormikActions } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { dataTest } from '../../../../../datatest/keys';
import { useApi, useSelectedProviderState } from '../../../../../utils';
import {
  EventAction,
  EventCategory,
  EventLabel,
  sendCustomInteractionToGTM,
} from '../../../../../utils/google-analytics';
import { getApplicationType } from '../../../../../utils/templates';
import {
  createValidatorWithServerErrorHandled,
  handleApiError,
} from '../../../../../utils/validation';
import { RemittancesDeliverySlipCreateModalConfirmation } from './RemittancesDeliverySlipCreateModalConfirmation';
import { DeliverySlipModalFieldNames } from './RemittancesDeliverySlipModal.constants';
import { PaperVouchersRemittanceForm } from './RemittancesDeliverySlipModal.types';

export const RemittancesDeliverySlipCreateModal = ({
  setConfirmCloseModal,
}: SwModalRenderProps) => {
  const { t, i18n } = useTranslation();
  const { DateTime } = useLuxon();
  const provider = useSelectedProviderState();
  const { providers } = useApi();

  const [
    {
      value: relativesResponse,
      loading: relativesLoading,
      error: relativesError,
    },
    getRelatives,
  ] = useAsyncCallback(
    async () => {
      const {
        data: {
          data: { headquarter, subsidiaries },
        },
      } = await providers.getProviderRelatives(provider.id);

      return [headquarter, ...subsidiaries.filter(({ isActive }) => isActive)];
    },
    [providers],
    { loading: true },
  );

  const [{ value: submitedForm }, submit] = useAsyncCallback(
    async (
      formikData: PaperVouchersRemittanceForm,
      formikActions: FormikActions<PaperVouchersRemittanceForm>,
    ) => {
      const {
        relativeId,
        effectiveMonthAndYears,
        ...paperVouchersRemittance
      } = formikData;

      const date = DateTime.fromISO(effectiveMonthAndYears);

      const [error, response] = await httpTo(
        providers.createRemittance(relativeId, {
          ...paperVouchersRemittance,
          language: i18n.language.toUpperCase() as LanguageCode,
          effectiveMonth: date.month,
          effectiveYear: date.year,
        } as PaperVouchersRemittance),
      );

      return {
        error,
        response,
        formikData,
        formikActions,
      };
    },
    [providers],
  );

  useEffect(() => {
    getRelatives();
  }, [getRelatives]);

  useEffect(() => {
    if (submitedForm) {
      const { error, formikActions, response } = submitedForm;
      const { setSubmitting, setStatus } = formikActions;

      if (handleApiError(error, formikActions)) {
        return;
      }

      if (!response) {
        setSubmitting(false);
        setStatus({
          msg: t(i18nKeys.remittances.modal.createDeliverySlip.errorMessage),
          type: AlertType.ERROR,
        });
      }

      sendCustomInteractionToGTM(
        EventCategory.REMITTANCES,
        EventAction.FORM_CONFIRMATION,
        EventLabel.REMITTANCES_PAPER_CREATE_SLIP,
      );
    }
  }, [submitedForm]);

  if (relativesLoading && !relativesResponse) {
    return <SwLoader />;
  }

  if (relativesError || !relativesResponse) {
    return <SwFetchErrorMessage onClick={getRelatives} />;
  }

  if (submitedForm && submitedForm.response) {
    setConfirmCloseModal(false);

    const { formikData } = submitedForm;

    const blob = new Blob([submitedForm.response.data], {
      type: getApplicationType(FileExtensionReference.PDF),
    });

    return (
      <RemittancesDeliverySlipCreateModalConfirmation
        formikData={formikData}
        fileBlob={blob}
      />
    );
  }

  const initialValues = {
    [DeliverySlipModalFieldNames.RELATIVE_ID]: provider.id,
    [DeliverySlipModalFieldNames.NUMBER_OF_VOUCHERS]: 0,
    [DeliverySlipModalFieldNames.EFFECTIVE_MONTH_AND_YEAR]: DateTime.local().toISO(),
    [DeliverySlipModalFieldNames.COMMUNICATION]: '',
  };

  const validate = createValidatorWithServerErrorHandled({
    [DeliverySlipModalFieldNames.RELATIVE_ID]: [required],
    [DeliverySlipModalFieldNames.NUMBER_OF_VOUCHERS]: [
      required,
      min(1),
      max(999999),
    ],
    [DeliverySlipModalFieldNames.EFFECTIVE_MONTH_AND_YEAR]: [required],
    [DeliverySlipModalFieldNames.COMMUNICATION]: [maxLength(15)],
  });

  const relativeOptions = relativesResponse.map(({ id, name }) => ({
    value: id,
    text: `${name} - ${id}`,
  }));

  const isSubsidiary = provider.type === ProviderType.SUBSIDIARY;

  return (
    <>
      <SwTitle tagName="h2" className="vl-u-spacer--medium">
        {t(i18nKeys.remittances.modal.createDeliverySlip.title)}
      </SwTitle>
      <Formik<Partial<PaperVouchersRemittanceForm>>
        onSubmit={submit}
        initialValues={initialValues}
        validate={validate}
      >
        {({ handleSubmit, dirty, isSubmitting }) => {
          setConfirmCloseModal(dirty);

          return (
            <SwForm onSubmit={handleSubmit}>
              <SwFormGrid modStacked className="vl-u-spacer">
                {isSubsidiary ? (
                  <>
                    <SwFormColumn width="12">
                      <SwFormMessageLabel
                        htmlFor={DeliverySlipModalFieldNames.RELATIVE_ID}
                      >
                        {t(
                          i18nKeys.remittances.modal.createDeliverySlip.form
                            .labels.exploitationName,
                        )}
                      </SwFormMessageLabel>
                      <SwFormInput>
                        <SwInputField
                          id={DeliverySlipModalFieldNames.RELATIVE_ID}
                          type="text"
                          value={`${provider.name} - ${provider.id}`}
                          modDisabled
                          modBlock
                        />
                      </SwFormInput>
                    </SwFormColumn>
                  </>
                ) : (
                  <Field
                    name={DeliverySlipModalFieldNames.RELATIVE_ID}
                    label={t(
                      i18nKeys.remittances.modal.createDeliverySlip.form.labels
                        .exploitationName,
                    )}
                    column={{ width: '12' }}
                    labelColumn={{ width: '12' }}
                    options={relativeOptions}
                    component={SwSelectField}
                    modNoPlaceholder
                  />
                )}
                <Field
                  name={DeliverySlipModalFieldNames.COMMUNICATION}
                  label={t(
                    i18nKeys.remittances.modal.createDeliverySlip.form.labels
                      .freeReference,
                  )}
                  column={{ width: '12' }}
                  labelColumn={{ width: '12' }}
                  component={SwInputTextField}
                />
                <Field
                  name={DeliverySlipModalFieldNames.NUMBER_OF_VOUCHERS}
                  label={t(
                    i18nKeys.remittances.modal.createDeliverySlip.form.labels
                      .vouchersAmount,
                  )}
                  column={{ width: '12' }}
                  labelColumn={{ width: '12' }}
                  component={SwInputTextField}
                  type="number"
                  min="0"
                />
                <SwFormColumn>
                  <Field
                    column={{ width: '12' }}
                    labelColumn={{ width: '12' }}
                    name={DeliverySlipModalFieldNames.EFFECTIVE_MONTH_AND_YEAR}
                    label={t(
                      i18nKeys.remittances.modal.createDeliverySlip.form.labels
                        .workMonth,
                    )}
                    component={SwInputCalendarField}
                    visualFormat="m/Y"
                    minDate={DateTime.local()
                      .minus({ month: 14 })
                      .toJSDate()}
                    maxDate={DateTime.local().toJSDate()}
                    defaultDate={DateTime.local().toJSDate()}
                    format={(date: string) =>
                      date ? [DateTime.fromISO(date).toJSDate()] : undefined
                    }
                    parse={(dates: Date[]) =>
                      dates && dates.length
                        ? DateTime.fromJSDate(dates[0]).toISODate()
                        : undefined
                    }
                  />
                </SwFormColumn>
                <SwActionGroup>
                  <SwButton
                    data-testid={
                      dataTest.remittances.paper.deliverySlip
                        .submitTemplateButton
                    }
                    type="submit"
                    modDisabled={!dirty}
                    modLoading={isSubmitting}
                  >
                    {t(
                      i18nKeys.remittances.modal.createDeliverySlip.cta.submit,
                    )}
                  </SwButton>
                </SwActionGroup>
                <SwFormSubmitMessage />
              </SwFormGrid>
            </SwForm>
          );
        }}
      </Formik>
    </>
  );
};
