import { WorkerSummary } from '@kaa/api/providers';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AlertType,
  getAlertPropsByType,
  selectPage,
  SwAlert,
  SwDataTable,
  SwLoader,
  SwPaginator,
  SwTitle,
} from '@kaa/ui-flanders/components';
import { FormikActions } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableHead } from '../../../../../components';
import { PaperVoucherWithValidation } from './RemittancesVoucherDeclaration.types';
import { RemittancesVouchersDeclarationEditRow } from './RemittancesVouchersDeclarationEditRow';

export const VOUCHERS_PAGE_SIZE = 10;

export type RemittancesVouchersDeclarationModalEditProps = {
  vouchers: PaperVoucherWithValidation[];
  voucherProcessed: { total: number; current: number };
  loading: boolean;
  submitRow: (
    values: PaperVoucherWithValidation,
    formikActions: FormikActions<PaperVoucherWithValidation>,
  ) => Promise<[Error | undefined, unknown | undefined]>;
  getWorkers: (
    providerId: string | number,
    query: string,
  ) => Promise<[Error | undefined, WorkerSummary[] | undefined]>;
  removeVoucher: (recordNumber: number) => void;
  setConfirmCloseModal: (bool: boolean) => void;
};

export const RemittancesVouchersDeclarationEdit = ({
  vouchers,
  loading,
  submitRow,
  voucherProcessed,
  getWorkers,
  removeVoucher,
  setConfirmCloseModal,
}: RemittancesVouchersDeclarationModalEditProps) => {
  const { t } = useTranslation();
  const [selectedPage, setSelectedPage] = useState(1);

  useEffect(() => {
    if (
      voucherProcessed.current === voucherProcessed.total &&
      !vouchers.length
    ) {
      setConfirmCloseModal(false);
    }
  }, [setConfirmCloseModal, vouchers.length, voucherProcessed]);

  const head = [
    { text: t(i18nKeys.general.label.record) },
    { text: t(i18nKeys.general.label.voucher) },
    { text: t(i18nKeys.general.label.nissWorker) },
    { text: t(i18nKeys.general.label.date) },
    { text: t(i18nKeys.general.label.activity) },
    { text: '' },
    { text: '' },
  ];

  return (
    <>
      <SwTitle tagName="h2" className="vl-u-spacer--medium">
        {t(i18nKeys.remittances.modal.declareVouchers.title)}
      </SwTitle>
      {voucherProcessed.current === voucherProcessed.total &&
        !vouchers.length && (
          <SwAlert
            {...getAlertPropsByType(AlertType.SUCCESS)}
            title={t(i18nKeys.remittances.modal.declareVoucher.success.title)}
            content={t(
              i18nKeys.remittances.modal.declareVoucher.success.message,
            )}
          />
        )}
      {loading && (
        <>
          <SwLoader
            style={{ textAlign: 'center' }}
            message={t(
              i18nKeys.remittances.modal.declareVoucher.processing.message,
            )}
          />
          <p className="vl-u-align-center vl-u-spacer--medium">
            {voucherProcessed.current}{' '}
            {t(i18nKeys.remittances.modal.declareVoucher.totalVouchers)}{' '}
            {voucherProcessed.total}
          </p>
        </>
      )}
      {!!vouchers.length && (
        <>
          <div className="vl-u-table-overflow">
            <SwDataTable className="vl-u-spacer--medium" modCollapsedM>
              <TableHead elements={head} />
              <tbody>
                {selectPage<PaperVoucherWithValidation>(
                  vouchers,
                  selectedPage,
                  VOUCHERS_PAGE_SIZE,
                ).map((voucher) => (
                  <RemittancesVouchersDeclarationEditRow
                    key={voucher.recordNumber}
                    voucher={voucher}
                    submitRow={submitRow}
                    getWorkers={getWorkers}
                    removeVoucher={removeVoucher}
                  />
                ))}
              </tbody>
            </SwDataTable>
          </div>
          <SwPaginator
            itemCount={vouchers.length}
            pageSize={VOUCHERS_PAGE_SIZE}
            selectedPage={selectedPage}
            setPage={setSelectedPage}
          />
        </>
      )}
    </>
  );
};
