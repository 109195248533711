import {
  Agreement,
  AgreementActionType,
  AgreementStatus,
} from '@kaa/api/providers';
import { ActionsHandler } from '@kaa/common/handlers';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  SwCheckboxField,
  SwColumn,
  SwFormGrid,
  SwGrid,
  SwTitle,
} from '@kaa/ui-flanders/components';
import { Field } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

type VisibilityByAgreementsProps = {
  agreements: Agreement[];
  modDisabled: boolean;
};

export const VisibilityByAgreements = ({
  agreements,
  modDisabled,
}: VisibilityByAgreementsProps) => {
  const { t } = useTranslation();

  return (
    <SwGrid modStacked>
      <SwColumn>
        <SwTitle tagName="h4">
          {t(i18nKeys.visibility.agreements.title)}
        </SwTitle>
      </SwColumn>
      <SwColumn>
        <SwFormGrid>
          {agreements.map(
            (agreement) =>
              agreement.status !== AgreementStatus.WITHDRAWN && (
                <ActionsHandler
                  key={agreement.activityType}
                  resourceId={agreement.resourceId}
                  actions={[AgreementActionType.CAN_EDIT]}
                >
                  {({ isAllowed }) => (
                    <Field
                      name={`agreements.${agreement.activityType}`}
                      label={t(
                        i18nKeys.general.services[agreement.activityType],
                      )}
                      component={SwCheckboxField}
                      modDisabled={modDisabled || !isAllowed}
                    />
                  )}
                </ActionsHandler>
              ),
          )}
        </SwFormGrid>
      </SwColumn>
    </SwGrid>
  );
};
