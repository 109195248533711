/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */

export type AgreementActionType = 'CAN_EDIT';

export const AgreementActionType = {
  CAN_EDIT: 'CAN_EDIT' as AgreementActionType,
};
