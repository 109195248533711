/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */

export type SupportType = 'ELECTRONIC' | 'PAPER';

export const SupportType = {
  ELECTRONIC: 'ELECTRONIC' as SupportType,
  PAPER: 'PAPER' as SupportType,
};
