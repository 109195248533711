import { Provider } from '@kaa/api/providers';
import { useAuthState } from '@kaa/auth/common';
import { AvailableLanguages, StorageKeys } from '@kaa/common/enums';
import { AuthContext } from '@kaa/core-app/providers/types';
import { useLanguage } from '@kaa/core-app/providers/utils';
import React, { Fragment, PropsWithChildren, useEffect } from 'react';

const getLanguage = (
  availableLanguages: AvailableLanguages[],
  provider?: Provider,
): AvailableLanguages | undefined => {
  const localStorageLanguage = localStorage.getItem(
    StorageKeys.LAST_SELECTED_LANGUAGE,
  ) as AvailableLanguages;

  if (
    localStorageLanguage &&
    availableLanguages.includes(localStorageLanguage)
  ) {
    return localStorageLanguage;
  }

  if (
    provider &&
    availableLanguages.includes(
      provider.language.toLowerCase() as AvailableLanguages,
    )
  ) {
    return provider.language.toLowerCase() as AvailableLanguages;
  }

  return undefined;
};

type ChangeLanguageCommonProps = {
  availableLanguages: AvailableLanguages[];
  fallbackLanguage: AvailableLanguages;
  websiteLanguage?: AvailableLanguages;
};

export const ChangeLanguageCommon = ({
  availableLanguages,
  fallbackLanguage,
  websiteLanguage,
  children,
}: PropsWithChildren<ChangeLanguageCommonProps>) => {
  const { user } = useAuthState<AuthContext>();
  const { language, changeLanguage } = useLanguage();

  useEffect(() => {
    if (!user) {
      /* /!\ if no user change lng with the origin /!\ */
      /* /!\ we have a deep link with the lokalise key /!\ */
      if (websiteLanguage && websiteLanguage !== language) {
        changeLanguage(websiteLanguage);
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (user) {
      const newLanguage =
        getLanguage(availableLanguages, user) || fallbackLanguage;

      if (newLanguage !== language) {
        changeLanguage(newLanguage);
      }
    }
    // eslint-disable-next-line
  }, [user]);

  /**
   * Due to the breaking change on the library react-i18next, we have to force
   * the re-render if the language change
   */
  return <Fragment key={language}>{children}</Fragment>;
};
