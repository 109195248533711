import {
  GetWorkerServicesParams,
  GetWorkersServicesParams,
  Provider,
  ServicesOverview,
  ValidateWorkerServicesParams,
  WorkersServicesResponseDataDataWorkersItem,
} from '@kaa/api/providers';
import { ServiceUpdateResponseDataData } from '@kaa/api/providers/model/serviceUpdateResponseDataData';
import { httpTo } from '@kaa/api/providers/utilities';
import { useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AlertType,
  Icon,
  SwColumn,
  SwDataTable,
  SwIcon,
  SwLink,
  SwLoader,
  SwModal,
  SwTitle,
} from '@kaa/ui-flanders/components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modals } from '../../../constants';
import { useApi } from '../../../utils';
import {
  EventAction,
  EventCategory,
  EventLabel,
  openModalWithPageView,
  sendCustomInteractionToGTM,
} from '../../../utils/google-analytics';
import { updateOverview } from '../components/PrestationsOverview.utils';
import { PrestationsSearchValidateModal } from '../components/validate/PrestationsSearchValidateModal';
import { ValidateStatus } from '../PrestationsScreen.types';
import { updatePendingServices } from '../PrestationsScreen.utils';
import { PrestationsSearchWorkersWorkerTable } from './PrestationsSearchWorkersWorkerTable';

type PrestationsSearchWorkersWorkerProps = {
  provider: Provider;
  worker: WorkersServicesResponseDataDataWorkersItem;
  parameters: GetWorkersServicesParams;
  opened?: boolean;
  bookmark?: string;
  overview: ServicesOverview;
  setOverview: (overview: ServicesOverview) => void;
  setValidateStatus: React.Dispatch<
    React.SetStateAction<ValidateStatus | undefined>
  >;
  setTotalOfPendingServices: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
};

export const PrestationsSearchWorkersWorker = ({
  provider,
  worker,
  parameters,
  opened = false,
  bookmark,
  overview,
  setOverview,
  setValidateStatus,
  setTotalOfPendingServices,
}: PrestationsSearchWorkersWorkerProps) => {
  const { t } = useTranslation();
  const { providers } = useApi();

  const [openWorker, setOpenWorker] = useState(opened);

  const {
    workerId,
    workerFullname,
    numberOfPendingServices: numberOfPendingServicesFromResponse,
  } = worker;

  const [numberOfPendingServices, setNumberOfPendingServices] = useState(
    numberOfPendingServicesFromResponse,
  );

  const [
    {
      value: validateWorkerServicesData,
      loading: validateWorkerServicesLoading,
    },
    validateWorkerServices,
  ] = useAsyncCallback(
    async ({
      sort,
      pageNumber,
      pageSize,
      workerId: searchedWorkerId,
      searchedProviderId,
      ...ValidateWorkerServicesParams
    }: GetWorkersServicesParams) => {
      setValidateStatus(undefined);

      const [error, response] = await httpTo(
        providers.validateWorkerServices(
          Number(searchedProviderId || provider.id),
          Number(workerId),
          ValidateWorkerServicesParams as ValidateWorkerServicesParams,
        ),
      );

      return {
        response: response?.data?.data,
        error,
      };
    },
    [providers],
    { loading: false, value: undefined },
  );

  useEffect(() => {
    setNumberOfPendingServices(numberOfPendingServicesFromResponse);
  }, [numberOfPendingServicesFromResponse]);

  useEffect(() => {
    if (validateWorkerServicesData) {
      const { response, error } = validateWorkerServicesData;

      if (error || !response) {
        setValidateStatus({
          type: AlertType.ERROR,
          title: t(i18nKeys.general.label.error),
          msg: t(
            i18nKeys.prestations.modals.validate.alert.validateAllForWorker
              .error,
          ),
        });
        return;
      }

      const {
        overview: newOverview,
        numberOfServicesValidated,
        numberOfPendingServices,
        totalOfPendingServices,
      } = response;

      setValidateStatus({
        type: AlertType.SUCCESS,
        title: t(i18nKeys.general.label.success),
        msg: t(
          i18nKeys.prestations.modals.validate.alert.validateAllForWorker
            .success,
          { count: numberOfServicesValidated },
        ),
      });

      setOverview(newOverview);

      setTotalOfPendingServices(() => totalOfPendingServices);
      setNumberOfPendingServices(() => numberOfPendingServices);

      sendCustomInteractionToGTM(
        EventCategory.PRESTATIONS,
        EventAction.FORM_CONFIRMATION,
        EventLabel.PRESTATIONS_VALIDATE_ALL_OF_ONE,
      );
    }
  }, [validateWorkerServicesData]);

  const onSuccess = ({
    additionalOverview,
    additionalPendingServicesForWorker,
    additionalTotalOfPendingServices,
  }: ServiceUpdateResponseDataData) => {
    setOverview(updateOverview(overview, additionalOverview));

    setTotalOfPendingServices((current) =>
      updatePendingServices(current, additionalTotalOfPendingServices),
    );

    setNumberOfPendingServices((current) =>
      updatePendingServices(current, additionalPendingServicesForWorker),
    );
  };

  const {
    sort,
    pageNumber,
    pageSize,
    workerId: unusedWorkerId,
    bookmark: unusedBookmark,
    ...workerServicesParams
  } = parameters;

  return (
    <li>
      <SwColumn className="vl-u-spacer">
        <div className={openWorker ? 'vl-u-table-overflow' : ''}>
          <SwDataTable
            modLine
            style={{
              border: '.1rem solid #cbd2da',
            }}
          >
            <thead>
              <tr>
                <th colSpan={8}>
                  <div
                    className="vl-u-display-flex vl-grid--v-center"
                    style={{ fontWeight: 'normal', flex: '0 1 auto' }}
                  >
                    <SwTitle
                      tagName="h5"
                      onClick={() => setOpenWorker(!openWorker)}
                      style={{ cursor: 'pointer' }}
                    >
                      <SwIcon
                        icon={Icon.USER}
                        style={{ top: '.3rem', position: 'relative' }}
                      />
                      {workerFullname}
                    </SwTitle>
                    {!validateWorkerServicesLoading ? (
                      <>
                        <p
                          style={{
                            marginRight: '2rem',
                            paddingLeft: '3rem',
                          }}
                        >
                          {t(
                            i18nKeys.prestations.search.table.head.actions
                              .pendingWorks,
                            {
                              count: numberOfPendingServices,
                            },
                          )}
                        </p>
                        {openWorker && numberOfPendingServices > 0 && (
                          <SwLink
                            onClick={(e) => {
                              e.preventDefault();
                              openModalWithPageView(
                                Modals.PRESTATIONS_WORKER_VALIDATE_ALL_PRESTATIONS_MODAL,
                                { appendToId: worker.workerId },
                              );
                            }}
                          >
                            {t(i18nKeys.general.label.validateAll)}
                          </SwLink>
                        )}
                      </>
                    ) : (
                      <div
                        style={{
                          marginRight: '2rem',
                          paddingLeft: '3rem',
                        }}
                      >
                        <SwLoader modMessageHidden />
                      </div>
                    )}
                    <button
                      type="button"
                      onClick={() => setOpenWorker(!openWorker)}
                      style={{ marginLeft: 'auto', border: 'none' }}
                    >
                      <SwIcon
                        modLarge
                        icon={Icon.ARROW_DOWN}
                        style={
                          openWorker
                            ? {
                                display: 'block',
                                transition: 'ease transform .4s',
                                transform: 'rotate(180deg)',
                              }
                            : {
                                display: 'block',
                                transition: 'ease transform .4s',
                                transform: 'rotate(0)',
                              }
                        }
                      />
                    </button>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {openWorker && (
                <tr>
                  <td colSpan={8} style={{ padding: 0 }}>
                    {!validateWorkerServicesLoading ? (
                      <PrestationsSearchWorkersWorkerTable
                        setValidateStatus={setValidateStatus}
                        provider={provider}
                        worker={worker}
                        onSuccess={onSuccess}
                        parameters={
                          workerServicesParams as GetWorkerServicesParams
                        }
                      />
                    ) : (
                      <SwDataTable modLine>
                        <tbody>
                          <tr>
                            <td>
                              <SwLoader />
                            </td>
                          </tr>
                        </tbody>
                      </SwDataTable>
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </SwDataTable>
          {openWorker && numberOfPendingServices > 0 && (
            <SwModal
              id={`${Modals.PRESTATIONS_WORKER_VALIDATE_ALL_PRESTATIONS_MODAL}${worker.workerId}`}
              modalId={`${Modals.PRESTATIONS_WORKER_VALIDATE_ALL_PRESTATIONS_MODAL}${worker.workerId}`}
              component={PrestationsSearchValidateModal}
              confirm={validateWorkerServices}
              parameters={{ ...parameters, bookmark }}
              title={t(i18nKeys.prestations.search.modal.validateAllForWorker, {
                workerName: worker.workerFullname,
              })}
              confirmText={t(i18nKeys.general.cta.validate)}
              isLoading={validateWorkerServicesLoading}
              closable
            />
          )}
        </div>
      </SwColumn>
    </li>
  );
};
