import { ContactPerson, LanguageCode } from '@kaa/api/providers';
import { httpTo } from '@kaa/api/providers/utilities';
import { useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AlertType,
  SwActionGroup,
  SwButton,
  SwColumn,
  SwFetchErrorMessage,
  SwForm,
  SwFormGrid,
  SwFormSubmitMessage,
  SwGrid,
  SwLoader,
  SwModalRenderProps,
  SwTitle,
} from '@kaa/ui-flanders/components';
import { Formik, FormikActions } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ContactPersonsForm } from '../../../../components/contact-persons/ContactPersonForm';
import {
  getDefaultContactPersonValidation,
  getRequiredDefaultContactPersonFields,
} from '../../../../components/contact-persons/ContactPersonForm.utils';
import { dataTest } from '../../../../datatest/keys';
import { useApi } from '../../../../utils';
import {
  createValidatorWithServerErrorHandled,
  handleApiError,
} from '../../../../utils/validation';

type ContactPersonAddModalProps = {
  providerId: string | number;
  onSubmit: (
    contactPerson: ContactPerson,
    actions?: { [key: string]: { [key: string]: boolean } },
  ) => void;
};

export const ContactPersonAddModal = ({
  providerId,
  setConfirmCloseModal,
  onSubmit,
}: SwModalRenderProps<ContactPersonAddModalProps>) => {
  const { t, i18n } = useTranslation();
  const { providers } = useApi();

  const [
    { value: titles = [], loading: titlesLoading, error: titlesError },
    getTitles,
  ] = useAsyncCallback(
    async () => {
      return (
        await providers.getGlobalTitles({
          language: i18n.language.toUpperCase() as LanguageCode,
        })
      ).data.data;
    },
    [providers],
    { loading: true },
  );

  useEffect(() => {
    getTitles();
  }, [getTitles]);

  const [{ value: submitedForm }, submit] = useAsyncCallback(
    async (
      formikData: ContactPerson,
      formikActions: FormikActions<ContactPerson>,
    ) => {
      const [error, response] = await httpTo(
        providers.addContactPerson(Number(providerId), formikData),
      );

      return { error, response, formikData, formikActions };
    },
    [providers, providerId],
  );

  useEffect(() => {
    if (submitedForm) {
      const { error, response, formikData, formikActions } = submitedForm;
      const { setSubmitting, setStatus } = formikActions;
      setSubmitting(false);

      if (handleApiError(error, formikActions)) {
        return;
      }

      if (!response) {
        setStatus({
          msg: t(i18nKeys.contactPersons.modal.add.errorMessage),
          type: AlertType.ERROR,
        });
        return;
      }

      const {
        data: {
          data: { id },
          actions,
        },
      } = response;

      onSubmit({ ...formikData, id: Number(id) }, actions);
    }
  }, [submitedForm]);

  if (titlesLoading && !titles) {
    return <SwLoader />;
  }

  if (titlesError || !titles) {
    return <SwFetchErrorMessage onClick={getTitles} />;
  }

  const validate = createValidatorWithServerErrorHandled(
    getDefaultContactPersonValidation(),
  );

  return (
    <Formik<Partial<ContactPerson>>
      initialValues={getRequiredDefaultContactPersonFields()}
      onSubmit={submit}
      validate={validate}
      render={({ handleSubmit, dirty, isSubmitting }) => {
        setConfirmCloseModal(dirty);
        return (
          <>
            <SwTitle tagName="h2">
              {t(i18nKeys.contactPersons.modal.addContactPerson.title)}
            </SwTitle>
            <SwForm onSubmit={handleSubmit}>
              <SwGrid modStacked>
                <SwColumn>
                  <SwFormGrid modStacked>
                    <ContactPersonsForm titles={titles} />
                    <SwActionGroup style={{ marginTop: '3rem' }}>
                      <SwButton
                        type="submit"
                        data-testid={
                          dataTest.contactPersons.addNewContactPerson
                        }
                        modLoading={isSubmitting}
                        modLarge
                        modDisabled={!dirty}
                      >
                        {t(i18nKeys.contactPersons.cta.add)}
                      </SwButton>
                    </SwActionGroup>
                    <SwFormSubmitMessage />
                  </SwFormGrid>
                </SwColumn>
              </SwGrid>
            </SwForm>
          </>
        );
      }}
    />
  );
};
