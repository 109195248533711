import {
  ContactPerson,
  ContactPersonActionType,
  LanguageCode,
} from '@kaa/api/providers';
import { httpTo } from '@kaa/api/providers/utilities';
import {
  ActionType,
  AlertAction,
  useActionDispatch,
  useAlertDispatch,
} from '@kaa/common/context';
import { ActionsHandler } from '@kaa/common/handlers';
import { useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AlertType,
  SwActionGroup,
  SwAlertProps,
  SwButton,
  SwColumn,
  SwContainer,
  SwFetchErrorMessage,
  SwForm,
  SwFormGrid,
  SwFormSubmitMessage,
  SwGrid,
  SwLink,
  SwTitle,
} from '@kaa/ui-flanders/components';
import { RouteComponentProps } from '@reach/router';
import { Formik } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ContainerLoadingPageHeader } from '../../../components';
import { ContactPersonsForm } from '../../../components/contact-persons/ContactPersonForm';
import {
  getDefaultContactPersonValidation,
  getRequiredDefaultContactPersonFields,
} from '../../../components/contact-persons/ContactPersonForm.utils';
import { getPath, Routes } from '../../../routes';
import { useApi } from '../../../utils';
import {
  EventAction,
  EventCategory,
  EventLabel,
  sendCustomInteractionToGTM,
} from '../../../utils/google-analytics';
import {
  createValidatorWithServerErrorHandled,
  handleApiError,
} from '../../../utils/validation';

type ContactPersonDetailScreenProps = {
  contactId: string;
  enterpriseId?: string;
};

export const ContactPersonDetailScreen = ({
  navigate,
  contactId,
  enterpriseId,
}: RouteComponentProps<ContactPersonDetailScreenProps>) => {
  const { t, i18n } = useTranslation();
  const dispatchAlert = useAlertDispatch<SwAlertProps>();
  const dispatchAction = useActionDispatch();

  const { providers: providersApi } = useApi();

  const [
    {
      value: contactPersonData,
      loading: contactPersonLoading,
      error: contactPersonError,
    },
    getContactPersonData,
  ] = useAsyncCallback(
    async () => {
      if (!contactId || !enterpriseId) {
        return undefined;
      }

      const [titles, contactPerson] = await Promise.all([
        providersApi.getGlobalTitles({
          language: i18n.language.toUpperCase() as LanguageCode,
        }),
        providersApi.getContactPerson(Number(enterpriseId), Number(contactId)),
      ]);

      const { actions } = contactPerson.data;

      if (actions) {
        dispatchAction({ type: ActionType.ADD, payload: actions });
      }

      return {
        titles: titles.data.data,
        contactPerson: contactPerson.data.data,
      };
    },
    [enterpriseId, contactId, providersApi],
    { loading: true },
  );

  useEffect(() => {
    getContactPersonData();
  }, [getContactPersonData]);

  const [{ value: submitedForm }, submit] = useAsyncCallback(
    async (formikData: ContactPerson, formikActions) => {
      if (!enterpriseId) {
        return undefined;
      }

      const [error, response] = await httpTo(
        providersApi.updateContactPerson(
          Number(enterpriseId),
          formikData.id,
          formikData,
        ),
      );

      return {
        error,
        response,
        formikData,
        formikActions,
      };
    },
    [providersApi, enterpriseId],
  );

  useEffect(() => {
    if (submitedForm) {
      const { error, formikData, formikActions } = submitedForm;
      const { setSubmitting, setStatus, resetForm } = formikActions;
      setSubmitting(false);

      if (handleApiError(error, formikActions)) {
        return;
      }

      resetForm(formikData);
      setStatus({
        type: AlertType.SUCCESS,
        msgWithLink: (
          <>
            {t(i18nKeys.contactPersons.details.edit.successMessage)}{' '}
            <SwLink to={getPath(Routes.PICKUP_BILLING)}>
              {t(i18nKeys.contactPersons.details.edit.sucessLink)}
            </SwLink>
          </>
        ),
      });

      sendCustomInteractionToGTM(
        EventCategory.COMPANY_MANAGEMENT,
        EventAction.MODIFY,
        EventLabel.CONTACT_PERSON,
      );
    }
  }, [submitedForm]);

  const [{ loading: isDeleting }, handleDelete] = useAsyncCallback(
    async (contactPerson, { setStatus }) => {
      if (!contactId || !enterpriseId) {
        return;
      }
      try {
        await providersApi.deleteContactPerson(
          Number(enterpriseId),
          Number(contactId),
        );
        if (navigate) {
          dispatchAlert({
            type: AlertAction.ADD,
            payload: {
              type: AlertType.SUCCESS,
              message: t(i18nKeys.contactPersons.alert.delete, {
                deletedContactPerson: `${contactPerson.firstName} ${contactPerson.lastName}`,
              }),
              modSmall: true,
              closable: true,
            },
          });

          navigate(getPath(Routes.ENTERPRISE));

          sendCustomInteractionToGTM(
            EventCategory.COMPANY_MANAGEMENT,
            EventAction.DELETE,
            EventLabel.CONTACT_PERSON,
          );
        }
      } catch (e) {
        setStatus({
          alertType: AlertType.ERROR,
          msg: t(i18nKeys.contactPersons.details.edit.errorMessage),
        });
      }
    },
    [providersApi, enterpriseId, contactId],
  );

  if (!contactPersonData && contactPersonLoading) {
    return (
      <ContainerLoadingPageHeader title={t(i18nKeys.contactPersons.title)} />
    );
  }

  if (!contactId || contactPersonError || !contactPersonData) {
    return (
      <SwContainer error>
        <SwFetchErrorMessage onClick={getContactPersonData} />
      </SwContainer>
    );
  }

  const { titles, contactPerson } = contactPersonData;

  const validate = createValidatorWithServerErrorHandled(
    getDefaultContactPersonValidation(),
  );

  return (
    <SwContainer>
      <SwGrid>
        <SwColumn width="10" widthS="12">
          <SwTitle tagName="h1" className="vl-u-spacer--medium">
            {t(i18nKeys.contactPersons.title)}
          </SwTitle>
          <SwColumn className="vl-u-spacer">
            <Formik
              initialValues={{
                ...getRequiredDefaultContactPersonFields(),
                ...contactPerson,
              }}
              onSubmit={submit}
              validate={validate}
              render={({ handleSubmit, dirty, isSubmitting, setStatus }) => {
                return (
                  <SwForm>
                    <SwGrid modStacked>
                      <SwColumn>
                        <SwFormGrid modStacked>
                          <ContactPersonsForm titles={titles} />
                          <SwActionGroup
                            modCollapseM
                            style={{ marginTop: '3rem' }}
                          >
                            <ActionsHandler
                              resourceId={contactPerson.resourceId}
                              actions={[ContactPersonActionType.CAN_EDIT]}
                            >
                              {({ isAllowed }) => (
                                <SwButton
                                  type="button"
                                  modLoading={isSubmitting}
                                  modLarge
                                  modDisabled={!isAllowed || !dirty}
                                  onClick={() => handleSubmit()}
                                >
                                  {t(i18nKeys.general.cta.save)}
                                </SwButton>
                              )}
                            </ActionsHandler>
                            <ActionsHandler
                              resourceId={contactPerson.resourceId}
                              actions={[ContactPersonActionType.CAN_DELETE]}
                            >
                              <SwButton
                                type="button"
                                modLoading={isDeleting}
                                modLarge
                                modSecondary
                                onClick={() =>
                                  handleDelete(contactPerson, {
                                    setStatus,
                                  })
                                }
                              >
                                {t(i18nKeys.contactPersons.cta.remove)}
                              </SwButton>
                            </ActionsHandler>
                          </SwActionGroup>
                          <SwFormSubmitMessage />
                        </SwFormGrid>
                      </SwColumn>
                    </SwGrid>
                  </SwForm>
                );
              }}
            />
          </SwColumn>
        </SwColumn>
      </SwGrid>
    </SwContainer>
  );
};
