import { SwColumn, SwInfoTile } from '@kaa/ui-flanders/components';
import React, { PropsWithChildren } from 'react';

type DashboardActionsCardProps = {
  title: string;
  tagName: string;
};

export const DashboardActionsCard = ({
  children,
  ...props
}: PropsWithChildren<DashboardActionsCardProps>) => (
  <SwColumn width="3" widthM="6" widthS="12">
    <SwInfoTile {...props} tagName="div">
      {children}
    </SwInfoTile>
  </SwColumn>
);
