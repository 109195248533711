import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  SwAlert,
  SwButton,
  SwColumn,
  SwTextAreaField,
} from '@kaa/ui-flanders/components';
import { Field } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  PickupCreateFormData,
  PickupFormFieldNames,
  PickupUpdateFormData,
} from '../PickupModals.constants';

type PickupModalsCommentProps = {
  values: PickupCreateFormData | PickupUpdateFormData;
  setFieldValue: (field: string, value: any) => void;
  lastComment?: string;
};

export const PickupModalsComment = ({
  values,
  setFieldValue,
  lastComment,
}: PickupModalsCommentProps) => {
  const { t } = useTranslation();

  return (
    <>
      {!!lastComment && (
        <SwColumn width="12" className="vl-u-spacer">
          <SwAlert title={t(i18nKeys.pickup.modal.add.form.commentLast)}>
            <p>{lastComment}</p>
            <SwButton
              onClick={(e) => {
                e.preventDefault();
                setFieldValue(PickupFormFieldNames.COMMENT, lastComment);
              }}
            >
              {t(i18nKeys.general.cta.use)}
            </SwButton>
          </SwAlert>
        </SwColumn>
      )}
      <SwColumn width="12" className="vl-u-spacer">
        <Field
          rows="4"
          name={PickupFormFieldNames.COMMENT}
          label={t(i18nKeys.pickup.modal.add.form.comment)}
          column={{ width: '12' }}
          style={{ resize: 'none' }}
          component={SwTextAreaField}
          maxLength="150"
          placeholder={t(i18nKeys.pickup.modal.add.form.commentInfo)}
        />
      </SwColumn>
    </>
  );
};
