import { useAuthState } from '@kaa/auth/common';
import { LocationProvider } from '@reach/router';
import React, { useRef } from 'react';
import { sendPageViewEvent } from '../../utils/google-analytics';
import { AuthContext } from '../../types';

export const PageViewGTMListener = () => {
  const previous = useRef<string | null>(null);
  const { authUser } = useAuthState<AuthContext>();
  return (
    <LocationProvider>
      {({ location }) => {
        if (previous.current !== location.pathname) {
          previous.current = location.pathname;
          sendPageViewEvent({
            ...location,
            modDefaultPathname: false,
            isLogged: !!authUser,
          });
        }
        return null;
      }}
    </LocationProvider>
  );
};
