import {
  LanguageCode,
  LegalFormCode,
  ProviderSubsidiary,
  Relative,
  TitleCode,
} from '@kaa/api/providers';
import { httpTo } from '@kaa/api/providers/utilities';
import { useAsyncCallback } from '@kaa/common/utils';
import {
  email,
  isIban,
  required,
  website,
  phone,
  mobilePhone,
} from '@kaa/common/validation';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AlertType,
  getDefaultAddressValidation,
  SwFetchErrorMessage,
  SwForm,
  SwFormColumn,
  SwFormGrid,
  SwFormSubmitMessage,
  SwLoader,
  SwModalRenderProps,
  SwTitle,
  SwWizard,
} from '@kaa/ui-flanders/components';
import { Formik, FormikActions } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getDefaultContactPersonValidation } from '../../../../../components/contact-persons/ContactPersonForm.utils';
import { Modals } from '../../../../../constants';
import { useApi } from '../../../../../utils';
import {
  EventAction,
  EventCategory,
  EventLabel,
  sendCustomInteractionToGTM,
  sendPageViewEvent,
} from '../../../../../utils/google-analytics';
import {
  createValidatorWithServerErrorHandled,
  handleApiError,
} from '../../../../../utils/validation';
import { RelativeAddStepCommunication } from './components/RelativeAddStepCommunication';
import { RelativeAddStepInformation } from './components/RelativeAddStepInformation';
import { RelativeAddStepSpecifications } from './components/RelativeAddStepSpecifications';
import { fieldNameThatNeedToBeValidate } from './RelativeAddModal.constants';
import { getRelativeAddSteps } from './RelativeAddModal.utils';

const defaultAddressValidation = getDefaultAddressValidation('address');
const defaultContactPersonValidation = getDefaultContactPersonValidation(
  'mainContact',
);
type RelativeAddModalProps = {
  bankAccount: string;
  vatNumber: string;
  providerId: number;
  onSubmit: (payload: {
    data: Relative;
    actions?: { [key: string]: { [key: string]: boolean } };
  }) => void;
};

export const RelativeAddModal = ({
  setConfirmCloseModal,
  bankAccount,
  vatNumber,
  providerId,
  onSubmit,
}: SwModalRenderProps<RelativeAddModalProps>) => {
  const { t, i18n } = useTranslation();
  const { providers } = useApi();

  const language = i18n.language.toUpperCase() as LanguageCode;

  const [
    { value: relativeAddModalData, loading, error },
    getRelativeAddModalData,
  ] = useAsyncCallback(
    async () => {
      const [locations, agreements, legalForms, titles] = await Promise.all([
        providers.getGlobalLocations(),
        providers.getProviderVisibilityForAgreements(providerId),
        providers.getGlobalLegalForms({
          language,
        }),
        providers.getGlobalTitles({
          language,
        }),
      ]);

      return {
        cities: locations.data.data.cities,
        agreements: agreements.data.data,
        legalForms: legalForms.data.data,
        titles: titles.data.data,
      };
    },
    [providerId, providers],
    { loading: true },
  );

  useEffect(() => {
    getRelativeAddModalData();
  }, [getRelativeAddModalData]);

  const [, getStreets] = useAsyncCallback(
    async (postcode: string, query: string) => {
      try {
        const {
          data: { data },
        } = await providers.getLocationsSuggestions({
          postcode,
          keyword: query,
        });
        return data;
      } catch (e) {
        return [];
      }
    },
    [providers],
  );
  const [{ value: submitedForm }, submit] = useAsyncCallback(
    async (
      formikData: ProviderSubsidiary,
      formikActions: FormikActions<ProviderSubsidiary>,
    ) => {
      const [error, response] = await httpTo(
        providers.createProvider(formikData),
      );
      return {
        error,
        response,
        formikData,
        formikActions,
      };
    },
    [providers],
  );

  useEffect(() => {
    if (submitedForm) {
      const { error, formikActions, response } = submitedForm;
      const { setSubmitting, setStatus } = formikActions;
      if (handleApiError(error, formikActions)) {
        return;
      }
      setSubmitting(false);

      if (!response) {
        setStatus({
          type: AlertType.ERROR,
          msg: t(i18nKeys.contactPersons.modal.add.errorMessage),
        });
        return;
      }
      sendCustomInteractionToGTM(
        EventCategory.COMPANY_MANAGEMENT,
        EventAction.ADD,
        EventLabel.RELATIVE,
      );
      onSubmit(response.data);
    }
  }, [submitedForm]);

  if (loading) {
    return <SwLoader />;
  }

  if (error || !relativeAddModalData) {
    return <SwFetchErrorMessage onClick={getRelativeAddModalData} />;
  }

  const { cities, agreements, legalForms, titles } = relativeAddModalData;

  // TODO ALIGN WITH RelativeDetailsScreenFieldNames AND validations
  const validate = createValidatorWithServerErrorHandled({
    name: [required],
    commercialName: [required],
    subsidyReceivalBankAccount: [required, isIban],
    subsidyReceivalMode: [required],
    emailAddress: [email],
    publicWebsite: [website],
    mobilePhoneNumber: [mobilePhone],
    phoneNumber: [phone],
    ...defaultContactPersonValidation,
    ...defaultAddressValidation,
  });

  const initialValues = {
    name: '',
    headquarterId: providerId,
    commercialName: '',
    vatNumber,
    address: {
      postcode: '',
      city: '',
      street: '',
      houseNumber: '',
      streetId: 0,
    },
    subsidyReceivalBankAccount: bankAccount,
    subsidyReceivalMode: '',
    language: LanguageCode.NL,
    legalForm: LegalFormCode.NUMBER_1,
    mobilePhoneNumber: '',
    phoneNumber: '',
    mainContact: {
      titleCode: TitleCode.MR,
      firstName: '',
      lastName: '',
      emailAddress: '',
      mobilePhoneNumber: '',
      phoneNumber: '',
      role: '',
      language: undefined,
    },
  };

  return (
    <Formik initialValues={initialValues} onSubmit={submit} validate={validate}>
      {({
        handleSubmit,
        dirty,
        touched,
        errors,
        setFieldTouched,
        isSubmitting,
      }) => {
        setConfirmCloseModal(dirty);
        const STEPS = getRelativeAddSteps({ touched, errors, t });
        return (
          <SwForm onSubmit={handleSubmit}>
            <SwFormGrid modStacked>
              <SwFormColumn>
                <SwTitle tagName="h2" className="vl-u-spacer--xsmall">
                  {t(i18nKeys.relative.add.mainTitle)}
                </SwTitle>
                <SwWizard steps={STEPS}>
                  {/** *** STEP INFORMATION **** */}
                  <SwWizard.SwWizardStep
                    next={async (selectStep, activeStep) => {
                      if (!STEPS[1].modDisabled) {
                        selectStep(activeStep + 1);
                        sendPageViewEvent({
                          pathname: `/${Modals.ADD_RELATIVE_MODAL_ID}/step2`,
                        });
                      } else {
                        fieldNameThatNeedToBeValidate.map((fieldName) =>
                          setFieldTouched(fieldName, true),
                        );
                      }
                    }}
                  >
                    <RelativeAddStepInformation
                      availableCities={cities || []}
                      getStreets={getStreets}
                    />
                  </SwWizard.SwWizardStep>
                  {/** *** STEP COMMUNICATION **** */}
                  <SwWizard.SwWizardStep
                    next={async (selectStep, activeStep) => {
                      if (
                        !STEPS[2].modDisabled &&
                        !errors?.hasOwnProperty('mobilePhoneNumber') &&
                        !errors?.hasOwnProperty('phoneNumber')
                      ) {
                        selectStep(activeStep + 1);
                        sendPageViewEvent({
                          pathname: `/${Modals.ADD_RELATIVE_MODAL_ID}/step3`,
                        });
                      }
                    }}
                  >
                    <RelativeAddStepCommunication
                      legalForms={legalForms || []}
                    />
                  </SwWizard.SwWizardStep>
                  {/** *** STEP SPECIFICATIONS **** */}
                  <SwWizard.SwWizardStep
                    isLast={false}
                    modSubmit
                    buttonText={t(i18nKeys.general.label.submit)}
                    modLoading={isSubmitting}
                  >
                    <RelativeAddStepSpecifications
                      bankAccount={bankAccount}
                      titles={titles || []}
                      agreements={agreements || []}
                    />
                  </SwWizard.SwWizardStep>
                </SwWizard>
              </SwFormColumn>
              <SwFormColumn>
                <SwFormSubmitMessage />
              </SwFormColumn>
            </SwFormGrid>
          </SwForm>
        );
      }}
    </Formik>
  );
};
