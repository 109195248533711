/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */

export type WorkerContractType = 'FIXED' | 'PERMANENT';

export const WorkerContractType = {
  FIXED: 'FIXED' as WorkerContractType,
  PERMANENT: 'PERMANENT' as WorkerContractType,
};
