import {
  GetSubsidiesParams,
  Relative,
  Sorting,
  SubsidySummary,
} from '@kaa/api/providers';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import { SwDataTable } from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  getLabelForSorting,
  TableHead,
  TableRowNoResult,
} from '../../../../components';
import { Modals } from '../../../../constants';
import {
  EventAction,
  EventCategory,
  sendCustomInteractionToGTM,
} from '../../../../utils/google-analytics';
import { RemittancesSubsidiesTableRow } from './RemittancesSubsidiesTableRow';

type RemittancesSubsidiesTableProps = {
  relatives: Relative[];
  subsidies: SubsidySummary[];
  parameters: GetSubsidiesParams;
  setParameters: React.Dispatch<React.SetStateAction<GetSubsidiesParams>>;
  openRemittanceModal: (
    selectedSubsidy: SubsidySummary,
    modalId: Modals,
  ) => void;
};

export const RemittancesSubsidiesTable = ({
  relatives,
  subsidies,
  parameters,
  setParameters,
  openRemittanceModal,
}: RemittancesSubsidiesTableProps) => {
  const { t } = useTranslation();

  const head = [
    {
      text: t(i18nKeys.remittances.reimbursement.search.table.remittancesId),
    },
    {
      text: t(
        i18nKeys.remittances.reimbursement.search.table.remittanceReference,
      ),
    },
    {
      text: t(i18nKeys.remittances.reimbursement.search.table.monthOfWork),
      label: getLabelForSorting(Sorting.MONTH_OF_WORK_ASC, parameters?.sort, t),
      onClick: () => {
        setParameters((currentParameters: GetSubsidiesParams) => {
          const newSortType =
            currentParameters?.sort === Sorting.MONTH_OF_WORK_ASC
              ? Sorting.MONTH_OF_WORK_DESC
              : Sorting.MONTH_OF_WORK_ASC;
          sendCustomInteractionToGTM(
            EventCategory.REMITTANCES_REIMBURSEMENT,
            EventAction.SORT_BY,
            t(
              newSortType === Sorting.MONTH_OF_WORK_ASC
                ? i18nKeys.remittances.reimbursement.sort.MONTH_OF_WORK_ASC
                : i18nKeys.remittances.reimbursement.sort.MONTH_OF_WORK_DESC,
            ),
          );
          return {
            ...currentParameters,
            sort: newSortType,
          };
        });
      },
    },
    {
      text: t(i18nKeys.remittances.reimbursement.search.table.supportType),
    },
    {
      text: t(i18nKeys.remittances.reimbursement.search.table.refundCenter),
      label: getLabelForSorting(Sorting.REFUND_CENTER_ASC, parameters?.sort, t),
      onClick: () => {
        setParameters((currentParameters: GetSubsidiesParams) => {
          const newSortType =
            currentParameters?.sort === Sorting.REFUND_CENTER_ASC
              ? Sorting.REFUND_CENTER_DESC
              : Sorting.REFUND_CENTER_ASC;
          sendCustomInteractionToGTM(
            EventCategory.REMITTANCES_REIMBURSEMENT,
            EventAction.SORT_BY,
            t(
              newSortType === Sorting.REFUND_CENTER_ASC
                ? i18nKeys.remittances.reimbursement.sort.REFUND_CENTER_ASC
                : i18nKeys.remittances.reimbursement.sort.REFUND_CENTER_DESC,
            ),
          );
          return {
            ...currentParameters,
            sort: newSortType,
          };
        });
      },
    },
    {
      text: t(
        i18nKeys.remittances.reimbursement.search.table.refundedVoucherQuantity,
      ),
    },
    {
      text: t(i18nKeys.remittances.reimbursement.search.table.paymentAmount),
    },
    {
      text: t(i18nKeys.remittances.reimbursement.search.table.dateOfPayment),
    },
    {
      text: t(i18nKeys.remittances.reimbursement.search.table.paymentReference),
    },
    {
      text: t(i18nKeys.remittances.reimbursement.search.table.seizureBlocking),
    },
  ];

  return (
    <div className="vl-u-table-overflow vl-u-spacer--medium">
      <SwDataTable modLine>
        <TableHead elements={head} />
        <tbody>
          {!subsidies || !subsidies.length ? (
            <TableRowNoResult
              colSpan={head.length}
              style={{ whiteSpace: 'normal' }}
            />
          ) : (
            subsidies.map((subsidy) => (
              <RemittancesSubsidiesTableRow
                key={subsidy.id}
                relatives={relatives}
                subsidy={subsidy}
                openRemittanceModal={openRemittanceModal}
              />
            ))
          )}
        </tbody>
      </SwDataTable>
    </div>
  );
};
