import { GenderCode, Worker, WorkerDetail } from '@kaa/api/providers';
import { httpTo } from '@kaa/api/providers/utilities';
import { ActionType, useActionDispatch } from '@kaa/common/context';
import { Format, useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AlertType,
  getAlertPropsByType,
  Icon,
  SwActionGroup,
  SwAlert,
  SwCheckbox,
  SwColumn,
  SwGrid,
  SwIcon,
  SwLink,
  SwLoader,
  SwModal,
  SwTitle,
  toggleModalById,
} from '@kaa/ui-flanders/components';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modals } from '../../../../constants';
import { useApi } from '../../../../utils';
import {
  EventAction,
  EventCategory,
  EventLabel,
  openModalWithPageView,
  sendCustomInteractionToGTM,
} from '../../../../utils/google-analytics';
import { WorkersActivationModal } from '../../components/WorkersActivationModal';
import { ActivationAlert } from './ActivationAlert';
import { EditWorkerModal } from './EditWorkerModal';

type GeneralInformationProps = {
  workerDetails: WorkerDetail;
  setWorkerDetails: React.Dispatch<
    React.SetStateAction<WorkerDetail | undefined>
  >;
};

export const GeneralInformation = ({
  workerDetails,
  setWorkerDetails,
}: GeneralInformationProps) => {
  const { t } = useTranslation();
  const { providers } = useApi();
  const dispatchAction = useActionDispatch();

  const { providerId, id: workerId, isActive } = workerDetails;

  const [activation, setActivation] = useState<boolean>(isActive);
  const [activationHasChanged, setActivationHasChanged] = useState(false);
  const [editWorkerStatus, setEditWorkerStatus] = useState<{
    type: AlertType;
    msg: string;
  } | null>(null);

  const editWorker = (worker: Worker) => {
    toggleModalById(Modals.WORKERS_EDIT_WORKER_MODAL);
    setWorkerDetails(
      (workerDetails) =>
        ({
          ...workerDetails,
          ...worker,
        } as WorkerDetail),
    );
    setEditWorkerStatus({
      type: AlertType.SUCCESS,
      msg: t(i18nKeys.workers.modals.editWorker.alert.successMessage),
    });
    sendCustomInteractionToGTM(
      EventCategory.WORKER_DETAILS,
      EventAction.FORM_CONFIRMATION,
      EventLabel.WORKER_DETAILS_EDIT,
    );
  };

  const [
    {
      loading: deOrActivateWorkerLoading,
      error: deOrActivateWorkerError,
      value: submitedActivation,
    },
    deOrActivateWorker,
  ] = useAsyncCallback(
    async (active: boolean) => {
      const [error, response] = await httpTo(
        active
          ? providers.deactivateWorker(Number(providerId), workerId)
          : providers.activateWorker(Number(providerId), workerId),
      );

      if (response?.data?.actions) {
        dispatchAction({
          type: ActionType.ADD,
          payload: response.data.actions,
        });
      }

      return {
        active,
        error,
        response,
      };
    },
    [providers, workerDetails],
    { loading: false },
  );

  useEffect(() => {
    if (submitedActivation) {
      const { active, error } = submitedActivation;
      if (error) {
        return;
      }
      setActivation(!active);
      setActivationHasChanged(true);

      sendCustomInteractionToGTM(
        EventCategory.WORKER_DETAILS,
        EventAction.FORM_CONFIRMATION,
        activation
          ? EventLabel.WORKER_DETAILS_DEACTIVATE
          : EventLabel.WORKER_DETAILS_ACTIVATE,
      );
    }
  }, [submitedActivation]);

  const {
    gender,
    firstName,
    lastName,
    niss,
    birthDate,
    language,
    reference,
    emailAddress,
    mobilePhoneNumber,
    postcode,
  } = workerDetails;

  return (
    <>
      <SwTitle
        tagName="h2"
        className="vl-u-display-flex vl-u-flex-align-center vl-u-spacer--xsmall"
      >
        <SwIcon icon={Icon.USER} modLarge style={{ marginRight: '1rem' }} />
        {`${
          gender === GenderCode.F
            ? t(i18nKeys.general.title.MRS)
            : t(i18nKeys.general.title.MR)
        } ${firstName} ${lastName}`}
      </SwTitle>
      <SwGrid className="vl-u-spacer--large vl-u-flex-v-flex-start">
        <SwColumn width="8" widthS="12">
          <p>{t(i18nKeys.worker.details.active.explanation)}</p>
          <SwCheckbox
            checked={activation}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setActivationHasChanged(false);
              openModalWithPageView(Modals.WORKERS_CONFIRMATION_MODAL, {
                appendToPath: activation
                  ? 'deactivate-worker'
                  : 'activate-worker',
              });
            }}
          >
            {deOrActivateWorkerLoading && <SwLoader modMessageHidden />}

            {!deOrActivateWorkerLoading &&
              (activation
                ? t(i18nKeys.workers.label.active)
                : t(i18nKeys.workers.label.inactive))}
          </SwCheckbox>

          {activationHasChanged && (
            <ActivationAlert
              deOrActivateWorkerHasError={deOrActivateWorkerError !== undefined}
              activation={activation}
              close={() => setActivationHasChanged(false)}
            />
          )}
        </SwColumn>
      </SwGrid>

      <SwTitle tagName="h3" tagStyle="h4" className="vl-u-spacer--small">
        {t(i18nKeys.general.label.general)}
      </SwTitle>

      <SwGrid modStacked className="vl-u-spacer--medium">
        <SwColumn width="2" widthM="4" widthS="12">
          <p>{t(i18nKeys.general.label.gender)}</p>
          <strong>
            {gender === GenderCode.F
              ? t(i18nKeys.general.title.MRS)
              : t(i18nKeys.general.title.MR)}
          </strong>
        </SwColumn>
        <SwColumn width="3" widthM="4" widthS="12">
          <p>{t(i18nKeys.general.label.firstname)}</p>
          <strong>{firstName}</strong>
        </SwColumn>
        <SwColumn width="3" widthM="4" widthS="12">
          <p>{t(i18nKeys.general.label.name)}</p>
          <strong>{lastName}</strong>
        </SwColumn>
        <SwColumn width="2" widthM="4" widthS="12">
          <p>{t(i18nKeys.general.label.nissWorker)}</p>
          <strong>{Format.nationalNumber(niss)}</strong>
        </SwColumn>
        <SwColumn width="2" widthM="8" widthS="12">
          <p>{t(i18nKeys.general.label.birthdate)}</p>
          <strong>{Format.date(birthDate)}</strong>
        </SwColumn>
      </SwGrid>
      <SwGrid modStacked>
        <SwColumn width="2" widthM="4" widthS="12">
          <p>{t(i18nKeys.general.label.language)}</p>
          <strong>{language}</strong>
        </SwColumn>
        <SwColumn width="3" widthM="4" widthS="12">
          <p>{t(i18nKeys.general.label.internalReference)}</p>
          <strong>{reference}</strong>
        </SwColumn>
        <SwColumn width="3" widthM="4" widthS="12">
          <p>{t(i18nKeys.general.label.emailAddress)}</p>
          <strong className="vl-u-display-block vl-u-text--ellipse">
            {emailAddress}
          </strong>
        </SwColumn>
        <SwColumn width="2" widthM="4" widthS="12">
          <p>{t(i18nKeys.general.label.phone)}</p>
          <strong>{mobilePhoneNumber}</strong>
        </SwColumn>
        <SwColumn width="2" widthM="8" widthS="12">
          <p>{t(i18nKeys.general.label.postcode)}</p>
          <strong>{postcode}</strong>
        </SwColumn>
      </SwGrid>
      <SwActionGroup style={{ margin: '3rem 0' }}>
        <SwLink
          icon={Icon.EDIT}
          tagName="button"
          type="button"
          modIconAfter
          onClick={() => {
            openModalWithPageView(Modals.WORKERS_EDIT_WORKER_MODAL);
            setEditWorkerStatus(null);
          }}
        >
          {t(i18nKeys.worker.details.cta.edit)}
        </SwLink>
      </SwActionGroup>
      {editWorkerStatus && (
        <SwGrid className="vl-u-flex-v-flex-start">
          <SwColumn width="10" widthS="12">
            <SwAlert
              {...getAlertPropsByType(editWorkerStatus.type)}
              close={() => setEditWorkerStatus(null)}
              closable
              modSmall
            >
              {editWorkerStatus.msg}
            </SwAlert>
          </SwColumn>
        </SwGrid>
      )}
      <hr className="vl-u-spacer--medium" />

      <SwModal
        id={Modals.WORKERS_EDIT_WORKER_MODAL}
        closable
        worker={workerDetails}
        onSuccess={editWorker}
        component={EditWorkerModal}
        confirmCloseMessage={t(i18nKeys.dialog.confirmClose.message)}
      />

      <SwModal
        id={Modals.WORKERS_CONFIRMATION_MODAL}
        closable
        confirmCloseMessage={t(i18nKeys.dialog.confirmClose.message)}
        component={WorkersActivationModal}
        confirm={() => {
          deOrActivateWorker(activation);
        }}
        title={
          !activation
            ? t(i18nKeys.workers.modals.activateWorker.title, { count: 1 })
            : t(i18nKeys.workers.modals.deactivateWorker.title, { count: 1 })
        }
        confirmText={
          !activation
            ? t(i18nKeys.workers.modals.activateWorker.cta, { count: 1 })
            : t(i18nKeys.workers.modals.deactivateWorker.cta, { count: 1 })
        }
      />
    </>
  );
};
