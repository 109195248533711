/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */

export type VideoResponseTitles =
  | 'electronicRemittances'
  | 'export'
  | 'newExtranetWebinar'
  | 'myCompany'
  | 'myServices'
  | 'myWorkers'
  | 'papersRemittances'
  | 'pickups'
  | 'webinar'
  | 'refunds';

export const VideoResponseTitles = {
  electronicRemittances: 'electronicRemittances' as VideoResponseTitles,
  export: 'export' as VideoResponseTitles,
  newExtranetWebinar: 'newExtranetWebinar' as VideoResponseTitles,
  myCompany: 'myCompany' as VideoResponseTitles,
  myServices: 'myServices' as VideoResponseTitles,
  myWorkers: 'myWorkers' as VideoResponseTitles,
  papersRemittances: 'papersRemittances' as VideoResponseTitles,
  pickups: 'pickups' as VideoResponseTitles,
  webinar: 'webinar' as VideoResponseTitles,
  refunds: 'refunds' as VideoResponseTitles,
};
