import {
  ServiceActiveStatus,
  ServiceInactiveStatus,
  ServiceStatus,
} from '@kaa/api/providers';

export enum PrestationsFiltersKey {
  WAITING_FOR_VALIDATION = 'waitingForValidation',
  WAITING_FOR_CONFIRMATION = 'waitingForConfirmation',
  CONTESTED = 'contested',
  MISSING_CONTRACT = 'missingContract',
  MISSING_VOUCHERS = 'missingVouchers',
  CANCELED = 'canceled',
  TO_BE_PAID_OUTSIDE_SV = 'toBePaidOutsideSv',
  IN_REMITTANCE = 'inRemittance',
  VOUCHERS_ASSIGNED = 'vouchersAssigned',
}

export const PRESTATIONS_SEARCH_FILTERS = {
  [PrestationsFiltersKey.WAITING_FOR_VALIDATION]: {
    status: [
      ServiceActiveStatus.CREATED,
      ServiceActiveStatus.MODIFIED,
      ServiceActiveStatus.CORRECTED,
      ServiceActiveStatus.CONTESTED,
      ServiceActiveStatus.CONFIRMED,
    ] as ServiceActiveStatus[],
    periodInMonths: 12,
  },
  [PrestationsFiltersKey.WAITING_FOR_CONFIRMATION]: {
    status: [ServiceActiveStatus.VALIDATED] as ServiceActiveStatus[],
    periodInMonths: 12,
  },
  [PrestationsFiltersKey.CONTESTED]: {
    status: [
      ServiceActiveStatus.CONTESTED,
      ServiceActiveStatus.PROPOSITION_TO_CANCEL,
    ] as ServiceActiveStatus[],
    periodInMonths: 12,
  },
  [PrestationsFiltersKey.MISSING_CONTRACT]: {
    status: [ServiceActiveStatus.MISSING_CONTRACT] as ServiceActiveStatus[],
    periodInMonths: 12,
  },
  [PrestationsFiltersKey.MISSING_VOUCHERS]: {
    status: [
      ServiceActiveStatus.MISSING_VOUCHERS,
      ServiceActiveStatus.PARTIALLY_ASSIGNED,
      ServiceActiveStatus.PARTIALLY_REFUNDED,
    ] as ServiceActiveStatus[],
    periodInMonths: 12,
  },
  [PrestationsFiltersKey.VOUCHERS_ASSIGNED]: {
    status: [
      ServiceActiveStatus.PARTIALLY_ASSIGNED,
      ServiceActiveStatus.PAID,
      ServiceActiveStatus.PARTIALLY_ASSIGNED_TO_BE_PAID_OUTSIDE_SV,
    ] as ServiceActiveStatus[],
    periodInMonths: 12,
  },
};

export const PRESTATIONS_HISTORY_FILTERS = {
  [PrestationsFiltersKey.CANCELED]: {
    status: [ServiceInactiveStatus.CANCELED] as ServiceInactiveStatus[],
    periodInMonths: 6,
  },
  [PrestationsFiltersKey.TO_BE_PAID_OUTSIDE_SV]: {
    status: [
      ServiceInactiveStatus.TO_BE_PAID_OUTSIDE_SV,
      ServiceInactiveStatus.PARTIALLY_REFUNDED_TO_BE_PAID_OUTSIDE_SV,
      ServiceActiveStatus.PARTIALLY_ASSIGNED_TO_BE_PAID_OUTSIDE_SV,
    ] as ServiceStatus[],
    periodInMonths: 12,
  },
  [PrestationsFiltersKey.IN_REMITTANCE]: {
    status: [
      ServiceInactiveStatus.REFUND_REQUESTED,
      ServiceInactiveStatus.PARTIALLY_REFUNDED_TO_BE_PAID_OUTSIDE_SV,
    ] as ServiceStatus[],
    periodInMonths: 3,
  },
};

export const PRESTATIONS_FILTERS = {
  ...PRESTATIONS_SEARCH_FILTERS,
  ...PRESTATIONS_HISTORY_FILTERS,
};
