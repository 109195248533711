import { getResourceIdMock } from '@kaa/api/common/mocks';
import {
  EntityCreatedResponse,
  EntityCreatedResponseData,
  EntityCreatedResponseWithActions,
} from '../model';
import { RESOURCE_PREFIX_PROVIDER } from './provider';
import { getId } from './utils';

const getEntityCreatedResponseData = (): EntityCreatedResponseData => ({
  id: getId(),
});

export const getEntityCreatedResponse = (): EntityCreatedResponse => {
  return {
    data: getEntityCreatedResponseData(),
  };
};

export const getEntityCreatedResponseWithActions = (
  providerId: string | number,
  actions?: {},
): EntityCreatedResponseWithActions => {
  return {
    data: getEntityCreatedResponseData(),
    ...(actions
      ? {
          actions: {
            [getResourceIdMock(RESOURCE_PREFIX_PROVIDER, providerId)]: actions,
          },
        }
      : {}),
  };
};
