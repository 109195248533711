import * as React from 'react';
import { SwLink } from '@kaa/ui-flanders/components';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import { VideoTopicReference } from '@kaa/api/providers';
import { getPath, Routes } from '../../routes';

type TutorialVideoLinkProps = {
  topic: VideoTopicReference;
};

export const TutorialVideoLink = ({ topic }: TutorialVideoLinkProps) => {
  const { t } = useTranslation();
  return (
    <p>
      {t(i18nKeys.tutorialVideoLink.text)}{' '}
      <SwLink to={`${getPath(Routes.VIDEOS)}#${topic}`}>
        {t(i18nKeys.tutorialVideoLink.linkText)}
      </SwLink>
    </p>
  );
};
