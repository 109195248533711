import {
  FileExtensionReference,
  FileStatus,
  RegionCode,
} from '@kaa/api/providers';
import { httpTo } from '@kaa/api/providers/utilities';
import { useAsyncCallback, useLuxon } from '@kaa/common/utils';
import { required } from '@kaa/common/validation';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  Icon,
  SwActionGroup,
  SwButton,
  SwForm,
  SwFormSubmitMessage,
  SwIconList,
  SwIconListItem,
  SwLink,
  SwLoader,
  SwModalRenderProps,
  SwTitle,
  SwUploadField,
} from '@kaa/ui-flanders/components';
import { Field, Formik, FormikActions } from 'formik';
import get from 'lodash.get';
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { getConfig } from '../../../../../common/config';
import { DownloadPublicTemplates } from '../../../../components';
import { Templates } from '../../../../constants';
import { useApi, useSelectedProviderIdState } from '../../../../utils';
import {
  EventAction,
  sendDownloadEventToGTM,
} from '../../../../utils/google-analytics';
import { getAllowedFileTypes, getTemplate } from '../../../../utils/templates';
import {
  createValidatorWithServerErrorHandled,
  handleApiError,
} from '../../../../utils/validation';
import { AddWorkerListModalConfirmation } from './AddWorkerListModalConfirmation';
import { AddWorkerListModalProcessing } from './AddWorkerListModalProcessing';

const FILE_STATUS_SET_TIMEOUT = 5000;

const WORKER_FILE_MAX_FILESIZE = 2000000; // 2mb

export type TrackingState = {
  upload: boolean;
  parsing: boolean;
};

export const TRACKING_STATE_DEFAULT = {
  upload: false,
  parsing: false,
} as TrackingState;

type WorkersListForm = {
  workersFile: File[];
};

type AddWorkerListModalProps = SwModalRenderProps & {
  setTrackingState: React.Dispatch<React.SetStateAction<TrackingState>>;
};

export const AddWorkerListModal = ({
  setTrackingState,
  setConfirmCloseModal,
}: AddWorkerListModalProps) => {
  const { t } = useTranslation();
  const { DateTime } = useLuxon();

  const { providers: providersApi } = useApi();
  const providerId = useSelectedProviderIdState();
  const regionCode = get(getConfig(), 'buildConfig.regionCode') as RegionCode;

  const [{ value: fileParserData }, postWorkersFileParser] = useAsyncCallback(
    async (
      formikData: WorkersListForm,
      formikActions: FormikActions<WorkersListForm>,
    ) => {
      const { workersFile } = formikData;
      const [error, response] = await httpTo(
        providersApi.parseWorkersFile(providerId, workersFile[0]),
      );

      return {
        response,
        formikData,
        formikActions,
        error,
      };
    },
    [providersApi],
  );

  const [
    { value: fileStatusData, loading },
    getWorkersFileStatus,
  ] = useAsyncCallback(
    async (init = false) => {
      const [error, response] = await httpTo(
        providersApi.getWorkersFileStatus(providerId),
      );

      return {
        response: response?.data.data,
        error,
        init,
      };
    },
    [providersApi],
  );

  useEffect(() => {
    setTrackingState(TRACKING_STATE_DEFAULT);
    getWorkersFileStatus(true);
  }, [getWorkersFileStatus]);

  useEffect(() => {
    if (fileStatusData) {
      const { response } = fileStatusData;

      if (!response) {
        // errors
        return;
      }

      setConfirmCloseModal(false);

      setTrackingState((currentState) => ({
        ...currentState,
        parsing:
          response.status === FileStatus.InProgress ||
          response.status === FileStatus.Parsed,
      }));

      if (
        response.status === FileStatus.InProgress ||
        response.status === FileStatus.Parsed
      ) {
        setTimeout(getWorkersFileStatus, FILE_STATUS_SET_TIMEOUT);
      }
    }
  }, [fileStatusData]);

  useEffect(() => {
    if (fileParserData) {
      const { formikActions, error } = fileParserData;

      if (handleApiError(error, formikActions)) {
        return;
      }

      setTrackingState((currentState) => ({
        ...currentState,
        upload: true,
      }));

      getWorkersFileStatus();
    }
  }, [fileParserData]);

  if (fileStatusData && fileStatusData.response) {
    const { response, init } = fileStatusData;

    if (
      response.status === FileStatus.InProgress ||
      response.status === FileStatus.Parsed
    ) {
      return (
        <AddWorkerListModalProcessing fileStatus={response}>
          <SwTitle tagName="h2" className="vl-u-spacer--small">
            {t(i18nKeys.workers.modals.addWorker.list.title)}
          </SwTitle>
          <p className="vl-u-spacer--medium">
            {t(i18nKeys.workers.modals.addWorker.list.processingImport.content)}
          </p>
        </AddWorkerListModalProcessing>
      );
    }

    if (!init) {
      return (
        <AddWorkerListModalConfirmation
          providerId={providerId}
          fileStatus={response}
        >
          <SwTitle tagName="h2" className="vl-u-spacer--medium">
            {t(i18nKeys.workers.modals.addWorker.list.title)}
          </SwTitle>
        </AddWorkerListModalConfirmation>
      );
    }
  }

  if (loading || !fileStatusData) {
    return (
      <>
        <SwTitle tagName="h2" className="vl-u-spacer--medium">
          {t(i18nKeys.workers.modals.addWorker.list.title)}
        </SwTitle>
        <SwLoader />
      </>
    );
  }

  const initialValues = { workersFile: [] };

  const validate = createValidatorWithServerErrorHandled({
    workersFile: [required],
  });

  const templatePDF = t(
    getTemplate(Templates.IMPORT_WORKERS, FileExtensionReference.PDF).value,
  );

  return (
    <>
      <SwTitle tagName="h2" className="vl-u-spacer--large">
        {t(i18nKeys.workers.modals.addWorker.list.title)}
      </SwTitle>
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={postWorkersFileParser}
      >
        {({ handleSubmit, dirty, isSubmitting }) => {
          setConfirmCloseModal(dirty);
          return (
            <>
              <SwForm onSubmit={handleSubmit} className="vl-u-spacer">
                <Field
                  name="workersFile"
                  label={t(
                    i18nKeys.workers.modals.addWorker.list.form.labels
                      .newUpload,
                  )}
                  maxFilesize={WORKER_FILE_MAX_FILESIZE}
                  allowedFileTypes={getAllowedFileTypes([
                    FileExtensionReference.XLS,
                    FileExtensionReference.XLSX,
                    FileExtensionReference.CSV,
                  ])}
                  column={{ width: '12' }}
                  labelColumn={{ width: '12' }}
                  component={SwUploadField}
                />
                {dirty && (
                  <>
                    <SwFormSubmitMessage />
                    <SwActionGroup className="vl-u-spacer">
                      <SwButton type="submit" modLoading={isSubmitting}>
                        {t(
                          i18nKeys.remittances.modal.declareVouchers.cta.title,
                        )}
                      </SwButton>
                    </SwActionGroup>
                  </>
                )}
              </SwForm>
            </>
          );
        }}
      </Formik>
      <SwTitle tagName="h5" className="vl-u-spacer--small">
        {t(i18nKeys.workers.modals.addWorker.list.help.title)}
      </SwTitle>
      <SwIconList className="vl-u-spacer--medium">
        <SwIconListItem
          className="vl-u-hr"
          style={{ paddingBottom: '1rem' }}
          icon={
            regionCode === RegionCode.BE_VLG
              ? Icon.DATA_DOWNLOAD
              : Icon.FILE_DOWNLOAD
          }
          modLarge
        >
          <Trans
            i18nKey={i18nKeys.workers.modals.addWorker.list.help.step1.title}
          />
          <DownloadPublicTemplates templates={Templates.IMPORT_WORKERS} />
        </SwIconListItem>
        <SwIconListItem
          className="vl-u-hr"
          style={{ paddingBottom: '1rem' }}
          icon={
            regionCode === RegionCode.BE_VLG
              ? Icon.DATA_DOWNLOAD
              : Icon.FILE_DOWNLOAD
          }
          modLarge
        >
          <Trans
            i18nKey={i18nKeys.workers.modals.addWorker.list.help.step2.title}
          >
            <strong>2.</strong>
            <SwLink
              to={templatePDF}
              target="_blank"
              onClick={() =>
                sendDownloadEventToGTM(
                  EventAction.OPEN,
                  `${templatePDF.split('/').pop()} | ${templatePDF}`,
                )
              }
            >
              .{FileExtensionReference.PDF.toLowerCase()}
            </SwLink>
          </Trans>
        </SwIconListItem>
        <SwIconListItem
          style={{ paddingBottom: '1rem' }}
          icon={
            regionCode === RegionCode.BE_VLG
              ? Icon.DATA_UPLOAD
              : Icon.FILE_UPLOAD
          }
          modLarge
        >
          <Trans
            i18nKey={i18nKeys.workers.modals.addWorker.list.help.step3.title}
          />
        </SwIconListItem>
      </SwIconList>
      {fileStatusData.response && (
        <AddWorkerListModalConfirmation
          providerId={providerId}
          fileStatus={fileStatusData.response}
        >
          <div
            className="vl-u-display-flex vl-u-spacer--small"
            style={{ alignItems: 'center' }}
          >
            <SwTitle tagName="h3" style={{ margin: '0 1rem 0 0' }}>
              {t(i18nKeys.workers.modals.addWorker.list.lastImport.title)}
            </SwTitle>
            {fileStatusData.response?.lastSuccessTimestamp && (
              <p>
                (
                {DateTime.fromISO(
                  fileStatusData.response.lastSuccessTimestamp,
                ).toFormat('dd/MM/yyyy - HH:mm:ss')}
                )
              </p>
            )}
          </div>
        </AddWorkerListModalConfirmation>
      )}
    </>
  );
};
