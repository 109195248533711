import { ApiError, Pickup, PickupType } from '@kaa/api/providers';
import { httpTo } from '@kaa/api/providers/utilities';
import { useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AlertType,
  SwButton,
  SwLink,
  SwModalRenderProps,
  SwRadio,
  SwTitle,
  toggleModalById,
} from '@kaa/ui-flanders/components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modals } from '../../../../constants';
import { useApi } from '../../../../utils';
import { onSubmitStatus } from '../PickupModals.constants';

type PickupDeleteModalProps = {
  providerId: number;
  pickup: Pickup;
  onSubmit: ({ msg, type }: onSubmitStatus) => void;
};

export const PickupDeleteModal = ({
  providerId,
  pickup,
  onSubmit,
}: SwModalRenderProps<PickupDeleteModalProps>) => {
  const { t } = useTranslation();
  const { providers } = useApi();

  const [pickupTypeToDelete, setPickupTypeToDelete] = useState<PickupType>(
    PickupType.STANDALONE,
  );

  const isRecurrent = pickup.type === PickupType.RECURRENT;

  const [
    { value: deleteSinglePickupResponse },
    deleteSinglePickup,
  ] = useAsyncCallback(async () => {
    const [error, response] = await httpTo(
      providers.deleteSinglePickup(
        providerId,
        pickup.id,
        isRecurrent
          ? {
              pickupDate: pickup.date,
            }
          : undefined,
      ),
    );
    return { error, response };
  }, [providers]);

  const [
    { value: deleteRecurrentPickupResponse },
    deleteRecurrentPickup,
  ] = useAsyncCallback(async () => {
    const [error, response] = await httpTo(
      providers.deleteRecurrentPickup(providerId, pickup.id),
    );
    return { error, response };
  }, [providers]);

  const onSubmitResponse = (error: ApiError | null) => {
    if (error) {
      onSubmit({
        type: AlertType.ERROR,
        msg: t(i18nKeys.errors.server.DEFAULT),
      });
    } else {
      onSubmit({
        type: AlertType.SUCCESS,
        msg:
          pickupTypeToDelete === PickupType.RECURRENT
            ? t(i18nKeys.pickup.alert.confirm.delete[PickupType.RECURRENT])
            : t(i18nKeys.pickup.alert.confirm.delete[PickupType.STANDALONE]),
      });
    }

    toggleModalById(Modals.PICKUP_DELETE_PICKUP_MODAL);
  };

  useEffect(() => {
    if (deleteSinglePickupResponse) {
      const { error } = deleteSinglePickupResponse;
      onSubmitResponse(error);
    }
  }, [deleteSinglePickupResponse]);

  useEffect(() => {
    if (deleteRecurrentPickupResponse) {
      const { error } = deleteRecurrentPickupResponse;
      onSubmitResponse(error);
    }
  }, [deleteRecurrentPickupResponse]);

  return (
    <>
      <SwTitle tagName="h2">
        {isRecurrent
          ? t(i18nKeys.pickup.modal.delete[PickupType.RECURRENT].title)
          : t(i18nKeys.pickup.modal.delete[PickupType.STANDALONE].title)}
      </SwTitle>
      {isRecurrent ? (
        <>
          <div className="vl-u-spacer">
            <SwRadio
              name="pickupType"
              id={PickupType.STANDALONE}
              value={PickupType.STANDALONE}
              onClick={() => setPickupTypeToDelete(PickupType.STANDALONE)}
              checked={pickupTypeToDelete === PickupType.STANDALONE}
            >
              {t(i18nKeys.pickup.modal.delete[PickupType.STANDALONE].cta)}
            </SwRadio>
            <SwRadio
              name="pickupType"
              id={PickupType.RECURRENT}
              value={PickupType.RECURRENT}
              onClick={() => setPickupTypeToDelete(PickupType.RECURRENT)}
              checked={pickupTypeToDelete === PickupType.RECURRENT}
            >
              {t(i18nKeys.pickup.modal.delete[PickupType.RECURRENT].cta)}
            </SwRadio>
          </div>
          <SwButton
            onClick={(e) => {
              e.preventDefault();
              if (pickupTypeToDelete === PickupType.RECURRENT) {
                deleteRecurrentPickup();
              } else {
                deleteSinglePickup();
              }
            }}
          >
            {t(i18nKeys.general.label.submit)}
          </SwButton>
          <SwLink
            style={{ marginLeft: '2rem' }}
            onClick={() => {
              toggleModalById(Modals.PICKUP_DELETE_PICKUP_MODAL);
            }}
          >
            {t(i18nKeys.general.cta.cancel)}
          </SwLink>
        </>
      ) : (
        <>
          <SwButton
            onClick={(e) => {
              e.preventDefault();
              deleteSinglePickup();
            }}
          >
            {t(i18nKeys.pickup.modal.delete[PickupType.STANDALONE].cta)}
          </SwButton>
          <SwLink
            style={{ marginLeft: '2rem' }}
            onClick={() => {
              toggleModalById(Modals.PICKUP_DELETE_PICKUP_MODAL);
            }}
          >
            {t(i18nKeys.general.cta.cancel)}
          </SwLink>
        </>
      )}
    </>
  );
};
