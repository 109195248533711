import { PickupType } from '@kaa/api/providers';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  SwColumn,
  SwFormGrid,
  SwFormMessageLabel,
  SwRadioField,
} from '@kaa/ui-flanders/components';
import { Field } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PickupFormFieldNames } from '../PickupModals.constants';

export const PickupModalsType = () => {
  const { t } = useTranslation();

  return (
    <SwColumn width="12" className="vl-u-spacer">
      <SwFormMessageLabel>
        {t(i18nKeys.pickup.modal.add.form.oneTimeOrRecurrence)}
      </SwFormMessageLabel>
      <SwFormGrid style={{ marginTop: 0 }}>
        <Field
          role="radio"
          name={PickupFormFieldNames.TYPE}
          id={PickupType.STANDALONE}
          label={t(i18nKeys.pickup.modal.add.form.oneTime)}
          component={SwRadioField}
          column={{ width: '0' }}
          labelcolumn={{ width: '0' }}
        />
        <Field
          role="radio"
          name={PickupFormFieldNames.TYPE}
          id={PickupType.RECURRENT}
          label={t(i18nKeys.pickup.modal.add.form.recurrence)}
          component={SwRadioField}
          column={{ width: '0' }}
          labelcolumn={{ width: '0' }}
        />
      </SwFormGrid>
    </SwColumn>
  );
};
