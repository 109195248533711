import { Service, ServiceActiveStatus } from '@kaa/api/providers';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import { SwLink } from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getPath, getWorkerDetailsPath, Routes } from '../../../../routes';
import {
  EventAction,
  EventCategory,
  EventLabel,
  sendCustomInteractionToGTM,
} from '../../../../utils/google-analytics';

export type PrestationsSearchTableRowStatusProps = {
  service: Service;
  providerId: number;
  workerId: string;
};

export const PrestationsSearchTableRowStatusContent = ({
  service,
  providerId,
  workerId,
}: PrestationsSearchTableRowStatusProps) => {
  const { t } = useTranslation();

  const {
    status,
    isActivityContested,
    isDurationInHoursContested,
    automaticValidationRemainingDays,
    assignedVouchers,
    remittableVouchers,
    remittedVouchers,
    durationInHours,
    missingVouchers,
  } = service;

  const contentProps = {
    className: 'vl-u-text--italic vl-u-text--small',
  };

  switch (status) {
    case ServiceActiveStatus.CREATED:
      return (
        <p {...contentProps}>
          {t(i18nKeys.prestations.search.table.status.text.CREATED, {
            count: automaticValidationRemainingDays,
          })}
        </p>
      );
    case ServiceActiveStatus.CORRECTED:
    case ServiceActiveStatus.MODIFIED:
      return (
        <p {...contentProps}>
          {t(i18nKeys.prestations.search.table.status.text.MODIFIED)}
        </p>
      );
    case ServiceActiveStatus.MISSING_CONTRACT:
      return (
        <p {...contentProps}>
          <SwLink
            to={getWorkerDetailsPath(workerId, providerId)}
            onClick={() => {
              sendCustomInteractionToGTM(
                EventCategory.PRESTATIONS,
                EventAction.QUICK_ACTION,
                EventLabel.PRESTATIONS_STATUS_MISSING_CONTRACT,
              );
            }}
          >
            {t(i18nKeys.prestations.search.table.status.text.MISSING_CONTRACT)}
          </SwLink>
        </p>
      );
    case ServiceActiveStatus.MISSING_VOUCHERS:
      return (
        <p {...contentProps}>
          {t(i18nKeys.prestations.search.table.status.text.MISSING_VOUCHERS)}
        </p>
      );
    case ServiceActiveStatus.CONFIRMED:
      return (
        <p {...contentProps}>
          {t(i18nKeys.prestations.search.table.status.text.CONFIRMED)}
        </p>
      );
    case ServiceActiveStatus.CONTESTED:
      return (
        <p {...contentProps}>
          {isActivityContested &&
            isDurationInHoursContested &&
            t(
              i18nKeys.prestations.search.table.status.text.CONTESTED
                .durationAndActivity,
            )}
          {isActivityContested &&
            !isDurationInHoursContested &&
            t(i18nKeys.prestations.search.table.status.text.CONTESTED.activity)}
          {!isActivityContested &&
            isDurationInHoursContested &&
            t(i18nKeys.prestations.search.table.status.text.CONTESTED.duration)}
        </p>
      );
    case ServiceActiveStatus.VALIDATED:
      return automaticValidationRemainingDays ? (
        <p {...contentProps}>
          {t(i18nKeys.prestations.search.table.status.text.VALIDATED, {
            count: automaticValidationRemainingDays,
          })}
        </p>
      ) : null;
    case ServiceActiveStatus.PAID:
      return (
        <p {...contentProps}>
          {t(
            i18nKeys.prestations.search.table.status.text.PARTIALLY
              .remittableVouchers,
            {
              remittableVouchers,
              durationInHours,
            },
          )}
          <SwLink
            to={getPath(Routes.REMITTANCES)}
            onClick={() => {
              sendCustomInteractionToGTM(
                EventCategory.PRESTATIONS,
                EventAction.QUICK_ACTION,
                EventLabel.PRESTATIONS_STATUS_PAID,
              );
            }}
          >
            {t(i18nKeys.prestations.search.table.status.text.PAID)}
          </SwLink>
        </p>
      );
    case ServiceActiveStatus.PARTIALLY_REFUNDED:
      return (
        <p {...contentProps}>
          {t(
            i18nKeys.prestations.search.table.status.text.PARTIALLY
              .assignedVouchers,
            {
              assignedVouchers,
              durationInHours,
            },
          )}
          <br />
          {t(
            i18nKeys.prestations.search.table.status.text.PARTIALLY
              .remittableVouchers,
            {
              remittableVouchers,
              durationInHours,
            },
          )}
          <br />
          {t(
            i18nKeys.prestations.search.table.status.text.PARTIALLY
              .remittedVouchers,
            {
              remittedVouchers,
              durationInHours,
            },
          )}
        </p>
      );
    case ServiceActiveStatus.PARTIALLY_ASSIGNED:
      return (
        <p {...contentProps}>
          {t(
            i18nKeys.prestations.search.table.status.text.PARTIALLY
              .assignedVouchers,
            {
              assignedVouchers,
              durationInHours,
            },
          )}
          <br />
          {t(
            i18nKeys.prestations.search.table.status.text.PARTIALLY
              .remittableVouchers,
            {
              remittableVouchers,
              durationInHours,
            },
          )}{' '}
          {!!remittableVouchers && (
            <SwLink
              to={getPath(Routes.REMITTANCES)}
              onClick={() => {
                sendCustomInteractionToGTM(
                  EventCategory.PRESTATIONS,
                  EventAction.QUICK_ACTION,
                  EventLabel.PRESTATIONS_STATUS_PAID,
                );
              }}
            >
              {t(i18nKeys.prestations.search.table.status.text.PARTIALLY.cta)}
            </SwLink>
          )}
          <br />
          {t(
            i18nKeys.prestations.search.table.status.text.PARTIALLY
              .remittedVouchers,
            {
              remittedVouchers,
              durationInHours,
            },
          )}
        </p>
      );
    case ServiceActiveStatus.PROPOSITION_TO_CANCEL:
      return (
        <p {...contentProps}>
          {t(
            i18nKeys.prestations.search.table.status.text.PROPOSITION_TO_CANCEL,
          )}
        </p>
      );
    case ServiceActiveStatus.PARTIALLY_ASSIGNED_TO_BE_PAID_OUTSIDE_SV:
      return (
        <p {...contentProps}>
          {t(
            i18nKeys.prestations.search.table.status.text
              .TO_BE_PARTIALLY_PAID_OUTSIDE_SV,
            {
              missingVouchers,
            },
          )}
        </p>
      );

    case ServiceActiveStatus.READY_FOR_VOUCHER_ASSIGNMENT:
    default:
      return null;
  }
};
