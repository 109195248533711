import { Provider, VideoTopicReference } from '@kaa/api/providers';
import { AuthAction, useAuthDispatch } from '@kaa/auth/common';
import { APP_ID } from '@kaa/core-app/providers/constants';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  getAlertPropsByType,
  SwActionLabel,
  SwAlert,
  SwColumn,
  SwContainer,
  SwForm,
  SwFormColumn,
  SwFormGrid,
  SwFormInput,
  SwFormMessageLabel,
  SwGrid,
  SwInputField,
  SwModal,
  SwTitle,
  toggleModalById,
} from '@kaa/ui-flanders/components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getConfig } from '../../../../common/config';
import { PageHeader, TutorialVideoLink } from '../../../components';
import { Modals } from '../../../constants';
import { dataTest } from '../../../datatest/keys';
import { AuthContext } from '../../../types';
import { useSelectedProviderState } from '../../../utils';
import {
  EventAction,
  EventCategory,
  EventLabel,
  openModalWithPageView,
  sendCustomInteractionToGTM,
} from '../../../utils/google-analytics';
import { ProfileProviderChangeEmailModal } from './components/ProfileProviderChangeEmailModal';
import { ProfileProviderChangePasswordModal } from './components/ProfileProviderChangePasswordModal';
import { ProfileStatusType } from './ProfileScreen.types';
import { ShiftBy } from '../../../components/shiftBy/ShiftBy';

export const ProfileScreen = () => {
  const { t } = useTranslation();
  const provider = useSelectedProviderState();
  const dispatchAuth = useAuthDispatch<AuthContext>();

  const [status, setStatus] = useState<ProfileStatusType | null>();

  const openModal = (modalId: Modals) => {
    setStatus(null);

    openModalWithPageView(modalId);
  };

  const onSubmitModal = (
    modalId: Modals,
    profileStatus: ProfileStatusType,
    providerUpdate?: Provider,
  ) => {
    setStatus(profileStatus);

    if (providerUpdate) {
      dispatchAuth({
        type: AuthAction.UPDATE,
        payload: {
          user: providerUpdate,
        },
      });
    }

    toggleModalById(modalId);

    switch (modalId) {
      case Modals.CHANGE_EMAIL_MODAL_ID:
        sendCustomInteractionToGTM(
          EventCategory.PROFILE,
          EventAction.SEND_FORM,
          EventLabel.PROFILE_EMAIL_EDITED,
        );
        break;
      case Modals.CHANGE_PASSWORD_MODAL_ID:
        sendCustomInteractionToGTM(
          EventCategory.PROFILE,
          EventAction.SEND_FORM,
          EventLabel.PROFILE_PASSWORD_EDITED,
        );
        break;
      default:
        break;
    }
  };

  // TODO: Set to false if provider logged with IDM
  const canEditEmailAndPassword =
    getConfig()?.buildConfig?.id !== APP_ID.BE_VLG;

  return (
    <SwContainer>
      <SwGrid modStacked>
        <SwColumn>
          <PageHeader
            title={t(i18nKeys.profile.title)}
            introduction={t(i18nKeys.profile.introduction)}
          />
          <div style={{ marginBottom: '24px' }}>
            <TutorialVideoLink topic={VideoTopicReference.ENTERPRISE} />
          </div>
          <SwColumn width="10" widthS="12">
            <SwForm>
              <SwGrid modStacked>
                <SwColumn>
                  <SwTitle tagName="h2" tagStyle="h2">
                    {t(i18nKeys.profile.loginSection.title)}
                  </SwTitle>
                  <SwTitle tagName="h3" tagStyle="h5">
                    {t(i18nKeys.profile.loggedAs.title)}
                  </SwTitle>
                </SwColumn>
                <SwColumn>
                  <SwFormGrid modStacked>
                    <SwFormColumn>
                      <SwFormGrid>
                        <SwFormColumn width="3" widthS="12">
                          <SwFormMessageLabel
                            htmlFor={t(i18nKeys.general.label.name)}
                          >
                            {t(i18nKeys.general.label.name)}
                          </SwFormMessageLabel>
                        </SwFormColumn>
                        <SwFormColumn width="9" widthS="12">
                          <SwFormInput>
                            <SwInputField
                              id={t(i18nKeys.general.label.name)}
                              type="text"
                              data-testid={dataTest.profile.profileName}
                              value={provider.name}
                              modDisabled
                              modBlock
                            />
                          </SwFormInput>
                        </SwFormColumn>
                      </SwFormGrid>
                    </SwFormColumn>
                    <SwFormColumn>
                      <SwFormGrid>
                        <SwFormColumn width="3" widthS="12">
                          <SwFormMessageLabel
                            htmlFor={t(
                              i18nKeys.general.label.authorizationNumber,
                            )}
                          >
                            {t(i18nKeys.general.label.authorizationNumber)}
                          </SwFormMessageLabel>
                        </SwFormColumn>
                        <SwFormColumn width="9" widthS="12">
                          <SwFormInput>
                            <SwInputField
                              id={t(i18nKeys.general.label.authorizationNumber)}
                              type="text"
                              data-testid={dataTest.profile.profileAuthNumber}
                              value={provider.id}
                              modDisabled
                              modBlock
                            />
                          </SwFormInput>
                        </SwFormColumn>
                      </SwFormGrid>
                    </SwFormColumn>
                    {canEditEmailAndPassword && (
                      <>
                        <SwActionLabel
                          id="editEmailAddress"
                          label={t(i18nKeys.general.label.emailAddress)}
                          value={provider.commercialEmailAddress}
                          onClick={() => {
                            openModal(Modals.CHANGE_EMAIL_MODAL_ID);
                          }}
                        />
                        <SwActionLabel
                          id="editPassword"
                          label={t(i18nKeys.profile.currentPassword)}
                          value="***********"
                          onClick={() => {
                            openModal(Modals.CHANGE_PASSWORD_MODAL_ID);
                          }}
                        />
                      </>
                    )}
                  </SwFormGrid>
                </SwColumn>
                {status && (
                  <SwColumn>
                    <SwAlert
                      {...getAlertPropsByType(status.type)}
                      close={() => setStatus(null)}
                      closable
                      modSmall
                    >
                      {status.msg}
                    </SwAlert>
                  </SwColumn>
                )}
              </SwGrid>
            </SwForm>
          </SwColumn>
        </SwColumn>
      </SwGrid>
      {canEditEmailAndPassword && (
        <>
          <SwModal
            provider={provider}
            id={Modals.CHANGE_EMAIL_MODAL_ID}
            component={ProfileProviderChangeEmailModal}
            confirmCloseMessage={t(i18nKeys.dialog.confirmClose.message)}
            onSubmit={(
              profileStatus: ProfileStatusType,
              providerUpdate?: Provider,
            ) =>
              onSubmitModal(
                Modals.CHANGE_EMAIL_MODAL_ID,
                profileStatus,
                providerUpdate,
              )
            }
            closable
          />
          <SwModal
            provider={provider}
            id={Modals.CHANGE_PASSWORD_MODAL_ID}
            component={ProfileProviderChangePasswordModal}
            confirmCloseMessage={t(i18nKeys.dialog.confirmClose.message)}
            onSubmit={(
              profileStatus: ProfileStatusType,
              providerUpdate?: Provider,
            ) =>
              onSubmitModal(
                Modals.CHANGE_PASSWORD_MODAL_ID,
                profileStatus,
                providerUpdate,
              )
            }
            closable
          />
        </>
      )}
    </SwContainer>
  );
};
