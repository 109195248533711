/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */
import { ServiceActiveStatus } from './serviceActiveStatus';
import { ServiceInactiveStatus } from './serviceInactiveStatus';

export type ServiceStatus = ServiceActiveStatus | ServiceInactiveStatus;

export const ServiceStatus = {
  ...ServiceActiveStatus,
  ...ServiceInactiveStatus,
};
