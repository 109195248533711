/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */

export type ServiceActiveStatus =
  | 'CREATED'
  | 'MODIFIED'
  | 'VALIDATED'
  | 'CONFIRMED'
  | 'CONTESTED'
  | 'PROPOSITION_TO_CANCEL'
  | 'CORRECTED'
  | 'MISSING_VOUCHERS'
  | 'MISSING_CONTRACT'
  | 'PAID'
  | 'READY_FOR_VOUCHER_ASSIGNMENT'
  | 'PARTIALLY_ASSIGNED'
  | 'PARTIALLY_REFUNDED'
  | 'PARTIALLY_ASSIGNED_TO_BE_PAID_OUTSIDE_SV';

export const ServiceActiveStatus = {
  CREATED: 'CREATED' as ServiceActiveStatus,
  MODIFIED: 'MODIFIED' as ServiceActiveStatus,
  VALIDATED: 'VALIDATED' as ServiceActiveStatus,
  CONFIRMED: 'CONFIRMED' as ServiceActiveStatus,
  CONTESTED: 'CONTESTED' as ServiceActiveStatus,
  PROPOSITION_TO_CANCEL: 'PROPOSITION_TO_CANCEL' as ServiceActiveStatus,
  CORRECTED: 'CORRECTED' as ServiceActiveStatus,
  MISSING_VOUCHERS: 'MISSING_VOUCHERS' as ServiceActiveStatus,
  MISSING_CONTRACT: 'MISSING_CONTRACT' as ServiceActiveStatus,
  PAID: 'PAID' as ServiceActiveStatus,
  READY_FOR_VOUCHER_ASSIGNMENT: 'READY_FOR_VOUCHER_ASSIGNMENT' as ServiceActiveStatus,
  PARTIALLY_ASSIGNED: 'PARTIALLY_ASSIGNED' as ServiceActiveStatus,
  PARTIALLY_REFUNDED: 'PARTIALLY_REFUNDED' as ServiceActiveStatus,
  PARTIALLY_ASSIGNED_TO_BE_PAID_OUTSIDE_SV: 'PARTIALLY_ASSIGNED_TO_BE_PAID_OUTSIDE_SV' as ServiceActiveStatus,
};
