/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */

export type BillActionType = 'CAN_SEE_DETAILS' | 'CAN_DOWNLOAD';

export const BillActionType = {
  CAN_SEE_DETAILS: 'CAN_SEE_DETAILS' as BillActionType,
  CAN_DOWNLOAD: 'CAN_DOWNLOAD' as BillActionType,
};
