/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */

export type VideoResponseDetails =
  | 'addSubsidiary'
  | 'addWorker'
  | 'automaticRemittanceManagement'
  | 'changeProfileData'
  | 'changeRefundSettings'
  | 'csvLayout'
  | 'declarationFileImport'
  | 'extranetWebinar'
  | 'newExtranetWebinar'
  | 'importWorkerList'
  | 'pickupPlan'
  | 'remittanceEncoding'
  | 'remittanceSlipCreation'
  | 'searchModuleUsage'
  | 'searchAndExportModuleUsage'
  | 'serviceEncoding'
  | 'serviceExport'
  | 'serviceImport'
  | 'variousTips'
  | 'voucherValidityCheck'
  | 'xlsxLayout';

export const VideoResponseDetails = {
  addSubsidiary: 'addSubsidiary' as VideoResponseDetails,
  addWorker: 'addWorker' as VideoResponseDetails,
  automaticRemittanceManagement: 'automaticRemittanceManagement' as VideoResponseDetails,
  changeProfileData: 'changeProfileData' as VideoResponseDetails,
  changeRefundSettings: 'changeRefundSettings' as VideoResponseDetails,
  csvLayout: 'csvLayout' as VideoResponseDetails,
  declarationFileImport: 'declarationFileImport' as VideoResponseDetails,
  extranetWebinar: 'extranetWebinar' as VideoResponseDetails,
  newExtranetWebinar: 'newExtranetWebinar' as VideoResponseDetails,
  importWorkerList: 'importWorkerList' as VideoResponseDetails,
  pickupPlan: 'pickupPlan' as VideoResponseDetails,
  remittanceEncoding: 'remittanceEncoding' as VideoResponseDetails,
  remittanceSlipCreation: 'remittanceSlipCreation' as VideoResponseDetails,
  searchModuleUsage: 'searchModuleUsage' as VideoResponseDetails,
  searchAndExportModuleUsage: 'searchAndExportModuleUsage' as VideoResponseDetails,
  serviceEncoding: 'serviceEncoding' as VideoResponseDetails,
  serviceExport: 'serviceExport' as VideoResponseDetails,
  serviceImport: 'serviceImport' as VideoResponseDetails,
  variousTips: 'variousTips' as VideoResponseDetails,
  voucherValidityCheck: 'voucherValidityCheck' as VideoResponseDetails,
  xlsxLayout: 'xlsxLayout' as VideoResponseDetails,
};
