import { Relative, RelativeActionType, Sorting } from '@kaa/api/providers';
import { httpTo } from '@kaa/api/providers/utilities';
import { ActionType, useActionDispatch } from '@kaa/common/context';
import { ActionsHandler } from '@kaa/common/handlers';
import { useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AlertType,
  selectPage,
  SwActionGroup,
  SwButton,
  SwCheckboxField,
  SwDataTable,
  SwFetchErrorMessage,
  SwForm,
  SwFormColumn,
  SwFormSubmitMessage,
  SwGrid,
  SwLoader,
  SwModalRenderProps,
  SwPaginator,
  SwTitle,
} from '@kaa/ui-flanders/components';
import { Field, FieldProps, Formik, FormikActions } from 'formik';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dataTest } from '../../../../../datatest/keys';
import { useApi, useSelectedProviderState } from '../../../../../utils';
import {
  EventAction,
  EventCategory,
  EventLabel,
  sendCustomInteractionToGTM,
} from '../../../../../utils/google-analytics';
import {
  createValidatorWithServerErrorHandled,
  handleApiError,
} from '../../../../../utils/validation';
import { RELATIVES_PAGE_SIZE } from './RemittanceAutomaticModal.constants';
import { ElectronicVouchersAutomaticRemittanceForm } from './RemittancesAutomaticModal.types';
import { sortRelatives } from './RemittancesAutomaticModal.utils';
import { RemittancesAutomaticModalRow } from './RemittancesAutomaticModalRow';
import { RemittancesAutomaticModalTableHead } from './RemittancesAutomaticModalTableHead';

export const RemittancesAutomaticModal = ({
  setConfirmCloseModal,
}: SwModalRenderProps) => {
  const { t } = useTranslation();
  const provider = useSelectedProviderState();
  const dispatchAction = useActionDispatch();
  const { providers } = useApi();

  const [selectedPage, setSelectedPage] = useState(1);
  const [currentSort, setSort] = useState<Sorting>(Sorting.NONE);

  const [
    { value: relativesResponse, loading, error },
    getRelatives,
  ] = useAsyncCallback(
    async () => {
      const {
        data: {
          data: { headquarter, subsidiaries },
          actions,
        },
      } = await providers.getProviderRelatives(provider.id);

      if (actions) {
        dispatchAction({ type: ActionType.ADD, payload: actions });
      }
      return {
        headquarter,
        relatives: subsidiaries.filter(({ isActive }) => isActive),
      };
    },
    [providers],
    { loading: true },
  );

  const [{ value: submitedForm }, submit] = useAsyncCallback(
    async (
      formikData: ElectronicVouchersAutomaticRemittanceForm,
      formikActions: FormikActions<ElectronicVouchersAutomaticRemittanceForm>,
    ) => {
      if (!relativesResponse) {
        return;
      }

      const { relatives, allSelected, touched } = formikData;

      const data = Object.keys(touched).map((key) => ({
        id: Number(key.replace('id-', '')),
        ...relatives[key],
      }));

      const headquarterFrequency = {
        id: relativesResponse?.headquarter.id,
        frequency: relativesResponse?.headquarter.remittanceFrequency,
        weeklyFrequencyOption:
          relativesResponse?.headquarter.weeklyRemittanceFrequency,
        ...relatives[`id-${relativesResponse?.headquarter.id}`],
      };

      const items = allSelected ? [headquarterFrequency] : data;

      const [error, response] = await httpTo(
        providers.updateProviderRemittanceConfiguration(provider.id, {
          applyFirstItemToAll: allSelected,
          items,
        }),
      );

      return {
        error,
        response,
        formikData,
        formikActions,
      };
    },
    [providers, provider.id, relativesResponse],
  );

  useEffect(() => {
    if (submitedForm) {
      const { error, formikActions, formikData } = submitedForm;
      const { setStatus, resetForm } = formikActions;
      if (handleApiError(error, formikActions)) {
        return;
      }
      const { allSelected, relatives } = formikData;
      resetForm({ allSelected, relatives, touched: {} });
      setStatus({
        type: AlertType.SUCCESS,
        msg: t(i18nKeys.remittances.modal.manual.form.update.successMessage),
      });

      sendCustomInteractionToGTM(
        EventCategory.REMITTANCES,
        EventAction.FORM_CONFIRMATION,
        EventLabel.REMITTANCES_AUTOMATIC_ELECTRONIC,
      );
    }
  }, [submitedForm]);

  useEffect(() => {
    getRelatives();
  }, [getRelatives]);

  if (loading) {
    return (
      <>
        <SwTitle tagName="h3" className="vl-u-spacer--small">
          {t(i18nKeys.remittances.modal.automatic.title)}
        </SwTitle>
        <SwTitle tagName="h4" tagStyle="h5" className="vl-u-spacer--small">
          {t(i18nKeys.relatives.myHeadquarter.title)}
        </SwTitle>
        <SwDataTable modCollapsedS className="vl-u-spacer--small">
          <RemittancesAutomaticModalTableHead />
        </SwDataTable>
        <div className="vl-u-spacer--large">
          <SwLoader modMessageHidden />
        </div>
        <SwTitle tagName="h4" tagStyle="h5" className="vl-u-spacer--small">
          {t(i18nKeys.general.label.mySubsidiaries)}
        </SwTitle>
        <SwDataTable modCollapsedS className="vl-u-spacer--small">
          <RemittancesAutomaticModalTableHead />
        </SwDataTable>
        <SwLoader modMessageHidden />
      </>
    );
  }

  if (error || !relativesResponse) {
    return <SwFetchErrorMessage onClick={getRelatives} />;
  }

  const validate = createValidatorWithServerErrorHandled<
    ElectronicVouchersAutomaticRemittanceForm
  >({ allSelected: [], relatives: [], touched: [] });

  const { relatives, headquarter } = relativesResponse;
  const sortedRelatives = sortRelatives(relatives, currentSort);

  return (
    <>
      <SwTitle tagName="h3" className="vl-u-spacer--small">
        {t(i18nKeys.remittances.modal.automatic.title)}
      </SwTitle>
      <Formik<Partial<ElectronicVouchersAutomaticRemittanceForm>>
        onSubmit={submit}
        initialValues={{
          allSelected: false,
          relatives: {
            [`id-${headquarter.id}`]: {
              frequency: headquarter.remittanceFrequency,
              weeklyFrequencyOption: headquarter.weeklyRemittanceFrequency,
              onlyFullyAssignedServices: headquarter.onlyFullyAssignedServices,
            },
            ...relatives.reduce(
              (acc, relative) => ({
                ...acc,
                [`id-${relative.id}`]: {
                  frequency: relative.remittanceFrequency,
                  weeklyFrequencyOption: relative.weeklyRemittanceFrequency,
                  onlyFullyAssignedServices: relative.onlyFullyAssignedServices,
                },
              }),
              {},
            ),
          },
          touched: {},
        }}
        validate={validate}
      >
        {({ handleSubmit, dirty, isSubmitting, values }) => {
          setConfirmCloseModal(dirty);
          return (
            <SwForm onSubmit={handleSubmit}>
              <SwTitle
                tagName="h4"
                tagStyle="h5"
                className="vl-u-spacer--small"
              >
                {t(i18nKeys.relatives.myHeadquarter.title)}
              </SwTitle>
              <ActionsHandler
                resourceId={headquarter.resourceId}
                actions={[RelativeActionType.CAN_EDIT]}
              >
                {({ isAllowed }) => (
                  <>
                    <SwDataTable className="vl-u-spacer--small" modCollapsedS>
                      <RemittancesAutomaticModalTableHead />
                      <tbody>
                        <RemittancesAutomaticModalRow
                          frequency={
                            values.relatives?.[`id-${headquarter.id}`]
                              ?.frequency
                          }
                          relative={headquarter}
                          canEdit={isAllowed}
                          allSelected={values.allSelected}
                          inputOnChange={(
                            fieldName: string,
                            event: ChangeEvent<HTMLInputElement>,
                            field: FieldProps['field'],
                            form: FieldProps['form'],
                          ) => {
                            form.setFieldValue('relatives', {
                              [`id-${headquarter.id}`]: {
                                ...form.values.relatives[
                                  `id-${headquarter.id}`
                                ],
                                [fieldName]: field.name.includes(
                                  'onlyFullyAssignedServices',
                                )
                                  ? event.target.checked
                                  : event.target.value,
                              },
                              ...relatives.reduce(
                                (acc, relative) => ({
                                  ...acc,
                                  [`id-${relative.id}`]: {
                                    ...form.values.relatives[
                                      `id-${relative.id}`
                                    ],
                                    [fieldName]: field.name.includes(
                                      'onlyFullyAssignedServices',
                                    )
                                      ? event.target.checked
                                      : event.target.value,
                                  },
                                }),
                                {},
                              ),
                            });
                          }}
                        />
                      </tbody>
                    </SwDataTable>
                    {isAllowed && (
                      <SwGrid
                        modEnd
                        className="vl-u-spacer--large vl-u-align-right"
                      >
                        <SwFormColumn width="0">
                          {t(
                            i18nKeys.remittances.modal.automatic.applyFrequency,
                          )}
                        </SwFormColumn>
                        <SwFormColumn width="0" style={{ padding: '0 1rem' }}>
                          <Field
                            name="allSelected"
                            modSingle
                            column={{ width: '0' }}
                            component={SwCheckboxField}
                            inputOnChange={(
                              event: ChangeEvent<HTMLInputElement>,
                              field: FieldProps['field'],
                              form: FieldProps['form'],
                            ) => {
                              if (event.target.checked) {
                                form.setFieldValue('relatives', {
                                  ...form.values.relatives,
                                  ...relatives.reduce((acc, relative) => {
                                    return {
                                      ...acc,
                                      [`id-${relative.id}`]: {
                                        ...(form.values.relatives?.[
                                          `id-${headquarter.id}`
                                        ].frequency && {
                                          frequency:
                                            form.values.relatives?.[
                                              `id-${headquarter.id}`
                                            ].frequency,
                                        }),
                                        ...(form.values.relatives?.[
                                          `id-${headquarter.id}`
                                        ].weeklyFrequencyOption && {
                                          weeklyFrequencyOption:
                                            form.values.relatives?.[
                                              `id-${headquarter.id}`
                                            ].weeklyFrequencyOption,
                                        }),
                                        ...(form.values.relatives?.[
                                          `id-${headquarter.id}`
                                        ]?.onlyFullyAssignedServices && {
                                          onlyFullyAssignedServices:
                                            form.values.relatives?.[
                                              `id-${headquarter.id}`
                                            ].onlyFullyAssignedServices,
                                        }),
                                      },
                                    };
                                  }, {}),
                                });
                              }
                            }}
                          />
                        </SwFormColumn>
                      </SwGrid>
                    )}
                  </>
                )}
              </ActionsHandler>
              <SwTitle
                tagName="h3"
                tagStyle="h5"
                className="vl-u-spacer--small"
              >
                {t(i18nKeys.general.label.mySubsidiaries)}
              </SwTitle>
              <SwDataTable modCollapsedS>
                <RemittancesAutomaticModalTableHead
                  currentSort={currentSort}
                  changeSort={setSort}
                />
                <tbody>
                  {selectPage<Relative>(
                    sortedRelatives,
                    selectedPage,
                    RELATIVES_PAGE_SIZE,
                  ).map((relative: Relative) => {
                    return (
                      <ActionsHandler
                        resourceId={relative.resourceId}
                        actions={[RelativeActionType.CAN_EDIT]}
                        key={relative.id}
                      >
                        {({ isAllowed }) => (
                          <RemittancesAutomaticModalRow
                            relative={relative}
                            frequency={
                              values.relatives?.[`id-${relative.id}`]?.frequency
                            }
                            key={relative.id}
                            allSelected={values.allSelected}
                            canEdit={isAllowed}
                          />
                        )}
                      </ActionsHandler>
                    );
                  })}
                </tbody>
              </SwDataTable>
              <SwPaginator
                itemCount={sortedRelatives.length}
                pageSize={RELATIVES_PAGE_SIZE}
                selectedPage={selectedPage}
                setPage={setSelectedPage}
              />
              <SwActionGroup>
                <SwButton
                  className="vl-u-spacer"
                  data-testid={
                    dataTest.remittances.electronic.automatic.submitButton
                  }
                  type="submit"
                  modDisabled={!dirty}
                  modLoading={isSubmitting}
                >
                  {t(i18nKeys.general.cta.save)}
                </SwButton>
              </SwActionGroup>
              <SwFormSubmitMessage />
            </SwForm>
          );
        }}
      </Formik>
    </>
  );
};
