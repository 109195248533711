import { GetWorkersParams, Relative } from '@kaa/api/providers';
import { minLength } from '@kaa/common/validation';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  Icon,
  SwActionGroup,
  SwButton,
  SwCheckboxField,
  SwColumn,
  SwForm,
  SwFormColumn,
  SwFormGrid,
  SwFormMessageLabel,
  SwFormSubmitMessage,
  SwInputTextField,
  SwLink,
  SwMultiselect,
  SwSelectField,
} from '@kaa/ui-flanders/components';
import { Field, FieldProps, Formik, FormikActions } from 'formik';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { createValidatorWithServerErrorHandled } from '../../../../utils/validation';
import { WorkersSearchFormFields } from '../WorkersSearch.constant';

type WorkersSearchFormProps = {
  isSubsidiary: boolean;
  relatives: Relative[];
  submit: (
    params: GetWorkersParams & { missingContract?: boolean },
    formikActions: FormikActions<
      GetWorkersParams & { missingContract?: boolean }
    >,
  ) => void;
  defaultSearchProviderId: string;
  paramaters?: GetWorkersParams;
  setParameters: React.Dispatch<
    React.SetStateAction<GetWorkersParams | undefined>
  >;
};

export const WorkersSearchForm = ({
  relatives,
  isSubsidiary,
  submit,
  paramaters,
  setParameters,
  defaultSearchProviderId,
}: WorkersSearchFormProps) => {
  const { t } = useTranslation();

  const relativeOptions = relatives.map(({ id, name }) => ({
    value: id,
    text: `${name} - ${id}`,
  }));
  const activeOptions = [
    {
      value: true,
      text: t(i18nKeys.workers.label.active),
    },
    {
      value: false,
      text: t(i18nKeys.workers.label.inactive),
    },
  ];

  const missingContract =
    typeof paramaters?.isContracted === 'undefined' || paramaters?.isContracted
      ? undefined
      : true;

  const initialValues = {
    [WorkersSearchFormFields.SEARCHED_PROVIDER_ID]: defaultSearchProviderId,
    [WorkersSearchFormFields.MISSING_CONTRACT]: missingContract,
    ...paramaters,
  };

  const validate = createValidatorWithServerErrorHandled({
    [WorkersSearchFormFields.WORKER_NAME_OR_NISS]: [minLength(2)],
  });

  return (
    <SwColumn
      className="vl-u-bg-alt vl-u-spacer--medium"
      style={{ padding: '2rem' }}
    >
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={submit}
        validate={validate}
        render={({
          handleSubmit,
          dirty,
          isSubmitting,
          setFieldValue,
          resetForm,
        }) => (
          <SwForm onSubmit={handleSubmit}>
            <SwFormGrid
              modStacked
              className="vl-u-display-flex vl-u-spacer--small"
            >
              {!isSubsidiary && (
                <SwColumn width="3" widthM="6" widthS="12">
                  <Field
                    column={{ width: '12' }}
                    labelColumn={{ width: '12' }}
                    name={WorkersSearchFormFields.SEARCHED_PROVIDER_ID}
                    label={t(i18nKeys.general.exploitationName)}
                    component={SwSelectField}
                    options={relativeOptions}
                  />
                </SwColumn>
              )}
              <SwColumn width="3" widthM="6" widthS="12">
                <Field
                  column={{ width: '12' }}
                  labelColumn={{ width: '12' }}
                  name={WorkersSearchFormFields.REFERENCE}
                  component={SwInputTextField}
                  label={t(i18nKeys.general.label.internalReference)}
                />
              </SwColumn>
              <SwColumn width="3" widthM="6" widthS="12">
                <Field
                  name={WorkersSearchFormFields.WORKER_NAME_OR_NISS}
                  column={{ width: '12' }}
                  labelColumn={{ width: '12' }}
                  component={SwInputTextField}
                  label={`${t(i18nKeys.general.label.firstname)} / ${t(
                    i18nKeys.general.label.name,
                  )} / ${t(i18nKeys.general.label.niss)}`}
                />
              </SwColumn>
              <SwColumn width="3" widthM="6" widthS="12">
                <Field
                  column={{ width: '12' }}
                  labelColumn={{ width: '12' }}
                  name={WorkersSearchFormFields.IS_ACTIVE}
                  label={t(i18nKeys.workers.loginInformation.status)}
                  component={SwSelectField}
                  placeholderText={t(i18nKeys.workers.label.all)}
                  options={activeOptions}
                />
              </SwColumn>
              <SwColumn width="6" widthM="6" widthS="12">
                <SwFormColumn>
                  <SwFormGrid>
                    <SwFormColumn>
                      <SwFormMessageLabel htmlFor="postcode">
                        {t(i18nKeys.general.label.postcode)}
                      </SwFormMessageLabel>
                    </SwFormColumn>
                    <SwFormColumn>
                      <SwMultiselect
                        isCreatable
                        placeHolder={t(i18nKeys.general.optionPlaceholder)}
                        onChange={(options) => {
                          if (
                            options &&
                            Array.isArray(options) &&
                            options.length
                          ) {
                            setFieldValue(
                              'postcode',
                              options.map(({ value }) => value),
                            );
                          } else {
                            setFieldValue('postcode', []);
                          }
                        }}
                        options={relatives.map(({ postcode }) => ({
                          value: postcode,
                          label: postcode,
                        }))}
                      />
                    </SwFormColumn>
                  </SwFormGrid>
                </SwFormColumn>
              </SwColumn>
              <SwColumn
                width="3"
                widthM="6"
                widthS="12"
                style={{ alignSelf: 'center', paddingTop: '2rem' }}
              >
                <Field
                  name={WorkersSearchFormFields.MISSING_CONTRACT}
                  column={{ width: '12' }}
                  label={t(
                    i18nKeys.workers.search.form.checkbox.missingContract,
                  )}
                  component={SwCheckboxField}
                  inputOnChange={(
                    e: ChangeEvent<HTMLInputElement>,
                    field: FieldProps['field'],
                    form: FieldProps['form'],
                  ) => {
                    form.setFieldValue(
                      'isContracted',
                      e.target.checked ? false : undefined,
                    );
                  }}
                />
              </SwColumn>
              <SwColumn
                width="3"
                widthM="6"
                widthS="12"
                style={{ alignSelf: 'flex-end' }}
              >
                <SwActionGroup modCollapseS>
                  <SwButton
                    type="submit"
                    modLoading={isSubmitting}
                    modDisabled={!dirty}
                    modIconBefore
                    icon={Icon.SYNCHRONIZE}
                  >
                    {t(i18nKeys.general.search)}
                  </SwButton>
                  <SwLink
                    type="button"
                    onClick={() => {
                      setParameters(undefined);
                      resetForm();
                    }}
                  >
                    {t(i18nKeys.general.label.reset)}
                  </SwLink>
                </SwActionGroup>
              </SwColumn>
              <SwFormSubmitMessage />
            </SwFormGrid>
          </SwForm>
        )}
      />
    </SwColumn>
  );
};
