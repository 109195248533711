import { Modals } from '@kaa/core-app/providers/constants';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  SwActionGroup,
  SwButton,
  SwTitle,
  toggleModalById,
} from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const ConfirmSignoutModal = ({ onConfirm }: any) => {
  const { t } = useTranslation();

  return (
    <>
      <SwTitle tagName="h2">{t(i18nKeys.dialog.signoutConfirm.title)}</SwTitle>
      <SwActionGroup style={{ marginTop: '3rem' }}>
        <SwButton
          onClick={() => {
            onConfirm(false);
            toggleModalById(Modals.CONFIRM_SIGNOUT_MODAL);
          }}
        >
          {t(i18nKeys.dialog.signoutConfirm.switch.cta)}
        </SwButton>
        <SwButton
          onClick={() => {
            onConfirm(true);
            toggleModalById(Modals.CONFIRM_SIGNOUT_MODAL);
          }}
        >
          {t(i18nKeys.dialog.signoutConfirm.signout.cta)}
        </SwButton>
      </SwActionGroup>
    </>
  );
};
