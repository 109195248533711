import { Worker, WorkerDetail } from '@kaa/api/providers';
import { httpTo } from '@kaa/api/providers/utilities';
import { useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AlertType,
  SwActionGroup,
  SwButton,
  SwForm,
  SwFormGrid,
  SwFormSubmitMessage,
  SwModalRenderProps,
  SwTitle,
} from '@kaa/ui-flanders/components';
import { Formik, FormikActions } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { dataTest } from '../../../../datatest/keys';
import { useApi, useSelectedProviderIdState } from '../../../../utils';
import {
  createValidatorWithServerErrorHandled,
  handleApiError,
} from '../../../../utils/validation';
import { WorkerForm } from '../../components/workerForm/WorkerForm';
import { getDefaultWorkersValidation } from '../../components/workerForm/WorkerForm.utils';

type EditWorkerModalProps = {
  worker: WorkerDetail;
  onSuccess: (worker: Worker) => void;
};

export const EditWorkerModal = ({
  setConfirmCloseModal,
  worker,
  onSuccess,
}: SwModalRenderProps<EditWorkerModalProps>) => {
  const { t } = useTranslation();
  const { providers } = useApi();
  const providerId = useSelectedProviderIdState();

  const [{ value: submitedForm }, submit] = useAsyncCallback(
    async (formikData: Worker, formikActions: FormikActions<Worker>) => {
      const [error, response] = await httpTo(
        providers.updateWorker(providerId, worker.id, formikData),
      );
      return { error, response, formikData, formikActions };
    },
    [providers, providerId, worker],
  );

  useEffect(() => {
    if (submitedForm) {
      const { error, response, formikData, formikActions } = submitedForm;
      const { setSubmitting, setStatus, resetForm } = formikActions;

      setSubmitting(false);

      if (handleApiError(error, formikActions)) {
        return;
      }

      if (!response) {
        setStatus({
          msg: t(i18nKeys.contactPersons.modal.add.errorMessage),
          type: AlertType.ERROR,
        });
      }

      setConfirmCloseModal(false);
      resetForm(formikData);
      onSuccess(formikData);
    }
  }, [submitedForm]);

  const validate = createValidatorWithServerErrorHandled(
    getDefaultWorkersValidation(),
  );

  return (
    <>
      <SwTitle tagName="h2">
        {t(i18nKeys.workers.modals.editWorker.title)}
      </SwTitle>

      <Formik<Partial<Worker>>
        onSubmit={submit}
        initialValues={worker}
        validate={validate}
        render={({ handleSubmit, dirty, isSubmitting }) => {
          setConfirmCloseModal(dirty);
          return (
            <SwForm onSubmit={handleSubmit}>
              <SwFormGrid modStacked>
                <WorkerForm />
                <SwActionGroup style={{ marginTop: '3rem' }}>
                  <SwButton
                    type="submit"
                    data-testid={dataTest.workers.addWorker.addNewWorker}
                    modLoading={isSubmitting}
                    modLarge
                    modDisabled={!dirty}
                  >
                    {t(i18nKeys.workers.modals.editWorker.title)}
                  </SwButton>
                </SwActionGroup>
                <SwFormSubmitMessage />
              </SwFormGrid>
            </SwForm>
          );
        }}
      />
    </>
  );
};
