import faker from 'faker';
import { DateTime } from 'luxon';
import {
  AddressTypeReference,
  GetProviderPickupPlanningParams,
  GetSinglePickupParams,
  MonthlyPickupResponse,
  Pickup,
  PickupAddress,
  PickupConfigurationResponse,
  PickupConfigurationResponseData,
  Pickups,
  PickupStatus,
  PickupType,
  PickupWeekFrequency,
  PickupWithAddress,
  WorkingDays,
} from '../model';
import { PickupResponse } from '../model/pickupResponse';
import { getProviderAddress, getRandomArray } from './utils';

export const getPickup = ({
  startDate,
  endDate,
  providerId,
  type,
  id,
}: {
  startDate: string;
  endDate: string;
  providerId?: number;
  type?: PickupType;
  id?: number;
}): Pickup => {
  const start = DateTime.fromISO(startDate).toJSDate();
  const end = DateTime.fromISO(endDate).toJSDate();

  const status = Object.values(PickupStatus).filter(
    (e) => e !== PickupStatus.NON_WORKING_DAY,
  );

  const pickup = {
    id: id || faker.random.number(999),
    providerId: providerId || faker.random.number(999),
    type: type || faker.helpers.randomize(Object.values(PickupType)),
    comment: faker.lorem.sentence(),
    date: DateTime.fromJSDate(faker.date.between(start, end)).toISODate(),
    address: {
      ...getProviderAddress(),
      addressType: faker.helpers.randomize(Object.values(AddressTypeReference)),
    },
  };

  if (pickup.type === PickupType.STANDALONE) {
    return {
      ...pickup,
      hasNonWorkingDay: false,
      status: faker.helpers.randomize(
        status.filter(
          (e) =>
            e !== PickupStatus.CANCELED && e !== PickupStatus.NON_WORKING_DAY,
        ),
      ),
      selectedDays: [],
      selectedWeeks: [],
    };
  }

  const selectedDays = faker.random.boolean()
    ? Object.values(WorkingDays)
    : getRandomArray({ min: 1, max: 5 }).map(() =>
        faker.helpers.randomize(Object.values(WorkingDays)),
      );

  const selectedWeeks = faker.random.boolean()
    ? Object.values(PickupWeekFrequency)
    : getRandomArray({ min: 1, max: 5 }).map(() =>
        faker.helpers.randomize(Object.values(PickupWeekFrequency)),
      );

  return {
    ...pickup,
    hasNonWorkingDay: faker.random.boolean(),
    status: faker.helpers.randomize(status),
    recurrenceStartDate: DateTime.fromJSDate(
      faker.date.between(
        DateTime.fromJSDate(start)
          .plus({ month: -1 })
          .toJSDate(),
        start,
      ),
    ).toISODate(),
    recurrenceEndDate: DateTime.fromJSDate(
      faker.date.between(
        end,
        DateTime.fromJSDate(end)
          .plus({ month: 1 })
          .toJSDate(),
      ),
    ).toISODate(),
    selectedDays: selectedDays.filter((e, i) => selectedDays.indexOf(e) === i),
    selectedWeeks: selectedWeeks.filter(
      (e, i) => selectedWeeks.indexOf(e) === i,
    ),
  };
};

const getPickupAddress = (): PickupAddress => ({
  address: getProviderAddress(),
  ...(faker.random.boolean()
    ? {
        comment: faker.lorem.sentence(),
      }
    : null),
});

const getPickupConfigurationResponseData = (): PickupConfigurationResponseData => {
  return {
    disabledDates: getRandomArray({ min: 1, max: 150 }).map(() =>
      DateTime.fromISO(
        faker.date
          .between(
            DateTime.fromJSDate(new Date()).toJSDate(),
            DateTime.fromJSDate(new Date())
              .plus({ year: 1 })
              .toJSDate(),
          )
          .toISOString(),
      )
        .startOf('day')
        .toISO(),
    ),
    mainPickupAddress: getPickupAddress(),
    ...(faker.random.boolean()
      ? {
          otherPickupAddresses: getRandomArray({ min: 1, max: 5 }).map(() =>
            getPickupAddress(),
          ),
        }
      : null),
  };
};

const getMonthlyPickupResponseData = (
  params?: GetProviderPickupPlanningParams,
): Pickups[] => {
  let dateAcc = DateTime.fromJSDate(new Date()).startOf('month');

  const months = [
    dateAcc.toISODate(),
    ...getRandomArray({ min: 1, max: 12 }).map((e) => {
      dateAcc = dateAcc.plus({
        months: faker.random.number({ min: 1, max: 2 }),
      });
      return dateAcc.toISODate();
    }),
  ];

  return months.map((startDate) => {
    const endDate = DateTime.fromISO(startDate)
      .endOf('month')
      .toISODate();
    const pickups = getRandomArray({ min: 1, max: 30 }).map(() =>
      getPickup({ startDate, endDate }),
    );

    const pickupsDates: string[] = [];

    return {
      startDate,
      estimatedCost: faker.random.number({ min: 10, max: 30 }),
      pickups: pickups.filter((e) => {
        if (!pickupsDates.includes(e.date)) {
          pickupsDates.push(e.date);
          return true;
        }
        return false;
      }),
    };
  });
};

export const getPickupResponse = (
  providerId: number,
  pickupId: number,
  params?: GetSinglePickupParams,
): PickupResponse => {
  const startDate = DateTime.fromJSDate(new Date())
    .plus({ months: faker.random.number({ min: -5, max: 5 }) })
    .toISODate();
  const endDate = DateTime.fromISO(startDate)
    .plus({ months: faker.random.number({ min: 5, max: 10 }) })
    .toISODate();

  return {
    data: {
      ...getPickup({
        startDate,
        endDate,
        id: pickupId,
        providerId,
        type: params?.type,
      }),
      address: getProviderAddress(),
    } as PickupWithAddress,
  };
};

export const getPickupConfigurationResponse = (): PickupConfigurationResponse => {
  return {
    data: getPickupConfigurationResponseData(),
  };
};

export const getMonthlyPickupResponse = (
  params?: GetProviderPickupPlanningParams,
): MonthlyPickupResponse => {
  return {
    data: getMonthlyPickupResponseData(params),
  };
};
