import { ContactPerson, ContactPersonsResponse } from '@kaa/api/providers';
import { ProviderActionType } from '@kaa/api/providers/model/providerActionType';
import { getPath, Routes } from '@kaa/core-app/providers/routes';
import { ActionType, useActionDispatch } from '@kaa/common/context';
import { ActionsHandler } from '@kaa/common/handlers';
import { useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AlertType,
  getAlertPropsByType,
  SwActionGroup,
  SwAlert,
  SwButton,
  SwColumn,
  SwContainer,
  SwFetchErrorMessage,
  SwGrid,
  SwLink,
  SwModal,
  SwTitle,
  toggleModalById,
} from '@kaa/ui-flanders/components';
import { RouteComponentProps } from '@reach/router';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ContainerLoadingPageHeader } from '../../../components';
import { AlertManager } from '../../../components/alert/AlertManager';
import { Modals } from '../../../constants';
import { dataTest } from '../../../datatest/keys';
import { useApi, useSelectedProviderState } from '../../../utils';
import {
  EventAction,
  EventCategory,
  EventLabel,
  openModalWithPageView,
  sendCustomInteractionToGTM,
} from '../../../utils/google-analytics';
import { ContactPersonAddModal } from './components/ContactPersonAddModal';
import { ContactPersonTeaser } from './components/ContactPersonTeaser';

type ContactPersonsScreenProps = {
  enterpriseId: string;
};

export const ContactPersonsScreen = ({
  enterpriseId,
}: RouteComponentProps<ContactPersonsScreenProps>) => {
  const { t } = useTranslation();
  const dispatchAction = useActionDispatch();
  const { providers } = useApi();
  const currentProvider = useSelectedProviderState();

  const [newContactPersonStatus, setNewContactPersonStatus] = useState<{
    type: AlertType;
    msgWithLink: any;
  } | null>(null);

  const [contactPersons, setContactPersons] = useState<
    ContactPersonsResponse['data'] | null
  >(null);

  const [{ value, loading, error }, getContactData] = useAsyncCallback(
    async () => {
      if (!enterpriseId) {
        return;
      }
      const [
        {
          data: { data: provider },
        },
        {
          data: { data: contacts, actions },
        },
      ] = await Promise.all([
        providers.getProvider(Number(enterpriseId)),
        providers.getContactPersons(Number(enterpriseId)),
      ]);
      if (actions) {
        dispatchAction({ type: ActionType.ADD, payload: actions });
      }
      return { provider, contacts };
    },
    [enterpriseId, dispatchAction, providers],
    { loading: true },
  );

  useEffect(() => {
    if (!value) {
      return;
    }
    const { contacts } = value;
    setContactPersons(contacts);
  }, [value]);

  useEffect(() => {
    getContactData();
  }, [getContactData]);

  const addNewContactPerson = (
    contactPerson: ContactPerson,
    actions?: { [key: string]: { [key: string]: boolean } },
  ) => {
    toggleModalById(Modals.CONTACT_PERSON_MODAL_ID);

    setContactPersons(
      (contactPersons) =>
        contactPersons && {
          mainContact: contactPersons.mainContact,
          otherContacts: [
            ...(contactPersons.otherContacts || []),
            contactPerson,
          ],
        },
    );

    if (actions) {
      dispatchAction({ type: ActionType.ADD, payload: actions });
    }

    setNewContactPersonStatus({
      type: AlertType.SUCCESS,
      msgWithLink: (
        <>
          {t(i18nKeys.contactPersons.modal.add.successMessage)}{' '}
          <SwLink to={getPath(Routes.PICKUP_BILLING)}>
            {t(i18nKeys.contactPersons.modal.add.successLink)}
          </SwLink>
        </>
      ),
    });

    sendCustomInteractionToGTM(
      EventCategory.COMPANY_MANAGEMENT,
      EventAction.ADD,
      EventLabel.CONTACT_PERSON,
    );
  };

  if (!value && loading) {
    return (
      <ContainerLoadingPageHeader title={t(i18nKeys.contactPersons.title)} />
    );
  }

  if (error || !contactPersons || !value || !enterpriseId) {
    return (
      <SwContainer error>
        <SwFetchErrorMessage onClick={getContactData} />
      </SwContainer>
    );
  }

  const { mainContact, otherContacts = [] } = contactPersons;
  const { provider } = value;

  return (
    <SwContainer>
      <SwGrid>
        <SwColumn width="10" widthS="12">
          <SwTitle tagName="h1" className="vl-u-spacer--medium">
            {t(i18nKeys.contactPersons.title)}
          </SwTitle>

          <ActionsHandler
            resourceId={provider.resourceId}
            actions={[ProviderActionType.CAN_ADD_CONTACT_PERSON]}
          >
            {({ isAllowed }) =>
              !isAllowed ? (
                <SwAlert {...getAlertPropsByType(AlertType.INFORMATION)}>
                  {t(i18nKeys.contactPersons.alert.description)}
                </SwAlert>
              ) : null
            }
          </ActionsHandler>

          <AlertManager />

          <SwTitle tagName="h2">
            {t(i18nKeys.contactPersons.mainContactPerson.title)}
          </SwTitle>

          <ContactPersonTeaser
            enterpriseId={enterpriseId}
            spacer="vl-u-spacer--medium"
            contactPerson={mainContact}
          />

          <SwTitle tagName="h2">
            {t(i18nKeys.contactPersons.otherContactPerson.title)}
          </SwTitle>
          <div className="vl-u-flex-direction-column vl-u-spacer--small">
            {otherContacts.map((contactPerson) => (
              <React.Fragment key={contactPerson.id}>
                <ContactPersonTeaser
                  enterpriseId={enterpriseId}
                  contactPerson={contactPerson}
                  spacer="vl-u-spacer--small"
                />
                <SwGrid className="vl-u-flex-v-flex-start">
                  <SwColumn width="10" widthS="12">
                    <hr className="vl-u-hr vl-u-spacer--small" />
                  </SwColumn>
                </SwGrid>
              </React.Fragment>
            ))}
          </div>

          <ActionsHandler
            resourceId={currentProvider.resourceId}
            actions={[ProviderActionType.CAN_ADD_CONTACT_PERSON]}
          >
            {({ isAllowed }) => (
              <SwActionGroup modCollapseS className="vl-u-spacer">
                <SwButton
                  type="button"
                  modLarge
                  data-testid={dataTest.contactPersons.modalNewContactPerson}
                  onClick={() => {
                    openModalWithPageView(Modals.CONTACT_PERSON_MODAL_ID);
                    setNewContactPersonStatus(null);
                  }}
                  modDisabled={!isAllowed}
                >
                  {t(i18nKeys.contactPersons.cta.add)}
                </SwButton>
              </SwActionGroup>
            )}
          </ActionsHandler>
        </SwColumn>
      </SwGrid>

      {newContactPersonStatus && (
        <SwGrid>
          <SwColumn width="10" widthS="12">
            <SwAlert
              {...getAlertPropsByType(newContactPersonStatus.type)}
              close={() => setNewContactPersonStatus(null)}
              closable
              modSmall
            >
              {newContactPersonStatus.msgWithLink}
            </SwAlert>
          </SwColumn>
        </SwGrid>
      )}

      <SwModal
        id={Modals.CONTACT_PERSON_MODAL_ID}
        closable
        component={ContactPersonAddModal}
        confirmCloseMessage={t(i18nKeys.dialog.confirmClose.message)}
        providerId={provider.id}
        onSubmit={addNewContactPerson}
      />
    </SwContainer>
  );
};
