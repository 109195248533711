import { SwLink } from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Templates } from '../../constants';
import {
  EventAction,
  sendDownloadEventToGTM,
} from '../../utils/google-analytics';
import { getTemplates } from '../../utils/templates';

type DownloadPublicTemplatesProps = {
  templates: Templates;
};

export const DownloadPublicTemplates = ({
  templates,
}: DownloadPublicTemplatesProps) => {
  const { t } = useTranslation();
  const templatesTranslated = getTemplates(templates).map((e) => ({
    ...e,
    value: t(e.value),
  }));
  return (
    <p>
      {templatesTranslated.map((template, i) => (
        <React.Fragment key={template.format}>
          {i > 0 && <> | </>}
          <SwLink
            to={template.value}
            target="_blank"
            onClick={() =>
              sendDownloadEventToGTM(
                EventAction.OPEN,
                `${template.value.split('/').pop()} | ${template.value}`,
              )
            }
          >
            .{template.format.toLowerCase()}
          </SwLink>
        </React.Fragment>
      ))}
    </p>
  );
};
