import {
  FileExtensionReference,
  FileStatus,
  RegionCode,
} from '@kaa/api/providers';
import { httpTo } from '@kaa/api/providers/utilities';
import { useAsyncCallback, useLuxon } from '@kaa/common/utils';
import { required } from '@kaa/common/validation';
import { DownloadPublicTemplates } from '@kaa/core-app/providers/components';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  Icon,
  SwActionGroup,
  SwButton,
  SwForm,
  SwFormSubmitMessage,
  SwIconList,
  SwIconListItem,
  SwLink,
  SwLoader,
  SwModalRenderProps,
  SwTitle,
  SwUploadField,
} from '@kaa/ui-flanders/components';
import { Field, Formik, FormikActions } from 'formik';
import get from 'lodash.get';
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { getConfig } from '../../../../../common/config';
import { Templates } from '../../../../constants';
import { useApi, useSelectedProviderIdState } from '../../../../utils';
import {
  EventAction,
  sendDownloadEventToGTM,
} from '../../../../utils/google-analytics';
import { getAllowedFileTypes, getTemplate } from '../../../../utils/templates';
import {
  createValidatorWithServerErrorHandled,
  handleApiError,
} from '../../../../utils/validation';
import { PrestationImportModalConfirmation } from './PrestationImportModalConfirmation';
import { PrestationImportModalProcessing } from './PrestationImportModalProcessing';

const FILE_STATUS_SET_TIMEOUT = 5000;

const PRESATIONS_FILE_MAX_FILESIZE = 2000000; // 2mb

type PresationsListForm = {
  prestationsFile: File[];
};

type PrestationImportModalProps = SwModalRenderProps & {
  onSuccess: () => void;
};

export const PrestationImportModal = ({
  onSuccess,
  setConfirmCloseModal,
}: PrestationImportModalProps) => {
  const { t } = useTranslation();
  const { DateTime } = useLuxon();
  const { providers: providersApi } = useApi();
  const providerId = useSelectedProviderIdState();
  const regionCode = get(getConfig(), 'buildConfig.regionCode') as RegionCode;

  const [{ value: fileParserData }, postServicesFileParser] = useAsyncCallback(
    async (
      formikData: PresationsListForm,
      formikActions: FormikActions<PresationsListForm>,
    ) => {
      const { prestationsFile } = formikData;
      const [error, response] = await httpTo(
        providersApi.parseServicesFile(providerId, prestationsFile[0]),
      );

      return {
        response,
        formikData,
        formikActions,
        error,
      };
    },
    [providersApi],
  );

  const [
    { value: fileStatusData, loading },
    getServicesFileStatus,
  ] = useAsyncCallback(
    async (init = false) => {
      const [error, response] = await httpTo(
        providersApi.getServicesFileStatus(providerId),
      );

      return {
        response: response?.data.data,
        error,
        init,
      };
    },
    [providersApi],
  );

  useEffect(() => {
    getServicesFileStatus(true);
  }, [getServicesFileStatus]);

  useEffect(() => {
    if (fileStatusData) {
      const { response, init } = fileStatusData;

      if (!response) {
        return;
      }

      setConfirmCloseModal(false);

      if (
        response.status === FileStatus.InProgress ||
        response.status === FileStatus.Parsed
      ) {
        setTimeout(getServicesFileStatus, FILE_STATUS_SET_TIMEOUT);
        return;
      }

      if (!init) {
        onSuccess();
      }
    }
  }, [fileStatusData]);

  useEffect(() => {
    if (fileParserData) {
      const { formikActions, error } = fileParserData;

      if (handleApiError(error, formikActions)) {
        return;
      }

      getServicesFileStatus();
    }
  }, [fileParserData]);

  if (fileStatusData && fileStatusData.response) {
    const { response, init } = fileStatusData;

    if (
      response.status === FileStatus.InProgress ||
      response.status === FileStatus.Parsed
    ) {
      return (
        <PrestationImportModalProcessing fileStatus={response}>
          <SwTitle tagName="h2" className="vl-u-spacer--small">
            {t(i18nKeys.prestations.modals.import.title)}
          </SwTitle>
          <p className="vl-u-spacer--medium">
            {t(i18nKeys.prestations.modals.import.processingImport.content)}
          </p>
        </PrestationImportModalProcessing>
      );
    }

    if (!init) {
      return (
        <PrestationImportModalConfirmation
          providerId={providerId}
          fileStatus={response}
        >
          <SwTitle tagName="h2" className="vl-u-spacer--medium">
            {t(i18nKeys.prestations.modals.import.title)}
          </SwTitle>
        </PrestationImportModalConfirmation>
      );
    }
  }

  if (loading || !fileStatusData) {
    return (
      <>
        <SwTitle tagName="h2" className="vl-u-spacer--medium">
          {t(i18nKeys.prestations.modals.import.title)}
        </SwTitle>
        <SwLoader />
      </>
    );
  }

  const initialValues = { prestationsFile: [] };

  const validate = createValidatorWithServerErrorHandled({
    prestationsFile: [required],
  });

  const templatePDF = t(
    getTemplate(Templates.IMPORT_WORKS, FileExtensionReference.PDF).value,
  );

  return (
    <>
      <SwTitle tagName="h2" className="vl-u-spacer--medium">
        {t(i18nKeys.prestations.modals.import.title)}
      </SwTitle>
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={postServicesFileParser}
      >
        {({ handleSubmit, dirty, isSubmitting }) => {
          setConfirmCloseModal(dirty);
          return (
            <>
              <SwForm onSubmit={handleSubmit} className="vl-u-spacer">
                <Field
                  name="prestationsFile"
                  label={t(
                    i18nKeys.prestations.modals.import.form.labels.newUpload,
                  )}
                  maxFilesize={PRESATIONS_FILE_MAX_FILESIZE}
                  allowedFileTypes={getAllowedFileTypes([
                    FileExtensionReference.XLS,
                    FileExtensionReference.XLSX,
                    FileExtensionReference.CSV,
                  ])}
                  column={{ width: '12' }}
                  labelColumn={{ width: '12' }}
                  component={SwUploadField}
                />
                {dirty && (
                  <>
                    <SwFormSubmitMessage />
                    <SwActionGroup className="vl-u-spacer">
                      <SwButton type="submit" modLoading={isSubmitting}>
                        {t(i18nKeys.prestations.modals.import.form.cta.title)}
                      </SwButton>
                    </SwActionGroup>
                  </>
                )}
              </SwForm>
            </>
          );
        }}
      </Formik>
      <SwTitle tagName="h3" className="vl-u-spacer--small">
        {t(i18nKeys.prestations.modals.import.help.title)}
      </SwTitle>
      <SwIconList className="vl-u-spacer--medium">
        <SwIconListItem
          className="vl-u-hr"
          style={{ paddingBottom: '1rem' }}
          icon={
            regionCode === RegionCode.BE_VLG
              ? Icon.DATA_DOWNLOAD
              : Icon.FILE_DOWNLOAD
          }
          modLarge
        >
          <Trans
            i18nKey={i18nKeys.prestations.modals.import.help.step1.title}
          />
          <DownloadPublicTemplates templates={Templates.IMPORT_WORKS} />
        </SwIconListItem>
        <SwIconListItem
          className="vl-u-hr"
          style={{ paddingBottom: '1rem' }}
          icon={
            regionCode === RegionCode.BE_VLG
              ? Icon.DATA_DOWNLOAD
              : Icon.FILE_DOWNLOAD
          }
          modLarge
        >
          <Trans i18nKey={i18nKeys.prestations.modals.import.help.step2.title}>
            <strong>2.</strong>
            <SwLink
              to={templatePDF}
              target="_blank"
              onClick={() =>
                sendDownloadEventToGTM(
                  EventAction.OPEN,
                  `${templatePDF.split('/').pop()} | ${templatePDF}`,
                )
              }
            >
              .{FileExtensionReference.PDF.toLowerCase()}
            </SwLink>
          </Trans>
        </SwIconListItem>
        <SwIconListItem
          style={{ paddingBottom: '1rem' }}
          icon={
            regionCode === RegionCode.BE_VLG
              ? Icon.DATA_UPLOAD
              : Icon.FILE_UPLOAD
          }
          modLarge
        >
          <Trans
            i18nKey={i18nKeys.prestations.modals.import.help.step3.title}
          />
        </SwIconListItem>
      </SwIconList>
      {fileStatusData.response && (
        <PrestationImportModalConfirmation
          providerId={providerId}
          fileStatus={fileStatusData.response}
        >
          <div
            className="vl-u-display-flex vl-u-spacer--small"
            style={{ alignItems: 'center' }}
          >
            <SwTitle tagName="h3" style={{ margin: '0 1rem 0 0' }}>
              {t(i18nKeys.prestations.modals.import.lastImport.title)}
            </SwTitle>
            {fileStatusData.response?.lastSuccessTimestamp && (
              <p>
                (
                {DateTime.fromISO(
                  fileStatusData.response.lastSuccessTimestamp,
                ).toFormat('dd/MM/yyyy - HH:mm:ss')}
                )
              </p>
            )}
          </div>
        </PrestationImportModalConfirmation>
      )}
    </>
  );
};
