import faker from 'faker';
import { LegalForm, LegalFormCode, LegalFormsResponse } from '../model';
import { getArray } from './utils';

export const getLegalFormsResponse = ({
  data,
}: Partial<LegalFormsResponse> = {}): LegalFormsResponse => {
  return { data: getLegalForms(data) };
};

export const getLegalForms = (
  legalForms: Array<Partial<LegalForm>> = getArray(3),
): LegalForm[] => {
  return legalForms
    .map(getLegalForm)
    .reduce<LegalForm[]>(
      (acc, title) =>
        acc.some(({ code }) => code === title.code) ? acc : [...acc, title],
      [],
    );
};

export const getLegalForm = (legalForm: Partial<LegalForm> = {}): LegalForm => {
  const code = faker.helpers.randomize(Object.values(LegalFormCode));
  return {
    code,
    name: code.toString(),
    ...legalForm,
  };
};
