import {
  PickupConfigurationResponseData,
  PickupType,
  RecurrentPickupCreate,
  Relative,
  StandalonePickupCreate,
} from '@kaa/api/providers';
import { httpTo } from '@kaa/api/providers/utilities';
import { useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AlertType,
  SwActionGroup,
  SwButton,
  SwColumn,
  SwFetchErrorMessage,
  SwForm,
  SwFormGrid,
  SwFormSubmitMessage,
  SwLink,
  SwLoader,
  SwModalRenderProps,
  SwTitle,
  toggleModalById,
} from '@kaa/ui-flanders/components';
import { FieldProps, Formik, FormikActions } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modals } from '../../../../constants';
import { useApi, useSelectedProviderState } from '../../../../utils';
import {
  EventAction,
  EventCategory,
  EventLabel,
  sendCustomInteractionToGTM,
} from '../../../../utils/google-analytics';
import {
  createValidatorWithServerErrorHandled,
  handleApiError,
} from '../../../../utils/validation';
import { PickupModalsComment } from '../components/PickupModalsComment';
import { PickupModalsRelatives } from '../components/PickupModalsRelatives';
import { PickupModalsType } from '../components/PickupModalsType';
import { PickupRecurrentModal } from '../components/PickupRecurrentModal';
import { PickupStandaloneModal } from '../components/PickupStandaloneModal';
import {
  onSubmitStatus,
  PickupCreateFormData,
  PickupFormFieldNames,
  PickupUpdateFormData,
} from '../PickupModals.constants';
import {
  getAddressValue,
  getNotIfValidations,
  getPickupCreateInitialValues,
  pickupValidation,
  recurrentPickupValidation,
  standalonePickupValidation,
} from '../PickupModals.utils';

type PickupCreateModalProps = {
  relatives: Relative[];
  onSubmit: (status: onSubmitStatus) => void;
};

export const PickupCreateModal = ({
  onSubmit,
  relatives,
  setConfirmCloseModal,
}: SwModalRenderProps<PickupCreateModalProps>) => {
  const { t } = useTranslation();
  const { providers } = useApi();

  const provider = useSelectedProviderState();

  const [configuration, setConfiguration] = useState<
    PickupConfigurationResponseData
  >();

  const [lastComment, setLastcomment] = useState<string>();

  const [
    {
      value: configurationResponse,
      loading: configurationLoading,
      error: configurationError,
    },
    getConfigurationData,
  ] = useAsyncCallback(
    async (
      relativeId: number,
      form: FieldProps<PickupCreateFormData>['form'],
    ) => {
      const configurationData = (
        await providers.getProviderPickupConfiguration(relativeId)
      ).data.data;

      return {
        configuration: configurationData,
        relativeId,
        form,
      };
    },
    [providers],
    { loading: true },
  );

  const [
    { value: submitedStandalonePickup },
    submitStandalonePickup,
  ] = useAsyncCallback(
    async (
      pickupFormData: Omit<PickupCreateFormData, PickupFormFieldNames.TYPE>,
      formikActions: FormikActions<
        Omit<PickupCreateFormData, PickupFormFieldNames.TYPE>
      >,
    ) => {
      const [error, response] = await httpTo(
        providers.createStandalonePickup(provider.id, {
          ...pickupFormData,
          [PickupFormFieldNames.ADDRESS]: JSON.parse(
            pickupFormData[PickupFormFieldNames.ADDRESS],
          ),
        } as StandalonePickupCreate),
      );

      return {
        error,
        response,
        formikActions,
      };
    },
    [providers, provider.id],
  );

  const [
    { value: submitedRecurrentPickup },
    submitRecurrentPickup,
  ] = useAsyncCallback(
    async (
      pickupFormData: Omit<PickupCreateFormData, PickupFormFieldNames.TYPE>,
      formikActions: FormikActions<
        Omit<PickupCreateFormData, PickupFormFieldNames.TYPE>
      >,
    ) => {
      const [error, response] = await httpTo(
        providers.createRecurrentPickup(provider.id, {
          ...pickupFormData,
          [PickupFormFieldNames.ADDRESS]: JSON.parse(
            pickupFormData[PickupFormFieldNames.ADDRESS],
          ),
        } as RecurrentPickupCreate),
      );

      return {
        error,
        response,
        formikActions,
      };
    },
    [providers, provider.id],
  );

  useEffect(() => {
    getConfigurationData(provider.id);
  }, [getConfigurationData]);

  useEffect(() => {
    if (configurationResponse) {
      const {
        configuration: configurationData,
        relativeId,
        form,
      } = configurationResponse;
      setConfiguration(configurationData);

      const {
        mainPickupAddress: { address, comment },
      } = configurationData;

      setLastcomment(comment);

      if (relativeId && form) {
        form.setValues({
          ...form.values,
          [PickupFormFieldNames.RELATIVE_ID as keyof PickupCreateFormData]: relativeId,
          [PickupFormFieldNames.ADDRESS as keyof PickupCreateFormData]: getAddressValue(
            address,
          ),
        });
      }
    }
  }, [configurationResponse]);

  useEffect(() => {
    if (submitedStandalonePickup) {
      const { error, response, formikActions } = submitedStandalonePickup;
      const { setStatus, resetForm } = formikActions;

      if (handleApiError(error, formikActions)) {
        return;
      }

      resetForm();

      if (!response) {
        setStatus({
          type: AlertType.ERROR,
          msg: t(i18nKeys.errors.server.DEFAULT),
        });
        return;
      }

      onSubmit({
        type: AlertType.SUCCESS,
        title: t(i18nKeys.pickup.alert.confirm.new),
      });

      toggleModalById(Modals.PICKUP_CREATE_PICKUP_MODAL);

      sendCustomInteractionToGTM(
        EventCategory.PICKUP,
        EventAction.FORM_CONFIRMATION,
        EventLabel.PICKUP_STAND_ALONE,
      );
    }
  }, [submitedStandalonePickup]);

  useEffect(() => {
    if (submitedRecurrentPickup) {
      const { error, response, formikActions } = submitedRecurrentPickup;
      const { setStatus, resetForm } = formikActions;

      if (handleApiError(error, formikActions)) {
        return;
      }

      resetForm();

      if (!response) {
        setStatus({
          type: AlertType.ERROR,
          msg: t(i18nKeys.errors.server.DEFAULT),
        });
        return;
      }

      onSubmit({
        type: AlertType.SUCCESS,
        title: t(i18nKeys.pickup.alert.confirm.new),
      });

      toggleModalById(Modals.PICKUP_CREATE_PICKUP_MODAL);
      sendCustomInteractionToGTM(
        EventCategory.PICKUP,
        EventAction.FORM_CONFIRMATION,
        EventLabel.PICKUP_RECURRENT,
      );
    }
  }, [submitedRecurrentPickup]);

  const onRelativeChange = (
    relativeId: string,
    form: FieldProps<PickupCreateFormData | PickupUpdateFormData>['form'],
  ) => {
    getConfigurationData(relativeId, form);
  };

  const onAddressSelect = (
    comment: string | undefined,
    form: FieldProps<PickupCreateFormData | PickupUpdateFormData>['form'],
  ) => {
    setLastcomment(comment);
  };

  const submitPickup = (
    {
      [PickupFormFieldNames.TYPE]: type,
      ...pickupFormData
    }: PickupCreateFormData,
    formikActions: FormikActions<PickupCreateFormData>,
  ) => {
    if (type === PickupType.RECURRENT) {
      submitRecurrentPickup(pickupFormData, formikActions);
    } else {
      submitStandalonePickup(pickupFormData, formikActions);
    }
  };

  if (configurationLoading && !configuration) {
    return (
      <>
        <SwTitle tagName="h2" className="vl-u-spacer">
          {t(i18nKeys.pickup.modal.add.form.title)}
        </SwTitle>
        <SwColumn width="12">
          <SwLoader />
        </SwColumn>
      </>
    );
  }

  if (configurationError || !configuration) {
    return (
      <>
        <SwTitle tagName="h2" className="vl-u-spacer">
          {t(i18nKeys.pickup.modal.add.form.title)}
        </SwTitle>
        <SwColumn width="12">
          <SwFetchErrorMessage
            onClick={() => {
              getConfigurationData(provider.id);
            }}
          />
        </SwColumn>
      </>
    );
  }

  return (
    <>
      <SwTitle tagName="h2" className="vl-u-spacer">
        {t(i18nKeys.pickup.modal.add.form.title)}
      </SwTitle>
      <Formik<PickupCreateFormData>
        onSubmit={submitPickup}
        initialValues={getPickupCreateInitialValues(provider.id, configuration)}
        validate={createValidatorWithServerErrorHandled({
          ...pickupValidation,
          ...getNotIfValidations(
            PickupFormFieldNames.START_DATE,
            standalonePickupValidation,
          ),
          ...getNotIfValidations(
            PickupFormFieldNames.DATE,
            recurrentPickupValidation,
          ),
        })}
        render={({
          handleSubmit,
          dirty,
          isSubmitting,
          values,
          setFieldValue,
        }) => {
          setConfirmCloseModal(dirty);

          return (
            <SwForm onSubmit={handleSubmit}>
              <SwFormGrid>
                <PickupModalsRelatives
                  relatives={relatives}
                  loading={configurationLoading}
                  configuration={configuration}
                  onRelativeChange={onRelativeChange}
                  onAddressSelect={onAddressSelect}
                />
                <PickupModalsComment
                  lastComment={lastComment}
                  values={values}
                  setFieldValue={setFieldValue}
                />
                <PickupModalsType />
                {values[PickupFormFieldNames.TYPE] === PickupType.RECURRENT ? (
                  <PickupRecurrentModal
                    configuration={configuration}
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                ) : (
                  <PickupStandaloneModal configuration={configuration} />
                )}
                <SwActionGroup className="vl-u-spacer">
                  <SwButton
                    type="submit"
                    modLoading={isSubmitting}
                    modLarge
                    modDisabled={!dirty}
                    style={{ margin: 0 }}
                  >
                    {t(i18nKeys.pickup.modal.add.form.cta)}
                  </SwButton>
                  <SwLink
                    style={{ marginLeft: '2rem' }}
                    onClick={() => {
                      toggleModalById(Modals.PICKUP_CREATE_PICKUP_MODAL);
                    }}
                  >
                    {t(i18nKeys.general.cta.cancel)}
                  </SwLink>
                </SwActionGroup>
                <SwFormSubmitMessage />
              </SwFormGrid>
            </SwForm>
          );
        }}
      />
    </>
  );
};
