import { Activity, ProviderType } from '@kaa/api/providers';
import { httpTo } from '@kaa/api/providers/utilities';
import { useAsyncCallback, useLuxon } from '@kaa/common/utils';
import { existsInError, required } from '@kaa/common/validation';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AlertType,
  AutocompleteInputField,
  SwActionGroup,
  SwButton,
  SwFetchErrorMessage,
  SwForm,
  SwFormColumn,
  SwFormGrid,
  SwFormMessageLabel,
  SwFormSubmitMessage,
  SwInputCalendarField,
  SwInputField,
  SwInputTextField,
  SwLoader,
  SwModalRenderProps,
  SwSelectField,
  SwTitle,
} from '@kaa/ui-flanders/components';
import { Field, Formik, FormikActions } from 'formik';
import isEmpty from 'lodash.isempty';
import React, { ChangeEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { dataTest } from '../../../../../datatest/keys';
import { useApi, useSelectedProviderState } from '../../../../../utils';
import {
  EventAction,
  EventCategory,
  EventLabel,
  sendCustomInteractionToGTM,
} from '../../../../../utils/google-analytics';
import {
  createValidatorWithServerErrorHandled,
  handleApiError,
  resetFormWithErrors,
} from '../../../../../utils/validation';
import { isValidVoucherNumber } from '../../RemittancesPaper.utils';
import { VoucherDeclarationFieldNames } from './RemittancesVoucherDeclaration.constants';
import { PaperVouchersDeclarationForm } from './RemittancesVoucherDeclaration.types';
import {
  findRegionalAgreementIdByProviderId,
  getAutocompleteWorkersOption,
  getErrorsFromPaperVoucherOneByOne,
  menuWorkerOptionFormat,
  onSelectWorkerOption,
} from './RemittancesVoucherDeclaration.utils';

type RemittancesVoucherDeclarationOneByOneModalProps = SwModalRenderProps & {
  onSuccess: () => void;
};

export const RemittancesVoucherDeclarationOneByOneModal = ({
  onSuccess,
  setConfirmCloseModal,
}: RemittancesVoucherDeclarationOneByOneModalProps) => {
  const { t } = useTranslation();
  const { DateTime } = useLuxon();
  const { providers } = useApi();
  const provider = useSelectedProviderState();

  const isHeadquarter = provider.type === ProviderType.HEADQUARTER;

  const [, getWorkers] = useAsyncCallback(
    async (relativeId, query: string) => {
      const {
        data: { data },
      } = await providers.getWorkers(relativeId, {
        workerNameOrNiss: query,
      });
      return data;
    },
    [providers],
  );

  const [{ value: relatives, loading, error }, getRelatives] = useAsyncCallback(
    async () => {
      const {
        data: {
          data: { headquarter, subsidiaries },
        },
      } = await providers.getProviderRelatives(provider.id);

      return [headquarter, ...subsidiaries.filter(({ isActive }) => isActive)];
    },
    [providers],
    { loading: true },
  );

  const [{ value: submitedForm }, submit] = useAsyncCallback(
    async (
      formikData: PaperVouchersDeclarationForm,
      formikActions: FormikActions<PaperVouchersDeclarationForm>,
    ) => {
      const {
        voucherReference,
        workDate,
        activityType,
        workerNiss,
        regionalAgreementId,
      } = formikData;

      const [error, response] = await httpTo(
        providers.createVoucherAnnouncement(Number(provider.id), {
          regionalAgreementId,
          announcements: [
            {
              voucherReference,
              workerNiss,
              workDate,
              activityType,
              recordNumber: 0,
            },
          ],
        }),
      );

      return {
        error,
        response,
        formikData,
        formikActions,
      };
    },
  );

  useEffect(() => {
    getRelatives();
  }, [getRelatives]);

  useEffect(() => {
    if (submitedForm) {
      const { response, formikActions, error, formikData } = submitedForm;
      const { setSubmitting, setStatus, resetForm } = formikActions;
      if (handleApiError(error, formikActions)) {
        return;
      }

      if (!response) {
        setSubmitting(false);
        setStatus({
          msg: t(
            i18nKeys.remittances.modal.declareVoucher.form.statusMessage.error,
          ),
          type: AlertType.ERROR,
        });
        return;
      }

      const [paperVoucher] = response.data.data;
      if (paperVoucher && !isEmpty(paperVoucher.validations)) {
        const errors = getErrorsFromPaperVoucherOneByOne(paperVoucher);
        resetFormWithErrors<Partial<PaperVouchersDeclarationForm>>(
          formikActions,
          formikData,
          errors,
        );
        return;
      }

      resetForm();

      setSubmitting(false);

      setStatus({
        msg: t(
          i18nKeys.remittances.modal.declareVoucher.form.statusMessage.success,
        ),
        type: AlertType.SUCCESS,
      });

      onSuccess();

      sendCustomInteractionToGTM(
        EventCategory.REMITTANCES,
        EventAction.FORM_CONFIRMATION,
        EventLabel.REMITTANCES_DECLARATION_ONE_BY_ONE,
      );
    }
  }, [submitedForm]);

  if (loading) {
    return <SwLoader />;
  }

  if (error || !relatives) {
    return <SwFetchErrorMessage onClick={getRelatives} />;
  }

  const activityOptions = Object.values(Activity).map((aggrement) => {
    return {
      value: aggrement,
      text: t(i18nKeys.general.services[aggrement]),
    };
  });

  const relativeOptions = relatives.map(({ id, name }) => ({
    value: id,
    text: name,
  }));

  const initialValues = {
    id: provider.id,
    regionalAgreementId: findRegionalAgreementIdByProviderId(
      relatives,
      provider.id,
    ),
    voucherReference: '',
    workDate: DateTime.local().toISODate(),
    workerName: '',
    workerNiss: '',
    activityType: activityOptions[0].value,
  };

  const validate = createValidatorWithServerErrorHandled<
    PaperVouchersDeclarationForm
  >({
    id: [required],
    regionalAgreementId: [required],
    voucherReference: [required, isValidVoucherNumber],
    workerNiss: [required],
    workerName: [
      required,
      existsInError(
        `${VoucherDeclarationFieldNames.WORKER_NAME}.autocomplete.invalid`,
      ),
    ],
    workDate: [required],
    activityType: [required],
  });

  return (
    <>
      <SwTitle tagName="h2" className="vl-u-spacer--medium">
        {t(i18nKeys.remittances.modal.declareVoucher.title)}
      </SwTitle>
      <Formik<Partial<PaperVouchersDeclarationForm>>
        onSubmit={submit}
        initialValues={initialValues}
        validate={validate}
      >
        {({ handleSubmit, dirty, isSubmitting, setFieldValue, values }) => {
          setConfirmCloseModal(dirty);
          return (
            <SwForm onSubmit={handleSubmit}>
              <SwFormGrid modStacked className="vl-u-spacer">
                {isHeadquarter ? (
                  <Field
                    name={VoucherDeclarationFieldNames.ID}
                    label={t(
                      i18nKeys.remittances.modal.declareVoucher.form.labels
                        .exploitationName,
                    )}
                    column={{ width: '12' }}
                    labelColumn={{ width: '12' }}
                    options={relativeOptions}
                    component={SwSelectField}
                    inputOnChange={(e: ChangeEvent<HTMLInputElement>) => {
                      const regionalAgreementId = findRegionalAgreementIdByProviderId(
                        relatives,
                        e.target.value,
                      );

                      setFieldValue(
                        VoucherDeclarationFieldNames.REGIONAL_AGREEMENT_ID,
                        regionalAgreementId,
                      );
                    }}
                  />
                ) : (
                  <SwFormColumn width="12">
                    <SwFormGrid>
                      <SwFormColumn width="12">
                        <SwFormMessageLabel>
                          {t(i18nKeys.general.exploitationName)}
                        </SwFormMessageLabel>
                      </SwFormColumn>
                      <SwFormColumn width="12">
                        <SwInputField
                          value={provider.commercialName}
                          modDisabled
                          style={{ width: '100%' }}
                        />
                      </SwFormColumn>
                    </SwFormGrid>
                  </SwFormColumn>
                )}
                <Field
                  name={VoucherDeclarationFieldNames.VOUCHER_REFERENCE}
                  label={t(
                    i18nKeys.remittances.modal.declareVoucher.form.labels
                      .voucherSerialNumber,
                  )}
                  column={{ width: '12' }}
                  labelColumn={{ width: '12' }}
                  component={SwInputTextField}
                />
                <Field
                  name={VoucherDeclarationFieldNames.WORKER_NAME}
                  autoComplete="off"
                  component={AutocompleteInputField}
                  label={t(
                    i18nKeys.remittances.modal.declareVoucher.form.labels
                      .nissWorker,
                  )}
                  column={{ width: '12' }}
                  labelColumn={{ width: '12' }}
                  getOptions={getAutocompleteWorkersOption(getWorkers)}
                  menuOptionFormat={menuWorkerOptionFormat}
                  onSelectOption={onSelectWorkerOption(
                    VoucherDeclarationFieldNames.WORKER_NAME,
                  )}
                  minLength={2}
                />
                <SwFormColumn>
                  <Field
                    column={{ width: '12' }}
                    labelColumn={{ width: '12' }}
                    name={VoucherDeclarationFieldNames.WORK_DATE}
                    label={t(
                      i18nKeys.remittances.modal.declareVoucher.form.labels
                        .prestationDate,
                    )}
                    component={SwInputCalendarField}
                    visualFormat="d/m/Y"
                    minDate={DateTime.local()
                      .minus({ month: 13 })
                      .toJSDate()}
                    maxDate={DateTime.local().toJSDate()}
                    defaultDate={DateTime.local().toJSDate()}
                    format={(date: string) =>
                      date ? [DateTime.fromISO(date).toJSDate()] : undefined
                    }
                    parse={(dates: Date[]) =>
                      dates && dates.length
                        ? DateTime.fromJSDate(dates[0]).toISO()
                        : undefined
                    }
                  />
                </SwFormColumn>
                <Field
                  name={VoucherDeclarationFieldNames.ACTIVITY_TYPE}
                  label={t(
                    i18nKeys.remittances.modal.declareVoucher.form.labels
                      .activity,
                  )}
                  column={{ width: '12' }}
                  labelColumn={{ width: '12' }}
                  options={activityOptions}
                  component={SwSelectField}
                />
                <SwActionGroup>
                  <SwButton
                    data-testid={
                      dataTest.remittances.paper.deliverySlip
                        .submitTemplateButton
                    }
                    type="submit"
                    modDisabled={!dirty}
                    modLoading={isSubmitting}
                  >
                    {t(i18nKeys.remittances.modal.declareVoucher.cta.submit)}
                  </SwButton>
                </SwActionGroup>
                <SwFormSubmitMessage />
              </SwFormGrid>
            </SwForm>
          );
        }}
      </Formik>
    </>
  );
};
