import {
  GetListServicesParams,
  GetServicesHistoryParams,
  GetWorkerServicesHistoryParams,
  GetWorkerServicesParams,
  Sorting,
} from '@kaa/api/providers';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  getLabelForSorting,
  TableHead,
  TableHeadElement,
} from '../../../components';
import {
  EventAction,
  EventCategory,
  EventLabel,
  sendCustomInteractionToGTM,
} from '../../../utils/google-analytics';

type PrestationsTableHeadParameters =
  | GetServicesHistoryParams
  | GetListServicesParams
  | GetWorkerServicesParams
  | GetWorkerServicesHistoryParams
  | undefined;

export type PrestationsTableHeadElement = TableHeadElement & {
  sorting?: Sorting;
};

type PrestationsTableHeadProps<T = PrestationsTableHeadParameters> = {
  head: PrestationsTableHeadElement[];
  parameters: T;
  setParameters: React.Dispatch<React.SetStateAction<T>>;
  eventCategory?: EventCategory;
};

export const PrestationsTableHead = <T extends PrestationsTableHeadParameters>({
  head,
  parameters,
  setParameters,
  eventCategory = EventCategory.PRESTATIONS,
}: PrestationsTableHeadProps<T>) => {
  const { t } = useTranslation();

  const sortingFilters = {
    [Sorting.DATE_ASC]: {
      label: getLabelForSorting(Sorting.DATE_ASC, parameters?.sort, t),
      onClick: () => {
        setParameters((currentParameters) => {
          const newSortType =
            currentParameters?.sort === Sorting.DATE_ASC
              ? Sorting.DATE_DESC
              : Sorting.DATE_ASC;

          sendCustomInteractionToGTM(
            eventCategory,
            EventAction.CLICK,
            `${EventLabel.PRESTATIONS_SORT_SERVICE_DATES} ${
              newSortType === Sorting.DATE_ASC ? 'ASC' : 'DESC'
            }`,
          );

          return {
            ...currentParameters,
            sort: newSortType,
          };
        });
      },
    },
    [Sorting.CREATION_DATE_ASC]: {
      label: getLabelForSorting(Sorting.CREATION_DATE_ASC, parameters?.sort, t),
      onClick: () => {
        setParameters((currentParameters) => {
          const newSortType =
            currentParameters?.sort === Sorting.CREATION_DATE_ASC
              ? Sorting.CREATION_DATE_DESC
              : Sorting.CREATION_DATE_ASC;

          sendCustomInteractionToGTM(
            eventCategory,
            EventAction.CLICK,
            `${EventLabel.PRESTATIONS_SORT_CREATION_DATES} ${
              newSortType === Sorting.CREATION_DATE_ASC ? 'ASC' : 'DESC'
            }`,
          );

          return {
            ...currentParameters,
            sort: newSortType,
          };
        });
      },
    },
    [Sorting.WORKER_ASC]: {
      label: getLabelForSorting(Sorting.WORKER_ASC, parameters?.sort, t),
      onClick: () => {
        setParameters((currentParameters) => {
          const newSortType =
            currentParameters?.sort === Sorting.WORKER_ASC
              ? Sorting.WORKER_DESC
              : Sorting.WORKER_ASC;

          sendCustomInteractionToGTM(
            eventCategory,
            EventAction.CLICK,
            `${EventLabel.PRESTATIONS_SORT_WORKER} ${
              newSortType === Sorting.WORKER_ASC ? 'ASC' : 'DESC'
            }`,
          );

          return {
            ...currentParameters,
            sort: newSortType,
          };
        });
      },
    },
    [Sorting.CUSTOMER_ASC]: {
      label: getLabelForSorting(Sorting.CUSTOMER_ASC, parameters?.sort, t),
      onClick: () => {
        setParameters((currentParameters) => {
          const newSortType =
            currentParameters?.sort === Sorting.CUSTOMER_ASC
              ? Sorting.CUSTOMER_DESC
              : Sorting.CUSTOMER_ASC;

          sendCustomInteractionToGTM(
            eventCategory,
            EventAction.CLICK,
            `${EventLabel.PRESTATIONS_SORT_CUSTOMER} ${
              newSortType === Sorting.CUSTOMER_ASC ? 'ASC' : 'DESC'
            }`,
          );

          return {
            ...currentParameters,
            sort: newSortType,
          };
        });
      },
    },
  };

  return (
    <TableHead
      elements={head.map(
        ({ sorting, ...rest }) =>
          ({
            ...rest,
            ...(sorting && sortingFilters[sorting]
              ? sortingFilters[sorting]
              : undefined),
          } as TableHeadElement),
      )}
    />
  );
};
