import { WorkerContractsResponseDataItem } from '@kaa/api/providers';
import { Format } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import { Icon, SwIcon } from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modals } from '../../../../constants';

type ContractListRowProps = {
  contract: WorkerContractsResponseDataItem;
  openModal: (
    modalId: Modals,
    contract?: WorkerContractsResponseDataItem | undefined,
  ) => void;
};

export const ContractListRow = ({
  contract,
  openModal,
}: ContractListRowProps) => {
  const { t } = useTranslation();

  const { endDate, startDate, status, type, vatNumber } = contract;

  const buttonStyle = (color: string) => ({
    border: 'none',
    textDecoration: 'underline',
    cursor: 'pointer',
    color,
  });

  return (
    <tr>
      <td style={{ verticalAlign: 'middle' }}>
        {t(i18nKeys.worker.details.contract.status[status])}
      </td>
      <td style={{ verticalAlign: 'middle' }}>
        {t(i18nKeys.worker.details.contract.type[type])}
      </td>
      <td style={{ verticalAlign: 'middle' }}>{vatNumber}</td>
      <td style={{ verticalAlign: 'middle' }}>{Format.date(startDate)}</td>
      <td style={{ verticalAlign: 'middle' }}>{Format.date(endDate)}</td>
      <td>
        <div className="vl-u-display-flex vl-u-flex-v-space-between">
          <button
            type="button"
            style={buttonStyle('#0055cc')}
            onClick={() => {
              openModal(Modals.WORKERS_EDIT_CONTRACT_MODAL, contract);
            }}
          >
            <SwIcon modLarge icon={Icon.EDIT} />
            <br />
            {t(i18nKeys.general.cta.edit)}
          </button>
          <button
            type="button"
            style={buttonStyle('red')}
            onClick={() => {
              openModal(Modals.WORKERS_DELETE_CONTRACT_MODAL, contract);
            }}
          >
            <SwIcon modLarge icon={Icon.BIN} />
            <br />
            {t(i18nKeys.general.cta.delete)}
          </button>
        </div>
      </td>
    </tr>
  );
};
