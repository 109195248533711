/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */

export type FieldValidationErrorCode =
  | 'INVALID_FORMAT'
  | 'VALUE_TOO_LONG'
  | 'VALUE_TOO_SHORT'
  | 'OUT_OF_ENUM'
  | 'MISSING_VALUE'
  | 'DATE_IN_FUTURE'
  | 'DATE_IN_PAST'
  | 'INVALID'
  | 'START_DATE_GREATER_THAN_END_DATE'
  | 'VALUE_ALREADY_EXISTS'
  | 'DATA_LIMIT_EXCEEDED'
  | 'NISS_MISMATCH'
  | 'CONTACT_SUPPORT'
  | 'UNKNOWN_VALUE'
  | 'MINIMUM_AGE_NOT_REACHED'
  | 'UNREGISTERED_WORKER'
  | 'WORKER_CONTRACT_MISSING'
  | 'NOT_FOUND'
  | 'TECHNICAL_ERROR'
  | 'DATE_TOO_OLD'
  | 'PERIOD_TOO_SHORT'
  | 'PAPER_VOUCHER_ALREADY_ANNOUNCED'
  | 'PAPER_VOUCHER_ALREADY_IN_REMITTANCE'
  | 'PAPER_VOUCHER_LOST_OR_STOLEN'
  | 'PAPER_VOUCHER_MISSING'
  | 'PAPER_VOUCHER_REFUNDED'
  | 'PAPER_VOUCHER_CANCELLED'
  | 'PAPER_VOUCHER_BLACKLISTED'
  | 'PAPER_VOUCHER_EXPIRED'
  | 'TIME_PERIOD_TOO_LONG'
  | 'START_DATE_CANNOT_BE_BEFORE_2016';

export const FieldValidationErrorCode = {
  INVALID_FORMAT: 'INVALID_FORMAT' as FieldValidationErrorCode,
  VALUE_TOO_LONG: 'VALUE_TOO_LONG' as FieldValidationErrorCode,
  VALUE_TOO_SHORT: 'VALUE_TOO_SHORT' as FieldValidationErrorCode,
  OUT_OF_ENUM: 'OUT_OF_ENUM' as FieldValidationErrorCode,
  MISSING_VALUE: 'MISSING_VALUE' as FieldValidationErrorCode,
  DATE_IN_FUTURE: 'DATE_IN_FUTURE' as FieldValidationErrorCode,
  DATE_IN_PAST: 'DATE_IN_PAST' as FieldValidationErrorCode,
  INVALID: 'INVALID' as FieldValidationErrorCode,
  START_DATE_GREATER_THAN_END_DATE: 'START_DATE_GREATER_THAN_END_DATE' as FieldValidationErrorCode,
  VALUE_ALREADY_EXISTS: 'VALUE_ALREADY_EXISTS' as FieldValidationErrorCode,
  DATA_LIMIT_EXCEEDED: 'DATA_LIMIT_EXCEEDED' as FieldValidationErrorCode,
  NISS_MISMATCH: 'NISS_MISMATCH' as FieldValidationErrorCode,
  CONTACT_SUPPORT: 'CONTACT_SUPPORT' as FieldValidationErrorCode,
  UNKNOWN_VALUE: 'UNKNOWN_VALUE' as FieldValidationErrorCode,
  MINIMUM_AGE_NOT_REACHED: 'MINIMUM_AGE_NOT_REACHED' as FieldValidationErrorCode,
  UNREGISTERED_WORKER: 'UNREGISTERED_WORKER' as FieldValidationErrorCode,
  WORKER_CONTRACT_MISSING: 'WORKER_CONTRACT_MISSING' as FieldValidationErrorCode,
  NOT_FOUND: 'NOT_FOUND' as FieldValidationErrorCode,
  TECHNICAL_ERROR: 'TECHNICAL_ERROR' as FieldValidationErrorCode,
  DATE_TOO_OLD: 'DATE_TOO_OLD' as FieldValidationErrorCode,
  PERIOD_TOO_SHORT: 'PERIOD_TOO_SHORT' as FieldValidationErrorCode,
  PAPER_VOUCHER_ALREADY_ANNOUNCED: 'PAPER_VOUCHER_ALREADY_ANNOUNCED' as FieldValidationErrorCode,
  PAPER_VOUCHER_ALREADY_IN_REMITTANCE: 'PAPER_VOUCHER_ALREADY_IN_REMITTANCE' as FieldValidationErrorCode,
  PAPER_VOUCHER_LOST_OR_STOLEN: 'PAPER_VOUCHER_LOST_OR_STOLEN' as FieldValidationErrorCode,
  PAPER_VOUCHER_MISSING: 'PAPER_VOUCHER_MISSING' as FieldValidationErrorCode,
  PAPER_VOUCHER_REFUNDED: 'PAPER_VOUCHER_REFUNDED' as FieldValidationErrorCode,
  PAPER_VOUCHER_CANCELLED: 'PAPER_VOUCHER_CANCELLED' as FieldValidationErrorCode,
  PAPER_VOUCHER_BLACKLISTED: 'PAPER_VOUCHER_BLACKLISTED' as FieldValidationErrorCode,
  PAPER_VOUCHER_EXPIRED: 'PAPER_VOUCHER_EXPIRED' as FieldValidationErrorCode,
  TIME_PERIOD_TOO_LONG: 'TIME_PERIOD_TOO_LONG' as FieldValidationErrorCode,
  START_DATE_CANNOT_BE_BEFORE_2016: 'START_DATE_CANNOT_BE_BEFORE_2016' as FieldValidationErrorCode,
};
