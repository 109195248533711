import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { ApiError, HttpError } from './model';
import { CodeError } from './model/codeError';

export type HttpResponse<V, E extends ApiError> = [E | null, V | undefined];

/**
 * Create an error object with a default code and message
 * @param {T} [error] - The error object to be returned.
 * @returns An object with the following properties:
 */
export const createApiError = <T extends HttpError | ApiError>(
  error?: T,
): T => {
  return ({
    code: CodeError.INTERNAL_SERVER_ERROR,
    message: '',
    ...error,
  } as any) as T;
};

/**
 * It creates an instance of Axios, and adds the interceptor to it
 * @param {string | undefined} baseUrl - The base URL for the API.
 * @param [headers] - A dictionary of headers to be sent with the request.
 * @param [interceptor] - This is an object that contains the request and response interceptors.
 * @returns An AxiosInstance.
 */
export const getHTTPInstance = (
  baseUrl: string | undefined,
  headers?: { [key: string]: string | undefined },
  interceptor?: {
    request?: {
      onFulfilled?: (
        value: AxiosRequestConfig,
      ) => AxiosRequestConfig | Promise<AxiosRequestConfig>;
      onRejected?: (error: any) => any;
    };
    response?: {
      onFulfilled?: (
        value: AxiosResponse,
      ) => AxiosResponse | Promise<AxiosResponse>;
      onRejected?: (error: any) => any;
    };
  },
): AxiosInstance => {
  const instance = axios.create({
    baseURL: baseUrl,
    headers,
  });

  if (interceptor && interceptor.request) {
    instance.interceptors.request.use(
      interceptor.request.onFulfilled,
      interceptor.request.onRejected,
    );
  }

  if (interceptor && interceptor.response) {
    instance.interceptors.response.use(
      interceptor.response.onFulfilled,
      interceptor.response.onRejected,
    );
  }

  return instance;
};

/**
 * Handling Promise error for axios
 * It takes a promise that returns a value, and returns a promise that returns an HttpResponse
 * @param promise - The promise to convert.
 * @returns A promise that resolves to an object with a status code and data.
 */
export function httpTo<V, E extends ApiError>(
  promise: Promise<V>,
): Promise<HttpResponse<V, E>> {
  return promise
    .then<[null, V]>((data) => [null, data])
    .catch<[E, undefined]>((error: E) => {
      return [createApiError(error), undefined];
    });
}
