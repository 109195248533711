import { RegionCode } from '@kaa/api/providers';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  SwColumn,
  SwContainer,
  SwGrid,
  SwLink,
} from '@kaa/ui-flanders/components';
import get from 'lodash.get';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getConfig } from '../../../common/config';
import { PageHeader } from '../../components';
import { getPath, Routes } from '../../routes';
import { useSelectedProviderState } from '../../utils';
import { DashboardActions } from './components/DashboardActions';
import { DashboardOverview } from './components/DashboardOverview';
import { DashboardTips } from './components/DashboardTips';

export const DashboardScreen = () => {
  const { t } = useTranslation();
  const { id: providerId, commercialName } = useSelectedProviderState();
  const regionCode = get(getConfig(), 'buildConfig.regionCode') as RegionCode;

  const enterprise = `${commercialName} - ${providerId}`;

  return (
    <SwContainer>
      <SwGrid modStacked>
        <SwColumn>
          <PageHeader
            title={t(i18nKeys.navigation.dashboard)}
            introduction={t(i18nKeys.dashboard.introductionNew)}
          />
        </SwColumn>
        <SwColumn className="vl-u-spacer">
          <SwLink
            to={getPath(Routes.ENTERPRISE)}
            style={{ fontSize: '2.4rem', lineHeight: 1.5, fontWeight: 400 }}
          >
            {enterprise}
          </SwLink>
        </SwColumn>
        <DashboardOverview providerId={providerId} regionCode={regionCode} />
        <DashboardActions providerId={providerId} />
        <DashboardTips regionCode={regionCode} />
      </SwGrid>
    </SwContainer>
  );
};
