import { FileExtensionReference, RegionCode } from '@kaa/api/providers';
import { Environments } from '@kaa/common/enums';
import { ValueOf } from '@kaa/common/types';
import { getConfig } from '@kaa/core-app/common/config';
import { ApplicationType, Templates } from '@kaa/core-app/providers/constants';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import get from 'lodash.get';

type Template = { value: string; format: string };

const TEMPLATES_FORMAT = (Object.keys(FileExtensionReference).reduce(
  (acc, key) => ({
    ...acc,
    [key as FileExtensionReference]: key.toLowerCase(),
  }),
  {},
) as any) as { [key in ValueOf<typeof FileExtensionReference>]: string };

const getTemplateBase = (template: Templates) => {
  const regionCode = get(getConfig(), 'buildConfig.regionCode') as RegionCode;
  const templates = (i18nKeys.templates as any) as {
    [key in ValueOf<typeof Templates>]: {
      [key in ValueOf<typeof RegionCode>]: {
        [key in ValueOf<typeof Environments>]: {
          [key in ValueOf<typeof TEMPLATES_FORMAT>]: string;
        };
      };
    };
  };
  return templates[template][regionCode];
};

export const hasTemplate = (
  template: Templates,
  fileExtension: FileExtensionReference,
) => {
  const environment = get(getConfig(), 'app.environment') as Environments;
  const templateName = getTemplateBase(template);
  const format = TEMPLATES_FORMAT[fileExtension];

  switch (environment) {
    case Environments.PROD:
      return Boolean(templateName[environment][format]);
    default:
      return Boolean(templateName[Environments.ACC][format]);
  }
};

export const getTemplate = (
  template: Templates,
  fileExtension: FileExtensionReference,
) => {
  const environment = get(getConfig(), 'app.environment') as Environments;
  const templateName = getTemplateBase(template);
  const format = TEMPLATES_FORMAT[fileExtension];

  switch (environment) {
    case Environments.PROD:
      return { value: templateName[environment][format], format };
    default:
      return { value: templateName[Environments.ACC][format], format };
  }
};

export const getTemplates = (
  template: Templates,
  fileExtensions = [
    FileExtensionReference.XLSX,
    FileExtensionReference.XLS,
    FileExtensionReference.CSV,
  ],
): Template[] =>
  fileExtensions.reduce(
    (acc: Template[], key: string) =>
      hasTemplate(template, key as FileExtensionReference)
        ? [...acc, getTemplate(template, key as FileExtensionReference)]
        : acc,
    [],
  );

export const getAllowedFileTypes = (arr: FileExtensionReference[]): string =>
  arr.reduce(
    (acc: string, e: FileExtensionReference) =>
      acc.length === 0 ? `.${e.toLowerCase()}` : `${acc}, .${e.toLowerCase()}`,
    '',
  );

export const getApplicationType = (format: FileExtensionReference): string => {
  switch (format) {
    case FileExtensionReference.XLSX:
      return ApplicationType.XLSX as string;
    case FileExtensionReference.PDF:
      return ApplicationType.PDF as string;
    case FileExtensionReference.CSV:
      return ApplicationType.CSV as string;
    default:
      return format.toLowerCase();
  }
};

export const getFileExtension = (type: ApplicationType): string => {
  switch (type) {
    case ApplicationType.XLSX: {
      return FileExtensionReference.XLSX.toLowerCase() as string;
    }
    case ApplicationType.PDF: {
      return FileExtensionReference.PDF.toLowerCase() as string;
    }
    case ApplicationType.CSV: {
      return FileExtensionReference.CSV.toLowerCase() as string;
    }
    default: {
      const ext = (type as string).split('.');
      return ext[ext.length - 1].toLowerCase();
    }
  }
};
