/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */

export type SubsidyReceivalModeCode =
  | 'ONE_RECEIVAL_PER_REMITTANCE'
  | 'MONTHLY_RECEIVAL';

export const SubsidyReceivalModeCode = {
  ONE_RECEIVAL_PER_REMITTANCE: 'ONE_RECEIVAL_PER_REMITTANCE' as SubsidyReceivalModeCode,
  MONTHLY_RECEIVAL: 'MONTHLY_RECEIVAL' as SubsidyReceivalModeCode,
};
