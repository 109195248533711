import {
  CreateRemittancesParams,
  GetServicesParams,
  PaginationDirectivesPaginationDirectives,
  ProviderType,
  ServicesResponse,
} from '@kaa/api/providers';
import { httpTo } from '@kaa/api/providers/utilities';
import { StorageKeys } from '@kaa/common/enums';
import { Format, useAsyncCallback } from '@kaa/common/utils';
import { required } from '@kaa/common/validation';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AlertType,
  SwActionGroup,
  SwButton,
  SwCheckboxField,
  SwFetchErrorMessage,
  SwForm,
  SwFormColumn,
  SwFormGrid,
  SwFormInput,
  SwFormMessageLabel,
  SwInputField,
  SwLoader,
  SwModalRenderProps,
  SwSelectField,
  SwTitle,
} from '@kaa/ui-flanders/components';
import { Field, FieldProps, Formik, FormikActions } from 'formik';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AvailableLanguages } from '../../../../../../../common/enums';
import { dataTest } from '../../../../../datatest/keys';
import { useApi, useSelectedProviderState } from '../../../../../utils';
import {
  EventAction,
  EventCategory,
  EventLabel,
  sendCustomInteractionToGTM,
} from '../../../../../utils/google-analytics';
import {
  createValidatorWithServerErrorHandled,
  handleApiError,
} from '../../../../../utils/validation';
import { RemittanceManualModalConfirmation } from './RemittanceManualModalConfirmation';
import {
  ElectronicManualRemittanceFieldNames,
  REMITTANCE_MANUAL_MODAL_PAGE_SIZE,
} from './RemittancesManualModal.constants';
import {
  ElectronicManualRemittanceForm,
  ElectronicManualRemittanceResultsForm,
} from './RemittancesManualModal.types';
import { RemittancesManualModalResultsForm } from './RemittancesManualModalResultsForm';

type RemittancesManualModalProps = SwModalRenderProps & {
  onSuccess: () => void;
};

export const RemittancesManualModal = ({
  onSuccess,
}: RemittancesManualModalProps) => {
  const { t, i18n } = useTranslation();
  const provider = useSelectedProviderState();
  const providerId = provider.id;
  const isSubsidiary = provider.type === ProviderType.SUBSIDIARY;
  const { providers: providersApi } = useApi();

  const [serviceMonthPeriod, setServiceMonthPeriod] = useState<string[]>([]);
  const [parameters, setParameters] = useState<GetServicesParams>();
  const [pagination, setPagination] = useState<
    PaginationDirectivesPaginationDirectives
  >();
  const [services, setServices] = useState<ServicesResponse['data']>();

  const manualElectronicRemittancePreference = localStorage.getItem(
    StorageKeys.MANUAL_EL_REMITTANCE_PREFERENCE,
  );
  const onlyFullyAssignedServices =
    manualElectronicRemittancePreference === 'true';

  const [
    {
      value: relativesResponse,
      loading: relativesLoading,
      error: relativesError,
    },
    getRelatives,
  ] = useAsyncCallback(async () => {
    const {
      data: {
        data: { headquarter, subsidiaries },
      },
    } = await providersApi.getProviderRelatives(providerId);

    return [headquarter, ...subsidiaries.filter(({ isActive }) => isActive)];
  }, [providersApi]);

  const [
    {
      value: serviceMonthPeriodResponse,
      loading: serviceMonthPeriodLoading,
      error: serviceMonthPeriodError,
    },
    getServiceMonthPeriod,
  ] = useAsyncCallback(
    async (
      relativeId: number | string,
      form: FieldProps<ElectronicManualRemittanceForm>['form'],
    ) => {
      const serviceMonthPeriod = (
        await providersApi.getAvailableMonths(Number(relativeId))
      ).data.data;

      return {
        serviceMonthPeriod,
        relativeId,
        form,
      };
    },
    [providersApi],
  );

  const [
    { value: servicesResponse, loading: servicesLoading },
    getServices,
  ] = useAsyncCallback(
    async ({
      [ElectronicManualRemittanceFieldNames.RELATED_PROVIDER_ID]: relativeId,
      ...params
    }: GetServicesParams) =>
      (await providersApi.getServices(Number(relativeId || providerId), params))
        .data,
    [providersApi],
  );

  const [{ value: submitSearchResponse }, submitSearch] = useAsyncCallback(
    async (
      formikData: ElectronicManualRemittanceForm,
      formikActions: FormikActions<ElectronicManualRemittanceForm>,
    ) => {
      return {
        formikActions,
        formikData,
      };
    },
    [providersApi],
  );

  const [{ value: submitResponse }, submit] = useAsyncCallback(
    async (
      formikData: ElectronicManualRemittanceResultsForm,
      formikActions: FormikActions<ElectronicManualRemittanceResultsForm>,
    ) => {
      if (
        !Object.keys(formikData.bundle).length &&
        !formikData.allServicesSelected
      ) {
        const { resetForm, setStatus } = formikActions;
        resetForm(formikData);
        setStatus({
          msg: 'errorMessage',
          type: AlertType.ERROR,
        });
        return undefined;
      }

      const bundle = Object.keys(formikData.bundle)
        .filter((v) => formikData.bundle[v])
        .map((value) => Number(value.replace('id-', '')));

      const [error, response] = await httpTo(
        providersApi.createRemittances(
          Number(parameters?.relatedProviderId || providerId),
          {
            ...formikData,
            bundle,
          },
          parameters as CreateRemittancesParams,
        ),
      );

      return {
        formikData,
        formikActions,
        error,
        response,
      };
    },
    [providersApi, parameters],
  );

  useEffect(() => {
    getRelatives();
  }, [getRelatives]);

  useEffect(() => {
    getServiceMonthPeriod(providerId);
  }, [getServiceMonthPeriod]);

  useEffect(() => {
    if (serviceMonthPeriodResponse) {
      const {
        serviceMonthPeriod: serviceMonthsData,
        relativeId,
        form,
      } = serviceMonthPeriodResponse;

      setServiceMonthPeriod(serviceMonthsData);

      if (relativeId && form) {
        form.setValues({
          ...form.values,
          [ElectronicManualRemittanceFieldNames.RELATED_PROVIDER_ID]: relativeId,
          [ElectronicManualRemittanceFieldNames.SERVICE_MONTH_PERIOD]: '',
        });
      }
    }
  }, [serviceMonthPeriodResponse]);

  useEffect(() => {
    if (parameters) {
      getServices(parameters);
    }
  }, [getServices, parameters]);

  useEffect(() => {
    if (servicesResponse) {
      const { paginationDirectives, data } = servicesResponse;
      setServices(data);
      setPagination(paginationDirectives);
    }
  }, [servicesResponse]);

  useEffect(() => {
    if (submitSearchResponse) {
      const {
        formikData,
        formikActions: { setSubmitting, resetForm },
      } = submitSearchResponse;

      setSubmitting(false);

      resetForm(formikData);
      setParameters({
        ...formikData,
        pageSize: REMITTANCE_MANUAL_MODAL_PAGE_SIZE,
        pageNumber: 1,
      });
      sendCustomInteractionToGTM(
        EventCategory.REMITTANCES_MANUAL_ELECTRONIC,
        EventAction.SEARCH,
        `${EventLabel.REMITTANCES_MANUAL_ELECTRONIC_SEARCH} MonthPeriod:${formikData.serviceMonthPeriod}`,
      );
    }
  }, [submitSearchResponse]);

  useEffect(() => {
    if (submitResponse) {
      const { error, formikActions, response } = submitResponse;
      const { setStatus, setSubmitting } = formikActions;

      if (handleApiError(error, formikActions)) {
        return;
      }

      if (!response) {
        setSubmitting(false);
        setStatus({
          msg: 'errorMessage',
          type: AlertType.ERROR,
        });
        return;
      }

      onSuccess();

      sendCustomInteractionToGTM(
        EventCategory.REMITTANCES_MANUAL_ELECTRONIC,
        EventAction.FORM_CONFIRMATION,
        EventLabel.REMITTANCES_MANUAL_ELECTRONIC,
      );
    }
  }, [submitResponse]);

  if (
    (serviceMonthPeriodLoading && !serviceMonthPeriodResponse) ||
    (relativesLoading && !relativesResponse)
  ) {
    return <SwLoader />;
  }

  if (
    serviceMonthPeriodError ||
    relativesError ||
    !serviceMonthPeriodResponse ||
    !relativesResponse
  ) {
    return (
      <SwFetchErrorMessage
        onClick={() => {
          getRelatives();
        }}
      />
    );
  }

  if (submitResponse?.response) {
    return (
      <RemittanceManualModalConfirmation
        providerId={providerId}
        confirmationProps={{ ...submitResponse.response.data.data }}
      />
    );
  }

  const onRelativeChange = (
    relativeId: number | string,
    form: FieldProps<ElectronicManualRemittanceForm>['form'],
  ) => {
    getServiceMonthPeriod(relativeId, form);
  };

  const relativeOptions = relativesResponse.map(({ id, name }) => ({
    value: id,
    text: `${name}`,
  }));

  const monthsOptions = serviceMonthPeriod.map((date: string) => ({
    value: date,
    text: Format.dateMonthYear(date, i18n.language as AvailableLanguages),
  }));

  const validate = createValidatorWithServerErrorHandled<
    ElectronicManualRemittanceForm
  >({
    relatedProviderId: [required],
    serviceMonthPeriod: [required],
  });

  return (
    <>
      <SwTitle tagName="h3" className="vl-u-spacer--medium">
        {t(i18nKeys.remittances.modal.manual.title)}
      </SwTitle>
      <Formik<ElectronicManualRemittanceForm>
        onSubmit={submitSearch}
        initialValues={{
          relatedProviderId: providerId.toString(),
          serviceMonthPeriod: undefined,
          onlyFullyAssignedServices,
        }}
        validate={validate}
      >
        {({ handleSubmit, values, isSubmitting }) => {
          return (
            <SwForm onSubmit={handleSubmit} className="vl-u-spacer">
              <SwFormGrid modStacked className="vl-u-spacer">
                {isSubsidiary ? (
                  <>
                    <SwFormColumn width="12">
                      <SwFormMessageLabel
                        htmlFor={
                          ElectronicManualRemittanceFieldNames.RELATED_PROVIDER_ID
                        }
                      >
                        {t(i18nKeys.remittances.general.exploitationName)}
                      </SwFormMessageLabel>
                      <SwFormInput>
                        <SwInputField
                          id={
                            ElectronicManualRemittanceFieldNames.RELATED_PROVIDER_ID
                          }
                          type="text"
                          value={provider.name}
                          modDisabled
                          modBlock
                        />
                      </SwFormInput>
                    </SwFormColumn>
                  </>
                ) : (
                  <Field
                    name={
                      ElectronicManualRemittanceFieldNames.RELATED_PROVIDER_ID
                    }
                    label={t(i18nKeys.remittances.general.exploitationName)}
                    column={{ width: '12' }}
                    labelColumn={{ width: '12' }}
                    options={relativeOptions}
                    component={SwSelectField}
                    modNoPlaceholder
                    inputOnChange={(
                      e: ChangeEvent<HTMLInputElement>,
                      field: FieldProps<
                        ElectronicManualRemittanceForm
                      >['field'],
                      form: FieldProps<ElectronicManualRemittanceForm>['form'],
                    ) => {
                      onRelativeChange(e.target.value, form);
                    }}
                  />
                )}
                {!serviceMonthPeriodLoading ? (
                  <Field
                    name={
                      ElectronicManualRemittanceFieldNames.SERVICE_MONTH_PERIOD
                    }
                    label={t(i18nKeys.remittances.general.month)}
                    column={{ width: '12' }}
                    labelColumn={{ width: '12' }}
                    options={monthsOptions}
                    component={SwSelectField}
                  />
                ) : (
                  <SwLoader modMessageHidden />
                )}
                <Field
                  name={
                    ElectronicManualRemittanceFieldNames.FULLY_ASSIGNED_SERVICES
                  }
                  label={t(
                    i18nKeys.remittances.modal.manual.onlyFullyAssignedServices
                      .text,
                  )}
                  column={{ width: '0' }}
                  component={SwCheckboxField}
                  inputOnChange={(e: ChangeEvent<HTMLInputElement>) => {
                    localStorage.setItem(
                      StorageKeys.MANUAL_EL_REMITTANCE_PREFERENCE,
                      String(e.target.checked),
                    );
                  }}
                />
                <SwActionGroup>
                  <SwButton
                    data-testid={
                      dataTest.remittances.electronic.manual.submitButton
                    }
                    type="submit"
                    modDisabled={!values.serviceMonthPeriod}
                    modLoading={isSubmitting}
                  >
                    {t(i18nKeys.general.search)}
                  </SwButton>
                </SwActionGroup>
              </SwFormGrid>
            </SwForm>
          );
        }}
      </Formik>

      {!!services && parameters && (
        <RemittancesManualModalResultsForm
          services={services}
          submit={submit}
          pagination={pagination}
          parameters={parameters}
          setParameters={setParameters}
          loading={servicesLoading}
        />
      )}
    </>
  );
};
