import * as client from '@govflanders/vl-widget-client';
import '@govflanders/vl-widget-polyfill';
import { httpTo } from '@kaa/api/providers/utilities';
import { CLIENT_ID_PROVIDER_VL_LOCAL, useAuthState } from '@kaa/auth/common';
import { getConfig } from '@kaa/core-app/common/config';
import { Modals } from '@kaa/core-app/providers/constants';
import { AuthContext } from '@kaa/core-app/providers/types';
import { SwModal, toggleModalById } from '@kaa/ui-flanders/components';
import { navigate } from '@reach/router';
import get from 'lodash.get';
import React, { useEffect, useRef, useState } from 'react';
import { ConfirmSignoutModal } from '../confirm-signout-modal/ConfirmSignoutModal';
import { configureSession, onLogoutRequest } from './Header.utils';

const setBlankPromise = (
  deferred: React.MutableRefObject<any>,
  promise: React.MutableRefObject<any>,
) => {
  // eslint-disable-next-line
  promise.current = new Promise((resolve, reject) => {
    // eslint-disable-next-line
    deferred.current = {
      resolve,
      reject,
    };
  });
};
export const Header = () => {
  const [widget, setWidget] = useState<any>(null);
  const [isWidgetMounted, setIsWidgetMounted] = useState(false);
  const $el = useRef(null);

  const deferredUserIntent = useRef<any>();
  const userIntentPromise = useRef<any>();

  const {
    getSigninRequestUrl,
    getLogoutRequestUrl,
    getClientId,
    getUser,
    authUser,
  } = useAuthState<AuthContext>();

  useEffect(() => {
    const getWidget = async () => {
      const [error, bootstrapWidget] = await httpTo(
        client.bootstrap(get(getConfig(), 'app.auth.definition.header')),
      );

      if (error) {
        setWidget(null);
        return;
      }

      setWidget(bootstrapWidget);
    };

    getWidget();
  }, []);

  useEffect(() => {
    if (!(widget && $el.current)) {
      return;
    }

    widget.setMountElement($el.current).mount();
    setIsWidgetMounted(true);
    return () => {
      widget.setMountElement(null).unmount();
      setIsWidgetMounted(false);
    };
  }, [widget]);

  useEffect(() => {
    if (!isWidgetMounted) {
      return;
    }

    client.capture((capturedWidget: any) => {
      if (capturedWidget.getPluginTypeId() !== 'global_header') {
        return;
      }

      /**
       * Allows to configure the loginUrl and logoutUrl that will redirect to IDP
       * with the correct configuration
       */
      capturedWidget
        .getExtension('citizen_profile.session')
        .then(async (session: any) => {
          const [error, data] = await httpTo(
            Promise.all([
              getSigninRequestUrl(),
              getLogoutRequestUrl(),
              getUser(),
            ]),
          );

          if (error || !data) {
            configureSession('', '', false, session);
            return navigate(
              get(getConfig(), 'buildConfig.errorPagePath') as string,
            );
          }

          configureSession(
            data[0] as string,
            data[1] as string,
            !!data[2],
            session,
          );
        });

      /**
       * Allows to handle logout click on header widget
       */
      capturedWidget.on(
        'citizen_profile.session.logout.request',
        onLogoutRequest(async () => {
          if (getClientId() === CLIENT_ID_PROVIDER_VL_LOCAL) {
            return true;
          }
          setBlankPromise(deferredUserIntent, userIntentPromise);
          toggleModalById(Modals.CONFIRM_SIGNOUT_MODAL);
          return userIntentPromise.current;
        }, authUser?.access_token),
      );
    });
  }, [
    isWidgetMounted,
    getSigninRequestUrl,
    getLogoutRequestUrl,
    getClientId,
    getUser,
    authUser,
  ]);

  return (
    <div ref={$el}>
      <SwModal
        id={Modals.CONFIRM_SIGNOUT_MODAL}
        component={ConfirmSignoutModal}
        onConfirm={(doLogout: boolean) => {
          deferredUserIntent.current.resolve(doLogout);
        }}
      />
    </div>
  );
};
