import { toCurrencyFormat, useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  SwColumn,
  SwFetchErrorMessage,
  SwGrid,
  SwIntroduction,
  SwLoader,
  SwModalRenderProps,
  SwTitle,
} from '@kaa/ui-flanders/components';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../../../utils';
import { PickupBillingDetailsModalTable } from './PickupBillingDetailsModalTable';

type PickupBillingDetailsModalProps = {
  providerId: number;
  billId: string;
};

export const PickupBillingDetailsModal = ({
  providerId,
  billId,
}: SwModalRenderProps<PickupBillingDetailsModalProps>) => {
  const { t } = useTranslation();
  const { providers } = useApi();

  const [
    { value: billDetails, loading, error },
    getProviderBill,
  ] = useAsyncCallback(
    async () => (await providers.getProviderBill(providerId, billId)).data.data,
    [providers],
    { loading: true },
  );

  useEffect(() => {
    getProviderBill();
  }, [getProviderBill]);

  if (loading && !billDetails) {
    return <SwLoader />;
  }

  if (error || !billDetails) {
    return <SwFetchErrorMessage onClick={getProviderBill} />;
  }

  const { reference, vatFreeAmount, tasks } = billDetails;

  return (
    <SwGrid modStacked>
      <SwColumn>
        <SwTitle tagName="h2">
          {t(i18nKeys.pickup.billing.detail.title, {
            reference,
          })}
        </SwTitle>
        <SwIntroduction tagName="p" className="vl-u-spacer--medium">
          {t(i18nKeys.pickup.billing.detail.subTitle, {
            total: toCurrencyFormat(vatFreeAmount),
          })}
        </SwIntroduction>
      </SwColumn>
      <SwColumn>
        <PickupBillingDetailsModalTable tasks={tasks} />
      </SwColumn>
    </SwGrid>
  );
};
