import {
  ExportWorkersParams,
  FileExtensionReference,
} from '@kaa/api/providers';
import { httpTo } from '@kaa/api/providers/utilities';
import { useAsync, useAsyncCallback } from '@kaa/common/utils';
import { DownloadTemplates } from '@kaa/core-app/providers/components';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  SwFetchErrorMessage,
  SwLoader,
  SwModalRenderProps,
  SwTitle,
} from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useApi, useSelectedProviderIdState } from '../../../../utils';
import { getApplicationType } from '../../../../utils/templates';

type WorkersSearchDownloadModalProps = {
  parameters?: ExportWorkersParams;
};

export const WorkersSearchDownloadModal = ({
  parameters,
}: SwModalRenderProps<WorkersSearchDownloadModalProps>) => {
  const { t } = useTranslation();
  const { providers } = useApi();
  const providerId = useSelectedProviderIdState();

  const [{ value: templateLink, loading, error }, getExport] = useAsyncCallback(
    async () => {
      const [, payload] = await httpTo(
        providers.exportWorkers(providerId, parameters),
      );

      if (!payload) {
        return undefined;
      }

      const blob = new Blob([payload.data as BlobPart], {
        type: getApplicationType(
          parameters?.fileExtension || FileExtensionReference.XLSX,
        ),
      });

      const url = window.URL.createObjectURL(blob);

      return { url, blob };
    },
    [providers],
    { loading: true },
  );

  useAsync(getExport);

  if (loading) {
    return <SwLoader />;
  }

  if (error || !templateLink) {
    return <SwFetchErrorMessage onClick={getExport} />;
  }

  return (
    <>
      <SwTitle tagName="h2" className="vl-u-spacer--medium">
        {t(i18nKeys.workers.modal.export.title)}
      </SwTitle>
      <DownloadTemplates
        linkToTemplate={templateLink.url}
        blob={templateLink.blob}
        fileName={t(i18nKeys.workers.downloadTemplate.fileName)}
      />
    </>
  );
};
