import { getActionsMock, getResourceIdMock } from '@kaa/api/common/mocks';
import faker from 'faker';
import { DateTime } from 'luxon';
import {
  Bill,
  BillActionType,
  BillBase,
  BillDetailsDataTasksItem,
  BillDetailsResponse,
  BillingConfigurationResponse,
  BillsResponse,
  BillType,
  GetProviderBillsParams,
  PaymentMode,
} from '../model';
import { getContactPerson } from './contactPersons';
import { getRandomArray } from './utils';

export const RESOURCE_PREFIX_BILL = 'bill';

const getBillBase = (
  id = faker.random.uuid(),
  vatFreeAmount = faker.random.number(100),
): BillBase => ({
  id,
  reference: id,
  vatFreeAmount,
  resourceId: getResourceIdMock(RESOURCE_PREFIX_BILL),
});

const getBill = (params?: GetProviderBillsParams): Bill => {
  const { vatFreeAmount, ...rest } = getBillBase();
  return {
    ...rest,
    vatFreeAmount,
    vatIncludedAmount: vatFreeAmount + (vatFreeAmount / 100) * 20,
    type: params?.type || faker.helpers.randomize(Object.values(BillType)),
    emissionDate: DateTime.fromJSDate(faker.date.past())
      .set({ year: params?.year || new Date().getFullYear() })
      .toISODate(),
  };
};

const getBillDetailsDataTasksItem = (
  vatFreeAmount = faker.random.number(20),
): BillDetailsDataTasksItem => ({
  commercialName: faker.company.companyName(),
  providerId: faker.random.uuid(),
  consumptionDate: DateTime.fromJSDate(faker.date.past())
    .set({ year: new Date().getFullYear() })
    .toISODate(),
  vatFreeAmount,
});

export const getBillsResponse = (
  params?: GetProviderBillsParams,
): BillsResponse => {
  const bills = getRandomArray({ min: 1, max: 30 })
    .map(() => getBill(params))
    .sort(
      (a, b) =>
        Number(new Date(b.emissionDate)) - Number(new Date(a.emissionDate)),
    );

  return {
    data: bills,
    actions: bills.reduce(
      (acc, { resourceId }) =>
        resourceId
          ? {
              ...acc,
              [resourceId]: getActionsMock(BillActionType),
            }
          : acc,
      {},
    ),
  };
};

export const getBillingConfigurationResponse = (): BillingConfigurationResponse => ({
  data: {
    paymentMode: faker.helpers.randomize(Object.values(PaymentMode)),
    financialContact: getContactPerson(),
  },
});

export const getBillDetailsResponse = (billId: string): BillDetailsResponse => {
  const tasks = getRandomArray({ min: 1, max: 100 }).map(() =>
    getBillDetailsDataTasksItem(),
  );

  return {
    data: {
      ...getBillBase(
        billId,
        tasks.reduce((acc, e) => acc + e.vatFreeAmount, 0),
      ),
      tasks,
    },
  };
};
