import { ProviderSubsidiary } from '@kaa/api/providers';

export const fieldNameThatNeedToBeValidate = [
  'name',
  'commercialName',
  'address.street',
  'address.postcode',
  'address.houseNumber',
];

export const RelativeAddModalfieldNames = {
  LANGUAGE: 'language' as keyof ProviderSubsidiary,
  LEGAL_FORM: 'legalForm' as keyof ProviderSubsidiary,
  PHONE_NUMBER: 'phoneNumber' as keyof ProviderSubsidiary,
  MOBILE_PHONE_NUMBER: 'mobilePhoneNumber' as keyof ProviderSubsidiary,
  EMAIL_ADDRESS: 'emailAddress' as keyof ProviderSubsidiary,
  PUBLIC_WEBSITE: 'publicWebsite' as keyof ProviderSubsidiary,
  NAME: 'name' as keyof ProviderSubsidiary,
  COMMERCIAL_NAME: 'commercialName' as keyof ProviderSubsidiary,
  VAT_NUMBER: 'vatNumber' as keyof ProviderSubsidiary,
  ADDRESS: 'address' as keyof ProviderSubsidiary,
  SUBSIDY_RECEIVAL_BANK_ACCOUNT: 'subsidyReceivalBankAccount' as keyof ProviderSubsidiary,
  SUBSIDY_RECEIVAL_MODE: 'subsidyReceivalMode' as keyof ProviderSubsidiary,
};
