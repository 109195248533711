import {
  Pickup,
  PickupConfigurationResponseData,
  PickupOccurrenceUpdate,
  PickupType,
  RecurrentPickupUpdate,
  Relative,
} from '@kaa/api/providers';
import { httpTo } from '@kaa/api/providers/utilities';
import { useAsyncCallback, useLuxon } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AlertType,
  SwActionGroup,
  SwButton,
  SwColumn,
  SwFetchErrorMessage,
  SwForm,
  SwFormGrid,
  SwFormSubmitMessage,
  SwLink,
  SwLoader,
  SwModalRenderProps,
  SwTitle,
  toggleModalById,
} from '@kaa/ui-flanders/components';
import { FieldProps, Formik, FormikActions } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modals } from '../../../../constants';
import { useApi } from '../../../../utils';
import {
  createValidatorWithServerErrorHandled,
  handleApiError,
} from '../../../../utils/validation';
import { PickupModalsComment } from '../components/PickupModalsComment';
import { PickupModalsRelatives } from '../components/PickupModalsRelatives';
import { PickupRecurrentModal } from '../components/PickupRecurrentModal';
import { PickupStandaloneModal } from '../components/PickupStandaloneModal';
import {
  onSubmitStatus,
  PickupCreateFormData,
  PickupFormFieldNames,
  PickupUpdateFormData,
} from '../PickupModals.constants';
import {
  getAddressValue,
  getNotIfValidations,
  getPickupUpdateInitialValues,
  pickupValidation,
  recurrentPickupValidation,
  standalonePickupValidation,
} from '../PickupModals.utils';
import { PickupUpdateModalSelect } from './PickupUpdateModalSelect';

type PickupUpdateModalProps = {
  providerId: number;
  relatives: Relative[];
  pickup: Pickup;
  onSubmit: (status: onSubmitStatus) => void;
};

export const PickupUpdateModal = ({
  providerId,
  pickup,
  onSubmit,
  relatives,
  setConfirmCloseModal,
}: SwModalRenderProps<PickupUpdateModalProps>) => {
  const { t } = useTranslation();
  const { DateTime } = useLuxon();
  const { providers } = useApi();

  const [recurrenceUpdate, setRecurrenceUpdate] = useState<
    boolean | undefined
  >();

  const [configuration, setConfiguration] = useState<
    PickupConfigurationResponseData
  >();

  const [lastComment, setLastcomment] = useState<string>();

  const [
    {
      value: singlePickup,
      loading: singlePickupLoading,
      error: singlePickupError,
    },
    getSinglePickup,
  ] = useAsyncCallback(
    async () =>
      (
        await providers.getSinglePickup(providerId, pickup.id, {
          type: pickup.type,
        })
      ).data.data,
    [providers],
    { loading: true },
  );

  const [
    {
      value: configurationResponse,
      loading: configurationLoading,
      error: configurationError,
    },
    getConfigurationData,
  ] = useAsyncCallback(
    async (
      relativeId: number,
      form: FieldProps<PickupUpdateFormData>['form'],
    ) => {
      const configurationData = (
        await providers.getProviderPickupConfiguration(relativeId)
      ).data.data;

      return {
        configuration: configurationData,
        relativeId,
        form,
      };
    },
    [providers],
    { loading: true },
  );

  const [
    { value: submitedOccurrenceUpdate },
    submitOccurrenceUpdate,
  ] = useAsyncCallback(
    async (
      pickupFormData: PickupUpdateFormData,
      formikActions: FormikActions<PickupUpdateFormData>,
    ) => {
      const [error, response] = await httpTo(
        providers.updateSinglePickup(providerId, pickupFormData.id, {
          ...pickupFormData,
          [PickupFormFieldNames.ADDRESS]: JSON.parse(
            pickupFormData[PickupFormFieldNames.ADDRESS],
          ),
        } as PickupOccurrenceUpdate),
      );

      return {
        error,
        response,
        formikActions,
      };
    },
    [providers, providerId],
  );

  const [
    { value: submitedRecurrentUpdate },
    submitRecurrentUpdate,
  ] = useAsyncCallback(
    async (
      pickupFormData: PickupUpdateFormData,
      formikActions: FormikActions<PickupUpdateFormData>,
    ) => {
      const [error, response] = await httpTo(
        providers.updateRecurrentPickup(providerId, pickupFormData.id, {
          ...pickupFormData,
          [PickupFormFieldNames.ADDRESS]: JSON.parse(
            pickupFormData[PickupFormFieldNames.ADDRESS],
          ),
        } as RecurrentPickupUpdate),
      );

      return {
        error,
        response,
        formikActions,
      };
    },
    [providers, providerId],
  );

  useEffect(() => {
    getSinglePickup();
  }, [getSinglePickup]);

  useEffect(() => {
    getConfigurationData(providerId);
  }, [getConfigurationData]);

  useEffect(() => {
    if (configurationResponse) {
      const {
        configuration: configurationData,
        relativeId,
        form,
      } = configurationResponse;
      setConfiguration(configurationData);

      const {
        mainPickupAddress: { address, comment },
      } = configurationData;

      setLastcomment(comment);

      if (relativeId && form) {
        form.setValues({
          ...form.values,
          [PickupFormFieldNames.RELATIVE_ID as keyof PickupUpdateFormData]: relativeId,
          [PickupFormFieldNames.ADDRESS as keyof PickupUpdateFormData]: getAddressValue(
            address,
          ),
        });
      }
    }
  }, [configurationResponse]);

  useEffect(() => {
    if (submitedOccurrenceUpdate) {
      const { error, response, formikActions } = submitedOccurrenceUpdate;
      const { setStatus, resetForm } = formikActions;

      if (handleApiError(error, formikActions)) {
        return;
      }

      resetForm();

      if (!response) {
        setStatus({
          type: AlertType.ERROR,
          msg: t(i18nKeys.errors.server.DEFAULT),
        });
        return;
      }

      onSubmit({
        type: AlertType.SUCCESS,
        title: t(i18nKeys.pickup.alert.confirm.new),
      });

      toggleModalById(Modals.PICKUP_UPDATE_PICKUP_MODAL);
    }
  }, [submitedOccurrenceUpdate]);

  useEffect(() => {
    if (submitedRecurrentUpdate) {
      const { error, response, formikActions } = submitedRecurrentUpdate;
      const { setStatus, resetForm } = formikActions;

      if (handleApiError(error, formikActions)) {
        return;
      }

      resetForm();

      if (!response) {
        setStatus({
          type: AlertType.ERROR,
          msg: t(i18nKeys.errors.server.DEFAULT),
        });
        return;
      }

      onSubmit({
        type: AlertType.SUCCESS,
        title: t(i18nKeys.pickup.alert.confirm.new),
      });

      toggleModalById(Modals.PICKUP_UPDATE_PICKUP_MODAL);
    }
  }, [submitedRecurrentUpdate]);

  const onRelativeChange = (
    relativeId: string,
    form: FieldProps<PickupCreateFormData | PickupUpdateFormData>['form'],
  ) => {
    getConfigurationData(relativeId, form);
  };

  const onAddressSelect = (
    comment: string | undefined,
    form: FieldProps<PickupCreateFormData | PickupUpdateFormData>['form'],
  ) => {
    setLastcomment(comment);
  };

  const submitPickup = (
    pickupFormData: PickupUpdateFormData,
    formikActions: FormikActions<PickupUpdateFormData>,
  ) => {
    const { type } = pickupFormData;
    if (type === PickupType.RECURRENT && recurrenceUpdate === true) {
      submitRecurrentUpdate(pickupFormData, formikActions);
    } else {
      submitOccurrenceUpdate(pickupFormData, formikActions);
    }
  };

  if (
    (singlePickupLoading && !singlePickup) ||
    (configurationLoading && !configuration)
  ) {
    return (
      <>
        <SwTitle tagName="h2" className="vl-u-spacer">
          {t(i18nKeys.pickup.modal.update.form.title)}
        </SwTitle>
        <SwColumn width="12">
          <SwLoader />
        </SwColumn>
      </>
    );
  }

  if (
    singlePickupError ||
    !singlePickup ||
    configurationError ||
    !configuration
  ) {
    return (
      <>
        <SwTitle tagName="h2" className="vl-u-spacer">
          {t(i18nKeys.pickup.modal.update.form.title)}
        </SwTitle>
        <SwColumn width="12">
          <SwFetchErrorMessage
            onClick={() => {
              getSinglePickup();
              getConfigurationData(providerId);
            }}
          />
        </SwColumn>
      </>
    );
  }

  if (
    singlePickup.type === PickupType.RECURRENT &&
    typeof recurrenceUpdate !== 'boolean'
  ) {
    return (
      <>
        <SwTitle tagName="h2" className="vl-u-spacer">
          {t(i18nKeys.pickup.modal.update.RECURRENT.title)}
        </SwTitle>
        <PickupUpdateModalSelect setRecurrenceUpdate={setRecurrenceUpdate} />
      </>
    );
  }

  return (
    <>
      <SwTitle tagName="h2" className="vl-u-spacer">
        {t(i18nKeys.pickup.modal.update.form.title)}
      </SwTitle>
      <Formik<PickupUpdateFormData>
        onSubmit={submitPickup}
        initialValues={{
          ...getPickupUpdateInitialValues(singlePickup),
          // TODO: Fix me in BFF for call of getPickup
          [PickupFormFieldNames.DATE]: pickup.date || '',
          [PickupFormFieldNames.INITIAL_DATE]: pickup.date || '',
        }}
        validate={createValidatorWithServerErrorHandled({
          ...pickupValidation,
          ...getNotIfValidations(
            PickupFormFieldNames.START_DATE,
            standalonePickupValidation,
          ),
          ...getNotIfValidations(
            PickupFormFieldNames.DATE,
            recurrentPickupValidation,
          ),
        })}
        render={({
          handleSubmit,
          dirty,
          isSubmitting,
          values,
          setFieldValue,
        }) => {
          setConfirmCloseModal(dirty);

          return (
            <SwForm onSubmit={handleSubmit}>
              <SwFormGrid>
                <PickupModalsRelatives
                  relatives={relatives}
                  loading={configurationLoading}
                  configuration={configuration}
                  onRelativeChange={onRelativeChange}
                  onAddressSelect={onAddressSelect}
                />
                <PickupModalsComment
                  lastComment={lastComment}
                  values={values}
                  setFieldValue={setFieldValue}
                />
                {values[PickupFormFieldNames.TYPE] === PickupType.RECURRENT &&
                recurrenceUpdate === true ? (
                  <PickupRecurrentModal
                    configuration={configuration}
                    values={values}
                    setFieldValue={setFieldValue}
                    disableStartDate={
                      DateTime.fromISO(values.startDate)
                        .endOf('day')
                        .toJSDate() <
                      DateTime.fromJSDate(new Date())
                        .endOf('day')
                        .toJSDate()
                    }
                  />
                ) : (
                  <PickupStandaloneModal configuration={configuration} />
                )}
                <SwActionGroup className="vl-u-spacer">
                  <SwButton
                    type="submit"
                    modLoading={isSubmitting}
                    modLarge
                    modDisabled={!dirty}
                    style={{ margin: 0 }}
                  >
                    {t(i18nKeys.pickup.modal.update.form.cta)}
                  </SwButton>
                  <SwLink
                    style={{ marginLeft: '2rem' }}
                    onClick={() => {
                      toggleModalById(Modals.PICKUP_UPDATE_PICKUP_MODAL);
                    }}
                  >
                    {t(i18nKeys.general.cta.cancel)}
                  </SwLink>
                </SwActionGroup>
                <SwFormSubmitMessage />
              </SwFormGrid>
            </SwForm>
          );
        }}
      />
    </>
  );
};
