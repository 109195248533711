import { WorkerContractStatus, WorkerContractType } from '@kaa/api/providers';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  Icon,
  SwBadge,
  SwColumn,
  SwFormColumn,
  SwFormGrid,
  SwFormMessageLabel,
  SwInputCalendarField,
  SwInputCalendarFieldFormatDate,
  SwInputCalendarFieldParseDate,
  SwInputTextField,
  SwSelectField,
  useTooltip,
} from '@kaa/ui-flanders/components';
import { Field } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContractFormFieldNames } from './ContractForm.constant';
import { getFieldName } from './ContractForm.utils';

export const ContractForm = () => {
  const { t } = useTranslation();
  const fieldNamePrefix = '';

  const workStatusOptions = Object.values(WorkerContractStatus).map(
    (value) => ({
      text: t(i18nKeys.worker.details.contract.status[value]),
      value,
    }),
  );

  const contractTypeOptions = Object.values(WorkerContractType).map(
    (value) => ({
      text: t(i18nKeys.worker.details.contract.type[value]),
      value,
    }),
  );

  const refContactForm = useTooltip({
    value: t(i18nKeys.contract.modal.addContract.tooltips.unitNumber),
    placement: 'auto',
  });

  return (
    <>
      <SwFormGrid modStacked>
        <Field
          name={getFieldName(
            ContractFormFieldNames.WORK_STATUS,
            fieldNamePrefix,
          )}
          column={{ width: '12' }}
          labelColumn={{ width: '12' }}
          label={t(i18nKeys.contract.modal.addContract.form.label.workStatus)}
          component={SwSelectField}
          options={workStatusOptions}
        />
        <Field
          name={getFieldName(ContractFormFieldNames.TYPE, fieldNamePrefix)}
          column={{ width: '12' }}
          labelColumn={{ width: '12' }}
          label={t(i18nKeys.contract.modal.addContract.form.label.contractType)}
          component={SwSelectField}
          options={contractTypeOptions}
        />

        <SwFormColumn>
          <SwFormMessageLabel
            htmlFor={getFieldName(
              ContractFormFieldNames.UNIT_NUMBER,
              fieldNamePrefix,
            )}
          >
            {t(i18nKeys.contract.modal.addContract.form.label.unitNumber)}{' '}
            <SwBadge
              modAction
              modXsmall
              icon={Icon.INFO}
              style={{ marginTop: '-.5rem', verticalAlign: 'middle' }}
              ref={refContactForm}
            />
          </SwFormMessageLabel>
        </SwFormColumn>
        <SwFormColumn style={{ marginTop: 0 }}>
          <Field
            name={getFieldName(
              ContractFormFieldNames.UNIT_NUMBER,
              fieldNamePrefix,
            )}
            column={{ width: '12' }}
            labelColumn={{ width: '12' }}
            component={SwInputTextField}
          />
        </SwFormColumn>

        <SwColumn>
          <fieldset>
            <legend className="vl-form__label" style={{ lineHeight: '1.5em' }}>
              {t(i18nKeys.contract.modal.addContract.form.label.period)}
            </legend>
            <SwFormGrid modStacked>
              <SwColumn width="6" widthS="12">
                <Field
                  column={{ width: '12' }}
                  labelColumn={{ width: '12' }}
                  name={getFieldName(
                    ContractFormFieldNames.START_DATE,
                    fieldNamePrefix,
                  )}
                  visualFormat="d/m/Y"
                  placeholder={t(i18nKeys.general.placeholder.datePicker.start)}
                  component={SwInputCalendarField}
                  parse={SwInputCalendarFieldParseDate}
                  format={SwInputCalendarFieldFormatDate}
                />
              </SwColumn>
              <SwColumn width="6" widthS="12">
                <Field
                  column={{ width: '12' }}
                  labelColumn={{ width: '12' }}
                  name={getFieldName(
                    ContractFormFieldNames.END_DATE,
                    fieldNamePrefix,
                  )}
                  visualFormat="d/m/Y"
                  placeholder={t(i18nKeys.general.placeholder.datePicker.end)}
                  component={SwInputCalendarField}
                  parse={SwInputCalendarFieldParseDate}
                  format={SwInputCalendarFieldFormatDate}
                />
              </SwColumn>
            </SwFormGrid>
          </fieldset>
        </SwColumn>
      </SwFormGrid>
    </>
  );
};
