import {
  FileExtensionReference,
  RemittanceWithTotalQuantities,
} from '@kaa/api/providers';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  Icon,
  SwColumn,
  SwDataTable,
  SwGrid,
  SwIcon,
} from '@kaa/ui-flanders/components';
import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { TableHead, TableRowNoResult } from '../../../../components';
import { Modals } from '../../../../constants';
import { RemittancesSearchHistoryTableRow } from './RemittancesSearchHistoryTableRow';

type RemittancesSearchHistoryTableProps = {
  remittances: RemittanceWithTotalQuantities[];
  openRemittanceModal: (
    remittance: RemittanceWithTotalQuantities,
    modalId: Modals,
    fileExtension?: FileExtensionReference,
  ) => void;
};

export const RemittancesSearchHistoryTable = ({
  remittances,
  openRemittanceModal,
}: RemittancesSearchHistoryTableProps) => {
  const { t } = useTranslation();

  const head = [
    { text: t(i18nKeys.remittances.general.number) },
    {
      text: t(i18nKeys.remittances.general.reference),
      style: { whiteSpace: 'normal', width: '10rem' } as CSSProperties,
    },
    { text: t(i18nKeys.remittances.general.monthPrestation) },

    { text: t(i18nKeys.remittances.general.type) },

    { text: t(i18nKeys.remittances.general.reimbursementCenter) },

    { text: t(i18nKeys.remittances.general.arrivalDate) },
    {
      text: t(i18nKeys.remittances.general.vouchers),
      className: 'vl-u-align-center',
      style: { whiteSpace: 'normal', minWidth: '35rem' } as CSSProperties,
      children: (
        <SwGrid style={{ margin: 0 }}>
          <SwColumn
            width="3"
            style={{ padding: 0 }}
            className="vl-u-text--ellipse"
          >
            {t(i18nKeys.remittances.general.announced)}
          </SwColumn>
          <SwColumn
            width="3"
            style={{ padding: 0 }}
            className="vl-u-text--ellipse"
          >
            {t(i18nKeys.remittances.general.toCorrect)}
          </SwColumn>
          <SwColumn
            width="3"
            style={{ padding: 0 }}
            className="vl-u-text--ellipse"
          >
            {t(i18nKeys.remittances.general.refused)}
          </SwColumn>
          <SwColumn
            width="3"
            style={{ padding: 0 }}
            className="vl-u-text--ellipse"
          >
            {t(i18nKeys.remittances.general.reimbursed)}
          </SwColumn>
        </SwGrid>
      ),
    },
    {
      text: '',
      style: {
        whiteSpace: 'normal',
        verticalAlign: 'middle',
      } as CSSProperties,
      children: (
        <SwIcon
          icon={Icon.FILE_DOWNLOAD}
          style={{
            fontSize: '2rem',
            marginRight: '0.5rem',
          }}
        />
      ),
    },
  ];

  return (
    <div className="vl-u-table-overflow vl-u-spacer--medium">
      <SwDataTable modLine>
        <TableHead elements={head} />
        <tbody>
          {!remittances || !remittances.length ? (
            <TableRowNoResult colSpan={head.length} />
          ) : (
            remittances.map((remittance) => (
              <RemittancesSearchHistoryTableRow
                remittance={remittance}
                key={remittance.id}
                openRemittanceModal={openRemittanceModal}
              />
            ))
          )}
        </tbody>
      </SwDataTable>
    </div>
  );
};
