/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */

export type ServiceInactiveStatus =
  | 'CANCELED'
  | 'TO_BE_PAID_OUTSIDE_SV'
  | 'REFUND_REQUESTED'
  | 'PARTIALLY_REFUNDED_TO_BE_PAID_OUTSIDE_SV';

export const ServiceInactiveStatus = {
  CANCELED: 'CANCELED' as ServiceInactiveStatus,
  TO_BE_PAID_OUTSIDE_SV: 'TO_BE_PAID_OUTSIDE_SV' as ServiceInactiveStatus,
  REFUND_REQUESTED: 'REFUND_REQUESTED' as ServiceInactiveStatus,
  PARTIALLY_REFUNDED_TO_BE_PAID_OUTSIDE_SV: 'PARTIALLY_REFUNDED_TO_BE_PAID_OUTSIDE_SV' as ServiceInactiveStatus,
};
