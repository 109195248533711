export enum ContactPersonsFormFieldNames {
  TITLE_CODE = 'titleCode',
  FIRSTNAME = 'firstName',
  LASTNAME = 'lastName',
  ROLE = 'role',
  PHONE_NUMBER = 'phoneNumber',
  MOBILE_PHONE_NUMBER = 'mobilePhoneNumber',
  EMAIL_ADDRESS = 'emailAddress',
  LANGUAGE = 'language',
}
