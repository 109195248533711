/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */

export type CodeError =
  | 'ENTITY_NOT_FOUND'
  | 'INVALID_REQUEST_SYNTAX'
  | 'VALIDATION_EXCEPTION'
  | 'CLIENT_NOT_AUTHENTICATED'
  | 'INTERNAL_SERVER_ERROR'
  | 'EXTERNAL_SERVICE_ERROR'
  | 'INVALID_FILE_FORMAT'
  | 'NO_VALID_CONTRACT'
  | 'BUSINESS_SERVICE_ERROR';

export const CodeError = {
  ENTITY_NOT_FOUND: 'ENTITY_NOT_FOUND' as CodeError,
  INVALID_REQUEST_SYNTAX: 'INVALID_REQUEST_SYNTAX' as CodeError,
  VALIDATION_EXCEPTION: 'VALIDATION_EXCEPTION' as CodeError,
  CLIENT_NOT_AUTHENTICATED: 'CLIENT_NOT_AUTHENTICATED' as CodeError,
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR' as CodeError,
  EXTERNAL_SERVICE_ERROR: 'EXTERNAL_SERVICE_ERROR' as CodeError,
  INVALID_FILE_FORMAT: 'INVALID_FILE_FORMAT' as CodeError,
  NO_VALID_CONTRACT: 'NO_VALID_CONTRACT' as CodeError,
  BUSINESS_SERVICE_ERROR: 'BUSINESS_SERVICE_ERROR' as CodeError,
};
