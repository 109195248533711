/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */

export type LegalFormCode =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23
  | 24
  | 30;

export const LegalFormCode = {
  NUMBER_0: 0 as LegalFormCode,
  NUMBER_1: 1 as LegalFormCode,
  NUMBER_2: 2 as LegalFormCode,
  NUMBER_3: 3 as LegalFormCode,
  NUMBER_4: 4 as LegalFormCode,
  NUMBER_5: 5 as LegalFormCode,
  NUMBER_6: 6 as LegalFormCode,
  NUMBER_7: 7 as LegalFormCode,
  NUMBER_8: 8 as LegalFormCode,
  NUMBER_9: 9 as LegalFormCode,
  NUMBER_10: 10 as LegalFormCode,
  NUMBER_11: 11 as LegalFormCode,
  NUMBER_12: 12 as LegalFormCode,
  NUMBER_13: 13 as LegalFormCode,
  NUMBER_14: 14 as LegalFormCode,
  NUMBER_15: 15 as LegalFormCode,
  NUMBER_16: 16 as LegalFormCode,
  NUMBER_17: 17 as LegalFormCode,
  NUMBER_18: 18 as LegalFormCode,
  NUMBER_19: 19 as LegalFormCode,
  NUMBER_20: 20 as LegalFormCode,
  NUMBER_21: 21 as LegalFormCode,
  NUMBER_22: 22 as LegalFormCode,
  NUMBER_23: 23 as LegalFormCode,
  NUMBER_24: 24 as LegalFormCode,
  NUMBER_30: 30 as LegalFormCode,
};
