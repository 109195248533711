import { RegionCode } from '@kaa/api/providers';
import { useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  Icon,
  SwButton,
  SwDescriptionDataItem,
  SwLoader,
} from '@kaa/ui-flanders/components';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../../utils';
import { DashboardOverviewProps } from '../Dashboard.types';
import { overviewCardChildrenProps } from '../Dashboard.utils';
import { DashboardOverviewCard } from './components/DashboardOverviewCard';
import { DashboardOverviewCardLabel } from './components/DashboardOverviewCardLabel';

export const DashboardOverviewWorks = ({
  providerId,
  regionCode,
}: DashboardOverviewProps) => {
  const { t } = useTranslation();
  const { providers } = useApi();

  const [
    { value, loading, error },
    getDashboardServicesOverview,
  ] = useAsyncCallback(
    async () =>
      (await providers.getDashboardServicesOverview(providerId)).data.data,
    [providers],
    { loading: true },
  );

  useEffect(() => {
    getDashboardServicesOverview();
  }, [getDashboardServicesOverview]);

  const overviewCardProps = {
    title: t(i18nKeys.dashboard.overview.works.title),
    icon: regionCode === RegionCode.BE_VLG ? Icon.FILE_NEW : Icon.WORKSADD,
  };

  if (loading && !value) {
    return (
      <DashboardOverviewCard {...overviewCardProps}>
        <SwDescriptionDataItem
          {...overviewCardChildrenProps}
          label={<SwLoader modMessageHidden />}
          subdata={t(i18nKeys.dashboard.overview.works.validated)}
        />
        <SwDescriptionDataItem
          {...overviewCardChildrenProps}
          label={<SwLoader modMessageHidden />}
          subdata={t(i18nKeys.dashboard.overview.works.waitingRemittance)}
        />
        <SwDescriptionDataItem
          {...overviewCardChildrenProps}
          label={<SwLoader modMessageHidden />}
          subdata={t(i18nKeys.dashboard.overview.works.contested)}
        />
      </DashboardOverviewCard>
    );
  }

  if (error || !value) {
    return (
      <DashboardOverviewCard
        {...overviewCardProps}
        footer={
          <SwButton onClick={getDashboardServicesOverview}>
            {t(i18nKeys.errors.serviceUnavailableScreen.cta)}
          </SwButton>
        }
      >
        <p>{t(i18nKeys.errors.serviceUnavailableScreen.title)}</p>
      </DashboardOverviewCard>
    );
  }

  const { validated, waitingRemittance, contested } = value;

  return (
    <DashboardOverviewCard {...overviewCardProps}>
      <SwDescriptionDataItem
        {...overviewCardChildrenProps}
        label={<DashboardOverviewCardLabel text={validated} />}
        subdata={t(i18nKeys.dashboard.overview.works.validated)}
      />
      <SwDescriptionDataItem
        {...overviewCardChildrenProps}
        label={<DashboardOverviewCardLabel text={waitingRemittance} />}
        subdata={t(i18nKeys.dashboard.overview.works.waitingRemittance)}
      />
      <SwDescriptionDataItem
        {...overviewCardChildrenProps}
        label={<DashboardOverviewCardLabel text={contested} />}
        subdata={t(i18nKeys.dashboard.overview.works.contested)}
      />
    </DashboardOverviewCard>
  );
};
