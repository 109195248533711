import { Sorting } from '@kaa/api/providers';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import { TFunction } from 'i18next';

export const getLabelForSorting = (
  def: Sorting,
  current: Sorting | undefined,
  t: TFunction,
) => {
  if (current === def) {
    switch (current) {
      case Sorting.DATE_ASC:
        return t(i18nKeys.general.label.sortDates.DESC);
      case Sorting.DATE_DESC:
        return t(i18nKeys.general.label.sortDates.ASC);
      case Sorting.CREATION_DATE_ASC:
        return t(i18nKeys.general.label.sortCreationDates.DESC);
      case Sorting.CREATION_DATE_DESC:
        return t(i18nKeys.general.label.sortCreationDates.ASC);
      case Sorting.POSTCODE_ASC:
        return t(i18nKeys.general.label.sortPostCode.DESC);
      case Sorting.POSTCODE_DESC:
        return t(i18nKeys.general.label.sortPostCode.ASC);
      case Sorting.NAME_ASC:
      case Sorting.WORKER_ASC:
      case Sorting.CUSTOMER_ASC:
        return t(i18nKeys.general.label.sortNames.DESC);
      case Sorting.NAME_DESC:
      case Sorting.WORKER_DESC:
      case Sorting.CUSTOMER_DESC:
        return t(i18nKeys.general.label.sortNames.ASC);
      case Sorting.REFUND_CENTER_ASC:
        return t(i18nKeys.remittances.reimbursement.sort.REFUND_CENTER_DESC);
      case Sorting.REFUND_CENTER_DESC:
        return t(i18nKeys.remittances.reimbursement.sort.REFUND_CENTER_ASC);
      case Sorting.MONTH_OF_WORK_ASC:
        return t(i18nKeys.remittances.reimbursement.sort.MONTH_OF_WORK_DESC);
      case Sorting.MONTH_OF_WORK_DESC:
        return t(i18nKeys.remittances.reimbursement.sort.MONTH_OF_WORK_ASC);
      default:
        return undefined;
    }
  }

  switch (def) {
    case Sorting.DATE_ASC:
      return t(i18nKeys.general.label.sortDates.ASC);
    case Sorting.DATE_DESC:
      return t(i18nKeys.general.label.sortDates.DESC);
    case Sorting.CREATION_DATE_ASC:
      return t(i18nKeys.general.label.sortCreationDates.ASC);
    case Sorting.CREATION_DATE_DESC:
      return t(i18nKeys.general.label.sortCreationDates.DESC);
    case Sorting.POSTCODE_ASC:
      return t(i18nKeys.general.label.sortPostCode.ASC);
    case Sorting.POSTCODE_DESC:
      return t(i18nKeys.general.label.sortPostCode.DESC);
    case Sorting.NAME_ASC:
    case Sorting.WORKER_ASC:
    case Sorting.CUSTOMER_ASC:
      return t(i18nKeys.general.label.sortNames.ASC);
    case Sorting.NAME_DESC:
    case Sorting.WORKER_DESC:
    case Sorting.CUSTOMER_DESC:
      return t(i18nKeys.general.label.sortNames.DESC);
    case Sorting.REFUND_CENTER_ASC:
      return t(i18nKeys.remittances.reimbursement.sort.REFUND_CENTER_ASC);
    case Sorting.REFUND_CENTER_DESC:
      return t(i18nKeys.remittances.reimbursement.sort.REFUND_CENTER_DESC);
    case Sorting.MONTH_OF_WORK_ASC:
      return t(i18nKeys.remittances.reimbursement.sort.MONTH_OF_WORK_ASC);
    case Sorting.MONTH_OF_WORK_DESC:
      return t(i18nKeys.remittances.reimbursement.sort.MONTH_OF_WORK_DESC);
    default:
      return undefined;
  }
};
