import {
  ExportServicesParams,
  FileExtensionReference,
  ServiceStatus,
} from '@kaa/api/providers';
import { httpTo } from '@kaa/api/providers/utilities';
import { useAsyncCallback, useLuxon } from '@kaa/common/utils';
import { required } from '@kaa/common/validation';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AlertType,
  SwActionGroup,
  SwButton,
  SwForm,
  SwFormGrid,
  SwFormSubmitMessage,
  SwInputCalendarField,
  SwInputCalendarFieldFormatDate,
  SwInputCalendarFieldParseDate,
  SwSelectField,
  SwTitle,
} from '@kaa/ui-flanders/components';
import { Field, Formik, FormikActions } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi, useSelectedProviderIdState } from '../../../../utils';
import {
  createValidatorWithServerErrorHandled,
  handleApiError,
} from '../../../../utils/validation';
import { FormExportService } from '../../PrestationsScreen.types';
import {
  getMaxEndDate,
  getMaxStartDate,
  getMinEndDate,
  getMinStartDate,
} from '../PrestationsForm.utils';
import { ExportModalFieldNames } from './PrestationExportModal.constants';
import { PrestationExportModalConfirmation } from './PrestationExportModalConfirmation';

const ALL_EXCEPT_FULLY_IN_REMITTANCE = 'ALL_EXCEPT_FULLY_IN_REMITTANCE';

const statusArray = Object.values(ServiceStatus);

export const PrestationExportModal = () => {
  const { t } = useTranslation();
  const { DateTime } = useLuxon();
  const providerId = useSelectedProviderIdState();
  const { providers } = useApi();

  const [{ value: submitedForm }, submit] = useAsyncCallback(
    async (
      formikData: FormExportService,
      formikActions: FormikActions<FormExportService>,
    ) => {
      const data = (formikData.status === ALL_EXCEPT_FULLY_IN_REMITTANCE
        ? {
            ...formikData,
            status: statusArray.filter(
              // All status without REFUND_REQUESTED in the array
              (status) => status !== ServiceStatus.REFUND_REQUESTED,
            ),
          }
        : {
            ...formikData,
            status: [formikData.status],
          }) as ExportServicesParams;

      const [error, response] = await httpTo(
        providers.exportServices(providerId, data),
      );

      return {
        error,
        response,
        formikData,
        formikActions,
      };
    },
    [providers],
  );

  useEffect(() => {
    if (submitedForm) {
      const { error, formikActions, response } = submitedForm;
      const { setSubmitting, setStatus } = formikActions;

      if (handleApiError(error, formikActions)) {
        return;
      }

      if (!response) {
        setSubmitting(false);
        setStatus({
          msg: t(i18nKeys.prestations.modals.export.errorMessage),
          type: AlertType.ERROR,
        });
      }
    }
  }, [submitedForm]);

  if (submitedForm && submitedForm.response) {
    return (
      <PrestationExportModalConfirmation
        data={submitedForm.response.data}
        fileExtension={submitedForm.formikData.fileExtension}
      />
    );
  }

  const options = [[ALL_EXCEPT_FULLY_IN_REMITTANCE], ...statusArray].map(
    (status) => ({
      value: status,
      text: t(
        i18nKeys.general.serviceStatus[
          status as keyof typeof i18nKeys.general.serviceStatus
        ],
      ),
    }),
  );

  const initialValues = {
    startDate: '',
    endDate: '',
    status: ALL_EXCEPT_FULLY_IN_REMITTANCE,
    fileExtension: FileExtensionReference.CSV,
  };

  const validate = createValidatorWithServerErrorHandled<FormExportService>({
    startDate: [required],
    endDate: [required],
    status: [required],
    fileExtension: [required],
  });

  return (
    <>
      <SwTitle tagName="h2" className="vl-u-spacer--medium">
        {t(i18nKeys.prestations.modals.export.title)}
      </SwTitle>
      <Formik<FormExportService>
        onSubmit={submit}
        initialValues={initialValues}
        validate={validate}
      >
        {({ handleSubmit, isSubmitting, values }) => {
          return (
            <SwForm onSubmit={handleSubmit}>
              <SwFormGrid modStacked className="vl-u-spacer">
                <Field
                  name={ExportModalFieldNames.STATUS}
                  label={t(
                    i18nKeys.prestations.modals.export.form.labels.status,
                  )}
                  options={options}
                  component={SwSelectField}
                  column={{ width: '12' }}
                  labelColumn={{ width: '12' }}
                  modNoPlaceholder
                />
                <Field
                  name={ExportModalFieldNames.START_DATE}
                  label={t(i18nKeys.general.label.periodFrom)}
                  component={SwInputCalendarField}
                  parse={SwInputCalendarFieldParseDate}
                  format={SwInputCalendarFieldFormatDate}
                  minDate={getMinStartDate(values)}
                  maxDate={getMaxStartDate(values)}
                  column={{ width: '12' }}
                  labelColumn={{ width: '12' }}
                  placeholder={t(i18nKeys.general.placeholder.datePicker.start)}
                />
                <Field
                  name={ExportModalFieldNames.END_DATE}
                  label={t(i18nKeys.general.label.periodTo)}
                  component={SwInputCalendarField}
                  parse={SwInputCalendarFieldParseDate}
                  format={SwInputCalendarFieldFormatDate}
                  minDate={getMinEndDate(values)}
                  maxDate={getMaxEndDate(values)}
                  column={{ width: '12' }}
                  labelColumn={{ width: '12' }}
                  placeholder={t(i18nKeys.general.placeholder.datePicker.end)}
                />
                <Field
                  name={ExportModalFieldNames.FILE_EXTENSION}
                  label={t(
                    i18nKeys.prestations.modals.export.form.labels.format,
                  )}
                  options={[
                    {
                      value: FileExtensionReference.XLSX,
                      text: `.${FileExtensionReference.XLSX.toLowerCase()}`,
                    },
                    {
                      value: FileExtensionReference.CSV,
                      text: `.${FileExtensionReference.CSV.toLowerCase()}`,
                    },
                  ]}
                  component={SwSelectField}
                  column={{ width: '12' }}
                  labelColumn={{ width: '12' }}
                  modNoPlaceholder
                />
                <SwActionGroup>
                  <SwButton type="submit" modLoading={isSubmitting}>
                    {t(i18nKeys.general.cta.validate)}
                  </SwButton>
                </SwActionGroup>
                <SwFormSubmitMessage />
              </SwFormGrid>
            </SwForm>
          );
        }}
      </Formik>
    </>
  );
};
