import { Pickup, PickupType } from '@kaa/api/providers';
import { httpTo } from '@kaa/api/providers/utilities';
import { useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AlertType,
  SwButton,
  SwLink,
  SwModalRenderProps,
  SwTitle,
  toggleModalById,
} from '@kaa/ui-flanders/components';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modals } from '../../../../constants';
import { useApi } from '../../../../utils';
import { onSubmitStatus } from '../PickupModals.constants';

type PickupResumeModalProps = {
  providerId: number;
  pickup: Pickup;
  onSubmit: ({ msg, type }: onSubmitStatus) => void;
};

export const PickupResumeModal = ({
  providerId,
  pickup,
  onSubmit,
}: SwModalRenderProps<PickupResumeModalProps>) => {
  const { t } = useTranslation();
  const { providers } = useApi();

  const isRecurrent = pickup.type === PickupType.RECURRENT;

  const [
    { value: resumeOccurrenceResponse },
    resumeOccurrence,
  ] = useAsyncCallback(async () => {
    const [error, response] = await httpTo(
      providers.resumeOccurrence(
        providerId,
        pickup.id,
        isRecurrent
          ? {
              pickupDate: pickup.date,
            }
          : undefined,
      ),
    );
    return { error, response };
  }, [providers]);

  useEffect(() => {
    if (resumeOccurrenceResponse) {
      const { error } = resumeOccurrenceResponse;

      if (error) {
        onSubmit({
          type: AlertType.ERROR,
          msg: t(i18nKeys.errors.server.DEFAULT),
        });
      } else {
        onSubmit({
          type: AlertType.SUCCESS,
          msg: t(i18nKeys.pickup.alert.confirm.resume),
        });
      }

      toggleModalById(Modals.PICKUP_RESUME_PICKUP_MODAL);
    }
  }, [resumeOccurrenceResponse]);

  return (
    <>
      <SwTitle tagName="h2">{t(i18nKeys.pickup.modal.resume.title)}</SwTitle>
      <p className="vl-u-spacer">{t(i18nKeys.pickup.modal.resume.text)}</p>
      <SwButton
        onClick={(e) => {
          e.preventDefault();
          resumeOccurrence();
        }}
      >
        {t(i18nKeys.pickup.modal.resume.cta)}
      </SwButton>
      <SwLink
        style={{ marginLeft: '2rem' }}
        onClick={() => {
          toggleModalById(Modals.PICKUP_RESUME_PICKUP_MODAL);
        }}
      >
        {t(i18nKeys.general.cta.cancel)}
      </SwLink>
    </>
  );
};
