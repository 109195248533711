import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsyncCallback } from '@kaa/common/utils';
import get from 'lodash.get';
import { RouterProps } from '@reach/router';
import {
  VideosResponse,
  VideoResponse,
  VideoTopicReference,
  LanguageCode,
} from '@kaa/api/providers';
import {
  SwContainer,
  SwFetchErrorMessage,
  SwGrid,
  SwColumn,
  SwTitle,
  SwIntroduction,
  SwIcon,
  Icon,
} from '@kaa/ui-flanders/components';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import { ContainerLoadingPageHeader, PageHeader } from '../../components';
import { useApi } from '../../utils';
import { getConfig } from '../../../common/config';

const groupBy = (videos: VideosResponse['data']) => {
  const groupedArray = {} as Record<VideoTopicReference, VideoResponse[]>;
  videos.forEach((video) => {
    if (groupedArray[video.topic]) {
      groupedArray[video.topic].push(video as VideoResponse);
    } else {
      groupedArray[video.topic] = [video as VideoResponse];
    }
  });
  return groupedArray;
};

export const VideosScreen = ({ location }: RouterProps) => {
  const { t, i18n } = useTranslation();
  const { providers: providersApi } = useApi();
  const [videoStreams, setVideoStreams] = React.useState<
    Record<string, string>
  >({});
  const [
    { value: videosData, loading, error },
    getVideosData,
  ] = useAsyncCallback(
    async () =>
      providersApi.getVideos({
        language: i18n.language.toUpperCase() as LanguageCode,
      }),
    [],
    {
      loading: true,
    },
  );

  useEffect(() => {
    getVideosData();
  }, [getVideosData]);
  useEffect(() => {
    if (location?.hash && !loading) {
      const element = document.getElementById(location.hash.replace('#', ''));
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [location?.hash, loading]);
  const kaaConfig = getConfig();
  const baseUrl = get(kaaConfig, 'app.apiUrl');

  const startStreaming = async (id: VideoResponse['id']) => {
    try {
      const resp = await providersApi.getVideoStreamId(id);
      setVideoStreams((oldVideoStreams) => ({
        ...oldVideoStreams,
        [id]: `${baseUrl}/v1/videos/${resp.data.data}/play`,
      }));
    } catch (error) {
      setVideoStreams((oldVideoStreams) => ({
        ...oldVideoStreams,
        [id]: `error`,
      }));
    }
  };

  if (!videosData && loading) {
    return (
      <ContainerLoadingPageHeader
        title={t(i18nKeys.tutorialVideos.title)}
        introduction={t(i18nKeys.tutorialVideos.introduction)}
      />
    );
  }

  if (error || !videosData) {
    return (
      <SwContainer error>
        <SwFetchErrorMessage onClick={getVideosData} />
      </SwContainer>
    );
  }
  return (
    <SwContainer>
      <SwGrid modStacked>
        <PageHeader
          title={t(i18nKeys.tutorialVideos.title)}
          introduction={t(i18nKeys.tutorialVideos.introduction)}
        />
      </SwGrid>
      <SwGrid modStacked width="10">
        {Object.entries(groupBy(videosData.data.data)).map(
          ([topic, videos]) => {
            return (
              <SwColumn width="10" id={topic} key={topic}>
                <div>
                  {videos.map((video) => (
                    <SwGrid
                      style={{
                        borderBottom: '1px solid rgb(203, 210, 218)',
                        padding: '24px 0',
                      }}
                      key={video.id}
                    >
                      <SwColumn width="6" widthS="12">
                        <SwTitle tagName="h3" className="vl-u-spacer--medium">
                          {t(
                            i18nKeys.tutorialVideos.videoInfo.title[
                              video.title
                            ],
                          )}
                        </SwTitle>
                        {video.description.summary ? (
                          <SwIntroduction
                            className="vl-u-text--small"
                            tagName="p"
                          >
                            {t(
                              i18nKeys.tutorialVideos.videoInfo.summary[
                                video.description.summary
                              ],
                            )}
                          </SwIntroduction>
                        ) : (
                          ''
                        )}
                        {video.description.details ? (
                          <div className="vl-typography vl-u-text--small">
                            <ul>
                              {video.description.details.map((detail) => (
                                <li key={detail}>
                                  {t(
                                    i18nKeys.tutorialVideos.videoInfo.details[
                                      detail
                                    ],
                                  )}
                                </li>
                              ))}
                            </ul>
                          </div>
                        ) : (
                          ''
                        )}
                        {video.description.urlLink ? (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={video.description.urlLink.url}
                          >
                            {t(
                              i18nKeys.tutorialVideos.videoInfo.details[
                                video.description.urlLink.title
                              ],
                            )}
                          </a>
                        ) : (
                          ''
                        )}
                      </SwColumn>
                      <SwColumn width="6" widthS="12">
                        <div style={{ width: '560px', height: '314px' }}>
                          {videoStreams[video.id] ? (
                            <div>
                              {videoStreams[video.id] === 'error' ? (
                                <>
                                  <button
                                    type="button"
                                    aria-label={t(
                                      i18nKeys.tutorialVideos.error
                                        .videoNotLoaded,
                                    )}
                                    onClick={() => startStreaming(video.id)}
                                  >
                                    <SwIcon modLarge icon={Icon.PLAY} />
                                  </button>
                                  <img
                                    width="560"
                                    height="314"
                                    src={`${baseUrl}/v1/videos/${video.id}/preview`}
                                    alt={video.description.summary}
                                  />
                                </>
                              ) : (
                                // eslint-disable-next-line jsx-a11y/media-has-caption
                                <video
                                  width="560"
                                  height="314"
                                  controls
                                  controlsList="nodownload"
                                  autoPlay
                                >
                                  <source
                                    src={videoStreams[video.id]}
                                    type="video/mp4"
                                  />
                                  {t(
                                    i18nKeys.tutorialVideos.error
                                      .videoTagNotSupported,
                                  )}
                                </video>
                              )}
                            </div>
                          ) : (
                            <>
                              <button
                                style={{
                                  transform: 'translate(245px, 125px)',
                                  position: 'absolute',
                                  backgroundColor: 'black',
                                  color: 'white',
                                  fontSize: 'xxx-large',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  padding: '8px',
                                }}
                                type="button"
                                onClick={() => startStreaming(video.id)}
                                aria-label={t(
                                  i18nKeys.tutorialVideos.labels.play,
                                )}
                              >
                                <SwIcon modLarge icon={Icon.PLAY} />
                              </button>
                              <img
                                width="560"
                                height="314"
                                src={`${baseUrl}/v1/videos/${video.id}/preview`}
                                alt={video.description.summary}
                              />
                            </>
                          )}
                        </div>
                      </SwColumn>
                    </SwGrid>
                  ))}
                </div>
              </SwColumn>
            );
          },
        )}
      </SwGrid>
    </SwContainer>
  );
};
