import { RegionCode } from '@kaa/api/providers';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import { SwColumn, SwGrid, SwTitle, Icon } from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getTipsFirstRow, getTipsSecondRow } from '../Dashboard.utils';
import { DashboardTipsCard } from './components/DashboardTipsCard';

type DashboardTipsProps = {
  regionCode: RegionCode;
};

export const DashboardTips = ({ regionCode }: DashboardTipsProps) => {
  const { t } = useTranslation();
  const tipsFirstRow = getTipsFirstRow(regionCode, t);
  const tipsSecondRow = getTipsSecondRow(regionCode, t);

  return (
    <SwColumn>
      <SwTitle tagName="h2" className="vl-u-spacer--medium">
        {t(i18nKeys.dashboard.tips.title)}
      </SwTitle>
      <SwGrid
        style={{
          width: '100%',
          justifyContent: 'flex-start',
          paddingBottom: '20px',
        }}
      >
        {tipsFirstRow.map((props, i) => (
          <DashboardTipsCard {...props} key={`tips-${i + 1}`} />
        ))}
      </SwGrid>
      <SwGrid style={{ width: '100%', justifyContent: 'flex-start' }}>
        {tipsSecondRow.map((props, i) => (
          <DashboardTipsCard {...props} key={`tips-${i + 1}`} />
        ))}
      </SwGrid>
    </SwColumn>
  );
};
