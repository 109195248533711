import { Provider } from '@kaa/api/providers';
import { httpTo } from '@kaa/api/providers/utilities';
import { useAsyncCallback } from '@kaa/common/utils';
import { equalTo } from '@kaa/common/validation';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AlertType,
  getAlertPropsByType,
  SwActionGroup,
  SwAlert,
  SwButton,
  SwColumn,
  SwForm,
  SwFormSubmitMessage,
  SwGrid,
  SwInputTextField,
  SwModalRenderProps,
  SwTitle,
} from '@kaa/ui-flanders/components';
import { Field, Formik } from 'formik';
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useApi } from '../../../../../../utils';
import {
  EventAction,
  EventCategory,
  EventLabel,
  sendCustomInteractionToGTM,
} from '../../../../../../utils/google-analytics';
import {
  createValidatorWithServerErrorHandled,
  handleApiError,
} from '../../../../../../utils/validation';

const mandatoryInput = 'DELETE';

type ConfirmDisableRelativeModalProps = {
  relative: Provider;
  onConfirm: () => void;
};

export const ConfirmDisableRelativeModal = ({
  relative,
  onConfirm,
  setConfirmCloseModal,
}: SwModalRenderProps<ConfirmDisableRelativeModalProps>) => {
  const { t } = useTranslation();
  const { providers } = useApi();

  const [{ value: submitedForm }, submit] = useAsyncCallback(
    async (formikData, formikActions) => {
      const [error, response] = await httpTo(
        providers.deactivateProviderMember(relative.id),
      );

      return { error, response, formikData, formikActions };
    },
    [relative, onConfirm, providers],
  );

  useEffect(() => {
    if (submitedForm) {
      const { error, formikActions } = submitedForm;
      const { setSubmitting } = formikActions;
      if (handleApiError(error, formikActions)) {
        return;
      }

      setSubmitting(false);
      onConfirm();
      sendCustomInteractionToGTM(
        EventCategory.COMPANY_MANAGEMENT,
        EventAction.DISABLE,
        EventLabel.RELATIVE,
      );
    }
  }, [submitedForm]);

  const validate = createValidatorWithServerErrorHandled({
    confirmField: [equalTo(mandatoryInput)],
  });

  return (
    <>
      <SwTitle tagName="h2">
        {t(
          i18nKeys.headquarterAndAffiliateManagement.deactivateRelativeModal
            .title,
          {
            relativeName: relative.name,
          },
        )}
      </SwTitle>

      <SwAlert
        {...getAlertPropsByType(AlertType.WARNING)}
        className="vl-u-spacer--medium"
        title={t(
          i18nKeys.headquarterAndAffiliateManagement.deactivateRelativeModal
            .subtitle,
          { relativeName: relative.name },
        )}
      >
        {t(
          i18nKeys.headquarterAndAffiliateManagement.deactivateRelativeModal
            .description,
          {
            relativeName: relative.name,
          },
        )}
      </SwAlert>

      <p>
        <Trans
          i18nKey={
            i18nKeys.headquarterAndAffiliateManagement.deactivateRelativeModal
              .explanation
          }
          values={{ mandatoryInput }}
        >
          Please type <strong>&quot;{mandatoryInput}&quot;</strong> to confirm
          in the field below.
        </Trans>
      </p>

      <Formik
        initialValues={{ confirmField: '' }}
        validate={validate}
        onSubmit={submit}
        render={({ handleSubmit, dirty, isSubmitting }) => {
          setConfirmCloseModal(dirty);
          return (
            <SwForm onSubmit={handleSubmit}>
              <SwGrid modStacked>
                <SwColumn>
                  <Field
                    name="confirmField"
                    component={SwInputTextField}
                    column={{ width: '12' }}
                  />
                  <SwActionGroup>
                    <SwButton
                      type="submit"
                      modLarge
                      modLoading={isSubmitting}
                      modDisabled={!dirty}
                    >
                      {t(
                        i18nKeys.headquarterAndAffiliateManagement
                          .deactivateRelativeModal.submit,
                        {
                          relativeName: relative.name,
                        },
                      )}
                    </SwButton>
                  </SwActionGroup>
                </SwColumn>
                <SwFormSubmitMessage />
              </SwGrid>
            </SwForm>
          );
        }}
      />
    </>
  );
};
