import {
  SwBadge,
  SwColumn,
  SwInfoTile,
  SwLink,
} from '@kaa/ui-flanders/components';
import React from 'react';
import {
  DashboardActionEvent,
  sendDashboardActionEventToGTM,
} from '../../../../utils/google-analytics';
import { DashboardTips } from '../../Dashboard.types';

export const DashboardTipsCard = ({
  title,
  icon,
  subtitle,
  link: { label, url },
}: DashboardTips) => (
  <SwColumn width="3" widthM="6" widthS="12" style={{ display: 'flex' }}>
    <SwInfoTile
      title={title}
      tagName="div"
      badge={<SwBadge modBorder icon={icon} />}
      subtitle={
        <>
          <span className="vl-u-spacer--xsmall" style={{ display: 'block' }}>
            {subtitle}
          </span>
          <SwLink
            to={url}
            target="_blank"
            onClick={() => {
              sendDashboardActionEventToGTM(
                DashboardActionEvent.CLICK_TIPS,
                title,
              );
            }}
          >
            {label}
          </SwLink>
        </>
      }
    />
  </SwColumn>
);
