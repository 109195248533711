import { i18nKeys } from '@kaa/i18n/providers/keys';
import { useAsyncCallback } from '@kaa/common/utils';
import { LanguageCode } from '@kaa/api/providers';
import { SwColumn, SwGrid, SwTitle } from '@kaa/ui-flanders/components';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DashboardActionsProps } from '../Dashboard.types';
import { DashboardActionsFinancialContact } from './DashboardActionsFinancialContact';
import { DashboardActionsRemittances } from './DashboardActionsRemittances';
import { DashboardActionsWorkers } from './DashboardActionsWorkers';
import { DashboardActionsWorks } from './DashboardActionsWorks';

import { useApi, useSelectedProviderIdState } from '../../../utils';

export const DashboardActions = (props: DashboardActionsProps) => {
  const { t, i18n } = useTranslation();

  const { providers } = useApi();
  const providerId = useSelectedProviderIdState();

  const [
    {
      value: providerBillingConfigurationResponse,
      loading: providerBillingConfigurationLoading,
    },
    getProviderBillingConfiguration,
  ] = useAsyncCallback(
    async () => {
      const {
        data: {
          data: { financialContact },
        },
      } = await providers.getProviderBillingConfiguration(providerId);
      return { financialContact };
    },
    [providers],
    { loading: true },
  );

  useEffect(() => {
    getProviderBillingConfiguration();
  }, [getProviderBillingConfiguration]);

  return (
    <SwColumn className="vl-u-spacer">
      <SwTitle tagName="h2" className="vl-u-spacer--medium">
        {t(i18nKeys.dashboard.actions.title)}
      </SwTitle>
      <SwGrid
        modVStretched
        style={{ width: '100%', justifyContent: 'flex-start' }}
      >
        <DashboardActionsWorks {...props} />
        <DashboardActionsRemittances {...props} />
        <DashboardActionsWorkers {...props} />
        {providerBillingConfigurationResponse &&
          !providerBillingConfigurationLoading &&
          !providerBillingConfigurationResponse.financialContact
            .emailAddress && <DashboardActionsFinancialContact />}
      </SwGrid>
    </SwColumn>
  );
};
