import { useAuthState } from '@kaa/auth/common';
import { useAsync } from '@kaa/common/utils';
import { SwContainer } from '@kaa/ui-flanders/components';
import { navigate, RouteComponentProps } from '@reach/router';
import React, { FunctionComponent } from 'react';
import { AuthContext } from '../../types';
import { setRedirectUriInSessionStorage } from '../../utils/setRedirectUriInSessionStorage';

type PrivateRouteProps = {
  component: FunctionComponent<RouteComponentProps>;
  unauthenticatedRedirectUrl?: string;
  [key: string]: unknown;
};

export const PrivateRoute = ({
  component: RoutedComponent,
  unauthenticatedRedirectUrl,
  ...rest
}: PrivateRouteProps) => {
  const { getUser, user } = useAuthState<AuthContext>();

  const { loading, value: isAuthenticated } = useAsync(async () => getUser(), [
    getUser,
  ]);

  if (loading) {
    return <SwContainer loading />;
  }

  if (!isAuthenticated) {
    setRedirectUriInSessionStorage();
    if (unauthenticatedRedirectUrl) {
      navigate(unauthenticatedRedirectUrl);
    }
    return <SwContainer loading />;
  }

  if (isAuthenticated && !user) {
    return <SwContainer loading />;
  }

  return <RoutedComponent {...rest} />;
};
