import { POSTCODES_CITIES } from '@kaa/api/common/mocks';
import faker from 'faker';
import {
  City,
  Locations,
  ProviderVisibilityLocations,
  Province,
  ProvinceCode,
  RegionCode,
} from '../model';
import { getArray } from './utils';

const PROVINCE_REGIONS = {
  [ProvinceCode.BEWBR]: RegionCode.BE_WAL,
  [ProvinceCode.BEWHT]: RegionCode.BE_WAL,
  [ProvinceCode.BEWLG]: RegionCode.BE_WAL,
  [ProvinceCode.BEWLX]: RegionCode.BE_WAL,
  [ProvinceCode.BEWNA]: RegionCode.BE_WAL,
  [ProvinceCode.BEVAN]: RegionCode.BE_VLG,
  [ProvinceCode.BEVLI]: RegionCode.BE_VLG,
  [ProvinceCode.BEVBR]: RegionCode.BE_VLG,
  [ProvinceCode.BEVOV]: RegionCode.BE_VLG,
  [ProvinceCode.BEVWV]: RegionCode.BE_VLG,
};

export const getAvailableLocationsResponse = ({
  nbrOfCities = faker.random.number({ min: 200, max: 300 }),
} = {}) => ({
  data: {
    regions: Object.values(RegionCode).map((code) => ({ code })),
    provinces: Object.values(ProvinceCode).map((code) => ({
      code,
      regionCode: PROVINCE_REGIONS[code],
    })),
    cities: getCities({ nbrOfCities }),
  },
});

export const getActiveLocationsResponse = () => ({
  data: getActiveLocations(),
});

export const getLocations = ({
  nbrOfProvinces = faker.random.number({ min: 1, max: 11 }),
  nbrOfCities = faker.random.number(99),
} = {}): Locations => ({
  ...getRegionsAndProvinces(nbrOfProvinces),
  cities: getCities({ nbrOfCities }),
});

export const getActiveLocations = (): ProviderVisibilityLocations => {
  const { cities, provinces, regions } = getLocations();

  return {
    postcodes: cities.map(({ postcode }) => postcode),
    provinces: provinces.map(({ code }) => code),
    regions: regions.map(({ code }) => code),
  };
};

export const getRegionsAndProvinces = (
  nbr = faker.random.number({ min: 1, max: 11 }),
) => {
  const provinces = getProvinces(nbr);
  const regions = (Object.values(RegionCode) as RegionCode[])
    .filter((regionCode) =>
      Object.values(ProvinceCode)
        .filter((provinceCode) => PROVINCE_REGIONS[provinceCode] === regionCode)
        .every((provinceCode) =>
          provinces.some(({ code }) => code === provinceCode),
        ),
    )
    .map((regionCode) => ({ code: regionCode }));
  return {
    regions,
    provinces: provinces.filter(({ regionCode }) =>
      regions.every(({ code }) => code !== regionCode),
    ),
  };
};

export const getProvinces = (
  nbr = faker.random.number({ min: 1, max: 11 }),
): Province[] => {
  const nbrOfProvinces =
    nbr > 11 || nbr <= 0 ? faker.random.number({ min: 1, max: 11 }) : nbr;

  return getArray(nbrOfProvinces).reduce((acc) => {
    const potentialProvinces = Object.values(ProvinceCode).filter(
      (province) =>
        !acc.some(({ code }: { code: string }) => code === province),
    );

    return [...acc, getProvince(potentialProvinces)];
  }, []);
};

export const getProvince = (
  potentialProvinces = Object.values(ProvinceCode),
): Province => {
  const provinceCode = faker.helpers.randomize<ProvinceCode>(
    potentialProvinces,
  );
  return {
    code: provinceCode,
    regionCode: PROVINCE_REGIONS[provinceCode],
  };
};

export const getCities = ({
  nbrOfCities = faker.random.number(99),
  potentialProvinces = Object.values(ProvinceCode),
} = {}) =>
  getArray(nbrOfCities)
    .map(() => getCity(potentialProvinces))
    .sort((a, b) => a.postcode.localeCompare(b.postcode))
    .filter(
      (city, index, cities) =>
        !cities
          .slice(0, index)
          .some(({ postcode }) => postcode === city.postcode),
    );

export const getCity = (
  potentialProvinces = Object.values(ProvinceCode),
): City => {
  const province = getProvince(potentialProvinces);

  const POSTCODE_CITY = faker.helpers.randomize(POSTCODES_CITIES);

  return {
    provinceCode: province.code,
    regionCode: province.regionCode,
    postcode: POSTCODE_CITY.postcode,
    names: POSTCODE_CITY.names,
  };
};
