import { useLuxon } from '@kaa/common/utils';
import { DownloadTemplates } from '@kaa/core-app/providers/components';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AlertType,
  getAlertPropsByType,
  SwAlert,
  SwTitle,
} from '@kaa/ui-flanders/components';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modals } from '../../../../../constants';
import { sendConfirmationModalViewEvent } from '../../../../../utils/google-analytics';
import { PaperVouchersRemittanceForm } from './RemittancesDeliverySlipModal.types';

type RemittancesDeliverySlipCreateModalConfirmationProps = {
  formikData: PaperVouchersRemittanceForm;
  fileBlob: Blob;
};

export const RemittancesDeliverySlipCreateModalConfirmation = ({
  formikData,
  fileBlob,
}: RemittancesDeliverySlipCreateModalConfirmationProps) => {
  const { t } = useTranslation();
  const { DateTime } = useLuxon();

  useEffect(() => {
    sendConfirmationModalViewEvent(Modals.REMITTANCES_SLIP_MODAL_ID);
  }, []);

  const {
    numberOfVouchers,
    communication,
    effectiveMonthAndYears,
  } = formikData;

  const date = DateTime.fromISO(effectiveMonthAndYears);
  const effectiveMonthAndYear = `${date.month}/${date.year}`;

  const fileName = `${t(
    i18nKeys.remittances.downloadTemplate.fileName,
  )}_${effectiveMonthAndYear}${
    communication ? `-${communication}` : ''
  }-${numberOfVouchers}`;
  const fileUrl = window.URL.createObjectURL(fileBlob);

  return (
    <>
      <SwTitle tagName="h2" className="vl-u-spacer--medium">
        {t(i18nKeys.remittances.modal.createDeliverySlip.title)}
      </SwTitle>
      <SwAlert
        {...getAlertPropsByType(AlertType.SUCCESS)}
        title={t(i18nKeys.general.label.success)}
        content={t(
          i18nKeys.remittances.modal.createDeliverySlip.successMessage,
        )}
      />
      <DownloadTemplates
        fromModal={`remittances/paper/${Modals.REMITTANCES_SLIP_MODAL_ID}`}
        blob={fileBlob}
        fileName={fileName}
        linkToTemplate={fileUrl}
        titleDownloadTemplate={t(i18nKeys.remittances.downloadTemplate.title)}
        titleOpenTemplate={t(i18nKeys.remittances.downloadTemplate.titleOpen)}
      />
    </>
  );
};
