import { GetLastRemittancesParams, Relative } from '@kaa/api/providers';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  Icon,
  SwActionGroup,
  SwButton,
  SwColumn,
  SwForm,
  SwFormGrid,
  SwFormInput,
  SwFormMessageLabel,
  SwFormSubmitMessage,
  SwInputCalendarField,
  SwInputCalendarFieldFormatDate,
  SwInputCalendarFieldParseDate,
  SwInputField,
  SwInputTextField,
  SwLink,
  SwSelectField,
} from '@kaa/ui-flanders/components';
import { Field, Formik, FormikActions } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { required } from '../../../../../../common/validation';
import { useSelectedProviderState } from '../../../../utils';
import { createValidatorWithServerErrorHandled } from '../../../../utils/validation';
import {
  RemittancesSearchHistoryFormFieldNames,
  RemittancesSearchHistoryFormType,
} from '../RemittancesSearchHistory.constants';
import {
  getMaxEndDate,
  getMaxStartDate,
  getMinEndDate,
  getMinStartDate,
  getRelativesOptions,
  getSupportTypeOptions,
  getVoucherStatusOptions,
} from '../RemittancesSearchHistory.utils';

type RemittancesSearchHistoryFormProps = {
  relatives: Relative[];
  isSubsidiary: boolean;
  parameters: GetLastRemittancesParams;
  relativeId?: number | string;
  submit: (
    params: RemittancesSearchHistoryFormType,
    formikActions: FormikActions<RemittancesSearchHistoryFormType>,
  ) => void;
  reset: (
    resetForm: (
      nextValues?: RemittancesSearchHistoryFormType | undefined,
    ) => void,
  ) => void;
};

export const RemittancesSearchHistoryForm = ({
  relatives,
  isSubsidiary,
  parameters,
  relativeId,
  submit,
  reset,
}: RemittancesSearchHistoryFormProps) => {
  const { t } = useTranslation();
  const provider = useSelectedProviderState();

  const { onAllRelatives, pageSize, pageNumber, ...rest } = parameters;

  const initialValues = {
    ...rest,
    searchedProviderId: relativeId || '',
  } as RemittancesSearchHistoryFormType;

  const validate = createValidatorWithServerErrorHandled({
    startDate: [required],
    endDate: [required],
  });

  return (
    <SwColumn
      className="vl-u-bg-alt vl-u-spacer--medium"
      style={{ padding: '2rem' }}
    >
      <Formik<RemittancesSearchHistoryFormType>
        initialValues={initialValues}
        validate={validate}
        onSubmit={submit}
        enableReinitialize
        render={({ handleSubmit, dirty, isSubmitting, values, resetForm }) => (
          <SwForm onSubmit={handleSubmit}>
            <SwFormGrid
              modStacked
              className="vl-u-display-flex vl-u-spacer--small"
            >
              {!isSubsidiary ? (
                <SwColumn width="4" widthM="6" widthS="12">
                  <Field
                    name={
                      RemittancesSearchHistoryFormFieldNames.SEARCHED_PROVIDER_ID
                    }
                    label={t(i18nKeys.remittances.general.reimbursementCenter)}
                    options={getRelativesOptions(relatives)}
                    component={SwSelectField}
                    column={{ width: '12' }}
                    labelColumn={{ width: '12' }}
                  />
                </SwColumn>
              ) : (
                <SwColumn width="4" widthM="6" widthS="12">
                  <SwFormMessageLabel
                    htmlFor={
                      RemittancesSearchHistoryFormFieldNames.SEARCHED_PROVIDER_ID
                    }
                  >
                    {t(i18nKeys.remittances.general.reimbursementCenter)}
                  </SwFormMessageLabel>
                  <SwFormInput>
                    <SwInputField
                      id={
                        RemittancesSearchHistoryFormFieldNames.SEARCHED_PROVIDER_ID
                      }
                      type="text"
                      value={`${provider.name} - ${provider.id}`}
                      modDisabled
                      modBlock
                    />
                  </SwFormInput>
                </SwColumn>
              )}
              <SwColumn width="4" widthM="6" widthS="12">
                <Field
                  id={RemittancesSearchHistoryFormFieldNames.KEYWORD}
                  name={RemittancesSearchHistoryFormFieldNames.KEYWORD}
                  label={t(i18nKeys.general.search)}
                  placeholder={t(i18nKeys.general.placeholder.search)}
                  component={SwInputTextField}
                  maxLength="15"
                  column={{ width: '12' }}
                  labelColumn={{ width: '12' }}
                />
              </SwColumn>
              <SwColumn width="4" widthM="12">
                <fieldset>
                  <legend
                    className="vl-form__label"
                    style={{ lineHeight: '1.5em' }}
                  >
                    {t(i18nKeys.remittances.search.form.period)}
                  </legend>
                  <SwFormGrid modStacked>
                    <SwColumn width="6" widthS="12">
                      <Field
                        name={RemittancesSearchHistoryFormFieldNames.START_DATE}
                        component={SwInputCalendarField}
                        parse={SwInputCalendarFieldParseDate}
                        format={SwInputCalendarFieldFormatDate}
                        minDate={getMinStartDate(values)}
                        maxDate={getMaxStartDate(values)}
                        column={{ width: '12' }}
                        labelColumn={{ width: '12' }}
                        placeholder={t(
                          i18nKeys.general.placeholder.datePicker.start,
                        )}
                      />
                    </SwColumn>
                    <SwColumn width="6" widthS="12">
                      <Field
                        name={RemittancesSearchHistoryFormFieldNames.END_DATE}
                        component={SwInputCalendarField}
                        parse={SwInputCalendarFieldParseDate}
                        format={SwInputCalendarFieldFormatDate}
                        minDate={getMinEndDate(values)}
                        maxDate={getMaxEndDate(values)}
                        column={{ width: '12' }}
                        labelColumn={{ width: '12' }}
                        placeholder={t(
                          i18nKeys.general.placeholder.datePicker.end,
                        )}
                      />
                    </SwColumn>
                  </SwFormGrid>
                </fieldset>
              </SwColumn>
            </SwFormGrid>
            <SwFormGrid modStacked className="vl-u-display-flex">
              <SwColumn width="4" widthM="6" widthS="12">
                <Field
                  name={RemittancesSearchHistoryFormFieldNames.SUPPORT_TYPE}
                  label={t(i18nKeys.remittances.general.type)}
                  component={SwSelectField}
                  options={getSupportTypeOptions(t)}
                  column={{ width: '12' }}
                  labelColumn={{ width: '12' }}
                />
              </SwColumn>
              <SwColumn width="4" widthM="6" widthS="12">
                <Field
                  name={RemittancesSearchHistoryFormFieldNames.VOUCHER_STATUS}
                  label={t(i18nKeys.remittances.general.voucherStatus)}
                  component={SwSelectField}
                  options={getVoucherStatusOptions(t)}
                  column={{ width: '12' }}
                  labelColumn={{ width: '12' }}
                />
              </SwColumn>
              <SwColumn
                width="4"
                widthM="6"
                widthS="12"
                style={{ alignSelf: 'flex-end' }}
              >
                <SwActionGroup modCollapseS>
                  <SwButton
                    type="submit"
                    icon={Icon.SYNCHRONIZE}
                    modLoading={isSubmitting}
                    modDisabled={!dirty}
                    modIconBefore
                  >
                    {t(i18nKeys.general.search)}
                  </SwButton>
                  <SwLink
                    type="button"
                    onClick={() => {
                      reset(resetForm);
                    }}
                  >
                    {t(i18nKeys.general.label.reset)}
                  </SwLink>
                </SwActionGroup>
              </SwColumn>
              <SwFormSubmitMessage />
            </SwFormGrid>
          </SwForm>
        )}
      />
    </SwColumn>
  );
};
