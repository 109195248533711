import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  SwActionGroup,
  SwButton,
  SwLink,
  SwModalRenderProps,
  SwTitle,
  toggleModalById,
} from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modals } from '../../../constants';

type WorkersActivationModalProps = SwModalRenderProps<{
  confirm: () => void;
  title: string;
  confirmText: string;
}>;

export const WorkersActivationModal = ({
  confirm,
  title,
  confirmText,
}: WorkersActivationModalProps) => {
  const { t } = useTranslation();

  return (
    <>
      <SwTitle tagName="h2">{title}</SwTitle>
      <SwActionGroup>
        <SwButton
          onClick={() => {
            confirm();
            toggleModalById(Modals.WORKERS_CONFIRMATION_MODAL);
          }}
        >
          {confirmText}
        </SwButton>
        <SwLink
          onClick={() => {
            toggleModalById(Modals.WORKERS_CONFIRMATION_MODAL);
          }}
        >
          {t(i18nKeys.general.cta.cancel)}
        </SwLink>
      </SwActionGroup>
    </>
  );
};
