import { ServicesOverview } from '@kaa/api/providers';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AlertType,
  getAlertPropsByType,
  SwAlert,
  SwColumn,
  SwLink,
} from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getPath, QueryParams, Routes } from '../../../routes';
import {
  EventAction,
  EventCategory,
  EventLabel,
  sendCustomInteractionToGTM,
} from '../../../utils/google-analytics';
import { OverviewAlert, OverviewFilter } from '../PrestationsScreen.types';
import { getAlerts } from './PrestationsOverview.utils';
import { PrestationsOverviewTooltip } from './PrestationsOverviewTooltip';

type PrestationsOverviewProps = {
  overview: ServicesOverview;
  applyFilter: (filter: OverviewFilter) => void;
  title: string;
  category?: EventCategory;
  route: Routes;
};

export const PrestationsOverview = ({
  overview,
  applyFilter,
  title,
  category,
  route,
}: PrestationsOverviewProps) => {
  const { t } = useTranslation();

  return (
    <SwColumn className="vl-u-spacer">
      <SwAlert {...getAlertPropsByType(AlertType.WARNING)} title={title}>
        {getAlerts(overview).map(
          ({ status, periodInMonths, key, count }: OverviewAlert) => (
            <SwColumn
              key={key}
              width="0"
              widthS="6"
              widthXs="12"
              style={{
                display: 'inline-block',
                marginBottom: '1rem',
              }}
            >
              <SwLink
                className="vl-u-text--small"
                style={{
                  marginRight: '2rem',
                }}
                to={getPath(route, {
                  queryParams: {
                    [QueryParams.FILTER]: key,
                  },
                })}
                onClick={() => {
                  applyFilter({
                    status,
                    periodInMonths,
                  } as OverviewFilter);
                  sendCustomInteractionToGTM(
                    category || EventCategory.PRESTATIONS,
                    EventAction.CLICK_ALERT,
                    `${EventLabel.PRESTATIONS_OVERVIEW}: ${key}`,
                  );
                }}
              >
                <PrestationsOverviewTooltip status={key} count={count} />
                {t(
                  i18nKeys.prestations.search.overview[
                    key as keyof typeof i18nKeys.prestations.search.overview
                  ],
                )}
              </SwLink>
            </SwColumn>
          ),
        )}
      </SwAlert>
    </SwColumn>
  );
};
