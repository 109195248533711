/* https://wiki.sodexo.be/display/PROJECTAAH/Data-testid */
/* Nomenclature for data-testid for Sodexo
   The data-testid is splitted in 3 parts: ComponentName-tagName-action or description of the element. */

export const dataTest = {
  dashboard: {
    profileButton: 'SwButton-button-profile',
    contactPersonButton: 'SwButton-button-contactPerson',
    relativeButton: 'SwButton-button-relative',
    visibilityButton: 'SwButton-button-visibility',
    activitiesButton: 'SwButton-button-activities',
    logoutButton: 'SwButton-button-logout',
  },
  header: {
    backEnterprise: 'SwTab-a-backEnterprise',
    backHome: 'SwTab-a-backhome',
    backContactPerson: 'SwTab-a-backContactPerson',
    backToPublicServiceVouchersLogin: 'SwTab-a-backToServiceVouchers',
    backWorkers: 'SwTab-a-backWorkers',
    linkLogout: 'Swlink-a-logout',
    support: 'SwButton-a-logout',
  },
  activities: {},
  contactPersons: {
    modalNewContactPerson: 'SwButton-button-openModalNewContactPerson',
    addNewContactPerson: 'SwButton-button-addNewContactPerson',
    detailsContactPerson: 'SwLink-a-seeDetails-#{id}',
  },
  profile: {
    profileName: 'SwInputField-input-profileName',
    profileAuthNumber: 'SwInputField-input-AuthNumber',
    confirmEmailModalForm: 'SwForm-form-confirmEmailModal',
    changeEmailModalEmailInput: 'SwButton-button-changeEmailModalEmailInput',
    changeEmailModalEmailConfirmInput:
      'SwButton-button-changeEmailModalEmailConfirmInput',
    submitButton: 'SwButton-button-submitconfirmEmailModal',
    changeEmailModal: {
      formChangeEmail: 'SwForm-form-loginEmailForm',
      oldEmailAdress: 'SwFormColumn-div-oldEmailAddress',
      newEmailAddress: 'SwInputTextField-input-loginEmailAddress',
      confirmEmailAddress: 'SwInputTextField-input-loginConfirmEmailAddress',
      submitChangeEmailModal: 'SwButton-button-submitNewLoginEmailAddress',
    },
    changePasswordModal: {
      formChangePassword: 'confirmPasswordModalForm',
      linkForgotPassword: 'SwLink-a-forgotPassword',
      submitChangePasswordModal: 'SwButton-button-comfirmPasswordsubmit',
    },
  },
  relatives: {
    affiliateCompanyId: 'SwColumn-div-companyId',
    headquarterCompanyId: 'SwColumn-div-headQuarterId',
    sortAffiliates: 'SwButton-button-sortAffiliates',
    addNewAffiliate: 'SwButton-button-addNewAffiliate',
    editHeadQuarter: 'SwLink-a-headQuarterEditButton',
    editAffiliate: 'SwColumn-div-showDetails',
    titleRelatives: 'SwTitle-h1-Title',
    tableHeader: 'SwDataTable.th.tableRelativesHeader',
    tableBody: 'SwDataTable.th.tableRelativesBody',
    details: {
      requestChangeButton: 'SwButton-button-requestChanges',
      editDetailsButton: 'SwButton-button-editDetails',
      titleDetailRelatives: 'SwTitle-h1-detailTitle',
      desactivateRealtive: 'SwButton-button-deactivateAffiliate',
      detailRelativeForm: 'relativeDetailsForm-div-relativeDetailsForm',
      detailRelativeCommunicationForm:
        'relativeDetailsCommunicationForm-div-relativeDetailsCommunicationForm',
      detailRelativeBankAccountForm:
        'relativeDetailsBankAccountForm-div-relativeDetailsBankAccountForm',
      modifyBankAccount: 'relativeDetailsForm-a-modifyBankAccount',
    },
  },
  visibility: {
    submitButton: 'SwButton-button-submitVisibility',
  },
  remittances: {
    tabPaper: 'SwTab-a-OpenPaperTab',
    tabElectronic: 'SwTab-a-OpenElectronicTab',
    reimbursement: 'SwButton-button-openReimbursementModal',
    electronic: {
      manual: {
        submitButton: 'SwButton-button-submitManualRemittance',
        createRemittance: 'SwButton-button-createRemittance',
      },
      automatic: {
        submitButton: 'SwButton-button-submitAutomaticRemittance',
      },
    },
    paper: {
      deliverySlip: {
        openVirginTemplateModal: 'SwLink-a-openVirginModal',
        openTemplateModal: 'SwButton-button-openVirginModal',
        openVirginTemplate: 'SwDocument-div-openVirginTemplate',
        openTemplate: 'SwDocument-div-openTemplate',
        downloadVirginTemplate: 'SwDocument-div-downloadVirginTemplate',
        downloadTemplate: 'SwDocument-div-downloadTemplate',
        submitTemplateButton: 'SwButton-button-submitDeliverySlipTemplate',
      },
    },
  },
  prestations: {
    tabWorker: 'SwTab-a-OpenWorkerTab',
    tabList: 'SwTab-a-OpenListTab',
  },
  work: {
    addWork: {
      addNewWork: 'SwButton-button-addNewWork',
    },
  },
  workers: {
    addWorker: {
      addNewWorker: 'SwButton-button-addNewWorker',
    },
    editWorker: 'SwButton-button-editWorker',
    details: {
      contract: {
        addContract: 'SwButton-button-addAContract',
        editContract: 'SwButton-button-editAContract',
      },
    },
    searchWorkers: {
      ExportResults: 'SwLink-a-exportResults',
    },
  },
};
