import { Service, WorkerServiceActionType } from '@kaa/api/providers';
import { ActionsHandler } from '@kaa/common/handlers';
import { Format, useLuxon } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import { Icon, SwIcon, SwLink, useTooltip } from '@kaa/ui-flanders/components';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Modals } from '../../../constants';
import { getWorkerDetailsPath } from '../../../routes';
import { MotionTr } from './MotionTr';

type PrestationsTableRowProps = {
  service: Service;
  providerId: string;
  openModal?: (modal: Modals, service: Service) => void;
  showWorker?: boolean;
  showActivity?: boolean;
  showActions?: boolean;
};

export const PrestationsTableRow = ({
  service,
  providerId,
  openModal,
  showWorker = false,
  showActivity = false,
  showActions = false,
  children,
}: PropsWithChildren<PrestationsTableRowProps>) => {
  const { t } = useTranslation();
  const { DateTime } = useLuxon();

  const {
    id,
    creationDate,
    serviceDate,
    customerFullname,
    customerAuthorisation,
    durationInHours,
    activity,
    resourceId,
    workerId,
    workerFullname,
  } = service;

  const buttonStyle = (isAllowed: boolean, color?: string) => ({
    border: 'none',
    textDecoration: 'underline',
    cursor: isAllowed ? 'pointer' : 'default',
    color: color && isAllowed ? color : '#cbd2d9',
  });

  const toolTipStatus = useTooltip({
    value: t(i18nKeys.general.activityType[activity]),
    placement: 'bottom',
  });

  return (
    <MotionTr isOpen>
      <td data-title={t(i18nKeys.general.label.id)}>
        {openModal ? (
          <SwLink
            onClick={(e) => {
              e.preventDefault();
              openModal(Modals.PRESTATIONS_DETAILS_PRESTATION_MODAL, service);
            }}
          >
            {id}
          </SwLink>
        ) : (
          <>{id}</>
        )}
      </td>
      <td data-title={t(i18nKeys.general.label.date)}>
        {Format.date(String(DateTime.fromISO(serviceDate)))}
      </td>
      <td data-title={t(i18nKeys.general.label.creationDate)}>
        {Format.date(String(DateTime.fromISO(creationDate)))}
      </td>
      {!!showWorker && (
        <td data-title={t(i18nKeys.general.label.worker)}>
          <SwLink to={getWorkerDetailsPath(workerId, providerId)}>
            {workerFullname}
          </SwLink>
        </td>
      )}
      <td data-title={t(i18nKeys.general.label.customer)}>
        {customerFullname} ({customerAuthorisation})
      </td>
      {showActivity ? (
        <td data-title={t(i18nKeys.general.label.activity)}>
          <SwIcon
            style={{
              top: '.3rem',
              position: 'relative',
              marginRight: '.5rem',
            }}
            icon={Icon[activity]}
          />
          {t(i18nKeys.general.activityType[activity])}
        </td>
      ) : (
        <td
          data-title={t(i18nKeys.general.label.activity)}
          style={{ whiteSpace: 'nowrap' }}
        >
          <SwIcon
            ref={toolTipStatus}
            icon={Icon[activity]}
            style={{
              top: '.3rem',
              position: 'relative',
              marginRight: '.5rem',
            }}
          />
        </td>
      )}
      <td data-title={t(i18nKeys.general.label.time)}>
        {durationInHours}
        {t(i18nKeys.general.label.hourCode)}
      </td>
      {children}
      {!!showActions && (
        <td>
          {!!openModal && (
            <div className="vl-u-display-flex vl-u-flex-v-space-between">
              <ActionsHandler
                resourceId={resourceId}
                actions={[WorkerServiceActionType.CAN_VALIDATE]}
              >
                {({ isAllowed }) => (
                  <button
                    type="button"
                    style={buttonStyle(isAllowed, '#0055cc')}
                    disabled={!isAllowed}
                    onClick={
                      isAllowed
                        ? (e) => {
                            e.preventDefault();
                            openModal(
                              Modals.PRESTATIONS_VALIDATE_PRESTATION_MODAL,
                              service,
                            );
                          }
                        : undefined
                    }
                  >
                    <SwIcon modLarge icon={Icon.CHECK_SMALL} />
                    <br />
                    {t(i18nKeys.general.cta.validate)}
                  </button>
                )}
              </ActionsHandler>
              <ActionsHandler
                resourceId={resourceId}
                actions={[WorkerServiceActionType.CAN_EDIT]}
              >
                {({ isAllowed }) => (
                  <button
                    type="button"
                    style={buttonStyle(isAllowed, '#0055cc')}
                    disabled={!isAllowed}
                    onClick={
                      isAllowed
                        ? (e) => {
                            e.preventDefault();
                            openModal(
                              Modals.PRESTATIONS_EDIT_PRESTATION_MODAL,
                              service,
                            );
                          }
                        : undefined
                    }
                  >
                    <SwIcon modLarge icon={Icon.EDIT} />
                    <br />
                    {t(i18nKeys.general.cta.edit)}
                  </button>
                )}
              </ActionsHandler>
              <ActionsHandler
                resourceId={resourceId}
                actions={[WorkerServiceActionType.CAN_CANCEL]}
              >
                {({ isAllowed }) => (
                  <button
                    type="button"
                    style={buttonStyle(isAllowed, 'red')}
                    disabled={!isAllowed}
                    onClick={
                      isAllowed
                        ? (e) => {
                            e.preventDefault();
                            openModal(
                              Modals.PRESTATIONS_CANCEL_PRESTATION_MODAL,
                              service,
                            );
                          }
                        : undefined
                    }
                  >
                    <SwIcon modLarge icon={Icon.BIN} />
                    <br />
                    {t(i18nKeys.general.cta.cancel)}
                  </button>
                )}
              </ActionsHandler>
            </div>
          )}
        </td>
      )}
    </MotionTr>
  );
};
