import { i18nKeys } from '@kaa/i18n/providers/keys';
import { Icon, SwIcon, SwLink } from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationType } from '../../constants';
import {
  EventAction,
  sendDownloadEventToGTM,
} from '../../utils/google-analytics';
import { getFileExtension } from '../../utils/templates';
import './DownloadTemplates.style.scss';
import { DownloadTemplatesProps } from './DownloadTemplates.utils';

export const DownloadTemplatesActions = ({
  fromModal,
  fileName,
  linkToTemplate,
  titleDownloadTemplate,
  blob,
}: DownloadTemplatesProps) => {
  const { t } = useTranslation();

  const file = `${fileName}.${getFileExtension(blob.type as ApplicationType)}`;

  return (
    <SwLink
      tagName="a"
      href={linkToTemplate}
      className="download-link"
      download={file}
      onClick={(e) => {
        if (window.navigator.msSaveOrOpenBlob) {
          e.preventDefault();
          window.navigator.msSaveOrOpenBlob(blob, file);
        }
        sendDownloadEventToGTM(
          EventAction.DOWNLOAD,
          `${file} | ${linkToTemplate}`,
        );
      }}
    >
      <SwIcon icon={Icon.FILE_DOWNLOAD} />
      {titleDownloadTemplate || t(i18nKeys.general.modal.export.cta.download)}
    </SwLink>
  );
};
