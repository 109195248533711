import { City, ProviderActionType, Street } from '@kaa/api/providers';
import { ActionsHandler } from '@kaa/common/handlers';
import { Format } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AddressFieldNames,
  SwFormAddress,
  SwFormGrid,
  SwInputTextField,
} from '@kaa/ui-flanders/components';
import { Field } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { dataTest } from '../../../../../datatest/keys';
import { RelativeDetailsScreenFieldNames } from '../RealtiveDetailsScreen.constants';

const mappingProviderActionsToAddressActions = {
  [AddressFieldNames.POSTCODE]: ProviderActionType.CAN_EDIT_ADDRESS_POSTCODE,
  [AddressFieldNames.STREET]: ProviderActionType.CAN_EDIT_ADDRESS_STREET,
  [AddressFieldNames.HOUSE_NUMBER]:
    ProviderActionType.CAN_EDIT_ADDRESS_HOUSE_NUMBER,
};

type RelativeDetailsFormProps = {
  resourceId: string;
  isHeadquarter: boolean;
  availableCities: City[];
  getStreets: (
    postcode: string,
    query: string,
  ) => Promise<[any, Street[] | undefined]>;
  modDisabled: boolean;
};

export const RelativeDetailsForm = ({
  resourceId,
  isHeadquarter,
  availableCities,
  getStreets,
  modDisabled,
}: RelativeDetailsFormProps) => {
  const { t } = useTranslation();

  return (
    <>
      <SwFormGrid
        modStacked
        className="vl-u-spacer"
        data-testid={dataTest.relatives.details.detailRelativeForm}
      >
        <ActionsHandler
          resourceId={resourceId}
          actions={[ProviderActionType.CAN_EDIT_NAME]}
        >
          {({ isAllowed }) => (
            <Field
              name={RelativeDetailsScreenFieldNames.NAME}
              label={t(i18nKeys.general.label.name, {
                type: isHeadquarter
                  ? t(i18nKeys.relativeType.headquarter)
                  : t(i18nKeys.relativeType.subsidiary),
              })}
              component={SwInputTextField}
              modDisabled={!isAllowed || modDisabled}
            />
          )}
        </ActionsHandler>
        <Field
          name={RelativeDetailsScreenFieldNames.COMMERCIAL_NAME}
          label={t(i18nKeys.general.label.commercialName)}
          component={SwInputTextField}
          modDisabled={modDisabled}
        />
        <Field
          name="id"
          label={t(i18nKeys.general.label.authorizationNumber)}
          component={SwInputTextField}
          modDisabled
        />
        <Field
          name={RelativeDetailsScreenFieldNames.VAT_NUMBER}
          label={t(i18nKeys.general.label.bceNumber)}
          component={SwInputTextField}
          parse={(value: string) => (value ? Format.iban(value) : '')}
          modDisabled
        />
        <SwFormAddress
          fieldNamePrefix="address"
          availableCities={availableCities}
          resourceId={resourceId}
          actions={mappingProviderActionsToAddressActions}
          modDisabled={modDisabled}
          getStreets={getStreets}
        />
      </SwFormGrid>
    </>
  );
};
