import {
  CustomersResponseDataItem,
  ServiceResponseData,
  ServiceStatus,
  WorkerSummary,
} from '@kaa/api/providers';
import {
  existsInError,
  maxLength,
  minLength,
  required,
  setError,
  unsetError,
} from '@kaa/common/validation';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import { FormikHandlers, FormikProps } from 'formik';
import { TFunction } from 'i18next';
import { DateTime } from 'luxon';
import { PrestationFormFieldNames } from './PrestationForm.constant';
import {
  PrestationFormData,
  PrestationStatusAlert,
} from './PrestationForm.types';

export const getDefaultWorksValidation = () => ({
  [PrestationFormFieldNames.PROVIDER_ID]: [required],
  [PrestationFormFieldNames.SERVICE_DATE]: [required],
  [PrestationFormFieldNames.CUSTOMER_NUMBER]: [
    existsInError(`${PrestationFormFieldNames.CUSTOMER_NUMBER}.invalid`),
    minLength(12),
    maxLength(12),
    required,
  ],
  [PrestationFormFieldNames.CUSTOMER_ID]: [required],
  [PrestationFormFieldNames.CUSTOMER_NAME]: [required],
  [PrestationFormFieldNames.WORKER_ID]: [required],
  [PrestationFormFieldNames.WORKER_NISS]: [required],
  [PrestationFormFieldNames.WORKER_NAME]: [required],
  [PrestationFormFieldNames.ACTIVITY]: [required],
  [PrestationFormFieldNames.DURATION]: [required],
});

export const getRequiredDefaultWorksFields = (
  prestationFormData: Partial<PrestationFormData> = {},
): Partial<PrestationFormData> => ({
  [PrestationFormFieldNames.PROVIDER_ID]: undefined,
  [PrestationFormFieldNames.SERVICE_DATE]: '',
  [PrestationFormFieldNames.CUSTOMER_ID]: '',
  [PrestationFormFieldNames.CUSTOMER_NUMBER]: '',
  [PrestationFormFieldNames.CUSTOMER_NAME]: '',
  [PrestationFormFieldNames.WORKER_ID]: '',
  [PrestationFormFieldNames.WORKER_NISS]: '',
  [PrestationFormFieldNames.WORKER_NAME]: '',
  [PrestationFormFieldNames.ACTIVITY]: undefined,
  [PrestationFormFieldNames.DURATION]: undefined,
  [PrestationFormFieldNames.COMMENT]: '',
  ...prestationFormData,
});

export const getAutocompleteCustomersOption = (
  getCustomers?: (
    query: string,
  ) => Promise<[Error | undefined, CustomersResponseDataItem[] | undefined]>,
) => async (
  query: string,
  field: {
    onChange: FormikHandlers['handleChange'];
    onBlur: FormikHandlers['handleBlur'];
    value: string;
    name: string;
  },
  form: FormikProps<PrestationFormData>,
) => {
  if ((!query && query.length !== 12) || !getCustomers) {
    return [];
  }

  const { setFieldValue, values } = form;
  const [, customers = []] = await getCustomers(query);

  if (customers.length) {
    const customer = customers[0];
    unsetError({
      setFieldValue,
      values,
      name: `${PrestationFormFieldNames.CUSTOMER_NUMBER}.invalid`,
    });
    setFieldValue(PrestationFormFieldNames.CUSTOMER_ID, customer.id);
    setFieldValue(PrestationFormFieldNames.CUSTOMER_NAME, customer.fullname);
  } else {
    setError({
      setFieldValue,
      name: `${PrestationFormFieldNames.CUSTOMER_NUMBER}.invalid`,
    });
    setFieldValue(PrestationFormFieldNames.CUSTOMER_ID, '');
    setFieldValue(PrestationFormFieldNames.CUSTOMER_NAME, '');
  }

  return [];
};

export const getAutocompleteWorkersOption = (
  searchedProviderId: string,
  getWorkers?: (
    query: string,
    searchedProviderId: string,
  ) => Promise<[Error | undefined, WorkerSummary[] | undefined]>,
) => async (query: string) => {
  if (!query || !getWorkers) {
    return [];
  }
  const [, value = []] = await getWorkers(query, searchedProviderId);

  return value.filter((el) => el.isActive);
};

export const onSelectWorkerOption = () => async (
  { firstName, lastName, niss, id }: WorkerSummary,
  field: {
    onChange: FormikHandlers['handleChange'];
    onBlur: FormikHandlers['handleBlur'];
    value: string;
    name: string;
  },
  form: FormikProps<PrestationFormData>,
) => {
  const { setFieldValue } = form;
  setFieldValue(PrestationFormFieldNames.WORKER_ID, id);
  setFieldValue(PrestationFormFieldNames.WORKER_NISS, niss);
  setFieldValue(
    PrestationFormFieldNames.WORKER_NAME,
    `${firstName} ${lastName}`,
  );
};

export const menuWorkerOptionFormat = ({
  firstName,
  lastName,
  niss,
}: WorkerSummary) => `${niss} - ${firstName} ${lastName}`;

export const getPrestationStatusAlert = (
  {
    status,
    automaticValidationRemainingDays,
    cancellationDate,
    remittances,
  }: ServiceResponseData,
  t: TFunction,
): PrestationStatusAlert => {
  switch (status) {
    case ServiceStatus.CREATED: {
      if (typeof automaticValidationRemainingDays !== 'undefined') {
        return {
          title: t(i18nKeys.prestations.modals.alerts.title.CREATED),
          text: t(i18nKeys.prestations.modals.alerts.text.CREATED, {
            remainingTime: automaticValidationRemainingDays,
          }),
        };
      }
      return {
        title: t(i18nKeys.prestations.modals.alerts.title.CREATED),
      };
    }
    case ServiceStatus.CONFIRMED: {
      if (typeof automaticValidationRemainingDays !== 'undefined') {
        return {
          title: t(i18nKeys.prestations.modals.alerts.title.CONFIRMED),
          text: t(
            i18nKeys.prestations.modals.alerts.text.CONFIRMED
              .automaticValidationRemaningDays,
            {
              remainingTime: automaticValidationRemainingDays,
            },
          ),
        };
      }
      return {
        title: t(i18nKeys.prestations.modals.alerts.title.CONFIRMED),
        text: t(
          i18nKeys.prestations.modals.alerts.text.CONFIRMED.noRemaningDays,
        ),
      };
    }
    case ServiceStatus.CANCELED: {
      if (typeof cancellationDate !== 'undefined') {
        return {
          title: t(i18nKeys.prestations.modals.alerts.title.CANCELED),
          text: t(i18nKeys.prestations.modals.alerts.text.CANCELED, {
            date: DateTime.fromISO(cancellationDate).toFormat('dd/LL/yyyy'),
          }),
        };
      }
      return {
        title: t(i18nKeys.prestations.modals.alerts.title.CANCELED),
      };
    }
    case ServiceStatus.PARTIALLY_ASSIGNED:
    case ServiceStatus.PARTIALLY_REFUNDED:
    case ServiceStatus.REFUND_REQUESTED: {
      if (remittances && remittances.length) {
        return {
          title: t(i18nKeys.prestations.modals.alerts.title[status]),
          text: remittances.map(({ remittanceId, vouchersQuantity }) =>
            t(i18nKeys.prestations.modals.alerts.inRemittances, {
              remittanceId,
              vouchersQuantity,
            }),
          ),
        };
      }
      return {
        title: t(i18nKeys.prestations.modals.alerts.title[status]),
      };
    }
    case ServiceStatus.CORRECTED:
      return {
        title: t(i18nKeys.prestations.modals.alerts.title.CORRECTED),
        text: t(i18nKeys.prestations.modals.alerts.text.CORRECTED),
      };
    case ServiceStatus.MISSING_VOUCHERS:
      return {
        title: t(i18nKeys.prestations.modals.alerts.title.MISSING_VOUCHERS),
        text: t(i18nKeys.prestations.modals.alerts.text.MISSING_VOUCHERS),
      };
    case ServiceStatus.MODIFIED:
      return {
        title: t(i18nKeys.prestations.modals.alerts.title.MODIFIED),
        text: t(i18nKeys.prestations.modals.alerts.text.MODIFIED),
      };
    case ServiceStatus.MISSING_CONTRACT:
      return {
        title: t(i18nKeys.prestations.modals.alerts.title.MISSING_CONTRACT),
        text: t(i18nKeys.prestations.modals.alerts.text.MISSING_CONTRACT),
      };
    case ServiceStatus.PAID:
      return {
        title: t(i18nKeys.prestations.modals.alerts.title.PAID),
        text: t(i18nKeys.prestations.modals.alerts.text.PAID),
      };
    case ServiceStatus.TO_BE_PAID_OUTSIDE_SV: {
      return {
        title: t(
          i18nKeys.prestations.modals.alerts.title.TO_BE_PAID_OUTSIDE_SV,
        ),
        text: t(i18nKeys.prestations.modals.alerts.text.TO_BE_PAID_OUTSIDE_SV),
      };
    }
    default: {
      return {
        title: t(i18nKeys.prestations.modals.alerts.title[status]),
      };
    }
  }
};
