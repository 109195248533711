import faker from 'faker';
import {
  VideoDescriptionLinksResponse,
  VideoDescriptionLinkTitle,
  VideoDescriptionResponse,
  VideoResponse,
  VideoResponseDetails,
  VideoResponseSummary,
  VideoResponseTitles,
  VideosResponse,
  VideosStreamIdResponse,
  VideoTopicReference,
} from '../model';
import { getRandomArray } from './utils';

export const getVideosResponse = (): VideosResponse => {
  return {
    data: [
      {
        id: '10074',
        section: 'explanationTutorial',
        title: 'myServices',
        topic: 'PRESTATIONS',
        thumbnail: '',
        description: {
          summary: 'explanationOfVideo',
          details: ['serviceEncoding', 'serviceImport', 'serviceExport'],
        },
      },
      {
        id: '10078',
        section: 'explanationTutorial',
        title: 'myWorkers',
        topic: 'WORKERS',
        thumbnail: '',
        description: {
          summary: 'explanationOfVideo',
          details: ['addWorker', 'importWorkerList'],
        },
      },
      {
        id: '10076',
        section: 'explanationTutorial',
        title: 'electronicRemittances',
        topic: 'REMITTANCE',
        thumbnail: '',
        description: {
          summary: 'explanationOfVideo',
          details: [
            'automaticRemittanceManagement',
            'remittanceEncoding',
            'searchAndExportModuleUsage',
          ],
        },
      },
      {
        id: '10077',
        section: 'explanationTutorial',
        title: 'papersRemittances',
        topic: 'REMITTANCE',
        thumbnail: '',
        description: {
          summary: 'explanationOfVideo',
          details: [
            'remittanceSlipCreation',
            'voucherValidityCheck',
            'declarationFileImport',
            'pickupPlan',
          ],
        },
      },
      {
        id: '10075',
        section: 'explanationTutorial',
        title: 'refunds',
        topic: 'REMITTANCE',
        thumbnail: '',
        description: {
          summary: 'explanationOfVideo',
          details: ['changeRefundSettings', 'searchModuleUsage'],
        },
      },
      {
        id: '10073',
        section: 'explanationTutorial',
        title: 'myCompany',
        topic: 'ENTERPRISE',
        thumbnail: '',
        description: {
          summary: 'explanationOfVideo',
          details: ['changeProfileData', 'addSubsidiary'],
        },
      },
      {
        id: '10072',
        section: 'explanationTutorial',
        title: 'pickups',
        topic: 'PICKUP',
        thumbnail: '',
        description: {
          summary: 'explanationOfVideo',
          details: ['pickupPlan', 'variousTips'],
        },
      },
      {
        id: '10079',
        section: 'explanationTutorial',
        title: 'export',
        topic: 'EXPORT',
        thumbnail: '',
        description: {
          summary: 'explanationOfVideo',
          details: ['csvLayout'],
        },
      },
      {
        id: '10080',
        section: 'explanationTutorial',
        title: 'export',
        topic: 'EXPORT',
        thumbnail: '',
        description: {
          summary: 'explanationOfVideo',
          details: ['xlsxLayout'],
        },
      },
      {
        id: '10081',
        section: 'webinar',
        title: 'newExtranetWebinar',
        topic: 'WEBINAR',
        thumbnail: '',
        description: {
          summary: 'extranetWebinarPresentation',
          details: [],
          urlLink: {
            url:
              'https://titres-services.wallonie.be/getattachment/9da9b6e1-7ed9-4bc2-8c7f-41568dc0ce12/webinar-nouvel-espace-securise-bruxelles-wallonie.pdf',
            title: 'extranetWebinar',
          },
        },
      },
    ],
  };
};

export const getVideos = (): VideoResponse[] => {
  return getRandomArray(10).map(getVideo);
};

export const getVideoDescriptionLinkResponse = (): VideoDescriptionLinksResponse => {
  return {
    title: faker.helpers.randomize(Object.values(VideoDescriptionLinkTitle)),
    url: faker.internet.url(),
  };
};

export const getVideoDescriptionResponse = (): VideoDescriptionResponse => {
  return {
    summary: faker.helpers.randomize(Object.values(VideoResponseSummary)),
    details: getRandomArray(5).map(() =>
      faker.helpers.randomize(Object.values(VideoResponseDetails)),
    ),
    urlLink: getVideoDescriptionLinkResponse(),
  };
};

export const getVideo = (): VideoResponse => {
  return {
    id: faker.random.word(),
    section: faker.random.word(),
    title: faker.helpers.randomize(Object.values(VideoResponseTitles)),
    topic: faker.helpers.randomize(Object.values(VideoTopicReference)),
    thumbnail: faker.image.abstract(),
    description: getVideoDescriptionResponse(),
  };
};

export const getVideoStreamId = (): VideosStreamIdResponse => {
  return { data: faker.random.uuid() };
};
