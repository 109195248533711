/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */

export type PickupType = 'STANDALONE' | 'RECURRENT';

export const PickupType = {
  STANDALONE: 'STANDALONE' as PickupType,
  RECURRENT: 'RECURRENT' as PickupType,
};
