/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */

export type VideoResponseSummary =
  | 'explanationOfVideo'
  | 'extranetWebinarPresentation';

export const VideoResponseSummary = {
  explanationOfVideo: 'explanationOfVideo' as VideoResponseSummary,
  extranetWebinarPresentation: 'extranetWebinarPresentation' as VideoResponseSummary,
};
