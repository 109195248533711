import {
  ActivateWorkersParams,
  DeactivateWorkersParams,
  GetWorkersParams,
} from '@kaa/api/providers';
import { httpTo } from '@kaa/api/providers/utilities';
import { useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  SwActionGroup,
  SwButton,
  SwLink,
  SwModalRenderProps,
  SwTitle,
  toggleModalById,
} from '@kaa/ui-flanders/components';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modals } from '../../../../constants';
import { useApi, useSelectedProviderState } from '../../../../utils';

type WorkersDeOrActivateModalProps = SwModalRenderProps<{
  parameters: GetWorkersParams | undefined;
  activation: {
    active: boolean;
    workerId?: number | undefined;
  };
  onComplete: (error: boolean, activation: boolean) => void;
}>;

export const WorkersDeOrActivateModal = ({
  parameters,
  activation,
  onComplete,
}: WorkersDeOrActivateModalProps) => {
  const { t } = useTranslation();
  const { providers } = useApi();
  const provider = useSelectedProviderState();

  const [
    { value: deOrActivateWorkersData },
    deOrActivateWorkers,
  ] = useAsyncCallback(
    async (active: boolean, parameters?: GetWorkersParams) => {
      const providerId = Number(parameters?.searchedProviderId || provider.id);
      const [error] = await httpTo(
        active
          ? providers.activateWorkers(
              providerId,
              parameters as ActivateWorkersParams,
            )
          : providers.deactivateWorkers(
              providerId,
              parameters as DeactivateWorkersParams,
            ),
      );

      return { error };
    },
    [providers],
    { loading: false },
  );

  const [
    { value: deOrActivateWorkerData },
    deOrActivateWorker,
  ] = useAsyncCallback(
    async (
      workerId: number,
      active: boolean,
      parameters?: GetWorkersParams,
    ) => {
      const providerId = Number(parameters?.searchedProviderId || provider.id);
      const [error] = await httpTo(
        active
          ? providers.activateWorker(providerId, workerId)
          : providers.deactivateWorker(providerId, workerId),
      );
      return { error };
    },
    [providers],
    { loading: false },
  );

  useEffect(() => {
    if (deOrActivateWorkersData) {
      onComplete(Boolean(deOrActivateWorkersData.error), activation.active);
    }
  }, [deOrActivateWorkersData]);

  useEffect(() => {
    if (deOrActivateWorkerData) {
      onComplete(Boolean(deOrActivateWorkerData.error), activation.active);
    }
  }, [deOrActivateWorkerData]);

  const title = activation.active
    ? t(i18nKeys.workers.modals.activateWorker.title, {
        count: activation.workerId ? 1 : 1000,
      })
    : t(i18nKeys.workers.modals.deactivateWorker.title, {
        count: activation.workerId ? 1 : 1000,
      });

  const confirmText = activation.active
    ? t(i18nKeys.workers.modals.activateWorker.cta, {
        count: activation.workerId ? 1 : 1000,
      })
    : t(i18nKeys.workers.modals.deactivateWorker.cta, {
        count: activation.workerId ? 1 : 1000,
      });

  return (
    <>
      <SwTitle tagName="h2">{title}</SwTitle>
      <SwActionGroup>
        <SwButton
          onClick={() => {
            if (activation.workerId) {
              deOrActivateWorker(
                activation.workerId,
                activation.active,
                parameters,
              );
            } else {
              deOrActivateWorkers(activation.active, parameters);
            }
          }}
        >
          {confirmText}
        </SwButton>
        <SwLink
          onClick={() => {
            toggleModalById(Modals.WORKERS_CONFIRMATION_MODAL);
          }}
        >
          {t(i18nKeys.general.cta.cancel)}
        </SwLink>
      </SwActionGroup>
    </>
  );
};
