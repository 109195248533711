import faker from 'faker';
import { Street } from '../model';
import { getArray } from './utils';

export const getStreet = (street?: Partial<Street>): Street => ({
  id: faker.random.number(99999999),
  name: faker.address.streetName(),
  postcode: '1000',
  city: faker.address.city(),
  country: 'Belgium',
  ...street,
});

export const getStreetsResponse = (postcode?: string) => {
  return {
    data: getArray(50).map(() => getStreet({ postcode })),
  };
};
