import { ApiErrorField } from '@kaa/api/providers';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const transitions = { ease: [0.12, 0.64, 0.05, 0.98], duration: 0.6 };
const variantsVoucherImages = {
  visible: {
    height: '75rem',
    overflow: 'auto',
    backgroundColor: '#e8ebee',
  },
  hidden: {
    height: 0,
    overflow: 'hidden',
  },
};

type RemittancesSearchHistoryIncorrectEditRowFormImageProps = {
  imageUrl?: string;
  isVisible: boolean;
  error?: ApiErrorField;
};
export const RemittancesSearchHistoryIncorrectEditRowFormImage = React.memo(
  ({
    imageUrl,
    isVisible,
  }: RemittancesSearchHistoryIncorrectEditRowFormImageProps) => {
    const { t } = useTranslation();
    const [rowOpen, setRowOpen] = useState(false);

    const onComplete = () => setRowOpen(isVisible);

    if (!imageUrl && isVisible) {
      return (
        <tr>
          <td colSpan={9} className="vl-u-align-center">
            {t(i18nKeys.remittances.searchHistory.noImages)}
          </td>
        </tr>
      );
    }

    return (
      <tr>
        <td colSpan={9} className="vl-u-align-center" style={{ padding: 0 }}>
          <motion.div
            transition={transitions}
            variants={variantsVoucherImages}
            onAnimationComplete={onComplete}
            animate={isVisible ? 'visible' : 'hidden'}
          >
            {(isVisible || rowOpen) && <img alt="" src={imageUrl} />}
          </motion.div>
        </td>
      </tr>
    );
  },
);
