import { Bill, BillActionType } from '@kaa/api/providers';
import { ActionsHandler } from '@kaa/common/handlers';
import { toCurrencyFormat, useLuxon } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import { Icon, SwIcon, SwLink } from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modals } from '../../../constants';

type PickupBillingTableRowProps = {
  bill: Bill;
  openModal: (modal: Modals, bill: Bill) => void;
};

export const PickupBillingTableRow = ({
  bill,
  openModal,
}: PickupBillingTableRowProps) => {
  const { t } = useTranslation();
  const { DateTime } = useLuxon();

  const {
    reference,
    type,
    emissionDate,
    vatFreeAmount,
    vatIncludedAmount,
    resourceId,
  } = bill;

  return (
    <tr>
      <td
        style={{ verticalAlign: 'middle' }}
        data-title={t(i18nKeys.pickup.billing.table.reference)}
      >
        <ActionsHandler
          resourceId={resourceId}
          actions={[BillActionType.CAN_SEE_DETAILS]}
        >
          {({ isAllowed }) =>
            isAllowed ? (
              <SwLink
                tagName="button"
                onClick={(e) => {
                  e.preventDefault();
                  openModal(Modals.PICKUP_BILLS_DETAIL_MODAL, bill);
                }}
              >
                {reference}
              </SwLink>
            ) : (
              <>{reference}</>
            )
          }
        </ActionsHandler>
      </td>
      <td
        style={{ verticalAlign: 'middle' }}
        data-title={t(i18nKeys.pickup.billing.table.type)}
      >
        {t(i18nKeys.pickup.billing.billType[type])}
      </td>
      <td
        style={{ verticalAlign: 'middle' }}
        data-title={t(i18nKeys.pickup.billing.table.emissionDate)}
      >
        {DateTime.fromISO(emissionDate).toFormat('LL/yyyy')}
      </td>
      <td
        style={{ verticalAlign: 'middle' }}
        data-title={t(i18nKeys.pickup.billing.table.vatFreeAmount)}
      >
        {toCurrencyFormat(vatFreeAmount)}
      </td>
      <td
        style={{ verticalAlign: 'middle' }}
        data-title={t(i18nKeys.pickup.billing.table.vatIncludedAmount)}
      >
        {toCurrencyFormat(vatIncludedAmount)}
      </td>
      <td style={{ verticalAlign: 'middle' }}>
        <ActionsHandler
          resourceId={resourceId}
          actions={[BillActionType.CAN_DOWNLOAD]}
        >
          {({ isAllowed }) =>
            isAllowed ? (
              <div className="vl-u-display-flex vl-u-flex-v-center">
                <button
                  type="button"
                  style={{
                    border: 'none',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    color: '#0055cc',
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    openModal(Modals.PICKUP_BILLS_DOWNLOAD_MODAL, bill);
                  }}
                >
                  <SwIcon modLarge icon={Icon.FILE_DOWNLOAD} />
                  <br />
                  {t(i18nKeys.general.modal.export.cta.download)}
                </button>
              </div>
            ) : (
              <>{}</>
            )
          }
        </ActionsHandler>
      </td>
    </tr>
  );
};
