import { Relative, SubsidySummary } from '@kaa/api/providers';
import { Format, toCurrencyFormat, useLuxon } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import { SwLink } from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modals } from '../../../../constants';
import { getRelative } from '../RemittancesSubsidies.utils';

type RemittancesSubsidiesTableRowProps = {
  relatives: Relative[];
  subsidy: SubsidySummary;
  openRemittanceModal: (
    selectedSubsidy: SubsidySummary,
    modalId: Modals,
  ) => void;
};

export const RemittancesSubsidiesTableRow = ({
  relatives,
  subsidy,
  openRemittanceModal,
}: RemittancesSubsidiesTableRowProps) => {
  const { t } = useTranslation();
  const { DateTime } = useLuxon();

  const {
    providerId,
    paymentDate,
    paymentReference,
    isBlocked,
    amountReceived,
    remittance: {
      id: remittanceId,
      communication,
      effectiveMonth,
      effectiveYear,
      supportType,
      numberOfVouchers,
    },
  } = subsidy;

  const provider = getRelative(relatives, providerId);

  return (
    <tr>
      <td
        data-title={t(
          i18nKeys.remittances.reimbursement.search.table.remittancesId,
        )}
        style={{ verticalAlign: 'middle' }}
      >
        <SwLink
          tagName="button"
          onClick={() => {
            openRemittanceModal(
              subsidy,
              Modals.REMITTANCES_REIMBURSEMENT_DETAIL_MODAL,
            );
          }}
        >
          {remittanceId}
        </SwLink>
      </td>
      <td
        data-title={t(
          i18nKeys.remittances.reimbursement.search.table.remittanceReference,
        )}
        style={{ verticalAlign: 'middle' }}
      >
        {communication || '-'}
      </td>
      <td
        data-title={t(
          i18nKeys.remittances.reimbursement.search.table.monthOfWork,
        )}
        style={{ verticalAlign: 'middle' }}
      >
        {DateTime.fromFormat(
          `${effectiveYear}-${effectiveMonth}`,
          'yyyy-L',
        ).toFormat('LL/yyyy')}
      </td>
      <td
        data-title={t(
          i18nKeys.remittances.reimbursement.search.table.supportType,
        )}
        style={{ verticalAlign: 'middle' }}
      >
        {t(i18nKeys.general.supportType[supportType])}
      </td>
      <td
        data-title={t(
          i18nKeys.remittances.reimbursement.search.table.refundCenter,
        )}
        style={{ verticalAlign: 'middle' }}
      >
        {provider ? `${provider.name} - ${provider.id}` : providerId}
      </td>
      <td
        data-title={t(
          i18nKeys.remittances.reimbursement.search.table
            .refundedVoucherQuantity,
        )}
        style={{ verticalAlign: 'middle' }}
      >
        {numberOfVouchers}
      </td>
      <td
        data-title={t(
          i18nKeys.remittances.reimbursement.search.table.paymentAmount,
        )}
        style={{ verticalAlign: 'middle' }}
      >
        {amountReceived ? toCurrencyFormat(amountReceived) : '-'}
      </td>
      <td
        data-title={t(
          i18nKeys.remittances.reimbursement.search.table.dateOfPayment,
        )}
        style={{ verticalAlign: 'middle' }}
      >
        {Format.date(paymentDate)}
      </td>
      <td
        data-title={t(
          i18nKeys.remittances.reimbursement.search.table.paymentReference,
        )}
        style={{ verticalAlign: 'middle' }}
      >
        {paymentReference}
      </td>
      <td
        data-title={t(
          i18nKeys.remittances.reimbursement.search.table.seizureBlocking,
        )}
        style={{ verticalAlign: 'middle' }}
      >
        {isBlocked
          ? t(i18nKeys.remittances.general.yes)
          : t(i18nKeys.remittances.general.no)}
      </td>
    </tr>
  );
};
