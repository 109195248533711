import faker from 'faker';
import { DateTime } from 'luxon';
import {
  Activity,
  ApiErrorField,
  ElectronicVoucherRemittanceConfirmation,
  ElectronicVoucherRemittanceCreationDemand,
  FieldValidationErrorCode,
  GetIncorrectVouchersRemittanceParams,
  GetLastRemittancesParams,
  GetRefusedVouchersRemittanceParams,
  GetSubsidiesParams,
  ReimbursementRefDateReference,
  Remittance,
  RemittanceDetail,
  RemittanceDetailIncorrectResponse,
  RemittanceDetailRefused,
  RemittanceDetailRefusedResponse,
  RemittanceDetailResponse,
  RemittanceIncorrectVouchersBundle,
  RemittanceRefusedVoucher,
  RemittancesOverviewResponse,
  RemittancesResponse,
  RemittanceStatus,
  RemittanceVouchersBundle,
  RemittanceWithTotalQuantities,
  SupportType,
  Voucher,
  VoucherRefusalReason,
  VoucherStatus,
} from '../model';
import { getDataWithPagination } from './pagination';
import { getArray, getId, getRandomArray } from './utils';

const getRemittance = (
  providerId: string | number,
  params?: GetLastRemittancesParams,
): Remittance => {
  // !params ? History mode : Search mode

  const serviceDate = DateTime.fromJSDate(
    faker.date.between(
      params?.startDate
        ? DateTime.fromISO(params?.startDate).toJSDate()
        : DateTime.fromJSDate(faker.date.past()).toJSDate(),
      params?.endDate
        ? DateTime.fromISO(params?.endDate).toJSDate()
        : new Date(),
    ),
  ).toISODate();

  const creationDate = DateTime.fromJSDate(
    faker.date.between(DateTime.fromISO(serviceDate).toJSDate(), new Date()),
  ).toISODate();

  return {
    id: faker.random.number(999999),
    providerId: faker.random.number(999999),
    supportType: params?.supportType
      ? params.supportType
      : faker.helpers.randomize(Object.values(SupportType)),
    communication: faker.random.uuid(),
    serviceDate,
    creationDate,
    firstRefundDate: faker.date.past().toISOString(),
    refundedAmount: faker.random.number(),
  };
};

const getRemittanceWithTotalAmounts = (
  providerId: string | number,
  params?: GetLastRemittancesParams,
): RemittanceWithTotalQuantities => {
  // !params ? History mode : Search mode
  const voucherStatus = params?.voucherStatus
    ? params.voucherStatus
    : faker.helpers.randomize(Object.values(VoucherStatus));

  const announcedVouchers = faker.random.number({ min: 1, max: 30 });
  const incorrectVouchers =
    voucherStatus === VoucherStatus.INCORRECT
      ? faker.random.number({ min: 1, max: announcedVouchers })
      : 0;
  const refusedVouchers =
    voucherStatus === VoucherStatus.REFUSED
      ? faker.random.number({ min: 1, max: announcedVouchers })
      : 0;

  return {
    ...getRemittance(providerId, params),
    announcedVouchers,
    incorrectVouchers,
    refusedVouchers,
    validVouchers:
      voucherStatus === VoucherStatus.VALID
        ? announcedVouchers
        : announcedVouchers - incorrectVouchers - refusedVouchers,
    inProcessVouchers: faker.random.number(),
    readVouchers: faker.random.number(),
    remittanceStatus: faker.helpers.randomize(Object.values(RemittanceStatus)),
    isReady: faker.random.boolean(),
    hasImages: faker.random.boolean(),
    refundedAmount: faker.random.number(),
    firstRefundDate: faker.date.past().toISOString(),
  };
};

const getRemittancesWithTotalAmounts = (
  providerId: string | number,
  params?: GetLastRemittancesParams,
): RemittanceWithTotalQuantities[] => {
  if (!params) {
    // History mode
    return getRandomArray(30).map(() =>
      getRemittanceWithTotalAmounts(providerId),
    );
  }
  // Search mode
  return getArray(
    faker.random.number(120 - (10 + Object.values(params).length)),
  ).map(() => getRemittanceWithTotalAmounts(providerId, params));
};

export const getLastRemittancesResponse = (
  providerId: string | number,
  params?: GetLastRemittancesParams,
): RemittancesResponse =>
  getDataWithPagination<RemittanceWithTotalQuantities>(
    getRemittancesWithTotalAmounts(providerId, params).sort((a, b) => {
      const dateA = new Date(a.serviceDate);
      const dateB = new Date(b.serviceDate);
      if (dateA > dateB) {
        return -1;
      }
      if (dateA < dateB) {
        return 1;
      }
      return 0;
    }),
    params,
  );

export const getRemittancesOverviewResponse = (
  providerId: string | number,
): RemittancesOverviewResponse => {
  return {
    data: {
      incorrectVouchers: faker.random.number({ min: 0, max: 200 }),
      assignedVouchers: faker.random.number({ min: 0, max: 200 }),
    },
  };
};

export const RemittanceVoucherBundle = (): RemittanceVouchersBundle => ({
  emissionDate: faker.date.past().toISOString(),
  voucherTotalValue: faker.random.number(),
  voucherWorkDate: faker.date.past().toISOString(),
  reimbursementRefDateType: faker.helpers.randomize([
    ReimbursementRefDateReference.MONTH_OF_ISSUE,
    ReimbursementRefDateReference.MONTH_OF_WORK,
  ]),
  announcedVouchers: faker.random.number({ min: 0, max: 20 }),
  readVouchers: faker.random.number({ min: 0, max: 20 }),
  processingVouchers: faker.random.number({ min: 0, max: 20 }),
  incorrectVouchers: faker.random.number({ min: 0, max: 3 }),
  refusedVouchers: faker.random.number({ min: 0, max: 1 }),
  validVouchers: faker.random.number({ min: 0, max: 20 }),
});

export const getRemittanceDetail = (
  providerId: string | number,
): RemittanceDetail => ({
  ...getRemittance(providerId),
  voucherBundle: getRandomArray({ min: 1, max: 10 }).map(
    RemittanceVoucherBundle,
  ),
});

export const getRemittanceSummary = ({
  keyword = '',
  serviceMonthsPeriod,
  ...params
}: Partial<GetSubsidiesParams>) => {
  const serviceMonthPeriodDate = serviceMonthsPeriod
    ? DateTime.fromFormat(
        faker.helpers.randomize(serviceMonthsPeriod),
        'yyyy-LL',
      )
    : DateTime.fromJSDate(faker.date.past());

  return {
    effectiveMonth: serviceMonthPeriodDate.get('month'),
    effectiveYear: serviceMonthPeriodDate.get('year'),
    numberOfVouchers: faker.random.number({ min: 1, max: 100 }),
    communication: `${keyword}${faker.random
      .number({ min: 100000000, max: 999999999 })
      .toFixed(0)
      .toString()}`,
    supportType: params?.supportType
      ? params.supportType
      : faker.helpers.randomize(Object.values(SupportType)),
    id: Number(getId()),
  };
};

export const getRemittancesDetailResponse = (
  providerId: string | number,
): RemittanceDetailResponse => {
  return {
    data: getRemittanceDetail(providerId),
  };
};

export const getVoucher = (): Voucher => ({
  regionalAgreementId: faker.random.uuid().slice(0, 5),
  workerNiss: faker.random.number(99999999999).toString(),
  serviceDate: faker.date.past().toISOString(),
  activityTypes: getRandomArray({
    min: 1,
    max: Object.values(Activity).length,
  })
    .map(() => faker.helpers.randomize(Object.values(Activity)))
    .filter((item, i, array) => array.indexOf(item) === i),
  voucherId: faker.random.number(),
  issueDate: faker.date.past().toISOString(),
});

const FieldToValidate = {
  regionalAgreementId: 'regionalAgreementId' as keyof Voucher,
  workerNiss: 'workerNiss' as keyof Voucher,
  serviceDate: 'serviceDate' as keyof Voucher,
  activityTypes: 'activityTypes' as keyof Voucher,
  voucherImageUrl: 'voucherImageUrl' as keyof Voucher,
};

export const getRemittanceIncorrectVouchersBundle = (): RemittanceIncorrectVouchersBundle => {
  const voucher = getVoucher();
  const voucherErrors = faker.helpers
    .shuffle(Object.values(FieldToValidate))
    .slice(0, faker.random.number(Object.values(FieldToValidate).length))
    .map((fieldName) => getFieldValidation({ fieldName }));

  const dateError = voucherErrors.find((e) => e.fieldName === 'serviceDate');
  let { serviceDate } = voucher;

  if (
    dateError &&
    dateError.errors.includes(FieldValidationErrorCode.INVALID as never)
  ) {
    serviceDate = `${
      faker.random.boolean()
        ? '**'
        : DateTime.fromISO(serviceDate).toFormat('dd')
    }/${
      faker.random.boolean()
        ? '**'
        : DateTime.fromISO(serviceDate).toFormat('MM')
    }/${
      faker.random.boolean()
        ? '**'
        : DateTime.fromISO(serviceDate).toFormat('yy')
    }`;
  } else if (
    dateError &&
    dateError.errors.includes(FieldValidationErrorCode.MISSING_VALUE as never)
  ) {
    serviceDate = '';
  } else {
    serviceDate = DateTime.fromISO(serviceDate).toFormat('dd/MM/yy');
  }

  return {
    ...voucher,
    serviceDate,
    voucherErrors,
    voucherReference: faker.random.uuid(),
    isWorkerRegistered: faker.random.boolean(),
    isWorkerContracted: faker.random.boolean(),
    contractRequired: faker.random.boolean(),
    registerRequired: faker.random.boolean(),
    workerId: faker.random.number(),
    correctionLimitDate: faker.date.future().toISOString(),
    voucherStatus: faker.helpers.randomize(Object.values(VoucherStatus)),
    voucherImageUrl: 'https://placekitten.com/640/360',
  };
};

const getFieldValidation = (
  voucherErroField: Partial<ApiErrorField> = {},
): ApiErrorField => {
  const errors = [...Object.values(FieldValidationErrorCode)];
  return {
    fieldName: faker.lorem.word(),
    errors: faker.helpers
      .shuffle(errors)
      .slice(0, faker.random.number(errors.length)) as ApiErrorField['errors'],
    ...voucherErroField,
  };
};

export const getRemittanceDetailIncorrectResponse = (
  providerId: string | number,
  remittanceId: string | number,
  params?: GetIncorrectVouchersRemittanceParams,
): RemittanceDetailIncorrectResponse =>
  getDataWithPagination<RemittanceIncorrectVouchersBundle>(
    getRandomArray({ min: 20, max: 100 }).map(() =>
      getRemittanceIncorrectVouchersBundle(),
    ),
    params,
  );

const getVoucherRefusalReason = (
  params?: GetRefusedVouchersRemittanceParams,
): VoucherRefusalReason[] => {
  return getRandomArray({ min: 1, max: 3 })
    .reduce(
      (acc) => [
        ...acc,
        faker.helpers.randomize(
          Object.values(VoucherRefusalReason).filter(
            (value) => !acc.includes(value),
          ),
        ),
      ],
      [params?.reason],
    )
    .filter((e: VoucherRefusalReason) => e);
};

const getRemittanceDetailRefused = (
  params?: GetRefusedVouchersRemittanceParams,
): RemittanceDetailRefused => {
  return getRandomArray({ min: 1, max: 110 })
    .map(() => ({
      voucherReference: faker.random.uuid(),
      issueDate: DateTime.fromJSDate(faker.date.past()).toISODate(),
      refusalReasons: getVoucherRefusalReason(params),
      customerFullname: `${faker.name.firstName()} ${faker.name.lastName()}`,
      customerAuthorizationId: faker.random.uuid(),
    }))
    .sort((a, b) => {
      const dateA = new Date(a.issueDate);
      const dateB = new Date(b.issueDate);
      if (dateA > dateB) {
        return -1;
      }
      if (dateA < dateB) {
        return 1;
      }
      return 0;
    });
};

export const getRemittanceDetailRefusedResponse = (
  params?: GetRefusedVouchersRemittanceParams,
): RemittanceDetailRefusedResponse =>
  getDataWithPagination<RemittanceRefusedVoucher>(
    getRemittanceDetailRefused(params),
    params,
  );

export const postRemittance = (
  providerId: number | string,
  electronicVoucherRemittance: ElectronicVoucherRemittanceCreationDemand,
): ElectronicVoucherRemittanceConfirmation => {
  const date = faker.date.recent(90);
  return {
    data: {
      numberOfVouchers:
        electronicVoucherRemittance.bundle.length || faker.random.number(),
      remittanceId: faker.random.number(),
      amountToRefund: faker.random.number(),
      issuePeriod: `${date.getFullYear()}-${date.getMonth()}`,
      communication: faker.random.word(),
      providerName: faker.company.companyName(),
    },
  };
};
