/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */

export type VoucherErrors =
  | 'AGREEMENT_NUMBER_INCOMPLETE'
  | 'AGREEMENT_NUMBER_EMPTY'
  | 'NISS_WORKER_INCOMPLETE'
  | 'NISS_WORKER_EMPTY'
  | 'NISS_WORKER_NOT_REGISTERED'
  | 'CONTRACT_WORKER_NOT_REGISTERED'
  | 'DATE_OF_WORK_INCOMPLETE'
  | 'DATE_OF_WORK_EMPTY'
  | 'DATE_OF_WORK_EXCEEDS'
  | 'ACTIVITY_EMPTY';

export const VoucherErrors = {
  AGREEMENT_NUMBER_INCOMPLETE: 'AGREEMENT_NUMBER_INCOMPLETE' as VoucherErrors,
  AGREEMENT_NUMBER_EMPTY: 'AGREEMENT_NUMBER_EMPTY' as VoucherErrors,
  NISS_WORKER_INCOMPLETE: 'NISS_WORKER_INCOMPLETE' as VoucherErrors,
  NISS_WORKER_EMPTY: 'NISS_WORKER_EMPTY' as VoucherErrors,
  NISS_WORKER_NOT_REGISTERED: 'NISS_WORKER_NOT_REGISTERED' as VoucherErrors,
  CONTRACT_WORKER_NOT_REGISTERED: 'CONTRACT_WORKER_NOT_REGISTERED' as VoucherErrors,
  DATE_OF_WORK_INCOMPLETE: 'DATE_OF_WORK_INCOMPLETE' as VoucherErrors,
  DATE_OF_WORK_EMPTY: 'DATE_OF_WORK_EMPTY' as VoucherErrors,
  DATE_OF_WORK_EXCEEDS: 'DATE_OF_WORK_EXCEEDS' as VoucherErrors,
  ACTIVITY_EMPTY: 'ACTIVITY_EMPTY' as VoucherErrors,
};
