import { Icon, SwIcon } from '@kaa/ui-flanders/components';
import React from 'react';
import { Trans } from 'react-i18next';

export type TableHeadSortingProps = {
  text: string;
  label?: string;
  onClick?: () => void;
};

export const TableHeadSorting = ({
  text,
  label,
  onClick,
}: TableHeadSortingProps) => {
  // const { t } = useTranslation();
  return onClick ? (
    <button
      type="button"
      aria-label={label}
      style={{
        border: 'none',
        padding: '0',
        fontSize: 'inherit',
        fontWeight: 'inherit',
        overflow: 'hidden',
        position: 'relative',
        paddingRight: '2rem',
      }}
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
    >
      <Trans>{text}</Trans>

      <SwIcon
        style={{
          position: 'absolute',
          top: '2px',
          right: '0',
        }}
        icon={Icon.DATA_TRANSFER}
        className="vl-u-text--action"
      />
    </button>
  ) : (
    <Trans>{text}</Trans>
  );
};
