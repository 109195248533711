import { RegionCode, Relative, SubsidySummary } from '@kaa/api/providers';
import {
  toCurrencyFormat,
  useAsyncCallback,
  useLuxon,
} from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  SwColumn,
  SwDataTable,
  SwDescriptionData,
  SwDescriptionDataItem,
  SwFetchErrorMessage,
  SwLoader,
  SwTitle,
} from '@kaa/ui-flanders/components';
import React, { CSSProperties, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import get from 'lodash.get';
import { TableHead } from '../../../../../components';
import { useApi } from '../../../../../utils';
import { getRelative } from '../../RemittancesSubsidies.utils';
import { getConfig } from '../../../../../../common/config';

type RemittancesSubsidiesDetailModalProps = {
  relatives: Relative[];
  subsidySummary: SubsidySummary;
};

export const RemittancesSubsidiesDetailModal = ({
  relatives,
  subsidySummary,
}: RemittancesSubsidiesDetailModalProps) => {
  const { t } = useTranslation();
  const { DateTime } = useLuxon();
  const { providers } = useApi();

  const { providerId, id: subsidyId } = subsidySummary;

  const [
    { value: subsidyDetails, loading, error },
    getSubsidyDetails,
  ] = useAsyncCallback(
    async () => (await providers.getSubsidy(providerId, subsidyId)).data.data,
    [providers],
    { loading: true },
  );

  useEffect(() => {
    getSubsidyDetails();
  }, [getSubsidyDetails]);

  if (loading && !subsidyDetails) {
    return <SwLoader />;
  }

  if (error || !subsidyDetails) {
    return <SwFetchErrorMessage onClick={getSubsidyDetails} />;
  }

  const {
    paymentDate,
    paymentReference,
    isBlocked,
    amountReceived,
    voucherSets,
    remittance: {
      id: remittanceId,
      communication,
      effectiveMonth,
      effectiveYear,
      supportType,
      numberOfVouchers,
    },
  } = subsidyDetails;

  const provider = getRelative(relatives, providerId);

  const SwDescriptionDataItemStyle = {
    paddingTop: '1rem',
    paddingBottom: '1rem',
    margin: 0,
  };

  const SwDescriptionDataItemColumn = { width: '2', widthM: '6' };

  const regionCode = get(getConfig(), 'buildConfig.regionCode') as RegionCode;

  const head = [
    {
      text: t(i18nKeys.remittances.reimbursement.modal.detail.issuePeriod),
      style: { maxWidth: '10%', whiteSpace: 'inherit' } as CSSProperties,
      className: 'vl-u-align-center',
    },
    ...(regionCode === RegionCode.BE_WAL
      ? [
          {
            text: t(i18nKeys.remittances.reimbursement.modal.detail.workMonth),
            style: { maxWidth: '10%', whiteSpace: 'inherit' } as CSSProperties,
            className: 'vl-u-align-center',
          },
          {
            text: t(
              i18nKeys.remittances.reimbursement.modal.detail
                .subsidyCalculationBasis,
            ),
            style: { maxWidth: '10%', whiteSpace: 'inherit' } as CSSProperties,
            className: 'vl-u-align-center',
          },
        ]
      : []),
    {
      text: t(i18nKeys.remittances.reimbursement.modal.detail.voucherQuantity),
      style: { maxWidth: '10%', whiteSpace: 'inherit' } as CSSProperties,
      className: 'vl-u-align-center',
    },
    {
      text: t(i18nKeys.remittances.reimbursement.modal.detail.unitValue),
      style: { maxWidth: '10%', whiteSpace: 'inherit' } as CSSProperties,
      className: 'vl-u-align-center',
    },
    {
      text: t(i18nKeys.remittances.reimbursement.modal.detail.total),
      className: 'vl-u-align-center',
    },
  ];

  return (
    <>
      <SwTitle tagName="h2" className="vl-u-spacer--medium">
        {`${t(
          i18nKeys.remittances.reimbursement.modal.detail.title,
        )} ${remittanceId}`}
      </SwTitle>
      <SwTitle tagName="h3" tagStyle="h4">
        {t(i18nKeys.remittances.reimbursement.modal.detail.general.title)}
      </SwTitle>
      <SwColumn width="12" widthM="12" className="vl-u-align-left">
        <SwDescriptionData className="vl-u-spacer--small">
          <SwDescriptionDataItem
            label={remittanceId}
            column={SwDescriptionDataItemColumn}
            style={SwDescriptionDataItemStyle}
          >
            {t(i18nKeys.remittances.reimbursement.search.table.remittancesId)}
          </SwDescriptionDataItem>
          <SwDescriptionDataItem
            label={communication || '-'}
            column={SwDescriptionDataItemColumn}
            style={SwDescriptionDataItemStyle}
          >
            {t(
              i18nKeys.remittances.reimbursement.search.table
                .remittanceReference,
            )}
          </SwDescriptionDataItem>
          <SwDescriptionDataItem
            label={DateTime.fromFormat(
              `${effectiveYear}-${effectiveMonth}`,
              'yyyy-L',
            ).toFormat('LL/yyyy')}
            column={SwDescriptionDataItemColumn}
            style={SwDescriptionDataItemStyle}
          >
            {t(i18nKeys.remittances.reimbursement.search.table.monthOfWork)}
          </SwDescriptionDataItem>
          <SwDescriptionDataItem
            label={t(i18nKeys.general.supportType[supportType])}
            column={SwDescriptionDataItemColumn}
            style={SwDescriptionDataItemStyle}
          >
            {t(i18nKeys.remittances.reimbursement.search.table.supportType)}
          </SwDescriptionDataItem>
          <SwDescriptionDataItem
            label={provider ? `${provider.name} - ${provider.id}` : providerId}
            column={SwDescriptionDataItemColumn}
            style={SwDescriptionDataItemStyle}
          >
            {t(i18nKeys.remittances.reimbursement.search.table.refundCenter)}
          </SwDescriptionDataItem>
          <SwDescriptionDataItem
            label={
              isBlocked
                ? t(i18nKeys.remittances.general.yes)
                : t(i18nKeys.remittances.general.no)
            }
            column={SwDescriptionDataItemColumn}
            style={SwDescriptionDataItemStyle}
          >
            {t(i18nKeys.remittances.reimbursement.search.table.seizureBlocking)}
          </SwDescriptionDataItem>
          <SwDescriptionDataItem
            label={numberOfVouchers}
            column={SwDescriptionDataItemColumn}
            style={SwDescriptionDataItemStyle}
          >
            {t(
              i18nKeys.remittances.reimbursement.search.table
                .refundedVoucherQuantity,
            )}
          </SwDescriptionDataItem>
          <SwDescriptionDataItem
            label={amountReceived ? toCurrencyFormat(amountReceived) : '-'}
            column={SwDescriptionDataItemColumn}
            style={SwDescriptionDataItemStyle}
          >
            {t(i18nKeys.remittances.reimbursement.search.table.paymentAmount)}
          </SwDescriptionDataItem>
          <SwDescriptionDataItem
            label={DateTime.fromISO(paymentDate).toFormat('dd/LL/yyyy')}
            column={SwDescriptionDataItemColumn}
            style={SwDescriptionDataItemStyle}
          >
            {t(i18nKeys.remittances.reimbursement.search.table.dateOfPayment)}
          </SwDescriptionDataItem>
          <SwDescriptionDataItem
            label={paymentReference}
            column={SwDescriptionDataItemColumn}
            style={SwDescriptionDataItemStyle}
          >
            {t(
              i18nKeys.remittances.reimbursement.search.table.paymentReference,
            )}
          </SwDescriptionDataItem>
        </SwDescriptionData>
      </SwColumn>
      <hr
        className="vl-u-spacer--medium"
        style={{
          height: '.1rem',
          border: 'none',
          backgroundColor: '#cbd2da',
        }}
      />
      <SwTitle tagName="h3" tagStyle="h4">
        {t(i18nKeys.remittances.reimbursement.modal.detail.monthly.title)}
      </SwTitle>
      <SwColumn width="8" widthM="12" className="vl-u-align-left">
        <div className="vl-u-table-overflow vl-u-spacer--medium">
          <SwDataTable modLine>
            <TableHead elements={head} />
            <tbody>
              {voucherSets.map(
                ({
                  issuePeriod,
                  voucherQuantity,
                  voucherValue,
                  voucherWorkDate,
                  reimbursementRefDateType,
                }) => (
                  <tr key={`${issuePeriod}${voucherQuantity}${voucherValue}`}>
                    <td className="vl-u-align-center">
                      {DateTime.fromISO(issuePeriod).toFormat('LL/yyyy')}
                    </td>
                    {regionCode === RegionCode.BE_WAL && (
                      <>
                        <td className="vl-u-align-center">
                          {DateTime.fromISO(voucherWorkDate).toFormat(
                            'LL/yyyy',
                          )}
                        </td>
                        <td className="vl-u-align-center">
                          {t(
                            i18nKeys.remittances.detailModal.monthlyView[
                              reimbursementRefDateType
                            ],
                          ).toLowerCase()}
                        </td>
                      </>
                    )}
                    <td className="vl-u-align-center">{voucherQuantity}</td>
                    <td className="vl-u-align-center">
                      {toCurrencyFormat(voucherValue)}
                    </td>
                    <td className="vl-u-align-center">
                      {toCurrencyFormat(voucherQuantity * voucherValue)}
                    </td>
                  </tr>
                ),
              )}
            </tbody>
          </SwDataTable>
        </div>
      </SwColumn>
    </>
  );
};
