import { httpTo } from '@kaa/api/providers/utilities';
import { useAsyncCallback } from '@kaa/common/utils';
import { required } from '@kaa/common/validation';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AlertType,
  getAlertPropsByType,
  Icon,
  SwActionGroup,
  SwAlert,
  SwButton,
  SwForm,
  SwIcon,
  SwInfoTile,
  SwInputTextField,
  SwModalRenderProps,
  SwTitle,
} from '@kaa/ui-flanders/components';
import { Field, Formik, FormikActions } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dataTest } from '../../../../../datatest/keys';
import { useApi } from '../../../../../utils';
import {
  EventAction,
  EventCategory,
  EventLabel,
  sendCustomInteractionToGTM,
} from '../../../../../utils/google-analytics';
import {
  createValidatorWithServerErrorHandled,
  handleApiError,
} from '../../../../../utils/validation';
import { isValidVoucherNumber } from '../../RemittancesPaper.utils';
import { CheckVoucherValidityForm } from './RemittancesVoucherValidityModal.types';
import { getVoucherValidityAlert } from './RemittancesVoucherValidityModal.utils';

export const RemittancesVoucherValidityModal = ({
  setConfirmCloseModal,
}: SwModalRenderProps) => {
  const { t } = useTranslation();

  const { providers } = useApi();

  const [voucherValidity, setVoucherValidity] = useState<{
    type: AlertType;
    title: string;
    msg: string;
  }>();

  const [{ value: submitedForm }, submit] = useAsyncCallback(
    async (
      formikData: CheckVoucherValidityForm,
      formikActions: FormikActions<CheckVoucherValidityForm>,
    ) => {
      const { voucherReference } = formikData;

      setVoucherValidity(undefined);

      const [error, response] = await httpTo(
        providers.getPaperVoucherValidity(voucherReference),
      );

      return {
        error,
        response,
        formikData,
        formikActions,
      };
    },
  );

  useEffect(() => {
    if (submitedForm) {
      const { formikActions, response, error, formikData } = submitedForm;
      const { voucherReference } = formikData;
      const { setSubmitting, setStatus, resetForm } = formikActions;

      if (handleApiError(error, formikActions)) {
        return;
      }

      setSubmitting(false);

      if (!response) {
        return setStatus({
          type: AlertType.ERROR,
          msg: t(i18nKeys.general.paperVoucherValidityReference.ERROR),
        });
      }

      const {
        data: { data: validity },
      } = response;

      resetForm();

      setVoucherValidity(
        getVoucherValidityAlert(validity, voucherReference, t),
      );

      sendCustomInteractionToGTM(
        EventCategory.REMITTANCES,
        EventAction.FORM_CONFIRMATION,
        EventLabel.REMITTANCES_VOUCHER_VALIDITY,
      );
    }
  }, [submitedForm]);

  const validate = createValidatorWithServerErrorHandled<
    CheckVoucherValidityForm
  >({
    voucherReference: [isValidVoucherNumber, required],
  });

  return (
    <>
      <SwTitle tagName="h3" className="vl-u-spacer--medium">
        {t(i18nKeys.remittances.modal.checkVoucherValidity.title)}
      </SwTitle>
      {voucherValidity && (
        <SwAlert
          {...getAlertPropsByType(voucherValidity.type)}
          title={voucherValidity.title}
        >
          {voucherValidity.msg}
        </SwAlert>
      )}
      <Formik
        onSubmit={submit}
        initialValues={{ voucherReference: '' }}
        validate={validate}
      >
        {({ handleSubmit, dirty, isSubmitting }) => {
          setConfirmCloseModal(dirty);
          return (
            <SwForm onSubmit={handleSubmit} className="vl-u-spacer">
              <Field
                name="voucherReference"
                label={t(
                  i18nKeys.remittances.modal.checkVoucherValidity.serialInput
                    .label,
                )}
                column={{ width: '12' }}
                labelColumn={{ width: '12' }}
                component={SwInputTextField}
                modFocus
              />
              <SwActionGroup className="vl-u-spacer-large">
                <SwButton
                  data-testid={
                    dataTest.remittances.paper.deliverySlip.submitTemplateButton
                  }
                  type="submit"
                  modDisabled={!dirty}
                  modLoading={isSubmitting}
                >
                  {t(
                    i18nKeys.remittances.modal.checkVoucherValidity.cta
                      .checkValidity,
                  )}
                </SwButton>
              </SwActionGroup>
            </SwForm>
          );
        }}
      </Formik>
      <SwTitle tagName="h5" className="vl-u-spacer--small">
        {t(i18nKeys.remittances.modal.checkVoucherValidity.description.title)}
      </SwTitle>
      <SwInfoTile
        badge={<SwIcon icon={Icon.SCAN} />}
        className="vl-u-spacer"
        style={{ border: 'none', padding: 0 }}
        modSubTitleBig
        tagName="div"
        title={t(
          i18nKeys.remittances.modal.checkVoucherValidity.description.scanner
            .title,
        )}
        subtitle={t(
          i18nKeys.remittances.modal.checkVoucherValidity.description.scanner
            .content,
        )}
      />
      <div className="vl-u-hr vl-u-spacer" />
      <SwInfoTile
        badge={<SwIcon icon={Icon.FIELD} />}
        style={{ border: 'none', padding: 0 }}
        modSubTitleBig
        tagName="div"
        title={t(
          i18nKeys.remittances.modal.checkVoucherValidity.description.noScanner
            .title,
        )}
        subtitle={t(
          i18nKeys.remittances.modal.checkVoucherValidity.description.noScanner
            .content,
        )}
      />
    </>
  );
};
