import { BillType, GetProviderBillsParams } from '@kaa/api/providers';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  Icon,
  SwActionGroup,
  SwButton,
  SwColumn,
  SwForm,
  SwFormGrid,
  SwFormSubmitMessage,
  SwSelectField,
} from '@kaa/ui-flanders/components';
import { Field, Formik, FormikActions } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

type PickupBillingFormProps = {
  parameters: GetProviderBillsParams;
  submit: (
    params: GetProviderBillsParams,
    formikActions: FormikActions<GetProviderBillsParams>,
  ) => void;
};

const MINUS_YEAR = 2014;

export const PickupBillingForm = ({
  parameters,
  submit,
}: PickupBillingFormProps) => {
  const { t } = useTranslation();

  const yearsCount = new Date().getFullYear() - MINUS_YEAR + 1;
  const yearsValues = [...Array(yearsCount)]
    .map((e, i) => ({
      value: MINUS_YEAR + i,
      text: MINUS_YEAR + i,
    }))
    .sort((a, b) => b.value - a.value);

  const typeValues = Object.values(BillType).map((value) => ({
    value,
    text: t(i18nKeys.pickup.billing.billType[value]),
  }));

  return (
    <SwColumn
      className="vl-u-bg-alt vl-u-spacer--medium"
      style={{ padding: '2rem' }}
    >
      <Formik<GetProviderBillsParams>
        initialValues={parameters}
        onSubmit={submit}
        enableReinitialize
        render={({ handleSubmit, dirty, isSubmitting }) => (
          <SwForm onSubmit={handleSubmit}>
            <SwFormGrid
              className="vl-u-display-flex vl-u-spacer--small"
              modStacked
            >
              <SwColumn width="4" widthM="6" widthS="12">
                <Field
                  name="year"
                  label={t(i18nKeys.general.label.year)}
                  options={yearsValues}
                  component={SwSelectField}
                  column={{ width: '12' }}
                  labelColumn={{ width: '12' }}
                  modNoPlaceholder
                />
              </SwColumn>
              <SwColumn width="4" widthM="6" widthS="12">
                <Field
                  name="type"
                  label={t(i18nKeys.pickup.billing.table.type)}
                  options={typeValues}
                  component={SwSelectField}
                  column={{ width: '12' }}
                  labelColumn={{ width: '12' }}
                />
              </SwColumn>
              <SwColumn
                width="4"
                widthM="6"
                widthS="12"
                style={{ alignSelf: 'flex-end' }}
              >
                <SwActionGroup modCollapseS>
                  <SwButton
                    type="submit"
                    icon={Icon.SYNCHRONIZE}
                    modLoading={isSubmitting}
                    modDisabled={!dirty}
                    modIconBefore
                  >
                    {t(i18nKeys.general.search)}
                  </SwButton>
                </SwActionGroup>
              </SwColumn>
              <SwFormSubmitMessage />
            </SwFormGrid>
          </SwForm>
        )}
      />
    </SwColumn>
  );
};
