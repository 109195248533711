import {
  FileExtensionReference,
  PaperVoucherAnnouncement,
  RegionCode,
} from '@kaa/api/providers';
import { httpTo } from '@kaa/api/providers/utilities';
import { useAsyncCallback } from '@kaa/common/utils';
import { required } from '@kaa/common/validation';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AlertType,
  Icon,
  SwActionGroup,
  SwButton,
  SwFetchErrorMessage,
  SwForm,
  SwFormSubmitMessage,
  SwIconList,
  SwIconListItem,
  SwLoader,
  SwModalRenderProps,
  SwSelectField,
  SwTitle,
  SwUploadField,
} from '@kaa/ui-flanders/components';
import { Field, Formik, FormikActions } from 'formik';
import chunk from 'lodash.chunk';
import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { getConfig } from '../../../../../../common/config';
import { DownloadPublicTemplates } from '../../../../../components';
import { Modals, Templates } from '../../../../../constants';
import { useApi, useSelectedProviderIdState } from '../../../../../utils';
import {
  EventAction,
  EventCategory,
  EventLabel,
  sendCustomInteractionToGTM,
  sendPageViewEvent,
} from '../../../../../utils/google-analytics';
import { getAllowedFileTypes } from '../../../../../utils/templates';
import {
  createValidatorWithServerErrorHandled,
  handleApiError,
} from '../../../../../utils/validation';
import {
  PaperVoucherWithValidation,
  RemittancesVouchersDeclarationForm,
} from './RemittancesVoucherDeclaration.types';
import {
  findRegionalAgreementIdByProviderId,
  getErrorsFromPaperVoucher,
} from './RemittancesVoucherDeclaration.utils';
import { RemittancesVouchersDeclarationEdit } from './RemittancesVouchersDeclarationEdit';

const VOUCHERS_DECLARATION_MAX_FILESIZE = 2000000; // 2mb
const VOUCHERS_CHUNK_NUMBER = 10;

type RemittancesVouchersDeclarationModalProps = SwModalRenderProps & {
  onSuccess: () => void;
};

const sortVouchers = (vouchers: PaperVoucherWithValidation[]) =>
  vouchers.sort((a, b) => a.recordNumber - b.recordNumber);

export const RemittancesVouchersDeclarationModal = ({
  onSuccess,
  setConfirmCloseModal,
}: RemittancesVouchersDeclarationModalProps) => {
  const { t } = useTranslation();
  const providerId = useSelectedProviderIdState();
  const { providers: providersApi } = useApi();

  const [vouchers, setVouchers] = useState<PaperVoucherWithValidation[]>([]);
  const [voucherProcessed, setVouchersProcessed] = useState<{
    total: number;
    current: number;
  }>({ total: 0, current: 0 });

  const removeVoucher = useCallback(
    (recordNumber: number) => {
      setVouchers((currentVouchers) =>
        sortVouchers(
          currentVouchers.filter(
            (voucher) => voucher.recordNumber !== recordNumber,
          ),
        ),
      );

      sendCustomInteractionToGTM(
        EventCategory.REMITTANCES_VOUCHERS_DECLARATION,
        EventAction.FORM_CONFIRMATION,
        EventLabel.REMITTANCES_DECLARATION_DELETE_ROW_LIST,
      );
    },
    [setVouchers],
  );

  const [{ value: relatives, loading, error }, getRelatives] = useAsyncCallback(
    async () => {
      const {
        data: {
          data: { headquarter, subsidiaries },
        },
      } = await providersApi.getProviderRelatives(providerId);

      return [headquarter, ...subsidiaries.filter(({ isActive }) => isActive)];
    },
    [providersApi],
    { loading: true },
  );

  useEffect(() => {
    getRelatives();
  }, [getRelatives]);

  const [
    { value: fileParserData, loading: parserLoading },
    postVoucherAnnoucementsFileParser,
  ] = useAsyncCallback(
    async (
      formikData: RemittancesVouchersDeclarationForm,
      formikActions: FormikActions<RemittancesVouchersDeclarationForm>,
    ) => {
      const { setSubmitting, setStatus } = formikActions;
      const { id, exploitationFiles } = formikData;
      const [error, payload] = await httpTo(
        providersApi.parseAnnouncementsFile(Number(id), exploitationFiles[0]),
      );

      if (handleApiError(error, formikActions)) {
        return;
      }

      if (!payload) {
        setSubmitting(false);
        setStatus({
          msg: t(
            i18nKeys.remittances.modal.declareVoucher.form.statusMessage.error,
          ),
          type: AlertType.ERROR,
        });
        return;
      }

      const {
        data: { data },
      } = payload;

      return {
        paperVoucherAnnouncements: data,
        formikActions,
        formikData,
      };
    },
    [providersApi],
  );

  useEffect(() => {
    if (fileParserData) {
      const {
        paperVoucherAnnouncements,
        formikActions,
        formikData,
      } = fileParserData;

      const { id, regionalAgreementId } = formikData;

      setVouchersProcessed({
        total: paperVoucherAnnouncements.length,
        current: 0,
      });

      const chunkedVouchers = chunk(
        paperVoucherAnnouncements,
        VOUCHERS_CHUNK_NUMBER,
      );

      chunkedVouchers.map(async (announcements) =>
        postVoucherAnnoucements(
          {
            id,
            announcements,
            regionalAgreementId,
          },
          formikActions,
        ),
      );

      sendCustomInteractionToGTM(
        EventCategory.REMITTANCES_VOUCHERS_DECLARATION,
        EventAction.FORM_CONFIRMATION,
        EventLabel.REMITTANCES_DECLARATION_UPLOAD_LIST,
      );
      sendPageViewEvent({
        pathname: `/paper/${Modals.REMITTANCES_VOUCHERS_DECLARATION_MODAL_ID}/voucher_list_to_correct`,
      });
    }
  }, [fileParserData]);

  const [
    { value: newVoucherAnnoucements },
    postVoucherAnnoucements,
  ] = useAsyncCallback(
    async (
      props: {
        id: number;
        announcements: PaperVoucherAnnouncement[];
        regionalAgreementId: string;
      },
      formikActions: FormikActions<RemittancesVouchersDeclarationForm>,
    ) => {
      const { id, announcements, regionalAgreementId } = props;
      const { setSubmitting, setStatus } = formikActions;
      const [error, payload] = await httpTo(
        providersApi.createVoucherAnnouncement(Number(id), {
          regionalAgreementId,
          announcements,
        }),
      );

      if (handleApiError(error, formikActions)) {
        return;
      }

      if (!payload) {
        setSubmitting(false);
        setStatus({
          msg: t(
            i18nKeys.remittances.modal.declareVoucher.form.statusMessage.error,
          ),
          type: AlertType.ERROR,
        });
        return;
      }

      const newVouchers = payload.data.data.map((paperVoucher) => {
        const errors = getErrorsFromPaperVoucher(paperVoucher);

        const annoucement = announcements.find(
          ({ recordNumber }) => paperVoucher.recordNumber === recordNumber,
        );

        return {
          ...annoucement,
          ...(annoucement && {
            workerName: annoucement.workerNiss,
          }),
          errors,
          regionalAgreementId,
          recordNumber: paperVoucher.recordNumber,
          id,
        };
      });

      return newVouchers;
    },
    [providersApi],
  );

  useEffect(() => {
    if (newVoucherAnnoucements) {
      setVouchers((oldVouchers) =>
        sortVouchers([...oldVouchers, ...newVoucherAnnoucements]),
      );
      setVouchersProcessed(({ total, current }) => ({
        total,
        current:
          current + VOUCHERS_CHUNK_NUMBER > total
            ? total
            : current + VOUCHERS_CHUNK_NUMBER,
      }));
    }
  }, [newVoucherAnnoucements, setVouchers, setVouchersProcessed]);

  const [, getWorkers] = useAsyncCallback(
    async (providerId, query: string) => {
      const {
        data: { data },
      } = await providersApi.getWorkers(providerId, {
        workerNameOrNiss: query,
      });
      return data;
    },
    [providersApi],
  );

  const [
    { value: submitedVoucherAnnoucementsForm },
    submitRow,
  ] = useAsyncCallback(
    async (
      formikData: PaperVoucherWithValidation,
      formikActions: FormikActions<PaperVoucherWithValidation>,
    ) => {
      const {
        id,
        voucherReference = '',
        workerNiss = '',
        workDate,
        activityType,
        recordNumber,
        regionalAgreementId,
      } = formikData;

      const [error, response] = await httpTo(
        providersApi.createVoucherAnnouncement(Number(id), {
          regionalAgreementId,
          announcements: [
            {
              voucherReference,
              workerNiss,
              workDate,
              activityType,
              recordNumber,
            },
          ],
        }),
      );

      sendCustomInteractionToGTM(
        EventCategory.REMITTANCES_VOUCHERS_DECLARATION,
        EventAction.FORM_CONFIRMATION,
        EventLabel.REMITTANCES_DECLARATION_CORRECT_ROW_LIST,
      );

      return {
        error,
        response,
        formikActions,
        formikData,
      };
    },
    [providersApi],
  );

  useEffect(() => {
    if (submitedVoucherAnnoucementsForm) {
      const {
        formikActions,
        error,
        response,
        formikData,
      } = submitedVoucherAnnoucementsForm;
      const { setSubmitting, setStatus, resetForm } = formikActions;
      if (handleApiError(error, formikActions)) {
        return;
      }

      if (!response) {
        setSubmitting(false);
        setStatus({
          msg: t(
            i18nKeys.remittances.modal.declareVoucher.form.statusMessage.error,
          ),
          type: AlertType.ERROR,
        });
        return;
      }

      const [paperVoucher] = response.data.data;

      if (paperVoucher && !isEmpty(paperVoucher.validations)) {
        const errors = getErrorsFromPaperVoucher(paperVoucher);

        resetForm({
          ...formikData,
          errors,
        });
        setSubmitting(false);

        return;
      }

      setSubmitting(false);

      onSuccess();

      removeVoucher(formikData.recordNumber);
    }
  }, [submitedVoucherAnnoucementsForm, removeVoucher]);

  if (loading) {
    return <SwLoader />;
  }

  if (error || !Array.isArray(relatives)) {
    return <SwFetchErrorMessage onClick={() => getRelatives} />;
  }

  if (voucherProcessed.current > 0) {
    return (
      <RemittancesVouchersDeclarationEdit
        vouchers={vouchers}
        submitRow={submitRow}
        loading={parserLoading}
        voucherProcessed={voucherProcessed}
        getWorkers={getWorkers}
        removeVoucher={removeVoucher}
        setConfirmCloseModal={setConfirmCloseModal}
      />
    );
  }

  const relativesOptions = relatives.map(({ id, name }) => ({
    value: id,
    text: `${name} - ${id}`,
  }));

  const validate = createValidatorWithServerErrorHandled({
    id: [required],
    exploitationFiles: [required],
  });

  const regionCode = get(getConfig(), 'buildConfig.regionCode') as RegionCode;

  return (
    <>
      <SwTitle tagName="h2" className="vl-u-spacer--medium">
        {t(i18nKeys.remittances.modal.declareVouchers.title)}
      </SwTitle>
      <SwTitle tagName="h3" tagStyle="h5" className="vl-u-spacer--small">
        {t(i18nKeys.remittances.modal.declareVouchers.stepTitle)}
      </SwTitle>
      <SwIconList className="vl-u-spacer--medium">
        <SwIconListItem
          className="vl-u-hr"
          style={{ paddingBottom: '1rem' }}
          icon={
            regionCode === RegionCode.BE_VLG
              ? Icon.DATA_DOWNLOAD
              : Icon.FILE_DOWNLOAD
          }
          modLarge
        >
          <Trans
            i18nKey={i18nKeys.remittances.modal.declareVouchers.step1.title}
          />
          <DownloadPublicTemplates templates={Templates.VOUCHER_ANNOUNCEMENT} />
        </SwIconListItem>
        <SwIconListItem
          className="vl-u-hr vl-typography"
          style={{ paddingBottom: '1rem' }}
          icon={regionCode === RegionCode.BE_VLG ? Icon.EDIT : Icon.FILE_EDIT}
          modLarge
        >
          <Trans
            i18nKey={i18nKeys.remittances.modal.declareVouchers.step2.title}
          />
          <ul className="vl-u-text--small">
            <li>
              <Trans
                i18nKey={
                  i18nKeys.remittances.modal.declareVouchers.step2.list.item1
                }
              />
            </li>
            <li>
              <Trans
                i18nKey={
                  i18nKeys.remittances.modal.declareVouchers.step2.list.item2
                }
              />
            </li>
            <li>
              <Trans
                i18nKey={
                  i18nKeys.remittances.modal.declareVouchers.step2.list.item3
                }
              />
            </li>
            <li>
              <Trans
                i18nKey={
                  i18nKeys.remittances.modal.declareVouchers.step2.list.item4
                }
              />
            </li>
          </ul>
        </SwIconListItem>
        <SwIconListItem
          className="vl-u-hr"
          style={{ paddingBottom: '1rem' }}
          icon={
            regionCode === RegionCode.BE_VLG
              ? Icon.DATA_UPLOAD
              : Icon.FILE_UPLOAD
          }
          modLarge
        >
          <Trans
            i18nKey={i18nKeys.remittances.modal.declareVouchers.step3.title}
          />
        </SwIconListItem>
      </SwIconList>
      <SwTitle tagName="h3" tagStyle="h5" className="vl-u-spacer--small">
        {t(i18nKeys.remittances.modal.declareVouchers.stepUpload)}
      </SwTitle>
      <Formik
        onSubmit={postVoucherAnnoucementsFileParser}
        validate={validate}
        initialValues={{
          id: providerId,
          regionalAgreementId: findRegionalAgreementIdByProviderId(
            relatives,
            providerId,
          ),
          exploitationFiles: [],
        }}
      >
        {({ handleSubmit, dirty, isSubmitting, setFieldValue }) => {
          setConfirmCloseModal(dirty);
          return (
            <SwForm onSubmit={handleSubmit} className="vl-u-spacer">
              <Field
                className="vl-u-spacer"
                name="id"
                label={t(i18nKeys.general.exploitationName)}
                column={{ width: '12' }}
                labelColumn={{ width: '12' }}
                options={relativesOptions}
                component={SwSelectField}
                inputOnChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const regionalAgreementId = findRegionalAgreementIdByProviderId(
                    relatives,
                    e.target.value,
                  );

                  setFieldValue('regionalAgreementId', regionalAgreementId);
                }}
              />
              <Field
                name="exploitationFiles"
                label={t(
                  i18nKeys.remittances.modal.declareVoucher.form.labels
                    .vouchersList,
                )}
                maxFilesize={VOUCHERS_DECLARATION_MAX_FILESIZE}
                allowedFileTypes={getAllowedFileTypes([
                  FileExtensionReference.XLS,
                  FileExtensionReference.XLSX,
                  FileExtensionReference.CSV,
                ])}
                column={{ width: '12' }}
                labelColumn={{ width: '12' }}
                component={SwUploadField}
              />
              <SwActionGroup className="vl-u-spacer">
                <SwButton
                  type="submit"
                  modDisabled={!dirty}
                  modLoading={isSubmitting}
                >
                  {t(i18nKeys.remittances.modal.declareVouchers.cta.title)}
                </SwButton>
              </SwActionGroup>
              <SwFormSubmitMessage />
            </SwForm>
          );
        }}
      </Formik>
    </>
  );
};
