import {
  ExportRefusedVouchersRemittanceParams,
  FileExtensionReference,
  RemittanceWithTotalQuantities,
} from '@kaa/api/providers';
import { httpTo } from '@kaa/api/providers/utilities';
import { useAsync, useAsyncCallback } from '@kaa/common/utils';
import { DownloadTemplatesActions } from '@kaa/core-app/providers/components';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import { SwFetchErrorMessage, SwLoader } from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modals } from '../../../../../constants';
import { useApi } from '../../../../../utils';
import { getApplicationType } from '../../../../../utils/templates';

type RemittancesSearchHistoryRefusedModalDownloadProps = {
  remittance: RemittanceWithTotalQuantities;
  parameters?: ExportRefusedVouchersRemittanceParams;
};
export const RemittancesSearchHistoryRefusedModalDownload = ({
  remittance,
  parameters,
}: RemittancesSearchHistoryRefusedModalDownloadProps) => {
  const { t } = useTranslation();
  const { providers } = useApi();

  const { providerId, id: remittanceId } = remittance;

  const [{ value: templateLink, loading, error }, getExport] = useAsyncCallback(
    async () => {
      const [, payload] = await httpTo(
        providers.exportRefusedVouchersRemittance(
          providerId,
          remittanceId,
          parameters,
        ),
      );

      if (!payload) {
        return undefined;
      }

      const blob = new Blob([payload.data], {
        type: getApplicationType(
          parameters?.fileExtension || FileExtensionReference.XLSX,
        ),
      });

      const url = window.URL.createObjectURL(blob);

      return { url, blob };
    },
    [providers],
    { loading: true },
  );

  useAsync(getExport);

  if (loading) {
    return <SwLoader modMessageHidden />;
  }

  if (error || !templateLink) {
    return <SwFetchErrorMessage onClick={getExport} />;
  }

  return (
    <DownloadTemplatesActions
      fromModal={Modals.REMITTANCES_SEARCH_HISTORY_REFUSED_MODAL}
      fileName={t(i18nKeys.remittances.refusedModal.downloadTemplate.fileName)}
      linkToTemplate={templateLink.url}
      blob={templateLink.blob}
    />
  );
};
