/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */

export type LanguageCode = 'FR' | 'NL' | 'DE' | 'EN';

export const LanguageCode = {
  FR: 'FR' as LanguageCode,
  NL: 'NL' as LanguageCode,
  DE: 'DE' as LanguageCode,
  EN: 'EN' as LanguageCode,
};
