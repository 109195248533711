import { Mask } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import { SwFormGrid, SwInputTextField } from '@kaa/ui-flanders/components';
import { Field } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { dataTest } from '../../../../../datatest/keys';
import { RelativeDetailsScreenFieldNames } from '../RealtiveDetailsScreen.constants';

type RelativeDetailsCommunicationFormProps = {
  modDisabled: boolean;
};

export const RelativeDetailsCommunicationForm = ({
  modDisabled,
}: RelativeDetailsCommunicationFormProps) => {
  const { t } = useTranslation();

  return (
    <>
      <SwFormGrid
        className="vl-u-spacer"
        modStacked
        data-testid={dataTest.relatives.details.detailRelativeCommunicationForm}
      >
        <Field
          name={RelativeDetailsScreenFieldNames.PHONE_NUMBER}
          label={t(i18nKeys.general.label.phone)}
          component={SwInputTextField}
          type="tel"
          modDisabled={modDisabled}
          mask={Mask.phoneNumber}
        />

        <Field
          name={RelativeDetailsScreenFieldNames.MOBILE_PHONE_NUMBER}
          label={t(i18nKeys.general.label.mobilePhone)}
          component={SwInputTextField}
          type="tel"
          modDisabled={modDisabled}
          mask={Mask.mobilePhoneNumber}
        />

        <Field
          name={RelativeDetailsScreenFieldNames.COMMERCIAL_EMAIL_ADDRESS}
          label={t(i18nKeys.general.label.emailAddress)}
          component={SwInputTextField}
          type="email"
          modDisabled={modDisabled}
        />

        <Field
          name={RelativeDetailsScreenFieldNames.WEBSITE}
          label={t(i18nKeys.general.label.website)}
          component={SwInputTextField}
          modDisabled={modDisabled}
        />
      </SwFormGrid>
    </>
  );
};
