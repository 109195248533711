import { i18nKeys } from '@kaa/i18n/providers/keys';
import { SwColumn, SwGrid, SwTitle } from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DashboardOverviewProps } from '../Dashboard.types';
import { DashboardOverviewPickups } from './DashboardOverviewPickups';
import { DashboardOverviewRemittances } from './DashboardOverviewRemittances';
import { DashboardOverviewWorks } from './DashboardOverviewWorks';

export const DashboardOverview = (props: DashboardOverviewProps) => {
  const { t } = useTranslation();

  return (
    <SwColumn className="vl-u-spacer">
      <SwTitle tagName="h2" className="vl-u-spacer--medium">
        {t(i18nKeys.dashboard.overview.title)}
      </SwTitle>
      <SwGrid
        modVStretched
        style={{
          width: '100%',
          justifyContent: 'flex-start',
        }}
      >
        <DashboardOverviewWorks {...props} />
        <DashboardOverviewRemittances {...props} />
        <DashboardOverviewPickups {...props} />
      </SwGrid>
    </SwColumn>
  );
};
