import { BillDetailsDataTasksItem } from '@kaa/api/providers';
import { toCurrencyFormat, useLuxon } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import React from 'react';
import { useTranslation } from 'react-i18next';

type PickupBillingDetailsModalTableRowProps = {
  task: BillDetailsDataTasksItem;
};

export const PickupBillingDetailsModalTableRow = ({
  task,
}: PickupBillingDetailsModalTableRowProps) => {
  const { t } = useTranslation();
  const { DateTime } = useLuxon();

  const { commercialName, providerId, consumptionDate, vatFreeAmount } = task;

  return (
    <tr>
      <td
        style={{ verticalAlign: 'middle' }}
        data-title={t(i18nKeys.pickup.billing.detail.table.enterpriseName)}
      >
        {commercialName}
      </td>
      <td
        style={{ verticalAlign: 'middle' }}
        data-title={t(i18nKeys.general.label.authorizationNumber)}
      >
        {providerId}
      </td>
      <td
        style={{ verticalAlign: 'middle' }}
        data-title={t(i18nKeys.pickup.billing.detail.table.pickupDate)}
      >
        {DateTime.fromISO(consumptionDate).toFormat('dd/MM/yyyy')}
      </td>
      <td
        style={{ verticalAlign: 'middle' }}
        data-title={t(i18nKeys.pickup.billing.table.vatFreeAmount)}
      >
        {toCurrencyFormat(vatFreeAmount)}
      </td>
    </tr>
  );
};
