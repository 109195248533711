import {
  ElectronicManualRemittanceForm,
  ElectronicManualRemittanceResultsForm,
} from './RemittancesManualModal.types';

export const ElectronicManualRemittanceFieldNames = {
  RELATED_PROVIDER_ID: 'relatedProviderId' as keyof ElectronicManualRemittanceForm,
  SERVICE_MONTH_PERIOD: 'serviceMonthPeriod' as keyof ElectronicManualRemittanceForm,
  FULLY_ASSIGNED_SERVICES: 'onlyFullyAssignedServices' as keyof ElectronicManualRemittanceForm,
};

export const ElectronicManualRemittanceResultsFieldNames = {
  COMMUNICATION: 'communication' as keyof ElectronicManualRemittanceResultsForm,
  BUNDLE: 'bundle' as keyof ElectronicManualRemittanceResultsForm,
  ALL_SERVICES_SELECTED: 'allServicesSelected' as keyof ElectronicManualRemittanceResultsForm,
};

export const REMITTANCE_MANUAL_MODAL_PAGE_SIZE = 50;
