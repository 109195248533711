import { i18nKeys } from '@kaa/i18n/providers/keys';
import { SwLink } from '@kaa/ui-flanders/components';
import { getPath, Routes } from '@kaa/core-app/providers/routes';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  DashboardActionEvent,
  sendDashboardActionEventToGTM,
} from '../../../utils/google-analytics';
import { DashboardActionsCard } from './components/DashboardActionsCard';

export const DashboardActionsFinancialContact = () => {
  const { t } = useTranslation();

  const actionsCardProps = {
    title: t(i18nKeys.dashboard.actions.financialContact.title),
    tagName: 'div',
  };

  return (
    <DashboardActionsCard {...actionsCardProps}>
      <ul className="vl-link-list">
        <li className="vl-link-list__item" style={{ display: 'flex' }}>
          <i
            className="vl-link__icon vl-link__icon--before vl-vi vl-vi-arrow-right-fat"
            aria-hidden="true"
          />
          <SwLink
            to={getPath(Routes.PICKUP_BILLING)}
            style={{
              padding: '0 0 0 .8rem',
              display: 'inline-block',
            }}
            onClick={() => {
              sendDashboardActionEventToGTM(
                DashboardActionEvent.CLICK_ACTION,
                ' tracking,',
              );
            }}
          >
            {t(i18nKeys.dashboard.actions.financialContact.description)}
          </SwLink>
        </li>
      </ul>
    </DashboardActionsCard>
  );
};
