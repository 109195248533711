import { SwColumn, SwInfoTileSpotlight } from '@kaa/ui-flanders/components';
import React, { PropsWithChildren } from 'react';

type PickupCardProps = {
  title: string;
  text: string;
  icon?: string;
};

export const PickupCard = ({
  title,
  text,
  icon,
  children,
}: PropsWithChildren<PickupCardProps>) => {
  return (
    <SwColumn width="3" widthM="6" widthS="12" className="vl-u-spacer">
      <SwInfoTileSpotlight
        modVCenter
        modBadge
        modMedium
        style={{ height: '100%' }}
        title={title}
        icon={icon}
        footer={children}
      >
        {text}
      </SwInfoTileSpotlight>
    </SwColumn>
  );
};
