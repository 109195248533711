/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */

export type WorkingDays =
  | 'MONDAY'
  | 'TUESDAY'
  | 'WEDNESDAY'
  | 'THURSDAY'
  | 'FRIDAY';

export const WorkingDays = {
  MONDAY: 'MONDAY' as WorkingDays,
  TUESDAY: 'TUESDAY' as WorkingDays,
  WEDNESDAY: 'WEDNESDAY' as WorkingDays,
  THURSDAY: 'THURSDAY' as WorkingDays,
  FRIDAY: 'FRIDAY' as WorkingDays,
};
