import { ContactPerson, Sorting, Title } from '@kaa/api/providers';
import { httpTo } from '@kaa/api/providers/utilities';
import { ActionType, useActionDispatch } from '@kaa/common/context';
import { getPath, Routes } from '@kaa/core-app/providers/routes';
import { useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AlertType,
  SwActionGroup,
  SwButton,
  SwColumn,
  SwForm,
  SwFormGrid,
  SwFormSubmitMessage,
  SwGrid,
  SwLink,
} from '@kaa/ui-flanders/components';
import { Formik, FormikActions } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ContactPersonsForm } from '../../../components/contact-persons/ContactPersonForm';
import { ContactPersonsFormFieldNames } from '../../../components/contact-persons/ContactPersonForm.constant';
import {
  getDefaultContactPersonValidation,
  getRequiredDefaultContactPersonFields,
} from '../../../components/contact-persons/ContactPersonForm.utils';
import { useApi, useSelectedProviderIdState } from '../../../utils';
import {
  EventAction,
  EventCategory,
  EventLabel,
  sendCustomInteractionToGTM,
} from '../../../utils/google-analytics';
import {
  createValidatorWithServerErrorHandled,
  handleApiError,
} from '../../../utils/validation';

type PickupBillingContactFormProps = {
  titles: Title[];
  financialContact: ContactPerson;
};

export const PickupBillingContactForm = ({
  titles,
  financialContact,
}: PickupBillingContactFormProps) => {
  const { t } = useTranslation();
  const { providers } = useApi();
  const providerId = useSelectedProviderIdState();
  const dispatchAction = useActionDispatch();

  const [{ value: submitedForm }, submit] = useAsyncCallback(
    async (
      formikData: ContactPerson,
      formikActions: FormikActions<ContactPerson>,
    ) => {
      const [error, response] = await httpTo(
        providers.putProviderBillingConfiguration(providerId, formikData),
      );

      return { error, response, formikData, formikActions };
    },
    [providers, providerId],
  );

  const [{ value: fetchedRelatives }, getRelatives] = useAsyncCallback(
    async () => {
      const response = await providers.getProviderRelatives(providerId, {
        sort: Sorting.POSTCODE_ASC,
      });

      const {
        data: { data, actions },
      } = response;

      if (actions) {
        dispatchAction({ type: ActionType.ADD, payload: actions });
      }

      return data;
    },
    [providerId, providers],
    { loading: true },
  );

  useEffect(() => {
    getRelatives();
  }, [getRelatives]);

  // @ts-ignore
  const headquarter = fetchedRelatives?.headquarter;

  useEffect(() => {
    if (submitedForm) {
      const { error, response, formikData, formikActions } = submitedForm;
      const { setSubmitting, setStatus, resetForm } = formikActions;

      if (handleApiError(error, formikActions)) {
        return;
      }

      setSubmitting(false);

      if (!response) {
        setStatus({
          msg: t(i18nKeys.pickup.billing.contact.errorMessage),
          type: AlertType.ERROR,
        });
        return;
      }

      resetForm(formikData);

      setStatus({
        msgWithLink: (
          <>
            {t(i18nKeys.pickup.billing.contact.successMessage)}{' '}
            <SwLink
              to={getPath(Routes.ENTERPRISE_DETAILS_CONTACT_PERSON, {
                params: {
                  enterpriseId: headquarter?.id,
                },
              })}
            >
              {t(i18nKeys.pickup.billing.contact.successLink)}
            </SwLink>
          </>
        ),
        type: AlertType.SUCCESS,
      });

      sendCustomInteractionToGTM(
        EventCategory.PICKUP_BILLING,
        EventAction.SEARCH,
        EventLabel.PICKUP_BILLING_EDIT_FINANCIAL_CONTACT,
      );
    }
  }, [submitedForm]);

  const initialValues = {
    ...getRequiredDefaultContactPersonFields(),
    ...financialContact,
  };

  const validate = createValidatorWithServerErrorHandled(
    getDefaultContactPersonValidation(),
  );

  return (
    <Formik<Partial<ContactPerson>>
      initialValues={initialValues}
      onSubmit={submit}
      validate={validate}
      render={({ handleSubmit, dirty, isSubmitting }) => {
        return (
          <SwForm onSubmit={handleSubmit}>
            <SwGrid modStacked>
              <SwColumn>
                <SwFormGrid modStacked>
                  <ContactPersonsForm
                    titles={titles}
                    focus={ContactPersonsFormFieldNames.EMAIL_ADDRESS}
                  />
                  <SwActionGroup style={{ marginTop: '3rem' }}>
                    <SwButton
                      type="submit"
                      modLoading={isSubmitting}
                      modLarge
                      modDisabled={!dirty}
                    >
                      {t(i18nKeys.pickup.billing.contact.saveModifications)}
                    </SwButton>
                  </SwActionGroup>
                  <SwFormSubmitMessage />
                </SwFormGrid>
              </SwColumn>
            </SwGrid>
          </SwForm>
        );
      }}
    />
  );
};
