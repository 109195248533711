import { ServicesOverview } from '@kaa/api/providers';
import { PRESTATIONS_FILTERS } from '../PrestationsScreen.constants';
import { OverviewAlert, PrestationsFilters } from '../PrestationsScreen.types';

const getAlert = (
  key: PrestationsFilters,
  count: number | undefined,
): OverviewAlert | undefined =>
  !!count && PRESTATIONS_FILTERS[key]
    ? {
        ...PRESTATIONS_FILTERS[key],
        count,
        key,
      }
    : undefined;

export const getAlerts = (alerts: ServicesOverview): OverviewAlert[] =>
  Object.keys(alerts).reduce((acc, key) => {
    const alert = getAlert(
      key as PrestationsFilters,
      alerts[key as keyof ServicesOverview],
    );
    return !alert ? acc : [...acc, alert];
  }, [] as OverviewAlert[]);

export const getSearchOverview = (
  overview: ServicesOverview,
): ServicesOverview => {
  const { canceled, toBePaidOutsideSv, inRemittance, ...rest } = overview;
  return { ...rest };
};

export const getSearchHistoryOverview = (
  overview: ServicesOverview,
): ServicesOverview => {
  const {
    waitingForValidation,
    waitingForConfirmation,
    contested,
    missingContract,
    missingVouchers,
    vouchersAssigned,
    ...rest
  } = overview;
  return { ...rest };
};

export const updateOverview = (
  overview: ServicesOverview,
  overviewUpdate: ServicesOverview | undefined,
): ServicesOverview =>
  overviewUpdate
    ? Object.keys(overview).reduce((acc, prop: string) => {
        const oldProp = overview[prop as keyof ServicesOverview];
        const newProp = overviewUpdate[prop as keyof ServicesOverview];

        if (
          oldProp !== undefined &&
          newProp !== undefined &&
          oldProp + newProp >= 0
        ) {
          return {
            ...acc,
            [prop]: oldProp + newProp,
          };
        }

        if (newProp !== undefined && newProp >= 0) {
          return { ...acc, [prop]: newProp };
        }

        return acc;
      }, overview)
    : overview;
