import { GetWorkersParams, ProviderType } from '@kaa/api/providers';
import { httpTo } from '@kaa/api/providers/utilities';
import { useAsyncCallback } from '@kaa/common/utils';
import { maxLength } from '@kaa/common/validation';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AlertType,
  SwActionGroup,
  SwAlert,
  SwButton,
  SwContainer,
  SwFetchErrorMessage,
  SwForm,
  SwFormSubmitMessage,
  SwModalRenderProps,
  SwTitle,
} from '@kaa/ui-flanders/components';
import { Formik, FormikActions } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { dataTest } from '../../../../datatest/keys';
import { useApi, useSelectedProviderState } from '../../../../utils';
import {
  EventAction,
  EventCategory,
  EventLabel,
  sendCustomInteractionToGTM,
} from '../../../../utils/google-analytics';
import {
  createValidatorWithServerErrorHandled,
  handleApiError,
} from '../../../../utils/validation';
import { PrestationForm } from '../form/PrestationForm';
import { PrestationFormData } from '../form/PrestationForm.types';
import {
  getDefaultWorksValidation,
  getRequiredDefaultWorksFields,
} from '../form/PrestationForm.utils';
import { getCustomerStatus } from './PrestationCreationModal.utils';
import { PrestationCreationModalConfirmation } from './PrestationCreationModalConfirmation';

type PrestationCreationModalProps = SwModalRenderProps & {
  onSuccess: () => void;
};

export const PrestationCreationModal = ({
  onSuccess,
  setConfirmCloseModal,
}: PrestationCreationModalProps) => {
  const { t } = useTranslation();
  const { providers } = useApi();
  const provider = useSelectedProviderState();

  const [
    {
      value: relativesResponse,
      loading: relativesloading,
      error: relativesError,
    },
    getRelatives,
  ] = useAsyncCallback(
    async () => {
      const {
        data: {
          data: { headquarter, subsidiaries },
        },
      } = await providers.getProviderRelatives(provider.id);

      return [headquarter, ...subsidiaries.filter(({ isActive }) => isActive)];
    },
    [providers],
    { loading: true },
  );

  const [{ loading: workersLoading }, getWorkers] = useAsyncCallback(
    async (query: string, searchedProviderId: string) => {
      const {
        data: { data },
      } = await providers.getWorkers(provider.id, {
        workerNissOrReference: query,
        searchedProviderId,
      } as GetWorkersParams);
      return data;
    },
    [providers],
  );

  const [
    { value: customers, loading: customersLoading },
    getCustomers,
  ] = useAsyncCallback(
    async (query: string) => {
      const {
        data: { data },
      } = await providers.getCustomers({
        contractId: query,
      });
      return data;
    },
    [providers],
  );

  const [{ value: submitedForm }, submit] = useAsyncCallback(
    async (
      formikData: PrestationFormData,
      formikActions: FormikActions<PrestationFormData>,
    ) => {
      const [error, response] = await httpTo(
        providers.createService(formikData.providerId, formikData),
      );
      return { error, response, formikData, formikActions };
    },
    [providers],
  );

  useEffect(() => {
    getRelatives();
  }, [getRelatives]);

  useEffect(() => {
    if (submitedForm) {
      const { error, response, formikData, formikActions } = submitedForm;
      const { setSubmitting, setStatus, resetForm } = formikActions;

      setSubmitting(false);

      if (handleApiError(error, formikActions)) {
        return;
      }

      if (!response) {
        setStatus({
          msg: t(i18nKeys.contactPersons.modal.add.errorMessage),
          type: AlertType.ERROR,
        });
        return;
      }

      setConfirmCloseModal(false);

      resetForm(formikData);

      onSuccess();

      sendCustomInteractionToGTM(
        EventCategory.PRESTATIONS,
        EventAction.FORM_CONFIRMATION,
        EventLabel.PRESTATIONS_CREATION,
      );
    }
  }, [submitedForm]);

  if (relativesloading && !relativesResponse) {
    return (
      <>
        <SwTitle tagName="h2">
          {t(i18nKeys.prestations.modals.add.title)}
        </SwTitle>
        <SwContainer loading />
      </>
    );
  }

  if (relativesError || !relativesResponse) {
    return (
      <SwContainer error>
        <SwFetchErrorMessage
          onClick={() => {
            getRelatives();
          }}
        />
      </SwContainer>
    );
  }

  if (submitedForm?.response?.data.data) {
    return (
      <>
        <SwTitle tagName="h2">
          {t(i18nKeys.prestations.modals.add.title)}
        </SwTitle>
        <PrestationCreationModalConfirmation
          relatives={relativesResponse}
          work={submitedForm?.formikData}
        />
      </>
    );
  }

  const validate = createValidatorWithServerErrorHandled({
    ...getDefaultWorksValidation(),
    comment: maxLength(255),
  });

  const customerAlert = getCustomerStatus(customers?.[0]);

  return (
    <>
      <SwTitle tagName="h2">{t(i18nKeys.prestations.modals.add.title)}</SwTitle>
      {customerAlert && (
        <SwAlert title={t(customerAlert.title)}>
          {t(customerAlert.text)}
        </SwAlert>
      )}
      <Formik<Partial<PrestationFormData>>
        onSubmit={submit}
        initialValues={getRequiredDefaultWorksFields({
          providerId: provider.id.toString(),
        })}
        validate={validate}
        render={({ handleSubmit, dirty, isSubmitting, values }) => {
          setConfirmCloseModal(dirty);
          return (
            <SwForm onSubmit={handleSubmit}>
              <PrestationForm
                relatives={relativesResponse}
                getWorkers={getWorkers}
                getCustomers={getCustomers}
                modWithoutComment
                modDisabled={{
                  providerId: provider.type !== ProviderType.HEADQUARTER,
                }}
                searchedProviderId={values.providerId}
                customersLoading={customersLoading}
                workersLoading={workersLoading}
              />
              <SwActionGroup style={{ marginTop: '3rem' }}>
                <SwButton
                  type="submit"
                  data-testid={dataTest.work.addWork.addNewWork}
                  modLoading={isSubmitting}
                  modLarge
                  modDisabled={!dirty}
                >
                  {t(i18nKeys.prestations.modals.add.cta)}
                </SwButton>
              </SwActionGroup>
              <SwFormSubmitMessage />
            </SwForm>
          );
        }}
      />
    </>
  );
};
