/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */

export type AgreementStatus = 'ACTIVE' | 'SUSPENDED' | 'WITHDRAWN';

export const AgreementStatus = {
  ACTIVE: 'ACTIVE' as AgreementStatus,
  SUSPENDED: 'SUSPENDED' as AgreementStatus,
  WITHDRAWN: 'WITHDRAWN' as AgreementStatus,
};
