import {
  GetServicesParams,
  PaginationDirectivesPaginationDirectives,
  ServicesResponse,
  Sorting,
} from '@kaa/api/providers';
import { maxLength, required } from '@kaa/common/validation';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  SwActionGroup,
  SwButton,
  SwCheckboxField,
  SwColumn,
  SwDataTable,
  SwForm,
  SwFormGrid,
  SwFormSubmitMessage,
  SwInputTextField,
  SwLoader,
  SwPaginator,
  SwTitle,
} from '@kaa/ui-flanders/components';
import { Field, Formik } from 'formik';
import React, { CSSProperties, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getLabelForSorting,
  TableHead,
  TableRowNoResult,
} from '../../../../../components';
import { dataTest } from '../../../../../datatest/keys';
import { createValidatorWithServerErrorHandled } from '../../../../../utils/validation';
import {
  ElectronicManualRemittanceResultsFieldNames,
  REMITTANCE_MANUAL_MODAL_PAGE_SIZE,
} from './RemittancesManualModal.constants';
import { ElectronicManualRemittanceResultsForm } from './RemittancesManualModal.types';
import { RemittancesManualModalResultsFormRow } from './RemittancesManualModalResultsFormRow';

type RemittancesManualModalResultsFormProps = {
  services: ServicesResponse['data'];
  pagination?: PaginationDirectivesPaginationDirectives;
  setParameters: React.Dispatch<
    React.SetStateAction<GetServicesParams | undefined>
  >;
  parameters: GetServicesParams;
  loading: boolean;
  submit: (...args: any) => Promise<[Error | undefined, unknown | undefined]>;
};

export const RemittancesManualModalResultsForm = ({
  services,
  pagination,
  submit,
  parameters,
  setParameters,
  loading,
}: RemittancesManualModalResultsFormProps) => {
  const { t } = useTranslation();
  const validate = createValidatorWithServerErrorHandled<
    ElectronicManualRemittanceResultsForm
  >({
    allServicesSelected: [required],
    bundle: [],
    communication: [maxLength(25)],
  });
  const [mustResetBundle, setMustResetBundle] = useState<boolean>(false);

  const head = [
    {
      text: t(i18nKeys.general.label.date),
      style: { position: 'relative' } as CSSProperties,
      label: getLabelForSorting(Sorting.DATE_ASC, parameters?.sort, t),
      onClick: () => {
        setParameters(() => ({
          ...parameters,
          sort:
            parameters?.sort === Sorting.DATE_ASC
              ? Sorting.DATE_DESC
              : Sorting.DATE_ASC,
        }));
      },
    },
    { text: t(i18nKeys.general.label.customer) },
    { text: t(i18nKeys.general.label.worker) },
    { text: t(i18nKeys.general.label.voucher) },
    { text: t(i18nKeys.general.label.totalTime) },
  ];

  useEffect(() => {
    setMustResetBundle(true);
  }, [parameters.serviceMonthPeriod, parameters.relatedProviderId]);

  return (
    <Formik<ElectronicManualRemittanceResultsForm>
      onSubmit={submit}
      initialValues={{
        allServicesSelected: false,
        bundle: {},
        communication: '',
      }}
      validate={validate}
    >
      {({ handleSubmit, dirty, isSubmitting, values, setFieldValue }) => {
        if (mustResetBundle) {
          setFieldValue('bundle', {});
          setMustResetBundle(false);
        }
        return loading ? (
          <SwLoader />
        ) : (
          <SwForm onSubmit={handleSubmit}>
            <SwFormGrid modStacked className="vl-u-spacer">
              <SwColumn>
                <SwTitle tagName="h5" className="vl-u-spacer--xsmall">
                  {t(i18nKeys.remittances.modal.manual.results.title)}
                </SwTitle>
                {pagination && pagination?.numberOfItems > 1 && (
                  <div style={{ marginLeft: '1rem' }}>
                    <Field
                      name={
                        ElectronicManualRemittanceResultsFieldNames.ALL_SERVICES_SELECTED
                      }
                      label={t(
                        i18nKeys.remittances.modal.manual.results.selectAll,
                        {
                          count: pagination?.numberOfItems,
                          totalInHours: services.extra.totalInHours,
                        },
                      )}
                      column={{ width: '12' }}
                      component={SwCheckboxField}
                    />
                  </div>
                )}
                <SwDataTable className="vl-u-spacer">
                  <TableHead elements={head} />
                  <tbody>
                    {!services ||
                    !services.values ||
                    !services.values.length ? (
                      <TableRowNoResult colSpan={head.length} />
                    ) : (
                      services.values.map((service) => (
                        <RemittancesManualModalResultsFormRow
                          key={service.id}
                          service={service}
                          allServicesSelected={values.allServicesSelected}
                        />
                      ))
                    )}
                  </tbody>
                </SwDataTable>
                {!!pagination && (
                  <div style={{ marginLeft: 'auto' }}>
                    <SwPaginator
                      itemCount={pagination.numberOfItems}
                      pageSize={REMITTANCE_MANUAL_MODAL_PAGE_SIZE}
                      selectedPage={pagination.pageNumber}
                      setPage={(page) => {
                        setParameters((currentParameters) => ({
                          ...currentParameters,
                          pageNumber:
                            typeof page === 'number'
                              ? page
                              : page(currentParameters?.pageNumber || 1),
                        }));
                      }}
                    />
                  </div>
                )}
                <SwTitle tagName="h5">
                  {t(i18nKeys.remittances.modal.manual.reference.title)}
                </SwTitle>
                <Field
                  name={
                    ElectronicManualRemittanceResultsFieldNames.COMMUNICATION
                  }
                  label={t(i18nKeys.remittances.general.reference)}
                  column={{ width: '12' }}
                  component={SwInputTextField}
                />
              </SwColumn>
              <SwActionGroup>
                <SwButton
                  data-testid={
                    dataTest.remittances.electronic.manual.createRemittance
                  }
                  type="submit"
                  modDisabled={!dirty || isSubmitting}
                  modLoading={isSubmitting}
                >
                  {t(i18nKeys.remittances.modal.manual.reference.cta)}
                </SwButton>
              </SwActionGroup>
              <SwFormSubmitMessage />
            </SwFormGrid>
          </SwForm>
        );
      }}
    </Formik>
  );
};
