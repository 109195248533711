import { ServiceActiveStatus } from '@kaa/api/providers';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AlertType,
  getAlertPropsByType,
  SwBadge,
  useTooltip,
} from '@kaa/ui-flanders/components';
import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PrestationsSearchTableRowStatusContent,
  PrestationsSearchTableRowStatusProps,
} from './PrestationsSearchTableRowStatusContent';

export const PrestationsSearchTableRowStatus = (
  props: PrestationsSearchTableRowStatusProps,
) => {
  const { t } = useTranslation();

  const {
    service: { status, remittedVouchers },
  } = props;

  const tdStyle = {
    position: 'relative',
    paddingLeft: '5rem',
  } as CSSProperties;

  const badgeBase = {
    modMedium: true,
    style: {
      position: 'absolute',
      top: '1.2rem',
      left: '1.2rem',
      width: '2.8rem',
      height: '2.8rem',
    } as CSSProperties,
  };

  const badgeSuccess = {
    ...badgeBase,
    ...getAlertPropsByType(AlertType.SUCCESS),
  };

  const badgeWarning = {
    ...badgeBase,
    ...getAlertPropsByType(AlertType.WARNING),
  };

  const badgeError = {
    ...badgeBase,
    ...getAlertPropsByType(AlertType.ERROR),
  };

  const badgeInformation = {
    ...badgeBase,
    ...getAlertPropsByType(AlertType.INFORMATION),
    modAction: true,
  };

  const toolTipPartiallyAssigned = useTooltip({
    value: t(
      i18nKeys.prestations.search.table.status.text.PARTIALLY_ASSIGNED.tooltip,
    ),
  });

  const toolTipPartiallyRefunded = useTooltip({
    value: t(
      i18nKeys.prestations.search.table.status.text.PARTIALLY_REFUNDED.tooltip,
    ),
  });

  const badgePartiallyAssigned = {
    ...badgeInformation,
    ref: remittedVouchers > 0 ? toolTipPartiallyAssigned : undefined,
  };

  const badgePartiallyRefunded = {
    ...badgeWarning,
    ref: toolTipPartiallyRefunded,
  };

  // TODO fix retour a la ligne titre status

  switch (status) {
    case ServiceActiveStatus.PAID: // Active
      return (
        <td style={tdStyle}>
          <SwBadge {...badgeSuccess} />
          {t(i18nKeys.general.serviceStatus[status])}
          <PrestationsSearchTableRowStatusContent {...props} />
        </td>
      );
    case ServiceActiveStatus.MODIFIED: // Active
    case ServiceActiveStatus.CORRECTED: // Active
    case ServiceActiveStatus.CONFIRMED: // Active
    case ServiceActiveStatus.MISSING_VOUCHERS: // Active
    case ServiceActiveStatus.MISSING_CONTRACT: // Active
    case ServiceActiveStatus.PARTIALLY_ASSIGNED_TO_BE_PAID_OUTSIDE_SV: // Active
      return (
        <td style={tdStyle}>
          <SwBadge {...badgeWarning} />
          {t(i18nKeys.general.serviceStatus[status])}
          <PrestationsSearchTableRowStatusContent {...props} />
        </td>
      );
    case ServiceActiveStatus.PARTIALLY_ASSIGNED: // Active
      return (
        <td style={tdStyle}>
          <SwBadge {...badgePartiallyAssigned} />
          {t(i18nKeys.general.serviceStatus[status])}
          <PrestationsSearchTableRowStatusContent {...props} />
        </td>
      );
    case ServiceActiveStatus.PARTIALLY_REFUNDED: // Active
      return (
        <td style={tdStyle}>
          <SwBadge {...badgePartiallyRefunded} />
          {t(i18nKeys.general.serviceStatus[status])}
          <PrestationsSearchTableRowStatusContent {...props} />
        </td>
      );
    case ServiceActiveStatus.CREATED: // Active
    case ServiceActiveStatus.VALIDATED: // Active
    case ServiceActiveStatus.READY_FOR_VOUCHER_ASSIGNMENT: // Active
      return (
        <td style={tdStyle}>
          <SwBadge {...badgeInformation} />
          {t(i18nKeys.general.serviceStatus[status])}
          <PrestationsSearchTableRowStatusContent {...props} />
        </td>
      );
    case ServiceActiveStatus.PROPOSITION_TO_CANCEL: // Active
    case ServiceActiveStatus.CONTESTED: // Active
      return (
        <td style={tdStyle}>
          <SwBadge {...badgeError} />
          {t(i18nKeys.general.serviceStatus[status])}
          <PrestationsSearchTableRowStatusContent {...props} />
        </td>
      );
    default:
      return (
        <td style={tdStyle}>{t(i18nKeys.general.serviceStatus[status])}</td>
      );
  }
};
