import { FileExtensionReference, LanguageCode } from '@kaa/api/providers';
import { httpTo } from '@kaa/api/providers/utilities';
import { useAsyncCallback } from '@kaa/common/utils';
import { DownloadTemplates } from '@kaa/core-app/providers/components';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  SwFetchErrorMessage,
  SwLoader,
  SwTitle,
} from '@kaa/ui-flanders/components';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modals } from '../../../../../constants';
import { useApi, useSelectedProviderIdState } from '../../../../../utils';
import { getApplicationType } from '../../../../../utils/templates';

export const RemittancesDeliverySlipTemplateModal = () => {
  const { t, i18n } = useTranslation();
  const { providers } = useApi();
  const providerId = useSelectedProviderIdState();

  const [
    { value: response, loading, error },
    createRemittance,
  ] = useAsyncCallback(
    async () => {
      const [, payload] = await httpTo(
        providers.createRemittance(providerId, {
          language: i18n.language.toUpperCase() as LanguageCode,
        }),
      );

      if (!payload) {
        return undefined;
      }

      const blob = new Blob([payload.data], {
        type: getApplicationType(FileExtensionReference.PDF),
      });

      const url = window.URL.createObjectURL(blob);

      return { url, blob };
    },
    [providers],
    { loading: true },
  );

  useEffect(() => {
    createRemittance();
  }, [createRemittance]);

  if (loading && !response) {
    return <SwLoader />;
  }

  if (error || !response) {
    return <SwFetchErrorMessage onClick={createRemittance} />;
  }

  const { blob, url } = response;

  return (
    <>
      <SwTitle tagName="h2" className="vl-u-spacer--medium">
        {t(i18nKeys.remittances.modal.deliverySlipTemplate.title)}
      </SwTitle>
      <p className="vl-u-spacer--medium">
        {t(i18nKeys.remittances.modal.deliverySlipTemplate.description)}
      </p>
      <DownloadTemplates
        fromModal={`remittances/paper/${Modals.REMITTANCES_SLIP_TEMPLATE_MODAL_ID}`}
        linkToTemplate={url}
        blob={blob}
        fileName={t(i18nKeys.remittances.downloadTemplate.fileName)}
        titleDownloadTemplate={t(
          i18nKeys.remittances.modal.deliverySlipTemplate.pdftemplate.title,
        )}
        titleOpenTemplate={t(
          i18nKeys.remittances.modal.deliverySlipTemplate.openPdfTemplate.title,
        )}
      />
    </>
  );
};
