import {
  email,
  maxLength,
  mobilePhone,
  phone,
  required,
} from '@kaa/common/validation';
import { ContactPersonsFormFieldNames } from './ContactPersonForm.constant';

export const getFieldName = (name: string, fieldNamePrefix?: string) => {
  if (!fieldNamePrefix) {
    return name;
  }

  return `${fieldNamePrefix}.${name}`;
};

export const getRequiredDefaultContactPersonFields = (
  fieldNamePrefix?: string,
) => ({
  [getFieldName(ContactPersonsFormFieldNames.TITLE_CODE, fieldNamePrefix)]: '',
  [getFieldName(ContactPersonsFormFieldNames.FIRSTNAME, fieldNamePrefix)]: '',
  [getFieldName(ContactPersonsFormFieldNames.LASTNAME, fieldNamePrefix)]: '',
  [getFieldName(ContactPersonsFormFieldNames.ROLE, fieldNamePrefix)]: '',
  [getFieldName(
    ContactPersonsFormFieldNames.PHONE_NUMBER,
    fieldNamePrefix,
  )]: '',
  [getFieldName(
    ContactPersonsFormFieldNames.MOBILE_PHONE_NUMBER,
    fieldNamePrefix,
  )]: '',
  [getFieldName(
    ContactPersonsFormFieldNames.EMAIL_ADDRESS,
    fieldNamePrefix,
  )]: '',
  [getFieldName(ContactPersonsFormFieldNames.LANGUAGE, fieldNamePrefix)]: '',
});

export const getDefaultContactPersonValidation = (
  fieldNamePrefix?: string,
) => ({
  [getFieldName(ContactPersonsFormFieldNames.TITLE_CODE, fieldNamePrefix)]: [
    required,
  ],
  [getFieldName(ContactPersonsFormFieldNames.FIRSTNAME, fieldNamePrefix)]: [
    maxLength(40),
    required,
  ],
  [getFieldName(ContactPersonsFormFieldNames.LASTNAME, fieldNamePrefix)]: [
    maxLength(40),
    required,
  ],
  [getFieldName(ContactPersonsFormFieldNames.ROLE, fieldNamePrefix)]: [
    maxLength(20),
    required,
  ],
  [getFieldName(ContactPersonsFormFieldNames.PHONE_NUMBER, fieldNamePrefix)]: [
    phone,
  ],
  [getFieldName(
    ContactPersonsFormFieldNames.MOBILE_PHONE_NUMBER,
    fieldNamePrefix,
  )]: [mobilePhone],
  [getFieldName(ContactPersonsFormFieldNames.EMAIL_ADDRESS, fieldNamePrefix)]: [
    maxLength(80),
    required,
    email,
  ],
  [getFieldName(ContactPersonsFormFieldNames.LANGUAGE, fieldNamePrefix)]: [
    required,
  ],
});
