/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */

export type RegionCode = 'BE_BRU' | 'BE_WAL' | 'BE_VLG';

export const RegionCode = {
  BE_BRU: 'BE_BRU' as RegionCode,
  BE_WAL: 'BE_WAL' as RegionCode,
  BE_VLG: 'BE_VLG' as RegionCode,
};
