import { Provider } from '@kaa/api/providers';
import { httpTo } from '@kaa/api/providers/utilities';
import { useAsyncCallback } from '@kaa/common/utils';
import {
  email,
  equalToFieldInsensitive,
  existsInError,
  notEqualTo,
  required,
  setError,
  unsetError,
} from '@kaa/common/validation';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AlertType,
  SwActionGroup,
  SwButton,
  SwColumn,
  SwForm,
  SwFormColumn,
  SwFormGrid,
  SwFormSubmitMessage,
  SwGrid,
  SwInputTextField,
  SwModalRenderProps,
  SwTitle,
} from '@kaa/ui-flanders/components';
import { Field, Formik, FormikActions } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { dataTest } from '../../../../datatest/keys';
import { useApi } from '../../../../utils';
import {
  createValidatorWithServerErrorHandled,
  handleApiError,
} from '../../../../utils/validation';
import { ProfileScreenFieldNames } from '../ProfileScreen.constants';
import {
  ProfileProviderChangeEmailFormState,
  ProfileStatusType,
} from '../ProfileScreen.types';

type ProfileProviderChangeEmailModalProps = {
  provider: Provider;
  onSubmit: (
    profileStatus: ProfileStatusType,
    providerUpdate?: Provider,
  ) => void;
};

export const ProfileProviderChangeEmailModal = ({
  provider,
  onSubmit,
  setConfirmCloseModal,
}: SwModalRenderProps<ProfileProviderChangeEmailModalProps>) => {
  const { t } = useTranslation();
  const { providers } = useApi();

  const { id: providerId, commercialEmailAddress } = provider;

  const initialValues = {
    [ProfileScreenFieldNames.CONFIRM_NEW_COMMERCIAL_EMAIL_ADDRESS]: '',
    [ProfileScreenFieldNames.NEW_COMMERCIAL_EMAIL_ADDRESS]: '',
  } as ProfileProviderChangeEmailFormState;

  const [{ value: submitedForm }, submit] = useAsyncCallback(
    async (
      formikData: ProfileProviderChangeEmailFormState,
      formikActions: FormikActions<ProfileProviderChangeEmailFormState>,
    ) => {
      const { confirmNewCommercialEmailAddress } = formikData;

      if (confirmNewCommercialEmailAddress === commercialEmailAddress) {
        return undefined;
      }

      const providerUpdate = {
        ...provider,
        commercialEmailAddress: confirmNewCommercialEmailAddress,
      };

      const [error, response] = await httpTo(
        providers.updateProvider(providerId, providerUpdate),
      );

      return { error, response, providerUpdate, formikActions };
    },
  );

  useEffect(() => {
    if (submitedForm) {
      const { error, providerUpdate, formikActions } = submitedForm;
      const { resetForm } = formikActions;

      if (handleApiError(error, formikActions)) {
        return;
      }

      resetForm(initialValues);

      onSubmit(
        {
          type: AlertType.SUCCESS,
          msg: t(i18nKeys.profile.updateProfile.successMessage),
        },
        providerUpdate,
      );
    }
  }, [submitedForm]);

  const validate = createValidatorWithServerErrorHandled({
    newCommercialEmailAddress: [
      email,
      required,
      notEqualTo(commercialEmailAddress),
    ],
    confirmNewCommercialEmailAddress: [
      existsInError(
        `${ProfileScreenFieldNames.CONFIRM_NEW_COMMERCIAL_EMAIL_ADDRESS}.notPasted`,
        {
          id: i18nKeys.profile.error.notPasted,
          defaultMessage: 'You are not allowed to paste into this field',
          displayFocussed: true,
        },
      ),
      required,
      email,
      equalToFieldInsensitive(
        ProfileScreenFieldNames.NEW_COMMERCIAL_EMAIL_ADDRESS,
        {
          defaultMessage: `Must be equal to ${t(
            i18nKeys.general.label.emailAddress,
          )}`,
          id: i18nKeys.errors.form.equal,
          displayFocussed: false,
          values: {
            value: t(i18nKeys.general.label.emailAddress),
          },
        },
      ),
    ],
  });

  return (
    <Formik<ProfileProviderChangeEmailFormState>
      initialValues={initialValues}
      validate={validate}
      onSubmit={submit}
      render={({
        handleSubmit,
        values,
        dirty,
        isValid,
        isSubmitting,
        setFieldValue,
        errors,
      }) => {
        setConfirmCloseModal(dirty);
        return (
          <SwGrid modStart style={{ justifyContent: 'flex-start' }}>
            <SwColumn width="9" widthS="12">
              <SwTitle tagName="h2">
                {t(i18nKeys.profile.changeEmailModal.title)}
              </SwTitle>
            </SwColumn>
            <SwColumn width="12" widthS="12">
              <SwForm
                onSubmit={handleSubmit}
                data-testid={dataTest.profile.confirmEmailModalForm}
              >
                <SwFormGrid modStacked>
                  <SwFormColumn>
                    <SwFormGrid>
                      <SwFormColumn width="12">
                        <span className="vl-form__label">
                          {t(i18nKeys.profile.changeEmailModal.oldEmailAddress)}
                        </span>
                      </SwFormColumn>
                      <SwFormColumn width="12">
                        {commercialEmailAddress}
                      </SwFormColumn>
                    </SwFormGrid>
                  </SwFormColumn>
                  <Field
                    name={ProfileScreenFieldNames.NEW_COMMERCIAL_EMAIL_ADDRESS}
                    label={t(i18nKeys.general.label.emailAddress)}
                    component={SwInputTextField}
                    column={{ width: '12' }}
                    labelColumn={{ width: '12' }}
                    data-testid={dataTest.profile.changeEmailModalEmailInput}
                  />
                  <Field
                    name={
                      ProfileScreenFieldNames.CONFIRM_NEW_COMMERCIAL_EMAIL_ADDRESS
                    }
                    label={t(i18nKeys.profile.emailAddressConfirmation)}
                    component={SwInputTextField}
                    column={{ width: '12' }}
                    labelColumn={{ width: '12' }}
                    onPaste={(e: ClipboardEvent) => {
                      e.preventDefault();
                      setError({
                        setFieldValue,
                        name: `${ProfileScreenFieldNames.CONFIRM_NEW_COMMERCIAL_EMAIL_ADDRESS}.notPasted`,
                      });
                    }}
                    inputOnChange={() => {
                      if (errors.confirmNewCommercialEmailAddress) {
                        unsetError({
                          setFieldValue,
                          values,
                          name: `${ProfileScreenFieldNames.CONFIRM_NEW_COMMERCIAL_EMAIL_ADDRESS}.notPasted`,
                        });
                      }
                    }}
                    data-testid={
                      dataTest.profile.changeEmailModalEmailConfirmInput
                    }
                  />
                  <SwActionGroup>
                    <SwButton
                      type="submit"
                      modDisabled={!dirty || !isValid}
                      modLoading={isSubmitting}
                      data-testid={dataTest.profile.submitButton}
                    >
                      {t(i18nKeys.general.cta.validate)}
                    </SwButton>
                  </SwActionGroup>
                  <SwFormSubmitMessage />
                </SwFormGrid>
              </SwForm>
            </SwColumn>
          </SwGrid>
        );
      }}
    />
  );
};
