import {
  Activity,
  Relative,
  ServiceActiveStatus,
  ServiceInactiveStatus,
} from '@kaa/api/providers';
import { getStarOfTheDayJsDate } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import { TFunction } from 'i18next';
import { DateTime } from 'luxon';
import { CommonFormValues } from '../PrestationsScreen.types';

const TODAY_DATE = getStarOfTheDayJsDate(new Date());
const BACKEND_LIMIT_DATE = getStarOfTheDayJsDate(new Date('2016/01/01'));

export const getRelativesOptions = (relatives: Relative[]) =>
  relatives.map(({ id, name }) => ({
    value: id,
    text: `${name} - ${id}`,
  }));

export const getActivityTypeOptions = (t: TFunction) =>
  Object.values(Activity).map((e: Activity) => ({
    value: e,
    text: t(i18nKeys.general.activityType[e]),
  }));

export const getServiceActiveStatusOptions = (t: TFunction) =>
  Object.values(ServiceActiveStatus).map((e) => ({
    value: e,
    text: t(i18nKeys.general.serviceStatus[e]),
  }));

export const getServiceInactiveStatusOptions = (t: TFunction) =>
  Object.values(ServiceInactiveStatus).map((e) => ({
    value: e,
    text: t(i18nKeys.general.serviceStatus[e]),
  }));

export const getMinStartDate = (values: CommonFormValues) => {
  if (values.endDate) {
    const limit = getStarOfTheDayJsDate(
      DateTime.fromISO(values.endDate).minus({ months: 12 }),
    );
    return limit > BACKEND_LIMIT_DATE ? limit : BACKEND_LIMIT_DATE;
  }

  return BACKEND_LIMIT_DATE;
};

export const getMaxStartDate = (values: CommonFormValues) => {
  return values.endDate
    ? getStarOfTheDayJsDate(DateTime.fromISO(values.endDate))
    : TODAY_DATE;
};

export const getMinEndDate = (values: CommonFormValues) => {
  return values.startDate
    ? getStarOfTheDayJsDate(DateTime.fromISO(values.startDate))
    : BACKEND_LIMIT_DATE;
};

export const getMaxEndDate = (values: CommonFormValues) => {
  if (values.startDate) {
    const limit = getStarOfTheDayJsDate(
      DateTime.fromISO(values.startDate).plus({ months: 12 }),
    );
    return limit < TODAY_DATE ? limit : TODAY_DATE;
  }

  return TODAY_DATE;
};
