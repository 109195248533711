import { FileExtensionReference } from '@kaa/api/providers';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import { Icon, SwIcon, SwLink } from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modals } from '../../constants';
import { openModalWithPageView } from '../../utils/google-analytics';

type DownloadButtonsProps = {
  extensions: FileExtensionReference[];
  modalId?: Modals;
  dataTestId?: string;
  onClick?: (extension: FileExtensionReference) => void;
};

export const DownloadButtons = ({
  modalId,
  extensions,
  dataTestId,
  onClick,
}: DownloadButtonsProps) => {
  const { t } = useTranslation();

  return (
    <div
      className="vl-u-display-flex"
      style={{
        alignItems: 'center',
      }}
    >
      <SwIcon
        icon={Icon.FILE_DOWNLOAD}
        style={{
          marginRight: '0.5rem',
        }}
      />
      <span>{`${t(i18nKeys.general.label.export)}: `}</span>
      <p
        style={{
          marginLeft: '0.5rem',
        }}
      >
        {extensions.map((extension: FileExtensionReference, i: number) => (
          <React.Fragment key={extension}>
            {i > 0 && <> | </>}
            <SwLink
              modBold
              tagName="button"
              onClick={(e) => {
                e.preventDefault();
                if (onClick) {
                  onClick(extension);
                } else {
                  openModalWithPageView(`${modalId}-${extension}` as Modals);
                }
              }}
              data-testid={
                dataTestId ? `${dataTestId}-${extension}` : undefined
              }
            >
              .{extension.toLowerCase()}
            </SwLink>
          </React.Fragment>
        ))}
      </p>
    </div>
  );
};
