/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */

export type VideoDescriptionLinkTitle =
  | 'extranetWebinar'
  | 'newExtranetWebinar';

export const VideoDescriptionLinkTitle = {
  extranetWebinar: 'extranetWebinar' as VideoDescriptionLinkTitle,
  newExtranetWebinar: 'newExtranetWebinar' as VideoDescriptionLinkTitle,
};
