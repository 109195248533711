/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */

export type BillType = 'INVOICE' | 'CREDIT_NOTE';

export const BillType = {
  INVOICE: 'INVOICE' as BillType,
  CREDIT_NOTE: 'CREDIT_NOTE' as BillType,
};
