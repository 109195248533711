/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */

export type ProviderActionType =
  | 'CAN_EDIT_NAME'
  | 'CAN_EDIT_ADDRESS_POSTCODE'
  | 'CAN_EDIT_ADDRESS_HOUSE_NUMBER'
  | 'CAN_EDIT_ADDRESS_STREET'
  | 'CAN_EDIT'
  | 'CAN_DISABLE'
  | 'CAN_ADD_CONTACT_PERSON'
  | 'CAN_CREATE_RELATIVE'
  | 'CAN_REQUEST_UNEDITABLE_CHANGES'
  | 'CAN_CREATE_ELECTRONIC_REMITTANCE'
  | 'CAN_EDIT_VISIBILITY';

export const ProviderActionType = {
  CAN_EDIT_NAME: 'CAN_EDIT_NAME' as ProviderActionType,
  CAN_EDIT_ADDRESS_POSTCODE: 'CAN_EDIT_ADDRESS_POSTCODE' as ProviderActionType,
  CAN_EDIT_ADDRESS_HOUSE_NUMBER: 'CAN_EDIT_ADDRESS_HOUSE_NUMBER' as ProviderActionType,
  CAN_EDIT_ADDRESS_STREET: 'CAN_EDIT_ADDRESS_STREET' as ProviderActionType,
  CAN_EDIT: 'CAN_EDIT' as ProviderActionType,
  CAN_DISABLE: 'CAN_DISABLE' as ProviderActionType,
  CAN_ADD_CONTACT_PERSON: 'CAN_ADD_CONTACT_PERSON' as ProviderActionType,
  CAN_CREATE_RELATIVE: 'CAN_CREATE_RELATIVE' as ProviderActionType,
  CAN_REQUEST_UNEDITABLE_CHANGES: 'CAN_REQUEST_UNEDITABLE_CHANGES' as ProviderActionType,
  CAN_CREATE_ELECTRONIC_REMITTANCE: 'CAN_CREATE_ELECTRONIC_REMITTANCE' as ProviderActionType,
  CAN_EDIT_VISIBILITY: 'CAN_EDIT_VISIBILITY' as ProviderActionType,
};
