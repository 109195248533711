import { Activity, AgreementStatus } from '@kaa/api/providers';
import { useAsyncCallback, useLuxon } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  Icon,
  SwBadge,
  SwColumn,
  SwContainer,
  SwFetchErrorMessage,
  SwGrid,
  SwInfoTile,
  SwTitle,
} from '@kaa/ui-flanders/components';
import { TFunction } from 'i18next';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ContainerLoadingPageHeader } from '../../../components';
import { useApi, useSelectedProviderIdState } from '../../../utils';

const getPropsByAgreementsType = (activityType: Activity, t: TFunction) => {
  switch (activityType) {
    case Activity.GROCERY_SHOPPING:
      return {
        title: t(i18nKeys.activityAgreement.GROCERYSHOPPING.title),
        subtitle: t(i18nKeys.activityAgreement.GROCERYSHOPPING.subtitle),
        icon: Icon.SHOPPING,
      };
    case Activity.HOUSEKEEPING:
      return {
        title: t(i18nKeys.activityAgreement.HOUSEKEEPING.title),
        subtitle: t(i18nKeys.activityAgreement.HOUSEKEEPING.subtitle),
        icon: Icon.VACCUM_CLEANER,
      };
    case Activity.DISABLED_PERSON_TRANSPORTATION:
      return {
        title: t(i18nKeys.activityAgreement.DISABLEDPERSONTRANSPORTATION.title),
        subtitle: t(
          i18nKeys.activityAgreement.DISABLEDPERSONTRANSPORTATION.subtitle,
        ),
        icon: Icon.TRANSPORT,
      };

    case Activity.IRONING:
      return {
        title: t(i18nKeys.activityAgreement.IRONING.title),
        subtitle: t(i18nKeys.activityAgreement.IRONING.subtitle),
        icon: Icon.IRONING,
      };

    default:
      return {
        title: 'Activity',
        subtitle: 'Activity short description',
        icon: Icon.IRONING,
      };
  }
};

export const ActivitiesScreen = () => {
  const { t } = useTranslation();
  const { DateTime } = useLuxon();
  const profileId = useSelectedProviderIdState();
  const { providers } = useApi();

  const [
    { value: agreements, loading, error },
    getVisibilityAgreements,
  ] = useAsyncCallback(
    async () =>
      (await providers.getProviderVisibilityForAgreements(profileId)).data.data,
    [profileId, providers],
    { loading: true },
  );

  useEffect(() => {
    getVisibilityAgreements();
  }, [getVisibilityAgreements]);

  if (loading) {
    return (
      <ContainerLoadingPageHeader title={t(i18nKeys.activityAgreement.title)} />
    );
  }

  if (error || !agreements) {
    return (
      <SwContainer error>
        <SwFetchErrorMessage onClick={getVisibilityAgreements} />
      </SwContainer>
    );
  }

  return (
    <SwContainer>
      <SwGrid modStacked>
        <SwColumn width="10" widthS="12">
          <SwTitle tagName="h1" className="vl-u-spacer--medium">
            {t(i18nKeys.activityAgreement.title)}
          </SwTitle>
          <SwTitle tagName="h2" tagStyle="h3" className="vl-u-spacer--none">
            {t(i18nKeys.activityAgreement.currentAgreement.title)}
          </SwTitle>
        </SwColumn>
        {agreements.map(
          ({
            activityType,
            status,
            issueDate,
            withdrawalDate,
            suspensionDate,
            resourceId,
          }) => {
            const { title, subtitle, icon } = getPropsByAgreementsType(
              activityType,
              t,
            );
            return (
              <SwColumn width="10" widthS="12" key={resourceId}>
                <SwInfoTile
                  tagName="div"
                  title={title}
                  subtitle={subtitle}
                  badge={
                    <SwBadge
                      modLarge
                      modBorder
                      modSuccess={status === AgreementStatus.ACTIVE}
                      modError={status !== AgreementStatus.ACTIVE}
                      icon={icon}
                    />
                  }
                >
                  <SwGrid modStacked className="vl-u-text--small">
                    <SwColumn width="3" widthM="6">
                      <p>{t(i18nKeys.activityAgreement.details.status)}</p>
                      {!!status && (
                        <strong>
                          {t(i18nKeys.activityAgreement.status[status])}
                        </strong>
                      )}
                    </SwColumn>

                    <SwColumn width="3" widthM="6">
                      <p>{t(i18nKeys.activityAgreement.details.issueDate)}</p>
                      <strong>
                        {DateTime.fromISO(issueDate).toFormat('dd/LL/yyyy')}
                      </strong>
                    </SwColumn>

                    <SwColumn width="3" widthM="6">
                      <p>
                        {t(i18nKeys.activityAgreement.details.withdrawalDate)}
                      </p>
                      <strong>
                        {withdrawalDate
                          ? DateTime.fromISO(withdrawalDate).toFormat(
                              'dd/LL/yyyy',
                            )
                          : '-'}
                      </strong>
                    </SwColumn>

                    <SwColumn width="3" widthM="6">
                      <p>
                        {t(i18nKeys.activityAgreement.details.suspensionDate)}
                      </p>
                      <strong>
                        {suspensionDate
                          ? DateTime.fromISO(suspensionDate).toFormat(
                              'dd/LL/yyyy',
                            )
                          : '-'}
                      </strong>
                    </SwColumn>
                  </SwGrid>
                </SwInfoTile>
              </SwColumn>
            );
          },
        )}
      </SwGrid>
    </SwContainer>
  );
};
