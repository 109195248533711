import { getActionsMock, getResourceIdMock } from '@kaa/api/common/mocks';
import { mergeDeep } from '@kaa/common/utils';
import faker from 'faker';
import {
  LanguageCode,
  Provider,
  ProviderActionType,
  ProviderBankAccount,
  ProviderSubsidiary,
  ProviderSubsidyConfigurationResponse,
  ProviderType,
  RelativeResponse,
  SubsidyBeneficiaryBankAccountHolderReference,
  SubsidyReceivalModeCode,
} from '../model';
import { getHeadquarterOrRelative } from './relatives';
import { getProviderAddress } from './utils';

const getProviderType = () =>
  faker.random.number({ min: 1, max: 5 }) === 1
    ? ProviderType.SUBSIDIARY
    : ProviderType.HEADQUARTER;

export const RESOURCE_PREFIX_PROVIDER = 'provider';

const getProviderActions = (
  actions?: {
    [key: string]: { [key: string]: boolean };
  },
  {
    resourceId = getResourceIdMock(RESOURCE_PREFIX_PROVIDER),
    type,
  }: Partial<Provider> = {},
) => {
  const isHeadquarter = type === ProviderType.HEADQUARTER;
  return mergeDeep(actions, {
    [resourceId]: {
      ...getActionsMock(ProviderActionType),
      CAN_EDIT: faker.random.boolean(),
      CAN_DISABLE: !isHeadquarter && faker.random.boolean(),
      CAN_EDIT_NAME: !isHeadquarter,
      CAN_EDIT_ADDRESS_POSTCODE: !isHeadquarter,
      CAN_EDIT_ADDRESS_HOUSE_NUMBER: !isHeadquarter,
      CAN_EDIT_ADDRESS_BOX_NUMBER: !isHeadquarter,
      CAN_EDIT_ADDRESS_STREET: !isHeadquarter,
      CAN_EDIT_VISIBILITY: !isHeadquarter,
    },
  });
};

const getProviderBankAccount = (
  relativeType: SubsidyBeneficiaryBankAccountHolderReference,
): ProviderBankAccount => ({
  providerId: faker.random.number(999999),
  commercialName: faker.company.companyName(),
  iban: `BE711992345246${faker.random.number(9)}${faker.random.number(9)}`,
  relativeType,
});

export const getProvider = (provider?: Partial<Provider>): Provider => {
  const id = faker.random.number(999999);
  const name = faker.company.companyName();
  return {
    id: faker.random.number(999999),
    name,
    commercialName: faker.company.companyName(),
    commercialEmailAddress: faker.internet.email(name),
    address: getProviderAddress(),
    language: faker.helpers.randomize(Object.values(LanguageCode)),
    resourceId: getResourceIdMock(
      RESOURCE_PREFIX_PROVIDER,
      (provider && provider.id) || id,
    ),
    phoneNumber: faker.phone.phoneNumber('0## ## ## ##'),
    mobilePhoneNumber: faker.phone.phoneNumber('04## ## ## ##'),
    website: faker.internet.url(),
    vatNumber: faker.random
      .number({ min: 100000000, max: 999999999 })
      .toFixed(0),
    iban: 'BE71199234524669',
    bic: faker.finance.bic(),
    type: getProviderType(),
    ...provider,
  };
};

export const getProviderResponse = ({
  actions,
  data,
}: Partial<{
  actions: {
    [key: string]: { [key: string]: boolean };
  };
  data: Partial<Provider>;
}> = {}) => {
  const resourceId =
    (data && data.resourceId) ||
    getResourceIdMock(RESOURCE_PREFIX_PROVIDER, data && data.id);
  const type = getProviderType();
  return {
    actions: getProviderActions(actions, { resourceId, type }),
    data: getProvider({
      resourceId,
      type,
      ...data,
    }),
  };
};

export const getProviderSubsidiaryCreateResponse = (
  providerSubsidiary: ProviderSubsidiary,
): RelativeResponse => {
  const resourceId = getResourceIdMock(RESOURCE_PREFIX_PROVIDER);

  return {
    actions: getProviderActions(undefined, {
      resourceId,
      type: ProviderType.SUBSIDIARY,
    }),
    data: getHeadquarterOrRelative(providerSubsidiary),
  };
};

export const ProviderSubsidyConfigurationResponseData = (): ProviderSubsidyConfigurationResponse['data'] => {
  const bankAccounts = [
    getProviderBankAccount(
      SubsidyBeneficiaryBankAccountHolderReference.HEADQUARTER,
    ),
  ].concat(
    faker.random.boolean()
      ? [
          getProviderBankAccount(
            SubsidyBeneficiaryBankAccountHolderReference.SUBSIDIARY,
          ),
        ]
      : [],
  );
  return {
    subsidyReceivalMode: faker.helpers.randomize(
      Object.values(SubsidyReceivalModeCode),
    ),
    subsidyBeneficiaryBankAccountHolder: faker.helpers.randomize(
      bankAccounts.map(({ relativeType }) => relativeType),
    ),
    bankAccounts,
  };
};

export const getProviderSubsidyConfigurationResponse = (): ProviderSubsidyConfigurationResponse => {
  return {
    data: ProviderSubsidyConfigurationResponseData(),
  };
};
