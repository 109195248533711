/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */

export type PaymentMode = 'BANK_TRANSFER' | 'DIRECT_DEBIT';

export const PaymentMode = {
  BANK_TRANSFER: 'BANK_TRANSFER' as PaymentMode,
  DIRECT_DEBIT: 'DIRECT_DEBIT' as PaymentMode,
};
