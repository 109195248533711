import { AuthStorageKeys } from '@kaa/auth/common';
import { pickRoute, Routes } from '../routes';

const unauthorizeUris = [
  Routes.LOGIN,
  Routes.LOGOUT,
  Routes.LOGOUT_CALLBACK,
  Routes.LOGIN_CALLBACK,
  Routes.SERVICE_UNAVAILABLE,
  Routes.PAGE_404,
  Routes.SILENT_RENEW_HTML_CALLBACK,
];

export const setRedirectUriInSessionStorage = () => {
  const activeRoute = pickRoute(window.location.pathname);
  if (activeRoute && !unauthorizeUris.includes(activeRoute.route.id as any)) {
    sessionStorage.setItem(
      AuthStorageKeys.CALLBACK_REDIRECT_URI,
      `${window.location.pathname}${window.location.search}`,
    );
  }
};
