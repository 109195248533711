/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */

export type AddressTypeReference = 'UNKNOWN' | 'MAIN' | 'OTHERS';

export const AddressTypeReference = {
  UNKNOWN: 'UNKNOWN' as AddressTypeReference,
  MAIN: 'MAIN' as AddressTypeReference,
  OTHERS: 'OTHERS' as AddressTypeReference,
};
