import { RemittanceRefusedVoucher } from '@kaa/api/providers';
import { useLuxon } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import React from 'react';
import { useTranslation } from 'react-i18next';

type RemittancesSearchHistoryRefusedModalTableRowProps = {
  vouchersBundle: RemittanceRefusedVoucher;
};

export const RemittancesSearchHistoryRefusedModalTableRow = ({
  vouchersBundle,
}: RemittancesSearchHistoryRefusedModalTableRowProps) => {
  const { t } = useTranslation();
  const { DateTime } = useLuxon();

  const {
    voucherReference,
    issueDate,
    refusalReasons,
    customerFullname,
    customerAuthorizationId,
  } = vouchersBundle;

  return (
    <tr>
      <td data-title={t(i18nKeys.remittances.general.vouchers)}>
        {voucherReference}
      </td>
      <td
        data-title={t(
          i18nKeys.remittances.refusedModal.table.tableHead.emission,
        )}
      >
        {DateTime.fromISO(issueDate).toFormat('MM/yyyy')}
      </td>
      <td data-title={t(i18nKeys.remittances.refusalReasons.label)}>
        <ul className="list--arrowed">
          {refusalReasons.map((reason) => (
            <li key={`${voucherReference}${reason}`}>
              {t(i18nKeys.remittances.refusalReasons[reason])}
            </li>
          ))}
        </ul>
      </td>
      <td
        data-title={t(i18nKeys.remittances.refusedModal.table.tableHead.user)}
      >
        {customerFullname}
      </td>
      <td
        data-title={t(i18nKeys.remittances.refusedModal.table.tableHead.userId)}
      >
        {customerAuthorizationId}
      </td>
    </tr>
  );
};
