import {
  SwColumn,
  SwContainer,
  SwGrid,
  SwLoader,
} from '@kaa/ui-flanders/components';
import React from 'react';
import { PageHeader } from '../pageHeader/PageHeader';

type ContainerLoadingPageHeaderProps = {
  title: string;
  introduction?: string;
  modFullwidth?: boolean;
};

export const ContainerLoadingPageHeader = ({
  title,
  introduction,
  modFullwidth,
}: ContainerLoadingPageHeaderProps) => {
  return (
    <SwContainer>
      <SwGrid
        modStacked
        className={modFullwidth ? 'vl-u-flex-v-flex-start' : ''}
      >
        <PageHeader
          title={title}
          introduction={introduction}
          className={!modFullwidth ? 'push-pageHeader' : ''}
        />
        <SwColumn width={modFullwidth ? '12' : '10'}>
          <SwLoader />
        </SwColumn>
      </SwGrid>
    </SwContainer>
  );
};
