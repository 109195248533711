/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */

export type FileExtensionReference = 'XLSX' | 'XLS' | 'CSV' | 'PDF';

export const FileExtensionReference = {
  XLSX: 'XLSX' as FileExtensionReference,
  XLS: 'XLS' as FileExtensionReference,
  CSV: 'CSV' as FileExtensionReference,
  PDF: 'PDF' as FileExtensionReference,
};
