import {
  GetRefusedVouchersRemittanceParams,
  VoucherRefusalReason,
} from '@kaa/api/providers';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  Icon,
  SwActionGroup,
  SwButton,
  SwForm,
  SwFormColumn,
  SwFormGrid,
  SwFormSubmitMessage,
  SwSelectField,
} from '@kaa/ui-flanders/components';
import { Field, Formik, FormikActions } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

type RemittancesSearchHistoryRefusedModalFormProps = {
  submit: (
    parameters: GetRefusedVouchersRemittanceParams,
    formikActions: FormikActions<GetRefusedVouchersRemittanceParams>,
  ) => void;
  parameters?: GetRefusedVouchersRemittanceParams;
};

export const RemittancesSearchHistoryRefusedModalForm = ({
  submit,
  parameters,
}: RemittancesSearchHistoryRefusedModalFormProps) => {
  const { t } = useTranslation();

  const reasonOptions = Object.values(VoucherRefusalReason).map((reason) => ({
    value: reason,
    text: t(i18nKeys.remittances.refusalReasons[reason]),
  }));

  return (
    <Formik<GetRefusedVouchersRemittanceParams>
      initialValues={{ ...parameters }}
      onSubmit={submit}
      render={({ handleSubmit, dirty, isSubmitting }) => (
        <SwForm onSubmit={handleSubmit}>
          <SwFormGrid className="vl-u-spacer--medium">
            <SwFormColumn widthS="12" width="4">
              <Field
                name="reason"
                component={SwSelectField}
                label={t(i18nKeys.remittances.refusalReasons.label)}
                options={reasonOptions}
                labelColumn={{ width: '12' }}
                column={{ width: '12' }}
              />
            </SwFormColumn>
            <SwFormColumn
              widthS="12"
              width="4"
              className="vl-u-display-flex vl-u-flex-align-flex-end"
            >
              <SwActionGroup
                modCollapseS
                className="vl-u-display-flex vl-u-flex-align-flex-end"
              >
                <SwButton
                  type="submit"
                  modLoading={isSubmitting}
                  modDisabled={!dirty}
                  modIconBefore
                  icon={Icon.SYNCHRONIZE}
                  style={{ marginLeft: 0 }}
                >
                  {t(i18nKeys.general.cta.refresh)}
                </SwButton>
              </SwActionGroup>
            </SwFormColumn>
            <SwFormSubmitMessage />
          </SwFormGrid>
        </SwForm>
      )}
    />
  );
};
