import faker from 'faker';
import { DateTime } from 'luxon';
import {
  GetSubsidiesParams,
  ReimbursementRefDateReference,
  SubsidiesResponse,
  SubsidyDetailResponse,
  SubsidySummary,
  SubsidyVoucherSet,
} from '../model';
import { getDataWithPagination } from './pagination';
import { getRemittanceSummary } from './remittances';
import { getId, getRandomArray } from './utils';

const getSubsidyBase = (
  providerId: number,
  params: Partial<GetSubsidiesParams>,
) => {
  const id = getId();

  const {
    isBlocked = faker.random.boolean(),
    fromPaymentDate,
    toPaymentDate,
  } = params;

  return {
    amountReceived: faker.random.number({ min: 1, max: 100 }),
    paymentDate: DateTime.fromJSDate(
      fromPaymentDate && toPaymentDate
        ? faker.date.between(fromPaymentDate, toPaymentDate)
        : faker.date.past(),
    ).toISODate(),
    paymentReference: id,
    providerId,
    isBlocked,
    id,
  };
};

const getVoucherSets = (
  summary: Omit<SubsidySummary, 'remittance'>,
): SubsidyVoucherSet[] => {
  const { paymentDate } = summary;
  const date = DateTime.fromISO(paymentDate);
  return getRandomArray({ min: 1, max: 10 }).map(() => ({
    issuePeriod: DateTime.fromJSDate(
      faker.date.between(date.minus({ year: 1 }).toJSDate(), date.toJSDate()),
    ).toISODate(),
    voucherWorkDate: faker.date.past().toISOString(),
    reimbursementRefDateType: faker.helpers.randomize([
      ReimbursementRefDateReference.MONTH_OF_ISSUE,
      ReimbursementRefDateReference.MONTH_OF_WORK,
    ]),
    voucherQuantity: faker.random.number({ min: 1, max: 10 }),
    voucherValue: faker.random.number({ min: 10, max: 100 }),
  }));
};

const getSubsidySummary = (
  providerId: number,
  params: Partial<GetSubsidiesParams>,
) => ({
  ...getSubsidyBase(providerId, params),
  remittance: getRemittanceSummary(params),
});

const getSubsidyDetail = (providerId: number) => {
  const { remittance, ...summary } = getSubsidySummary(providerId, {});
  const voucherSets = getVoucherSets(summary);
  return {
    ...summary,
    voucherSets,
    remittance: {
      ...remittance,
      numberOfVouchers: voucherSets.reduce(
        (acc, e) => acc + (e.voucherQuantity || 0),
        0,
      ),
    },
    ...{
      amountReceived: voucherSets.reduce(
        (acc, e) =>
          e.voucherValue && e.voucherQuantity
            ? acc + e.voucherValue * e.voucherQuantity
            : acc,
        0,
      ),
    },
  };
};

export const getSubsidiesResponse = (
  providerId: string | number,
  params = {} as GetSubsidiesParams,
): SubsidiesResponse => {
  const subsidies = getRandomArray({ min: 10, max: 100 }).map(() =>
    getSubsidySummary(Number(providerId), params),
  );

  return getDataWithPagination<SubsidySummary>(subsidies, params);
};

export const getSubsidyDetailResponse = (
  providerId: string | number,
): SubsidyDetailResponse => {
  return {
    data: getSubsidyDetail(Number(providerId)),
  };
};
