import { dynamicDataTest } from '@kaa/common/utils/dataTest';
import { i18nKeys } from '@kaa/i18n/common/keys';
import React, { AllHTMLAttributes, createElement } from 'react';
import { useTranslation } from 'react-i18next';
import { dataTest } from '../../datatest/keys';
import { mergeClassNames } from '../utilities';

type PillProps<T = HTMLDivElement> = AllHTMLAttributes<T> & {
  tagName?: string;
  modError?: boolean;
  modSuccess?: boolean;
  modWarning?: boolean;
  modClickable?: boolean;
  modClosable?: boolean;
  modCheckable?: boolean;
  modRequired?: boolean;
  id?: string;
};

type Classes = {
  modError?: boolean;
  modSuccess?: boolean;
  modWarning?: boolean;
  modClickable?: boolean;
  modClosable?: boolean;
  modCheckable?: boolean;
};

const getClasses = ({
  modError,
  modSuccess,
  modWarning,
  modClickable,
  modClosable,
  modCheckable,
}: Classes) => {
  return [
    'vl-pill',
    modError ? 'vl-pill--error' : '',
    modSuccess ? 'vl-pill--success' : '',
    modWarning ? 'vl-pill--warning' : '',
    modClosable ? 'vl-pill--closable' : '',
    modClickable ? 'vl-pill--clickable' : '',
    modCheckable ? 'vl-pill--checkable' : '',
  ].join(' ');
};

export const SwPill = <T extends HTMLElement>({
  tagName,
  className,
  children,
  modError,
  modSuccess,
  modWarning,
  modClickable,
  modClosable,
  modCheckable,
  modRequired = false,
  id,
  value,
  ...rest
}: PillProps<T>) => {
  const { t } = useTranslation();
  const TagName = tagName || 'div';

  const classes = getClasses({
    modError,
    modSuccess,
    modWarning,
    modClickable,
    modClosable,
    modCheckable,
  });

  return createElement(
    TagName,
    { className: mergeClassNames(classes, className), ...rest },
    <>
      {modClosable && (
        <>
          <span>{children}</span>
          <button
            className="vl-pill__close"
            type="button"
            data-testid={dynamicDataTest(dataTest.SwPill, { id })}
          >
            <span className="vl-u-visually-hidden">
              {t(i18nKeys.general.close)}
            </span>
          </button>
        </>
      )}
      {modCheckable && (
        <>
          <input
            data-testid={`SwPill-input-checkbox-${id}`}
            className="vl-pill--checkable__checkbox"
            type="checkbox"
            name="checkbox"
            id={id}
            value={value}
          />
          <span />
        </>
      )}
      {!modClosable && children}
    </>,
  );
};
