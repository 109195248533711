import { Relative, RemittanceFrequency, WeekDays } from '@kaa/api/providers';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  Icon,
  SwAlert,
  SwCheckboxField,
  SwColumn,
  SwGrid,
  SwRadioField,
  SwSelectField,
} from '@kaa/ui-flanders/components';
import { Field, FieldProps } from 'formik';
import { motion } from 'framer-motion';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { RemittancesAutomaticModalRowTooltip } from './RemittancesAutomaticModalRowTooltip';

type RemittancesAutomaticModalRowProps = {
  relative: Relative;
  frequency?: RemittanceFrequency;
  allSelected?: boolean;
  inputOnChange?: (
    fieldName: string,
    event: ChangeEvent<HTMLInputElement>,
    field: FieldProps['field'],
    form: FieldProps['form'],
  ) => void;
  canEdit: boolean;
};

export const RemittancesAutomaticModalRow = React.memo(
  ({
    relative,
    frequency,
    allSelected,
    inputOnChange,
    canEdit,
  }: RemittancesAutomaticModalRowProps) => {
    const { t } = useTranslation();
    const transition = { ease: 'backInOut', duration: 0.4 };
    const variants = {
      open: { opacity: 1, height: 'auto', padding: '0 0 1rem 1rem' },
      closed: {
        opacity: 0,
        height: 0,
        padding: '0 0 0 1rem',
        overflow: 'hidden',
      },
    };

    const frequencyOptions = Object.keys(RemittanceFrequency).map(
      (frequency) => ({
        value: frequency,
        text: t(
          i18nKeys.remittances.modal.manual.form.frequencyOption[
            frequency.toLowerCase() as 'daily' | 'monthly' | 'none' | 'weekly'
          ],
        ),
      }),
    );

    const modDisabled = !canEdit || (allSelected && !inputOnChange);

    return (
      <>
        <tr
          style={
            frequency === RemittanceFrequency.WEEKLY && !modDisabled
              ? { borderColor: '#fff', transition: 'ease border-color .4s' }
              : { transition: 'ease border-color .4s' }
          }
        >
          <td data-title={t(i18nKeys.general.label.name)}>{relative.name}</td>
          <td data-title={t(i18nKeys.general.label.authorizationNumber)}>
            {relative.id}
          </td>
          <td data-title={t(i18nKeys.general.label.postcode)}>
            {relative.postcode}
          </td>
          <td
            data-title={t(i18nKeys.general.label.frequency)}
            style={
              frequency !== RemittanceFrequency.WEEKLY
                ? {}
                : { borderBottom: '1px solid #fff' }
            }
          >
            <SwGrid modVCenter>
              <SwColumn width="12">
                <Field
                  name={`relatives.id-${relative.id}.frequency`}
                  label=""
                  column={{ width: '12' }}
                  labelColumn={{ width: '12' }}
                  options={frequencyOptions}
                  component={SwSelectField}
                  modDisabled={modDisabled}
                  inputOnChange={(
                    event: ChangeEvent<HTMLInputElement>,
                    field: FieldProps['field'],
                    form: FieldProps['form'],
                  ) => {
                    form.setFieldValue(`touched.id-${relative.id}`, true);
                    if (allSelected && inputOnChange) {
                      inputOnChange('frequency', event, field, form);
                    } else {
                      form.setFieldValue(
                        `relatives.id-${relative.id}.weeklyFrequencyOption`,
                        event.target.value === RemittanceFrequency.WEEKLY
                          ? WeekDays.MONDAY
                          : undefined,
                      );
                    }
                  }}
                />
              </SwColumn>
            </SwGrid>
          </td>
          <td style={{ display: 'flex' }}>
            <SwColumn width="6">
              <Field
                name={`relatives.id-${relative.id}.onlyFullyAssignedServices`}
                modSingle
                column={{ width: '0' }}
                component={SwCheckboxField}
                modDisabled={modDisabled}
                inputOnChange={(
                  event: ChangeEvent<HTMLInputElement>,
                  field: FieldProps['field'],
                  form: FieldProps['form'],
                ) => {
                  form.setFieldValue(`touched.id-${relative.id}`, true);
                  if (allSelected && inputOnChange) {
                    inputOnChange(
                      'onlyFullyAssignedServices',
                      event,
                      field,
                      form,
                    );
                  }
                }}
              />
            </SwColumn>
            <SwColumn width="6" style={{ padding: '0 0 0 1.5rem' }}>
              <RemittancesAutomaticModalRowTooltip relative={relative} />
            </SwColumn>
          </td>
        </tr>

        <tr
          style={
            frequency !== RemittanceFrequency.WEEKLY
              ? { borderColor: '#fff', transition: 'ease border-color .4s' }
              : { transition: 'ease border-color .4s' }
          }
        >
          <td colSpan={5} style={{ padding: '0 1rem 0 0' }}>
            <motion.div
              initial={
                frequency === RemittanceFrequency.WEEKLY && !modDisabled
                  ? 'open'
                  : 'closed'
              }
              className=" vl-form-grid vl-form-grid--align-end"
              transition={transition}
              variants={variants}
              animate={
                frequency === RemittanceFrequency.WEEKLY && !modDisabled
                  ? 'open'
                  : 'closed'
              }
            >
              <SwAlert
                icon={Icon.ALERT_CIRCLE}
                style={{ width: '100%' }}
                title={t(
                  i18nKeys.remittances.modal.manual.form.repeatWeeklyLabel,
                )}
              >
                <SwGrid>
                  {Object.keys(WeekDays).map((day) => {
                    return (
                      <Field
                        style={{ margin: '0 0 1rem 2.5rem' }}
                        key={day}
                        label={t(
                          i18nKeys.remittances.modal.manual.form.day[
                            day.toLowerCase() as
                              | 'friday'
                              | 'monday'
                              | 'saturday'
                              | 'sunday'
                              | 'thursday'
                              | 'tuesday'
                              | 'wednesday'
                          ],
                        )}
                        value={day}
                        id={`relatives.id-${relative.id}.weeklyFrequencyOption.${day}`}
                        column={{ width: '0', widthS: '6' }}
                        name={`relatives.id-${relative.id}.weeklyFrequencyOption`}
                        component={SwRadioField}
                        inputOnChange={(
                          event: ChangeEvent<HTMLInputElement>,
                          field: FieldProps['field'],
                          form: FieldProps['form'],
                        ) => {
                          form.setFieldValue(`touched.id-${relative.id}`, true);
                          if (allSelected && inputOnChange) {
                            inputOnChange(
                              'weeklyFrequencyOption',
                              event,
                              field,
                              form,
                            );
                          }
                        }}
                      />
                    );
                  })}
                </SwGrid>
              </SwAlert>
            </motion.div>
          </td>
        </tr>
      </>
    );
  },
);
