import get from 'lodash.get';
import { getConfig } from '@kaa/core-app/common/config';
import { getApi, LoginResponse } from '@kaa/api/idp';
import { AxiosResponse } from 'axios';
import { COMMON_ROUTES } from '@kaa/common/constants';

export const configureSession: (
  loginUrl: string,
  logoutUrl: string,
  active: boolean,
  session: any,
) => void = (
  loginUrl: string,
  logoutUrl: string,
  active = false,
  session: any,
) => {
  session.configure({
    active,
    endpoints: {
      loginUrl,
      logoutUrl,
    },
  });
};

export const onLogoutRequest = (getUserIntent: any, accessToken?: string) => (
  logoutRequest: any,
) => {
  // Check whether the logout request should be handled.
  // Acknowledge the logout request to prevent the session extension from performing default
  // action due to response timeout (5 seconds).
  logoutRequest.acknowledge();

  // Evaluate the logout reason.
  switch (logoutRequest.getRequest().getReason()) {
    case 'inactivity':
    case 'expired':
      // Because we do a silent renew to the session
      logoutRequest.reject();
      break;
    case 'user': // Accept the application logout request.
      getUserIntent().then((doLogout: boolean) => {
        console.log('getUserIntent', doLogout);
        if (doLogout) {
          logoutRequest.accept();
        } else {
          const idpBaseUrl = get(getConfig(), 'app.auth.oidc.authority');
          const idpApi = getApi({ baseUrl: idpBaseUrl });
          idpApi
            .getIdmSwitchRedirectUrl(
              accessToken,
              `${window.location.origin}${COMMON_ROUTES.LOGIN_CALLBACK.path}`,
            )
            .then((response: AxiosResponse<LoginResponse>) => {
              if (response?.data?.redirectUrl) {
                window.location.replace(response?.data?.redirectUrl);
              }
              logoutRequest.reject();
            })
            .catch((err) => {
              logoutRequest.reject();
            });
        }
      });
      break;
    default:
      // Reject the application logout request.
      logoutRequest.reject();
  }
};
