/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */

export type Activity =
  | 'HOUSEKEEPING'
  | 'GROCERY_SHOPPING'
  | 'IRONING'
  | 'DISABLED_PERSON_TRANSPORTATION';

export const Activity = {
  HOUSEKEEPING: 'HOUSEKEEPING' as Activity,
  GROCERY_SHOPPING: 'GROCERY_SHOPPING' as Activity,
  IRONING: 'IRONING' as Activity,
  DISABLED_PERSON_TRANSPORTATION: 'DISABLED_PERSON_TRANSPORTATION' as Activity,
};
