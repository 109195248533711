/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */

export type VoucherStatus = 'INCORRECT' | 'REFUSED' | 'VALID';

export const VoucherStatus = {
  INCORRECT: 'INCORRECT' as VoucherStatus,
  REFUSED: 'REFUSED' as VoucherStatus,
  VALID: 'VALID' as VoucherStatus,
};
