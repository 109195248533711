import { WorkerContractsResponseDataItem } from '@kaa/api/providers';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AlertType,
  getAlertPropsByType,
  SwActionGroup,
  SwAlert,
  SwButton,
  SwColumn,
  SwDataTable,
  SwGrid,
  SwModal,
  SwTitle,
} from '@kaa/ui-flanders/components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableHead } from '../../../../components';
import { Modals } from '../../../../constants';
import {
  EventAction,
  EventCategory,
  EventLabel,
  openModalWithPageView,
  sendCustomInteractionToGTM,
} from '../../../../utils/google-analytics';
import { AddContractModal } from './contractActions/AddContractModal';
import { DeleteContractModal } from './contractActions/DeleteContractModal';
import { EditContractModal } from './contractActions/EditContractModal';
import { ContractListRow } from './ContractListRow';

type WorkerContractsProps = {
  providerId: string;
  workerId: string;
  contracts: WorkerContractsResponseDataItem[];
};
export const ContractList = ({
  providerId,
  workerId,
  contracts,
}: WorkerContractsProps) => {
  const { t } = useTranslation();

  const [contractStatusAlert, setContractStatusAlert] = useState<{
    type: AlertType;
    msg: string;
  } | null>(null);

  const [workerContracts, setWorkerContracts] = useState<
    WorkerContractsResponseDataItem[]
  >(contracts);

  const [selectedContract, setSelectedContract] = useState<
    WorkerContractsResponseDataItem
  >();

  const addContract = (contract: WorkerContractsResponseDataItem): void => {
    setWorkerContracts((contractsState) => [contract, ...contractsState]);
    setContractStatusAlert({
      type: AlertType.SUCCESS,
      msg: t(i18nKeys.worker.details.contract.alert.addContract.message),
    });
  };

  const updateContract = (contract: WorkerContractsResponseDataItem): void => {
    setWorkerContracts((contractsState) => [
      ...contractsState.map((e) => (e.id === contract.id ? contract : e)),
    ]);
    setContractStatusAlert({
      type: AlertType.SUCCESS,
      msg: t(i18nKeys.worker.details.contract.alert.updateContract.message),
    });
    sendCustomInteractionToGTM(
      EventCategory.WORKER_DETAILS,
      EventAction.FORM_CONFIRMATION,
      EventLabel.WORKER_CONTRACT_EDIT,
    );
  };

  const deleteContract = (contract: WorkerContractsResponseDataItem): void => {
    setWorkerContracts((contractsState) => [
      ...contractsState.filter((e) => e.id !== contract.id),
    ]);
    setContractStatusAlert({
      type: AlertType.SUCCESS,
      msg: t(i18nKeys.worker.details.contract.alert.deleteContract.message),
    });
    sendCustomInteractionToGTM(
      EventCategory.WORKER_DETAILS,
      EventAction.FORM_CONFIRMATION,
      EventLabel.WORKER_CONTRACT_DELETE,
    );
  };

  const openModal = (
    modalId: Modals,
    contract?: WorkerContractsResponseDataItem,
  ): void => {
    setContractStatusAlert(null);
    setSelectedContract(contract);
    openModalWithPageView(modalId);
  };

  const head = [
    { text: t(i18nKeys.worker.details.contract.table.tableHead.workStatus) },
    { text: t(i18nKeys.worker.details.contract.table.tableHead.type) },
    {
      text: t(
        i18nKeys.worker.details.contract.table.tableHead.establishmentNumber,
      ),
    },
    { text: t(i18nKeys.worker.details.contract.table.tableHead.startDate) },
    { text: t(i18nKeys.worker.details.contract.table.tableHead.endDate) },
    { text: '' },
  ];

  return (
    <>
      <SwTitle tagName="h3" tagStyle="h4" className="vl-u-spacer--small">
        {t(i18nKeys.general.label.contracts)}
      </SwTitle>
      {!workerContracts.length ? (
        <SwAlert
          {...getAlertPropsByType(AlertType.INFORMATION)}
          title={t(i18nKeys.worker.details.contract.alert.title)}
        >
          <p>{t(i18nKeys.worker.details.contract.alert.body)}</p>
        </SwAlert>
      ) : (
        <div className="vl-u-table-overflow vl-u-spacer--medium">
          <SwDataTable modLine>
            <TableHead elements={head} />
            <tbody>
              {workerContracts.map((contract) => (
                <ContractListRow
                  contract={contract}
                  openModal={openModal}
                  key={contract.id}
                />
              ))}
            </tbody>
          </SwDataTable>
        </div>
      )}
      <SwActionGroup style={{ marginTop: '3rem' }} className="vl-u-spacer">
        <SwButton
          modLarge
          onClick={() => {
            openModal(Modals.WORKERS_ADD_CONTRACT_MODAL);
          }}
        >
          {t(i18nKeys.worker.details.contract.cta.add)}
        </SwButton>
      </SwActionGroup>
      {contractStatusAlert && (
        <SwGrid>
          <SwColumn>
            <SwAlert
              {...getAlertPropsByType(contractStatusAlert.type)}
              close={() => setContractStatusAlert(null)}
              closable
              modSmall
            >
              {contractStatusAlert.msg}
            </SwAlert>
          </SwColumn>
        </SwGrid>
      )}
      <SwModal
        id={Modals.WORKERS_ADD_CONTRACT_MODAL}
        closable
        providerId={providerId}
        workerId={workerId}
        openModal={openModal}
        onSuccess={addContract}
        component={AddContractModal}
        confirmCloseMessage={t(i18nKeys.dialog.confirmClose.message)}
      />
      {!!selectedContract && (
        <>
          <SwModal
            id={Modals.WORKERS_EDIT_CONTRACT_MODAL}
            closable
            providerId={providerId}
            workerId={workerId}
            contract={selectedContract}
            openModal={openModal}
            onSuccess={updateContract}
            component={EditContractModal}
            confirmCloseMessage={t(i18nKeys.dialog.confirmClose.message)}
          />
          <SwModal
            id={Modals.WORKERS_DELETE_CONTRACT_MODAL}
            closable
            providerId={providerId}
            workerId={workerId}
            contract={selectedContract}
            openModal={openModal}
            onSuccess={deleteContract}
            component={DeleteContractModal}
            confirmCloseMessage={t(i18nKeys.dialog.confirmClose.message)}
          />
        </>
      )}
    </>
  );
};
