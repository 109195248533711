import { useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import { SwButton, SwLink, SwLoader } from '@kaa/ui-flanders/components';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../../utils';
import {
  DashboardActionEvent,
  sendDashboardActionEventToGTM,
} from '../../../utils/google-analytics';
import { DashboardActionsProps } from '../Dashboard.types';
import { getActionsListWorks } from '../Dashboard.utils';
import { DashboardActionsCard } from './components/DashboardActionsCard';
import { DashboardActionsCardLabel } from './components/DashboardActionsCardLabel';

export const DashboardActionsWorks = ({
  providerId,
}: DashboardActionsProps) => {
  const { t } = useTranslation();
  const { providers } = useApi();

  const [
    { value, loading, error },
    getDashboardServicesActions,
  ] = useAsyncCallback(
    async () =>
      (await providers.getDashboardServicesActions(providerId)).data.data,
    [providers],
    { loading: true },
  );

  useEffect(() => {
    getDashboardServicesActions();
  }, [getDashboardServicesActions]);

  const actionsCardProps = {
    title: t(i18nKeys.dashboard.actions.works.title),
    tagName: 'div',
  };

  if (loading && !value) {
    return (
      <DashboardActionsCard {...actionsCardProps}>
        <SwLoader modMessageHidden />
      </DashboardActionsCard>
    );
  }

  if (error || !value) {
    return (
      <DashboardActionsCard {...actionsCardProps}>
        <div style={{ textAlign: 'center' }}>
          <p className="vl-u-spacer--small">
            {t(i18nKeys.errors.serviceUnavailableScreen.title)}
          </p>
          <SwButton onClick={getDashboardServicesActions}>
            {t(i18nKeys.errors.serviceUnavailableScreen.cta)}
          </SwButton>
        </div>
      </DashboardActionsCard>
    );
  }

  const list = getActionsListWorks(value);

  if (!list.length) {
    return (
      <DashboardActionsCard {...actionsCardProps}>
        <p>{t(i18nKeys.dashboard.actions.nothingToDo)}</p>
      </DashboardActionsCard>
    );
  }

  return (
    <DashboardActionsCard {...actionsCardProps}>
      <ul className="vl-link-list">
        {list.map(({ key, url, text, tracking }) => {
          return (
            <li
              key={key}
              className="vl-link-list__item"
              style={{ display: 'flex' }}
            >
              <i
                className="vl-link__icon vl-link__icon--before vl-vi vl-vi-arrow-right-fat"
                aria-hidden="true"
              />
              {url ? (
                <SwLink
                  to={url}
                  style={{
                    padding: '0 0 0 .8rem',
                    display: 'inline-block',
                  }}
                  onClick={() => {
                    sendDashboardActionEventToGTM(
                      DashboardActionEvent.CLICK_ACTION,
                      tracking,
                    );
                  }}
                >
                  <DashboardActionsCardLabel {...text} />
                </SwLink>
              ) : (
                <DashboardActionsCardLabel {...text} />
              )}
            </li>
          );
        })}
      </ul>
    </DashboardActionsCard>
  );
};
