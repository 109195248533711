import { Bill } from '@kaa/api/providers';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  selectPage,
  SwColumn,
  SwDataTable,
  SwGrid,
  SwPaginator,
} from '@kaa/ui-flanders/components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableHead, TableRowNoResult } from '../../../components';
import { Modals } from '../../../constants';
import { BILLING_PAGE_SIZE } from './PickupBilling.contants';
import { PickupBillingTableRow } from './PickupBillingTableRow';

type PickupBillingTableProps = {
  bills: Bill[];
  openModal: (modal: Modals, bill: Bill) => void;
};

export const PickupBillingTable = ({
  bills,
  openModal,
}: PickupBillingTableProps) => {
  const { t } = useTranslation();

  const [selectedPage, setSelectedPage] = useState(1);

  useEffect(() => {
    setSelectedPage(1);
  }, [bills]);

  const head = [
    { text: t(i18nKeys.pickup.billing.table.reference) },
    { text: t(i18nKeys.pickup.billing.table.type) },
    { text: t(i18nKeys.pickup.billing.table.emissionDate) },
    { text: t(i18nKeys.pickup.billing.table.vatFreeAmount) },
    { text: t(i18nKeys.pickup.billing.table.vatIncludedAmount) },
    { text: '' },
  ];

  return (
    <SwGrid>
      <SwColumn>
        <div className="vl-u-table-overflow vl-u-spacer--medium">
          <SwDataTable modLine>
            <TableHead elements={head} />
            <tbody>
              {!bills.length ? (
                <TableRowNoResult
                  colSpan={head.length}
                  style={{ whiteSpace: 'normal' }}
                />
              ) : (
                <>
                  {selectPage<Bill>(bills, selectedPage, BILLING_PAGE_SIZE).map(
                    (bill: Bill) => (
                      <PickupBillingTableRow
                        key={bill.id}
                        bill={bill}
                        openModal={openModal}
                      />
                    ),
                  )}
                </>
              )}
            </tbody>
          </SwDataTable>
        </div>
      </SwColumn>
      <SwColumn>
        <SwPaginator
          itemCount={bills.length}
          pageSize={BILLING_PAGE_SIZE}
          selectedPage={selectedPage}
          setPage={setSelectedPage}
        />
      </SwColumn>
    </SwGrid>
  );
};
