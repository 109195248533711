import { Omit } from '@kaa/ui-flanders/components/utilities';
import { motion, MotionProps, useAnimation } from 'framer-motion';
import React, { HTMLAttributes, useEffect } from 'react';

type MotionTrProps = MotionProps &
  Omit<HTMLAttributes<HTMLElement>, 'defaultValue'> & {
    testid?: string;
    isOpen: boolean;
  };

export const MotionTr = ({
  testid,
  isOpen,
  children,
  ...props
}: MotionTrProps) => {
  const controls = useAnimation();
  const transitions = { ease: 'easeIn', duration: 0.3 };
  const variantsTr = {
    open: { opacity: 1, height: 'auto' },
    close: { opacity: 0, height: 0, overflow: 'hidden' },
  };

  useEffect(() => {
    controls.stop();
    if (isOpen) {
      controls.start('open');
    } else {
      controls.start('close');
    }

    return () => {
      controls.stop();
    };
  }, [isOpen, controls]);

  return (
    <motion.tr
      style={{
        verticalAlign: 'middle',
      }}
      transition={transitions}
      variants={variantsTr}
      animate="open"
      initial={!isOpen ? 'open' : 'close'}
      {...props}
    >
      {children}
    </motion.tr>
  );
};
