import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AlertType,
  getAlertPropsByType,
  SwAlert,
} from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const PrestationEditModalConfirmation = () => {
  const { t } = useTranslation();
  return (
    <>
      <SwAlert
        {...getAlertPropsByType(AlertType.SUCCESS)}
        title={t(i18nKeys.general.label.success)}
        className="vl-u-spacer"
      >
        {t(i18nKeys.prestations.modals.edit.confirmation.message)}
      </SwAlert>
    </>
  );
};
