export const pick = <T>(obj: T, ...props: Array<keyof T>): Partial<T> =>
  props.reduce(
    (result, prop) => ({
      ...result,
      [prop]: obj[prop],
    }),
    {} as T,
  );

export const omit = <T>(obj: T, ...props: Array<keyof T>): Partial<T> => {
  const result = { ...obj };
  props.forEach((prop) => {
    delete result[prop];
  });
  return result;
};

export * from './hooks';
