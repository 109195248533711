import {
  ApiErrorField,
  PaperVoucherAnnouncement,
  PaperVoucherAnnouncementValidation,
  Relative,
  WorkerSummary,
} from '@kaa/api/providers';
import {
  existsInError,
  required,
  ValidationError,
} from '@kaa/common/validation';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import { FieldProps } from 'formik';
import { DateTime } from 'luxon';
import {
  createValidatorWithServerErrorHandled,
  getErrorFromApiErrorField,
  mapperServerError,
} from '../../../../../utils/validation';
import { isValidVoucherNumber } from '../../RemittancesPaper.utils';
import { VoucherDeclarationFieldNames } from './RemittancesVoucherDeclaration.constants';
import {
  PaperVouchersDeclarationForm,
  PaperVoucherWithValidation,
} from './RemittancesVoucherDeclaration.types';

export const getAutocompleteWorkersOption = (
  getWorkers: (
    providerId: string | number,
    query: string,
  ) => Promise<[Error | undefined, WorkerSummary[] | undefined]>,
) => async (
  query: string,
  field: FieldProps<PaperVouchersDeclarationForm>['field'],
  form: FieldProps<PaperVouchersDeclarationForm>['form'],
) => {
  if (!query) {
    return [];
  }

  const [, value = []] = await getWorkers(form.values.id, query);

  return value;
};

export const onSelectWorkerOption = (fieldName: string) => async (
  { firstName, lastName, niss }: WorkerSummary,
  field: FieldProps<PaperVouchersDeclarationForm>['field'],
  form: FieldProps<PaperVouchersDeclarationForm>['form'],
) => {
  const { setFieldValue } = form;
  setFieldValue(VoucherDeclarationFieldNames.WORKER_NISS, niss);
  setFieldValue(fieldName, `${firstName} ${lastName} - ${niss}`);
};

export const menuWorkerOptionFormat = ({
  firstName,
  lastName,
  niss,
}: WorkerSummary) => `${firstName} ${lastName} - ${niss}`;

export const findRegionalAgreementIdByProviderId = (
  relatives: Relative[],
  providerId: string | number,
) => {
  const relative = relatives.find(
    ({ id }) => id.toString() === providerId.toString(),
  );

  if (!relative) {
    return;
  }

  return relative.regionalAgreementId;
};

export const mapperPaperVoucherFieldNameError = (
  validations: ApiErrorField[],
) => {
  const { workerNiss, ...rest } = getErrorFromApiErrorField<
    PaperVoucherAnnouncement
  >({
    validations,
  });

  return { ...rest, workerName: workerNiss };
};

export const transformISODateToDatesJs = (date?: string): Date[] => {
  if (!date) {
    return [];
  }

  const datetime = DateTime.fromISO(date);
  return datetime.isValid ? [datetime.toJSDate()] : [];
};

export const validVoucherDateISO = (value?: string) => {
  if (!value) {
    return false;
  }

  const date = DateTime.fromISO(value);

  if (!date.isValid || date > DateTime.local()) {
    return {
      defaultMessage: 'Invalid',
      id: i18nKeys.errors.form.date.invalid,
    };
  }

  return false;
};

export const isValidNiss = (value?: string): ValidationError | false => {
  if (!value) {
    return false;
  }

  const niss = value.replace(/\D/g, '');

  if (niss.length === 11) {
    return false;
  }

  return {
    defaultMessage: 'Invalid Niss',
    id: i18nKeys.errors.form.niss.invalid,
  };
};

export const validateDeclarationRow = createValidatorWithServerErrorHandled(
  {
    voucherReference: [required, isValidVoucherNumber],
    workerNiss: [],
    workerName: [
      required,
      existsInError(
        `${VoucherDeclarationFieldNames.WORKER_NAME}.autocomplete.invalid`,
      ),
    ],
    workDate: [required],
    activityType: [required],
  },
  { displayFocussed: true },
);

export const getErrorsFromPaperVoucher = (
  paperVoucher: PaperVoucherAnnouncementValidation,
) => {
  const { errors } = mapperServerError<Partial<PaperVoucherWithValidation>>(
    mapperPaperVoucherFieldNameError(paperVoucher.validations),
  );
  return errors;
};

export const getErrorsFromPaperVoucherOneByOne = (
  paperVoucher: PaperVoucherAnnouncementValidation,
) => {
  const { errors } = mapperServerError<Partial<PaperVouchersDeclarationForm>>(
    mapperPaperVoucherFieldNameError(paperVoucher.validations),
  );
  return errors;
};
