import { RegionCode } from '@kaa/api/providers';
import {
  AuthServiceAbstract,
  AuthStorageKeys,
  useAuthState,
} from '@kaa/auth/common';
import { useAsync, useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  Icon,
  SwBadge,
  SwButton,
  SwColumn,
  SwContainer,
  SwFetchErrorMessage,
  SwGrid,
  SwIcon,
  SwInfoTileSpotlight,
  SwLink,
  SwTitle,
} from '@kaa/ui-flanders/components';
import { navigate, RouteComponentProps } from '@reach/router';
import get from 'lodash.get';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getConfig } from '../../common/config';
import { PageHeader } from '../components';
import { getPath, Routes } from '../routes';
import { AuthContext } from '../types';
import './LoginScreen.style.scss';

export const LoginScreen = ({
  local = false,
}: RouteComponentProps<{ local: boolean }>) => {
  const { t, i18n } = useTranslation();

  const config = getConfig();

  const { getUser } = useAuthState<AuthContext>();

  const regionCode = get(config, 'buildConfig.regionCode') as RegionCode;

  const {
    getDirectSigninRequestUrlToIdp,
    getDirectSigninRequestUrlToIdpLocal,
    signinSilent,
  } = useAuthState<AuthServiceAbstract>();

  const [
    { value: signinSilentResponse, loading: signinSilentLoading },
    getSigninSilent,
  ] = useAsyncCallback(async () => {
    return signinSilent();
  }, [signinSilent]);

  const [
    { loading, value: loginUrl, error: loginUrlError },
    getLoginUrl,
  ] = useAsyncCallback(async () => {
    const url = local
      ? await getDirectSigninRequestUrlToIdpLocal()
      : await getDirectSigninRequestUrlToIdp();
    // TODO: Move language in useAuthState
    return `${url}&ui_locales=${i18n.language}`;
  }, [getDirectSigninRequestUrlToIdp]);

  const {
    loading: authLoading,
    value: isAuthenticated,
    error: isAuthenticatedError,
  } = useAsync(async () => getUser().then((authenticated) => authenticated), [
    getUser,
    config,
  ]);

  useEffect(() => {
    getLoginUrl();
  }, [getLoginUrl]);

  useEffect(() => {
    getSigninSilent();
  }, [getSigninSilent]);

  useEffect(() => {
    if (isAuthenticated || signinSilentResponse) {
      const redirectionUri =
        sessionStorage.getItem(AuthStorageKeys.CALLBACK_REDIRECT_URI) ||
        getPath(Routes.DASHBOARD);
      sessionStorage.removeItem(AuthStorageKeys.CALLBACK_REDIRECT_URI);

      navigate(redirectionUri);
    }
  }, [isAuthenticated, signinSilentResponse]);

  if (loading || isAuthenticated || authLoading) {
    return <SwContainer loading />;
  }

  if (
    signinSilentLoading ||
    signinSilentResponse ||
    loading ||
    isAuthenticated ||
    authLoading
  ) {
    return <SwContainer loading />;
  }

  if (isAuthenticatedError || loginUrlError) {
    return (
      <SwContainer error>
        <SwFetchErrorMessage
          onClick={() => {
            window.location.reload();
          }}
        />
      </SwContainer>
    );
  }

  return (
    <SwContainer>
      <SwGrid modStacked>
        <PageHeader title={t(i18nKeys.landing.title)} />
        <SwColumn width="10" widthS="12">
          <SwGrid modStacked className="vl-u-flex-v-flex-start">
            <SwColumn width="6" widthS="12">
              <SwInfoTileSpotlight
                tagName="div"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  width: '100%',
                  height: '100%',
                }}
                footer={
                  <SwButton to={loginUrl} modDisabled={!loginUrl}>
                    {t(i18nKeys.landing.cards.signin.cta)}
                  </SwButton>
                }
              >
                <SwBadge
                  icon={Icon.BURGER_PROFILE}
                  modMedium
                  modAction={regionCode !== RegionCode.BE_WAL}
                  modAccent={regionCode === RegionCode.BE_WAL}
                />
                <SwTitle tagName="h3" tagStyle="h4" className="vl-u-spacer">
                  {t(i18nKeys.navigation.landing)}
                </SwTitle>
                <p className="vl-u-spacer">
                  {t(i18nKeys.landing.cards.signin.description)}
                </p>
              </SwInfoTileSpotlight>
            </SwColumn>
          </SwGrid>
        </SwColumn>
        {regionCode === RegionCode.BE_VLG && (
          <SwColumn width="10" widthS="12">
            <SwIcon
              icon={Icon.INFO_CIRCLE}
              className="vl-u-text--action"
              style={{ marginRight: '1rem' }}
            />
            <p className="vl-u-text--small" style={{ display: 'inline-block' }}>
              {t(i18nKeys.login.usefullLinks.title)}
            </p>
            <ul style={{ marginLeft: '3rem', listStyle: 'initial' }}>
              <li>
                <SwLink
                  to={t(i18nKeys.vl.login.usefullLinks.access.url)}
                  target="_blank"
                  className="vl-u-text--small"
                >
                  {t(i18nKeys.vl.login.usefullLinks.access.label)}
                </SwLink>
              </li>
              <li>
                <SwLink
                  to={t(i18nKeys.vl.login.usefullLinks.review.url)}
                  target="_blank"
                  className="vl-u-text--small"
                >
                  {t(i18nKeys.vl.login.usefullLinks.review.label)}
                </SwLink>
              </li>
            </ul>
          </SwColumn>
        )}
      </SwGrid>
    </SwContainer>
  );
};
