import { CustomersResponseDataItem, CustomerStatus } from '@kaa/api/providers';
import { i18nKeys } from '@kaa/i18n/providers/keys';

export const getCustomerStatus = (customer?: CustomersResponseDataItem) => {
  if (!customer) {
    return;
  }

  switch (customer.status) {
    case CustomerStatus.BLOCKED:
      return {
        title:
          i18nKeys.prestations.modals.add.alert.customerStatus.blocked.content,
        text:
          i18nKeys.prestations.modals.add.alert.customerStatus.blocked.content,
      };
    case CustomerStatus.FROZEN:
      return {
        title:
          i18nKeys.prestations.modals.add.alert.customerStatus.frozen.title,
        text:
          i18nKeys.prestations.modals.add.alert.customerStatus.frozen.content,
      };

    default:
      return undefined;
  }
};
