import { RemittancesOverviewResponse } from '@kaa/api/providers';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  Icon,
  SwButton,
  SwGrid,
  SwLink,
  SwModal,
  SwTitle,
} from '@kaa/ui-flanders/components';
import { navigate } from '@reach/router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modals } from '../../../constants';
import { dataTest } from '../../../datatest/keys';
import { getPath, QueryParams, Routes } from '../../../routes';
import { openModalWithPageView } from '../../../utils/google-analytics';
import { RemittancesCard } from '../components/RemittancesCard';
import { RemittancesVoucherValidityModal } from './components/checkVoucherValidity/RemittancesVoucherValidityModal';
import { RemittancesDeliverySlipCreateModal } from './components/deliverySlips/RemittancesDeliverySlipCreateModal';
import { RemittancesDeliverySlipTemplateModal } from './components/deliverySlips/RemittancesDeliverySlipTemplateModal';
import { RemittancesVoucherDeclarationOneByOneModal } from './components/vouchersDeclaration/RemittancesVoucherDeclarationOneByOneModal';
import { RemittancesVouchersDeclarationModal } from './components/vouchersDeclaration/RemittancesVouchersDeclarationModal';

type RemittancesPaperProps = {
  overview: RemittancesOverviewResponse['data'];
  overviewLoading: boolean;
  getOverview: () => void;
};

export const RemittancesPaper = ({ getOverview }: RemittancesPaperProps) => {
  const { t } = useTranslation();

  return (
    <>
      <SwTitle tagName="h2" className="vl-u-spacer--medium">
        {t(i18nKeys.general.supportType.PAPER)}
      </SwTitle>
      <SwGrid
        modVStretched
        style={{ width: '100%', justifyContent: 'flex-start' }}
      >
        <RemittancesCard
          title={t(i18nKeys.remittances.cards.deliverySlips.title)}
          text={t(i18nKeys.remittances.cards.deliverySlips.description)}
          icon={Icon.FILE_DOWNLOAD}
        >
          <SwButton
            data-testid={
              dataTest.remittances.paper.deliverySlip.openTemplateModal
            }
            modBlock
            modNarrow
            onClick={() => {
              openModalWithPageView(Modals.REMITTANCES_SLIP_MODAL_ID);
            }}
          >
            {t(i18nKeys.remittances.cards.deliverySlips.cta.createDeliverySlip)}
          </SwButton>
          <SwLink
            data-testid={
              dataTest.remittances.paper.deliverySlip.openVirginTemplateModal
            }
            className="vl-u-text--small"
            tagName="button"
            onClick={() => {
              openModalWithPageView(Modals.REMITTANCES_SLIP_TEMPLATE_MODAL_ID);
            }}
          >
            {t(i18nKeys.remittances.cards.deliverySlips.cta.downloadTemplate)}
          </SwLink>
        </RemittancesCard>
        <RemittancesCard
          title={t(i18nKeys.remittances.cards.vouchersValidity.title)}
          text={t(i18nKeys.remittances.cards.vouchersValidity.description)}
          icon={Icon.VOUCHER_CHECK}
        >
          <SwButton
            modBlock
            modNarrow
            onClick={() => {
              openModalWithPageView(Modals.REMITTANCES_CHECK_VALIDITY_MODAL_ID);
            }}
          >
            {t(i18nKeys.remittances.cards.vouchersValidity.cta)}
          </SwButton>
          <SwLink className="vl-u-text--small" />
        </RemittancesCard>
        <RemittancesCard
          title={t(i18nKeys.remittances.cards.vouchersDeclaration.title)}
          text={t(i18nKeys.remittances.cards.vouchersDeclaration.description)}
          icon={Icon.VOUCHERS_LIST}
        >
          <SwButton
            modBlock
            modNarrow
            onClick={() => {
              openModalWithPageView(
                Modals.REMITTANCES_VOUCHERS_DECLARATION_MODAL_ID,
              );
            }}
          >
            {t(i18nKeys.remittances.cards.vouchersDeclaration.cta.importList)}
          </SwButton>
          <SwLink
            className="vl-u-text--small"
            tagName="button"
            onClick={() => {
              openModalWithPageView(
                Modals.REMITTANCES_VOUCHER_DECLARATION_ONE_BY_ONE_MODAL_ID,
              );
            }}
          >
            {t(
              i18nKeys.remittances.cards.vouchersDeclaration.cta.declareVoucher,
            )}
          </SwLink>
        </RemittancesCard>
        <RemittancesCard
          title={t(i18nKeys.remittances.cards.pickupPlanning.title)}
          text={t(i18nKeys.remittances.cards.pickupPlanning.description)}
          icon={Icon.PICK_UP}
        >
          <SwButton
            modBlock
            modNarrow
            onClick={() => {
              const path = `${getPath(Routes.PICKUP, {
                queryParams: {
                  [QueryParams.MODAL]: Modals.PICKUP_CREATE_PICKUP_MODAL,
                },
              })}`;
              navigate(path);
            }}
          >
            {t(i18nKeys.remittances.cards.pickupPlanning.cta.managePlanning)}
          </SwButton>
          <SwLink className="vl-u-text--small" />
        </RemittancesCard>
      </SwGrid>
      <SwModal
        id={Modals.REMITTANCES_SLIP_TEMPLATE_MODAL_ID}
        component={RemittancesDeliverySlipTemplateModal}
        confirmCloseMessage={t(i18nKeys.dialog.confirmClose.message)}
        closable
      />
      <SwModal
        id={Modals.REMITTANCES_SLIP_MODAL_ID}
        component={RemittancesDeliverySlipCreateModal}
        confirmCloseMessage={t(i18nKeys.dialog.confirmClose.message)}
        closable
      />
      <SwModal
        id={Modals.REMITTANCES_CHECK_VALIDITY_MODAL_ID}
        component={RemittancesVoucherValidityModal}
        confirmCloseMessage={t(i18nKeys.dialog.confirmClose.message)}
        closable
      />
      <SwModal
        id={Modals.REMITTANCES_VOUCHER_DECLARATION_ONE_BY_ONE_MODAL_ID}
        component={RemittancesVoucherDeclarationOneByOneModal}
        confirmCloseMessage={t(i18nKeys.dialog.confirmClose.message)}
        onSuccess={getOverview}
        closable
      />
      <SwModal
        id={Modals.REMITTANCES_VOUCHERS_DECLARATION_MODAL_ID}
        component={RemittancesVouchersDeclarationModal}
        confirmCloseMessage={t(i18nKeys.dialog.confirmClose.message)}
        onSuccess={getOverview}
        modFullScreen
        closable
      />
    </>
  );
};
