import {
  GetListServicesParams,
  GetWorkerServicesParams,
  Provider,
  Service,
} from '@kaa/api/providers';
import { ServiceUpdateResponseDataData } from '@kaa/api/providers/model/serviceUpdateResponseDataData';
import { httpTo } from '@kaa/api/providers/utilities';
import { ActionType, useActionDispatch } from '@kaa/common/context';
import { useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import { AlertType, SwModal } from '@kaa/ui-flanders/components';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modals } from '../../../constants';
import { useApi } from '../../../utils';
import {
  EventAction,
  EventCategory,
  EventLabel,
  sendCustomInteractionToGTM,
} from '../../../utils/google-analytics';
import { ValidateStatus } from '../PrestationsScreen.types';
import { PrestationCancelModal } from './cancel/PrestationCancelModal';
import { PrestationDetailsModal } from './details/PrestationDetailsModal';
import { PrestationEditModal } from './edit/PrestationEditModal';
import { PrestationsModalParameters } from './PrestationsModals.constants';
import { PrestationsSearchValidateModal } from './validate/PrestationsSearchValidateModal';

type PrestationsModalsProps = {
  provider: Provider;
  parameters: GetWorkerServicesParams | GetListServicesParams;
  selectedService: Service;
  services: Service[];
  setServices: (value: React.SetStateAction<Service[]>) => void;
  onSuccess: (props: ServiceUpdateResponseDataData) => void;
  setValidateStatus: (
    value: React.SetStateAction<ValidateStatus | undefined>,
  ) => void;
};

export const PrestationsModals = ({
  provider,
  parameters,
  selectedService,
  services,
  setServices,
  onSuccess,
  setValidateStatus,
}: PrestationsModalsProps) => {
  const { t } = useTranslation();
  const { providers } = useApi();

  const dispatchAction = useActionDispatch();

  const providerId = Number(parameters?.searchedProviderId || provider.id);

  const removeService = ({ service, services }: PrestationsModalParameters) => {
    setServices(
      services.filter((oldService: Service) => service.id !== oldService.id),
    );
  };

  const updateService = ({ service, services }: PrestationsModalParameters) => {
    setServices(
      services.map((oldService: Service) =>
        service.id === oldService.id ? service : oldService,
      ),
    );
  };

  const [
    { value: validateWorkerServiceData, loading: validateWorkerServiceLoading },
    validateWorkerService,
  ] = useAsyncCallback(
    async ({ service, services }: PrestationsModalParameters) => {
      const [error, response] = await httpTo(
        providers.validateWorkerService(providerId, Number(service.id)),
      );

      return {
        service,
        services,
        response: response?.data,
        error,
      };
    },
    [providers],
    { loading: false, value: undefined },
  );

  useEffect(() => {
    if (validateWorkerServiceData) {
      const { service, services, response, error } = validateWorkerServiceData;

      if (error || !response) {
        setValidateStatus({
          type: AlertType.ERROR,
          title: t(i18nKeys.general.label.error),
          msg: t(
            i18nKeys.prestations.modals.validate.alert.validateForWorker.error,
          ),
        });
        return;
      }

      const { data, actions } = response;

      if (actions) {
        dispatchAction({
          type: ActionType.ADD,
          payload: actions,
        });
      }

      const { service: editedService, ...rest } = data;

      setValidateStatus({
        type: AlertType.SUCCESS,
        title: t(i18nKeys.general.label.success),
        msg: t(
          i18nKeys.prestations.modals.validate.alert.validateForWorker.success,
        ),
      });

      updateService({
        services,
        service: {
          ...service,
          ...editedService,
        },
      });

      onSuccess(rest);

      sendCustomInteractionToGTM(
        EventCategory.PRESTATIONS,
        EventAction.CLICK,
        EventLabel.PRESTATIONS_VALIDATE,
      );
    }
  }, [validateWorkerServiceData]);

  const prestationsModalParameters = {
    service: selectedService,
    services,
  } as PrestationsModalParameters;

  return (
    <>
      <SwModal
        id={`${Modals.PRESTATIONS_DETAILS_PRESTATION_MODAL}${selectedService.workerId}`}
        component={PrestationDetailsModal}
        confirmCloseMessage={t(i18nKeys.dialog.confirmClose.message)}
        prestationId={selectedService.id}
        searchedProviderId={providerId}
        closable
      />
      <SwModal
        id={`${Modals.PRESTATIONS_EDIT_PRESTATION_MODAL}${selectedService.workerId}`}
        component={PrestationEditModal}
        confirmCloseMessage={t(i18nKeys.dialog.confirmClose.message)}
        prestationId={selectedService.id}
        searchedProviderId={providerId}
        validateService={updateService}
        parameters={prestationsModalParameters}
        onSuccess={onSuccess}
        closable
      />
      <SwModal
        id={`${Modals.PRESTATIONS_CANCEL_PRESTATION_MODAL}${selectedService.workerId}`}
        component={PrestationCancelModal}
        confirmCloseMessage={t(i18nKeys.dialog.confirmClose.message)}
        prestationId={selectedService.id}
        searchedProviderId={providerId}
        cancelService={removeService}
        parameters={prestationsModalParameters}
        onSuccess={onSuccess}
        closable
      />
      <SwModal
        id={`${Modals.PRESTATIONS_VALIDATE_PRESTATION_MODAL}${selectedService.workerId}`}
        modalId={`${Modals.PRESTATIONS_VALIDATE_PRESTATION_MODAL}${selectedService.workerId}`}
        component={PrestationsSearchValidateModal}
        confirm={validateWorkerService}
        confirmText={t(i18nKeys.general.cta.validate)}
        title={t(i18nKeys.prestations.search.modal.validate, {
          customerName: selectedService.customerFullname,
          workId: selectedService.id,
        })}
        parameters={prestationsModalParameters}
        closable
        isLoading={validateWorkerServiceLoading}
      />
    </>
  );
};
