/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */

export type PaperVoucherValidityReference =
  | 'VALID'
  | 'POSTAL_LOSS'
  | 'LOST_OR_STOLEN'
  | 'REFUNDED'
  | 'CANCELLED'
  | 'BLACKLISTED'
  | 'EXPIRED'
  | 'INVALID'
  | 'NOT_FOUND'
  | 'UNKNOWN';

export const PaperVoucherValidityReference = {
  VALID: 'VALID' as PaperVoucherValidityReference,
  POSTAL_LOSS: 'POSTAL_LOSS' as PaperVoucherValidityReference,
  LOST_OR_STOLEN: 'LOST_OR_STOLEN' as PaperVoucherValidityReference,
  REFUNDED: 'REFUNDED' as PaperVoucherValidityReference,
  CANCELLED: 'CANCELLED' as PaperVoucherValidityReference,
  BLACKLISTED: 'BLACKLISTED' as PaperVoucherValidityReference,
  EXPIRED: 'EXPIRED' as PaperVoucherValidityReference,
  INVALID: 'INVALID' as PaperVoucherValidityReference,
  NOT_FOUND: 'NOT_FOUND' as PaperVoucherValidityReference,
  UNKNOWN: 'UNKNOWN' as PaperVoucherValidityReference,
};
