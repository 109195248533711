import {
  PickupAddress,
  PickupConfigurationResponseData,
  ProviderType,
  Relative,
} from '@kaa/api/providers';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  SwColumn,
  SwLoader,
  SwRadioField,
  SwSelectField,
} from '@kaa/ui-flanders/components';
import { Field, FieldProps } from 'formik';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelectedProviderState } from '../../../../utils';
import {
  PickupCreateFormData,
  PickupFormFieldNames,
  PickupUpdateFormData,
} from '../PickupModals.constants';
import {
  getAddressLabel,
  getAddressValue,
  getRelativesOptions,
} from '../PickupModals.utils';

type PickupModalsRelativesProps = {
  loading: boolean;
  relatives: Relative[];
  configuration: PickupConfigurationResponseData;
  onRelativeChange: (
    relativeId: string,
    form: FieldProps<PickupCreateFormData | PickupUpdateFormData>['form'],
  ) => void;
  onAddressSelect?: (
    comment: string | undefined,
    form: FieldProps<PickupCreateFormData | PickupUpdateFormData>['form'],
  ) => void;
};

export const PickupModalsRelatives = ({
  loading,
  relatives,
  configuration,
  onRelativeChange: onRelativeChangeEvent,
  onAddressSelect: onAddressSelectEvent,
}: PickupModalsRelativesProps) => {
  const { t } = useTranslation();
  const provider = useSelectedProviderState();

  const { mainPickupAddress, otherPickupAddresses = [] } = configuration;

  const pickupAddress = [mainPickupAddress, ...otherPickupAddresses];

  const onRelativeChange = (
    e: ChangeEvent<HTMLInputElement>,
    field: FieldProps<PickupCreateFormData | PickupUpdateFormData>['field'],
    form: FieldProps<PickupCreateFormData | PickupUpdateFormData>['form'],
  ) => {
    onRelativeChangeEvent(e.target.value, form);
  };

  const onAddressSelect = (
    e: ChangeEvent<HTMLInputElement>,
    field: FieldProps<PickupCreateFormData | PickupUpdateFormData>['field'],
    form: FieldProps<PickupCreateFormData | PickupUpdateFormData>['form'],
    comment: string | undefined,
  ) => {
    if (onAddressSelectEvent) {
      onAddressSelectEvent(comment, form);
    }
  };

  if (loading) {
    return (
      <>
        <SwColumn width="12" className="vl-u-spacer--small">
          <Field
            modNoPlaceholder
            column={{ width: '12' }}
            labelColumn={{ width: '12' }}
            name={PickupFormFieldNames.RELATIVE_ID}
            label={t(i18nKeys.pickup.modal.add.form.location)}
            component={SwSelectField}
            options={getRelativesOptions(relatives)}
            modDisabled={provider.type !== ProviderType.HEADQUARTER}
            inputOnChange={onRelativeChange}
          />
        </SwColumn>
        <SwColumn width="12" className="vl-u-spacer">
          <SwLoader modMessageHidden />
        </SwColumn>
      </>
    );
  }

  return (
    <>
      <SwColumn width="12" className="vl-u-spacer--small">
        <Field
          modNoPlaceholder
          column={{ width: '12' }}
          labelColumn={{ width: '12' }}
          name={PickupFormFieldNames.RELATIVE_ID}
          label={t(i18nKeys.pickup.modal.add.form.location)}
          component={SwSelectField}
          options={getRelativesOptions(relatives)}
          modDisabled={provider.type !== ProviderType.HEADQUARTER}
          inputOnChange={onRelativeChange}
        />
      </SwColumn>
      <SwColumn width="12" className="vl-u-spacer">
        {pickupAddress.map(({ address, comment }: PickupAddress, i: number) => (
          <Field
            key={`${PickupFormFieldNames.ADDRESS}-${i + 1}`}
            name={PickupFormFieldNames.ADDRESS}
            id={`${getAddressValue(address)}`}
            label={getAddressLabel(address)}
            component={SwRadioField}
            inputOnChange={(
              e: ChangeEvent<HTMLInputElement>,
              field: FieldProps<
                PickupCreateFormData | PickupUpdateFormData
              >['field'],
              form: FieldProps<
                PickupCreateFormData | PickupUpdateFormData
              >['form'],
            ) => onAddressSelect(e, field, form, comment)}
          />
        ))}
      </SwColumn>
    </>
  );
};
