/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */

export type RelativeActionType = 'CAN_SEE_DETAILS' | 'CAN_EDIT';

export const RelativeActionType = {
  CAN_SEE_DETAILS: 'CAN_SEE_DETAILS' as RelativeActionType,
  CAN_EDIT: 'CAN_EDIT' as RelativeActionType,
};
