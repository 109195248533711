import { maxLength, required } from '@kaa/common/validation';
import { ContractFormFieldNames } from './ContractForm.constant';

export const getFieldName = (name: string, fieldNamePrefix?: string) => {
  if (!fieldNamePrefix) {
    return name;
  }

  return `${fieldNamePrefix}.${name}`;
};

export const getRequiredDefaultContractFields = (fieldNamePrefix?: string) => ({
  [getFieldName(ContractFormFieldNames.WORK_STATUS, fieldNamePrefix)]: '',
  [getFieldName(ContractFormFieldNames.TYPE, fieldNamePrefix)]: '',
  [getFieldName(ContractFormFieldNames.UNIT_NUMBER, fieldNamePrefix)]: '',
  [getFieldName(ContractFormFieldNames.START_DATE, fieldNamePrefix)]: '',
  [getFieldName(ContractFormFieldNames.END_DATE, fieldNamePrefix)]: '',
});

export const getDefaultContractValidation = (fieldNamePrefix?: string) => ({
  [getFieldName(ContractFormFieldNames.WORK_STATUS, fieldNamePrefix)]: [],
  [getFieldName(ContractFormFieldNames.TYPE, fieldNamePrefix)]: [],
  [getFieldName(ContractFormFieldNames.UNIT_NUMBER, fieldNamePrefix)]: [
    maxLength(40),
    required,
  ],
  [getFieldName(ContractFormFieldNames.START_DATE, fieldNamePrefix)]: [],
  [getFieldName(ContractFormFieldNames.END_DATE, fieldNamePrefix)]: [],
});
