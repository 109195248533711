import { Sorting } from '@kaa/api/providers';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import { Icon, SwBadge, useTooltip } from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getLabelForSorting, TableHead } from '../../../../../components';

type RemittancesAutomaticModalTableHeadProps = {
  changeSort?: (sort: Sorting) => void;
  currentSort?: Sorting;
};

export const RemittancesAutomaticModalTableHead = ({
  changeSort,
  currentSort,
}: RemittancesAutomaticModalTableHeadProps) => {
  const { t } = useTranslation();

  const refTooltipInfo = useTooltip({
    value: `<div style="white-space: normal;">${t(
      i18nKeys.remittances.modal.automatic.onlyFullyAssignedServices.definition,
    )}</div>`,
    placement: 'auto',
  });

  const head = [
    currentSort
      ? {
          text: t(i18nKeys.general.label.name),
          label: getLabelForSorting(Sorting.NAME_ASC, currentSort, t),
          onClick: () => {
            if (typeof changeSort === 'function') {
              changeSort(
                currentSort === Sorting.NAME_ASC
                  ? Sorting.NAME_DESC
                  : Sorting.NAME_ASC,
              );
            }
          },
        }
      : { text: t(i18nKeys.general.label.name) },
    { text: t(i18nKeys.general.label.authorizationNumber) },
    currentSort
      ? {
          text: t(i18nKeys.general.label.postcode),
          label: getLabelForSorting(Sorting.POSTCODE_ASC, currentSort, t),
          onClick: () => {
            if (typeof changeSort === 'function') {
              changeSort(
                currentSort === Sorting.POSTCODE_ASC
                  ? Sorting.POSTCODE_DESC
                  : Sorting.POSTCODE_ASC,
              );
            }
          },
        }
      : { text: t(i18nKeys.general.label.postcode) },
    { text: t(i18nKeys.general.label.frequency) },
    {
      text: t(i18nKeys.general.label.onlyFullyAssignedServices),
      style: { width: '20rem' },
      children: (
        <SwBadge
          style={{
            verticalAlign: 'top',
            marginLeft: '0.5rem',
          }}
          ref={refTooltipInfo}
          modAction
          modXsmall
          icon={Icon.INFO}
        />
      ),
    },
  ];

  return <TableHead elements={head} />;
};
