/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */

export type Sorting =
  | 'NONE'
  | 'ID_ASC'
  | 'ID_DESC'
  | 'DATE_ASC'
  | 'DATE_DESC'
  | 'CREATION_DATE_ASC'
  | 'CREATION_DATE_DESC'
  | 'WORKER_ASC'
  | 'WORKER_DESC'
  | 'CUSTOMER_ASC'
  | 'CUSTOMER_DESC'
  | 'USER_ASC'
  | 'USER_DESC'
  | 'ACTIVITY_ASC'
  | 'ACTIVITY_DESC'
  | 'HOURS_ASC'
  | 'HOURS_DESC'
  | 'STATUT_ASC'
  | 'STATUT_DESC'
  | 'REMITTANCE_NUMBER_ASC'
  | 'REMITTANCE_NUMBER_DESC'
  | 'REFERENCE_ASC'
  | 'REFERENCE_DESC'
  | 'MONTH_OF_WORK_ASC'
  | 'MONTH_OF_WORK_DESC'
  | 'SUPPORT_ASC'
  | 'SUPPORT_DESC'
  | 'REFUND_CENTER_ASC'
  | 'REFUND_CENTER_DESC'
  | 'NUMBER_OF_REFUND_VOUCHER_ASC'
  | 'NUMBER_OF_REFUND_VOUCHER_DESC'
  | 'PAYMENT_AMOUNT_ASC'
  | 'PAYMENT_AMOUNT_DESC'
  | 'PAYMENT_DATE_ASC'
  | 'PAYMENT_DATE_DESC'
  | 'PAYMENT_REFERENCE_ASC'
  | 'PAYMENT_REFERENCE_DESC'
  | 'IS_BLOCKED_ASC'
  | 'IS_BLOCKED_DESC'
  | 'NUMBER_OF_VOUCHERS_ASC'
  | 'NUMBER_OF_VOUCHERS_DESC'
  | 'TOTAL_HOURS_ASC'
  | 'TOTAL_HOURS_DESC'
  | 'POSTCODE_ASC'
  | 'POSTCODE_DESC'
  | 'COMMERCIAL_NAME_ASC'
  | 'COMMERCIAL_NAME_DESC'
  | 'PROVIDER_ID_ASC'
  | 'PROVIDER_ID_DESC'
  | 'CONSUMPTION_DATE_ASC'
  | 'CONSUMPTION_DATE_DESC'
  | 'NAME_ASC'
  | 'NAME_DESC';

export const Sorting = {
  NONE: 'NONE' as Sorting,
  ID_ASC: 'ID_ASC' as Sorting,
  ID_DESC: 'ID_DESC' as Sorting,
  DATE_ASC: 'DATE_ASC' as Sorting,
  DATE_DESC: 'DATE_DESC' as Sorting,
  CREATION_DATE_ASC: 'CREATION_DATE_ASC' as Sorting,
  CREATION_DATE_DESC: 'CREATION_DATE_DESC' as Sorting,
  WORKER_ASC: 'WORKER_ASC' as Sorting,
  WORKER_DESC: 'WORKER_DESC' as Sorting,
  CUSTOMER_ASC: 'CUSTOMER_ASC' as Sorting,
  CUSTOMER_DESC: 'CUSTOMER_DESC' as Sorting,
  USER_ASC: 'USER_ASC' as Sorting,
  USER_DESC: 'USER_DESC' as Sorting,
  ACTIVITY_ASC: 'ACTIVITY_ASC' as Sorting,
  ACTIVITY_DESC: 'ACTIVITY_DESC' as Sorting,
  HOURS_ASC: 'HOURS_ASC' as Sorting,
  HOURS_DESC: 'HOURS_DESC' as Sorting,
  STATUT_ASC: 'STATUT_ASC' as Sorting,
  STATUT_DESC: 'STATUT_DESC' as Sorting,
  REMITTANCE_NUMBER_ASC: 'REMITTANCE_NUMBER_ASC' as Sorting,
  REMITTANCE_NUMBER_DESC: 'REMITTANCE_NUMBER_DESC' as Sorting,
  REFERENCE_ASC: 'REFERENCE_ASC' as Sorting,
  REFERENCE_DESC: 'REFERENCE_DESC' as Sorting,
  MONTH_OF_WORK_ASC: 'MONTH_OF_WORK_ASC' as Sorting,
  MONTH_OF_WORK_DESC: 'MONTH_OF_WORK_DESC' as Sorting,
  SUPPORT_ASC: 'SUPPORT_ASC' as Sorting,
  SUPPORT_DESC: 'SUPPORT_DESC' as Sorting,
  REFUND_CENTER_ASC: 'REFUND_CENTER_ASC' as Sorting,
  REFUND_CENTER_DESC: 'REFUND_CENTER_DESC' as Sorting,
  NUMBER_OF_REFUND_VOUCHER_ASC: 'NUMBER_OF_REFUND_VOUCHER_ASC' as Sorting,
  NUMBER_OF_REFUND_VOUCHER_DESC: 'NUMBER_OF_REFUND_VOUCHER_DESC' as Sorting,
  PAYMENT_AMOUNT_ASC: 'PAYMENT_AMOUNT_ASC' as Sorting,
  PAYMENT_AMOUNT_DESC: 'PAYMENT_AMOUNT_DESC' as Sorting,
  PAYMENT_DATE_ASC: 'PAYMENT_DATE_ASC' as Sorting,
  PAYMENT_DATE_DESC: 'PAYMENT_DATE_DESC' as Sorting,
  PAYMENT_REFERENCE_ASC: 'PAYMENT_REFERENCE_ASC' as Sorting,
  PAYMENT_REFERENCE_DESC: 'PAYMENT_REFERENCE_DESC' as Sorting,
  IS_BLOCKED_ASC: 'IS_BLOCKED_ASC' as Sorting,
  IS_BLOCKED_DESC: 'IS_BLOCKED_DESC' as Sorting,
  NUMBER_OF_VOUCHERS_ASC: 'NUMBER_OF_VOUCHERS_ASC' as Sorting,
  NUMBER_OF_VOUCHERS_DESC: 'NUMBER_OF_VOUCHERS_DESC' as Sorting,
  TOTAL_HOURS_ASC: 'TOTAL_HOURS_ASC' as Sorting,
  TOTAL_HOURS_DESC: 'TOTAL_HOURS_DESC' as Sorting,
  POSTCODE_ASC: 'POSTCODE_ASC' as Sorting,
  POSTCODE_DESC: 'POSTCODE_DESC' as Sorting,
  COMMERCIAL_NAME_ASC: 'COMMERCIAL_NAME_ASC' as Sorting,
  COMMERCIAL_NAME_DESC: 'COMMERCIAL_NAME_DESC' as Sorting,
  PROVIDER_ID_ASC: 'PROVIDER_ID_ASC' as Sorting,
  PROVIDER_ID_DESC: 'PROVIDER_ID_DESC' as Sorting,
  CONSUMPTION_DATE_ASC: 'CONSUMPTION_DATE_ASC' as Sorting,
  CONSUMPTION_DATE_DESC: 'CONSUMPTION_DATE_DESC' as Sorting,
  NAME_ASC: 'NAME_ASC' as Sorting,
  NAME_DESC: 'NAME_DESC' as Sorting,
};
