import React, { createElement, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { IndicatorProps } from 'react-select/src/components/indicators';
import {
  ActionMeta,
  GroupedOptionsType,
  OptionsType,
  OptionTypeBase,
  ValueType,
} from 'react-select/src/types';
import { dataTest } from '../../datatest/keys';
import { SwIcon } from '../icon/SwIcon';
import { Icon } from '../icon/SwIcon.constants';
import './SwMultiSelect.scss';

const DropdownIndicator = <OptionType extends any>(
  props: PropsWithChildren<IndicatorProps<OptionType>>,
) => {
  const { DropdownIndicator } = components;
  return (
    DropdownIndicator && (
      <DropdownIndicator {...props}>
        <SwIcon icon={Icon.SEARCH} />
      </DropdownIndicator>
    )
  );
};

const selectStyles = {
  option: (styles: any, { isFocused }: { isFocused: any }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? '#e8ebee' : 'white',
    };
  },
};

type SwMultiselectProps<OptionType = { label: string; value: string }> = {
  onChange: (value: ValueType<OptionType>, actionMeta: ActionMeta) => void;
  options: GroupedOptionsType<OptionType> | OptionsType<OptionType>;
  isCreatable?: boolean;
  placeHolder?: string;
};

export const SwMultiselect = <OptionType extends any>({
  onChange,
  options,
  isCreatable,
  placeHolder = '',
}: SwMultiselectProps<OptionType>) => {
  const { t } = useTranslation();
  const TagName = isCreatable ? CreatableSelect : Select;

  return createElement(TagName, {
    'data-testid': dataTest.SwMultiselect,
    components: { DropdownIndicator },
    className: 'js-vl-multiselect',
    isMulti: true,
    onChange,
    options,
    styles: selectStyles,
    placeholder: placeHolder,
  } as OptionTypeBase);
};
