import { getResourceIdMock, POSTCODES_CITIES } from '@kaa/api/common/mocks';
import faker from 'faker';
import {
  ProviderActionType,
  Relative,
  RelativeAddresses,
  RelativeAddressesResponse,
  RelativesResponse,
  RemittanceFrequency,
  WeekDays,
} from '../model';
import { GetProviderRelativesParams } from '../model/getProviderRelativesParams';
import { getProviderAddress, getRandomArray } from './utils';

export const RESOURCE_PREFIX_RELATIVE = 'relative';

export const getHeadquarterOrRelative = (
  relative?: Partial<Relative>,
): Relative => {
  const id = faker.random.number(999999);
  const POSTOCDE_CITY = faker.helpers.randomize(POSTCODES_CITIES);
  return {
    id: faker.random.number(999999),
    name: faker.company.companyName(),
    postcode: POSTOCDE_CITY.postcode,
    isActive: faker.random.boolean(),
    resourceId: getResourceIdMock(
      RESOURCE_PREFIX_RELATIVE,
      (relative && relative.id) || id,
    ),
    regionalAgreementId: faker.random.number(999999).toString(),
    remittanceFrequency: faker.helpers.randomize(
      Object.values(RemittanceFrequency),
    ),
    weeklyRemittanceFrequency: faker.helpers.randomize(Object.values(WeekDays)),
    onlyFullyAssignedServices: faker.random.boolean(),
    lastModificationTimestamp: faker.date.past().toISOString(),
    lastAuthoredBy: faker.random.word(),
    lastAuthorCompanyName: faker.company.companyName(),
    lastAuthorCompanyId: faker.random.number(),
    ...relative,
  };
};

export const getRelativesResponse = (
  providerId: number = faker.random.number(999999),
  params?: GetProviderRelativesParams,
): RelativesResponse => {
  const headquarterId = providerId || faker.random.number(999999);

  const relativeOneId = faker.random.number(999999);
  const relativeOneCanDisable = faker.random.boolean();
  const relativeOneCanEdit = faker.random.boolean();

  const relativeTwoId = faker.random.number(999999);
  const relativeTwoCanDisable = faker.random.boolean();
  const relativeTwoCanEdit = faker.random.boolean();

  const relativeThreeId = faker.random.number(999999);
  const relativeThreeCanDisable = faker.random.boolean();
  const relativeThreeCanEdit = faker.random.boolean();

  return {
    data: {
      headquarter: getHeadquarterOrRelative({
        id: headquarterId,
      }),
      subsidiaries: [
        getHeadquarterOrRelative({
          id: relativeOneId,
          isActive: relativeOneCanDisable,
        }),
        getHeadquarterOrRelative({
          id: relativeTwoId,
          isActive: relativeTwoCanDisable,
        }),
        getHeadquarterOrRelative({
          id: relativeThreeId,
          isActive: relativeThreeCanDisable,
        }),
        ...getRandomArray(99).map(getHeadquarterOrRelative),
      ],
    },
    actions: {
      [getResourceIdMock(RESOURCE_PREFIX_RELATIVE, headquarterId)]: {
        CAN_EDIT: faker.random.boolean(),
        CAN_DISABLE: faker.random.boolean(),
      },
      [getResourceIdMock(RESOURCE_PREFIX_RELATIVE, relativeOneId)]: {
        CAN_EDIT: relativeOneCanEdit,
        CAN_DISABLE: relativeOneCanDisable,
      },
      [getResourceIdMock(RESOURCE_PREFIX_RELATIVE, relativeTwoId)]: {
        CAN_EDIT: relativeTwoCanEdit,
        CAN_DISABLE: relativeTwoCanDisable,
      },
      [getResourceIdMock(RESOURCE_PREFIX_RELATIVE, relativeThreeId)]: {
        CAN_EDIT: relativeThreeCanEdit,
        CAN_DISABLE: relativeThreeCanDisable,
      },
      [getResourceIdMock(RESOURCE_PREFIX_RELATIVE, relativeThreeId)]: {
        CAN_EDIT: relativeThreeCanEdit,
        CAN_DISABLE: relativeThreeCanDisable,
      },
      [getResourceIdMock('provider', providerId)]: {
        [ProviderActionType.CAN_CREATE_RELATIVE]: faker.random.boolean(),
      },
    },
  };
};

export const getRelativeAddresses = (): RelativeAddresses => ({
  mainAddress: getProviderAddress(),
  ...(faker.random.boolean()
    ? {
        otherAddresses: getRandomArray({ min: 1, max: 5 }).map(() =>
          getProviderAddress(),
        ),
      }
    : null),
});

export const getRelativeAddressesResponse = (): RelativeAddressesResponse => {
  return {
    data: getRelativeAddresses(),
  };
};
