import { FieldValidationErrorCode } from '@kaa/api/providers';
import { ValueOf } from '@kaa/common/types';
import { ValidationError } from '@kaa/common/validation';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import i18nKeysEn from '@kaa/i18n/providers/locales/en.json';

export const FORM_ERROR_BY_CODE = ({
  DEFAULT: {
    id: i18nKeys.errors.server.form.DEFAULT,
    defaultMessage: 'Unexpected error',
  },
  ...Object.values(FieldValidationErrorCode).reduce(
    (acc, key) => ({
      ...acc,
      [FieldValidationErrorCode[key]]: {
        id:
          i18nKeys.errors.server.form[
            key as keyof typeof i18nKeys.errors.server.form
          ],
        defaultMessage:
          i18nKeysEn[`errors.server.form.${key}` as keyof typeof i18nKeysEn],
      },
    }),
    {},
  ),
  [FieldValidationErrorCode.MINIMUM_AGE_NOT_REACHED]: {
    id: i18nKeys.errors.server.form.MINIMUMAGENOTREACHED,
    defaultMessage: i18nKeysEn[`errors.server.form.MINIMUMAGENOTREACHED`],
  },
} as any) as {
  [key in ValueOf<typeof FieldValidationErrorCode>]: ValidationError;
} & {
  DEFAULT: ValidationError;
};
