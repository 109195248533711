import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  ProfileProviderChangeEmailFormState,
  ProfileProviderChangePasswordFormState,
} from './ProfileScreen.types';

export const ProfileScreenChangePasswordFieldNames = {
  CONFIRM_NEW_PASSWORD: 'confirmNewPassword' as keyof ProfileProviderChangePasswordFormState,
  NEW_PASSWORD: 'newPassword' as keyof ProfileProviderChangePasswordFormState,
  OLD_PASSWORD: 'oldPassword' as keyof ProfileProviderChangePasswordFormState,
};

export const ProfileScreenFieldNames = {
  NEW_COMMERCIAL_EMAIL_ADDRESS: 'newCommercialEmailAddress' as keyof ProfileProviderChangeEmailFormState,
  CONFIRM_NEW_COMMERCIAL_EMAIL_ADDRESS: 'confirmNewCommercialEmailAddress' as keyof ProfileProviderChangeEmailFormState,
};

export const notPastedError = {
  id: i18nKeys.profile.error.notPasted,
  defaultMessage: 'You are not allowed to paste into this field',
  displayFocussed: true,
};
