import {
  ExportSubsidiesParams,
  FileExtensionReference,
  GetSubsidiesParams,
  PaginationDirectives,
  ProviderType,
  SubsidySummary,
} from '@kaa/api/providers';
import { useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  SwColumn,
  SwContainer,
  SwFetchErrorMessage,
  SwGrid,
  SwModal,
  SwPaginator,
  SwTitle,
} from '@kaa/ui-flanders/components';
import { FormikActions } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DownloadButtons } from '../../../components';
import { Modals } from '../../../constants';
import { Routes } from '../../../routes';
import { omit, useApi, useSelectedProviderState } from '../../../utils';
import {
  openModalWithPageView,
  sendSearchToGTM,
} from '../../../utils/google-analytics';
import { RemittancesSubsidiesDetailModal } from './components/detail-modal/RemittancesSubsidiesDetailModal';
import { RemittancesSubsidiesDownloadModal } from './components/download-modal/RemittancesSubsidiesDownloadModal';
import { RemittancesSubsidiesForm } from './components/RemittancesSubsidiesForm';
import { RemittancesSubsidiesTable } from './components/RemittancesSubsidiesTable';
import {
  NOW_DATE,
  PAGE_SIZE,
  RemittancesSubsidiesFormFieldNames,
  RemittancesSubsidiesFormType,
} from './RemittancesSubsidies.constants';
import { getFormatedSubsidiesParams } from './RemittancesSubsidies.utils';

export const RemittancesSubsidies = () => {
  const { t } = useTranslation();
  const { providers } = useApi();
  const provider = useSelectedProviderState();

  const isSubsidiary = provider.type === ProviderType.SUBSIDIARY;

  const initialParameters = {
    // todo check if we can use constants for the keys here
    onAllRelatives: !isSubsidiary,
    serviceMonthsPeriod: [NOW_DATE.startOf('month').toISODate()],
    fromPaymentDate: NOW_DATE.minus({ months: 3 }).toISODate(),
    toPaymentDate: NOW_DATE.toISODate(),
    pageSize: PAGE_SIZE,
    pageNumber: 1,
  };

  const [parameters, setParameters] = useState<GetSubsidiesParams>(
    initialParameters,
  );

  const [pagination, setPagination] = useState<
    PaginationDirectives['paginationDirectives']
  >();

  const [subsidies, setSubsidies] = useState<SubsidySummary[]>([]);
  const [currentSubsidy, setCurrentSubsidy] = useState<SubsidySummary>();
  const [currentRelativeId, setCurrentRelativeId] = useState<
    string | number | undefined
  >(undefined);

  const [
    {
      value: subsidiesResponse,
      loading: subsidiesLoading,
      error: subsidiesError,
    },
    getSubsidies,
  ] = useAsyncCallback(
    async (relativeId: number, subsidiesParams: RemittancesSubsidiesFormType) =>
      (
        await providers.getSubsidies(
          relativeId,
          getFormatedSubsidiesParams<GetSubsidiesParams>(
            omit(
              subsidiesParams,
              RemittancesSubsidiesFormFieldNames.SERVICE_MONTH_PERIOD,
            ),
          ),
        )
      ).data,
    [providers],
    { loading: true },
  );

  const [
    {
      value: relativesResponse,
      loading: relativesloading,
      error: relativesError,
    },
    getRelatives,
  ] = useAsyncCallback(
    async () => {
      const {
        data: {
          data: { headquarter, subsidiaries },
        },
      } = await providers.getProviderRelatives(provider.id);

      return [headquarter, ...subsidiaries.filter(({ isActive }) => isActive)];
    },
    [providers],
    { loading: true },
  );

  const [, submitSearch] = useAsyncCallback(
    async (
      formikData: RemittancesSubsidiesFormType,
      formikActions: FormikActions<RemittancesSubsidiesFormType>,
    ) => {
      const { setSubmitting, resetForm } = formikActions;
      const { relativeId, ...subsidiesParams } = formikData;

      setCurrentRelativeId(() => relativeId);

      const updateParameters = {
        ...subsidiesParams,
        onAllRelatives: !isSubsidiary && !relativeId,
        pageSize: PAGE_SIZE,
        pageNumber: 1,
      };

      setParameters(() => updateParameters);

      setSubmitting(false);

      resetForm(formikData);

      sendSearchToGTM(
        Routes.REMITTANCES_REIMBURSEMENT,
        JSON.stringify(updateParameters),
      );

      return {
        formikActions,
        subsidiesParams,
      };
    },
    [setParameters],
  );

  useEffect(() => {
    getRelatives();
  }, [getRelatives]);

  useEffect(() => {
    getSubsidies(Number(currentRelativeId || provider.id), parameters);
  }, [getSubsidies, parameters]);

  useEffect(() => {
    if (subsidiesResponse) {
      const { paginationDirectives, data } = subsidiesResponse;

      setSubsidies(data);
      setPagination(paginationDirectives);
    }
  }, [subsidiesResponse]);

  if (
    (subsidiesLoading && !subsidiesResponse) ||
    (relativesloading && !relativesResponse)
  ) {
    return (
      <SwColumn>
        <SwContainer loading />
      </SwColumn>
    );
  }

  if (
    relativesError ||
    subsidiesError ||
    !relativesResponse ||
    !subsidiesResponse
  ) {
    return (
      <SwContainer error>
        <SwFetchErrorMessage
          onClick={() => {
            getSubsidies(Number(currentRelativeId || provider.id), parameters);
            getRelatives();
          }}
        />
      </SwContainer>
    );
  }

  const resetSearch = (
    resetForm: (nextValues?: RemittancesSubsidiesFormType | undefined) => void,
  ) => {
    setParameters(() => initialParameters);
    setCurrentRelativeId(() => undefined);

    const {
      serviceMonthsPeriod,
      fromPaymentDate,
      toPaymentDate,
    } = initialParameters;

    resetForm({
      [RemittancesSubsidiesFormFieldNames.RELATIVE_ID]: '',
      [RemittancesSubsidiesFormFieldNames.SERVICE_MONTHS_PERIOD]: serviceMonthsPeriod,
      [RemittancesSubsidiesFormFieldNames.START_DATE]: fromPaymentDate,
      [RemittancesSubsidiesFormFieldNames.END_DATE]: toPaymentDate,
    } as RemittancesSubsidiesFormType);
  };

  const openRemittanceModal = (
    selectedSubsidy: SubsidySummary,
    modalId: Modals,
  ) => {
    setCurrentSubsidy(selectedSubsidy);
    openModalWithPageView(modalId);
  };

  const downloadExtensions = [
    FileExtensionReference.XLSX,
    FileExtensionReference.CSV,
  ];

  return (
    <SwColumn width="12" widthS="12">
      <SwTitle tagName="h2">
        {t(i18nKeys.remittances.reimbursement.search.title)}
      </SwTitle>
      <SwGrid>
        <SwColumn>
          <RemittancesSubsidiesForm
            isSubsidiary={isSubsidiary}
            relatives={relativesResponse}
            submit={submitSearch}
            reset={resetSearch}
            parameters={parameters}
            relativeId={currentRelativeId}
          />
        </SwColumn>
        <SwColumn>
          <RemittancesSubsidiesTable
            relatives={relativesResponse}
            subsidies={subsidies}
            parameters={parameters}
            setParameters={setParameters}
            openRemittanceModal={openRemittanceModal}
          />
          {!!subsidies.length && (
            <div className="vl-u-display-flex">
              <DownloadButtons
                modalId={Modals.REMITTANCES_REIMBURSEMENT_DOWNLOAD_MODAL}
                extensions={downloadExtensions}
              />
              {!!pagination && (
                <div style={{ marginLeft: 'auto' }}>
                  <SwPaginator
                    itemCount={pagination.numberOfItems}
                    pageSize={PAGE_SIZE}
                    selectedPage={pagination.pageNumber}
                    setPage={(page) => {
                      setParameters((currentParameters) => ({
                        ...currentParameters,
                        pageNumber:
                          typeof page === 'number'
                            ? page
                            : page(parameters?.pageNumber || 1),
                      }));
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </SwColumn>
      </SwGrid>
      {!!currentSubsidy && (
        <SwModal
          id={Modals.REMITTANCES_REIMBURSEMENT_DETAIL_MODAL}
          component={RemittancesSubsidiesDetailModal}
          confirmCloseMessage={t(i18nKeys.dialog.confirmClose.message)}
          relatives={relativesResponse}
          subsidySummary={currentSubsidy}
          openRemittanceModal={openRemittanceModal}
          modFullScreen
          closable
        />
      )}
      {!!subsidies.length && (
        <>
          {downloadExtensions.map((fileExtension: FileExtensionReference) => (
            <SwModal
              key={`${Modals.REMITTANCES_REIMBURSEMENT_DOWNLOAD_MODAL}-${fileExtension}`}
              id={`${Modals.REMITTANCES_REIMBURSEMENT_DOWNLOAD_MODAL}-${fileExtension}`}
              component={RemittancesSubsidiesDownloadModal}
              providerId={Number(currentRelativeId || provider.id)}
              parameters={{
                ...getFormatedSubsidiesParams<ExportSubsidiesParams>(
                  omit(parameters, 'sort', 'pageNumber', 'pageSize'),
                ),
                fileExtension,
              }}
              closable
            />
          ))}
        </>
      )}
    </SwColumn>
  );
};
