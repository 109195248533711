import {
  ProviderActionType,
  ProviderType,
  ProviderUpdate,
  RegionCode,
} from '@kaa/api/providers';
import { httpTo } from '@kaa/api/providers/utilities';
import { ActionType, useActionDispatch } from '@kaa/common/context';
import { ActionsHandler } from '@kaa/common/handlers';
import { useAsyncCallback } from '@kaa/common/utils';
import { email, maxLength, required, website } from '@kaa/common/validation';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AlertType,
  getDefaultAddressValidation,
  Icon,
  SwActionGroup,
  SwButton,
  SwColumn,
  SwContainer,
  SwFetchErrorMessage,
  SwForm,
  SwFormSubmitMessage,
  SwGrid,
  SwModal,
  SwTitle,
} from '@kaa/ui-flanders/components';
import { RouteComponentProps } from '@reach/router';
import { Formik, FormikActions } from 'formik';
import get from 'lodash.get';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getConfig } from '../../../../../common/config';
import { ContainerLoadingPageHeader } from '../../../../components';
import { Modals, REGION_EMAIL } from '../../../../constants';
import { dataTest } from '../../../../datatest/keys';
import { getPath, Routes } from '../../../../routes';
import { useApi } from '../../../../utils';
import {
  EventAction,
  EventCategory,
  EventLabel,
  openModalWithPageView,
  sendCustomInteractionToGTM,
} from '../../../../utils/google-analytics';
import {
  createValidatorWithServerErrorHandled,
  handleApiError,
} from '../../../../utils/validation';
import { RelativeDetailsBankAccountForm } from './components/RelativeDetailsBankAccountForm';
import { RelativeDetailsCommunicationForm } from './components/RelativeDetailsCommunicationForm';
import { RelativeDetailsForm } from './components/RelativeDetailsForm';
import { ConfirmDisableRelativeModal } from './components/relativeDisableModal/RelativeDisableModal';

const getI18nKeyForType = (type: ProviderType) =>
  type === ProviderType.HEADQUARTER
    ? i18nKeys.relativeType.headquarter
    : i18nKeys.relativeType.subsidiary;

const defaultAddressValidation = getDefaultAddressValidation('address');

type RelativeDetailsScreenProps = {
  enterpriseId: number | string;
};

export const RelativeDetailsScreen: React.FC<RouteComponentProps<
  RelativeDetailsScreenProps
>> = ({ navigate, enterpriseId }) => {
  const { t } = useTranslation();
  const dispatchAction = useActionDispatch();
  const { providers } = useApi();
  const regionCode = get(getConfig(), 'buildConfig.regionCode') as RegionCode;

  const [
    { value: relativeDetailsData, loading, error },
    getRelativeDetailsData,
  ] = useAsyncCallback(
    async () => {
      if (!enterpriseId) {
        return;
      }

      const [locations, provider] = await Promise.all([
        providers.getGlobalLocations(),
        providers.getProvider(Number(enterpriseId)),
      ]);

      const { actions } = provider.data;

      if (actions) {
        dispatchAction({ type: ActionType.ADD, payload: actions });
      }

      return {
        cities: locations.data.data.cities,
        provider: provider.data.data,
      };
    },
    [enterpriseId, providers],
    { loading: true },
  );

  useEffect(() => {
    getRelativeDetailsData();
  }, [getRelativeDetailsData]);

  const [, getStreets] = useAsyncCallback(
    async (postcode: string, query: string) => {
      try {
        const {
          data: { data },
        } = await providers.getLocationsSuggestions({
          postcode,
          keyword: query,
        });
        return data;
      } catch (e) {
        return [];
      }
    },
    [providers],
  );

  const [{ value: submitedForm }, submit] = useAsyncCallback(
    async (
      formikData: ProviderUpdate,
      formikActions: FormikActions<ProviderUpdate>,
    ) => {
      if (!enterpriseId) {
        return;
      }

      const [error, response] = await httpTo(
        providers.updateProvider(Number(enterpriseId), formikData),
      );

      return {
        error,
        response,
        formikData,
        formikActions,
      };
    },
    [enterpriseId, providers],
  );

  useEffect(() => {
    if (submitedForm) {
      const { error, formikData, formikActions } = submitedForm;
      const { setSubmitting, setStatus, resetForm } = formikActions;
      if (handleApiError(error, formikActions)) {
        return;
      }

      resetForm(formikData);
      setStatus({
        type: AlertType.SUCCESS,
        msg: t(
          i18nKeys.headquarterAndAffiliateManagement.updateProvider
            .successMessage,
        ),
      });
      setSubmitting(false);

      sendCustomInteractionToGTM(
        EventCategory.COMPANY_MANAGEMENT,
        EventAction.MODIFY,
        provider.type,
      );
    }
  }, [submitedForm]);

  if (loading) {
    return (
      <ContainerLoadingPageHeader
        title={t(i18nKeys.headquarterAndAffiliateManagement.detailsTitle, {
          name: '',
        })}
      />
    );
  }

  if (!enterpriseId || error || !relativeDetailsData) {
    return (
      <SwContainer error>
        <SwFetchErrorMessage onClick={getRelativeDetailsData} />
      </SwContainer>
    );
  }

  const { cities, provider } = relativeDetailsData;

  const handleConfirmDisable = () => {
    if (navigate) {
      navigate(getPath(Routes.ENTERPRISE));
    }
  };

  const validate = createValidatorWithServerErrorHandled({
    name: [maxLength(40), required],
    commercialName: [maxLength(100), required],
    ...defaultAddressValidation,
    phoneNumber: [maxLength(20)],
    mobilePhoneNumber: [maxLength(20)],
    commercialEmailAddress: [email, maxLength(256)],
    website: [website, maxLength(60)],
  });

  return (
    <SwContainer>
      <SwGrid modStacked>
        <SwColumn width="10" widthS="12">
          <SwTitle
            tagName="h1"
            data-testid={dataTest.relatives.details.titleDetailRelatives}
            className="vl-u-spacer--medium"
          >
            {t(i18nKeys.headquarterAndAffiliateManagement.detailsTitle, {
              name: provider.name,
            })}
          </SwTitle>
          <Formik<ProviderUpdate>
            onSubmit={submit}
            initialValues={provider}
            validate={validate}
          >
            {({ handleSubmit, isSubmitting, dirty }) => (
              <ActionsHandler
                resourceId={provider.resourceId}
                actions={[ProviderActionType.CAN_EDIT]}
              >
                {({ isAllowed: canEdit }) => (
                  <SwForm>
                    <SwGrid modStacked>
                      <SwColumn>
                        <SwTitle tagName="h2">
                          {t(
                            i18nKeys.headquarterAndAffiliateManagement
                              .formDetailsTitle,
                            {
                              type: t(getI18nKeyForType(provider.type)),
                            },
                          )}
                        </SwTitle>
                        <RelativeDetailsForm
                          resourceId={provider.resourceId}
                          isHeadquarter={
                            provider.type === ProviderType.HEADQUARTER
                          }
                          availableCities={cities || []}
                          getStreets={getStreets}
                          modDisabled={!canEdit}
                        />
                      </SwColumn>
                      <ActionsHandler
                        resourceId={provider.resourceId}
                        actions={[
                          ProviderActionType.CAN_REQUEST_UNEDITABLE_CHANGES,
                        ]}
                      >
                        <SwActionGroup modCollapseM>
                          <SwButton
                            data-testid={
                              dataTest.relatives.details.requestChangeButton
                            }
                            className="vl-u-spacer--xsmall"
                            type="button"
                            tagName="a"
                            modLarge
                            modSecondary
                            modIconBefore
                            icon={Icon.ENVELOPE}
                            href={`mailto:${t(REGION_EMAIL[regionCode])}`}
                            target="_blank"
                            onClick={() => {
                              sendCustomInteractionToGTM(
                                EventCategory.COMPANY_MANAGEMENT,
                                EventAction.CLICK_EMAIL,
                                EventLabel.REQUEST_CHANGE,
                              );
                            }}
                          >
                            {t(
                              i18nKeys.headquarterAndAffiliateManagement.form
                                .cta.request,
                            )}
                          </SwButton>
                        </SwActionGroup>
                      </ActionsHandler>
                      <SwColumn>
                        <SwTitle tagName="h2">
                          {t(
                            i18nKeys.headquarterAndAffiliateManagement
                              .formCommunicationTitle,
                            {
                              type: t(getI18nKeyForType(provider.type)),
                            },
                          )}
                        </SwTitle>
                        <RelativeDetailsCommunicationForm
                          modDisabled={!canEdit}
                        />
                      </SwColumn>
                      <SwColumn>
                        <SwTitle tagName="h2">
                          {t(
                            i18nKeys.headquarterAndAffiliateManagement
                              .formMoreDetailsTitle,
                            {
                              type: t(getI18nKeyForType(provider.type)),
                            },
                          )}
                        </SwTitle>
                        <RelativeDetailsBankAccountForm
                          modDisabled={!canEdit}
                        />
                      </SwColumn>
                      <SwActionGroup modCollapseM>
                        {canEdit && (
                          <>
                            <SwButton
                              data-testid={
                                dataTest.relatives.details.editDetailsButton
                              }
                              className="vl-u-spacer--xsmall"
                              type="button"
                              modLoading={isSubmitting}
                              modLarge
                              modDisabled={!dirty}
                              onClick={() => handleSubmit()}
                            >
                              {t(i18nKeys.general.cta.save)}
                            </SwButton>
                          </>
                        )}
                        <ActionsHandler
                          resourceId={provider.resourceId}
                          actions={[ProviderActionType.CAN_DISABLE]}
                        >
                          <SwButton
                            data-testid={
                              dataTest.relatives.details.desactivateRealtive
                            }
                            className="vl-u-spacer--xsmall"
                            modLarge
                            modError
                            modSecondary
                            type="button"
                            onClick={() => {
                              openModalWithPageView(
                                Modals.DISABLE_RELATIVE_MODAL_ID,
                              );
                            }}
                          >
                            {t(
                              i18nKeys.headquarterAndAffiliateManagement
                                .deactivateAffiliate,
                            )}
                          </SwButton>
                        </ActionsHandler>
                      </SwActionGroup>
                      <SwFormSubmitMessage />
                    </SwGrid>
                  </SwForm>
                )}
              </ActionsHandler>
            )}
          </Formik>
        </SwColumn>
      </SwGrid>
      <ActionsHandler
        resourceId={provider.resourceId}
        actions={[ProviderActionType.CAN_DISABLE]}
      >
        <SwModal
          id={Modals.DISABLE_RELATIVE_MODAL_ID}
          closable
          component={ConfirmDisableRelativeModal}
          confirmCloseMessage={t(i18nKeys.dialog.confirmClose.message)}
          relative={provider}
          onConfirm={handleConfirmDisable}
        />
      </ActionsHandler>
    </SwContainer>
  );
};
