import { WorkerContractsResponseDataItem } from '@kaa/api/providers';
import { httpTo } from '@kaa/api/providers/utilities';
import { useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  SwActionGroup,
  SwButton,
  SwLink,
  SwTitle,
  toggleModalById,
} from '@kaa/ui-flanders/components';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modals } from '../../../../../constants';
import { useApi } from '../../../../../utils';
import { ContractModalWithContractProps } from './ContractForm.types';

export const DeleteContractModal = ({
  providerId,
  workerId,
  contract,
  onSuccess,
}: ContractModalWithContractProps) => {
  const { t } = useTranslation();
  const { providers } = useApi();

  const { id: contractId } = contract;

  const [{ value: submitedDelete }, submit] = useAsyncCallback(async () => {
    const [error, response] = await httpTo(
      providers.deleteWorkerContract(
        Number(providerId),
        Number(workerId),
        Number(contractId),
      ),
    );
    return { error, response };
  }, [providers, providerId, contract]);

  useEffect(() => {
    if (submitedDelete) {
      toggleModalById(Modals.WORKERS_DELETE_CONTRACT_MODAL);

      onSuccess({
        ...contract,
        id: contractId,
      } as WorkerContractsResponseDataItem);
    }
  }, [submitedDelete]);

  return (
    <>
      <SwTitle tagName="h2">
        {t(i18nKeys.contract.modal.deleteContract.title)}
      </SwTitle>
      <SwActionGroup style={{ marginTop: '3rem' }}>
        <SwButton modLarge onClick={submit}>
          {t(i18nKeys.contract.modal.deleteContract.cta)}
        </SwButton>
        <SwLink
          modLarge
          onClick={() => toggleModalById(Modals.WORKERS_DELETE_CONTRACT_MODAL)}
        >
          {t(i18nKeys.general.cta.cancel)}
        </SwLink>
      </SwActionGroup>
    </>
  );
};
