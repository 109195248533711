/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */

export type VoucherRefusalReason =
  | 'OUTDATED'
  | 'UNRECOGNIZED'
  | 'CANCELLED_BY_CUSTOMER'
  | 'CANCELLED_BY_PROVIDER'
  | 'INCOMPLETE'
  | 'WORK_VCHISSUE_DELAY'
  | 'REGISTERED_WORKER'
  | 'ACTIVITY_APPROVAL'
  | 'COMPANY_APPROVAL'
  | 'WORKER_CONTRACT'
  | 'WORKER_HOURS_LIMIT'
  | 'REMITTANCE_DATE_VOUCHER_ISSUE_DELAY';

export const VoucherRefusalReason = {
  OUTDATED: 'OUTDATED' as VoucherRefusalReason,
  UNRECOGNIZED: 'UNRECOGNIZED' as VoucherRefusalReason,
  CANCELLED_BY_CUSTOMER: 'CANCELLED_BY_CUSTOMER' as VoucherRefusalReason,
  CANCELLED_BY_PROVIDER: 'CANCELLED_BY_PROVIDER' as VoucherRefusalReason,
  INCOMPLETE: 'INCOMPLETE' as VoucherRefusalReason,
  WORK_VCHISSUE_DELAY: 'WORK_VCHISSUE_DELAY' as VoucherRefusalReason,
  REGISTERED_WORKER: 'REGISTERED_WORKER' as VoucherRefusalReason,
  ACTIVITY_APPROVAL: 'ACTIVITY_APPROVAL' as VoucherRefusalReason,
  COMPANY_APPROVAL: 'COMPANY_APPROVAL' as VoucherRefusalReason,
  WORKER_CONTRACT: 'WORKER_CONTRACT' as VoucherRefusalReason,
  WORKER_HOURS_LIMIT: 'WORKER_HOURS_LIMIT' as VoucherRefusalReason,
  REMITTANCE_DATE_VOUCHER_ISSUE_DELAY: 'REMITTANCE_DATE_VOUCHER_ISSUE_DELAY' as VoucherRefusalReason,
};
