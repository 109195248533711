import {
  PaginationDirectivesPaginationDirectives,
  ServicesHistoryResponseDataDataExtra,
} from '@kaa/api/providers';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  getFromIndex,
  getToIndex,
  SwPaginator,
} from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

type PrestationsPaginationProps = {
  pagination: PaginationDirectivesPaginationDirectives;
  extra: ServicesHistoryResponseDataDataExtra;
  setPage: React.Dispatch<React.SetStateAction<number>>;
};

export const PrestationsPagination = ({
  pagination,
  extra,
  setPage,
}: PrestationsPaginationProps) => {
  const { t } = useTranslation();

  const { pageNumber, numberOfItems, pageSize } = pagination;
  const { durationInHours, totalDurationInHours } = extra;

  const fromItemIndex = getFromIndex(pageNumber, pageSize);
  const toItemIndex = getToIndex(pageNumber, pageSize, numberOfItems);

  return (
    <div className="vl-u-display-flex">
      <ul>
        <li>
          {t(i18nKeys.general.label.numberOfServices)}:{' '}
          <strong>
            {fromItemIndex} - {toItemIndex}
          </strong>{' '}
          / {numberOfItems}
        </li>
        <li>
          {t(i18nKeys.general.label.numberOfHours)}:{' '}
          <strong>{durationInHours}</strong> / {totalDurationInHours}
        </li>
      </ul>
      <div style={{ marginLeft: 'auto' }}>
        <SwPaginator
          itemCount={numberOfItems}
          pageSize={pageSize}
          selectedPage={pageNumber}
          setPage={setPage}
        />
      </div>
    </div>
  );
};
