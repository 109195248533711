import { Service, ServiceStatus } from '@kaa/api/providers';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AlertType,
  getAlertPropsByType,
  SwBadge,
} from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

type PrestationsHistoryTableRowStatusProps = {
  service: Service;
};

export const PrestationsHistoryTableRowStatus = ({
  service,
}: PrestationsHistoryTableRowStatusProps) => {
  const { t } = useTranslation();

  const { status } = service;

  const badgeBase = {
    modMedium: true,
    style: {
      verticalAlign: 'middle',
      minWidth: '2.75rem',
      width: '2.75rem',
      height: '2.75rem',
      marginRight: '.5rem',
    },
  };

  const badgeSuccess = {
    ...badgeBase,
    ...getAlertPropsByType(AlertType.SUCCESS),
  };

  const badgeWarning = {
    ...badgeBase,
    ...getAlertPropsByType(AlertType.WARNING),
  };

  const badgeError = {
    ...badgeBase,
    ...getAlertPropsByType(AlertType.ERROR),
  };

  const badgeInformation = {
    ...badgeBase,
    ...getAlertPropsByType(AlertType.INFORMATION),
    modAction: true,
  };

  switch (status) {
    case ServiceStatus.PAID: // Active
    case ServiceStatus.REFUND_REQUESTED: // Inactive
      return (
        <td>
          <SwBadge {...badgeSuccess} />
          {t(i18nKeys.general.serviceStatus[status])}
        </td>
      );
    case ServiceStatus.MODIFIED: // Active
    case ServiceStatus.CORRECTED: // Active
    case ServiceStatus.CONFIRMED: // Active
    case ServiceStatus.MISSING_VOUCHERS: // Active
    case ServiceStatus.PARTIALLY_REFUNDED: // Active
    case ServiceStatus.MISSING_CONTRACT: // Active
    case ServiceStatus.TO_BE_PAID_OUTSIDE_SV: // Inactive
    case ServiceStatus.PARTIALLY_ASSIGNED_TO_BE_PAID_OUTSIDE_SV: // Active
    case ServiceStatus.PARTIALLY_REFUNDED_TO_BE_PAID_OUTSIDE_SV: // Inactive
      return (
        <td>
          <SwBadge {...badgeWarning} />
          {t(i18nKeys.general.serviceStatus[status])}
        </td>
      );
    case ServiceStatus.CREATED: // Active
    case ServiceStatus.VALIDATED: // Active
    case ServiceStatus.READY_FOR_VOUCHER_ASSIGNMENT: // Active
    case ServiceStatus.PARTIALLY_ASSIGNED: // Active
      return (
        <td>
          <SwBadge {...badgeInformation} />
          {t(i18nKeys.general.serviceStatus[status])}
        </td>
      );
    case ServiceStatus.CONTESTED: // Active
    case ServiceStatus.PROPOSITION_TO_CANCEL: // Active
    case ServiceStatus.CANCELED: // Inactive
      return (
        <td>
          <SwBadge {...badgeError} />
          {t(i18nKeys.general.serviceStatus[status])}
        </td>
      );
    default:
      return <td>{t(i18nKeys.general.serviceStatus[status])}</td>;
  }
};
