import faker from 'faker';
import { PaginationDirectives } from '../model';

const PAGE_SIZE = 20;

const getPaginationDirectives = (
  numberOfItems = faker.random.number(),
  pageNumber = 1,
  pageSize = PAGE_SIZE,
): PaginationDirectives['paginationDirectives'] => ({
  pageNumber,
  numberOfPages: Math.ceil(numberOfItems / pageSize),
  numberOfItems,
  pageSize,
  bookmark: String(Date.now()),
});

export const getDataWithPagination = <T>(
  data: T[],
  params?: Partial<PaginationDirectives['paginationDirectives']>,
) => ({
  data: data.slice(0, params?.pageSize || PAGE_SIZE),
  paginationDirectives: getPaginationDirectives(
    data.length,
    params?.pageNumber,
    params?.pageSize || PAGE_SIZE,
  ),
});
