import { PickupWeekFrequency } from '@kaa/api/providers';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import { FieldArray, FieldArrayRenderProps } from 'formik';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PickupCreateFormData,
  PickupFormFieldNames,
  PickupUpdateFormData,
} from '../PickupModals.constants';

type PickupRecurrentModalSelectWeeksProps = {
  values: PickupCreateFormData | PickupUpdateFormData;
};

export const PickupRecurrentModalSelectWeeks = ({
  values,
}: PickupRecurrentModalSelectWeeksProps) => {
  const { t } = useTranslation();

  const isSelected = (frequency: PickupWeekFrequency) =>
    values[PickupFormFieldNames.SELECTED_WEEKS].includes(frequency);

  const onChange = (
    e: ChangeEvent<HTMLInputElement>,
    frequency: PickupWeekFrequency,
    arrayHelpers: FieldArrayRenderProps,
  ) => {
    if (e.target.checked) {
      arrayHelpers.push(frequency);
    } else {
      arrayHelpers.remove(
        values[PickupFormFieldNames.SELECTED_WEEKS].indexOf(frequency),
      );
    }
  };

  return (
    <FieldArray
      name={PickupFormFieldNames.SELECTED_WEEKS}
      render={(arrayHelpers) => (
        <>
          {Object.keys(PickupWeekFrequency).map((key: string) => {
            const frequency = key as PickupWeekFrequency;
            return (
              <div key={frequency} style={{ paddingLeft: '1.5rem' }}>
                <label className="vl-checkbox">
                  <input
                    type="checkbox"
                    className="vl-checkbox__toggle"
                    name={PickupFormFieldNames.SELECTED_WEEKS}
                    value={frequency}
                    checked={isSelected(frequency)}
                    onChange={(e) => onChange(e, frequency, arrayHelpers)}
                  />
                  <div className="vl-checkbox__label">
                    <div className="vl-checkbox__box" aria-hidden="true" />
                    {t(i18nKeys.pickup.modal.add.form.weeks[frequency])}
                  </div>
                </label>
              </div>
            );
          })}
        </>
      )}
    />
  );
};
