import { RegionCode, RemittancesOverviewResponse } from '@kaa/api/providers';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  Icon,
  SwButton,
  SwGrid,
  SwLoader,
  SwModal,
  SwTitle,
} from '@kaa/ui-flanders/components';
import get from 'lodash.get';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getConfig } from '../../../../common/config';
import { Modals } from '../../../constants';
import { openModalWithPageView } from '../../../utils/google-analytics';
import { RemittancesCard } from '../components/RemittancesCard';
import { RemittancesAutomaticModal } from './components/automatic/RemittancesAutomaticModal';
import { RemittancesManualModal } from './components/manual/RemittancesManualModal';

type RemittancesElectronicProps = {
  overview: RemittancesOverviewResponse['data'];
  overviewLoading: boolean;
  getOverview: () => void;
};

export const RemittancesElectronic = ({
  overview,
  overviewLoading,
  getOverview,
}: RemittancesElectronicProps) => {
  const { t } = useTranslation();
  const regionCode = get(getConfig(), 'buildConfig.regionCode') as RegionCode;

  const { assignedVouchers = 0 } = overview;

  return (
    <>
      <SwTitle tagName="h2" className="vl-u-spacer--medium">
        {t(i18nKeys.general.supportType.ELECTRONIC)}
      </SwTitle>
      <SwGrid
        modVStretched
        style={{ width: '100%', justifyContent: 'flex-start' }}
      >
        <RemittancesCard
          title={t(i18nKeys.remittances.cards.automatic.title)}
          text={t(i18nKeys.remittances.cards.automatic.description)}
          icon={
            regionCode === RegionCode.BE_VLG ? Icon.SYNCHRONISE : Icon.AUTOFILE
          }
        >
          <SwButton
            modBlock
            modNarrow
            onClick={() => {
              openModalWithPageView(Modals.REMITTANCES_AUTOMATIC_MODAL_ID);
            }}
          >
            {t(i18nKeys.remittances.cards.automatic.cta)}
          </SwButton>
        </RemittancesCard>
        <RemittancesCard
          title={t(i18nKeys.remittances.cards.manual.title)}
          text={
            !overviewLoading ? (
              t(i18nKeys.remittances.cards.manual.description, {
                count: assignedVouchers,
              })
            ) : (
              <SwLoader />
            )
          }
          icon={Icon.FILE_EDIT}
        >
          {!overviewLoading && (
            <SwButton
              modBlock
              modNarrow
              onClick={() => {
                openModalWithPageView(Modals.REMITTANCES_MANUAL_MODAL_ID);
              }}
            >
              {t(i18nKeys.remittances.cards.manual.cta)}
            </SwButton>
          )}
        </RemittancesCard>
      </SwGrid>
      <SwModal
        id={Modals.REMITTANCES_AUTOMATIC_MODAL_ID}
        component={RemittancesAutomaticModal}
        confirmCloseMessage={t(i18nKeys.dialog.confirmClose.message)}
        modLarge
        closable
      />
      <SwModal
        id={Modals.REMITTANCES_MANUAL_MODAL_ID}
        component={RemittancesManualModal}
        confirmCloseMessage={t(i18nKeys.dialog.confirmClose.message)}
        onSuccess={getOverview}
        modMedium
        closable
      />
    </>
  );
};
