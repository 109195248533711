import { City, Street } from '@kaa/api/providers';
import {
  SwFormAddress,
  SwFormGrid,
  SwInputTextField,
} from '@kaa/ui-flanders/components';
import { Field } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import { RelativeAddModalfieldNames } from '../RelativeAddModal.constants';

type RelativeAddStepInformationProps = {
  availableCities: City[];
  getStreets: (
    postcode: string,
    query: string,
  ) => Promise<[any, Street[] | undefined]>;
};

export const RelativeAddStepInformation = ({
  availableCities,
  getStreets,
}: RelativeAddStepInformationProps) => {
  const { t } = useTranslation();

  return (
    <>
      <p className="vl-u-spacer">{t(i18nKeys.relative.add.introduction)}</p>

      <fieldset>
        <SwFormGrid modStacked>
          <Field
            column={{ width: '12' }}
            labelColumn={{ width: '12' }}
            name={RelativeAddModalfieldNames.NAME}
            label={t(i18nKeys.general.label.name, {
              type: t(i18nKeys.relativeType.subsidiary),
            })}
            component={SwInputTextField}
          />

          <Field
            column={{ width: '12' }}
            labelColumn={{ width: '12' }}
            name={RelativeAddModalfieldNames.COMMERCIAL_NAME}
            label={t(i18nKeys.general.label.commercialName)}
            component={SwInputTextField}
          />

          <Field
            column={{ width: '12' }}
            labelColumn={{ width: '12' }}
            name={RelativeAddModalfieldNames.VAT_NUMBER}
            label={t(i18nKeys.general.label.bceNumber)}
            component={SwInputTextField}
            modDisabled
          />

          <SwFormAddress
            column={{ width: '12' }}
            labelColumn={{ width: '12' }}
            fieldNamePrefix={RelativeAddModalfieldNames.ADDRESS}
            availableCities={availableCities}
            getStreets={getStreets}
          />
        </SwFormGrid>
      </fieldset>
    </>
  );
};
