import { PaymentMode, RegionCode } from '@kaa/api/providers';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  Icon,
  SwAlert,
  SwButton,
  SwColumn,
  SwGrid,
  SwTitle,
} from '@kaa/ui-flanders/components';
import get from 'lodash.get';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getConfig } from '../../../../common/config';
import {
  EventAction,
  EventCategory,
  EventLabel,
  sendCustomInteractionToGTM,
} from '../../../utils/google-analytics';

type PickupBillingBankProps = {
  paymentMode: PaymentMode;
};

export const PickupBillingBank = ({ paymentMode }: PickupBillingBankProps) => {
  const { t } = useTranslation();

  const regionCode = get(getConfig(), 'buildConfig.regionCode') as RegionCode;

  const isDomiciliation = paymentMode === PaymentMode.DIRECT_DEBIT;

  const state = isDomiciliation
    ? t(i18nKeys.remittances.general.yes)
    : t(i18nKeys.remittances.general.no);

  return (
    <SwGrid
      modStacked
      style={{
        width: '100%',
        justifyContent: 'flex-start',
      }}
    >
      <SwColumn width="10" widthS="12">
        <SwTitle tagName="h2">{t(i18nKeys.pickup.billing.bank.title)}</SwTitle>
      </SwColumn>
      <SwColumn width="10" widthS="12">
        <SwAlert
          icon={Icon.INFO_CIRCLE}
          title={t(i18nKeys.pickup.billing.bank.domiciliation, {
            state,
          })}
          actions={
            <SwButton
              to={t(i18nKeys.pickup.billing.bank.url[regionCode])}
              target="_blank"
              onClick={() => {
                sendCustomInteractionToGTM(
                  EventCategory.PICKUP_BILLING,
                  EventAction.CLICK,
                  EventLabel.PICKUP_BILLING_REQUEST_DOMICILIATION,
                );
              }}
            >
              {t(i18nKeys.pickup.billing.bank.cta)}
            </SwButton>
          }
        >
          {t(i18nKeys.pickup.billing.bank.introduction)}
        </SwAlert>
      </SwColumn>
    </SwGrid>
  );
};
