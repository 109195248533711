/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */

export type ProvinceCode =
  | 'BE-VAN'
  | 'BE-WBR'
  | 'BE-WHT'
  | 'BE-WLG'
  | 'BE-VLI'
  | 'BE-WLX'
  | 'BE-WNA'
  | 'BE-VOV'
  | 'BE-VBR'
  | 'BE-VWV';

export const ProvinceCode = {
  BEVAN: 'BE-VAN' as ProvinceCode,
  BEWBR: 'BE-WBR' as ProvinceCode,
  BEWHT: 'BE-WHT' as ProvinceCode,
  BEWLG: 'BE-WLG' as ProvinceCode,
  BEVLI: 'BE-VLI' as ProvinceCode,
  BEWLX: 'BE-WLX' as ProvinceCode,
  BEWNA: 'BE-WNA' as ProvinceCode,
  BEVOV: 'BE-VOV' as ProvinceCode,
  BEVBR: 'BE-VBR' as ProvinceCode,
  BEVWV: 'BE-VWV' as ProvinceCode,
};
