import {
  AlertAction,
  useAlertDispatch,
  useAlertState,
} from '@kaa/common/context';
import {
  getAlertPropsByType,
  SwAlert,
  SwAlertProps,
  SwColumn,
} from '@kaa/ui-flanders/components';
import React, { useEffect } from 'react';

export const AlertManager = () => {
  const alerts = useAlertState<SwAlertProps>();
  const dispathAlert = useAlertDispatch<SwAlertProps>();

  useEffect(() => {
    return () => {
      dispathAlert({ type: AlertAction.CLEAR });
    };
  }, [dispathAlert]);

  return (
    <>
      {alerts.map(({ id, message, type, ...props }, index) => {
        const alertProps = getAlertPropsByType(type);
        return (
          <SwColumn key={index}>
            <SwAlert
              close={() =>
                dispathAlert({ type: AlertAction.DELETE, payload: id })
              }
              {...alertProps}
              {...props}
            >
              {message}
            </SwAlert>
          </SwColumn>
        );
      })}
    </>
  );
};
