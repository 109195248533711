import { ContactPerson } from '@kaa/api/providers';
import { dynamicDataTest } from '@kaa/common/utils/dataTest';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  Icon,
  SwColumn,
  SwGrid,
  SwLink,
  SwTitle,
} from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { dataTest } from '../../../../datatest/keys';
import { getPath, Routes } from '../../../../routes';

type ContactPersonTeaserProps = {
  enterpriseId: string;
  contactPerson: ContactPerson;
  spacer?: string;
};
export const ContactPersonTeaser = ({
  enterpriseId,
  contactPerson,
  spacer,
}: ContactPersonTeaserProps) => {
  const { t } = useTranslation();

  const { id, firstName, lastName, emailAddress, phoneNumber } = contactPerson;

  return (
    <SwGrid className={spacer}>
      <SwColumn width="4" widthS="8">
        <SwTitle tagName="h5" modSans>
          {`${firstName} ${lastName}`}
        </SwTitle>
      </SwColumn>

      <SwColumn width="8" widthS="4">
        <SwLink
          data-testid={dynamicDataTest(
            dataTest.contactPersons.detailsContactPerson,
            { id },
          )}
          icon={Icon.ARROW_RIGHT}
          modIconAfter
          to={getPath(Routes.ENTERPRISE_DETAILS_CONTACT_PERSON_DETAILS, {
            params: {
              enterpriseId,
              contactId: id,
            },
          })}
        >
          {t(i18nKeys.general.cta.detail)}
        </SwLink>
      </SwColumn>

      <SwColumn width="12">
        <p>{emailAddress}</p>
      </SwColumn>

      <SwColumn width="12">
        <p>{phoneNumber}</p>
      </SwColumn>
    </SwGrid>
  );
};
