import React, { AllHTMLAttributes } from 'react';
import { SwFormColumn, SwFormGrid } from '../../../core';
import { Column } from '../../../core/SwColumn';
import { SwFormInput } from '../../../form-input/SwFormInput';
import { SwFormMessageLabel } from '../../../form-message/SwFormMessageLabel';
import { SwUpload } from '../../../upload/SwUpload';
import { FieldPropsClean } from '../../../utilities';
import { SwErrorMessageForField } from '../error-message-for-field/SwErrorMessageForField';

type SwUploadFieldProps = AllHTMLAttributes<HTMLDivElement> &
  FieldPropsClean & {
    type: string;
    label?: string;
    column?: Column;
    labelColumn?: Column;
    autoUnsetServerError?: boolean;
    modRequired?: boolean;
  };

export const SwUploadField = ({
  field,
  form,
  label = '',
  column = { width: '9', widthS: '12' },
  labelColumn = { width: '3', widthS: '12' },
  autoUnsetServerError = true,
  modRequired,
  ...props
}: SwUploadFieldProps) => {
  return (
    <SwFormColumn>
      <SwFormGrid>
        <SwFormColumn {...labelColumn}>
          <SwFormMessageLabel modRequired={modRequired} htmlFor={field.name}>
            {label}
          </SwFormMessageLabel>
        </SwFormColumn>
        <SwFormColumn {...column}>
          <SwFormInput>
            <SwUpload
              id={field.name}
              {...props}
              onUpload={(files: File[]) => {
                form.setFieldValue(field.name, files);
              }}
            />
            <SwErrorMessageForField
              field={field}
              form={form}
              autoUnsetServerError={autoUnsetServerError}
            />
          </SwFormInput>
        </SwFormColumn>
      </SwFormGrid>
    </SwFormColumn>
  );
};
