import { getActionsMock, getResourceIdMock } from '@kaa/api/common/mocks';
import faker from 'faker';
import {
  ContactPerson,
  ContactPersonActionType,
  ContactPersonResponse,
  ContactPersonsResponse,
  LanguageCode,
  TitleCode,
} from '../model';
import { RESOURCE_PREFIX_PROVIDER } from './provider';
import { getRandomArray } from './utils';

export const getContactPersonsResponse = (
  { data }: Partial<{ data: Partial<ContactPersonsResponse['data']> }> = {},
  providerId?: string | number,
): ContactPersonsResponse => {
  const contactPersons = getContactPersons(data);
  const actions = contactPersons.otherContacts
    ? contactPersons.otherContacts.reduce(
        (acc, { resourceId }) => ({
          ...acc,
          [resourceId]: getActionsMock(ContactPersonActionType),
        }),
        {},
      )
    : {};
  return {
    data: contactPersons,
    actions: providerId
      ? {
          ...actions,
          [getResourceIdMock(RESOURCE_PREFIX_PROVIDER, providerId)]: {
            CAN_ADD_CONTACT_PERSON:
              contactPersons.otherContacts &&
              contactPersons.otherContacts.length < 4,
          },
        }
      : actions,
  };
};

export const getContactPersons = ({
  mainContact = getContactPerson(),
  otherContacts = getRandomArray({ min: 1, max: 4 }).map(getContactPerson),
}: Partial<
  ContactPersonsResponse['data']
> = {}): ContactPersonsResponse['data'] => {
  return {
    mainContact,
    otherContacts,
  };
};

export const getContactPersonResponse = ({
  data,
  actions,
}: {
  data?: Partial<ContactPerson>;
  actions?: { [key: string]: boolean };
} = {}): ContactPersonResponse => {
  const contactPerson = getContactPerson(data);
  return {
    data: contactPerson,
    actions: {
      [contactPerson.resourceId]:
        actions || getActionsMock(ContactPersonActionType),
    },
  };
};

export const getContactPerson = (
  contactPerson?: Partial<ContactPerson>,
): ContactPerson => {
  const firstName = faker.name.firstName();
  const lastName = faker.name.lastName();
  return {
    id: faker.random.number(999),
    titleCode: faker.helpers.randomize(Object.values(TitleCode)),
    firstName,
    lastName,
    role: faker.lorem.word(),
    language: faker.helpers.randomize(Object.values(LanguageCode)),
    emailAddress: faker.internet.email(firstName, lastName),
    phoneNumber: faker.phone.phoneNumber('0## ## ## ##'),
    mobilePhoneNumber: faker.phone.phoneNumber('04## ## ## ##'),
    resourceId: faker.random.uuid(),
    ...contactPerson,
  };
};
