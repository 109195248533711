/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */
import axios, { AxiosPromise, AxiosInstance } from 'axios';
import {
  Actions,
  ActivateWorkersParams,
  Activity,
  AddressTypeReference,
  AgreementResponse,
  AgreementStatus,
  AnnouncementsWithRegionalAgreement,
  Author,
  BillDetailsResponse,
  BillType,
  BillingConfigurationResponse,
  BillsResponse,
  ContactPerson,
  ContactPersonResponse,
  ContactPersonsResponse,
  CreateRemittancesParams,
  CustomerStatus,
  CustomersResponse,
  DashboardPickupOverviewResponse,
  DashboardRemittancesActionsResponse,
  DashboardRemittancesOverviewResponse,
  DashboardWorkersActionsResponse,
  DashboardWorksActionsResponse,
  DashboardWorksOverviewResponse,
  DeactivateWorkersParams,
  DeleteSinglePickupParams,
  ElectronicVoucherRemittanceConfirmation,
  ElectronicVoucherRemittanceCreationDemand,
  EntityCreatedResponse,
  EntityCreatedResponseWithActions,
  ExportFileServicesParams,
  ExportFileWorkersParams,
  ExportRefusedVouchersRemittanceParams,
  ExportRemittanceWorksParams,
  ExportRemittancesParams,
  ExportServicesParams,
  ExportSubsidiesParams,
  ExportWorkersParams,
  FieldValidationErrorCode,
  FileStatus,
  GenderCode,
  GetCustomersParams,
  GetGlobalLegalFormsParams,
  GetGlobalTitlesParams,
  GetIncorrectVouchersRemittanceParams,
  GetLastRemittancesParams,
  GetListServicesParams,
  GetLocationsSuggestionsParams,
  GetProviderBillsParams,
  GetProviderPickupPlanningParams,
  GetProviderRelativesParams,
  GetRefusedVouchersRemittanceParams,
  GetServicesHistoryParams,
  GetServicesParams,
  GetSinglePickupParams,
  GetSubsidiesParams,
  GetVideosParams,
  GetWorkerServicesHistoryParams,
  GetWorkerServicesParams,
  GetWorkersParams,
  GetWorkersServicesParams,
  LanguageCode,
  LegalFormsResponse,
  ListServicesResponse,
  LocationsResponse,
  MonthPeriodResponse,
  MonthlyPickupResponse,
  NoContentResponse,
  PaperVoucherAnnouncementsFileParserResponse,
  PaperVoucherAnnouncementsResponse,
  PaperVoucherValidityReference,
  PaperVoucherValidityResponse,
  PaperVouchersRemittance,
  PaymentMode,
  PickupConfigurationResponse,
  PickupOccurrenceUpdate,
  PickupResponse,
  PickupStatus,
  PickupType,
  PickupWeekFrequency,
  ProviderRemittanceUpdateConfiguration,
  ProviderResponse,
  ProviderSubsidiary,
  ProviderSubsidyConfigurationResponse,
  ProviderSubsidyConfigurationUpdate,
  ProviderType,
  ProviderUpdate,
  ProviderVisibilityLocations,
  ProviderVisibilityLocationsResponse,
  ProviderVisibilityUpdateAgreements,
  ProvinceCode,
  RecurrentPickupCreate,
  RecurrentPickupUpdate,
  RegionCode,
  ReimbursementRefDateReference,
  RelativeAddressesResponse,
  RelativeResponse,
  RelativesResponse,
  RemittanceDetailIncorrectResponse,
  RemittanceDetailRefusedResponse,
  RemittanceDetailResponse,
  RemittanceFrequency,
  RemittanceStatus,
  RemittancesOverviewResponse,
  RemittancesResponse,
  ResumeOccurrenceParams,
  ServiceActiveStatus,
  ServiceCancel,
  ServiceCreate,
  ServiceInactiveStatus,
  ServiceResponse,
  ServiceUpdate,
  ServiceUpdateResponse,
  ServicesFileStatusResponse,
  ServicesHistoryResponse,
  ServicesOverviewResponse,
  ServicesResponse,
  ServicesValidatedResponse,
  StandalonePickupCreate,
  StreetsResponse,
  SubsidiesResponse,
  SubsidyBeneficiaryBankAccountHolderReference,
  SubsidyDetailResponse,
  SubsidyReceivalModeCode,
  SupportType,
  TitleCode,
  TitlesResponse,
  UserPasswordUpdate,
  ValidateWorkerServicesParams,
  ValidateWorkersServicesParams,
  VideoDescriptionLinkTitle,
  VideoResponseDetails,
  VideoResponseSummary,
  VideoResponseTitles,
  VideoTopicReference,
  VideosResponse,
  VideosStreamIdResponse,
  Voucher,
  VoucherRefusalReason,
  VoucherStatus,
  WeekDays,
  Worker,
  WorkerContract,
  WorkerContractStatus,
  WorkerContractType,
  WorkerContractsResponse,
  WorkerCreateContractResponse,
  WorkerDetailResponse,
  WorkerResetPasswordResponse,
  WorkerServicesResponse,
  WorkerUpdatedResponse,
  WorkersCreatedResponse,
  WorkersFileStatusResponse,
  WorkersOverviewResponse,
  WorkersResponse,
  WorkersServicesResponse,
  WorkingDays,
} from './model';

export interface BFFProvidersApi {
  // Check if server is up and running
  liveness(): AxiosPromise<unknown>;
  // Get all videos
  getVideos(
    params?: GetVideosParams,
    version?: number,
  ): AxiosPromise<VideosResponse>;
  // Get video stream id
  getVideoStreamId(
    videoId: string,
    version?: number,
  ): AxiosPromise<VideosStreamIdResponse>;
  // stream video
  streamVideo(streamId: string, version?: number): AxiosPromise<Blob>;
  // Thumbnail for video
  thumbnailVideo(videoId: string, version?: number): AxiosPromise<Blob>;
  // Get all locations
  getGlobalLocations(version?: number): AxiosPromise<LocationsResponse>;
  // Search streets by keyword and postcode
  getLocationsSuggestions(
    params?: GetLocationsSuggestionsParams,
    version?: number,
  ): AxiosPromise<StreetsResponse>;
  // Get all titles
  getGlobalTitles(
    params?: GetGlobalTitlesParams,
    version?: number,
  ): AxiosPromise<TitlesResponse>;
  // Get all legal forms
  getGlobalLegalForms(
    params?: GetGlobalLegalFormsParams,
    version?: number,
  ): AxiosPromise<LegalFormsResponse>;
  // Get provider active locations
  getProviderVisibilityForLocations(
    providerId: number,
    version?: number,
  ): AxiosPromise<ProviderVisibilityLocationsResponse>;
  // Update provider active locations
  updateProviderVisibilityForLocations(
    providerId: number,
    providerVisibilityLocations: ProviderVisibilityLocations,
    version?: number,
  ): AxiosPromise<NoContentResponse>;
  // Get provider activity agreements
  getProviderVisibilityForAgreements(
    providerId: number,
    version?: number,
  ): AxiosPromise<AgreementResponse>;
  // Update provider activity agreements
  updateProviderVisibilityForAgreements(
    providerId: number,
    providerVisibilityUpdateAgreements: ProviderVisibilityUpdateAgreements,
    version?: number,
  ): AxiosPromise<NoContentResponse>;
  // Get dashboard works overview information
  getDashboardServicesOverview(
    providerId: number,
    version?: number,
  ): AxiosPromise<DashboardWorksOverviewResponse>;
  // Get dashboard remittances overview information
  getDashboardRemittancesOverview(
    providerId: number,
    version?: number,
  ): AxiosPromise<DashboardRemittancesOverviewResponse>;
  // Get dashboard pickups overview information
  getDashboardPickupsOverview(
    providerId: number,
    version?: number,
  ): AxiosPromise<DashboardPickupOverviewResponse>;
  // Get dashboard remittances actions information
  getDashboardRemittancesActions(
    providerId: number,
    version?: number,
  ): AxiosPromise<DashboardRemittancesActionsResponse>;
  // Get dashboard vouchers overview information
  getDashboardServicesActions(
    providerId: number,
    version?: number,
  ): AxiosPromise<DashboardWorksActionsResponse>;
  // Get dashboard workers overview information
  getDashboardWorkersActions(
    providerId: number,
    version?: number,
  ): AxiosPromise<DashboardWorkersActionsResponse>;
  // Create a subsidiary
  createProvider(
    providerSubsidiary: ProviderSubsidiary,
    version?: number,
  ): AxiosPromise<RelativeResponse>;
  // Get provider
  getProvider(
    providerId: number,
    version?: number,
  ): AxiosPromise<ProviderResponse>;
  // Update provider
  updateProvider(
    providerId: number,
    providerUpdate: ProviderUpdate,
    version?: number,
  ): AxiosPromise<NoContentResponse>;
  // Get provider subsidy configuration
  getProviderSubsidyConfiguration(
    providerId: number,
    version?: number,
  ): AxiosPromise<ProviderSubsidyConfigurationResponse>;
  // Update provider subsidy configuration
  updateProviderSubsidyConfiguration(
    providerId: number,
    providerSubsidyConfigurationUpdate: ProviderSubsidyConfigurationUpdate,
    version?: number,
  ): AxiosPromise<NoContentResponse>;
  // Update provider remittance configuration
  updateProviderRemittanceConfiguration(
    providerId: number,
    providerRemittanceUpdateConfiguration: ProviderRemittanceUpdateConfiguration,
    version?: number,
  ): AxiosPromise<NoContentResponse>;
  // Get provider's contact list
  getContactPersons(
    providerId: number,
    version?: number,
  ): AxiosPromise<ContactPersonsResponse>;
  // Add a new provider contact
  addContactPerson(
    providerId: number,
    contactPerson: ContactPerson,
    version?: number,
  ): AxiosPromise<EntityCreatedResponseWithActions>;
  // Get a provider contact
  getContactPerson(
    providerId: number,
    contactPersonId: number,
    version?: number,
  ): AxiosPromise<ContactPersonResponse>;
  // Update a provider contact
  updateContactPerson(
    providerId: number,
    contactPersonId: number,
    contactPerson: ContactPerson,
    version?: number,
  ): AxiosPromise<Actions>;
  // Delete a provider contact
  deleteContactPerson(
    providerId: number,
    contactPersonId: number,
    version?: number,
  ): AxiosPromise<NoContentResponse>;
  // Get provider relatives
  getProviderRelatives(
    providerId: number,
    params?: GetProviderRelativesParams,
    version?: number,
  ): AxiosPromise<RelativesResponse>;
  // Get provider addresses
  getProviderAddresses(
    providerId: number,
    version?: number,
  ): AxiosPromise<RelativeAddressesResponse>;
  // Deactivate a provider
  deactivateProviderMember(
    providerId: number,
    version?: number,
  ): AxiosPromise<RelativeResponse>;
  // Get provider pickup configuration
  getProviderPickupConfiguration(
    providerId: number,
    version?: number,
  ): AxiosPromise<PickupConfigurationResponse>;
  // Get provider pickup planning
  getProviderPickupPlanning(
    providerId: number,
    params?: GetProviderPickupPlanningParams,
    version?: number,
  ): AxiosPromise<MonthlyPickupResponse>;
  // Create a standalone pickup
  createStandalonePickup(
    providerId: number,
    standalonePickupCreate: StandalonePickupCreate,
    version?: number,
  ): AxiosPromise<NoContentResponse>;
  // Get a single pickup
  getSinglePickup(
    providerId: number,
    pickupId: number,
    params?: GetSinglePickupParams,
    version?: number,
  ): AxiosPromise<PickupResponse>;
  // Update a single pickup
  updateSinglePickup(
    providerId: number,
    pickupId: number,
    pickupOccurrenceUpdate: PickupOccurrenceUpdate,
    version?: number,
  ): AxiosPromise<NoContentResponse>;
  // Delete a standalone pickup or cancel an occurrence
  deleteSinglePickup(
    providerId: number,
    pickupId: number,
    params?: DeleteSinglePickupParams,
    version?: number,
  ): AxiosPromise<NoContentResponse>;
  // Resume an occurrence
  resumeOccurrence(
    providerId: number,
    pickupId: number,
    params?: ResumeOccurrenceParams,
    version?: number,
  ): AxiosPromise<NoContentResponse>;
  // Create a recurrent pickup
  createRecurrentPickup(
    providerId: number,
    recurrentPickupCreate: RecurrentPickupCreate,
    version?: number,
  ): AxiosPromise<NoContentResponse>;
  // Update a recurrent pickup
  updateRecurrentPickup(
    providerId: number,
    pickupId: number,
    recurrentPickupUpdate: RecurrentPickupUpdate,
    version?: number,
  ): AxiosPromise<NoContentResponse>;
  // Delete a recurrent pickup
  deleteRecurrentPickup(
    providerId: number,
    pickupId: number,
    version?: number,
  ): AxiosPromise<NoContentResponse>;
  // Search for remittances
  getLastRemittances(
    providerId: number,
    params?: GetLastRemittancesParams,
    version?: number,
  ): AxiosPromise<RemittancesResponse>;
  // Add a new remittances
  createRemittances(
    providerId: number,
    electronicVoucherRemittanceCreationDemand: ElectronicVoucherRemittanceCreationDemand,
    params?: CreateRemittancesParams,
    version?: number,
  ): AxiosPromise<ElectronicVoucherRemittanceConfirmation>;
  // Get overview for all remittances
  getRemittancesOverview(
    providerId: number,
    version?: number,
  ): AxiosPromise<RemittancesOverviewResponse>;
  // Get available months
  getAvailableMonths(
    providerId: number,
    version?: number,
  ): AxiosPromise<MonthPeriodResponse>;
  // Get a remittance
  getRemittance(
    providerId: number,
    remittanceId: number,
    version?: number,
  ): AxiosPromise<RemittanceDetailResponse>;
  // Get incorrect vouchers of a remittance
  getIncorrectVouchersRemittance(
    providerId: number,
    remittanceId: number,
    params?: GetIncorrectVouchersRemittanceParams,
    version?: number,
  ): AxiosPromise<RemittanceDetailIncorrectResponse>;
  // Get refused vouchers of a remittance
  getRefusedVouchersRemittance(
    providerId: number,
    remittanceId: number,
    params?: GetRefusedVouchersRemittanceParams,
    version?: number,
  ): AxiosPromise<RemittanceDetailRefusedResponse>;
  // Export refused vouchers of a remittance
  exportRefusedVouchersRemittance(
    providerId: number,
    remittanceId: number,
    params?: ExportRefusedVouchersRemittanceParams,
    version?: number,
  ): AxiosPromise<Blob>;
  // Export searched remittances
  exportRemittances(
    providerId: number,
    params?: ExportRemittancesParams,
    version?: number,
  ): AxiosPromise<Blob>;
  // Export remittance works
  exportRemittanceWorks(
    providerId: number,
    remittanceId: number,
    params?: ExportRemittanceWorksParams,
    version?: number,
  ): AxiosPromise<Blob>;
  // Add a new paper vouchers remittance
  createRemittance(
    providerId: number,
    paperVouchersRemittance: PaperVouchersRemittance,
    version?: number,
  ): AxiosPromise<Blob>;
  // Add a new paper voucher announcement
  createVoucherAnnouncement(
    providerId: number,
    announcementsWithRegionalAgreement: AnnouncementsWithRegionalAgreement,
    version?: number,
  ): AxiosPromise<PaperVoucherAnnouncementsResponse>;
  // Parse a file containing a list of announcements
  parseAnnouncementsFile(
    providerId: number,
    blob: Blob,
    version?: number,
  ): AxiosPromise<PaperVoucherAnnouncementsFileParserResponse>;
  // Correct a voucher
  correctVoucher(
    providerId: number,
    remittanceId: number,
    voucherId: number,
    voucher: Voucher,
    version?: number,
  ): AxiosPromise<NoContentResponse>;
  // Get paper voucher validity
  getPaperVoucherValidity(
    voucherReference: string,
    version?: number,
  ): AxiosPromise<PaperVoucherValidityResponse>;
  // Get provider's worker list
  getWorkers(
    providerId: number,
    params?: GetWorkersParams,
    version?: number,
  ): AxiosPromise<WorkersResponse>;
  // Add new workers
  createWorkers(
    providerId: number,
    worker: Worker,
    version?: number,
  ): AxiosPromise<WorkersCreatedResponse>;
  // Activate workers
  activateWorkers(
    providerId: number,
    params?: ActivateWorkersParams,
    version?: number,
  ): AxiosPromise<NoContentResponse>;
  // Deactivate workers
  deactivateWorkers(
    providerId: number,
    params?: DeactivateWorkersParams,
    version?: number,
  ): AxiosPromise<NoContentResponse>;
  // Export provider's worker list
  exportWorkers(
    providerId: number,
    params?: ExportWorkersParams,
    version?: number,
  ): AxiosPromise<Blob>;
  // Parse a file containing a list of workers
  parseWorkersFile(
    providerId: number,
    blob: Blob,
    version?: number,
  ): AxiosPromise<NoContentResponse>;
  // get status of the imported file containing a list of workers
  getWorkersFileStatus(
    providerId: number,
    version?: number,
  ): AxiosPromise<WorkersFileStatusResponse>;
  // Export imported workers list
  exportFileWorkers(
    providerId: number,
    params?: ExportFileWorkersParams,
    version?: number,
  ): AxiosPromise<Blob>;
  // Get overview for all workers
  getWorkersOverview(
    providerId: number,
    version?: number,
  ): AxiosPromise<WorkersOverviewResponse>;
  // Get detail of a worker
  getWorkerDetail(
    providerId: number,
    workerId: number,
    version?: number,
  ): AxiosPromise<WorkerDetailResponse>;
  // Update a worker
  updateWorker(
    providerId: number,
    workerId: number,
    worker: Worker,
    version?: number,
  ): AxiosPromise<NoContentResponse>;
  // Activate a worker
  activateWorker(
    providerId: number,
    workerId: number,
    version?: number,
  ): AxiosPromise<WorkerUpdatedResponse>;
  // Deactivate a worker
  deactivateWorker(
    providerId: number,
    workerId: number,
    version?: number,
  ): AxiosPromise<WorkerUpdatedResponse>;
  // Reset password of a worker
  resetPasswordWorker(
    providerId: number,
    workerId: number,
    version?: number,
  ): AxiosPromise<WorkerResetPasswordResponse>;
  // get contracts of a worker
  getWorkerContracts(
    providerId: number,
    workerId: number,
    version?: number,
  ): AxiosPromise<WorkerContractsResponse>;
  // Add new contract of a worker
  createWorkerContract(
    providerId: number,
    workerId: number,
    workerContract: WorkerContract,
    version?: number,
  ): AxiosPromise<WorkerCreateContractResponse>;
  // Update contract of a worker
  updateWorkerContract(
    providerId: number,
    workerId: number,
    contractId: number,
    workerContract: WorkerContract,
    version?: number,
  ): AxiosPromise<NoContentResponse>;
  // Delete a worker contract
  deleteWorkerContract(
    providerId: number,
    workerId: number,
    contractId: number,
    version?: number,
  ): AxiosPromise<NoContentResponse>;
  // Get provider's works list
  getWorkersServices(
    providerId: number,
    params?: GetWorkersServicesParams,
    version?: number,
  ): AxiosPromise<WorkersServicesResponse>;
  // Get provider's works list
  getListServices(
    providerId: number,
    params?: GetListServicesParams,
    version?: number,
  ): AxiosPromise<ListServicesResponse>;
  // Get provider's services history list
  getServicesHistory(
    providerId: number,
    params?: GetServicesHistoryParams,
    version?: number,
  ): AxiosPromise<ServicesHistoryResponse>;
  // Get provider's services history list for worker
  getWorkerServicesHistory(
    providerId: number,
    workerId: number,
    params?: GetWorkerServicesHistoryParams,
    version?: number,
  ): AxiosPromise<ServicesHistoryResponse>;
  // Get a worker services list
  getWorkerServices(
    providerId: number,
    workerId: number,
    params?: GetWorkerServicesParams,
    version?: number,
  ): AxiosPromise<WorkerServicesResponse>;
  // Validate the queried services for the requested worker
  validateWorkerServices(
    providerId: number,
    workerId: number,
    params?: ValidateWorkerServicesParams,
    version?: number,
  ): AxiosPromise<ServicesValidatedResponse>;
  // get a service
  getService(
    providerId: number,
    serviceId: string,
    version?: number,
  ): AxiosPromise<ServiceResponse>;
  // update a service
  updateWorkerService(
    providerId: string,
    serviceId: string,
    serviceUpdate: ServiceUpdate,
    version?: number,
  ): AxiosPromise<ServiceUpdateResponse>;
  // Cancel a service
  cancelService(
    providerId: string,
    serviceId: string,
    serviceCancel: ServiceCancel,
    version?: number,
  ): AxiosPromise<ServiceUpdateResponse>;
  // Validate the queried services
  validateWorkersServices(
    providerId: number,
    params?: ValidateWorkersServicesParams,
    version?: number,
  ): AxiosPromise<ServicesValidatedResponse>;
  // Validate a service
  validateWorkerService(
    providerId: number,
    serviceId: number,
    version?: number,
  ): AxiosPromise<ServiceUpdateResponse>;
  // Export provider's services list
  exportServices(
    providerId: number,
    params?: ExportServicesParams,
    version?: number,
  ): AxiosPromise<Blob>;
  // Parse a file containing a list of services
  parseServicesFile(
    providerId: number,
    blob: Blob,
    version?: number,
  ): AxiosPromise<NoContentResponse>;
  // Export imported services list
  exportFileServices(
    providerId: number,
    params?: ExportFileServicesParams,
    version?: number,
  ): AxiosPromise<Blob>;
  // get status of a file containing a list of services
  getServicesFileStatus(
    providerId: number,
    version?: number,
  ): AxiosPromise<ServicesFileStatusResponse>;
  // get services of a provider
  getServices(
    providerId: number,
    params?: GetServicesParams,
    version?: number,
  ): AxiosPromise<ServicesResponse>;
  // Add a new service
  createService(
    providerId: string,
    serviceCreate: ServiceCreate,
    version?: number,
  ): AxiosPromise<EntityCreatedResponse>;
  // get services overview of a provider
  getServicesOverview(
    providerId: number,
    version?: number,
  ): AxiosPromise<ServicesOverviewResponse>;
  // get services overview of a provider
  getServicesOverviewHistory(
    providerId: number,
    version?: number,
  ): AxiosPromise<ServicesOverviewResponse>;
  // get customers
  getCustomers(
    params?: GetCustomersParams,
    version?: number,
  ): AxiosPromise<CustomersResponse>;
  // get provider's subsidies
  getSubsidies(
    providerId: number,
    params?: GetSubsidiesParams,
    version?: number,
  ): AxiosPromise<SubsidiesResponse>;
  // Export provider's subsidies
  exportSubsidies(
    providerId: number,
    params?: ExportSubsidiesParams,
    version?: number,
  ): AxiosPromise<Blob>;
  // Get provider's subsidy
  getSubsidy(
    providerId: number,
    subsidyId: string,
    version?: number,
  ): AxiosPromise<SubsidyDetailResponse>;
  // Update logged in user password
  updateUserPassword(
    userPasswordUpdate: UserPasswordUpdate,
    version?: number,
  ): AxiosPromise<NoContentResponse>;
  // Get a provider's bills
  getProviderBills(
    providerId: number,
    params?: GetProviderBillsParams,
    version?: number,
  ): AxiosPromise<BillsResponse>;
  // Get a provider's bill
  getProviderBill(
    providerId: number,
    billId: string,
    version?: number,
  ): AxiosPromise<BillDetailsResponse>;
  // Get a provider's billing configuration
  getProviderBillingConfiguration(
    providerId: number,
    version?: number,
  ): AxiosPromise<BillingConfigurationResponse>;
  // Update a provider's billing configuration
  putProviderBillingConfiguration(
    providerId: number,
    contactPerson: ContactPerson,
    version?: number,
  ): AxiosPromise<NoContentResponse>;
  // Download bill document
  downloadBill(
    providerId: number,
    billingDocumentId: string,
    version?: number,
  ): AxiosPromise<Blob>;
}

export const getBFFProvidersApi = (axios: AxiosInstance): BFFProvidersApi => ({
  liveness(): AxiosPromise<unknown> {
    return axios.get(`/liveness`);
  },
  getVideos(
    params?: GetVideosParams,
    version = 1,
  ): AxiosPromise<VideosResponse> {
    return axios.get(`/v${version}/videos`, {
      params,
    });
  },
  getVideoStreamId(
    videoId: string,
    version = 1,
  ): AxiosPromise<VideosStreamIdResponse> {
    return axios.get(`/v${version}/videos/${videoId}/generate-stream`);
  },
  streamVideo(streamId: string, version = 1): AxiosPromise<Blob> {
    return axios.get(`/v${version}/videos/${streamId}/play`, {
      responseType: 'blob',
    });
  },
  thumbnailVideo(videoId: string, version = 1): AxiosPromise<Blob> {
    return axios.get(`/v${version}/videos/${videoId}/preview`, {
      responseType: 'blob',
    });
  },
  getGlobalLocations(version = 1): AxiosPromise<LocationsResponse> {
    return axios.get(`/v${version}/locations`);
  },
  getLocationsSuggestions(
    params?: GetLocationsSuggestionsParams,
    version = 1,
  ): AxiosPromise<StreetsResponse> {
    return axios.get(`/v${version}/locations/streets`, {
      params,
    });
  },
  getGlobalTitles(
    params?: GetGlobalTitlesParams,
    version = 1,
  ): AxiosPromise<TitlesResponse> {
    return axios.get(`/v${version}/titles`, {
      params,
    });
  },
  getGlobalLegalForms(
    params?: GetGlobalLegalFormsParams,
    version = 1,
  ): AxiosPromise<LegalFormsResponse> {
    return axios.get(`/v${version}/legal-forms`, {
      params,
    });
  },
  getProviderVisibilityForLocations(
    providerId: number,
    version = 1,
  ): AxiosPromise<ProviderVisibilityLocationsResponse> {
    return axios.get(`/v${version}/providers/${providerId}/active-locations`);
  },
  updateProviderVisibilityForLocations(
    providerId: number,
    providerVisibilityLocations: ProviderVisibilityLocations,
    version = 1,
  ): AxiosPromise<NoContentResponse> {
    return axios.put(
      `/v${version}/providers/${providerId}/active-locations`,
      providerVisibilityLocations,
    );
  },
  getProviderVisibilityForAgreements(
    providerId: number,
    version = 1,
  ): AxiosPromise<AgreementResponse> {
    return axios.get(
      `/v${version}/providers/${providerId}/activity-agreements`,
    );
  },
  updateProviderVisibilityForAgreements(
    providerId: number,
    providerVisibilityUpdateAgreements: ProviderVisibilityUpdateAgreements,
    version = 1,
  ): AxiosPromise<NoContentResponse> {
    return axios.put(
      `/v${version}/providers/${providerId}/activity-agreements`,
      providerVisibilityUpdateAgreements,
    );
  },
  getDashboardServicesOverview(
    providerId: number,
    version = 1,
  ): AxiosPromise<DashboardWorksOverviewResponse> {
    return axios.get(`/v${version}/providers/${providerId}/dashboard/services`);
  },
  getDashboardRemittancesOverview(
    providerId: number,
    version = 1,
  ): AxiosPromise<DashboardRemittancesOverviewResponse> {
    return axios.get(
      `/v${version}/providers/${providerId}/dashboard/remittances`,
    );
  },
  getDashboardPickupsOverview(
    providerId: number,
    version = 1,
  ): AxiosPromise<DashboardPickupOverviewResponse> {
    return axios.get(`/v${version}/providers/${providerId}/dashboard/pickups`);
  },
  getDashboardRemittancesActions(
    providerId: number,
    version = 1,
  ): AxiosPromise<DashboardRemittancesActionsResponse> {
    return axios.get(
      `/v${version}/providers/${providerId}/dashboard/actions/remittances`,
    );
  },
  getDashboardServicesActions(
    providerId: number,
    version = 1,
  ): AxiosPromise<DashboardWorksActionsResponse> {
    return axios.get(
      `/v${version}/providers/${providerId}/dashboard/actions/services`,
    );
  },
  getDashboardWorkersActions(
    providerId: number,
    version = 1,
  ): AxiosPromise<DashboardWorkersActionsResponse> {
    return axios.get(
      `/v${version}/providers/${providerId}/dashboard/actions/workers`,
    );
  },
  createProvider(
    providerSubsidiary: ProviderSubsidiary,
    version = 1,
  ): AxiosPromise<RelativeResponse> {
    return axios.post(`/v${version}/providers`, providerSubsidiary);
  },
  getProvider(providerId: number, version = 1): AxiosPromise<ProviderResponse> {
    return axios.get(`/v${version}/providers/${providerId}`);
  },
  updateProvider(
    providerId: number,
    providerUpdate: ProviderUpdate,
    version = 1,
  ): AxiosPromise<NoContentResponse> {
    return axios.put(`/v${version}/providers/${providerId}`, providerUpdate);
  },
  getProviderSubsidyConfiguration(
    providerId: number,
    version = 1,
  ): AxiosPromise<ProviderSubsidyConfigurationResponse> {
    return axios.get(
      `/v${version}/providers/${providerId}/subsidy-configuration`,
    );
  },
  updateProviderSubsidyConfiguration(
    providerId: number,
    providerSubsidyConfigurationUpdate: ProviderSubsidyConfigurationUpdate,
    version = 1,
  ): AxiosPromise<NoContentResponse> {
    return axios.put(
      `/v${version}/providers/${providerId}/subsidy-configuration`,
      providerSubsidyConfigurationUpdate,
    );
  },
  updateProviderRemittanceConfiguration(
    providerId: number,
    providerRemittanceUpdateConfiguration: ProviderRemittanceUpdateConfiguration,
    version = 1,
  ): AxiosPromise<NoContentResponse> {
    return axios.put(
      `/v${version}/providers/${providerId}/remittance-configuration`,
      providerRemittanceUpdateConfiguration,
    );
  },
  getContactPersons(
    providerId: number,
    version = 1,
  ): AxiosPromise<ContactPersonsResponse> {
    return axios.get(`/v${version}/providers/${providerId}/contact-persons`);
  },
  addContactPerson(
    providerId: number,
    contactPerson: ContactPerson,
    version = 1,
  ): AxiosPromise<EntityCreatedResponseWithActions> {
    return axios.post(
      `/v${version}/providers/${providerId}/contact-persons`,
      contactPerson,
    );
  },
  getContactPerson(
    providerId: number,
    contactPersonId: number,
    version = 1,
  ): AxiosPromise<ContactPersonResponse> {
    return axios.get(
      `/v${version}/providers/${providerId}/contact-persons/${contactPersonId}`,
    );
  },
  updateContactPerson(
    providerId: number,
    contactPersonId: number,
    contactPerson: ContactPerson,
    version = 1,
  ): AxiosPromise<Actions> {
    return axios.put(
      `/v${version}/providers/${providerId}/contact-persons/${contactPersonId}`,
      contactPerson,
    );
  },
  deleteContactPerson(
    providerId: number,
    contactPersonId: number,
    version = 1,
  ): AxiosPromise<NoContentResponse> {
    return axios.delete(
      `/v${version}/providers/${providerId}/contact-persons/${contactPersonId}`,
    );
  },
  getProviderRelatives(
    providerId: number,
    params?: GetProviderRelativesParams,
    version = 1,
  ): AxiosPromise<RelativesResponse> {
    return axios.get(`/v${version}/providers/${providerId}/relatives`, {
      params,
    });
  },
  getProviderAddresses(
    providerId: number,
    version = 1,
  ): AxiosPromise<RelativeAddressesResponse> {
    return axios.get(`/v${version}/providers/${providerId}/addresses`);
  },
  deactivateProviderMember(
    providerId: number,
    version = 1,
  ): AxiosPromise<RelativeResponse> {
    return axios.post(
      `/v${version}/providers/${providerId}/deactivate`,
      undefined,
    );
  },
  getProviderPickupConfiguration(
    providerId: number,
    version = 1,
  ): AxiosPromise<PickupConfigurationResponse> {
    return axios.get(
      `/v${version}/providers/${providerId}/pickups/configuration`,
    );
  },
  getProviderPickupPlanning(
    providerId: number,
    params?: GetProviderPickupPlanningParams,
    version = 1,
  ): AxiosPromise<MonthlyPickupResponse> {
    return axios.get(`/v${version}/providers/${providerId}/pickups`, {
      params,
    });
  },
  createStandalonePickup(
    providerId: number,
    standalonePickupCreate: StandalonePickupCreate,
    version = 1,
  ): AxiosPromise<NoContentResponse> {
    return axios.post(
      `/v${version}/providers/${providerId}/pickups/standalone`,
      standalonePickupCreate,
    );
  },
  getSinglePickup(
    providerId: number,
    pickupId: number,
    params?: GetSinglePickupParams,
    version = 1,
  ): AxiosPromise<PickupResponse> {
    return axios.get(
      `/v${version}/providers/${providerId}/pickups/${pickupId}`,
      {
        params,
      },
    );
  },
  updateSinglePickup(
    providerId: number,
    pickupId: number,
    pickupOccurrenceUpdate: PickupOccurrenceUpdate,
    version = 1,
  ): AxiosPromise<NoContentResponse> {
    return axios.put(
      `/v${version}/providers/${providerId}/pickups/${pickupId}/occurrence`,
      pickupOccurrenceUpdate,
    );
  },
  deleteSinglePickup(
    providerId: number,
    pickupId: number,
    params?: DeleteSinglePickupParams,
    version = 1,
  ): AxiosPromise<NoContentResponse> {
    return axios.delete(
      `/v${version}/providers/${providerId}/pickups/${pickupId}/occurrence`,
      {
        params,
      },
    );
  },
  resumeOccurrence(
    providerId: number,
    pickupId: number,
    params?: ResumeOccurrenceParams,
    version = 1,
  ): AxiosPromise<NoContentResponse> {
    return axios.post(
      `/v${version}/providers/${providerId}/pickups/${pickupId}/resume`,
      undefined,
      {
        params,
      },
    );
  },
  createRecurrentPickup(
    providerId: number,
    recurrentPickupCreate: RecurrentPickupCreate,
    version = 1,
  ): AxiosPromise<NoContentResponse> {
    return axios.post(
      `/v${version}/providers/${providerId}/pickups/recurrent`,
      recurrentPickupCreate,
    );
  },
  updateRecurrentPickup(
    providerId: number,
    pickupId: number,
    recurrentPickupUpdate: RecurrentPickupUpdate,
    version = 1,
  ): AxiosPromise<NoContentResponse> {
    return axios.put(
      `/v${version}/providers/${providerId}/pickups/${pickupId}/recurrent`,
      recurrentPickupUpdate,
    );
  },
  deleteRecurrentPickup(
    providerId: number,
    pickupId: number,
    version = 1,
  ): AxiosPromise<NoContentResponse> {
    return axios.delete(
      `/v${version}/providers/${providerId}/pickups/${pickupId}/recurrent`,
    );
  },
  getLastRemittances(
    providerId: number,
    params?: GetLastRemittancesParams,
    version = 1,
  ): AxiosPromise<RemittancesResponse> {
    return axios.get(`/v${version}/providers/${providerId}/remittances`, {
      params,
    });
  },
  createRemittances(
    providerId: number,
    electronicVoucherRemittanceCreationDemand: ElectronicVoucherRemittanceCreationDemand,
    params?: CreateRemittancesParams,
    version = 1,
  ): AxiosPromise<ElectronicVoucherRemittanceConfirmation> {
    return axios.post(
      `/v${version}/providers/${providerId}/remittances`,
      electronicVoucherRemittanceCreationDemand,
      {
        params,
      },
    );
  },
  getRemittancesOverview(
    providerId: number,
    version = 1,
  ): AxiosPromise<RemittancesOverviewResponse> {
    return axios.get(
      `/v${version}/providers/${providerId}/remittances/overview`,
    );
  },
  getAvailableMonths(
    providerId: number,
    version = 1,
  ): AxiosPromise<MonthPeriodResponse> {
    return axios.get(
      `/v${version}/providers/${providerId}/remittances/available-months`,
    );
  },
  getRemittance(
    providerId: number,
    remittanceId: number,
    version = 1,
  ): AxiosPromise<RemittanceDetailResponse> {
    return axios.get(
      `/v${version}/providers/${providerId}/remittances/${remittanceId}`,
    );
  },
  getIncorrectVouchersRemittance(
    providerId: number,
    remittanceId: number,
    params?: GetIncorrectVouchersRemittanceParams,
    version = 1,
  ): AxiosPromise<RemittanceDetailIncorrectResponse> {
    return axios.get(
      `/v${version}/providers/${providerId}/remittances/${remittanceId}/incorrect-vouchers`,
      {
        params,
      },
    );
  },
  getRefusedVouchersRemittance(
    providerId: number,
    remittanceId: number,
    params?: GetRefusedVouchersRemittanceParams,
    version = 1,
  ): AxiosPromise<RemittanceDetailRefusedResponse> {
    return axios.get(
      `/v${version}/providers/${providerId}/remittances/${remittanceId}/refused-vouchers`,
      {
        params,
      },
    );
  },
  exportRefusedVouchersRemittance(
    providerId: number,
    remittanceId: number,
    params?: ExportRefusedVouchersRemittanceParams,
    version = 1,
  ): AxiosPromise<Blob> {
    return axios.get(
      `/v${version}/providers/${providerId}/remittances/${remittanceId}/refused-vouchers/export`,
      {
        params,
        responseType: 'blob',
      },
    );
  },
  exportRemittances(
    providerId: number,
    params?: ExportRemittancesParams,
    version = 1,
  ): AxiosPromise<Blob> {
    return axios.get(
      `/v${version}/providers/${providerId}/remittances/export`,
      {
        params,
        responseType: 'blob',
      },
    );
  },
  exportRemittanceWorks(
    providerId: number,
    remittanceId: number,
    params?: ExportRemittanceWorksParams,
    version = 1,
  ): AxiosPromise<Blob> {
    return axios.get(
      `/v${version}/providers/${providerId}/remittances/${remittanceId}/export`,
      {
        params,
        responseType: 'blob',
      },
    );
  },
  createRemittance(
    providerId: number,
    paperVouchersRemittance: PaperVouchersRemittance,
    version = 1,
  ): AxiosPromise<Blob> {
    return axios.post(
      `/v${version}/providers/${providerId}/vouchers/remittance-slips`,
      paperVouchersRemittance,
      {
        responseType: 'blob',
      },
    );
  },
  createVoucherAnnouncement(
    providerId: number,
    announcementsWithRegionalAgreement: AnnouncementsWithRegionalAgreement,
    version = 1,
  ): AxiosPromise<PaperVoucherAnnouncementsResponse> {
    return axios.post(
      `/v${version}/providers/${providerId}/vouchers/announcements`,
      announcementsWithRegionalAgreement,
    );
  },
  parseAnnouncementsFile(
    providerId: number,
    blob: Blob,
    version = 1,
  ): AxiosPromise<PaperVoucherAnnouncementsFileParserResponse> {
    const formData = new FormData();
    formData.append('file', blob);
    return axios.post(
      `/v${version}/providers/${providerId}/vouchers/announcements/file-parser`,
      formData,
    );
  },
  correctVoucher(
    providerId: number,
    remittanceId: number,
    voucherId: number,
    voucher: Voucher,
    version = 1,
  ): AxiosPromise<NoContentResponse> {
    return axios.put(
      `/v${version}/providers/${providerId}/remittances/${remittanceId}/vouchers/${voucherId}`,
      voucher,
    );
  },
  getPaperVoucherValidity(
    voucherReference: string,
    version = 1,
  ): AxiosPromise<PaperVoucherValidityResponse> {
    return axios.get(
      `/v${version}/paper-vouchers/${voucherReference}/validity`,
    );
  },
  getWorkers(
    providerId: number,
    params?: GetWorkersParams,
    version = 1,
  ): AxiosPromise<WorkersResponse> {
    return axios.get(`/v${version}/providers/${providerId}/workers`, {
      params,
    });
  },
  createWorkers(
    providerId: number,
    worker: Worker,
    version = 1,
  ): AxiosPromise<WorkersCreatedResponse> {
    return axios.post(`/v${version}/providers/${providerId}/workers`, worker);
  },
  activateWorkers(
    providerId: number,
    params?: ActivateWorkersParams,
    version = 1,
  ): AxiosPromise<NoContentResponse> {
    return axios.post(
      `/v${version}/providers/${providerId}/workers/activate`,
      undefined,
      {
        params,
      },
    );
  },
  deactivateWorkers(
    providerId: number,
    params?: DeactivateWorkersParams,
    version = 1,
  ): AxiosPromise<NoContentResponse> {
    return axios.post(
      `/v${version}/providers/${providerId}/workers/deactivate`,
      undefined,
      {
        params,
      },
    );
  },
  exportWorkers(
    providerId: number,
    params?: ExportWorkersParams,
    version = 1,
  ): AxiosPromise<Blob> {
    return axios.get(`/v${version}/providers/${providerId}/workers/export`, {
      params,
      responseType: 'blob',
    });
  },
  parseWorkersFile(
    providerId: number,
    blob: Blob,
    version = 1,
  ): AxiosPromise<NoContentResponse> {
    const formData = new FormData();
    formData.append('file', blob);
    return axios.post(
      `/v${version}/providers/${providerId}/workers/file-parser`,
      formData,
    );
  },
  getWorkersFileStatus(
    providerId: number,
    version = 1,
  ): AxiosPromise<WorkersFileStatusResponse> {
    return axios.get(
      `/v${version}/providers/${providerId}/workers/file-status`,
    );
  },
  exportFileWorkers(
    providerId: number,
    params?: ExportFileWorkersParams,
    version = 1,
  ): AxiosPromise<Blob> {
    return axios.get(
      `/v${version}/providers/${providerId}/workers/file-export`,
      {
        params,
        responseType: 'blob',
      },
    );
  },
  getWorkersOverview(
    providerId: number,
    version = 1,
  ): AxiosPromise<WorkersOverviewResponse> {
    return axios.get(`/v${version}/providers/${providerId}/workers/overview`);
  },
  getWorkerDetail(
    providerId: number,
    workerId: number,
    version = 1,
  ): AxiosPromise<WorkerDetailResponse> {
    return axios.get(
      `/v${version}/providers/${providerId}/workers/${workerId}`,
    );
  },
  updateWorker(
    providerId: number,
    workerId: number,
    worker: Worker,
    version = 1,
  ): AxiosPromise<NoContentResponse> {
    return axios.put(
      `/v${version}/providers/${providerId}/workers/${workerId}`,
      worker,
    );
  },
  activateWorker(
    providerId: number,
    workerId: number,
    version = 1,
  ): AxiosPromise<WorkerUpdatedResponse> {
    return axios.post(
      `/v${version}/providers/${providerId}/workers/${workerId}/activate`,
      undefined,
    );
  },
  deactivateWorker(
    providerId: number,
    workerId: number,
    version = 1,
  ): AxiosPromise<WorkerUpdatedResponse> {
    return axios.post(
      `/v${version}/providers/${providerId}/workers/${workerId}/deactivate`,
      undefined,
    );
  },
  resetPasswordWorker(
    providerId: number,
    workerId: number,
    version = 1,
  ): AxiosPromise<WorkerResetPasswordResponse> {
    return axios.post(
      `/v${version}/providers/${providerId}/workers/${workerId}/reset-password`,
      undefined,
    );
  },
  getWorkerContracts(
    providerId: number,
    workerId: number,
    version = 1,
  ): AxiosPromise<WorkerContractsResponse> {
    return axios.get(
      `/v${version}/providers/${providerId}/workers/${workerId}/contracts`,
    );
  },
  createWorkerContract(
    providerId: number,
    workerId: number,
    workerContract: WorkerContract,
    version = 1,
  ): AxiosPromise<WorkerCreateContractResponse> {
    return axios.post(
      `/v${version}/providers/${providerId}/workers/${workerId}/contracts`,
      workerContract,
    );
  },
  updateWorkerContract(
    providerId: number,
    workerId: number,
    contractId: number,
    workerContract: WorkerContract,
    version = 1,
  ): AxiosPromise<NoContentResponse> {
    return axios.put(
      `/v${version}/providers/${providerId}/workers/${workerId}/contracts/${contractId}`,
      workerContract,
    );
  },
  deleteWorkerContract(
    providerId: number,
    workerId: number,
    contractId: number,
    version = 1,
  ): AxiosPromise<NoContentResponse> {
    return axios.delete(
      `/v${version}/providers/${providerId}/workers/${workerId}/contracts/${contractId}`,
    );
  },
  getWorkersServices(
    providerId: number,
    params?: GetWorkersServicesParams,
    version = 1,
  ): AxiosPromise<WorkersServicesResponse> {
    return axios.get(`/v${version}/providers/${providerId}/workers/services`, {
      params,
    });
  },
  getListServices(
    providerId: number,
    params?: GetListServicesParams,
    version = 1,
  ): AxiosPromise<ListServicesResponse> {
    return axios.get(`/v${version}/providers/${providerId}/list/services`, {
      params,
    });
  },
  getServicesHistory(
    providerId: number,
    params?: GetServicesHistoryParams,
    version = 1,
  ): AxiosPromise<ServicesHistoryResponse> {
    return axios.get(`/v${version}/providers/${providerId}/services/history`, {
      params,
    });
  },
  getWorkerServicesHistory(
    providerId: number,
    workerId: number,
    params?: GetWorkerServicesHistoryParams,
    version = 1,
  ): AxiosPromise<ServicesHistoryResponse> {
    return axios.get(
      `/v${version}/providers/${providerId}/workers/${workerId}/services/history`,
      {
        params,
      },
    );
  },
  getWorkerServices(
    providerId: number,
    workerId: number,
    params?: GetWorkerServicesParams,
    version = 1,
  ): AxiosPromise<WorkerServicesResponse> {
    return axios.get(
      `/v${version}/providers/${providerId}/workers/${workerId}/services`,
      {
        params,
      },
    );
  },
  validateWorkerServices(
    providerId: number,
    workerId: number,
    params?: ValidateWorkerServicesParams,
    version = 1,
  ): AxiosPromise<ServicesValidatedResponse> {
    return axios.patch(
      `/v${version}/providers/${providerId}/workers/${workerId}/services/validate`,
      undefined,
      {
        params,
      },
    );
  },
  getService(
    providerId: number,
    serviceId: string,
    version = 1,
  ): AxiosPromise<ServiceResponse> {
    return axios.get(
      `/v${version}/providers/${providerId}/services/${serviceId}`,
    );
  },
  updateWorkerService(
    providerId: string,
    serviceId: string,
    serviceUpdate: ServiceUpdate,
    version = 1,
  ): AxiosPromise<ServiceUpdateResponse> {
    return axios.put(
      `/v${version}/providers/${providerId}/services/${serviceId}`,
      serviceUpdate,
    );
  },
  cancelService(
    providerId: string,
    serviceId: string,
    serviceCancel: ServiceCancel,
    version = 1,
  ): AxiosPromise<ServiceUpdateResponse> {
    return axios.post(
      `/v${version}/providers/${providerId}/services/${serviceId}/cancel`,
      serviceCancel,
    );
  },
  validateWorkersServices(
    providerId: number,
    params?: ValidateWorkersServicesParams,
    version = 1,
  ): AxiosPromise<ServicesValidatedResponse> {
    return axios.patch(
      `/v${version}/providers/${providerId}/services/validate`,
      undefined,
      {
        params,
      },
    );
  },
  validateWorkerService(
    providerId: number,
    serviceId: number,
    version = 1,
  ): AxiosPromise<ServiceUpdateResponse> {
    return axios.patch(
      `/v${version}/providers/${providerId}/services/${serviceId}/validate`,
      undefined,
    );
  },
  exportServices(
    providerId: number,
    params?: ExportServicesParams,
    version = 1,
  ): AxiosPromise<Blob> {
    return axios.get(`/v${version}/providers/${providerId}/services/export`, {
      params,
      responseType: 'blob',
    });
  },
  parseServicesFile(
    providerId: number,
    blob: Blob,
    version = 1,
  ): AxiosPromise<NoContentResponse> {
    const formData = new FormData();
    formData.append('file', blob);
    return axios.post(
      `/v${version}/providers/${providerId}/services/file-parser`,
      formData,
    );
  },
  exportFileServices(
    providerId: number,
    params?: ExportFileServicesParams,
    version = 1,
  ): AxiosPromise<Blob> {
    return axios.get(
      `/v${version}/providers/${providerId}/services/file-export`,
      {
        params,
        responseType: 'blob',
      },
    );
  },
  getServicesFileStatus(
    providerId: number,
    version = 1,
  ): AxiosPromise<ServicesFileStatusResponse> {
    return axios.get(
      `/v${version}/providers/${providerId}/services/file-status`,
    );
  },
  getServices(
    providerId: number,
    params?: GetServicesParams,
    version = 1,
  ): AxiosPromise<ServicesResponse> {
    return axios.get(`/v${version}/providers/${providerId}/services`, {
      params,
    });
  },
  createService(
    providerId: string,
    serviceCreate: ServiceCreate,
    version = 1,
  ): AxiosPromise<EntityCreatedResponse> {
    return axios.post(
      `/v${version}/providers/${providerId}/services`,
      serviceCreate,
    );
  },
  getServicesOverview(
    providerId: number,
    version = 1,
  ): AxiosPromise<ServicesOverviewResponse> {
    return axios.get(`/v${version}/providers/${providerId}/services/overview`);
  },
  getServicesOverviewHistory(
    providerId: number,
    version = 1,
  ): AxiosPromise<ServicesOverviewResponse> {
    return axios.get(
      `/v${version}/providers/${providerId}/services/overview-history`,
    );
  },
  getCustomers(
    params?: GetCustomersParams,
    version = 1,
  ): AxiosPromise<CustomersResponse> {
    return axios.get(`/v${version}/customers`, {
      params,
    });
  },
  getSubsidies(
    providerId: number,
    params?: GetSubsidiesParams,
    version = 1,
  ): AxiosPromise<SubsidiesResponse> {
    return axios.get(`/v${version}/providers/${providerId}/subsidies`, {
      params,
    });
  },
  exportSubsidies(
    providerId: number,
    params?: ExportSubsidiesParams,
    version = 1,
  ): AxiosPromise<Blob> {
    return axios.get(`/v${version}/providers/${providerId}/subsidies/export`, {
      params,
      responseType: 'blob',
    });
  },
  getSubsidy(
    providerId: number,
    subsidyId: string,
    version = 1,
  ): AxiosPromise<SubsidyDetailResponse> {
    return axios.get(
      `/v${version}/providers/${providerId}/subsidies/${subsidyId}`,
    );
  },
  updateUserPassword(
    userPasswordUpdate: UserPasswordUpdate,
    version = 1,
  ): AxiosPromise<NoContentResponse> {
    return axios.put(`/v${version}/user/password`, userPasswordUpdate);
  },
  getProviderBills(
    providerId: number,
    params?: GetProviderBillsParams,
    version = 1,
  ): AxiosPromise<BillsResponse> {
    return axios.get(`/v${version}/providers/${providerId}/bills`, {
      params,
    });
  },
  getProviderBill(
    providerId: number,
    billId: string,
    version = 1,
  ): AxiosPromise<BillDetailsResponse> {
    return axios.get(`/v${version}/providers/${providerId}/bills/${billId}`);
  },
  getProviderBillingConfiguration(
    providerId: number,
    version = 1,
  ): AxiosPromise<BillingConfigurationResponse> {
    return axios.get(
      `/v${version}/providers/${providerId}/billing-configuration`,
    );
  },
  putProviderBillingConfiguration(
    providerId: number,
    contactPerson: ContactPerson,
    version = 1,
  ): AxiosPromise<NoContentResponse> {
    return axios.put(
      `/v${version}/providers/${providerId}/billing-configuration`,
      contactPerson,
    );
  },
  downloadBill(
    providerId: number,
    billingDocumentId: string,
    version = 1,
  ): AxiosPromise<Blob> {
    return axios.get(
      `/v${version}/providers/${providerId}/bills/${billingDocumentId}/download`,
      {
        responseType: 'blob',
      },
    );
  },
});
