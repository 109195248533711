import { LanguageCode, LegalForm, LegalFormCode } from '@kaa/api/providers';
import { Format } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  SwFormGrid,
  SwInputTextField,
  SwSelectField,
} from '@kaa/ui-flanders/components';
import { Field } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RelativeAddModalfieldNames } from '../RelativeAddModal.constants';

type RelativeAddStepCommunicationProps = {
  legalForms: LegalForm[];
};

export const RelativeAddStepCommunication = ({
  legalForms,
}: RelativeAddStepCommunicationProps) => {
  const { t } = useTranslation();

  const languageOptions = Object.values(LanguageCode).filter(item => item != LanguageCode.EN)
    .reduce<
    Array<{
      value: LanguageCode;
      text: string;
    }>
  >((acc, language) => [...acc, { value: language, text: language }], []);

  const legalFormOptions = legalForms.reduce<
    Array<{ value: LegalFormCode; text: string }>
  >((acc, { code: value, name: text }) => [...acc, { value, text }], []);

  return (
    <fieldset>
      <SwFormGrid modStacked>
        <Field
          column={{ width: '12' }}
          labelColumn={{ width: '12' }}
          name={RelativeAddModalfieldNames.LANGUAGE}
          label={t(i18nKeys.relative.add.label.language)}
          options={languageOptions}
          component={SwSelectField}
        />

        <Field
          column={{ width: '12' }}
          labelColumn={{ width: '12' }}
          name={RelativeAddModalfieldNames.LEGAL_FORM}
          label={t(i18nKeys.relative.add.label.legalForm)}
          options={legalFormOptions}
          component={SwSelectField}
        />

        <Field
          column={{ width: '12' }}
          labelColumn={{ width: '12' }}
          name={RelativeAddModalfieldNames.PHONE_NUMBER}
          label={t(i18nKeys.general.label.phone)}
          component={SwInputTextField}
          parse={(value: string) => (value ? Format.phoneNumber(value) : '')}
          type="tel"
        />

        <Field
          column={{ width: '12' }}
          labelColumn={{ width: '12' }}
          name={RelativeAddModalfieldNames.MOBILE_PHONE_NUMBER}
          label={t(i18nKeys.general.label.mobilePhone)}
          component={SwInputTextField}
          parse={(value: string) =>
            value ? Format.mobilePhoneNumber(value) : ''
          }
          type="tel"
        />

        <Field
          column={{ width: '12' }}
          labelColumn={{ width: '12' }}
          name={RelativeAddModalfieldNames.EMAIL_ADDRESS}
          label={t(i18nKeys.general.label.emailAddress)}
          type="email"
          component={SwInputTextField}
        />

        <Field
          column={{ width: '12' }}
          labelColumn={{ width: '12' }}
          name={RelativeAddModalfieldNames.PUBLIC_WEBSITE}
          label={t(i18nKeys.general.label.website)}
          component={SwInputTextField}
        />
      </SwFormGrid>
    </fieldset>
  );
};
