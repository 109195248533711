import {
  ApiError,
  BadRequestResponse,
  CodeError,
  FieldValidationErrorCode,
  HttpError,
} from '@kaa/api/providers';
import {
  join,
  ValidationError,
  ValidatorFunction,
} from '@kaa/common/validation';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import { AlertType } from '@kaa/ui-flanders/components';
import { Omit } from '@kaa/ui-flanders/components/utilities';
import { FormikActions } from 'formik';
import i18n from 'i18next';
import get from 'lodash.get';
import set from 'lodash.set';
import { FORM_ERROR_BY_CODE } from './map-server-error-code-to-translation-key';

export const createValidatorWithServerErrorHandled = <T = any>(
  rules: { [key in keyof T]: ValidatorFunction | ValidatorFunction[] },
  validationError?: Partial<ValidationError>,
  serverErrorsSetInFormValuesFunc: (
    fieldName: string,
  ) => ValidatorFunction = serverErrorsSetInFormValues,
  autoHandleServerError = true,
) => {
  return (data = {}): { [key in keyof T]: ValidationError | false } => {
    const errors = Object.keys(rules).reduce((acc: any, key: string) => {
      // concat enables both functions and arrays of functions
      const rule = join(
        ([] as ValidatorFunction[]).concat(
          rules[key as keyof T],
          autoHandleServerError ? [serverErrorsSetInFormValuesFunc(key)] : [],
        ),
      );
      const error = rule(get(data, key.split('.')), data) as ValidationError;

      return error
        ? { ...acc, [key as keyof T]: { ...error, ...validationError } }
        : acc;
    }, {} as any);
    return errors;
  };
};

function identity<T>(
  value: { [x in keyof T]: FieldValidationErrorCode[] },
): { [x in keyof T]: FieldValidationErrorCode[] } {
  return value;
}

/**
 * This functions helps to handle all the server error
 * If we have validation errors, it will set the errors in form value and display the correct error message
 * else we display the error message for the CodeError
 * @param error
 * @param formikActions
 * @param mapperForErrorMap
 */
export function handleApiError<T>(
  error: HttpError | ApiError | BadRequestResponse | null,
  { setSubmitting, setStatus, setFieldValue }: FormikActions<T>,
  mapperForErrorMap: (
    value: { [x in keyof T]: FieldValidationErrorCode[] },
  ) => { [x in keyof T]: FieldValidationErrorCode[] } = identity,
): boolean {
  if (!error) {
    return false;
  }
  switch (error.code) {
    case CodeError.BUSINESS_SERVICE_ERROR: {
      setSubmitting(false);
      const businessError = (error as BadRequestResponse)?.error as
        | keyof typeof i18nKeys.errors.server.business
        | undefined;

      const code = businessError
        ? i18nKeys.errors.server.business[businessError]
        : i18nKeys.errors.server.DEFAULT;

      setStatus({
        msg: i18n.t(code || i18nKeys.errors.server.DEFAULT),
        type: AlertType.ERROR,
      });
      return true;
    }
    case CodeError.VALIDATION_EXCEPTION: {
      setSubmitting(false);
      setServerErrorInFormValues(
        setFieldValue,
        mapperForErrorMap(getErrorFromApiErrorField<T>(error as ApiError)),
      );
      return true;
    }
    default: {
      setSubmitting(false);
      type ErrorCode = keyof Omit<
        Omit<typeof i18nKeys.errors.server, 'form' | 'maintenance'>,
        'business'
      >;

      const errorCode = error.code as ErrorCode;
      setStatus({
        msg: i18n.t(
          i18nKeys.errors.server[errorCode] || i18nKeys.errors.server.DEFAULT,
        ),
        type: AlertType.ERROR,
      });
      return true;
    }
  }
}

/**
 * This functions helps to set the server error in form values and
 * keep the state when the form re-render
 * The path in form values is : `errors.server.${fieldName}.${errorCode}`
 * This validator is automatically set in the createValidator function
 * @param fieldName
 */
export function serverErrorsSetInFormValues(
  fieldName: string,
): ValidatorFunction {
  return (value: string, values: any): ValidationError | false => {
    const firstError = Object.values(FieldValidationErrorCode)
      .map((errorCode: FieldValidationErrorCode) => {
        return get(
          values,
          getServerErrorPathInFormValuesError(fieldName, errorCode),
        )
          ? FORM_ERROR_BY_CODE[errorCode] || FORM_ERROR_BY_CODE.DEFAULT
          : null;
      })
      .filter(Boolean)[0];

    if (firstError) {
      return firstError;
    }
    return false;
  };
}

export const getServerErrorPathInFormValuesError = (
  fieldName: string,
  errorCode: string,
) => {
  return `errors.server.${fieldName}.${errorCode}`;
};

/**
 * Allows to create a error object for Formik based ApiErrorField fieldName
 * This object will used with serverErrorsSetInFormValues validation to
 * set the correct error in form value. The object used with the
 * setErrorInFormValues to set in the field value the correct server
 * error for the correct field name
 * @param error
 */
export function getErrorFromApiErrorField<
  T,
  U extends string = FieldValidationErrorCode,
  A extends { fieldName: string; errors: U[] } = {
    fieldName: string;
    errors: U[];
  }
>(error?: { validations?: A[] }): { [x in keyof T]: U[] } {
  if (error && error.validations) {
    return (error.validations.reduce((obj, apiErrorField: A) => {
      const { fieldName, errors } = apiErrorField;
      const firstErrorCode = errors[0];
      if (firstErrorCode) {
        return {
          ...obj,
          [fieldName]: errors,
        };
      }
      return obj;
    }, {}) as any) as { [x in keyof T]: U[] };
  }
  return ({} as any) as { [x in keyof T]: U[] };
}

/**
 * This function will take the setFieldValue and errors (generated by getErrorFromApiErrorField)
 * then compute the path to set the error in form values and set it thanks to setFieldValue
 * @param setFieldValue
 * @param errors
 */
export function setServerErrorInFormValues<T>(
  setFieldValue: (field: string, value: any) => void,
  errors: { [x in keyof T]: FieldValidationErrorCode[] },
) {
  if (!setFieldValue && !errors) {
    return;
  }

  setFieldValue('errors.server', mapperServerError(errors).errors.server);
}

export function mapperServerError<
  T,
  U extends string = FieldValidationErrorCode
>(
  errors: { [x in keyof T]: U[] },
): { errors: { server: { [key in keyof T]: true } } } {
  return Object.entries<U[]>(errors).reduce((acc, [key, error]) => {
    if (!error || !error.length) {
      return acc;
    }
    return set(acc, getServerErrorPathInFormValuesError(key, error[0]), true);
  }, ({} as any) as { errors: { server: { [key in keyof T]: true } } });
}

// This function force form validation after reset with custom errors
export function resetFormWithErrors<T>(
  formikActions: FormikActions<T>,
  formikData: T,
  errors: { server: { [key in keyof T]: true } },
) {
  const {
    setSubmitting,
    resetForm,
    validateForm,
    setFieldTouched,
  } = formikActions;

  const data = {
    ...formikData,
    errors,
  };

  resetForm(data);
  setSubmitting(false);
  validateForm(data);

  const { server, ...rest } = errors;

  Object.keys(server)
    .concat(Object.keys(rest))
    .forEach((key) => {
      setFieldTouched(key, true);
    });
}
