import { WorkersFileStatusResponseData } from '@kaa/api/providers';
import { SwLoader } from '@kaa/ui-flanders/components';
import React, { PropsWithChildren } from 'react';

type AddWorkerListModalProcessingProps = {
  fileStatus: WorkersFileStatusResponseData;
};

export const AddWorkerListModalProcessing = ({
  children,
  fileStatus,
}: PropsWithChildren<AddWorkerListModalProcessingProps>) => (
  <>
    {children}
    <SwLoader
      message={`Loading: ${Math.floor(
        (fileStatus.processedRows / fileStatus.totalRows) * 100,
      )}%`}
    />
  </>
);
