import { ServicesFileStatusResponseData } from '@kaa/api/providers';
import { SwLoader } from '@kaa/ui-flanders/components';
import React, { PropsWithChildren } from 'react';

type PrestationImportModalProcessingProps = {
  fileStatus: ServicesFileStatusResponseData;
};

export const PrestationImportModalProcessing = ({
  children,
  fileStatus,
}: PropsWithChildren<PrestationImportModalProcessingProps>) => (
  <>
    {children}
    <SwLoader
      message={`Loading: ${Math.floor(
        (fileStatus.processedRows / fileStatus.totalRows) * 100,
      )}%`}
    />
  </>
);
