import { GetSubsidiesParams, Relative } from '@kaa/api/providers';
import { useLuxon } from '@kaa/common/utils';
import { maxLength, required } from '@kaa/common/validation';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AlertType,
  getAlertPropsByType,
  Icon,
  SwActionGroup,
  SwAlert,
  SwButton,
  SwColumn,
  SwForm,
  SwFormGrid,
  SwFormInput,
  SwFormMessageLabel,
  SwFormSubmitMessage,
  SwInputCalendarField,
  SwInputCalendarFieldFormatDate,
  SwInputCalendarFieldParseDate,
  SwInputField,
  SwInputTextField,
  SwLink,
  SwPill,
  SwSelectField,
} from '@kaa/ui-flanders/components';
import { Field, Formik, FormikActions } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { useSelectedProviderState } from '../../../../utils';
import { createValidatorWithServerErrorHandled } from '../../../../utils/validation';
import {
  NOW_DATE,
  PAST_DATE_LIMIT,
  RemittancesSubsidiesFormFieldNames,
  RemittancesSubsidiesFormType,
  SERVICE_MONTHS_PERIOD_LIMIT,
} from '../RemittancesSubsidies.constants';
import {
  getIsBlockedOptions,
  getMaxEndDate,
  getMaxStartDate,
  getMinEndDate,
  getMinStartDate,
  getRelativeOptions,
  getSupportTypeOptions,
} from '../RemittancesSubsidies.utils';

type RemittancesSubsidiesFormProps = {
  relatives: Relative[];
  isSubsidiary: boolean;
  parameters: GetSubsidiesParams;
  relativeId?: number | string;
  submit: (
    params: RemittancesSubsidiesFormType,
    formikActions: FormikActions<RemittancesSubsidiesFormType>,
  ) => void;
  reset: (
    resetForm: (nextValues?: RemittancesSubsidiesFormType | undefined) => void,
  ) => void;
};

const checkDatesAreInSequence = (val: string[]) => {
  const sortedArray = val.sort((a, b) => {
    return DateTime.fromISO(a).valueOf() - DateTime.fromISO(b).valueOf();
  });
  for (let i = 0; i < sortedArray.length - 1; i += 1) {
    const time1 = DateTime.fromISO(sortedArray[i])
      .plus({ month: 1 })
      .startOf('month')
      .valueOf();
    const time2 = DateTime.fromISO(sortedArray[i + 1])
      .startOf('month')
      .valueOf();
    if (time1 !== time2) {
      return false;
    }
  }
  return true;
};

export const RemittancesSubsidiesForm = ({
  relatives,
  isSubsidiary,
  parameters,
  relativeId,
  submit,
  reset,
}: RemittancesSubsidiesFormProps) => {
  const { t } = useTranslation();
  const { DateTime } = useLuxon();
  const provider = useSelectedProviderState();

  const {
    onAllRelatives,
    serviceMonthsPeriod,
    fromPaymentDate,
    toPaymentDate,
    sort,
    pageNumber,
    pageSize,
    ...rest
  } = parameters;

  const initialValues = {
    ...rest,
    [RemittancesSubsidiesFormFieldNames.RELATIVE_ID]: relativeId || '',
    [RemittancesSubsidiesFormFieldNames.SERVICE_MONTH_PERIOD]: '',
    [RemittancesSubsidiesFormFieldNames.SERVICE_MONTHS_PERIOD]: serviceMonthsPeriod,
    [RemittancesSubsidiesFormFieldNames.START_DATE]: fromPaymentDate,
    [RemittancesSubsidiesFormFieldNames.END_DATE]: toPaymentDate,
  } as RemittancesSubsidiesFormType;

  const validate = createValidatorWithServerErrorHandled({
    [RemittancesSubsidiesFormFieldNames.SERVICE_MONTHS_PERIOD]: [
      required,
      (val) => {
        if (val?.length > 1 && !checkDatesAreInSequence(val)) {
          return {
            defaultMessage: 'We currently accept only dates in Sequence',
            id: i18nKeys.errors.form.sequenceDates,
          };
        }
        return false;
      },
    ],
    [RemittancesSubsidiesFormFieldNames.KEYWORD]: [maxLength(18)],
  });

  return (
    <SwColumn
      className="vl-u-bg-alt vl-u-spacer--medium"
      style={{ padding: '2rem' }}
    >
      <Formik<RemittancesSubsidiesFormType>
        initialValues={initialValues}
        validate={validate}
        onSubmit={submit}
        enableReinitialize
        render={({
          handleSubmit,
          dirty,
          isSubmitting,
          values,
          resetForm,
          setFieldValue,
          errors,
          isValid,
        }) => {
          const serviceMonthsPeriodValue =
            values[RemittancesSubsidiesFormFieldNames.SERVICE_MONTHS_PERIOD];
          const serviceMonthsError =
            errors[RemittancesSubsidiesFormFieldNames.SERVICE_MONTHS_PERIOD];
          return (
            <SwForm onSubmit={handleSubmit}>
              <SwFormGrid
                className="vl-u-display-flex vl-u-spacer--small"
                modStacked
              >
                {!isSubsidiary ? (
                  <SwColumn width="4" widthM="6" widthS="12">
                    <Field
                      name={RemittancesSubsidiesFormFieldNames.RELATIVE_ID}
                      label={t(
                        i18nKeys.remittances.reimbursement.search.form[
                          RemittancesSubsidiesFormFieldNames.RELATIVE_ID
                        ],
                      )}
                      options={getRelativeOptions(relatives)}
                      component={SwSelectField}
                      column={{ width: '12' }}
                      labelColumn={{ width: '12' }}
                    />
                  </SwColumn>
                ) : (
                  <SwColumn width="4" widthM="6" widthS="12">
                    <SwFormMessageLabel
                      htmlFor={RemittancesSubsidiesFormFieldNames.RELATIVE_ID}
                    >
                      {t(
                        i18nKeys.remittances.reimbursement.search.form[
                          RemittancesSubsidiesFormFieldNames.RELATIVE_ID
                        ],
                      )}
                    </SwFormMessageLabel>
                    <SwFormInput>
                      <SwInputField
                        id={RemittancesSubsidiesFormFieldNames.RELATIVE_ID}
                        type="text"
                        value={`${provider.name} - ${provider.id}`}
                        modDisabled
                        modBlock
                      />
                    </SwFormInput>
                  </SwColumn>
                )}

                <SwColumn width="4" widthM="6" widthS="12">
                  <Field
                    name={RemittancesSubsidiesFormFieldNames.KEYWORD}
                    label={t(i18nKeys.general.search)}
                    placeholder={t(i18nKeys.general.placeholder.search)}
                    id={RemittancesSubsidiesFormFieldNames.KEYWORD}
                    component={SwInputTextField}
                    column={{ width: '12' }}
                    labelColumn={{ width: '12' }}
                    maxLength="18"
                  />
                </SwColumn>
                <SwColumn width="4" widthM="12">
                  <fieldset>
                    <legend
                      className="vl-form__label"
                      style={{ lineHeight: '1.5em' }}
                    >
                      {t(i18nKeys.remittances.reimbursement.search.form.period)}
                    </legend>
                    <SwFormGrid modStacked>
                      <SwColumn width="6" widthS="12">
                        <Field
                          name={RemittancesSubsidiesFormFieldNames.START_DATE}
                          parse={SwInputCalendarFieldParseDate}
                          format={SwInputCalendarFieldFormatDate}
                          placeholder={t(
                            i18nKeys.general.placeholder.datePicker.start,
                          )}
                          component={SwInputCalendarField}
                          column={{ width: '12' }}
                          labelColumn={{ width: '12' }}
                          minDate={getMinStartDate(values)}
                          maxDate={getMaxStartDate(values)}
                        />
                      </SwColumn>
                      <SwColumn width="6" widthS="12">
                        <Field
                          name={RemittancesSubsidiesFormFieldNames.END_DATE}
                          parse={SwInputCalendarFieldParseDate}
                          format={SwInputCalendarFieldFormatDate}
                          placeholder={t(
                            i18nKeys.general.placeholder.datePicker.end,
                          )}
                          component={SwInputCalendarField}
                          column={{ width: '12' }}
                          labelColumn={{ width: '12' }}
                          minDate={getMinEndDate(values)}
                          maxDate={getMaxEndDate(values)}
                        />
                      </SwColumn>
                    </SwFormGrid>
                  </fieldset>
                </SwColumn>
                <SwColumn width="4" widthM="12">
                  <Field
                    modMonthSelect
                    column={{ width: '12' }}
                    labelColumn={{ width: '12' }}
                    name={
                      RemittancesSubsidiesFormFieldNames.SERVICE_MONTH_PERIOD
                    }
                    label={t(
                      i18nKeys.remittances.reimbursement.search.form[
                        RemittancesSubsidiesFormFieldNames.SERVICE_MONTH_PERIOD
                      ],
                    )}
                    component={SwInputCalendarField}
                    visualFormat="m/Y"
                    minDate={PAST_DATE_LIMIT.startOf('month').toJSDate()}
                    maxDate={NOW_DATE.endOf('month').toJSDate()}
                    placeholder="Select months"
                    modDisabled={
                      serviceMonthsPeriodValue.length >=
                      SERVICE_MONTHS_PERIOD_LIMIT
                    }
                    inputOnChange={(dates: Date[]) => {
                      // check date validity
                      if (!DateTime.fromJSDate(dates[0]).isValid) {
                        return;
                      }
                      // todo make this check an option of the calendar (maxSelectableMonths)
                      if (
                        serviceMonthsPeriodValue.length >=
                        SERVICE_MONTHS_PERIOD_LIMIT
                      ) {
                        return;
                      }
                      const newValues = [
                        ...serviceMonthsPeriodValue,
                        DateTime.fromJSDate(dates[0]).toISODate(),
                      ];

                      setFieldValue(
                        RemittancesSubsidiesFormFieldNames.SERVICE_MONTHS_PERIOD,
                        newValues.filter(
                          (d, index) => newValues.indexOf(d) === index,
                        ),
                      );
                    }}
                    format={(date: string) =>
                      date
                        ? [
                            DateTime.fromISO(date)
                              .startOf('month')
                              .toJSDate(),
                          ]
                        : undefined
                    }
                    parse={(dates: Date[]) =>
                      dates && dates.length
                        ? DateTime.fromJSDate(dates[0])
                            .startOf('month')
                            .toISODate()
                        : undefined
                    }
                  />
                </SwColumn>
                <SwColumn width="4" widthM="6" widthS="12">
                  <Field
                    name={RemittancesSubsidiesFormFieldNames.SUPPORT_TYPE}
                    label={t(
                      i18nKeys.remittances.reimbursement.search.form[
                        RemittancesSubsidiesFormFieldNames.SUPPORT_TYPE
                      ],
                    )}
                    component={SwSelectField}
                    options={getSupportTypeOptions(t)}
                    column={{ width: '12' }}
                    labelColumn={{ width: '12' }}
                  />
                </SwColumn>
                <SwColumn width="4" widthM="6" widthS="12">
                  <Field
                    name={RemittancesSubsidiesFormFieldNames.IS_BLOCKED}
                    label={t(
                      i18nKeys.remittances.reimbursement.search.form[
                        RemittancesSubsidiesFormFieldNames.IS_BLOCKED
                      ],
                    )}
                    component={SwSelectField}
                    options={getIsBlockedOptions(t)}
                    column={{ width: '12' }}
                    labelColumn={{ width: '12' }}
                  />
                </SwColumn>
              </SwFormGrid>
              <SwFormGrid modStacked className="vl-u-display-flex">
                <SwColumn
                  width="4"
                  widthM="6"
                  widthS="12"
                  style={{ alignSelf: 'flex-end' }}
                >
                  <div>
                    {serviceMonthsPeriodValue.map((date: string, i: number) => {
                      const serviceMonthDateTime = DateTime.fromISO(date);
                      const dateString = serviceMonthDateTime.toFormat(
                        'LL/yyyy',
                      );
                      return (
                        <SwPill
                          key={`${dateString}${i}`}
                          id={`${RemittancesSubsidiesFormFieldNames.SERVICE_MONTHS_PERIOD}-${dateString}`}
                          className="vl-u-spacer--xsmall"
                          style={{
                            marginRight: '1rem',
                            backgroundColor: '#fff',
                          }}
                          aria-label={`${t(
                            i18nKeys.general.cta.delete,
                          )} ${dateString}`}
                          modClosable={
                            values[
                              RemittancesSubsidiesFormFieldNames
                                .SERVICE_MONTHS_PERIOD
                            ].length > 1
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            if (
                              values[
                                RemittancesSubsidiesFormFieldNames
                                  .SERVICE_MONTHS_PERIOD
                              ].length > 1
                            ) {
                              setFieldValue(
                                RemittancesSubsidiesFormFieldNames.SERVICE_MONTHS_PERIOD,
                                values[
                                  RemittancesSubsidiesFormFieldNames
                                    .SERVICE_MONTHS_PERIOD
                                ].filter(
                                  (d) => d !== serviceMonthDateTime.toISODate(),
                                ),
                              );
                            }
                          }}
                        >
                          {dateString}
                        </SwPill>
                      );
                    })}
                  </div>
                  <SwActionGroup modCollapseS>
                    <SwButton
                      type="submit"
                      icon={Icon.SYNCHRONIZE}
                      modLoading={isSubmitting}
                      modDisabled={!dirty || !isValid}
                      modIconBefore
                    >
                      {t(i18nKeys.general.search)}
                    </SwButton>
                    <SwLink
                      type="button"
                      onClick={() => {
                        reset(resetForm);
                      }}
                    >
                      {t(i18nKeys.general.label.reset)}
                    </SwLink>
                  </SwActionGroup>
                </SwColumn>
                {serviceMonthsError && (
                  <SwColumn>
                    <SwAlert modSmall {...getAlertPropsByType(AlertType.ERROR)}>
                      {t(i18nKeys.errors.form.sequenceDates)}
                    </SwAlert>
                  </SwColumn>
                )}
                <SwFormSubmitMessage />
              </SwFormGrid>
            </SwForm>
          );
        }}
      />
    </SwColumn>
  );
};
