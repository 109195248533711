import {
  RegionCode,
  ServicesOverview,
  VideoTopicReference,
} from '@kaa/api/providers';
import { useAsyncCallback, useEventListener } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  Icon,
  SwButton,
  SwColumn,
  SwContainer,
  SwFetchErrorMessage,
  SwGrid,
  SwModal,
  SwTabs,
  SwTitle,
  Tab,
} from '@kaa/ui-flanders/components';
import get from 'lodash.get';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StorageKeys } from '../../../../common/enums';
import { getConfig } from '../../../common/config';
import { PageHeader, TutorialVideoLink } from '../../components';
import { Modals } from '../../constants';
import { dataTest } from '../../datatest/keys';
import { useApi, useSelectedProviderState } from '../../utils';
import {
  openModalWithPageView,
  sendPageViewEvent,
} from '../../utils/google-analytics';
import { PrestationCreationModal } from './components/creation/PrestationCreationModal';
import { PrestationExportModal } from './components/export/PrestationExportModal';
import { PrestationImportModal } from './components/import/PrestationImportModal';
import { PrestationsCard } from './components/PrestationsCard';
import { PrestationsSearchList } from './search/PrestationsSearchList';
import { PrestationsSearchWorkers } from './search/PrestationsSearchWorkers';
import { ShiftBy } from '../../components/shiftBy/ShiftBy';

export const PrestationsScreen = () => {
  const { t } = useTranslation();

  const { providers } = useApi();
  const provider = useSelectedProviderState();

  const regionCode = get(getConfig(), 'buildConfig.regionCode') as RegionCode;

  const [overview, setOverview] = useState<ServicesOverview>({
    totalOfServices: 0,
    totalOfServicesForHistory: 0,
  });

  const [selectedTab, setSelectedTab] = useState(
    localStorage.getItem(StorageKeys.LAST_SELECTED_PRESTATIONS_TAB) || '1',
  );

  const tabDefault = {
    tagName: 'a',
    className: 'vl-link vl-u-display-inline-flex',
  };

  const prestationsTabs: Tab[] = [
    {
      ...tabDefault,
      uid: '1',
      hash: '#workers',
      header: t(i18nKeys.prestations.search.tabs.worker),
      'data-testid': dataTest.prestations.tabWorker,
      onClick: () => {
        localStorage.setItem(StorageKeys.LAST_SELECTED_PRESTATIONS_TAB, '1');
        sendPageViewEvent({ pathname: '/workers' });
      },
    },
    {
      ...tabDefault,
      uid: '2',
      hash: '#list',
      header: t(i18nKeys.prestations.search.tabs.list),
      'data-testid': dataTest.prestations.tabList,
      onClick: () => {
        localStorage.setItem(StorageKeys.LAST_SELECTED_PRESTATIONS_TAB, '2');
        sendPageViewEvent({ pathname: '/list' });
      },
    },
  ].map((e) => ({
    ...e,
    isActive: e.uid === selectedTab,
  }));

  const [
    {
      value: prestationsData,
      loading: prestationsDataLoading,
      error: prestationsDataError,
    },
    getPrestationsData,
  ] = useAsyncCallback(
    async () => {
      const [providerRelatives, servicesOverview] = await Promise.all([
        providers.getProviderRelatives(provider.id),
        providers.getServicesOverview(provider.id),
      ]);

      const {
        data: {
          data: { headquarter, subsidiaries },
        },
      } = providerRelatives;

      const {
        data: { data: newOverview },
      } = servicesOverview;

      return {
        overview: newOverview,
        relatives: [
          headquarter,
          ...subsidiaries.filter(({ isActive }) => isActive),
        ],
      };
    },
    [providers],
    { loading: true },
  );

  useEffect(() => {
    const currentTab = prestationsTabs.find((tab) => {
      return tab.hash === window.location.hash;
    });

    if (currentTab) {
      setSelectedTab(currentTab.uid);
    }
  }, [setSelectedTab, prestationsTabs]);

  useEventListener(
    'hashchange',
    () => {
      const currentTab = prestationsTabs.find((tab) => {
        return tab.hash === window.location.hash;
      });

      if (currentTab) {
        setSelectedTab(currentTab.uid);
      }
    },
    window,
  );

  useEffect(() => {
    getPrestationsData();
  }, [getPrestationsData]);

  useEffect(() => {
    if (prestationsData) {
      const { overview: initOverview } = prestationsData;
      setOverview(initOverview);
    }
  }, [prestationsData]);

  if (prestationsDataLoading && !prestationsData) {
    return <SwContainer loading />;
  }

  if (prestationsDataError || !prestationsData) {
    return (
      <SwContainer error>
        <SwFetchErrorMessage
          onClick={() => {
            getPrestationsData();
          }}
        />
      </SwContainer>
    );
  }

  const { relatives } = prestationsData;

  const onSuccess = () => getPrestationsData();

  const updateOverview = (newOverview: ServicesOverview) =>
    setOverview(() => newOverview);

  return (
    <SwContainer>
      <SwGrid modStacked>
        <SwColumn>
          <PageHeader
            title={t(i18nKeys.navigation.prestations)}
            className="vl-u-spacer"
          />
          <ShiftBy y={-30}>
            <TutorialVideoLink topic={VideoTopicReference.PRESTATIONS} />
          </ShiftBy>
          <SwGrid
            modVStretched
            style={{ width: '100%', justifyContent: 'flex-start' }}
          >
            <PrestationsCard
              title={t(i18nKeys.prestations.cards.add.title)}
              text={t(i18nKeys.prestations.cards.add.content)}
              icon={
                regionCode === RegionCode.BE_VLG ? Icon.FILE_NEW : Icon.WORKSADD
              }
            >
              <SwButton
                data-testid={
                  dataTest.remittances.paper.deliverySlip.openTemplateModal
                }
                modBlock
                modNarrow
                onClick={() => {
                  openModalWithPageView(
                    Modals.PRESTATIONS_ADD_PRESTATION_MODAL,
                  );
                }}
              >
                {t(i18nKeys.prestations.cards.add.cta)}
              </SwButton>
            </PrestationsCard>
            <PrestationsCard
              title={t(i18nKeys.prestations.cards.addList.title)}
              text={t(i18nKeys.prestations.cards.addList.content)}
              icon={
                regionCode === RegionCode.BE_VLG
                  ? Icon.FILE_UPLOAD
                  : Icon.UPLOAD_LISTFILE
              }
            >
              <SwButton
                data-testid={
                  dataTest.remittances.paper.deliverySlip.openTemplateModal
                }
                modBlock
                modNarrow
                onClick={() => {
                  openModalWithPageView(
                    Modals.PRESTATIONS_IMPORT_LIST_PRESTATION_MODAL,
                  );
                }}
              >
                {t(i18nKeys.prestations.cards.addList.cta)}
              </SwButton>
            </PrestationsCard>
            <PrestationsCard
              title={t(i18nKeys.prestations.cards.exportList.title)}
              text={t(i18nKeys.prestations.cards.exportList.content)}
              icon={
                regionCode === RegionCode.BE_VLG
                  ? Icon.FILE_DOWNLOAD
                  : Icon.DOWNLOAD_LISTFILE
              }
            >
              <SwButton
                data-testid={
                  dataTest.remittances.paper.deliverySlip.openTemplateModal
                }
                modBlock
                modNarrow
                onClick={() => {
                  openModalWithPageView(
                    Modals.PRESTATIONS_EXPORT_LIST_PRESTATION_MODAL,
                  );
                }}
              >
                {t(i18nKeys.prestations.cards.exportList.cta)}
              </SwButton>
            </PrestationsCard>
          </SwGrid>
        </SwColumn>
        <SwColumn>
          <SwTitle tagName="h2">{t(i18nKeys.prestations.search.title)}</SwTitle>
        </SwColumn>
        {!prestationsDataLoading && (
          <>
            {selectedTab === '1' && (
              <PrestationsSearchWorkers
                overview={overview}
                setOverview={updateOverview}
                relatives={relatives}
              >
                <SwTabs tabs={prestationsTabs} />
              </PrestationsSearchWorkers>
            )}
            {selectedTab === '2' && (
              <PrestationsSearchList
                overview={overview}
                setOverview={updateOverview}
                relatives={relatives}
              >
                <SwTabs tabs={prestationsTabs} />
              </PrestationsSearchList>
            )}
          </>
        )}
      </SwGrid>
      <SwModal
        id={Modals.PRESTATIONS_ADD_PRESTATION_MODAL}
        closable
        component={PrestationCreationModal}
        confirmCloseMessage={t(i18nKeys.dialog.confirmClose.message)}
        onSuccess={onSuccess}
      />
      <SwModal
        id={Modals.PRESTATIONS_EXPORT_LIST_PRESTATION_MODAL}
        closable
        component={PrestationExportModal}
        confirmCloseMessage={t(i18nKeys.dialog.confirmClose.message)}
        onSuccess={onSuccess}
      />
      <SwModal
        id={Modals.PRESTATIONS_IMPORT_LIST_PRESTATION_MODAL}
        closable
        component={PrestationImportModal}
        confirmCloseMessage={t(i18nKeys.dialog.confirmClose.message)}
        onSuccess={onSuccess}
      />
    </SwContainer>
  );
};
