import {
  GetWorkersServicesParams,
  PaginationDirectives,
  ProviderType,
  Relative,
  ServicesOverview,
  ValidateWorkersServicesParams,
  WorkersServicesResponseDataDataWorkersItem,
} from '@kaa/api/providers';
import { httpTo } from '@kaa/api/providers/utilities';
import { useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AlertType,
  getAlertPropsByType,
  SwAlert,
  SwButton,
  SwColumn,
  SwGrid,
  SwLoader,
  SwModal,
  SwPaginator,
} from '@kaa/ui-flanders/components';
import { FormikActions } from 'formik';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modals } from '../../../constants';
import { getQueryParams, QueryParams, Routes } from '../../../routes';
import { useApi, useSelectedProviderState } from '../../../utils';
import {
  EventAction,
  EventCategory,
  EventLabel,
  openModalWithPageView,
  sendCustomInteractionToGTM,
  sendSearchToGTM,
} from '../../../utils/google-analytics';
import { PrestationsForm } from '../components/PrestationsForm';
import { getServiceActiveStatusOptions } from '../components/PrestationsForm.utils';
import { PrestationsOverview } from '../components/PrestationsOverview';
import { getSearchOverview } from '../components/PrestationsOverview.utils';
import { PrestationsSearchValidateModal } from '../components/validate/PrestationsSearchValidateModal';
import { PRESTATIONS_SEARCH_FILTERS } from '../PrestationsScreen.constants';
import {
  OverviewFilter,
  PrestationsSearchFilters,
  ValidateStatus,
} from '../PrestationsScreen.types';
import {
  getInitialParameters,
  getWorkersServicesParamsForFilter,
} from '../PrestationsScreen.utils';
import { PrestationsSearchWorkersWorker } from './PrestationsSearchWorkersWorker';

const PRESTATIONS_SEARCH_PAGE_SIZE = 10;

type PrestationsSearchWorkersProps = {
  overview: ServicesOverview;
  setOverview: (overview: ServicesOverview) => void;
  relatives: Relative[];
};

export const PrestationsSearchWorkers = ({
  overview,
  setOverview,
  relatives,
  children,
}: PropsWithChildren<PrestationsSearchWorkersProps>) => {
  const { t } = useTranslation();
  const { providers } = useApi();
  const provider = useSelectedProviderState();

  const isSubsidiary = provider.type === ProviderType.SUBSIDIARY;

  const filter = getQueryParams<PrestationsSearchFilters>(QueryParams.FILTER);

  const {
    initialParameters,
    paginationInitialParameters,
  } = getInitialParameters(PRESTATIONS_SEARCH_PAGE_SIZE);

  const [parameters, setParameters] = useState<GetWorkersServicesParams>(
    filter && PRESTATIONS_SEARCH_FILTERS[filter]
      ? {
          ...getWorkersServicesParamsForFilter(
            PRESTATIONS_SEARCH_FILTERS[filter],
            PRESTATIONS_SEARCH_PAGE_SIZE,
            initialParameters,
          ),
          ...paginationInitialParameters,
        }
      : initialParameters,
  );

  const [totalOfPendingServices, setTotalOfPendingServices] = useState<
    number | undefined
  >();

  const [services, setWorkersServices] = useState<
    WorkersServicesResponseDataDataWorkersItem[] | undefined
  >();

  const [pagination, setPagination] = useState<
    PaginationDirectives['paginationDirectives']
  >();

  const [validateStatus, setValidateStatus] = useState<ValidateStatus>();

  const resetPrestationForm = () => {
    setParameters(() => initialParameters);
  };

  const applyFilter = (overviewFilter: OverviewFilter) => {
    setParameters(({ bookmark, ...currentParameters }) => ({
      ...getWorkersServicesParamsForFilter(
        overviewFilter,
        PRESTATIONS_SEARCH_PAGE_SIZE,
        currentParameters,
      ),
      ...paginationInitialParameters,
    }));
  };

  const [
    { value: servicesResponse, loading: servicesLoading },
    getServices,
  ] = useAsyncCallback(
    async (servicesParams: GetWorkersServicesParams) =>
      (await providers.getWorkersServices(provider.id, servicesParams)).data,
    [providers],
    { loading: true },
  );

  useEffect(() => {
    getServices(parameters);
  }, [getServices, parameters]);

  useEffect(() => {
    if (servicesResponse) {
      const {
        paginationDirectives,
        data: { totalOfPendingServices: total, workers },
      } = servicesResponse;

      setWorkersServices(() => workers);
      setTotalOfPendingServices(() => total);

      setPagination(() => paginationDirectives);
    }
  }, [servicesResponse]);

  const [, submitSearch] = useAsyncCallback(
    async (
      { bookmark, ...servicesParams }: GetWorkersServicesParams,
      formikActions: FormikActions<GetWorkersServicesParams>,
    ) => {
      const { setSubmitting, resetForm } = formikActions;

      setSubmitting(false);
      resetForm(servicesParams);

      setParameters(() => ({
        ...servicesParams,
        ...paginationInitialParameters,
      }));

      const {
        workerNameOrNiss, // Sensitive info we can't log due to GDPR
        customerNameOrContractId, // Sensitive info we can't log due to GDPR
        ...workerServiceParamsWithoutSensitiveInfo
      } = servicesParams;

      sendSearchToGTM(
        Routes.PRESTATIONS,
        JSON.stringify(workerServiceParamsWithoutSensitiveInfo),
      );

      return {
        formikActions,
        servicesParams,
      };
    },
    [setParameters],
  );

  const [
    {
      value: validateWorkersServicesData,
      loading: validateWorkersServicesLoading,
    },
    validateWorkersServices,
  ] = useAsyncCallback(
    async ({
      sort,
      pageNumber,
      pageSize,
      workerId,
      searchedProviderId,
      ...validateWorkersServicesParams
    }: GetWorkersServicesParams) => {
      setValidateStatus(undefined);

      const [error, response] = await httpTo(
        providers.validateWorkersServices(
          Number(searchedProviderId || provider.id),
          validateWorkersServicesParams as ValidateWorkersServicesParams,
        ),
      );

      return {
        response: response?.data?.data,
        error,
      };
    },
    [providers],
    { loading: false, value: undefined },
  );

  useEffect(() => {
    if (validateWorkersServicesData) {
      const { response, error } = validateWorkersServicesData;

      if (error || !response) {
        setValidateStatus({
          type: AlertType.ERROR,
          title: t(i18nKeys.general.label.error),
          msg: t(
            i18nKeys.prestations.modals.validate.alert.validateAllForWorkers
              .error,
          ),
        });
        return;
      }

      const { overview: newOverview, numberOfServicesValidated } = response;

      setValidateStatus({
        type: AlertType.SUCCESS,
        title: t(i18nKeys.general.label.success),
        msg: t(
          i18nKeys.prestations.modals.validate.alert.validateAllForWorkers
            .success,
          { count: numberOfServicesValidated },
        ),
      });

      setOverview(newOverview);

      setParameters(({ bookmark, ...currentParameters }) => ({
        ...currentParameters,
        ...paginationInitialParameters,
      }));

      sendCustomInteractionToGTM(
        EventCategory.PRESTATIONS,
        EventAction.CLICK,
        EventLabel.PRESTATIONS_VALIDATE_ALL_OF_ALL,
      );
    }
  }, [validateWorkersServicesData]);

  const { totalOfServices } = overview;

  return (
    <SwColumn width="12" widthS="12">
      <SwGrid modStacked>
        {!validateWorkersServicesLoading && totalOfServices > 0 && (
          <PrestationsOverview
            overview={getSearchOverview(overview)}
            applyFilter={applyFilter}
            route={Routes.PRESTATIONS}
            title={t(i18nKeys.prestations.search.alert.title, {
              count: totalOfServices,
            })}
          />
        )}
        <SwColumn>
          <PrestationsForm
            resetPrestationForm={resetPrestationForm}
            isLoading={servicesLoading}
            initialParameters={parameters}
            isSubsidiary={isSubsidiary}
            relatives={relatives}
            submit={submitSearch}
            voucherStatusOptions={getServiceActiveStatusOptions(t)}
          />
        </SwColumn>
        {!!validateStatus && (
          <SwColumn>
            <SwAlert
              {...getAlertPropsByType(validateStatus.type)}
              title={validateStatus.title}
              close={() => setValidateStatus(undefined)}
              closable
              modSmall
            >
              {validateStatus.msg}
            </SwAlert>
          </SwColumn>
        )}
        {children && <SwColumn>{children}</SwColumn>}
        {!servicesLoading ? (
          <>
            <SwColumn className="vl-u-spacer">
              {!!totalOfPendingServices && totalOfPendingServices > 0 && (
                <>
                  <SwButton
                    onClick={() => {
                      openModalWithPageView(
                        Modals.PRESTATIONS_WORKERS_VALIDATE_ALL_PRESTATIONS_MODAL,
                      );
                    }}
                    style={{
                      marginRight: '2rem',
                    }}
                  >
                    {t(i18nKeys.general.label.validateAll)}
                  </SwButton>
                  {t(i18nKeys.prestations.search.totalPendingWorks, {
                    count: totalOfPendingServices,
                  })}
                </>
              )}
            </SwColumn>
            <SwColumn>
              <ul>
                {!!services && services.length > 0 ? (
                  services.map((worker) => (
                    <PrestationsSearchWorkersWorker
                      key={worker.workerId}
                      provider={provider}
                      worker={worker}
                      parameters={parameters}
                      bookmark={pagination?.bookmark}
                      setValidateStatus={setValidateStatus}
                      setTotalOfPendingServices={setTotalOfPendingServices}
                      overview={overview}
                      setOverview={setOverview}
                    />
                  ))
                ) : (
                  <li>{t(i18nKeys.general.noResults)}</li>
                )}
              </ul>
            </SwColumn>
            <SwColumn>
              {!!pagination && (
                <div style={{ marginLeft: 'auto' }}>
                  <SwPaginator
                    itemCount={pagination.numberOfItems}
                    pageSize={parameters.pageSize}
                    selectedPage={pagination.pageNumber}
                    setPage={(page) => {
                      setParameters((currentParameters) => ({
                        ...currentParameters,
                        bookmark: pagination.bookmark,
                        pageSize: currentParameters.pageSize,
                        pageNumber:
                          typeof page === 'number'
                            ? page
                            : page(currentParameters?.pageNumber || 1),
                      }));
                    }}
                  />
                </div>
              )}
            </SwColumn>
          </>
        ) : (
          <SwColumn className="vl-u-spacer">
            <SwLoader />
          </SwColumn>
        )}
      </SwGrid>
      {!servicesLoading && !!services && services.length > 0 && (
        <SwModal
          id={Modals.PRESTATIONS_WORKERS_VALIDATE_ALL_PRESTATIONS_MODAL}
          modalId={Modals.PRESTATIONS_WORKERS_VALIDATE_ALL_PRESTATIONS_MODAL}
          component={PrestationsSearchValidateModal}
          title={t(i18nKeys.prestations.search.modal.validateAll)}
          confirmText={t(i18nKeys.general.cta.validate)}
          parameters={{
            ...parameters,
            bookmark: pagination?.bookmark,
          }}
          confirm={validateWorkersServices}
          isLoading={validateWorkersServicesLoading}
          closable
        />
      )}
    </SwColumn>
  );
};
