import { AvailableLanguages } from '@kaa/common/enums';
import { ChangeLanguageCommon } from '@kaa/core-app/providers/components/headless/ChangeLanguage';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

export const ChangeLanguage = (
  props: PropsWithChildren<{
    availableLanguages: AvailableLanguages[];
    fallbackLanguage: AvailableLanguages;
  }>,
) => {
  const { t } = useTranslation();
  const { availableLanguages } = props;

  const websiteLanguage = availableLanguages.find(
    (lng: AvailableLanguages) =>
      t(i18nKeys.vl.general.serviceVouchers.website.url, {
        lng,
      }) === window.location.origin,
  );

  return <ChangeLanguageCommon {...props} websiteLanguage={websiteLanguage} />;
};
