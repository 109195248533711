import { PickupConfigurationResponseData } from '@kaa/api/providers';
import { useLuxon } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  Icon,
  SwBadge,
  SwColumn,
  SwFormMessageLabel,
  SwInputCalendarField,
  SwInputCalendarFieldFormatDate,
  SwInputCalendarFieldParseDate,
  useTooltip,
} from '@kaa/ui-flanders/components';
import { Field } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PickupFormFieldNames } from '../PickupModals.constants';
import { getDisabledDates } from '../PickupModals.utils';

type PickupStandaloneModalProps = {
  configuration: PickupConfigurationResponseData;
};

export const PickupStandaloneModal = ({
  configuration: { disabledDates = [] },
}: PickupStandaloneModalProps) => {
  const { t } = useTranslation();
  const { DateTime } = useLuxon();

  const today = DateTime.fromJSDate(new Date())
    .startOf('day')
    .toISODate();

  const [selectedDate, setSelectedDate] = useState('');

  const refTooltipPickupInformation = useTooltip({
    value: t(i18nKeys.pickup.modal.add.form.pickUpTooltip),
    placement: 'right',
  });

  const onDateChange = (dates: Date[]) => {
    setSelectedDate(SwInputCalendarFieldParseDate(dates));
  };

  return (
    <SwColumn width="12">
      <SwFormMessageLabel>
        {t(i18nKeys.pickup.modal.add.form.pickUpDate)}
        <SwBadge
          modAction
          modXsmall
          icon={Icon.INFO}
          style={{
            marginLeft: '.5rem',
            verticalAlign: 'middle',
          }}
          ref={refTooltipPickupInformation}
        />
      </SwFormMessageLabel>
      <Field
        column={{ width: '12' }}
        labelColumn={{ width: '12' }}
        name={PickupFormFieldNames.DATE}
        component={SwInputCalendarField}
        parse={SwInputCalendarFieldParseDate}
        format={SwInputCalendarFieldFormatDate}
        disabledDates={getDisabledDates(disabledDates)}
        inputOnChange={onDateChange}
        minDate={DateTime.fromISO(today)
          .plus({ day: 1 })
          .toJSDate()}
      />
      {selectedDate ===
        DateTime.fromISO(today)
          .plus({ day: 1 })
          .toISODate() && (
        <p className="vl-u-text--small" style={{ marginTop: '1rem' }}>
          {t(i18nKeys.pickup.modal.add.form.pickUpDateInfo)}
        </p>
      )}
    </SwColumn>
  );
};
