import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  Icon,
  SwButton,
  SwColumn,
  SwContainer,
  SwGrid,
  SwModal,
} from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { VideoTopicReference } from '@kaa/api/providers';
import { PageHeader, TutorialVideoLink } from '../../components';
import { Modals } from '../../constants';
import { dataTest } from '../../datatest/keys';
import { openModalWithPageView } from '../../utils/google-analytics';
import { RemittancesCard } from './components/RemittancesCard';
import { RemittancesReimbursementModal } from './components/RemittancesReimbursementModal';
import { RemittancesSubsidies } from './subsidies/RemittancesSubsidies';
import { ShiftBy } from '../../components/shiftBy/ShiftBy';

export const RemittancesReimbursementScreen = () => {
  const { t } = useTranslation();

  return (
    <SwContainer>
      <SwGrid modStacked>
        <SwColumn>
          <PageHeader
            title={t(i18nKeys.navigation.remittancesReimbursement)}
            introduction={t(i18nKeys.remittances.reimbursement.introduction)}
            className="vl-u-spacer"
          />
          <ShiftBy y={-15}>
            <TutorialVideoLink topic={VideoTopicReference.REMITTANCE} />
          </ShiftBy>
          <SwGrid
            modVStretched
            style={{ width: '100%', justifyContent: 'flex-start' }}
          >
            <RemittancesCard
              title={t(i18nKeys.remittances.reimbursement.modal.title)}
              text={t(i18nKeys.remittances.reimbursement.modal.description)}
              icon={Icon.UNDO}
            >
              <SwButton
                data-testid={dataTest.remittances.reimbursement}
                modBlock
                modNarrow
                onClick={() => {
                  openModalWithPageView(
                    Modals.REMITTANCES_REIMBURSEMENT_MODAL_ID,
                  );
                }}
              >
                {t(i18nKeys.remittances.reimbursement.modal.card.cta)}
              </SwButton>
            </RemittancesCard>
          </SwGrid>
        </SwColumn>
        <RemittancesSubsidies />
        <SwModal
          id={Modals.REMITTANCES_REIMBURSEMENT_MODAL_ID}
          closable
          confirmCloseMessage={t(i18nKeys.dialog.confirmClose.message)}
          component={RemittancesReimbursementModal}
        />
      </SwGrid>
    </SwContainer>
  );
};
