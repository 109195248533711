import React, {
  HTMLAttributes,
  PropsWithChildren,
  ThHTMLAttributes,
} from 'react';
import { TableHeadSorting, TableHeadSortingProps } from './TableHeadSorting';

export type TableHeadElement = PropsWithChildren<
  ThHTMLAttributes<HTMLTableCellElement> & TableHeadSortingProps
>;

type TableHeadProps = PropsWithChildren<
  HTMLAttributes<HTMLTableSectionElement> & {
    elements: TableHeadElement[];
  }
>;

export const TableHead = ({ elements, children, ...props }: TableHeadProps) => (
  <thead {...props}>
    <tr>
      {elements.map(({ text, label, onClick, children, ...rest }) => (
        <th key={`th-${text}`} style={{ whiteSpace: 'normal' }} {...rest}>
          <TableHeadSorting {...{ text, label, onClick }} />
          {children}
        </th>
      ))}
    </tr>
    {children}
  </thead>
);
