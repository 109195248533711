import { ServiceActiveStatus } from '@kaa/api/providers';
import { ServiceUpdateResponseDataData } from '@kaa/api/providers/model/serviceUpdateResponseDataData';
import { httpTo } from '@kaa/api/providers/utilities';
import { ActionType, useActionDispatch } from '@kaa/common/context';
import { useAsyncCallback } from '@kaa/common/utils';
import { maxLength, minLength, required } from '@kaa/common/validation';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AlertType,
  getAlertPropsByType,
  SwActionGroup,
  SwAlert,
  SwButton,
  SwContainer,
  SwFetchErrorMessage,
  SwForm,
  SwFormSubmitMessage,
  SwModalRenderProps,
  SwTitle,
} from '@kaa/ui-flanders/components';
import { Formik, FormikActions } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi, useSelectedProviderIdState } from '../../../../utils';
import {
  EventAction,
  EventCategory,
  EventLabel,
  sendCustomInteractionToGTM,
} from '../../../../utils/google-analytics';
import {
  createValidatorWithServerErrorHandled,
  handleApiError,
} from '../../../../utils/validation';
import { PrestationForm } from '../form/PrestationForm';
import { PrestationFormData } from '../form/PrestationForm.types';
import {
  getDefaultWorksValidation,
  getPrestationStatusAlert,
  getRequiredDefaultWorksFields,
} from '../form/PrestationForm.utils';
import { PrestationContestedAlert } from '../PrestationContestedAlert';
import { PrestationsModalParameters } from '../PrestationsModals.constants';
import { PrestationCancelModalConfirmation } from './PrestationCancelModalConfirmation';

type PrestationCancelModalProps = {
  prestationId: string;
  searchedProviderId: number;
  parameters: PrestationsModalParameters;
  cancelService: (parameters: PrestationsModalParameters) => void;
  onSuccess: (props: ServiceUpdateResponseDataData) => void;
};

export const PrestationCancelModal = ({
  prestationId,
  searchedProviderId,
  cancelService,
  parameters,
  setConfirmCloseModal,
  onSuccess,
}: SwModalRenderProps<PrestationCancelModalProps>) => {
  const { t } = useTranslation();
  const { providers } = useApi();
  const providerId = useSelectedProviderIdState();

  const dispatchAction = useActionDispatch();

  const [
    { value: prestationData, loading, error },
    getPrestationData,
  ] = useAsyncCallback(
    async () => {
      const [
        {
          data: {
            data: { headquarter, subsidiaries },
          },
        },
        {
          data: { data },
        },
      ] = await Promise.all([
        providers.getProviderRelatives(providerId),
        providers.getService(searchedProviderId, prestationId),
      ]);

      return {
        relatives: [
          headquarter,
          ...subsidiaries.filter(({ isActive }) => isActive),
        ],
        prestation: data,
      };
    },
    [providers, providerId, prestationId],
    { loading: true },
  );

  const [{ value: submitedForm }, submit] = useAsyncCallback(
    async (
      formikData: PrestationFormData,
      formikActions: FormikActions<PrestationFormData>,
    ) => {
      const [error, response] = await httpTo(
        providers.cancelService(formikData.providerId, prestationId, {
          comment: formikData.comment || '',
        }),
      );
      return { error, response: response?.data, formikData, formikActions };
    },
    [providers],
  );

  useEffect(() => {
    getPrestationData();
  }, [getPrestationData]);

  useEffect(() => {
    if (submitedForm) {
      const { error, response, formikData, formikActions } = submitedForm;
      const { setSubmitting, setStatus, resetForm } = formikActions;
      setSubmitting(false);

      if (handleApiError(error, formikActions)) {
        return;
      }

      if (!response) {
        setStatus({
          msg: t(i18nKeys.contactPersons.modal.add.errorMessage),
          type: AlertType.ERROR,
        });
        return;
      }

      const { data, actions } = response;

      if (actions) {
        dispatchAction({
          type: ActionType.ADD,
          payload: actions,
        });
      }

      setConfirmCloseModal(false);
      resetForm(formikData);
      cancelService(parameters);

      onSuccess(data);

      sendCustomInteractionToGTM(
        EventCategory.PRESTATIONS,
        EventAction.FORM_CONFIRMATION,
        EventLabel.PRESTATIONS_CANCEL,
      );
    }
  }, [submitedForm]);

  if (loading && !prestationData) {
    return (
      <>
        <SwTitle tagName="h2">
          {t(i18nKeys.prestations.modals.cancel.title)}
        </SwTitle>
        <SwContainer loading />
      </>
    );
  }
  if (error || !prestationData) {
    return (
      <SwContainer error>
        <SwFetchErrorMessage
          onClick={() => {
            getPrestationData();
          }}
        />
      </SwContainer>
    );
  }

  if (submitedForm?.response) {
    return (
      <>
        <SwTitle tagName="h2">
          {t(i18nKeys.prestations.modals.cancel.title)}
        </SwTitle>
        <PrestationCancelModalConfirmation />
      </>
    );
  }

  const { relatives, prestation } = prestationData;

  const validate = createValidatorWithServerErrorHandled({
    ...getDefaultWorksValidation(),
    comment: [required, minLength(8), maxLength(255)],
  });

  const status = getPrestationStatusAlert(prestation, t);

  return (
    <>
      <SwTitle tagName="h2">
        {t(i18nKeys.prestations.modals.cancel.title)}
      </SwTitle>
      {status && (
        <SwAlert
          {...getAlertPropsByType(AlertType.WARNING)}
          title={status.title}
        >
          {prestation.status !== ServiceActiveStatus.CONTESTED && status.text && (
            <>
              {Array.isArray(status.text)
                ? status.text.map((e) => (
                    <span
                      key={JSON.stringify(e)}
                      style={{
                        display: 'block',
                      }}
                    >
                      {e}
                    </span>
                  ))
                : status.text}
            </>
          )}
          {prestation.status === ServiceActiveStatus.CONTESTED && (
            <PrestationContestedAlert prestation={prestation} />
          )}
        </SwAlert>
      )}
      <Formik<Partial<PrestationFormData>>
        onSubmit={submit}
        validate={validate}
        initialValues={getRequiredDefaultWorksFields(prestation)}
        render={({ handleSubmit, isSubmitting, dirty }) => {
          return (
            <SwForm onSubmit={handleSubmit}>
              <PrestationForm
                relatives={relatives}
                modDisabled={{
                  activity: true,
                  providerId: true,
                  durationInHours: true,
                  workerNiss: true,
                  customerContractId: true,
                  serviceDate: true,
                }}
                comments={prestation.comments}
                customerFullname={prestation.customerFullname}
              />
              <SwActionGroup
                style={{ marginTop: '3rem' }}
                className="vl-u-spacer--medium"
              >
                <SwButton
                  type="submit"
                  modLoading={isSubmitting}
                  modLarge
                  modDisabled={!dirty}
                >
                  {t(i18nKeys.prestations.modals.cancel.cta)}
                </SwButton>
              </SwActionGroup>
              <SwFormSubmitMessage />
            </SwForm>
          );
        }}
      />
    </>
  );
};
