import { CommonRoutes, COMMON_ROUTES } from '@kaa/common/constants';
import { RoutesDefinition } from '@kaa/common/types';
import {
  getPathForRoutes,
  getQueryParam,
  getRouteForRoutes,
  getRouterPathForRoutes,
  pickRouteForRoutes,
} from '@kaa/common/utils';

export enum QueryParams {
  MODAL = 'modal',
  STATUS = 'status',
  FILTER = 'filter',
  MONTHS = 'months',
}

export type Routes =
  | CommonRoutes
  | 'LOGIN_LOCAL'
  | 'REMITTANCES'
  | 'REMITTANCES_REIMBURSEMENT'
  | 'PRESTATIONS'
  | 'PRESTATIONS_HISTORY'
  | 'WORKERS'
  | 'WORKERS_DETAILS'
  | 'PICKUP'
  | 'PICKUP_BILLING'
  | 'ENTERPRISE'
  | 'ENTERPRISE_DETAILS'
  | 'ENTERPRISE_ACTIVITIES'
  | 'ENTERPRISE_ACTIVITIES_VISIBILITY'
  | 'VIDEOS'
  | 'FORBIDDEN_WRONG_REGION';
export const Routes = {
  ...CommonRoutes,
  LOGIN_LOCAL: 'LOGIN_LOCAL' as Routes,
  REMITTANCES: 'REMITTANCES' as Routes,
  REMITTANCES_REIMBURSEMENT: 'REMITTANCES_REIMBURSEMENT' as Routes,
  PRESTATIONS: 'PRESTATIONS' as Routes,
  PRESTATIONS_HISTORY: 'PRESTATIONS_HISTORY' as Routes,
  WORKERS: 'WORKERS' as Routes,
  WORKERS_DETAILS: 'WORKERS_DETAILS' as Routes,
  PICKUP: 'PICKUP' as Routes,
  PICKUP_BILLING: 'PICKUP_BILLING' as Routes,
  ENTERPRISE: 'ENTERPRISE' as Routes,
  ENTERPRISE_DETAILS: 'ENTERPRISE_DETAILS' as Routes,
  ENTERPRISE_DETAILS_CONTACT_PERSON: 'ENTERPRISE_DETAILS_CONTACT_PERSON' as Routes,
  ENTERPRISE_DETAILS_CONTACT_PERSON_DETAILS: 'ENTERPRISE_DETAILS_CONTACT_PERSON_DETAILS' as Routes,
  ENTERPRISE_DETAILS_VISIBILITY: 'ENTERPRISE_DETAILS_VISIBILITY' as Routes,
  ENTERPRISE_ACTIVITIES: 'ENTERPRISE_ACTIVITIES' as Routes,
  FORBIDDEN_WRONG_REGION: 'FORBIDDEN_WRONG_REGION' as Routes,
  MAINTENANCE: 'MAINTENANCE' as Routes,
  VIDEOS: 'VIDEOS' as Routes,
};

export const ROUTES = {
  ...COMMON_ROUTES,
  [Routes.LOGIN_LOCAL]: {
    title: Routes.LOGIN_LOCAL,
    path: '/auth/login-local',
  },
  [Routes.REMITTANCES]: {
    title: Routes.REMITTANCES,
    path: '/remittances',
  },
  [Routes.REMITTANCES_REIMBURSEMENT]: {
    title: Routes.REMITTANCES_REIMBURSEMENT,
    path: '/remittances/reimbursement',
  },
  [Routes.PRESTATIONS]: {
    title: Routes.PRESTATIONS,
    path: '/prestations',
  },
  [Routes.PRESTATIONS_HISTORY]: {
    title: Routes.PRESTATIONS_HISTORY,
    path: '/prestations/history',
  },
  [Routes.WORKERS]: {
    title: Routes.WORKERS,
    path: '/workers',
  },
  [Routes.WORKERS_DETAILS]: {
    title: Routes.WORKERS_DETAILS,
    path: '/:providerId/:workerId',
    parent: Routes.WORKERS,
  },
  [Routes.PICKUP]: {
    title: Routes.PICKUP,
    path: '/pickup',
  },
  [Routes.PICKUP_BILLING]: {
    title: Routes.PICKUP_BILLING,
    path: '/pickup/billing',
  },
  [Routes.ENTERPRISE]: {
    title: Routes.ENTERPRISE,
    path: '/enterprise',
  },
  [Routes.ENTERPRISE_DETAILS]: {
    title: Routes.ENTERPRISE_DETAILS,
    path: '/:enterpriseId',
    parent: Routes.ENTERPRISE,
  },
  [Routes.ENTERPRISE_DETAILS_VISIBILITY]: {
    title: Routes.ENTERPRISE_DETAILS_VISIBILITY,
    path: '/:enterpriseId/visibility',
    parent: Routes.ENTERPRISE,
  },
  [Routes.ENTERPRISE_DETAILS_CONTACT_PERSON]: {
    title: Routes.ENTERPRISE_DETAILS_CONTACT_PERSON,
    path: '/:enterpriseId/contact-person',
    parent: Routes.ENTERPRISE,
  },
  [Routes.ENTERPRISE_DETAILS_CONTACT_PERSON_DETAILS]: {
    title: Routes.ENTERPRISE_DETAILS_CONTACT_PERSON_DETAILS,
    path: '/:enterpriseId/contact-person/:contactId',
    parent: Routes.ENTERPRISE,
  },
  [Routes.ENTERPRISE_ACTIVITIES]: {
    title: Routes.ENTERPRISE_ACTIVITIES,
    path: '/activities',
    parent: Routes.ENTERPRISE,
  },
  [Routes.FORBIDDEN_WRONG_REGION]: {
    title: Routes.FORBIDDEN_WRONG_REGION,
    path: '/forbidden-wrong-region',
  },
  [Routes.MAINTENANCE]: {
    title: Routes.MAINTENANCE,
    path: '/maintenance',
  },
  [Routes.VIDEOS]: {
    title: Routes.VIDEOS,
    path: '/dashboard/videos',
  },
} as RoutesDefinition<Routes>;

export const getRoute = getRouteForRoutes(ROUTES);
export const getRouterPath = getRouterPathForRoutes(ROUTES);
export const getPath = getPathForRoutes(ROUTES);
export const pickRoute = pickRouteForRoutes(ROUTES);

export const getQueryParams = <T = string>(str: string): T | undefined =>
  (getQueryParam(str) as unknown) as T | undefined;

// Todo make me global
export const getWorkerDetailsPath = (
  workerId?: number | string,
  providerId?: number | string,
) =>
  getPath(!workerId || !providerId ? Routes.WORKERS : Routes.WORKERS_DETAILS, {
    params: {
      workerId,
      providerId,
    },
  });
