/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */

export type Author = 'CUSTOMER' | 'PROVIDER';

export const Author = {
  CUSTOMER: 'CUSTOMER' as Author,
  PROVIDER: 'PROVIDER' as Author,
};
