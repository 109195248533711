import {
  Pickup,
  Pickups,
  PickupType,
  PickupWeekFrequency,
  WorkingDays,
} from '@kaa/api/providers';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import { TFunction } from 'i18next';
import { DateTime } from 'luxon';
import {
  PickupCreateFormData,
  PickupUpdateFormData,
} from './components/PickupModals.constants';

export const sortPickupsPerMonth = (
  pickupsMonths: Pickups[],
  getName: (date: string) => string,
) =>
  pickupsMonths.map(({ startDate, pickups, estimatedCost }) => ({
    name: getName(startDate),
    pickups: pickups.sort(
      (a, b) => Number(new Date(a.date)) - Number(new Date(b.date)),
    ),
    estimatedCost,
  }));

const getRecurrentPickupInfoDate = (
  startDate: string,
  endDate: string,
  t: TFunction,
): string =>
  t(i18nKeys.pickup.recurent.info.dates, {
    startDate: DateTime.fromISO(startDate).toFormat('dd/MM/yyyy'),
    endDate: DateTime.fromISO(endDate).toFormat('dd/MM/yyyy'),
  });

const getRecurrentPickupInfoDays = (
  days: WorkingDays[],
  t: TFunction,
): string =>
  days.reduce(
    (acc, day, i) =>
      `${acc}${t(i18nKeys.general.days[day])}${
        i !== days.length - 1 ? ', ' : ''
      }`,
    '',
  );

const getRecurrentPickupInfoWeeks = (
  frequencies: PickupWeekFrequency[],
  t: TFunction,
): string =>
  frequencies.reduce(
    (acc, frequency, i) =>
      `${acc}${t(i18nKeys.general.frequency[frequency])}${
        i !== frequencies.length - 1 ? ', ' : ''
      }`,
    '',
  );

type getRecurrentPickupStatus =
  | (Pickup & {
      startDate?: string;
      endDate?: string;
    })
  | PickupCreateFormData
  | PickupUpdateFormData;

export const getRecurrentPickupInfo = (
  {
    type,
    startDate,
    endDate,
    selectedWeeks,
    selectedDays,
  }: getRecurrentPickupStatus,
  t: TFunction,
): string | undefined => {
  if (type === PickupType.STANDALONE) {
    return undefined;
  }

  const status =
    Object.values(PickupWeekFrequency).filter((e) => selectedWeeks.includes(e))
      .length === Object.values(PickupWeekFrequency).length
      ? t(i18nKeys.pickup.recurent.info.all, {
          selectedDays: getRecurrentPickupInfoDays(selectedDays, t),
        })
      : t(i18nKeys.pickup.recurent.info.each, {
          selectedDays: getRecurrentPickupInfoDays(selectedDays, t),
          selectedWeeks: getRecurrentPickupInfoWeeks(selectedWeeks, t),
        });

  if (!startDate || !endDate) {
    return status;
  }

  return `${status}, ${getRecurrentPickupInfoDate(startDate, endDate, t)}`;
};

export const getPickupAddress = (
  { address }: Pickup,
  t: TFunction,
): string | undefined => {
  if (!address) {
    return undefined;
  }

  const { houseNumber, street, postcode, city } = address;

  return `${street}, ${houseNumber} - ${postcode} - ${city}`;
};
