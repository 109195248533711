import { Activity } from '@kaa/api/providers';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  SwFormColumn,
  SwPill,
  SwSelectField,
} from '@kaa/ui-flanders/components';
import { Field, FormikProps } from 'formik';
import get from 'lodash.get';
import React, { ChangeEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IncorrectVoucherFieldNames } from './RemittancesSearchHistoryIncorrect.constants';
import { IncorrectVoucherWithValidation } from './RemittancesSearchHistoryIncorrect.types';

type RemittancesSearchHistoryIncorrectEditRowFormActivitiesProps = FormikProps<
  IncorrectVoucherWithValidation
> & {
  selectedActivities: Activity[];
  validated?: boolean;
};

export const RemittancesSearchHistoryIncorrectEditRowFormActivities = ({
  selectedActivities,
  validated,
  values,
  setFieldValue,
}: RemittancesSearchHistoryIncorrectEditRowFormActivitiesProps) => {
  const { t } = useTranslation();

  const refSelect = useRef<HTMLSelectElement | null>(null);

  const isValidActivityTypes = useRef(
    !get(values.errors, IncorrectVoucherFieldNames.ACTIVITY_TYPES) &&
      !get(
        values.errors,
        `server.${IncorrectVoucherFieldNames.ACTIVITY_TYPES}`,
      ),
  );

  const SwPillActivityProps = {
    className: 'vl-u-spacer--xsmall',
    style: {
      marginRight: '1rem',
      backgroundColor: '#fff',
    },
  };

  if (validated || isValidActivityTypes.current) {
    return (
      <>
        {values.activityTypes.map((activity) => (
          <SwPill {...SwPillActivityProps} key={activity}>
            {t(i18nKeys.general.services[activity])}
          </SwPill>
        ))}
      </>
    );
  }

  // const errors = Object.keys(
  //   get(values.errors, IncorrectVoucherFieldNames.ACTIVITY_TYPES) ||
  //     get(values.errors, `server.${IncorrectVoucherFieldNames.ACTIVITY_TYPES}`),
  // );

  const activitiesOptions = Object.values(Activity)
    .filter((activity) => !selectedActivities.includes(activity))
    .map((activity) => {
      return {
        value: activity,
        text: t(i18nKeys.general.services[activity]),
      };
    });

  const addActivity = (activity: Activity) => {
    const newSelectedActivities = [activity, ...(values.activityTypes || [])];

    setFieldValue(
      IncorrectVoucherFieldNames.ACTIVITY_TYPES,
      newSelectedActivities.filter(
        (activity, index) => newSelectedActivities.indexOf(activity) === index,
      ),
    );

    if (refSelect.current && refSelect.current?.selectedIndex) {
      refSelect.current.selectedIndex = 0;
    }
  };

  const removeActivity = (activity: Activity) => {
    const newSelectedActivities = selectedActivities.filter(
      (item) => item !== activity,
    );

    setFieldValue(
      IncorrectVoucherFieldNames.ACTIVITY_TYPES,
      newSelectedActivities,
    );
  };

  return (
    <SwFormColumn width="12">
      <Field
        column={{ width: '12' }}
        name={IncorrectVoucherFieldNames.ACTIVITY_TYPES}
        id={`${values.voucherReference}-${IncorrectVoucherFieldNames.ACTIVITY_TYPES}`}
        options={activitiesOptions}
        component={SwSelectField}
        inputOnChange={(e: ChangeEvent<HTMLInputElement>) => {
          e.preventDefault();
          addActivity(e.target.value as Activity);
        }}
      />
      <div style={{ marginTop: '1rem' }}>
        {selectedActivities.map((activity: Activity) => {
          const id = `${values.voucherReference}-${IncorrectVoucherFieldNames.ACTIVITY_TYPES}-${activity}`;
          const label = t(i18nKeys.general.services[activity]);
          return (
            <SwPill
              {...SwPillActivityProps}
              key={id}
              id={id}
              aria-label={`${t(i18nKeys.general.cta.delete)} ${label}`}
              modClosable
              onClick={(e) => {
                e.preventDefault();
                removeActivity(activity);
              }}
            >
              {label}
            </SwPill>
          );
        })}
      </div>
    </SwFormColumn>
  );
};
