import { PickupType } from '@kaa/api/providers';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  SwButton,
  SwColumn,
  SwLink,
  SwRadio,
  toggleModalById,
} from '@kaa/ui-flanders/components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modals } from '../../../../constants';

type PickupUpdateModalSelectProps = {
  setRecurrenceUpdate: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >;
};

export const PickupUpdateModalSelect = ({
  setRecurrenceUpdate,
}: PickupUpdateModalSelectProps) => {
  const { t } = useTranslation();
  const [pickupTypeToEdit, setPickupTypeToEdit] = useState<PickupType>(
    PickupType.STANDALONE,
  );
  return (
    <SwColumn width="12">
      <div className="vl-u-spacer">
        <SwRadio
          name="pickupType"
          id={PickupType.STANDALONE}
          value={PickupType.STANDALONE}
          onClick={() => setPickupTypeToEdit(PickupType.STANDALONE)}
          checked={pickupTypeToEdit === PickupType.STANDALONE}
        >
          {t(i18nKeys.pickup.modal.update[PickupType.STANDALONE].cta)}
        </SwRadio>
        <SwRadio
          name="pickupType"
          id={PickupType.RECURRENT}
          value={PickupType.RECURRENT}
          onClick={() => setPickupTypeToEdit(PickupType.RECURRENT)}
          checked={pickupTypeToEdit === PickupType.RECURRENT}
        >
          {t(i18nKeys.pickup.modal.update[PickupType.RECURRENT].cta)}
        </SwRadio>
      </div>
      <SwButton
        onClick={(e) => {
          e.preventDefault();
          if (pickupTypeToEdit === PickupType.RECURRENT) {
            setRecurrenceUpdate(true);
          } else {
            setRecurrenceUpdate(false);
          }
        }}
      >
        {t(i18nKeys.general.label.submit)}
      </SwButton>
      <SwLink
        style={{ marginLeft: '2rem' }}
        onClick={() => {
          toggleModalById(Modals.PICKUP_UPDATE_PICKUP_MODAL);
        }}
      >
        {t(i18nKeys.general.cta.cancel)}
      </SwLink>
    </SwColumn>
  );
};
