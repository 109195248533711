import { SupportType } from '@kaa/api/providers';
import { DateTime } from 'luxon';

export const PAGE_SIZE = 30;

export const NOW_DATE = DateTime.local().startOf('day');

export const PAST_DATE_LIMIT = DateTime.fromISO('2016-01-01').startOf('day');

export const SERVICE_MONTHS_PERIOD_LIMIT = 12;

export enum RemittancesSubsidiesFormFieldNames {
  RELATIVE_ID = 'relativeId',
  KEYWORD = 'keyword',
  SERVICE_MONTH_PERIOD = 'serviceMonthPeriod',
  SERVICE_MONTHS_PERIOD = 'serviceMonthsPeriod',
  START_DATE = 'fromPaymentDate',
  END_DATE = 'toPaymentDate',
  SUPPORT_TYPE = 'supportType',
  IS_BLOCKED = 'isBlocked',
}

export type RemittancesSubsidiesFormType = {
  [RemittancesSubsidiesFormFieldNames.SERVICE_MONTHS_PERIOD]: string[];
  [RemittancesSubsidiesFormFieldNames.START_DATE]: string;
  [RemittancesSubsidiesFormFieldNames.END_DATE]: string;
  [RemittancesSubsidiesFormFieldNames.RELATIVE_ID]?: string;
  [RemittancesSubsidiesFormFieldNames.KEYWORD]?: string;
  [RemittancesSubsidiesFormFieldNames.SUPPORT_TYPE]?: SupportType;
  [RemittancesSubsidiesFormFieldNames.IS_BLOCKED]?: boolean;
  [RemittancesSubsidiesFormFieldNames.SERVICE_MONTH_PERIOD]?: string; // this is kept to trick the form to show the last edited value
};
