import { i18nKeys } from '@kaa/i18n/providers/keys';
import { SwDocument } from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationType, Modals } from '../../constants';
import {
  EventAction,
  sendDownloadEventToGTM,
} from '../../utils/google-analytics';
import { getFileExtension } from '../../utils/templates';

type DownloadReportProps = {
  reportFile: Blob;
  reportName: string;
  reportUrl: string;
  reportLabel?: string;
  modalId?: Modals | string;
};

const getFileInfo = (fileName: string, file: Blob) => {
  const fileExtension = getFileExtension(file.type as ApplicationType);
  return {
    fileExtension,
    fileNameExtension: `${fileName}.${fileExtension}`,
  };
};

export const DownloadReport = ({
  reportFile,
  reportName,
  reportUrl,
  reportLabel,
  modalId,
}: DownloadReportProps) => {
  const { t } = useTranslation();

  const { fileExtension, fileNameExtension } = getFileInfo(
    reportName,
    reportFile,
  );

  return (
    <SwDocument
      tagName="a"
      href={reportUrl}
      target="_blank"
      external
      download={fileNameExtension}
      mimeType={fileExtension}
      title={reportLabel || t(i18nKeys.general.modal.export.cta.download)}
      onClick={(e) => {
        if (window.navigator.msSaveOrOpenBlob) {
          e.preventDefault();
          window.navigator.msSaveOrOpenBlob(reportFile, fileNameExtension);
        }
        sendDownloadEventToGTM(
          EventAction.DOWNLOAD,
          `${fileNameExtension} | ${reportUrl}`,
        );
      }}
    />
  );
};
