import {
  GetIncorrectVouchersRemittanceParams,
  VoucherErrors,
} from '@kaa/api/providers';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  Icon,
  SwActionGroup,
  SwButton,
  SwForm,
  SwFormColumn,
  SwFormGrid,
  SwFormSubmitMessage,
  SwSelectField,
} from '@kaa/ui-flanders/components';
import { Field, Formik, FormikActions } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

type RemittancesSearchHistoryIncorrectFormProps = {
  submit: (
    parameters: GetIncorrectVouchersRemittanceParams,
    formikActions: FormikActions<GetIncorrectVouchersRemittanceParams>,
  ) => void;
  parameters?: GetIncorrectVouchersRemittanceParams;
  loading: boolean;
};

export const RemittancesSearchHistoryIncorrectForm = ({
  parameters,
  submit,
  loading,
}: RemittancesSearchHistoryIncorrectFormProps) => {
  const { t } = useTranslation();

  const errorTypeOptions = Object.values(VoucherErrors).map(
    (reason: VoucherErrors) => ({
      value: reason,
      text: t(i18nKeys.general.incorrectType[reason]),
    }),
  );

  return (
    <Formik<GetIncorrectVouchersRemittanceParams>
      initialValues={{ ...parameters }}
      onSubmit={submit}
      render={({ handleSubmit, dirty, isSubmitting }) => (
        <SwForm onSubmit={handleSubmit}>
          <SwFormGrid modVBottom>
            <SwFormColumn width="6" widthS="12">
              <Field
                column={{ width: '12' }}
                labelColumn={{ width: '12' }}
                name="type"
                label={t(
                  i18nKeys.remittances.modal.incorrect.form.label.errorType,
                )}
                component={SwSelectField}
                options={errorTypeOptions}
              />
            </SwFormColumn>
            <SwFormColumn width="5" widthS="12">
              <SwActionGroup modCollapseS>
                <SwButton
                  type="submit"
                  modLoading={isSubmitting || loading}
                  modDisabled={!dirty || isSubmitting}
                  modIconBefore
                  icon={Icon.SYNCHRONIZE}
                  style={{ marginLeft: 0 }}
                >
                  {t(i18nKeys.general.cta.refresh)}
                </SwButton>
              </SwActionGroup>
              <SwFormSubmitMessage />
            </SwFormColumn>
          </SwFormGrid>
        </SwForm>
      )}
    />
  );
};
