import { getActionsMock, POSTCODES_CITIES } from '@kaa/api/common/mocks';
import { Format } from '@kaa/common/utils';
import faker from 'faker';
import { DateTime } from 'luxon';
import {
  CountResponse,
  FileStatus,
  GenderCode,
  GetWorkerServicesParams,
  GetWorkersParams,
  GetWorkersServicesParams,
  LanguageCode,
  ServiceActiveStatus,
  Sorting,
  Worker,
  WorkerBase,
  WorkerContract,
  WorkerContractsResponse,
  WorkerContractsResponseDataItem,
  WorkerContractStatus,
  WorkerContractType,
  WorkerCreateContractResponse,
  WorkerDetailResponse,
  WorkerResetPasswordResponse,
  WorkersCreatedResponse,
  WorkerServiceActionType,
  WorkerServicesResponse,
  WorkersFileStatusResponse,
  WorkersFileStatusResponseData,
  WorkersOverviewResponse,
  WorkersResponse,
  WorkersServicesResponse,
  WorkersServicesResponseDataDataWorkersItem,
  WorkerSummary,
} from '../model';
import { getDataWithPagination } from './pagination';
import { getService, sortServices } from './services';
import { getFullName, getId, getRandomArray, sortByPostcodes } from './utils';

const getWorkerCreated = () => ({
  id: faker.random.number(99999999).toString(),
  login: faker.internet.userName(),
  password: faker.internet.password(),
  alreadyExists: faker.random.boolean(),
  firstName: faker.name.firstName(),
  lastName: faker.name.lastName(),
});

const getWorkerContract = (
  workerContract?: Partial<WorkerContract>,
): WorkerContract => ({
  status: faker.helpers.randomize(Object.values(WorkerContractStatus)),
  type: faker.helpers.randomize(Object.values(WorkerContractType)),
  vatNumber: faker.random
    .number({ min: 100000000, max: 999999999 })
    .toFixed(0)
    .toString(),
  startDate: faker.date.past().toISOString(),
  endDate: faker.date.future().toISOString(),
});

const getWorkerContractWithId = (
  workerContract?: Partial<WorkerContractsResponseDataItem>,
): WorkerContractsResponseDataItem => ({
  id: faker.random.number(9999999999).toString(),
  ...workerContract,
  ...getWorkerContract(workerContract),
});

const getWorkerServices = ({
  workerId,
  workerNameOrNiss,
}: {
  workerId?: string;
  workerNameOrNiss?: string;
}): WorkersServicesResponseDataDataWorkersItem => ({
  workerId: workerId || getId(),
  workerFullname: workerNameOrNiss || getFullName(),
  numberOfServices: faker.random.number({ min: 0, max: 100 }),
  numberOfPendingServices: faker.random.number({ min: 0, max: 100 }),
});

export const getWorkerBase = (worker?: Partial<WorkerBase>): WorkerBase => {
  const POSTOCDE_CITY = faker.helpers.randomize(POSTCODES_CITIES);
  return {
    providerId: getId(),
    firstName: faker.name.firstName(),
    lastName: faker.name.lastName(),
    niss: Format.nationalNumber(
      faker.random.number(9999999999999999).toString(),
    ),
    postcode: POSTOCDE_CITY.postcode,
    reference: faker.random.word(),
    ...worker,
  };
};

export const getWorkerSummary = (
  worker?: Partial<WorkerSummary>,
): WorkerSummary => ({
  id: faker.random.number(99999999),
  isActive: faker.random.boolean(),
  isContracted: faker.random.boolean(),
  isRegistered: faker.random.boolean(),
  login: faker.internet.userName(),
  resourceId: faker.random.uuid(),
  ...worker,
  ...getWorkerBase(worker),
});

export const getWorkersSummaryResponse = (
  params?: Partial<GetWorkersParams>,
): WorkersResponse => {
  let workers =
    params?.workerNameOrNiss?.length === 11
      ? [
          getWorkerSummary({
            niss: params.workerNameOrNiss,
          }),
        ]
      : getRandomArray(99).map(getWorkerSummary);

  if (params?.sort) {
    switch (params.sort) {
      case Sorting.POSTCODE_ASC:
      case Sorting.POSTCODE_DESC:
        workers = sortByPostcodes(params.sort, workers);
        break;
      default:
        break;
    }
  }

  return getDataWithPagination<WorkerSummary>(workers, params);
};

export const getWorkerCreatedResponse = (): WorkersCreatedResponse => {
  return {
    data: getWorkerCreated(),
  };
};

export const getWorker = (worker?: Partial<Worker>): Worker => ({
  birthDate: faker.date.past().toISOString(),
  gender: faker.helpers.randomize(Object.values(GenderCode)),
  language: faker.helpers.randomize(Object.values(LanguageCode)),
  isForeigner: faker.random.boolean(),
  mobilePhoneNumber: faker.phone.phoneNumber(),
  emailAddress: faker.internet.email(),
  ...worker,
  ...getWorkerBase(worker),
});

export const getWorkersOverviewResponse = (): WorkersOverviewResponse => {
  return {
    data: {
      workersUncontracted: faker.random.number(100),
    },
  };
};

export const getWorkerDetailResponse = (
  worker?: Partial<WorkerSummary & Worker & { password?: string }>,
): WorkerDetailResponse => {
  return {
    data: {
      isBlocked: faker.random.boolean(),
      password: faker.internet.password(),
      ...worker,
      ...getWorkerSummary(worker),
      ...getWorker(worker),
    },
  };
};

export const getWorkerResetPasswordResponse = (): WorkerResetPasswordResponse => {
  return {
    data: {
      login: faker.internet.userName(),
      password: faker.internet.password(),
    },
  };
};

export const getWorkerContractsResponse = (): WorkerContractsResponse => {
  return {
    data: getRandomArray(10).map(getWorkerContractWithId),
  };
};

export const getWorkerCreateContractResponse = (): WorkerCreateContractResponse => {
  return {
    data: {
      id: faker.random.number(9999999999).toString(),
    },
  };
};

// Generate or update a fileStatus depending current status
const getWorkersFileStatusResponseData = (
  data?: WorkersFileStatusResponseData,
): WorkersFileStatusResponseData => {
  if (
    data?.status === FileStatus.Done ||
    data?.status === FileStatus.DoneWithError
  ) {
    return data;
  }

  if (data?.status === FileStatus.InProgress) {
    return {
      ...data,
      status: faker.helpers.randomize([
        FileStatus.InProgress,
        FileStatus.Parsed,
      ]),
    };
  }

  if (data?.status === FileStatus.Parsed) {
    return {
      ...data,
      status: faker.helpers.randomize([
        FileStatus.Parsed,
        FileStatus.Done,
        FileStatus.DoneWithError,
      ]),
    };
  }

  const totalRows = faker.random.number();
  const processedRows = faker.random.number({ min: 1, max: totalRows });
  const created = faker.random.number({
    min: 1,
    max: processedRows,
  });
  const updated = faker.random.number({ min: 1, max: processedRows - created });
  const partial = faker.random.number({
    min: 1,
    max: processedRows - created - updated,
  });
  const invalid = processedRows - created - updated - partial;

  return {
    status: faker.helpers.randomize(Object.values(FileStatus)),
    lastSuccessTimestamp: DateTime.fromJSDate(new Date()).toISO(),
    totalRows,
    processedRows,
    created,
    updated,
    invalid,
    partial,
  };
};

// First call, I have a current fileStatus or not
let fileStatus =
  faker.random.number({ min: 1, max: 10 }) > 3
    ? getWorkersFileStatusResponseData()
    : undefined;

// New upload set a new fileStatus
export const setNewWorkersFileStatusResponse = (): void => {
  fileStatus = getWorkersFileStatusResponseData();
};

// Return a new fileStatus or the current one maybe updated
export const getWorkersFileStatusResponse = (): WorkersFileStatusResponse => {
  if (!fileStatus) {
    return {
      data: undefined,
    };
  }

  fileStatus = getWorkersFileStatusResponseData(fileStatus);

  return {
    data: fileStatus,
  };
};

export const getWorkerServicesResponse = (
  workerId: number,
  params?: GetWorkerServicesParams,
): WorkerServicesResponse => {
  const services = sortServices(
    params?.sort,
    getRandomArray({ min: 1, max: 100 }).map((e) => ({
      ...getService(Object.values(ServiceActiveStatus)),
      workerId: String(workerId),
    })),
  );

  return {
    data: services,
    actions: services.reduce(
      (acc, { resourceId }) =>
        resourceId
          ? {
              ...acc,
              [resourceId]: getActionsMock(WorkerServiceActionType),
            }
          : acc,
      {},
    ),
  };
};

export const getWorkersServicesResponse = (
  params?: GetWorkersServicesParams,
): WorkersServicesResponse => {
  const workersData = getRandomArray({ min: 1, max: 100 }).map(() =>
    getWorkerServices(params || {}),
  );

  const { data: workers, paginationDirectives } = getDataWithPagination<
    WorkersServicesResponseDataDataWorkersItem
  >(workersData, params);

  return {
    paginationDirectives,
    data: {
      workers,
      totalOfPendingServices: workers.reduce(
        (acc, e) => acc + e.numberOfPendingServices,
        0,
      ),
    },
  };
};

export const getCountResponse = (): CountResponse => ({
  data: {
    count: faker.random.number({ min: 0, max: 100 }),
  },
});
