import { WorkingDays } from '@kaa/api/providers';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import { FieldArray, FieldArrayRenderProps } from 'formik';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PickupCreateFormData,
  PickupFormFieldNames,
  PickupUpdateFormData,
} from '../PickupModals.constants';

type PickupRecurrentModalSelectDaysProps = {
  values: PickupCreateFormData | PickupUpdateFormData;
};

export const PickupRecurrentModalSelectDays = ({
  values,
}: PickupRecurrentModalSelectDaysProps) => {
  const { t } = useTranslation();

  const isSelected = (day: WorkingDays) =>
    values[PickupFormFieldNames.SELECTED_DAYS].includes(day);

  const onChange = (
    e: ChangeEvent<HTMLInputElement>,
    day: WorkingDays,
    arrayHelpers: FieldArrayRenderProps,
  ) => {
    if (e.target.checked) {
      arrayHelpers.push(day);
    } else {
      arrayHelpers.remove(
        values[PickupFormFieldNames.SELECTED_DAYS].indexOf(day),
      );
    }
  };

  return (
    <FieldArray
      name={PickupFormFieldNames.SELECTED_DAYS}
      render={(arrayHelpers) => (
        <div
          className="vl-u-display-flex"
          style={{
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            marginLeft: '-2rem',
          }}
        >
          {Object.keys(WorkingDays).map((key: string) => {
            const day = key as WorkingDays;
            return (
              <div key={day} style={{ paddingLeft: '2rem' }}>
                <label className="vl-checkbox">
                  <input
                    type="checkbox"
                    className="vl-checkbox__toggle"
                    name={PickupFormFieldNames.SELECTED_DAYS}
                    value={day}
                    checked={isSelected(day)}
                    onChange={(e) => onChange(e, day, arrayHelpers)}
                  />
                  <div className="vl-checkbox__label">
                    <div className="vl-checkbox__box" aria-hidden="true" />
                    {t(i18nKeys.general.days[day])}
                  </div>
                </label>
              </div>
            );
          })}
        </div>
      )}
    />
  );
};
