import { AuthServiceAbstract, useAuthState } from '@kaa/auth/common';
import { useAsync } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  SwColumn,
  SwGrid,
  SwLayout,
  SwLoader,
  SwRegion,
} from '@kaa/ui-flanders/components';
import { RouteComponentProps } from '@reach/router';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const LoginRedirectScreen: React.FC<RouteComponentProps> = () => {
  const { signinRedirect } = useAuthState<AuthServiceAbstract>();
  const { t } = useTranslation();

  const { loading } = useAsync(() => signinRedirect(), [signinRedirect]);

  if (loading) {
    return (
      <SwRegion>
        <SwLayout>
          <SwGrid modStacked>
            <SwColumn>
              <SwLoader message={t(i18nKeys.general.pageLoading)} />
            </SwColumn>
          </SwGrid>
        </SwLayout>
      </SwRegion>
    );
  }

  return null;
};
