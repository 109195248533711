import { RouteActive } from '@kaa/common/types';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import { SwFunctionalHeader, SwTabs } from '@kaa/ui-flanders/components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getPath, getRoute, Routes } from '../../routes';
import {
  EventAction,
  sendMainNavigationEventToGTM,
} from '../../utils/google-analytics';
import './VlFunctionalHeader.style.scss';
import { VlFunctionHeaderTab } from './VlFunctionalHeader.types';
import { getTabs } from './VlFunctionalHeader.utils';

type VlFunctionalHeaderProps = {
  activeRoute?: RouteActive<Routes> | null;
};

export const VlFunctionalHeader = ({
  activeRoute,
}: VlFunctionalHeaderProps) => {
  const { t } = useTranslation();
  const [tabs, setTabs] = useState<VlFunctionHeaderTab[]>();

  useEffect(() => {
    if (activeRoute) {
      setTabs(getTabs(activeRoute.route.id, t));
    }
  }, [activeRoute, t]);

  if (!tabs || !activeRoute) {
    return null;
  }

  return (
    <SwFunctionalHeader
      title={t(i18nKeys.general.appTitle)}
      modBorder
      subActions={
        tabs && (
          <SwTabs
            tabs={[
              ...tabs.map(({ id, active, ...rest }) => ({
                ...rest,
                id,
                active,
                to: getPath(id, { params: activeRoute.params }),
                isActive: active || id === activeRoute.route.id,
                onClick: () => {
                  const route = getRoute(id);
                  if (route.parent) {
                    sendMainNavigationEventToGTM(route.parent.id, id);
                  } else {
                    sendMainNavigationEventToGTM(
                      activeRoute.route.id === Routes.DASHBOARD
                        ? EventAction.CLICK
                        : EventAction.BACK,
                      id,
                    );
                  }
                },
              })),
            ]}
          />
        )
      }
    />
  );
};
