import {
  GetWorkerServicesHistoryParams,
  PaginationDirectives,
  Service,
  ServiceStatus,
  Sorting,
  WorkerDetail,
} from '@kaa/api/providers';
import { useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  SwColumn,
  SwDataTable,
  SwFetchErrorMessage,
  SwGrid,
  SwLink,
  SwLoader,
  SwPaginator,
  SwTitle,
} from '@kaa/ui-flanders/components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableRowLoader, TableRowNoResult } from '../../../../components';
import { getPath, Routes } from '../../../../routes';
import { useApi } from '../../../../utils';
import { EventCategory } from '../../../../utils/google-analytics';
import {
  PrestationsTableHead,
  PrestationsTableHeadElement,
} from '../../../prestations/components/PrestationsTableHead';
import { PrestationsTableRow } from '../../../prestations/components/PrestationsTableRow';
import { PrestationsHistoryTableRowStatus } from '../../../prestations/history/PrestationsHistoryTableRowStatus';

const WORKS_INFORMATION_PAGE_SIZE = 10;

type WorksInformationProps = {
  workerDetails: WorkerDetail;
};

export const WorksInformation = ({ workerDetails }: WorksInformationProps) => {
  const { t } = useTranslation();
  const { providers } = useApi();

  const { providerId, id: workerId } = workerDetails;

  const initialParameters = {
    status: Object.keys(ServiceStatus) as ServiceStatus[],
    pageSize: WORKS_INFORMATION_PAGE_SIZE,
    pageNumber: 1,
  };

  const [parameters, setParameters] = useState<GetWorkerServicesHistoryParams>(
    initialParameters,
  );

  const [pagination, setPagination] = useState<
    PaginationDirectives['paginationDirectives']
  >();

  const [services, setServices] = useState<Service[]>([]);

  const [display, setDisplay] = useState(false);

  const [
    { value: servicesHistoryResponse, loading, error },
    getWorkerServicesHistory,
  ] = useAsyncCallback(
    async (servicesHistoryParams: GetWorkerServicesHistoryParams) =>
      (
        await providers.getWorkerServicesHistory(
          Number(providerId),
          Number(workerId),
          servicesHistoryParams,
        )
      ).data,
    [providers],
    { loading: false },
  );

  useEffect(() => {
    if (display) {
      getWorkerServicesHistory(parameters);
    }
  }, [display, parameters]);

  useEffect(() => {
    if (servicesHistoryResponse) {
      const {
        paginationDirectives,
        data: { values },
      } = servicesHistoryResponse;

      setServices(() => values);
      setPagination(() => paginationDirectives);
    }
  }, [servicesHistoryResponse]);

  if (loading && !servicesHistoryResponse) {
    return (
      <>
        <SwTitle tagName="h3" tagStyle="h4" className="vl-u-spacer--small">
          {t(i18nKeys.workers.worksInformation.title)}
        </SwTitle>
        <p className="vl-u-spacer--small">
          {t(i18nKeys.workers.worksInformation.subtitle)}
        </p>
        <SwGrid modStacked className="vl-u-spacer--medium">
          <SwColumn>
            <SwLoader />
          </SwColumn>
        </SwGrid>
        <hr className="vl-u-spacer--medium" />
      </>
    );
  }

  if (error && !servicesHistoryResponse) {
    return (
      <>
        <SwTitle tagName="h3" tagStyle="h4" className="vl-u-spacer--small">
          {t(i18nKeys.workers.worksInformation.title)}
        </SwTitle>
        <p className="vl-u-spacer--small">
          {t(i18nKeys.workers.worksInformation.subtitle)}
        </p>
        <SwGrid modStacked className="vl-u-spacer--medium">
          <SwColumn>
            <SwFetchErrorMessage onClick={() => setDisplay(true)} />
          </SwColumn>
        </SwGrid>
        <hr className="vl-u-spacer--medium" />
      </>
    );
  }

  if (!servicesHistoryResponse || !parameters) {
    return (
      <>
        <SwTitle tagName="h3" tagStyle="h4" className="vl-u-spacer--small">
          {t(i18nKeys.workers.worksInformation.title)}
        </SwTitle>
        <p className="vl-u-spacer--small">
          {t(i18nKeys.workers.worksInformation.subtitle)}
        </p>
        <SwGrid modStacked className="vl-u-spacer--medium">
          <SwColumn>
            <SwLink onClick={() => setDisplay(true)}>
              {t(i18nKeys.workers.worksInformation.cta)}
            </SwLink>
          </SwColumn>
        </SwGrid>
        <hr className="vl-u-spacer--medium" />
      </>
    );
  }

  const head = [
    {
      text: t(i18nKeys.general.label.id),
    },
    {
      text: t(i18nKeys.general.label.date),
      sorting: Sorting.DATE_ASC,
    },
    {
      text: t(i18nKeys.general.label.creationDate),
      sorting: Sorting.CREATION_DATE_ASC,
    },
    {
      text: t(i18nKeys.general.label.customer),
      sorting: Sorting.CUSTOMER_ASC,
    },
    { text: t(i18nKeys.general.label.activity) },
    { text: t(i18nKeys.general.label.time) },
    { text: t(i18nKeys.general.label.status) },
  ] as PrestationsTableHeadElement[];

  return (
    <>
      <SwTitle tagName="h3" tagStyle="h4" className="vl-u-spacer--small">
        {t(i18nKeys.workers.worksInformation.title)}
      </SwTitle>
      <p className="vl-u-spacer--small">
        {t(i18nKeys.workers.worksInformation.subtitle)}
      </p>
      <SwGrid modStacked className="vl-u-spacer--medium">
        <SwColumn>
          <div className="vl-u-table-overflow">
            <SwDataTable modLine style={{ border: '.1rem solid #cbd2da' }}>
              <PrestationsTableHead<GetWorkerServicesHistoryParams>
                head={head}
                parameters={parameters}
                setParameters={setParameters}
                eventCategory={EventCategory.PRESTATIONS_HISTORY}
              />
              <tbody>
                {loading ? (
                  <TableRowLoader colSpan={head.length} />
                ) : (
                  <>
                    {!services || !services.length ? (
                      <TableRowNoResult colSpan={head.length} />
                    ) : (
                      services.map((service) => (
                        <PrestationsTableRow
                          key={service.id}
                          service={service}
                          providerId={providerId}
                          showActivity
                        >
                          <PrestationsHistoryTableRowStatus service={service} />
                        </PrestationsTableRow>
                      ))
                    )}
                  </>
                )}
              </tbody>
            </SwDataTable>
          </div>
        </SwColumn>
        <SwColumn>
          <div className="vl-u-display-flex">
            <SwLink to={getPath(Routes.PRESTATIONS_HISTORY)}>
              {t(i18nKeys.workers.worksInformation.linkToHistory)}
            </SwLink>
            {!!pagination && (
              <div style={{ marginLeft: 'auto' }}>
                <SwPaginator
                  itemCount={pagination.numberOfItems}
                  pageSize={parameters.pageSize}
                  selectedPage={pagination.pageNumber}
                  setPage={(page) => {
                    setParameters((currentParameters) =>
                      currentParameters
                        ? {
                            ...currentParameters,
                            pageSize: currentParameters.pageSize,
                            pageNumber:
                              typeof page === 'number'
                                ? page
                                : page(currentParameters.pageNumber || 1),
                          }
                        : initialParameters,
                    );
                  }}
                />
              </div>
            )}
          </div>
        </SwColumn>
      </SwGrid>
      <hr className="vl-u-spacer--medium" />
    </>
  );
};
