import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import { SwTitle } from '@kaa/ui-flanders/components';
import { DownloadTemplates } from '@kaa/core-app/providers/components';
import { FileExtensionReference } from '@kaa/api/providers';
import { Modals } from '../../../../constants';
import { getApplicationType } from '../../../../utils/templates';
import { sendConfirmationModalViewEvent } from '../../../../utils/google-analytics';

type PrestationExportModalConfirmationProps = {
  data: Blob;
  fileExtension?: FileExtensionReference;
};

export const PrestationExportModalConfirmation = ({
  data,
  fileExtension = FileExtensionReference.XLSX,
}: PrestationExportModalConfirmationProps) => {
  const { t } = useTranslation();
  useEffect(() => {
    sendConfirmationModalViewEvent(
      Modals.PRESTATIONS_EXPORT_LIST_PRESTATION_MODAL,
    );
  }, []);

  const blob = new Blob([data], {
    type: getApplicationType(fileExtension),
  });

  const url = window.URL.createObjectURL(blob);

  return (
    <>
      <SwTitle tagName="h2">
        {t(i18nKeys.prestations.modals.export.title)}
      </SwTitle>
      <p className="vl-u-spacer">
        {t(i18nKeys.prestations.modals.export.subtitle)}
      </p>
      <DownloadTemplates
        fromModal={Modals.PRESTATIONS_EXPORT_LIST_PRESTATION_MODAL}
        linkToTemplate={url}
        fileName={`${t(
          i18nKeys.prestations.modals.export.downloadTemplate.fileName,
        )}`}
        titleDownloadTemplate={t(i18nKeys.general.modal.export.cta.download)}
        titleOpenTemplate={t(i18nKeys.general.modal.export.cta.openNewTab)}
        blob={blob}
      />
    </>
  );
};
