import { ContactPerson, Title } from '@kaa/api/providers';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  Icon,
  SwColumn,
  SwFormColumn,
  SwFormGrid,
  SwFormInput,
  SwFormMessageLabel,
  SwGrid,
  SwIcon,
  SwInputField,
  SwLink,
  SwTitle,
} from '@kaa/ui-flanders/components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContactPersonsFormFieldNames } from '../../../components/contact-persons/ContactPersonForm.constant';
import { getFieldName } from '../../../components/contact-persons/ContactPersonForm.utils';
import { PickupBillingContactForm } from './PickupBillingContactForm';

type PickupBillingContactProps = {
  titles: Title[];
  financialContact: ContactPerson;
};

export const PickupBillingContact = (props: PickupBillingContactProps) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const {
    financialContact: { emailAddress },
  } = props;

  return (
    <SwGrid
      modStacked
      style={{
        width: '100%',
        justifyContent: 'flex-start',
      }}
    >
      <SwColumn width="10" widthS="12">
        <div className="vl-u-display-flex vl-u-spacer--small">
          <SwTitle tagName="h2" style={{ margin: 0 }}>
            {t(i18nKeys.pickup.billing.contact.title)}
          </SwTitle>
          <SwLink
            tagName="button"
            onClick={() => setIsOpen((current) => !current)}
            style={{ marginLeft: 'auto', border: 'none' }}
          >
            {t(i18nKeys.pickup.billing.contact.moreDetails)}
            <SwIcon
              modLarge
              icon={Icon.ARROW_DOWN}
              style={
                isOpen
                  ? {
                      display: 'block',
                      transition: 'ease transform .4s',
                      transform: 'rotate(180deg)',
                    }
                  : {
                      display: 'block',
                      transition: 'ease transform .4s',
                      transform: 'rotate(0)',
                    }
              }
            />
          </SwLink>
        </div>
      </SwColumn>
      <SwColumn width="10" widthS="12">
        {!isOpen ? (
          <SwFormGrid modStacked onClick={() => setIsOpen(true)}>
            <SwFormColumn>
              <SwFormGrid>
                <SwFormColumn width="3" widthS="12">
                  <SwFormMessageLabel
                    htmlFor={t(i18nKeys.general.label.emailAddress)}
                  >
                    {t(i18nKeys.general.label.emailAddress)}
                  </SwFormMessageLabel>
                </SwFormColumn>
                <SwFormColumn width="9" widthS="12">
                  <SwFormInput>
                    <SwInputField
                      id={getFieldName(
                        ContactPersonsFormFieldNames.EMAIL_ADDRESS,
                      )}
                      value={emailAddress}
                      modBlock
                    />
                  </SwFormInput>
                </SwFormColumn>
              </SwFormGrid>
            </SwFormColumn>
          </SwFormGrid>
        ) : (
          <PickupBillingContactForm {...props} />
        )}
      </SwColumn>
    </SwGrid>
  );
};
