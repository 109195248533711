import {
  FileExtensionReference,
  FileStatus,
  WorkersFileStatusResponseData,
} from '@kaa/api/providers';
import { httpTo } from '@kaa/api/providers/utilities';
import { useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AlertType,
  getAlertPropsByType,
  SwAlert,
  SwDocument,
  SwLoader,
  SwTitle,
} from '@kaa/ui-flanders/components';
import React, { PropsWithChildren, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DownloadReport } from '../../../../components/downloadReport/DownloadReport';
import { Modals } from '../../../../constants';
import { useApi } from '../../../../utils';
import { sendConfirmationModalViewEvent } from '../../../../utils/google-analytics';
import { getApplicationType } from '../../../../utils/templates';

type AddWorkerListModalConfirmationProps = {
  providerId: number;
  fileStatus: WorkersFileStatusResponseData;
};

export const AddWorkerListModalConfirmation = ({
  providerId,
  fileStatus,
  children,
}: PropsWithChildren<AddWorkerListModalConfirmationProps>) => {
  const { t } = useTranslation();

  useEffect(() => {
    sendConfirmationModalViewEvent(Modals.WORKERS_IMPORT_LIST_WORKER_MODAL);
  }, []);

  const { providers: providersApi } = useApi();

  const [
    { value: reportFile, loading: reportLoading },
    getReport,
  ] = useAsyncCallback(
    async (fileExtension: FileExtensionReference) => {
      const [, payload] = await httpTo(
        providersApi.exportFileWorkers(providerId, {
          fileExtension,
        }),
      );

      if (!payload) {
        return undefined;
      }

      const blob = new Blob([payload.data as BlobPart], {
        type: getApplicationType(fileExtension || FileExtensionReference.XLSX),
      });

      const url = window.URL.createObjectURL(blob);

      return { url, blob };
    },
    [providersApi],
  );

  const { status, totalRows, created, updated, invalid, partial } = fileStatus;

  const {
    created: keyCreated,
    updated: keyUpdated,
    refused: keyInvalid,
    partial: keyPartial,
  } = i18nKeys.workers.modals.addWorker.confirmation.alert.error;

  const downloadExtensions = [
    FileExtensionReference.XLSX,
    FileExtensionReference.CSV,
  ];

  return (
    <>
      {children}
      {status === FileStatus.Done && (
        <SwAlert
          {...getAlertPropsByType(AlertType.SUCCESS)}
          className="vl-u-spacer"
          title={t(
            i18nKeys.workers.modals.addWorker.confirmation.alert.success.title,
          )}
        >
          <p>
            {created > 0 && (
              <>
                {`${t(keyCreated)} ${created}/${totalRows}`}
                {updated > 0 && <br />}
              </>
            )}
            {updated > 0 && <>{`${t(keyUpdated)} ${updated}/${totalRows}`}</>}
          </p>
        </SwAlert>
      )}
      {status === FileStatus.DoneWithError && (
        <SwAlert
          {...getAlertPropsByType(AlertType.ERROR)}
          className="vl-u-spacer"
          title={t(
            i18nKeys.workers.modals.addWorker.confirmation.alert.error.title,
          )}
        >
          <p>
            {created > 0 && (
              <>
                {`${t(keyCreated)} ${created}/${totalRows}`}
                <br />
              </>
            )}
            {updated > 0 && (
              <>
                {`${t(keyUpdated)} ${updated}/${totalRows}`}
                <br />
              </>
            )}
            {invalid > 0 && (
              <>
                {`${t(keyInvalid)} ${invalid}/${totalRows}`}
                <br />
              </>
            )}
            {partial > 0 && (
              <>
                {`${t(keyPartial)} ${partial}/${totalRows}`}
                <br />
              </>
            )}
            <br />
            {t(
              i18nKeys.workers.modals.addWorker.confirmation.alert.error
                .messageBottom,
            )}
          </p>
        </SwAlert>
      )}
      {reportLoading ? (
        <SwLoader modMessageHidden />
      ) : (
        <>
          {reportFile ? (
            <>
              <SwTitle tagName="h5" className="vl-u-spacer--small">
                {t(
                  i18nKeys.workers.modals.addWorker.confirmation.downloadReport
                    .title,
                )}
              </SwTitle>
              <DownloadReport
                modalId={Modals.WORKERS_IMPORT_LIST_WORKER_MODAL}
                reportFile={reportFile.blob}
                reportUrl={reportFile.url}
                reportName={t(
                  i18nKeys.workers.modals.addWorker.confirmation.downloadReport
                    .fileName,
                )}
                reportLabel={t(
                  i18nKeys.workers.modals.addWorker.confirmation.downloadReport
                    .linkTitle,
                )}
              />
            </>
          ) : (
            <>
              <SwTitle tagName="h5" className="vl-u-spacer--small">
                {t(
                  i18nKeys.workers.modals.addWorker.confirmation.downloadReport
                    .generate,
                )}
              </SwTitle>
              <div className="vl-u-display-flex">
                {downloadExtensions.map(
                  (fileExtension: FileExtensionReference, i) => (
                    <SwDocument
                      key={fileExtension}
                      style={i > 0 ? { marginLeft: '2rem' } : undefined}
                      mimeType={fileExtension}
                      title={t(i18nKeys.general.label.format, {
                        format: fileExtension.toLowerCase(),
                      })}
                      onClick={(e) => {
                        e.preventDefault();
                        getReport(fileExtension);
                      }}
                    />
                  ),
                )}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
