import { ProviderUpdate } from '@kaa/api/providers';

export const RelativeDetailsScreenFieldNames = {
  IBAN: 'iban' as keyof ProviderUpdate,
  PHONE_NUMBER: 'phoneNumber' as keyof ProviderUpdate,
  MOBILE_PHONE_NUMBER: 'mobilePhoneNumber' as keyof ProviderUpdate,
  COMMERCIAL_EMAIL_ADDRESS: 'commercialEmailAddress' as keyof ProviderUpdate,
  WEBSITE: 'website' as keyof ProviderUpdate,
  NAME: 'name' as keyof ProviderUpdate,
  COMMERCIAL_NAME: 'commercialName' as keyof ProviderUpdate,
  ID: 'id' as keyof ProviderUpdate,
  VAT_NUMBER: 'vatNumber' as keyof ProviderUpdate,
};
