import { ServicesResponseDataDataValuesItem } from '@kaa/api/providers';
import { useLuxon } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AlertType,
  getAlertPropsByType,
  SwBadge,
  SwCheckboxField,
  SwGrid,
  useTooltip,
} from '@kaa/ui-flanders/components';
import { Field } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ElectronicManualRemittanceResultsFieldNames } from './RemittancesManualModal.constants';

type RemittancesManualModalResultsFormRowProps = {
  service: ServicesResponseDataDataValuesItem;
  allServicesSelected?: boolean;
};

export const RemittancesManualModalResultsFormRow = ({
  service,
  allServicesSelected,
}: RemittancesManualModalResultsFormRowProps) => {
  const { t } = useTranslation();
  const { DateTime } = useLuxon();

  const {
    id,
    serviceDate,
    customerFullname,
    workerFullname,
    durationInHours,
    hasVoucherExpiringSoon,
    remittableVouchers,
    isFullyAssigned,
  } = service;

  const refExpirationTooltip = useTooltip({
    value: t(i18nKeys.remittances.modal.manual.results.tooltip),
    placement: 'right',
  });

  const refNotFullyAssignTooltip = useTooltip({
    value: t(i18nKeys.remittances.modal.manual.results.tooltip2),
    placement: 'right',
  });

  const badgeBase = {
    modXsmall: true,
    style: {
      marginLeft: '.5rem',
      height: '2.2rem',
      width: '2.2rem',
      padding: 0,
    },
  };

  const badgeWarning = {
    ...badgeBase,
    ...getAlertPropsByType(AlertType.WARNING),
  };

  const badgeInformation = {
    ...badgeBase,
    ...getAlertPropsByType(AlertType.INFORMATION),
    modAction: true,
  };

  return (
    <tr>
      <td style={{ width: '17rem' }}>
        {hasVoucherExpiringSoon ? (
          <strong>
            <SwGrid modStart>
              <Field
                style={{ display: 'inline-block' }}
                name={`${ElectronicManualRemittanceResultsFieldNames.BUNDLE}.id-${id}`}
                label={DateTime.fromISO(serviceDate).toFormat('dd/LL/yy')}
                column={{ width: '0' }}
                component={SwCheckboxField}
                modDisabled={allServicesSelected}
                modVisualChecked={allServicesSelected}
              />
              <SwBadge {...badgeWarning} ref={refExpirationTooltip} />
              {!isFullyAssigned && (
                <SwBadge {...badgeInformation} ref={refNotFullyAssignTooltip} />
              )}
            </SwGrid>
          </strong>
        ) : (
          <SwGrid modStart>
            <Field
              // keep -id at the end bug from formik that try to create and array the key is a number
              style={{ display: 'inline-block' }}
              name={`${ElectronicManualRemittanceResultsFieldNames.BUNDLE}.id-${id}`}
              label={DateTime.fromISO(serviceDate).toFormat('dd/LL/yy')}
              column={{ width: '0' }}
              component={SwCheckboxField}
              modDisabled={allServicesSelected}
              modVisualChecked={allServicesSelected}
            />
            {!isFullyAssigned && (
              <SwBadge {...badgeInformation} ref={refNotFullyAssignTooltip} />
            )}
          </SwGrid>
        )}
      </td>
      <td>{customerFullname}</td>
      <td>{workerFullname}</td>
      <td className="vl-u-align-center">{remittableVouchers}</td>
      <td className="vl-u-align-center">{durationInHours}</td>
    </tr>
  );
};
