/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */

export type RemittanceStatus =
  | 'TO_BE_PROCESSED'
  | 'CAN_BE_REPAID'
  | 'REPAID'
  | 'CONFRONTATION_ERROR'
  | 'REPAYMENT_ERROR'
  | 'QUOTA_REPAID'
  | 'TO_BE_VALIDATED'
  | 'REJECTED'
  | 'CANCELED'
  | 'OCR_DONE'
  | 'OCR_ERROR'
  | 'TO_SEND_BACK'
  | 'SENT_BACK'
  | 'IN_CONTROL';

export const RemittanceStatus = {
  TO_BE_PROCESSED: 'TO_BE_PROCESSED' as RemittanceStatus,
  CAN_BE_REPAID: 'CAN_BE_REPAID' as RemittanceStatus,
  REPAID: 'REPAID' as RemittanceStatus,
  CONFRONTATION_ERROR: 'CONFRONTATION_ERROR' as RemittanceStatus,
  REPAYMENT_ERROR: 'REPAYMENT_ERROR' as RemittanceStatus,
  QUOTA_REPAID: 'QUOTA_REPAID' as RemittanceStatus,
  TO_BE_VALIDATED: 'TO_BE_VALIDATED' as RemittanceStatus,
  REJECTED: 'REJECTED' as RemittanceStatus,
  CANCELED: 'CANCELED' as RemittanceStatus,
  OCR_DONE: 'OCR_DONE' as RemittanceStatus,
  OCR_ERROR: 'OCR_ERROR' as RemittanceStatus,
  TO_SEND_BACK: 'TO_SEND_BACK' as RemittanceStatus,
  SENT_BACK: 'SENT_BACK' as RemittanceStatus,
  IN_CONTROL: 'IN_CONTROL' as RemittanceStatus,
};
