import {
  GetServicesHistoryParams,
  GetWorkersServicesParams,
  Relative,
  ServiceActiveStatus,
  ServiceInactiveStatus,
  ServiceStatus,
} from '@kaa/api/providers';
import { required } from '@kaa/common/validation';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  Icon,
  SwActionGroup,
  SwButton,
  SwColumn,
  SwForm,
  SwFormGrid,
  SwFormMessageLabel,
  SwFormSubmitMessage,
  SwInputCalendarField,
  SwInputCalendarFieldFormatDate,
  SwInputCalendarFieldParseDate,
  SwInputTextField,
  SwLink,
  SwPill,
  SwSelect,
  SwSelectField,
} from '@kaa/ui-flanders/components';
import { Field, Formik, FormikActions } from 'formik';
import React, { ChangeEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createValidatorWithServerErrorHandled } from '../../../utils/validation';
import {
  getActivityTypeOptions,
  getMaxEndDate,
  getMaxStartDate,
  getMinEndDate,
  getMinStartDate,
  getRelativesOptions,
} from './PrestationsForm.utils';

type PrestationsFormProps = {
  initialParameters: GetWorkersServicesParams | GetServicesHistoryParams;
  relatives: Relative[];
  isSubsidiary: boolean;
  isLoading: boolean;
  voucherStatusOptions: Array<{
    value: ServiceActiveStatus | ServiceInactiveStatus;
    text: string;
  }>;
  submit: (
    params: GetWorkersServicesParams | GetServicesHistoryParams,
    formikActions: FormikActions<
      GetWorkersServicesParams | GetServicesHistoryParams
    >,
  ) => void;
  resetPrestationForm: () => void;
};

type PrestationsForm = GetWorkersServicesParams & GetServicesHistoryParams;

const PrestationsFormFieldNames = {
  STATUS: 'status' as keyof PrestationsForm,
  SEARCHED_PROVIDER_ID: 'searchedProviderId' as keyof PrestationsForm,
  START_DATE: 'startDate' as keyof PrestationsForm,
  END_DATE: 'endDate' as keyof PrestationsForm,
  WORKER_NAME_OR_NISS: 'workerNameOrNiss' as keyof PrestationsForm,
  CUSTOMER_NAME_OR_CONTRACT_ID: 'customerNameOrContractId' as keyof PrestationsForm,
  ACTIVITY: 'activity' as keyof PrestationsForm,
};

export const PrestationsForm = ({
  isLoading = false,
  initialParameters,
  isSubsidiary,
  relatives,
  submit,
  voucherStatusOptions,
  resetPrestationForm,
}: PrestationsFormProps) => {
  const { t } = useTranslation();

  const [selectedStatus, setSelectedStatus] = useState<
    ServiceStatus[] | undefined
  >(initialParameters.status);

  const refSelect = useRef<HTMLSelectElement | null>(null);

  const initialValues = { startDate: '', endDate: '', ...initialParameters };

  const validate = createValidatorWithServerErrorHandled({
    startDate: [required],
    endDate: [required],
  });

  return (
    <SwColumn
      className="vl-u-bg-alt vl-u-spacer--medium"
      style={{ padding: '2rem' }}
    >
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={submit}
        enableReinitialize
        render={({
          handleSubmit,
          dirty,
          isSubmitting,
          values,
          setFieldValue,
          resetForm,
        }) => {
          setSelectedStatus(values.status);
          return (
            <SwForm onSubmit={handleSubmit}>
              <SwFormGrid
                modStacked
                className="vl-u-display-flex vl-u-spacer--small"
              >
                {!isSubsidiary && (
                  <SwColumn width="3" widthM="6" widthS="12">
                    <Field
                      column={{ width: '12' }}
                      labelColumn={{ width: '12' }}
                      name={PrestationsFormFieldNames.SEARCHED_PROVIDER_ID}
                      label={t(
                        i18nKeys.remittances.general.reimbursementCenter,
                      )}
                      component={SwSelectField}
                      options={getRelativesOptions(relatives)}
                    />
                  </SwColumn>
                )}
                <SwColumn width="3" widthM="6" widthS="12">
                  <Field
                    column={{ width: '12' }}
                    labelColumn={{ width: '12' }}
                    name={
                      PrestationsFormFieldNames.CUSTOMER_NAME_OR_CONTRACT_ID
                    }
                    component={SwInputTextField}
                    placeholder={t(
                      i18nKeys.prestations.search.form.labels
                        .customerNameAndNumber,
                    )}
                    label={t(i18nKeys.general.label.customer)}
                    maxLength="15"
                  />
                </SwColumn>
                <SwColumn width="3" widthM="6" widthS="12">
                  <Field
                    column={{ width: '12' }}
                    labelColumn={{ width: '12' }}
                    name={PrestationsFormFieldNames.WORKER_NAME_OR_NISS}
                    component={SwInputTextField}
                    placeholder={t(
                      i18nKeys.prestations.search.form.labels.workerNameOrNiss,
                    )}
                    label={t(
                      i18nKeys.prestations.search.form.labels.workerNameOrNiss,
                    )}
                    maxLength="15"
                  />
                </SwColumn>
                <SwColumn width="3" widthM="6" widthS="12">
                  <Field
                    column={{ width: '12' }}
                    labelColumn={{ width: '12' }}
                    name={PrestationsFormFieldNames.ACTIVITY}
                    component={SwSelectField}
                    label={t(i18nKeys.general.label.activity)}
                    options={getActivityTypeOptions(t)}
                  />
                </SwColumn>
              </SwFormGrid>
              <SwFormGrid modStacked className="vl-u-display-flex">
                <SwColumn width="3" widthM="6" widthS="12">
                  <SwFormMessageLabel>
                    {t(i18nKeys.prestations.modals.export.form.labels.status)}
                  </SwFormMessageLabel>
                  <SwSelect
                    ref={refSelect}
                    modBlock
                    onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                      const newStatus = [
                        event.target.value,
                        ...(values.status || []),
                      ];
                      setFieldValue(
                        PrestationsFormFieldNames.STATUS,
                        newStatus.filter(
                          (status, index) =>
                            newStatus.indexOf(status) === index,
                        ),
                      );
                      if (
                        refSelect.current &&
                        refSelect.current?.selectedIndex
                      ) {
                        refSelect.current.selectedIndex = 0;
                      }
                    }}
                  >
                    {voucherStatusOptions.map(({ value, text }, i) => {
                      return (
                        <option key={`${text}${i}`} value={value}>
                          {text}
                        </option>
                      );
                    })}
                  </SwSelect>
                </SwColumn>
                <SwColumn width="6" widthM="12">
                  <SwFormGrid modStacked>
                    <SwColumn width="6" widthS="12">
                      <Field
                        name={PrestationsFormFieldNames.START_DATE}
                        label={t(
                          i18nKeys.prestations.search.form.labels
                            .prestationDateStart,
                        )}
                        component={SwInputCalendarField}
                        parse={SwInputCalendarFieldParseDate}
                        format={SwInputCalendarFieldFormatDate}
                        minDate={getMinStartDate(values)}
                        maxDate={getMaxStartDate(values)}
                        column={{ width: '12' }}
                        labelColumn={{ width: '12' }}
                        placeholder={t(
                          i18nKeys.general.placeholder.datePicker.start,
                        )}
                      />
                    </SwColumn>
                    <SwColumn width="6" widthS="12">
                      <Field
                        name={PrestationsFormFieldNames.END_DATE}
                        label={t(
                          i18nKeys.prestations.search.form.labels
                            .prestationDateEnd,
                        )}
                        component={SwInputCalendarField}
                        parse={SwInputCalendarFieldParseDate}
                        format={SwInputCalendarFieldFormatDate}
                        minDate={getMinEndDate(values)}
                        maxDate={getMaxEndDate(values)}
                        column={{ width: '12' }}
                        labelColumn={{ width: '12' }}
                        placeholder={t(
                          i18nKeys.general.placeholder.datePicker.end,
                        )}
                      />
                    </SwColumn>
                  </SwFormGrid>
                </SwColumn>
                <SwColumn
                  width="12"
                  widthM="12"
                  widthS="12"
                  style={{ alignSelf: 'flex-end' }}
                >
                  <div>
                    {selectedStatus &&
                      selectedStatus.map(
                        (
                          status: ServiceActiveStatus | ServiceInactiveStatus,
                          i: number,
                        ) => (
                          <SwPill
                            key={`${PrestationsFormFieldNames.STATUS}${i}`}
                            modClosable
                            id={`${PrestationsFormFieldNames.STATUS}-${status}`}
                            className="vl-u-spacer--xsmall"
                            style={{
                              marginRight: '1rem',
                              backgroundColor: '#fff',
                            }}
                            aria-label={`${t(i18nKeys.general.cta.delete)} ${t(
                              i18nKeys.general.serviceStatus[status],
                            )}`}
                            onClick={() => {
                              const newStatus = selectedStatus.filter(
                                (s) => s !== status,
                              );
                              setSelectedStatus(newStatus);
                              setFieldValue(
                                PrestationsFormFieldNames.STATUS,
                                newStatus,
                              );
                            }}
                          >
                            {t(i18nKeys.general.serviceStatus[status])}
                          </SwPill>
                        ),
                      )}
                  </div>
                  <SwActionGroup modCollapseS>
                    <SwButton
                      type="submit"
                      modLoading={isLoading || isSubmitting}
                      modDisabled={isLoading || isSubmitting || !dirty}
                      modIconBefore
                      icon={Icon.SYNCHRONIZE}
                    >
                      {t(i18nKeys.general.search)}
                    </SwButton>
                    <SwLink
                      type="button"
                      onClick={() => {
                        resetForm();
                        resetPrestationForm();
                        if (
                          refSelect.current &&
                          refSelect.current?.selectedIndex
                        ) {
                          refSelect.current.selectedIndex = 0;
                        }
                      }}
                    >
                      {t(i18nKeys.general.label.reset)}
                    </SwLink>
                  </SwActionGroup>
                </SwColumn>
                <SwFormSubmitMessage />
              </SwFormGrid>
            </SwForm>
          );
        }}
      />
    </SwColumn>
  );
};
