export enum PrestationFormFieldNames {
  PROVIDER_ID = 'providerId',
  SERVICE_DATE = 'serviceDate',
  CUSTOMER_ID = 'customerId',
  CUSTOMER_NUMBER = 'customerContractId',
  CUSTOMER_NAME = 'customerFullname',
  WORKER_ID = 'workerId',
  WORKER_NISS = 'workerNiss',
  WORKER_NAME = 'workerFullname',
  ACTIVITY = 'activity',
  DURATION = 'durationInHours',
  COMMENT = 'comment',
}
