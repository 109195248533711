import {
  DashboardRemittancesActions,
  DashboardRemittancesActionsIncorrect,
  DashboardRemittancesActionsRefused,
  DashboardWorkersActions,
  DashboardWorksActions,
  RegionCode,
  VoucherStatus,
} from '@kaa/api/providers';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import { Icon, SwColumn } from '@kaa/ui-flanders/components';
import { TFunction } from 'i18next';
import { TransProps } from 'react-i18next';
import { getPath, QueryParams, Routes } from '../../routes';
import { DashboardActionsListItem, DashboardTips } from './Dashboard.types';

export const overviewCardChildrenProps = {
  tagName: SwColumn,
  column: { width: '12' },
  style: { background: 'none' },
};

const getActionsListWorksItem = (
  key: keyof DashboardWorksActions,
  value: number | undefined,
): DashboardActionsListItem | undefined => {
  const el = {
    key,
    tracking: `works ${key}`,
    text: {
      i18nKey: i18nKeys.dashboard.actions.works[key],
      tOptions: {
        value,
      },
    } as TransProps,
  };

  switch (key) {
    case 'aboutToExpire':
      return {
        ...el,
        url: getPath(Routes.REMITTANCES),
      };
    case 'contested':
    case 'waitingForValidation':
      return {
        ...el,
        url: getPath(Routes.PRESTATIONS, {
          queryParams: {
            [QueryParams.FILTER]: key,
          },
        }),
      };
    default:
      return undefined;
  }
};

const getActionsListRemittancesItem = (
  key: keyof DashboardRemittancesActions,
  value:
    | DashboardRemittancesActionsIncorrect
    | DashboardRemittancesActionsRefused
    | undefined,
): DashboardActionsListItem | undefined => {
  if (!value) {
    return undefined;
  }

  const { remittances, vouchers } = value;
  const el = {
    key,
    tracking: `works ${key}`,
    text: {
      i18nKey:
        remittances > 1
          ? i18nKeys.dashboard.actions.remittances[key]
          : i18nKeys.dashboard.actions.remittances.single[key],
      tOptions: {
        remittances,
        vouchers,
      },
    } as TransProps,
  };

  switch (key) {
    case 'refused': {
      return {
        ...el,
        url: getPath(Routes.REMITTANCES, {
          queryParams: {
            [QueryParams.STATUS]: VoucherStatus.REFUSED,
            [QueryParams.MONTHS]: 12,
          },
        }),
      };
    }
    case 'incorrect': {
      return {
        ...el,
        url: getPath(Routes.REMITTANCES, {
          queryParams: {
            [QueryParams.STATUS]: VoucherStatus.INCORRECT,
            [QueryParams.MONTHS]: 12,
          },
        }),
      };
    }
    default:
      return undefined;
  }
};

const getActionsListWorkersItem = (
  key: keyof DashboardWorkersActions,
  value: number | undefined,
): DashboardActionsListItem | undefined => {
  const el = {
    key,
    tracking: `works ${key}`,
    text: {
      i18nKey: i18nKeys.dashboard.actions.workers[key],
      tOptions: {
        value,
      },
    } as TransProps,
  };

  switch (key) {
    case 'needResetPassword':
    case 'missingContract':
      return {
        ...el,
        url: getPath(Routes.WORKERS, {
          queryParams: {
            [QueryParams.STATUS]: key,
          },
        }),
      };
    default:
      return undefined;
  }
};

export const getActionsListWorks = (
  list: DashboardWorksActions,
): DashboardActionsListItem[] => {
  return Object.keys(list).reduce((acc, key) => {
    const content = getActionsListWorksItem(
      key as keyof DashboardWorksActions,
      list[key as keyof DashboardWorksActions],
    );
    return content ? [...acc, content] : acc;
  }, [] as DashboardActionsListItem[]);
};

export const getActionsListRemittances = (
  list: DashboardRemittancesActions,
): DashboardActionsListItem[] => {
  return Object.keys(list).reduce((acc, key) => {
    const content = getActionsListRemittancesItem(
      key as keyof DashboardRemittancesActions,
      list[key as keyof DashboardRemittancesActions],
    );
    return content ? [...acc, content] : acc;
  }, [] as DashboardActionsListItem[]);
};

export const getActionsListWorkers = (
  list: DashboardWorkersActions,
): DashboardActionsListItem[] => {
  return Object.keys(list).reduce((acc, key) => {
    const content = getActionsListWorkersItem(
      key as keyof DashboardWorkersActions,
      list[key as keyof DashboardWorkersActions],
    );
    return content ? [...acc, content] : acc;
  }, [] as DashboardActionsListItem[]);
};

export const getTipsFirstRow = (
  regionCode: RegionCode,
  t: TFunction,
): DashboardTips[] => [
  {
    title: t(i18nKeys.dashboard.tips.advice1.title),
    subtitle: t(i18nKeys.dashboard.tips.advice1.subTitle),
    icon: regionCode === RegionCode.BE_VLG ? Icon.FILE_NEW : Icon.WORKSADD,
    link: {
      label: t(i18nKeys.dashboard.tips.cta),
      url: t(i18nKeys.dashboard.tips.advice1.url[regionCode]),
    },
  },
  {
    title: t(i18nKeys.dashboard.tips.advice2.title),
    subtitle: t(i18nKeys.dashboard.tips.advice2.subTitle),
    icon: Icon.UNDO,
    link: {
      label: t(i18nKeys.dashboard.tips.cta),
      url: t(i18nKeys.dashboard.tips.advice2.url[regionCode]),
    },
  },
];

export const getTipsSecondRow = (
  regionCode: RegionCode,
  t: TFunction,
): DashboardTips[] => [
  {
    title: t(i18nKeys.dashboard.tips.advice5.title),
    subtitle: t(i18nKeys.dashboard.tips.advice5.subTitle),
    icon: regionCode === RegionCode.BE_VLG ? Icon.NEWSPAPER : Icon.FILE_NEW,
    link: {
      label: t(i18nKeys.dashboard.tips.advice5.cta),
      url: t(i18nKeys.dashboard.tips.advice5.url[regionCode]),
    },
  },
  {
    title: t(i18nKeys.dashboard.tips.advice3.title),
    subtitle: t(i18nKeys.dashboard.tips.advice3.subTitle),
    icon: Icon.LIGHT_BULB,
    link: {
      label: t(i18nKeys.dashboard.tips.cta),
      url: t(i18nKeys.dashboard.tips.advice3.url[regionCode]),
    },
  },
  {
    title: t(i18nKeys.dashboard.tips.advice4.title),
    subtitle: t(i18nKeys.dashboard.tips.advice4.subTitle),
    icon: Icon.FILM,
    link: {
      label: t(i18nKeys.dashboard.tips.advice4.cta),
      url: getPath(Routes.VIDEOS),
    },
  },
];
