import { Worker } from '@kaa/api/providers';
import { httpTo } from '@kaa/api/providers/utilities';
import { useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AlertType,
  SwActionGroup,
  SwButton,
  SwForm,
  SwFormGrid,
  SwFormSubmitMessage,
  SwModalRenderProps,
  SwTitle,
} from '@kaa/ui-flanders/components';
import { Formik, FormikActions } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modals } from '../../../../constants';
import { dataTest } from '../../../../datatest/keys';
import { useApi, useSelectedProviderIdState } from '../../../../utils';
import {
  createValidatorWithServerErrorHandled,
  handleApiError,
} from '../../../../utils/validation';
import { WorkerForm } from '../workerForm/WorkerForm';
import {
  getDefaultWorkersValidation,
  getRequiredDefaultWorkersFields,
} from '../workerForm/WorkerForm.utils';
import { AddWorkerModalConfirmation } from './AddWorkerModalConfirmation';

export const AddWorkerModal = ({
  setConfirmCloseModal,
}: SwModalRenderProps) => {
  const { t } = useTranslation();
  const { providers } = useApi();
  const providerId = useSelectedProviderIdState();

  const [{ value: submitedForm }, submit] = useAsyncCallback(
    async (formikData: Worker, formikActions: FormikActions<Worker>) => {
      const [error, response] = await httpTo(
        providers.createWorkers(providerId, formikData),
      );
      return { error, response, formikData, formikActions };
    },
    [providers, providerId],
  );

  useEffect(() => {
    if (submitedForm) {
      const { error, response, formikData, formikActions } = submitedForm;
      const { setSubmitting, setStatus, resetForm } = formikActions;

      setSubmitting(false);

      if (handleApiError(error, formikActions)) {
        return;
      }

      if (!response) {
        setStatus({
          msg: t(i18nKeys.contactPersons.modal.add.errorMessage),
          type: AlertType.ERROR,
        });
        return;
      }

      setConfirmCloseModal(false);
      resetForm(formikData);
    }
  }, [submitedForm]);

  const validate = createValidatorWithServerErrorHandled(
    getDefaultWorkersValidation(),
  );

  if (submitedForm && submitedForm.response?.data.data) {
    return (
      <>
        <SwTitle tagName="h2">{t(i18nKeys.workers.addWorker.title)}</SwTitle>
        <AddWorkerModalConfirmation worker={submitedForm.response.data.data} />
      </>
    );
  }

  return (
    <>
      <SwTitle tagName="h2">{t(i18nKeys.workers.addWorker.title)}</SwTitle>
      <Formik<Partial<Worker>>
        onSubmit={submit}
        initialValues={getRequiredDefaultWorkersFields()}
        validate={validate}
        render={({ handleSubmit, dirty, isSubmitting }) => {
          setConfirmCloseModal(dirty);
          return (
            <SwForm onSubmit={handleSubmit}>
              <SwFormGrid modStacked>
                <WorkerForm />
                <SwActionGroup style={{ marginTop: '3rem' }}>
                  <SwButton
                    type="submit"
                    data-testid={dataTest.workers.addWorker.addNewWorker}
                    modLoading={isSubmitting}
                    modLarge
                    modDisabled={!dirty}
                  >
                    {t(i18nKeys.workers.modals.addWorker.cta)}
                  </SwButton>
                </SwActionGroup>
                <SwFormSubmitMessage />
              </SwFormGrid>
            </SwForm>
          );
        }}
      />
    </>
  );
};
