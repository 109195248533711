import { WorkerDetail } from '@kaa/api/providers';
import { ActionType, useActionDispatch } from '@kaa/common/context';
import { useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  SwColumn,
  SwContainer,
  SwFetchErrorMessage,
  SwGrid,
} from '@kaa/ui-flanders/components';
import { RouteComponentProps } from '@reach/router';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContainerLoadingPageHeader, PageHeader } from '../../../components';
import { useApi } from '../../../utils';
import { ContractList } from './components/ContractList';
import { GeneralInformation } from './components/GeneralInformation';
import { LoginInformation } from './components/LoginInformation';
import { WorksInformation } from './components/WorksInformation';

type WorkersDetailsScreenProps = {
  providerId: string;
  workerId: string;
};

export const WorkersDetailsScreen = ({
  providerId,
  workerId,
}: RouteComponentProps<WorkersDetailsScreenProps>) => {
  const { t } = useTranslation();
  const { providers } = useApi();
  const dispatchAction = useActionDispatch();
  const [workerDetails, setWorkerDetails] = useState<WorkerDetail>();

  const [
    { value: workersDetailsData, loading, error },
    getWorkersDetailsData,
  ] = useAsyncCallback(
    async () => {
      if (!workerId || !providerId) {
        return undefined;
      }

      const [
        workerDetailsResponse,
        workerContractsResponse,
      ] = await Promise.all([
        providers.getWorkerDetail(Number(providerId), Number(workerId)),
        providers.getWorkerContracts(Number(providerId), Number(workerId)),
      ]);

      if (workerDetailsResponse.data.actions) {
        dispatchAction({
          type: ActionType.ADD,
          payload: workerDetailsResponse.data.actions,
        });
      }

      return {
        workerDetails: workerDetailsResponse.data.data,
        workerContracts: workerContractsResponse.data.data,
      };
    },
    [providerId, workerId, providers],
    { loading: true },
  );

  useEffect(() => {
    if (workersDetailsData) {
      setWorkerDetails(workersDetailsData.workerDetails);
    }
  }, [workersDetailsData]);

  useEffect(() => {
    getWorkersDetailsData();
  }, [getWorkersDetailsData]);

  if (loading && !workersDetailsData && !workerDetails) {
    return (
      <ContainerLoadingPageHeader
        title={t(i18nKeys.worker.details.title)}
        modFullwidth
      />
    );
  }

  if (
    error ||
    !workersDetailsData ||
    !workerId ||
    !providerId ||
    !workerDetails
  ) {
    return (
      <SwContainer error>
        <SwFetchErrorMessage
          onClick={() => {
            getWorkersDetailsData(workerId);
          }}
        />
      </SwContainer>
    );
  }

  const { workerContracts } = workersDetailsData;

  return (
    <SwContainer>
      <SwGrid modStacked>
        <PageHeader title={t(i18nKeys.worker.details.title)} />
        <SwColumn width="10" widthS="12">
          <GeneralInformation
            workerDetails={workerDetails}
            setWorkerDetails={setWorkerDetails}
          />
          <LoginInformation
            providerId={providerId}
            workerDetails={workerDetails}
            setWorkerDetails={setWorkerDetails}
          />
          <WorksInformation workerDetails={workerDetails} />
          <ContractList
            providerId={providerId}
            workerId={workerId}
            contracts={workerContracts}
          />
        </SwColumn>
      </SwGrid>
    </SwContainer>
  );
};
