/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */

export type FileStatus = 'Done' | 'InProgress' | 'Parsed' | 'DoneWithError';

export const FileStatus = {
  Done: 'Done' as FileStatus,
  InProgress: 'InProgress' as FileStatus,
  Parsed: 'Parsed' as FileStatus,
  DoneWithError: 'DoneWithError' as FileStatus,
};
