import {
  WorkerContract,
  WorkerContractsResponseDataItem,
} from '@kaa/api/providers';
import { httpTo } from '@kaa/api/providers/utilities';
import { useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AlertType,
  SwActionGroup,
  SwButton,
  SwForm,
  SwFormGrid,
  SwFormSubmitMessage,
  SwTitle,
  toggleModalById,
} from '@kaa/ui-flanders/components';
import { Formik, FormikActions } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modals } from '../../../../../constants';
import { dataTest } from '../../../../../datatest/keys';
import { useApi } from '../../../../../utils';
import {
  createValidatorWithServerErrorHandled,
  handleApiError,
} from '../../../../../utils/validation';
import { ContractForm } from './ContractForm';
import { ContractModalWithContractProps } from './ContractForm.types';
import { getDefaultContractValidation } from './ContractForm.utils';

export const EditContractModal = ({
  providerId,
  workerId,
  contract,
  onSuccess,
  setConfirmCloseModal,
}: ContractModalWithContractProps) => {
  const { t } = useTranslation();
  const { providers } = useApi();

  const { id: contractId } = contract;

  const [{ value: submitedForm }, submit] = useAsyncCallback(
    async (
      formikData: WorkerContract,
      formikActions: FormikActions<WorkerContract>,
    ) => {
      const [error, response] = await httpTo(
        providers.updateWorkerContract(
          Number(providerId),
          Number(workerId),
          Number(contractId),
          formikData,
        ),
      );

      return { error, response, formikData, formikActions };
    },
    [providers, providerId, contract],
  );

  useEffect(() => {
    if (submitedForm) {
      const { error, response, formikData, formikActions } = submitedForm;
      const { setSubmitting, setStatus } = formikActions;
      setSubmitting(false);

      if (handleApiError(error, formikActions)) {
        return;
      }

      if (!response) {
        setStatus({
          msg: t(i18nKeys.contactPersons.modal.add.errorMessage),
          type: AlertType.ERROR,
        });
        return;
      }

      toggleModalById(Modals.WORKERS_EDIT_CONTRACT_MODAL);

      onSuccess({
        ...formikData,
        id: contractId,
      } as WorkerContractsResponseDataItem);
    }
  }, [submitedForm]);

  const validate = createValidatorWithServerErrorHandled(
    getDefaultContractValidation(),
  );

  return (
    <>
      <SwTitle tagName="h2">
        {t(i18nKeys.contract.modal.editContract.title)}
      </SwTitle>
      <Formik
        initialValues={contract}
        onSubmit={submit}
        validate={validate}
        render={({ handleSubmit, dirty, isSubmitting }) => {
          setConfirmCloseModal(dirty);
          return (
            <SwForm onSubmit={handleSubmit}>
              <SwFormGrid modStacked>
                <ContractForm />
                <SwActionGroup style={{ marginTop: '3rem' }}>
                  <SwButton
                    type="submit"
                    data-testid={dataTest.workers.details.contract.editContract}
                    modLoading={isSubmitting}
                    modLarge
                    modDisabled={!dirty}
                  >
                    {t(i18nKeys.contract.modal.editContract.cta)}
                  </SwButton>
                </SwActionGroup>
                <SwFormSubmitMessage />
              </SwFormGrid>
            </SwForm>
          );
        }}
      />
    </>
  );
};
