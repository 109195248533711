import {
  ExportSubsidiesParams,
  GetSubsidiesParams,
  Relative,
  SupportType,
} from '@kaa/api/providers';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import { TFunction } from 'i18next';
import { DateTime } from 'luxon';
import {
  NOW_DATE,
  PAST_DATE_LIMIT,
  RemittancesSubsidiesFormFieldNames,
  RemittancesSubsidiesFormType,
} from './RemittancesSubsidies.constants';

export const getRelative = (
  relatives: Relative[],
  providerId: string | number,
) => relatives.find((relative) => Number(relative.id) === Number(providerId));

export const getFormatedSubsidiesParams = <
  T = GetSubsidiesParams | ExportSubsidiesParams
>({
  serviceMonthsPeriod = [],
  ...subsidiesParams
}: Partial<RemittancesSubsidiesFormType>): T => ({
  ...(subsidiesParams as T),
  serviceMonthsPeriod: serviceMonthsPeriod.map((e) =>
    DateTime.fromISO(e).toFormat('yyyy-LL'),
  ),
});

export const getRelativeOptions = (relatives: Relative[]) =>
  relatives.map(({ id, name }) => ({
    value: id,
    text: `${name} - ${id}`,
  }));

export const getSupportTypeOptions = (t: TFunction) =>
  Object.values(SupportType).map((e: SupportType) => ({
    value: e,
    text: t(i18nKeys.general.supportType[e]),
  }));

export const getIsBlockedOptions = (t: TFunction) => [
  {
    value: true,
    text: t(i18nKeys.remittances.general.yes),
  },
  {
    value: false,
    text: t(i18nKeys.remittances.general.no),
  },
];

export const getMinStartDate = ({
  [RemittancesSubsidiesFormFieldNames.END_DATE]: endDate,
}: RemittancesSubsidiesFormType) =>
  DateTime.fromISO(endDate)
    .minus({ year: 1 })
    .toJSDate() > PAST_DATE_LIMIT.toJSDate()
    ? DateTime.fromISO(endDate)
        .minus({ year: 1 })
        .toJSDate()
    : PAST_DATE_LIMIT.toJSDate();

export const getMaxStartDate = ({
  [RemittancesSubsidiesFormFieldNames.END_DATE]: endDate,
}: RemittancesSubsidiesFormType) =>
  DateTime.fromISO(endDate).toJSDate() || NOW_DATE.toJSDate();

export const getMinEndDate = ({
  [RemittancesSubsidiesFormFieldNames.START_DATE]: startDate,
}: RemittancesSubsidiesFormType) =>
  DateTime.fromISO(startDate).toJSDate() || PAST_DATE_LIMIT;

export const getMaxEndDate = ({
  [RemittancesSubsidiesFormFieldNames.START_DATE]: startDate,
}: RemittancesSubsidiesFormType) =>
  DateTime.fromISO(startDate)
    .plus({ year: 1 })
    .toJSDate() < NOW_DATE.toJSDate()
    ? DateTime.fromISO(startDate)
        .plus({ year: 1 })
        .toJSDate()
    : NOW_DATE.toJSDate();
