/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */

export type PickupStatus =
  | 'PLANNED'
  | 'CANCELED'
  | 'NON_WORKING_DAY'
  | 'CONFIRMED';

export const PickupStatus = {
  PLANNED: 'PLANNED' as PickupStatus,
  CANCELED: 'CANCELED' as PickupStatus,
  NON_WORKING_DAY: 'NON_WORKING_DAY' as PickupStatus,
  CONFIRMED: 'CONFIRMED' as PickupStatus,
};
