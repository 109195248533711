import { BillDetailsDataTasksItem, Sorting } from '@kaa/api/providers';

export const sortByCommercialName = <T extends BillDetailsDataTasksItem>(
  sort: Sorting,
  elements: T[],
): T[] =>
  elements.sort((a, b) =>
    sort === Sorting.COMMERCIAL_NAME_ASC
      ? a.commercialName.localeCompare(b.commercialName)
      : b.commercialName.localeCompare(a.commercialName),
  );

export const sortByProviderId = <T extends BillDetailsDataTasksItem>(
  sort: Sorting,
  elements: T[],
): T[] =>
  elements.sort((a, b) =>
    sort === Sorting.PROVIDER_ID_ASC
      ? a.providerId.localeCompare(b.providerId)
      : b.providerId.localeCompare(a.providerId),
  );

export const sortByConsumptionDate = <T extends BillDetailsDataTasksItem>(
  sort: Sorting,
  elements: T[],
): T[] =>
  elements.sort((a, b) =>
    sort === Sorting.CONSUMPTION_DATE_ASC
      ? Number(new Date(b.consumptionDate)) -
        Number(new Date(a.consumptionDate))
      : Number(new Date(a.consumptionDate)) -
        Number(new Date(b.consumptionDate)),
  );
