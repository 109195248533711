import { BillDetailsDataTasksItem, Sorting } from '@kaa/api/providers';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  selectPage,
  SwColumn,
  SwDataTable,
  SwGrid,
  SwPaginator,
} from '@kaa/ui-flanders/components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableHead, TableRowNoResult } from '../../../../components';
import {
  EventAction,
  EventCategory,
  EventLabel,
  sendCustomInteractionToGTM,
} from '../../../../utils/google-analytics';
import { BILLING_DETAIL_PAGE_SIZE } from '../PickupBilling.contants';
import {
  sortByCommercialName,
  sortByConsumptionDate,
  sortByProviderId,
} from '../PickupBilling.utils';
import { PickupBillingDetailsModalTableRow } from './PickupBillingDetailsModalTableRow';

type PickupBillingDetailsModalTableProps = {
  tasks: BillDetailsDataTasksItem[];
};

export const PickupBillingDetailsModalTable = ({
  tasks,
}: PickupBillingDetailsModalTableProps) => {
  const { t } = useTranslation();

  const [items, setItems] = useState<BillDetailsDataTasksItem[]>(tasks);
  const [selectedPage, setSelectedPage] = useState(1);
  const [currentSorting, setSorting] = useState<Sorting>(Sorting.NONE);

  const sortBy = (sort: Sorting) => {
    setSorting(sort);
    setSelectedPage(1);

    let tracking = '';
    switch (sort) {
      case Sorting.COMMERCIAL_NAME_ASC:
      case Sorting.COMMERCIAL_NAME_DESC:
        setItems((currentItems) =>
          sortByCommercialName<BillDetailsDataTasksItem>(sort, currentItems),
        );
        tracking = `${EventLabel.PICKUP_BILLING_SORTBY_COMMERCIAL_NAME} ${
          sort === Sorting.COMMERCIAL_NAME_ASC ? 'ASC' : 'DESC'
        }`;
        break;
      case Sorting.PROVIDER_ID_ASC:
      case Sorting.PROVIDER_ID_DESC:
        setItems((currentItems) =>
          sortByProviderId<BillDetailsDataTasksItem>(sort, currentItems),
        );
        tracking = `${EventLabel.PICKUP_BILLING_SORTBY_PROVIDER_ID} ${
          sort === Sorting.PROVIDER_ID_ASC ? 'ASC' : 'DESC'
        }`;
        break;
      case Sorting.CONSUMPTION_DATE_ASC:
      case Sorting.CONSUMPTION_DATE_DESC:
        setItems((currentItems) =>
          sortByConsumptionDate<BillDetailsDataTasksItem>(sort, currentItems),
        );
        tracking = `${EventLabel.PICKUP_BILLING_SORTBY_CONSUMPTION_DATE} ${
          sort === Sorting.CONSUMPTION_DATE_ASC ? 'ASC' : 'DESC'
        }`;
        break;
      default:
        setItems(() => tasks);
        tracking = `${EventLabel.PICKUP_BILLING_SORTBY_DEFAULT}`;
        break;
    }

    sendCustomInteractionToGTM(
      EventCategory.PICKUP_BILLING,
      EventAction.CLICK,
      tracking,
    );
  };

  const head = [
    {
      text: t(i18nKeys.pickup.billing.detail.table.enterpriseName),
      onClick: () => {
        sortBy(
          currentSorting === Sorting.COMMERCIAL_NAME_ASC
            ? Sorting.COMMERCIAL_NAME_DESC
            : Sorting.COMMERCIAL_NAME_ASC,
        );
      },
    },
    {
      text: t(i18nKeys.general.label.authorizationNumber),
      onClick: () => {
        sortBy(
          currentSorting === Sorting.PROVIDER_ID_ASC
            ? Sorting.PROVIDER_ID_DESC
            : Sorting.PROVIDER_ID_ASC,
        );
      },
    },
    {
      text: t(i18nKeys.pickup.billing.detail.table.pickupDate),
      onClick: () => {
        sortBy(
          currentSorting === Sorting.CONSUMPTION_DATE_ASC
            ? Sorting.CONSUMPTION_DATE_DESC
            : Sorting.CONSUMPTION_DATE_ASC,
        );
      },
    },
    {
      text: t(i18nKeys.pickup.billing.table.vatFreeAmount),
    },
  ];

  return (
    <SwGrid>
      <SwColumn>
        <div className="vl-u-table-overflow vl-u-spacer--medium">
          <SwDataTable modLine>
            <TableHead elements={head} />
            <tbody>
              {!items.length ? (
                <TableRowNoResult
                  colSpan={head.length}
                  style={{ whiteSpace: 'normal' }}
                />
              ) : (
                <>
                  {selectPage<BillDetailsDataTasksItem>(
                    items,
                    selectedPage,
                    BILLING_DETAIL_PAGE_SIZE,
                  ).map((task: BillDetailsDataTasksItem) => (
                    <PickupBillingDetailsModalTableRow
                      key={JSON.stringify(task)}
                      task={task}
                    />
                  ))}
                </>
              )}
            </tbody>
          </SwDataTable>
        </div>
      </SwColumn>
      <SwColumn>
        <SwPaginator
          itemCount={items.length}
          pageSize={BILLING_DETAIL_PAGE_SIZE}
          selectedPage={selectedPage}
          setPage={setSelectedPage}
        />
      </SwColumn>
    </SwGrid>
  );
};
