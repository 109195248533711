import { useAuthState } from '@kaa/auth/common';
import React, { ReactNode } from 'react';
import { AuthContext } from '../../types';
import { AlertManager } from '../alert/AlertManager';
import { PageHeaderIntro } from './PageHeaderIntro';

export type PageHeaderProps = {
  title?: string;
  className?: string;
  introduction?: string | ReactNode;
  alert?: ReactNode;
};

export const PageHeader = ({ alert, ...rest }: PageHeaderProps) => {
  const { user } = useAuthState<AuthContext>();

  if (!user?.id) {
    return <PageHeaderIntro {...rest} />;
  }

  return (
    <>
      <PageHeaderIntro {...rest} />
      <div className="vl-alert-wrapper">
        <AlertManager />
        {alert}
      </div>
    </>
  );
};
