import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  Icon,
  SwButton,
  SwColumn,
  SwContainer,
  SwGrid,
  SwModal,
} from '@kaa/ui-flanders/components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VideoTopicReference } from '@kaa/api/providers';
import { PageHeader, TutorialVideoLink } from '../../components';
import { Modals } from '../../constants';
import {
  EventAction,
  EventCategory,
  EventLabel,
  openModalWithPageView,
  sendCustomInteractionToGTM,
} from '../../utils/google-analytics';
import { AddWorkerModal } from './components/addWorker/AddWorkerModal';
import {
  AddWorkerListModal,
  TrackingState,
  TRACKING_STATE_DEFAULT,
} from './components/addWorkerList/AddWorkerListModal';
import { WorkersCard } from './components/WorkersCard';
import { WorkersSearch } from './searchWorker/WorkersSearch';
import { ShiftBy } from '../../components/shiftBy/ShiftBy';

export const WorkersRootScreen = () => {
  const { t } = useTranslation();

  const [trackingState, setTrackingState] = useState<TrackingState>(
    TRACKING_STATE_DEFAULT,
  );

  return (
    <SwContainer>
      <PageHeader
        title={t(i18nKeys.navigation.workers)}
        className="vl-u-spacer"
      />
      <ShiftBy y={-30}>
        <TutorialVideoLink topic={VideoTopicReference.WORKERS} />
      </ShiftBy>
      <SwGrid
        modVStretched
        style={{ width: '100%', justifyContent: 'flex-start' }}
      >
        <WorkersCard
          title={t(i18nKeys.workers.addWorker.title)}
          text={t(i18nKeys.workers.cards.addWorker.description)}
          icon={Icon.USER}
        >
          <SwButton
            modBlock
            modNarrow
            onClick={() => {
              openModalWithPageView(Modals.WORKERS_ADD_WORKER_MODAL);
            }}
          >
            {t(i18nKeys.workers.cards.addWorker.cta)}
          </SwButton>
        </WorkersCard>
        <WorkersCard
          title={t(i18nKeys.workers.addWorker.list.title)}
          text={t(i18nKeys.workers.cards.addWorker.list.description)}
          icon={Icon.USER}
        >
          <SwButton
            modBlock
            modNarrow
            onClick={() => {
              openModalWithPageView(Modals.WORKERS_IMPORT_LIST_WORKER_MODAL);
            }}
          >
            {t(i18nKeys.workers.cards.addWorker.list.cta)}
          </SwButton>
        </WorkersCard>
        <SwColumn>
          <WorkersSearch />
        </SwColumn>
      </SwGrid>
      <SwModal
        id={Modals.WORKERS_IMPORT_LIST_WORKER_MODAL}
        closable
        confirmCloseMessage={t(i18nKeys.dialog.confirmClose.message)}
        component={AddWorkerListModal}
        setTrackingState={setTrackingState}
        onClose={() => {
          const { upload, parsing } = trackingState;
          if (upload) {
            sendCustomInteractionToGTM(
              EventCategory.PRESTATIONS,
              EventAction.UPLOAD,
              parsing
                ? EventLabel.PRESTATIONS_CLOSE_MODAL_DURING_UPLOAD_PARSING
                : EventLabel.PRESTATIONS_CLOSE_MODAL_AFTER_UPLOAD_PARSING,
            );
          }
        }}
      />
      <SwModal
        id={Modals.WORKERS_ADD_WORKER_MODAL}
        closable
        component={AddWorkerModal}
        confirmCloseMessage={t(i18nKeys.dialog.confirmClose.message)}
      />
    </SwContainer>
  );
};
