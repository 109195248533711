/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */

export type WorkerActionType = 'CAN_RESET_PASSWORD';

export const WorkerActionType = {
  CAN_RESET_PASSWORD: 'CAN_RESET_PASSWORD' as WorkerActionType,
};
