import { GetWorkersServicesParams } from '@kaa/api/providers';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  SwActionGroup,
  SwButton,
  SwLink,
  SwModalRenderProps,
  SwTitle,
  toggleModalById,
} from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modals } from '../../../../constants';
import { PrestationsModalParameters } from '../PrestationsModals.constants';

type PrestationsSearchValidateModalProps = SwModalRenderProps<{
  modalId: Modals | string;
  title?: string;
  confirmText?: string;
  parameters: GetWorkersServicesParams | PrestationsModalParameters;
  isLoading?: boolean;
  confirm: (
    parameters: GetWorkersServicesParams | PrestationsModalParameters,
  ) => void;
}>;

export const PrestationsSearchValidateModal = ({
  modalId,
  confirm,
  title,
  confirmText,
  isLoading = false,
  parameters,
}: PrestationsSearchValidateModalProps) => {
  const { t } = useTranslation();

  return (
    <>
      {!!title && <SwTitle tagName="h2">{title}</SwTitle>}
      <SwActionGroup>
        <SwButton
          modLoading={isLoading}
          modDisabled={isLoading}
          onClick={() => {
            confirm(parameters);
            toggleModalById(modalId);
          }}
        >
          {confirmText}
        </SwButton>
        <SwLink
          onClick={() => {
            toggleModalById(modalId);
          }}
        >
          {t(i18nKeys.general.cta.cancel)}
        </SwLink>
      </SwActionGroup>
    </>
  );
};
