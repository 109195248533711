import {
  email,
  maxLength,
  required,
  ValidationError,
  ValidatorFunction,
} from '@kaa/common/validation';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import { DateTime } from 'luxon';
import { WorkerFormFieldNames } from './WorkerForm.constant';

export const getFieldName = (name: string, fieldNamePrefix?: string) => {
  if (!fieldNamePrefix) {
    return name;
  }

  return `${fieldNamePrefix}.${name}`;
};

const minFifteenYearOld: ValidatorFunction = (
  value: string,
): ValidationError | false => {
  if (!value) {
    return false;
  }

  const date = DateTime.fromISO(value);

  if (date > DateTime.local().minus({ year: 15 })) {
    return {
      defaultMessage: 'Subscription non authorized under 15 years old',
      id: i18nKeys.register.errors.form.minFifteenYearOld,
    };
  }

  return false;
};

export const getRequiredDefaultWorkersFields = () => ({
  [WorkerFormFieldNames.GENDER]: undefined,
  [WorkerFormFieldNames.FIRSTNAME]: '',
  [WorkerFormFieldNames.LASTNAME]: '',
  [WorkerFormFieldNames.EMAIL_ADDRESS]: '',
  [WorkerFormFieldNames.NISS]: '',
  [WorkerFormFieldNames.BIRTHDATE]: '',
  [WorkerFormFieldNames.LANGUAGE]: undefined,
  [WorkerFormFieldNames.REFERENCE]: '',
  [WorkerFormFieldNames.POSTCODE]: '',
  [WorkerFormFieldNames.PHONE_NUMBER]: '',
});

export const getDefaultWorkersValidation = () => ({
  [WorkerFormFieldNames.GENDER]: [required],
  [WorkerFormFieldNames.FIRSTNAME]: [maxLength(40), required],
  [WorkerFormFieldNames.LASTNAME]: [maxLength(40), required],
  [WorkerFormFieldNames.EMAIL_ADDRESS]: [email],
  [WorkerFormFieldNames.NISS]: [required],
  [WorkerFormFieldNames.BIRTHDATE]: [required, minFifteenYearOld],
  [WorkerFormFieldNames.LANGUAGE]: [required],
  [WorkerFormFieldNames.REFERENCE]: [required, maxLength(40)],
  [WorkerFormFieldNames.POSTCODE]: [maxLength(10), required],
  [WorkerFormFieldNames.PHONE_NUMBER]: [
    maxLength(20, {
      defaultMessage: 'The phone number format is not valid',
      id: i18nKeys.errors.form.mobilePhoneNumber,
    }),
  ],
});
