import { Provider } from '@kaa/api/providers';
import { httpTo } from '@kaa/api/providers/utilities';
import { useAsyncCallback } from '@kaa/common/utils';
import {
  equalToField,
  existsInError,
  notEqualToPassword,
  password,
  required,
  setError,
} from '@kaa/common/validation';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AlertType,
  SwActionGroup,
  SwButton,
  SwColumn,
  SwForm,
  SwFormColumn,
  SwFormGrid,
  SwFormSubmitMessage,
  SwGrid,
  SwInputPasswordField,
  SwLink,
  SwModalRenderProps,
  SwTitle,
} from '@kaa/ui-flanders/components';
import { Field, Formik, FormikActions } from 'formik';
import get from 'lodash.get';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getConfig } from '../../../../../common/config';
import { dataTest } from '../../../../datatest/keys';
import { useApi } from '../../../../utils';
import {
  createValidatorWithServerErrorHandled,
  handleApiError,
} from '../../../../utils/validation';
import { ProfileScreenChangePasswordFieldNames } from '../ProfileScreen.constants';
import {
  ProfileProviderChangePasswordFormState,
  ProfileStatusType,
} from '../ProfileScreen.types';

type ProfileProviderChangePasswordModalProps = {
  provider: Provider;
  onSubmit: (
    profileStatus: ProfileStatusType,
    providerUpdate?: Provider,
  ) => void;
};

export const ProfileProviderChangePasswordModal = ({
  onSubmit,
  setConfirmCloseModal,
}: SwModalRenderProps<ProfileProviderChangePasswordModalProps>) => {
  const { t } = useTranslation();
  const { providers } = useApi();

  const initialValues = {
    [ProfileScreenChangePasswordFieldNames.OLD_PASSWORD]: '',
    [ProfileScreenChangePasswordFieldNames.NEW_PASSWORD]: '',
    [ProfileScreenChangePasswordFieldNames.CONFIRM_NEW_PASSWORD]: '',
  } as ProfileProviderChangePasswordFormState;

  const [{ value: submitedForm }, submit] = useAsyncCallback(
    async (
      formikData: ProfileProviderChangePasswordFormState,
      formikActions: FormikActions<ProfileProviderChangePasswordFormState>,
    ) => {
      const { newPassword, oldPassword } = formikData;

      const [error, response] = await httpTo(
        providers.updateUserPassword({
          newPassword,
          oldPassword,
        }),
      );

      return { error, response, formikData, formikActions };
    },
  );

  useEffect(() => {
    if (submitedForm) {
      const { error, formikActions } = submitedForm;
      const { resetForm } = formikActions;

      if (handleApiError(error, formikActions)) {
        return;
      }

      resetForm(initialValues);

      onSubmit({
        type: AlertType.SUCCESS,
        msg: t(i18nKeys.profile.updateProfile.successMessage),
      });
    }
  }, [submitedForm]);

  const validate = createValidatorWithServerErrorHandled({
    oldPassword: [required],
    newPassword: [
      required,
      password,
      notEqualToPassword(ProfileScreenChangePasswordFieldNames.OLD_PASSWORD),
    ],
    confirmNewPassword: [
      existsInError(
        `${ProfileScreenChangePasswordFieldNames.CONFIRM_NEW_PASSWORD}.notPasted`,
        {
          id: i18nKeys.profile.error.notPasted,
          defaultMessage: 'You are not allowed to paste into this field',
          displayFocussed: true,
        },
      ),
      required,
      equalToField(ProfileScreenChangePasswordFieldNames.NEW_PASSWORD, {
        defaultMessage: `Must be equal to ${t(i18nKeys.profile.newPassword)}`,
        id: i18nKeys.errors.form.equal,
        displayFocussed: false,
        values: {
          value: t(i18nKeys.profile.newPassword),
        },
      }),
    ],
  });
  const {i18n} = useTranslation();
  const tenant= (get(getConfig(), 'buildConfig.oidc.acr_values') as string).split(':')[1];
  const forgotPasswordUrl = `${get(getConfig(), 'app.auth.oidc.authority')}/password-recovery?tenant=${tenant}&localize-key=${get(getConfig(), 'buildConfig.lokaliseKey')}&uiLocale=${i18n.language}`;

  return (
    <Formik<ProfileProviderChangePasswordFormState>
      initialValues={initialValues}
      validate={validate}
      onSubmit={submit}
      render={({ handleSubmit, dirty, isSubmitting, setFieldValue }) => {
        setConfirmCloseModal(dirty);
        return (
          <SwGrid>
            <SwColumn width="12">
              <SwTitle tagName="h2">
                {t(i18nKeys.profile.changePasswordModal.title)}
              </SwTitle>
              <p className="vl-u-spacer--xsmall">
                {t(i18nKeys.profile.changePasswordModal.description)}
              </p>
            </SwColumn>
            <SwColumn width="12" widthS="12">
              <SwForm
                onSubmit={handleSubmit}
                data-testid={
                  dataTest.profile.changePasswordModal.formChangePassword
                }
              >
                <SwFormGrid modStacked>
                  <SwFormColumn>
                    <Field
                      name={ProfileScreenChangePasswordFieldNames.OLD_PASSWORD}
                      label={t(i18nKeys.profile.currentPassword)}
                      component={SwInputPasswordField}
                      autoComplete="current-password"
                      data-testid="changePasswordModalOldPasswordInput"
                      column={{ width: '12' }}
                      labelColumn={{ width: '12' }}
                      modRequired
                    />
                    <SwLink
                      to={forgotPasswordUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      data-testid={
                        dataTest.profile.changePasswordModal.linkForgotPassword
                      }
                    >
                      {t(i18nKeys.profile.forgotPassword)}
                    </SwLink>
                  </SwFormColumn>
                  <SwFormColumn>
                    <Field
                      name={ProfileScreenChangePasswordFieldNames.NEW_PASSWORD}
                      label={t(i18nKeys.profile.newPassword)}
                      helpText={t(
                        i18nKeys.profile.changePasswordModal.passwordRules,
                      )}
                      component={SwInputPasswordField}
                      data-testid="changePasswordModalnewPasswordInput"
                      column={{ width: '12' }}
                      labelColumn={{ width: '12' }}
                      modRequired
                    />
                  </SwFormColumn>
                  <Field
                    name={
                      ProfileScreenChangePasswordFieldNames.CONFIRM_NEW_PASSWORD
                    }
                    label={t(i18nKeys.profile.confirmNewPassword)}
                    component={SwInputPasswordField}
                    data-testid="changePasswordModalnewPasswordInputConfirm"
                    onPaste={(e: ClipboardEvent) => {
                      e.preventDefault();
                      setError({
                        setFieldValue,
                        name: `${ProfileScreenChangePasswordFieldNames.CONFIRM_NEW_PASSWORD}.notPasted`,
                      });
                    }}
                    column={{ width: '12' }}
                    labelColumn={{ width: '12' }}
                    modRequired
                  />
                  <SwActionGroup>
                    <SwButton
                      type="submit"
                      modDisabled={!dirty}
                      modLoading={isSubmitting}
                      data-testid={
                        dataTest.profile.changePasswordModal
                          .submitChangePasswordModal
                      }
                    >
                      {t(i18nKeys.general.cta.validate)}
                    </SwButton>
                  </SwActionGroup>
                  <SwFormSubmitMessage />
                </SwFormGrid>
              </SwForm>
            </SwColumn>
          </SwGrid>
        );
      }}
    />
  );
};
