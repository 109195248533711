/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Providers Api
 * OpenAPI spec version: v1
 */

export type CustomerStatus = 'ACTIVE' | 'FROZEN' | 'BLOCKED';

export const CustomerStatus = {
  ACTIVE: 'ACTIVE' as CustomerStatus,
  FROZEN: 'FROZEN' as CustomerStatus,
  BLOCKED: 'BLOCKED' as CustomerStatus,
};
