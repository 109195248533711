import { WorkerActionType, WorkerDetail } from '@kaa/api/providers';
import { useActionsHandler } from '@kaa/common/handlers';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AlertType,
  getAlertPropsByType,
  Icon,
  SwAlert,
  SwBadge,
  SwColumn,
  SwGrid,
  SwIcon,
  SwLink,
  SwModal,
  SwTitle,
  useTooltip,
} from '@kaa/ui-flanders/components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modals } from '../../../../constants';
import {
  EventAction,
  EventCategory,
  EventLabel,
  openModalWithPageView,
  sendCustomInteractionToGTM,
} from '../../../../utils/google-analytics';
import { ResetPasswordModal } from './ResetPasswordModal';

type LoginInformationProps = {
  providerId: string;
  workerDetails: WorkerDetail;
  setWorkerDetails: React.Dispatch<
    React.SetStateAction<WorkerDetail | undefined>
  >;
};

export const LoginInformation = ({
  providerId,
  workerDetails,
  setWorkerDetails,
}: LoginInformationProps) => {
  const { t } = useTranslation();
  const [passwordIsHidden, setPasswordIsHidden] = useState(true);
  const [passwordIsReset, setPasswordIsReset] = useState(false);

  const setCredentials = (credentials: {
    login: string;
    password: string | undefined;
  }) => {
    setPasswordIsHidden(false);
    setPasswordIsReset(true);
    setWorkerDetails(
      (workerDetails) =>
        ({
          ...workerDetails,
          ...credentials,
        } as WorkerDetail),
    );
    sendCustomInteractionToGTM(
      EventCategory.WORKER_DETAILS,
      EventAction.CLICK,
      EventLabel.WORKER_RESET_PASSWORD,
    );
  };

  const { login, password } = workerDetails;

  const refTooltipLoginInformation = useTooltip({
    value: t(i18nKeys.worker.details.loginInformation.tooltip),
    placement: 'auto',
  });

  const canResetPassword = useActionsHandler(workerDetails.resourceId, [
    WorkerActionType.CAN_RESET_PASSWORD,
  ]);

  return (
    <>
      <SwTitle tagName="h3" tagStyle="h4" className="vl-u-spacer--small">
        {t(i18nKeys.workers.loginInformation.title)}
        <SwBadge
          modAction
          modXsmall
          icon={Icon.INFO}
          style={{ marginLeft: '1rem', verticalAlign: 'middle' }}
          ref={refTooltipLoginInformation}
        />
      </SwTitle>

      <SwGrid className="vl-u-spacer--medium vl-u-flex-v-flex-start">
        <SwColumn width="2" widthM="4" widthS="6">
          <p>{t(i18nKeys.workers.loginInformation.login)}</p>
          <strong>{login}</strong>
        </SwColumn>
        {!workerDetails.isBlocked ? (
          <SwColumn width="6" className="vl-u-spacer">
            <p>{t(i18nKeys.workers.loginInformation.password)}</p>
            <strong>
              <input
                size={password?.length}
                style={{
                  fontWeight: 'bold',
                  border: 'none',
                  letterSpacing: passwordIsHidden ? '.4rem' : '0',
                }}
                readOnly
                type={passwordIsHidden ? 'password' : 'text'}
                value={password}
              />
            </strong>
            <button
              type="button"
              style={{ border: 'none', margin: '0 2rem 0 -1rem' }}
              onClick={() => setPasswordIsHidden(!passwordIsHidden)}
            >
              <SwIcon icon={passwordIsHidden ? Icon.SHOW : Icon.HIDE} />
            </button>
            <SwLink
              modDisabled={!canResetPassword}
              icon={Icon.LOCK}
              tagName="button"
              type="button"
              modIconAfter
              onClick={() => {
                setPasswordIsReset(false);
                openModalWithPageView(Modals.WORKERS_RESET_PASSWORD_MODAL);
              }}
            >
              {t(i18nKeys.workers.loginInformation.resetPassword.title)}
            </SwLink>
          </SwColumn>
        ) : (
          <SwColumn width="6" className="vl-u-spacer">
            <p>{t(i18nKeys.workers.loginInformation.password)}</p>
            <div>
              <strong style={{ marginRight: '2rem' }}>
                <SwBadge
                  style={{ verticalAlign: 'middle' }}
                  modSmall
                  modWarning
                  icon={Icon.ALERT_CIRCLE}
                />
                {t(i18nKeys.workers.loginInformation.blocked)}
              </strong>
              <SwLink
                modDisabled={!canResetPassword}
                icon={Icon.LOCK}
                tagName="button"
                type="button"
                modIconAfter
                onClick={() => {
                  setPasswordIsReset(false);
                  openModalWithPageView(Modals.WORKERS_RESET_PASSWORD_MODAL);
                }}
              >
                {t(i18nKeys.workers.loginInformation.resetPassword.title)}
              </SwLink>
            </div>
          </SwColumn>
        )}
        {passwordIsReset && (
          <SwColumn width="12">
            <SwAlert {...getAlertPropsByType(AlertType.SUCCESS)} modSmall>
              {t(
                i18nKeys.workers.loginInformation.resetPassword.alert.success
                  .message,
              )}
            </SwAlert>
          </SwColumn>
        )}
      </SwGrid>

      {canResetPassword && (
        <SwModal
          id={Modals.WORKERS_RESET_PASSWORD_MODAL}
          closable
          providerId={providerId}
          workerId={workerDetails.id}
          setCredentials={setCredentials}
          component={ResetPasswordModal}
          confirmCloseMessage={t(i18nKeys.dialog.confirmClose.message)}
        />
      )}

      <hr className="vl-u-spacer--medium" />
    </>
  );
};
