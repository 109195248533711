import { getActionsMock, getResourceIdMock } from '@kaa/api/common/mocks';
import faker from 'faker';
import { DateTime } from 'luxon';
import {
  sortByCreationDate,
  sortByCustomerName,
  sortByServiceDate,
  sortByWorkerName,
} from '.';
import {
  Activity,
  Author,
  FileStatus,
  GetListServicesParams,
  GetServicesHistoryParams,
  GetServicesParams,
  ListServicesResponse,
  Service,
  ServiceBase,
  ServiceComment,
  ServiceRemittanceInformation,
  ServiceResponse,
  ServicesFileStatusResponse,
  ServicesFileStatusResponseData,
  ServicesHistoryResponse,
  ServicesOverview,
  ServicesOverviewResponse,
  ServicesResponse,
  ServicesResponseDataDataValuesItem,
  ServiceStatus,
  ServicesValidatedResponse,
  ServiceUpdate,
  ServiceUpdateResponse,
  Sorting,
  WorkerServiceActionType,
} from '../model';
import { getDataWithPagination } from './pagination';
import { getFullName, getId, getRandomArray } from './utils';

export const RESOURCE_PREFIX_SERVICE = 'service';

const getServicesOverview = (): ServicesOverview => {
  // Search
  const totalOfServices = faker.random.number({
    min: 0,
    max: 100,
  });

  const waitingForValidation = faker.random.number({
    min: 0,
    max: totalOfServices,
  });

  const waitingForConfirmation = faker.random.number({
    min: 0,
    max: totalOfServices - waitingForValidation,
  });

  const contested = faker.random.number({
    min: 0,
    max: totalOfServices - waitingForValidation - waitingForConfirmation,
  });

  const missingContract = faker.random.number({
    min: 0,
    max:
      totalOfServices -
      waitingForValidation -
      waitingForConfirmation -
      contested,
  });

  const missingVouchers = faker.random.number({
    min: 0,
    max:
      totalOfServices -
      waitingForValidation -
      waitingForConfirmation -
      contested -
      missingContract,
  });

  const vouchersAssigned = faker.random.number({
    min: 0,
    max:
      totalOfServices -
      waitingForValidation -
      waitingForConfirmation -
      contested -
      missingContract -
      missingVouchers,
  });
  // History
  const totalOfServicesForHistory = faker.random.number({
    min: 0,
    max: 100,
  });

  const inRemittance = faker.random.number({
    min: 0,
    max: totalOfServicesForHistory,
  });

  const canceled = faker.random.number({
    min: 0,
    max: totalOfServicesForHistory - inRemittance,
  });

  const toBePaidOutsideSv = faker.random.number({
    min: 0,
    max: totalOfServicesForHistory - inRemittance - canceled,
  });

  return {
    totalOfServices,
    totalOfServicesForHistory,
    waitingForValidation,
    waitingForConfirmation,
    contested,
    missingContract,
    missingVouchers,
    vouchersAssigned,
    inRemittance,
    canceled,
    toBePaidOutsideSv,
  };
};

const getServiceBase = (): ServiceBase => {
  const id = getId();
  const serviceDate = faker.date.past();
  return {
    id,
    serviceDate: serviceDate.toISOString(),
    creationDate: faker.date.between(serviceDate, new Date()).toISOString(),
    workerId: getId(),
    workerFullname: getFullName(),
    customerFullname: getFullName(),
    customerAuthorisation: getId(),
    durationInHours: faker.random.number({ min: 1, max: 4 }),
    resourceId: getResourceIdMock(RESOURCE_PREFIX_SERVICE, id),
  };
};

const getServicesValidatedResponseData = () => {
  return {
    overview: getServicesOverview(),
    totalOfPendingServices: faker.random.number({
      min: 1,
      max: 1000,
    }),
    numberOfServicesValidated: faker.random.number({
      min: 1,
      max: 10,
    }),
    numberOfPendingServices: faker.random.number({
      min: 1,
      max: 10,
    }),
  };
};

export const getService = (
  serviceStatus = Object.values(ServiceStatus),
): Service => {
  const status = faker.helpers.randomize(serviceStatus);

  const service = {
    ...getServiceBase(),
    hasVoucherExpiringSoon: faker.random.boolean(),
    activity: faker.helpers.randomize(Object.values(Activity)),
    status,
  };

  switch (status) {
    case ServiceStatus.PAID:
      return {
        ...service,
        assignedVouchers: service.durationInHours,
        remittableVouchers: service.durationInHours,
        remittedVouchers: 0,
        missingVouchers: 0,
      };
    case ServiceStatus.REFUND_REQUESTED:
      return {
        ...service,
        assignedVouchers: service.durationInHours,
        remittableVouchers: 0,
        remittedVouchers: service.durationInHours,
        missingVouchers: 0,
      };
    case ServiceStatus.CREATED:
    case ServiceStatus.CONFIRMED:
      return {
        ...service,
        assignedVouchers: 0,
        remittableVouchers: 0,
        remittedVouchers: 0,
        missingVouchers: service.durationInHours,
        automaticValidationRemainingDays: faker.random.number({
          min: 1,
          max: 10,
        }),
      };
    case ServiceStatus.CONTESTED:
      return {
        ...service,
        assignedVouchers: 0,
        remittableVouchers: 0,
        remittedVouchers: 0,
        missingVouchers: service.durationInHours,
        isDurationInHoursContested: faker.random.boolean(),
        isActivityContested: faker.random.boolean(),
      };
    case ServiceStatus.PARTIALLY_ASSIGNED: {
      const assignedVouchers = faker.random.number({
        min: 1,
        max: service.durationInHours,
      });

      const remittableVouchers = faker.random.number({
        min: 1,
        max: assignedVouchers,
      });

      return {
        ...service,
        assignedVouchers,
        remittableVouchers,
        remittedVouchers: assignedVouchers - remittableVouchers,
        missingVouchers: service.durationInHours - assignedVouchers,
      };
    }
    case ServiceStatus.PARTIALLY_REFUNDED: {
      const assignedVouchers = faker.random.number({
        min: 1,
        max: service.durationInHours,
      });

      const remittableVouchers = 0;

      return {
        ...service,
        assignedVouchers,
        remittableVouchers,
        remittedVouchers: assignedVouchers,
        missingVouchers: service.durationInHours - assignedVouchers,
      };
    }
    default:
      return {
        ...service,
        assignedVouchers: 0,
        remittableVouchers: 0,
        remittedVouchers: 0,
        missingVouchers: service.durationInHours,
      };
  }
};

export const getServicesResponse = (
  params?: GetServicesParams,
): ServicesResponse => {
  const servicesData = getRandomArray({ min: 13, max: 145 }).map(() => {
    const serviceBase = getServiceBase();

    const remittableVouchers = faker.random.number({
      min: 1,
      max: serviceBase.durationInHours,
    });

    return {
      ...serviceBase,
      workerFullname: getFullName(),
      hasVoucherExpiringSoon: faker.random.boolean(),
      remittableVouchers,
      isFullyAssigned:
        remittableVouchers === serviceBase.durationInHours
          ? true
          : faker.random.boolean(),
    };
  });

  const { data: values, paginationDirectives } = getDataWithPagination<
    ServicesResponseDataDataValuesItem
  >(servicesData, params);

  return {
    paginationDirectives,
    data: {
      values,
      extra: {
        totalInHours: servicesData.reduce(
          (totalInHours, { durationInHours }) => totalInHours + durationInHours,
          0,
        ),
      },
    },
  };
};

export const sortServices = (
  sort: Sorting | undefined,
  services: Service[],
) => {
  switch (sort) {
    case Sorting.DATE_ASC:
    case Sorting.DATE_DESC:
      return sortByServiceDate(sort, services);
    case Sorting.CREATION_DATE_ASC:
    case Sorting.CREATION_DATE_DESC:
      return sortByCreationDate(sort, services);
    case Sorting.CUSTOMER_ASC:
    case Sorting.CUSTOMER_DESC:
      return sortByCustomerName(sort, services);
    case Sorting.WORKER_ASC:
    case Sorting.WORKER_DESC:
      return sortByWorkerName(sort, services);
    default:
      return services;
  }
};

export const getListServicesResponse = (
  params?: GetListServicesParams,
): ListServicesResponse => {
  // PaginationDirectives & Actions & ListServicesResponseData
  const services = sortServices(
    params?.sort,
    getRandomArray({
      min: 13,
      max: 145,
    }).map(() => getService()),
  );

  const { data: values, paginationDirectives } = getDataWithPagination<Service>(
    services,
    params,
  );

  const currentPage = services.slice(0, paginationDirectives?.pageSize || 20);

  return {
    paginationDirectives,
    data: {
      actions: currentPage.reduce(
        (acc, { resourceId }) =>
          resourceId
            ? {
                ...acc,
                [resourceId]: getActionsMock(WorkerServiceActionType),
              }
            : acc,
        {},
      ),
      values,
      extra: {
        totalOfPendingServices: services.length,
        durationInHours: currentPage.reduce(
          (acc, e) => acc + e.durationInHours,
          0,
        ),
        totalDurationInHours: services.reduce(
          (acc, e) => acc + e.durationInHours,
          0,
        ),
      },
    },
  };
};

export const getServicesHistoryResponse = (
  params?: GetServicesHistoryParams,
): ServicesHistoryResponse => {
  const services = sortServices(
    params?.sort,
    getRandomArray({
      min: 13,
      max: 145,
    }).map(() => getService()),
  );

  const { data: values, paginationDirectives } = getDataWithPagination<Service>(
    services,
    params,
  );

  const currentPage = services.slice(0, paginationDirectives?.pageSize || 20);

  return {
    paginationDirectives,
    data: {
      values,
      extra: {
        durationInHours: currentPage.reduce(
          (acc, e) => acc + e.durationInHours,
          0,
        ),
        totalDurationInHours: services.reduce(
          (acc, e) => acc + e.durationInHours,
          0,
        ),
      },
    },
  };
};

export const getServiceComment = (
  serviceDeliveryComment: Partial<ServiceComment> = {},
): ServiceComment => {
  return {
    author: faker.helpers.randomize(Object.values(Author)),
    text: faker.lorem.sentence(),
    publicationTimestamp: faker.date.past().toISOString(),
    ...serviceDeliveryComment,
  };
};

const getServiceRemittanceInformation = (
  service: Service,
  previous?: ServiceRemittanceInformation,
): ServiceRemittanceInformation => ({
  remittanceId: Number(getId()),
  vouchersQuantity: faker.random.number({
    min: 1,
    max: service.durationInHours - (previous ? previous.vouchersQuantity : 0),
  }),
});

const getServiceRemittanceInformationArray = (
  service: Service,
  min: number,
  max: number,
): ServiceRemittanceInformation[] | undefined => {
  const remittances = getRandomArray({
    min,
    max,
  })
    .reduce(
      (acc) => [
        ...acc,
        getServiceRemittanceInformation(service, acc[acc.length - 1]),
      ],
      [],
    )
    .filter((e: ServiceRemittanceInformation) => e.vouchersQuantity >= 1);
  return remittances.length ? remittances : undefined;
};

export const getServiceResponse = (): ServiceResponse => {
  const service = {
    ...getService(),
    providerId: faker.random.uuid(),
    providerName: faker.company.companyName(),
    customerId: getId(),
    customerContractId: `${getId()}${getId()}${getId()}`.slice(0, 12),
    workerNiss: faker.random.number(99999999999).toString(),
    automaticValidationRemainingDays: faker.random.number(),
    cancellationDate: faker.date.past().toISOString(),
    previousDurationInHours: faker.random.number({ min: 1, max: 4 }),
    previousActivity: faker.helpers.randomize(Object.values(Activity)),
    comments: getRandomArray({ min: 0, max: 5 }).map(getServiceComment),
  };

  switch (service.status) {
    case ServiceStatus.PARTIALLY_ASSIGNED: {
      return {
        data: {
          ...service,
          ...getServiceRemittanceInformationArray(service, 0, 3),
        },
      };
    }
    case ServiceStatus.PARTIALLY_REFUNDED:
    case ServiceStatus.REFUND_REQUESTED: {
      return {
        data: {
          ...service,
          ...getServiceRemittanceInformationArray(service, 1, 3),
        },
      };
    }
    default:
      return { data: { ...service } };
  }
};

const getAdditionalServiceResponse = () => ({
  additionalOverview: {
    totalOfServices: -1,
    totalOfServicesForHistory: 0,
    waitingForValidation: -1,
    waitingForConfirmation: 1,
  } as ServicesOverview,
  additionalPendingServicesForWorker: -1,
  additionalTotalOfPendingServices: -1,
});

export const getServiceCancelResponse = (): ServiceUpdateResponse => {
  return {
    data: {
      ...getAdditionalServiceResponse(),
    },
  };
};

export const getServiceUpdateResponse = (
  serviceUpdate: ServiceUpdate,
): ServiceUpdateResponse => {
  const service = {
    ...getService(),
    ...serviceUpdate,
    status: ServiceStatus.VALIDATED,
  };
  const resourceId = getResourceIdMock(RESOURCE_PREFIX_SERVICE, service.id);
  return {
    actions: {
      [resourceId]: getActionsMock(WorkerServiceActionType),
    },
    data: {
      ...getAdditionalServiceResponse(),
      service: {
        ...service,
        resourceId,
      },
    },
  };
};

export const getServiceValidateResponse = (
  id: number,
): ServiceUpdateResponse => {
  const service = {
    ...getService(),
    status: ServiceStatus.VALIDATED,
    id: String(id),
  };
  const resourceId = getResourceIdMock(RESOURCE_PREFIX_SERVICE, service.id);
  return {
    actions: {
      [resourceId]: getActionsMock(WorkerServiceActionType),
    },
    data: {
      ...getAdditionalServiceResponse(),
      service,
    },
  };
};

// Generate or update a fileStatus depending current status
const getServicesFileStatusResponseData = (
  data?: ServicesFileStatusResponseData,
): ServicesFileStatusResponseData => {
  if (
    data?.status === FileStatus.Done ||
    data?.status === FileStatus.DoneWithError
  ) {
    return data;
  }

  if (data?.status === FileStatus.InProgress) {
    return {
      ...data,
      status: faker.helpers.randomize([
        FileStatus.InProgress,
        FileStatus.Parsed,
      ]),
    };
  }

  if (data?.status === FileStatus.Parsed) {
    return {
      ...data,
      status: faker.helpers.randomize([
        FileStatus.Parsed,
        FileStatus.Done,
        FileStatus.DoneWithError,
      ]),
    };
  }

  const totalRows = faker.random.number();
  const processedRows = faker.random.number({ min: 1, max: totalRows });
  const created = faker.random.number({
    min: 1,
    max: processedRows,
  });

  const invalid = processedRows - created;

  return {
    status: faker.helpers.randomize(Object.values(FileStatus)),
    lastSuccessTimestamp: DateTime.fromJSDate(new Date()).toISO(),
    totalRows,
    processedRows,
    created,
    invalid,
  };
};

// First call, I have a current fileStatus or not
let fileStatus =
  faker.random.number({ min: 1, max: 10 }) > 3
    ? getServicesFileStatusResponseData()
    : undefined;

// New upload set a new fileStatus
export const setNewServicesFileStatusResponse = (): void => {
  fileStatus = getServicesFileStatusResponseData();
};

// Return a new fileStatus or the current one maybe updated
export const getServicesFileStatusResponse = (): ServicesFileStatusResponse => {
  if (!fileStatus) {
    return { data: undefined };
  }

  fileStatus = getServicesFileStatusResponseData(fileStatus);

  return {
    data: fileStatus,
  };
};

export const getServicesOverviewResponse = (): ServicesOverviewResponse => ({
  data: getServicesOverview(),
});

export const getServicesValidatedResponse = (): ServicesValidatedResponse => ({
  data: getServicesValidatedResponseData(),
});
