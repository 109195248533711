import {
  GetListServicesParams,
  ListServicesResponseDataDataDataExtra,
  PaginationDirectives,
  ProviderType,
  Relative,
  Service,
  ServicesOverview,
  ServiceUpdateResponseDataData,
  Sorting,
  ValidateWorkersServicesParams,
} from '@kaa/api/providers';
import { httpTo } from '@kaa/api/providers/utilities';
import { useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AlertType,
  getAlertPropsByType,
  SwAlert,
  SwButton,
  SwColumn,
  SwDataTable,
  SwGrid,
  SwLoader,
  SwModal,
} from '@kaa/ui-flanders/components';
import { FormikActions } from 'formik';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionType, useActionDispatch } from '../../../../../common/context';
import { TableRowNoResult } from '../../../components';
import { Modals } from '../../../constants';
import { getQueryParams, QueryParams, Routes } from '../../../routes';
import { useApi, useSelectedProviderState } from '../../../utils';
import {
  EventAction,
  EventCategory,
  EventLabel,
  openModalWithPageView,
  sendCustomInteractionToGTM,
  sendSearchToGTM,
} from '../../../utils/google-analytics';
import { PrestationsPagination } from '../components/pagination/PrestationsPagination';
import { PrestationsForm } from '../components/PrestationsForm';
import { getServiceActiveStatusOptions } from '../components/PrestationsForm.utils';
import { PrestationsModals } from '../components/PrestationsModals';
import { PrestationsOverview } from '../components/PrestationsOverview';
import {
  getSearchOverview,
  updateOverview,
} from '../components/PrestationsOverview.utils';
import {
  PrestationsTableHead,
  PrestationsTableHeadElement,
} from '../components/PrestationsTableHead';
import { PrestationsTableRow } from '../components/PrestationsTableRow';
import { PrestationsSearchValidateModal } from '../components/validate/PrestationsSearchValidateModal';
import { PRESTATIONS_SEARCH_FILTERS } from '../PrestationsScreen.constants';
import {
  OverviewFilter,
  PrestationsSearchFilters,
  ValidateStatus,
} from '../PrestationsScreen.types';
import {
  getInitialParameters,
  getListServicesParamsForFilter,
  updatePendingServices,
} from '../PrestationsScreen.utils';
import { PrestationsSearchTableRowStatus } from './components/PrestationsSearchTableRowStatus';

const PRESTATIONS_SEARCH_PAGE_SIZE = 50;

type PrestationsSearchListProps = {
  overview: ServicesOverview;
  setOverview: (overview: ServicesOverview) => void;
  relatives: Relative[];
};

export const PrestationsSearchList = ({
  overview,
  setOverview,
  relatives,
  children,
}: PropsWithChildren<PrestationsSearchListProps>) => {
  const { t } = useTranslation();
  const { providers } = useApi();
  const provider = useSelectedProviderState();

  const dispatchAction = useActionDispatch();

  const isSubsidiary = provider.type === ProviderType.SUBSIDIARY;

  const filter = getQueryParams<PrestationsSearchFilters>(QueryParams.FILTER);

  const {
    initialParameters,
    paginationInitialParameters,
  } = getInitialParameters(PRESTATIONS_SEARCH_PAGE_SIZE);

  const [parameters, setParameters] = useState<GetListServicesParams>(
    filter && PRESTATIONS_SEARCH_FILTERS[filter]
      ? {
          ...getListServicesParamsForFilter(
            PRESTATIONS_SEARCH_FILTERS[filter],
            PRESTATIONS_SEARCH_PAGE_SIZE,
            initialParameters,
          ),
          ...paginationInitialParameters,
        }
      : initialParameters,
  );

  const [totalOfPendingServices, setTotalOfPendingServices] = useState<
    number | undefined
  >();

  const [services, setServices] = useState<Service[]>([]);

  const [selectedService, setSelectedService] = useState<Service>();

  const [pagination, setPagination] = useState<
    PaginationDirectives['paginationDirectives']
  >();

  const [servicesExtra, setServicesExtra] = useState<
    ListServicesResponseDataDataDataExtra | undefined
  >();

  const [validateStatus, setValidateStatus] = useState<ValidateStatus>();

  const resetPrestationForm = () => {
    setParameters(() => initialParameters);
  };

  const applyFilter = (overviewFilter: OverviewFilter) => {
    setParameters(({ bookmark, ...currentParameters }) => ({
      ...getListServicesParamsForFilter(
        overviewFilter,
        PRESTATIONS_SEARCH_PAGE_SIZE,
        currentParameters,
      ),
      ...paginationInitialParameters,
    }));
  };

  const [
    { value: servicesResponse, loading: servicesLoading },
    getServices,
  ] = useAsyncCallback(
    async (servicesParams: GetListServicesParams) =>
      (await providers.getListServices(provider.id, servicesParams)).data,
    [providers],
    { loading: true },
  );

  useEffect(() => {
    getServices(parameters);
  }, [getServices, parameters]);

  useEffect(() => {
    if (servicesResponse) {
      const {
        paginationDirectives,
        data: { extra, values, actions },
      } = servicesResponse;

      if (actions) {
        dispatchAction({
          type: ActionType.ADD,
          payload: actions,
        });
      }

      setServices(() => values);
      setTotalOfPendingServices(() => extra?.totalOfPendingServices);
      setServicesExtra(() => extra);
      setPagination(() => paginationDirectives);
    }
  }, [servicesResponse]);

  const [, submitSearch] = useAsyncCallback(
    async (
      { bookmark, ...servicesParams }: GetListServicesParams,
      formikActions: FormikActions<GetListServicesParams>,
    ) => {
      const { setSubmitting, resetForm } = formikActions;

      setSubmitting(false);
      resetForm(servicesParams);

      setParameters(() => ({
        ...servicesParams,
        ...paginationInitialParameters,
      }));

      const {
        workerNameOrNiss, // Sensitive info we can't log due to GDPR
        customerNameOrContractId, // Sensitive info we can't log due to GDPR
        ...workerServiceParamsWithoutSensitiveInfo
      } = servicesParams;

      sendSearchToGTM(
        Routes.PRESTATIONS,
        JSON.stringify(workerServiceParamsWithoutSensitiveInfo),
      );

      return {
        formikActions,
        servicesParams,
      };
    },
    [setParameters],
  );

  const [
    {
      value: validateWorkersServicesData,
      loading: validateWorkersServicesLoading,
    },
    validateWorkersServices,
  ] = useAsyncCallback(
    async ({
      sort,
      pageNumber,
      pageSize,
      workerId,
      searchedProviderId,
      ...validateWorkersServicesParams
    }: GetListServicesParams) => {
      setValidateStatus(undefined);

      const [error, response] = await httpTo(
        providers.validateWorkersServices(
          Number(searchedProviderId || provider.id),
          validateWorkersServicesParams as ValidateWorkersServicesParams,
        ),
      );

      return {
        response: response?.data?.data,
        error,
      };
    },
    [providers],
    { loading: false, value: undefined },
  );

  useEffect(() => {
    if (validateWorkersServicesData) {
      const { response, error } = validateWorkersServicesData;

      if (error || !response) {
        setValidateStatus({
          type: AlertType.ERROR,
          title: t(i18nKeys.general.label.error),
          msg: t(
            i18nKeys.prestations.modals.validate.alert.validateAllForWorkers
              .error,
          ),
        });
        return;
      }

      const { overview: newOverview, numberOfServicesValidated } = response;

      setValidateStatus({
        type: AlertType.SUCCESS,
        title: t(i18nKeys.general.label.success),
        msg: t(
          i18nKeys.prestations.modals.validate.alert.validateAllForWorkers
            .success,
          { count: numberOfServicesValidated },
        ),
      });

      setOverview(newOverview);

      setParameters(({ bookmark, ...currentParameters }) => ({
        ...currentParameters,
        ...paginationInitialParameters,
      }));

      sendCustomInteractionToGTM(
        EventCategory.PRESTATIONS,
        EventAction.CLICK,
        EventLabel.PRESTATIONS_VALIDATE_ALL_OF_ALL,
      );
    }
  }, [validateWorkersServicesData]);

  const openModal = (modal: Modals | string, service: Service) => {
    setSelectedService(service);
    openModalWithPageView(modal as Modals, {
      appendToId: service.workerId,
    });
  };

  const onSuccess = ({
    additionalOverview,
    additionalTotalOfPendingServices,
  }: ServiceUpdateResponseDataData) => {
    setOverview(updateOverview(overview, additionalOverview));

    setTotalOfPendingServices((current) =>
      updatePendingServices(current, additionalTotalOfPendingServices),
    );
  };

  const { totalOfServices } = overview;

  const head = [
    {
      text: t(i18nKeys.general.label.id),
    },
    {
      text: t(i18nKeys.general.label.date),
      sorting: Sorting.DATE_ASC,
    },
    {
      text: t(i18nKeys.general.label.creationDate),
      sorting: Sorting.CREATION_DATE_ASC,
    },
    {
      text: t(i18nKeys.general.label.worker),
      sorting: Sorting.WORKER_ASC,
    },
    {
      text: t(i18nKeys.general.label.customer),
      sorting: Sorting.CUSTOMER_ASC,
    },
    { text: t(i18nKeys.general.label.activity) },
    { text: t(i18nKeys.general.label.time) },
    { text: t(i18nKeys.general.label.status) },
    { text: '' },
  ] as PrestationsTableHeadElement[];

  return (
    <SwColumn width="12" widthS="12">
      <SwGrid modStacked>
        {!validateWorkersServicesLoading && totalOfServices > 0 && (
          <PrestationsOverview
            overview={getSearchOverview(overview)}
            applyFilter={applyFilter}
            route={Routes.PRESTATIONS}
            title={t(i18nKeys.prestations.search.alert.title, {
              count: totalOfServices,
            })}
          />
        )}
        <SwColumn>
          <PrestationsForm
            resetPrestationForm={resetPrestationForm}
            isLoading={servicesLoading}
            initialParameters={parameters}
            isSubsidiary={isSubsidiary}
            relatives={relatives}
            submit={submitSearch}
            voucherStatusOptions={getServiceActiveStatusOptions(t)}
          />
        </SwColumn>
        {!!validateStatus && (
          <SwColumn>
            <SwAlert
              {...getAlertPropsByType(validateStatus.type)}
              title={validateStatus.title}
              close={() => setValidateStatus(undefined)}
              closable
              modSmall
            >
              {validateStatus.msg}
            </SwAlert>
          </SwColumn>
        )}
        {children && <SwColumn>{children}</SwColumn>}
        {!servicesLoading ? (
          <>
            {!!totalOfPendingServices && totalOfPendingServices > 0 && (
              <SwColumn className="vl-u-spacer">
                <SwButton
                  onClick={() => {
                    openModalWithPageView(
                      Modals.PRESTATIONS_WORKERS_VALIDATE_ALL_PRESTATIONS_MODAL,
                    );
                  }}
                  style={{
                    marginRight: '2rem',
                  }}
                >
                  {t(i18nKeys.general.label.validateAll)}
                </SwButton>
                {t(i18nKeys.prestations.search.totalPendingWorks, {
                  count: totalOfPendingServices,
                })}
              </SwColumn>
            )}
            <SwColumn>
              <div className="vl-u-table-overflow">
                <SwDataTable modLine>
                  <PrestationsTableHead<GetListServicesParams>
                    head={head}
                    parameters={parameters}
                    setParameters={setParameters}
                    eventCategory={EventCategory.PRESTATIONS}
                  />
                  <tbody>
                    {!services || !services.length ? (
                      <TableRowNoResult colSpan={head.length} />
                    ) : (
                      services.map((service) => (
                        <PrestationsTableRow
                          key={`${service.workerId}-${service.id}-${service.status}`}
                          service={service}
                          openModal={openModal}
                          providerId={
                            parameters?.searchedProviderId ||
                            provider.id.toString()
                          }
                          showWorker
                          showActions
                        >
                          <PrestationsSearchTableRowStatus
                            providerId={provider.id}
                            workerId={service.workerId}
                            service={service}
                          />
                        </PrestationsTableRow>
                      ))
                    )}
                  </tbody>
                </SwDataTable>
              </div>
            </SwColumn>
            {!!services &&
              services.length > 0 &&
              !!servicesExtra &&
              !!pagination && (
                <SwColumn>
                  <PrestationsPagination
                    pagination={pagination}
                    extra={servicesExtra}
                    setPage={(page) => {
                      setParameters((currentParameters) => ({
                        ...currentParameters,
                        pageSize: currentParameters.pageSize,
                        pageNumber:
                          typeof page === 'number'
                            ? page
                            : page(currentParameters?.pageNumber || 1),
                      }));
                    }}
                  />
                </SwColumn>
              )}
          </>
        ) : (
          <SwColumn className="vl-u-spacer">
            <SwLoader />
          </SwColumn>
        )}
      </SwGrid>
      {!servicesLoading && !!services && services.length > 0 && (
        <>
          <SwModal
            id={Modals.PRESTATIONS_WORKERS_VALIDATE_ALL_PRESTATIONS_MODAL}
            modalId={Modals.PRESTATIONS_WORKERS_VALIDATE_ALL_PRESTATIONS_MODAL}
            component={PrestationsSearchValidateModal}
            title={t(i18nKeys.prestations.search.modal.validateAll)}
            confirmText={t(i18nKeys.general.cta.validate)}
            parameters={{
              ...parameters,
              bookmark: pagination?.bookmark,
            }}
            confirm={validateWorkersServices}
            isLoading={validateWorkersServicesLoading}
            closable
          />
          {selectedService && (
            <PrestationsModals
              provider={provider}
              parameters={parameters}
              selectedService={selectedService}
              services={services}
              setServices={setServices}
              onSuccess={onSuccess}
              setValidateStatus={setValidateStatus}
            />
          )}
        </>
      )}
    </SwColumn>
  );
};
